import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AOK_PENDING_EXPIRATION_SSO_SESSION_INIT_PROVIDER } from '../../bootstrap/pending-expiration-session.listener';
import { PendingExpirationSessionInterceptor } from './pending-expiration-session.interceptor';

@NgModule({
  imports: [OverlayModule],
})
export class PendingExpirationSessionModule {
  static forRoot(): ModuleWithProviders<PendingExpirationSessionModule> {
    return {
      ngModule: PendingExpirationSessionModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: PendingExpirationSessionInterceptor,
          multi: true,
        },
        AOK_PENDING_EXPIRATION_SSO_SESSION_INIT_PROVIDER,
      ],
    };
  }
}
