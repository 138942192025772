import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AokSvgIconComponent } from '@aok/common';

@Component({
  selector: 'aok-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, AokSvgIconComponent],
})
export class FilterButtonComponent {
  @Input()
  label = 'Liste filtern';

  @Input()
  isAreaOpen: boolean;

  @Input()
  counter = 0;

  @Output()
  buttonClick: EventEmitter<void> = new EventEmitter();

  constructor() {}
}
