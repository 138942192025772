import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NotificationBannerLink } from '@aok/common';

@Component({
  selector: 'aok-notification-banner',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./notification-banner.component.scss'],
  templateUrl: 'notification-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AokNotificationBannerComponent {
  @Input() bannerHeadline: string;
  @Input() bannerLabel: string;
  @Input() bannerLinks: NotificationBannerLink[];
  @Input() accent: 'bad' | 'good' | 'warning' | 'info';
  @Input() closable = false;
  @Input() iconName: string;

  @Output()
  public closed = new EventEmitter<void>();

  public get bannerIcon(): string {
    if (this.iconName) {
      return this.iconName;
    }
    return this.accent === 'good' ? 'checkmark-circle' : 'alert-circle';
  }

  public onClose(): void {
    this.closed.emit();
  }
}
