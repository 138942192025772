<dialog-layout fullscreen="false" [isClosable]="false" aokFocusFirstChild [autoBlur]="true" cdkTrapFocus>
  <dialog-header>
    <p class="aok-text-l2 required-label">* Pflichtfeld</p>
    <h4>Nutzungsbedingungen des Arztportals</h4>
  </dialog-header>
  <div>
    <p>
      Um das Arztportal nutzen zu können, benötigen wir Ihre Zustimmung zu den Nutzungsbedingungen. Wir empfehlen Ihnen
      die <a href="/publik/nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a> zu lesen und zu überprüfen bevor
      Sie diese bestätigen. Auf der Seite <a href="/publik/datenschutz" target="_blank">Datenschutz</a> finden Sie alle
      Informationen darüber wie das Arztportal Ihre Daten verarbeitet.
    </p>

    <form [formGroup]="formGroup">
      <aok-input-field>
        <aok-checkbox [formControl]="getFormControl('terms')">
          <label
            >Ich habe die
            <a
              href="/publik/nutzungsbedingungen"
              (keyup.enter)="followLink($event, '/publik/nutzungsbedingungen')"
              target="_blank"
              >Nutzungsbedingungen</a
            >
            zur Kenntnis genommen und stimme diesen zu. <aok-required-mark></aok-required-mark
          ></label>
        </aok-checkbox>
      </aok-input-field>
      <aok-input-field>
        <aok-checkbox [formControl]="getFormControl('privacy')">
          <label
            >Ich habe die Informationen zum
            <a href="/publik/datenschutz" (keyup.enter)="followLink($event, '/publik/datenschutz')" target="_blank"
              >Datenschutz</a
            >
            zur Kenntnis genommen. <aok-required-mark></aok-required-mark
          ></label>
        </aok-checkbox>
      </aok-input-field>
    </form>

    <p>
      Sie möchten den Nutzungsbedingungen nicht zustimmen und Ihr Profil nicht weiter nutzen oder wünschen die Löschung
      des Profils? In diesem Fall schreiben Sie uns eine E-Mail an
      <a href="mailto:aok-arztportal@nds.aok.de">AOK-Arztportal@nds.aok.de</a>.
    </p>
  </div>

  <dialog-footer>
    <button class="secondary" (click)="keycloak.logout()">Ausloggen</button>
    <button (click)="commit()">Zustimmung senden</button>
  </dialog-footer>
</dialog-layout>
