import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  A11yUtilsModule,
  AokCardReaderError,
  AokDMP,
  AokInsuranceStatusClient,
  AokPatientSearchTypeEnum,
  AokPopoverComponent,
  AokPopoverContentTemplateComponent,
  AokServiceRequest,
  ContextState,
  CurrentUserState,
  INSURANCE_DATA_REQUEST_TYPES,
  isDoctor,
  scrollToTop,
  TableData,
  TableTypeRow,
} from '@aok/common';
import {
  AokCheckboxModule,
  AokFooterModule,
  AokInputFieldModule,
  AokNotificationBannerModule,
  AokTableModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
  RequiredMarkModule,
} from '@aok/components';
import { DmpProgramsService } from '../../../services/dmp-programs.service';
import { MatomoService } from '../../../services/matomo.service';
import { getInsuranceDataTable, getPersonalResultTable, ServicePagesLabels } from '../../../utils/service-pages.util';
import { ServicePageComponent } from '../service-page.component';
import { ErrorBannerService } from '../service-request-form/error-banner.service';
import { ServiceRequestFormComponent } from '../service-request-form/service-request-form.component';

@Component({
  selector: 'aok-cockpit-service-options-inquiry',
  styleUrls: ['service-options-inquiry.component.scss'],
  templateUrl: 'service-options-inquiry.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    HeaderModule,
    InfoBannerComponent,
    AokTableModule,
    AokNotificationBannerModule,
    A11yUtilsModule,
    RequiredMarkModule,
    AokInputFieldModule,
    AokCheckboxModule,
    ReactiveFormsModule,
    AokPopoverComponent,
    AokPopoverContentTemplateComponent,
    ServiceRequestFormComponent,
    FeedbackButtonModule,
    AokFooterModule,
  ],
})
export class ServiceOptionsInquiryComponent extends ServicePageComponent {
  public dmpPopoverList: string[] = [];

  public headerHint = 'Bitte geben Sie die Versichertendaten ein, um die ausgewählten Informationen abzufragen.';
  public dmpStatusPopoverHeader: string;
  public membershipStatusPopover =
    'Der Mitgliedschaftsstatus wird immer mit abgefragt und kann daher nicht abgewählt werden.';

  public masterCheckbox = new FormControl(false);

  public serviceOptionsForm = new UntypedFormGroup({
    dmpStatus: new FormControl(false),
    careLevel: new FormControl(false),
    paymentExemption: new FormControl(false),
    membershipStatus: new FormControl({ value: true, disabled: true }),
  });

  public resultTableData: TableData[] = [];

  get isDmpAllowed(): boolean {
    return this.dmpPopoverList?.length > 0;
  }

  constructor(
    protected client: AokInsuranceStatusClient,
    protected errorBannerService: ErrorBannerService,
    protected cd: ChangeDetectorRef,
    private dmpProgramsService: DmpProgramsService,
    private currentUser: CurrentUserState,
    private route: ActivatedRoute,
    private contextState: ContextState,
    private matomoService: MatomoService
  ) {
    super(client, errorBannerService, cd);
    this.setupDmpData();
    this.setupMasterCheckbox();
    this.setupServiceOptionCheckboxes();
  }

  public handlePatientFormData(request: AokServiceRequest): void {
    const mappedOptions = this.getSelectedServices();
    this.matomoService.setMatomoServiceSearchVariable(mappedOptions);

    this.previousPatientSearchData = request;

    this.client
      .getFullInsuranceStatus({
        ...request,
        insuranceDataRequestTypeList: mappedOptions,
        lanr: this.getLanr(),
      })
      .subscribe({
        next: this.handleResponse(),
        error: this.handleError(),
      });
  }

  public handleCardReaderError(error: AokCardReaderError): void {
    this.handleErrorBanner([{ id: 'cardReaderError', label: error.ErrorMsg }]);
  }

  public prepareResultTableData(): void {
    this.headerHint = null;
    const serviceOptionsTable = this.getServiceOptionsTable();

    this.resultTableData = [
      {
        sectionLabel: ServicePagesLabels.PATIENT_DATA_SECTION,
        table: getPersonalResultTable(this.response.insuree),
      },
      {
        sectionLabel: ServicePagesLabels.INSURANCE_DATA_SECTION,
        table: getInsuranceDataTable(this.response.insuree, false),
      },
    ];

    if (serviceOptionsTable.length !== 0) {
      this.resultTableData.push({
        sectionLabel: ServicePagesLabels.REQUEST_OPTIONS_STATUS,
        table: serviceOptionsTable,
      });
    }
    this.cd.markForCheck();

    scrollToTop();
  }

  public handleRadioOptionChanged(searchType: AokPatientSearchTypeEnum): void {
    this.patientSearchType = searchType;
    this.cd.markForCheck();
  }

  private getServiceOptionsTable(): TableTypeRow[] {
    const table: TableTypeRow[] = [];

    if (this.response.dmpResponse) {
      const insureePrograms = this.response.dmpResponse.insureePrograms.map((dmp) => dmp.indicationLong);
      table.push({
        id: 'dmpStatus',
        label: 'DMP-Teilnahme',
        value: insureePrograms.length
          ? insureePrograms.join('\n')
          : 'Der Patient nimmt an keinem für Ihre Fachgruppe relevanten Disease-Management-Programm (DMP) teil.',
        popoverHeader: this.dmpStatusPopoverHeader,
        popoverList: this.dmpPopoverList,
      });
    }

    if (this.response.careLevelResponse) {
      const careLevel = this.response.careLevelResponse.careLevel;
      table.push({
        id: 'careLevel',
        label: 'Pflegegrad',
        value: careLevel ? 'Pflegegrad ' + careLevel.toString() : 'Es liegt kein Pflegegrad vor.',
      });
    }

    if (this.response.exemptionResponse) {
      const exempted = this.response.exemptionResponse.exempted;
      table.push({
        id: 'exemptStatus',
        label: 'Zuzahlungsbefreiung',
        value: exempted ? 'befreit' : 'nicht befreit',
      });
    }

    return table;
  }

  private setupDmpData(): void {
    const currentUserDmpPrograms = this.route.snapshot.data['programs'];
    if (currentUserDmpPrograms?.length > 0) {
      this.mapDmpProgramsLongIndication(currentUserDmpPrograms);
      this.resetDmpHandling();
    } else {
      this.handleNoDmpPrograms();
    }
    this.handleDmpOnContextChange();
  }

  private mapDmpProgramsLongIndication(programs: AokDMP[]): void {
    this.dmpPopoverList = programs.map((dmp) => dmp.indicationLong);
    this.cd.markForCheck();
  }

  private handleDmpOnContextChange(): void {
    this.dmpProgramsService
      .getDmpProgramsOnContextChange()
      .pipe(takeUntilDestroyed())
      .subscribe((data) => {
        this.reload();
        this.mapDmpProgramsLongIndication(data);

        if (data?.length === 0) {
          this.handleNoDmpPrograms();
        } else {
          this.resetDmpHandling();
        }
      });
  }

  private handleNoDmpPrograms(): void {
    this.dmpStatusPopoverHeader =
      'Aufgrund Ihrer Fachgruppe haben Sie keine Berechtigung, den DMP-Status Ihrer Patienten abzufragen.';
    const dmpStatus = this.serviceOptionsForm.controls.dmpStatus;
    dmpStatus.setValue(false);
    dmpStatus.disable();
    this.cd.markForCheck();
  }

  private resetDmpHandling(): void {
    this.dmpStatusPopoverHeader =
      'Aufgrund des hinterlegten Fachgruppenschlüssels haben Sie die Berechtigung zur Anzeige folgender DMP:';
    this.serviceOptionsForm.controls.dmpStatus.enable();
    this.cd.markForCheck();
  }

  /**
   * Sets up subscription to changes in the master checkbox (`allOptions`).
   * Propagates the master state to individual service option checkboxes.
   */
  private setupMasterCheckbox(): void {
    this.masterCheckbox.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.setIndividualCheckboxes(value);
    });
  }

  /**
   * Sets the value of individual service option checkboxes based on the master checkbox value.
   *
   * @param value - The value of the master checkbox.
   */
  private setIndividualCheckboxes(value: boolean): void {
    Object.keys(this.serviceOptionsForm.controls)
      .filter((key) => !(this.serviceOptionsForm.get(key) as FormControl).disabled)
      .forEach((key) => (this.serviceOptionsForm.get(key) as FormControl).setValue(value, { emitEvent: false }));
  }

  /**
   * Sets up subscription to changes in individual service option checkboxes.
   * Updates the master checkbox accordingly to reflect the overall state of the group.
   *
   */
  private setupServiceOptionCheckboxes(): void {
    this.serviceOptionsForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      const masterCheckboxValue = Object.keys(this.serviceOptionsForm.controls)
        .filter((key) => !(this.serviceOptionsForm.get(key) as FormControl).disabled)
        .every((key) => this.serviceOptionsForm.get(key).value);
      this.masterCheckbox.patchValue(masterCheckboxValue, { emitEvent: false });
    });
  }

  private getLanr(): string {
    return isDoctor(this.currentUser.snapshot) ? this.currentUser.lanr9 : this.contextState.practitionerLanr9;
  }

  private getSelectedServices(): string[] {
    const options = this.serviceOptionsForm.getRawValue();

    return Object.entries(options)
      .filter(([, value]) => value)
      .map(([key]) => INSURANCE_DATA_REQUEST_TYPES[key]);
  }
}
