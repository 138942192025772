import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MatCalendar, MatCalendarHeader, MatDatepickerIntl } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aok-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerHeaderComponent extends MatCalendarHeader<any> implements OnDestroy {
  private ngDestroyed: Subject<void>;

  constructor(
    calendar: MatCalendar<any>,
    private dateAdapter: DateAdapter<any>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private cd: ChangeDetectorRef,
    private intl: MatDatepickerIntl
  ) {
    super(intl, calendar, dateAdapter, dateFormats, cd);
    this.ngDestroyed = new Subject<void>();
    this.calendar.stateChanges.pipe(takeUntil(this.ngDestroyed)).subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  get periodButtonText(): string {
    if (this.calendar.currentView === 'year') {
      const month = this.calendar.activeDate.getMonth();
      return this.dateAdapter.getMonthNames('long')[month];
    }

    return super.periodButtonText;
  }

  get isMonthView(): boolean {
    return this.calendar.currentView === 'month';
  }
}
