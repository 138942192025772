import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KvnInfo, RequiredTask } from '@aok/common';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { KvnService } from '../kvn.service';

@Injectable()
export class KvnErrorRedirectTaskService implements RequiredTask {
  constructor(private router: Router, private kvnService: KvnService) {}

  trigger(): Observable<boolean> {
    return this.hasError().pipe(
      mergeMap((redirect) => {
        if (redirect) {
          this.redirect().subscribe();
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }

  public redirect(): Observable<boolean> {
    return from(this.router.navigate(['/kvn-fehler']));
  }

  public hasError(): Observable<boolean> {
    return this.kvnService.getKvnInfo().pipe(map((kvnInfo: KvnInfo) => kvnInfo.isKvnWithoutAccess));
  }
}
