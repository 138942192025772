import { APP_BOOTSTRAP_LISTENER, Provider } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { lastValueFrom } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { AokOrgClient } from '../../clients';
import { AokUser } from '../../schemas';
import { CurrentUserState } from '../../states/current-user.state';
import { isKvnAssistant, isKvnOrFullDoctor } from '../../utils';

export function initAokCurrentUser(keycloak: KeycloakService, currentUser: CurrentUserState, orgClient: AokOrgClient) {
  return (): Promise<AokUser> =>
    // TODO maybe user service loadKeycloakInstance case be used
    lastValueFrom(
      keycloak.keycloakEvents$.pipe(
        // wait for until keycloak is ready, then start initializing the active user data, if we don't wait for the
        // ready event here we wouldn't have a valid session to fetch the active user provider with
        filter((e) => e.type === KeycloakEventType.OnReady),
        take(1),
        mergeMap(() => currentUser.retrieveCurrentUser()),
        tap((user) => {
          if (isKvnOrFullDoctor(user?.userType) || isKvnAssistant(user?.userType)) {
            orgClient.updatePracticeData().subscribe();
          }
        })
      )
    );
}

export const AOK_CURRENT_USER_INIT_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: initAokCurrentUser,
  deps: [KeycloakService, CurrentUserState, AokOrgClient],
  multi: true,
};
