<div class="aok-container">
  <aok-cockpit-search-bar
    class="tw-col-1-11 tw-mt-5"
    (submitted)="handleSearchAndFilter($event)"
    (loadSuggestions)="retrieveSearchSuggestions($event)"
    [suggestions]="searchSuggestions"
    [isHeaderSearch]="false"
    [label]="'Was suchen Sie?'"
    [placeholder]="'Diagnose oder ICD Code eingeben'"
  ></aok-cockpit-search-bar>

  <aok-filter-button
    class="tw-col-11-13 tw-h-fit tw-self-end"
    [isAreaOpen]="isOpenFilterArea"
    [counter]="filterCounter"
    (buttonClick)="isOpenFilterArea = !isOpenFilterArea"
  >
  </aok-filter-button>

  <aok-filter-area
    class="tw-col-1-13"
    [isOpen]="isOpenFilterArea"
    [hasSelection]="filterCounter > 0"
    (resetFilters)="handleSearchAndFilter({ category: '', region: '' })"
  >
    <div class="tw-col-2-7">
      <aok-input-field label="Kategorie">
        <aok-dropdown
          [mode]="dropdownMode"
          [control]="filterForm.get('category')"
          [options]="categoryOptions"
        ></aok-dropdown>
      </aok-input-field>
    </div>

    <div class="tw-col-7-12">
      <aok-input-field label="Region">
        <aok-dropdown
          [mode]="dropdownMode"
          [control]="filterForm.get('region')"
          [options]="regionOptions"
        ></aok-dropdown>
      </aok-input-field>
    </div>
  </aok-filter-area>

  <div *ngIf="searchActive" class="tw-col-1-13 tw-my-4 tw-flex tw-items-center">
    <h5 class="tw-mr-1">{{ totalElements | i18nPlural : pluralMapping }} gefunden</h5>
    <a
      class="aok-link-1"
      (click)="handleSearchAndFilter({ query: '', category: '', region: '' })"
      (keyup.enter)="handleSearchAndFilter({ query: '', category: '', region: '' })"
    >
      Alle anzeigen
    </a>
  </div>
</div>
