import { Injectable } from '@angular/core';
import { AokConsent, ArrayBehaviorState } from '@aok/common';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConsentState extends ArrayBehaviorState<AokConsent> {
  constructor() {
    super();
  }

  public latestConsentAccepted$(): Observable<boolean> {
    return this.asObservable().pipe(
      filter((consents) => !!consents.length),
      map((consents) => consents.every((consent) => consent.latestApprovedVersion))
    );
  }

  public updateConsent(consent: AokConsent): void {
    this.patch(
      [consent],
      this.snapshot.findIndex((existingConsent) => existingConsent.id === consent.id)
    );
  }
}
