<dialog-layout
  fullscreen="true"
  cdkTrapFocus
  aokFocusFirstChild
  isClosable="true"
  [headerText]="'Ergänzende Registrierung im AOK-Arztportal'"
  [main]="false"
>
  <div class="aok-container">
    <div class="tw-mb-6 tw-col-2-12">
      {{ secondFactorAuthHint }}
    </div>

    <div class="tw-mb-6 tw-col-2-12">
      Ihre bereits über den KVN-Zugang hinterlegten Daten werden übernommen. Sie müssen nur eine
      Zwei-Faktor-Authentifizierung und anschließend Ihr Passwort festlegen. Die wichtigsten Informationen rund um die
      direkte Anmeldung zum AOK-Arztportal erhalten Sie zusätzlich per E-Mail.
    </div>

    <ng-container *ngIf="errorIds.length">
      <ng-container *ngTemplateOutlet="userMessages"></ng-container>
    </ng-container>

    <div class="tw-mb-8 tw-col-2-12 required-label">
      <aok-required-mark></aok-required-mark>
      Pflichtfeld
    </div>

    <h5 class="tw-mb-7 tw-col-2-12">Zwei-Faktor-Authentifizierung</h5>

    <aok-second-factor-auth-options
      class="tw-col-2-12"
      [form]="form"
      [formLabels]="formLabels"
      [isCardOrderingEnabled]="isCardOrderingEnabled"
    >
    </aok-second-factor-auth-options>

    <div class="tw-col-2-12 tw-mt-4">
      <button (click)="submit()">Daten übermitteln</button>
    </div>
  </div>
</dialog-layout>

<ng-template #userMessages>
  <div class="tw-col-2-12 tw-mb-7">
    <aok-notification-banner
      accent="bad"
      [bannerHeadline]="bannerHeadline"
      [bannerLabel]="bannerLabel"
      [bannerLinks]="errorIds"
    >
    </aok-notification-banner>
  </div>
</ng-template>
