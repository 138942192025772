import { InjectionToken, Provider } from '@angular/core';
import { KnownRoles, PAGE_FOOTER_COMPONENT, PAGE_HEADER_COMPONENT } from '@aok/common';
import { AOK_A11Y_LINKS } from '../components/a11y-links/a11y-links.component';
import { AokFooterComponent } from '../components/footer/footer.component';
import { HeaderComponent } from '../components/header/header.component';
import { AokA11yLink } from '../schema/a11y-link.schema';
import { AokFooterContact, AokFooterLink, AokFooterLinkGroup } from '../schema/footer.schema';
import { AokCookieService } from '../services/cookie/cookie.service';

export const AOK_FOOTER_LINKS = new InjectionToken<AokFooterLink[]>('AOK_FOOTER_LINKS');
export const AOK_FOOTER_CONTACT = new InjectionToken<AokFooterContact>('AOK_FOOTER_CONTACT');
export const AOK_FOOTER_LINK_GROUPS = new InjectionToken<AokFooterLinkGroup[]>('AOK_FOOTER_LINK_GROUPS');

export const APP_PAGE_HEADER_PROVIDER: Provider = {
  provide: PAGE_HEADER_COMPONENT,
  useValue: HeaderComponent,
};

export const APP_PAGE_FOOTER_PROVIDER: Provider = {
  provide: PAGE_FOOTER_COMPONENT,
  useValue: AokFooterComponent,
};

export const APP_A11Y_LINKS_PROVIDER: Provider = {
  provide: AOK_A11Y_LINKS,
  useValue: [
    {
      alt: 'Leichte Sprache',
      icon: 'easy-language',
      url: '/leichte-sprache',
    },
    {
      alt: 'Gebärdensprache',
      icon: 'sign-language',
      url: '/gebaerdensprache',
    },
  ] as AokA11yLink[],
};

export const footerPresetBaseGroups = [
  { key: 'a18y', displayTitle: 'Barrierefreiheit' },
  { key: 'legal', displayTitle: 'Rechtliches' },
  { key: 'ref', displayTitle: 'Ihre AOK' },
] as AokFooterLinkGroup[];

export const footerContactBase = {
  displayTitle: 'Sie haben Fragen oder Feedback zum Arztportal?',
  email: {
    title: 'Support kontaktieren',
    value: 'aok-arztportal@nds.aok.de',
  },
  tel: '0511 285-34679',
} as AokFooterContact;

export const getFooterPresetBaseLinks = (cookieConsent: AokCookieService): AokFooterLink[] => {
  return [
    {
      name: 'Erklärung zur Barrierefreiheit',
      url: '/erklaerung-zur-barrierefreiheit',
      group: 'a18y',
      testId: 'footer-accessibility',
    },
    {
      name: 'Leichte Sprache',
      url: '/leichte-sprache',
      group: 'a18y',
      testId: 'footer-easy-speech',
    },
    {
      name: 'Gebärdensprache',
      url: '/gebaerdensprache',
      group: 'a18y',
      testId: 'footer-sign-language',
    },
    {
      name: 'Barriere melden',
      url: '/barriere-melden',
      group: 'a18y',
      requiresAuthentication: true,
      roles: [KnownRoles.HealthcareProfessional, KnownRoles.Assistant],
    },
    {
      name: 'Nutzungsbedingungen',
      url: '/nutzungsbedingungen',
      group: 'legal',
      testId: 'footer-terms-of-usage',
    },
    {
      name: 'Datenschutz',
      url: '/datenschutz',
      group: 'legal',
      testId: 'footer-data-privacy',
    },
    {
      name: 'Impressum',
      url: '/impressum',
      group: 'legal',
      testId: 'footer-imprint',
    },
    {
      name: 'Cookie-Einstellungen',
      group: 'legal',
      callback: () => cookieConsent.openCookieDialog(),
    },
    {
      name: 'Gesundheitspartnerportal',
      url: 'https://www.aok.de/gp/?region=niedersachsen',
      group: 'ref',
    },
    {
      name: 'aok.de',
      url: 'https://www.aok.de/pk/niedersachsen/',
      group: 'ref',
    },
  ] as AokFooterLink[];
};

export const footerInfoLinks: AokFooterLink[] = [
  {
    name: 'Nutzung des Arztportals',
    group: 'infos',
    url: '/hilfe/arztportal-nutzung',
    requiresAuthentication: true,
  },
  {
    name: 'Hilfe',
    group: 'infos',
    url: '/hilfe/allgemeine-informationen',
    requiresAuthentication: true,
  },
  {
    name: 'Sitemap',
    group: 'infos',
    url: '/sitemap',
    requiresAuthentication: true,
    roles: [KnownRoles.HealthcareProfessional, KnownRoles.Assistant],
  },
];

export const footerInfoGroup: AokFooterLinkGroup = { key: 'infos', displayTitle: 'Informationen' };
