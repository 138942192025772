<dialog-layout
  aokFocusFirstChild
  cdkTrapFocus
  fullscreen="true"
  [headerText]="'KVN-Zugang für AOK-Arztportal einrichten'"
  [main]="false"
  [isClosable]="true"
  [autoClose]="false"
  [autoBlur]="true"
  (dispose)="closeDialog()"
>
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup">
      <aok-stepper [navMode]="NAV_MODE.FREE_SEQUENTIAL">
        <!--        TODO check error handling on add practices component and adjust this one if it can be done simpler-->
        <div *ngIf="isErrorBannerEnabled && errorState" id="errorMessages" class="userMessages">
          <ng-container [ngSwitch]="errorState">
            <aok-notification-banner
              *ngSwitchCase="errorStateEnum.multiError"
              id="error_validation_multi"
              accent="bad"
              bannerHeadline="Es sind mehrere Fehler aufgetreten."
              bannerLabel="Bitte überprüfen Sie Ihre Eingabe."
              [bannerLinks]="bannerLinks"
            >
            </aok-notification-banner>
            <aok-notification-banner
              *ngSwitchCase="errorStateEnum.error"
              id="error_validation_one"
              accent="bad"
              bannerHeadline="Es ist ein Fehler aufgetreten"
              bannerLabel="Bitte überprüfen Sie Ihre Eingabe."
              [bannerLinks]="bannerLinks"
            >
            </aok-notification-banner>
          </ng-container>
        </div>

        <aok-step [title]="'Persönliche Daten'" [iconName]="'0284-profile'">
          <div formGroupName="userData" id="personalDataForm" class="grid-10 lg:grid-8">
            <p class="aok-bold tw-col-1-11 lg:tw-col-1-9">
              Nachdem der KVN-Zugang eingerichtet wurde, können Sie die vorbelegten Daten im Arztportal ändern.
            </p>

            <p class="mandatory-hint required-label tw-col-1-9">* Pflichtfeld</p>
            <h5 class="tw-col-1-11 lg:tw-col-1-9">Persönliche Daten</h5>
            <aok-input-field #userData label="Anrede" required="true" class="tw-col-1-4">
              <aok-dropdown
                id="salutation"
                [control]="formGroup.get('userData.salutation')"
                [options]="salutationOptions"
              ></aok-dropdown>
            </aok-input-field>
            <aok-input-field #userData label="Titel" class="tw-col-4-8 lg:tw-col-4-7">
              <input id="title" formControlName="title" aokTrim />
            </aok-input-field>
            <aok-input-field #userData label="Vorname" required="true" class="tw-col-1-8 lg:tw-col-1-7">
              <input id="firstName" formControlName="firstName" aokTrim />
            </aok-input-field>
            <aok-input-field #userData label="Nachname" required="true" class="tw-col-1-8 lg:tw-col-1-7">
              <input id="lastName" formControlName="lastName" aokTrim />
            </aok-input-field>

            <div class="radio-with-popover tw-col-1-6 lg:tw-col-1-5">
              <aok-input-field #userData label="Rechtlicher Status" required="true">
                <aok-radio id="doctorType" [options]="doctorTypes" formControlName="doctorType"></aok-radio>
              </aok-input-field>
            </div>

            <aok-input-field #userData label="LANR" required="true" class="tw-col-1-8 lg:tw-col-1-7">
              <input id="lanr" formControlName="lanr" />
            </aok-input-field>

            <aok-notification-banner
              *ngIf="displaySpecialization()"
              class="specialization tw-col-1-8 lg:tw-col-1-7"
              bannerHeadline="Fachgruppe"
              iconName="info-circle"
              [bannerLabel]="specializationControlValue"
            >
            </aok-notification-banner>

            <h5 class="distance-h5 tw-col-1-11 lg:tw-col-1-9">Kontaktdaten</h5>

            <p class="tw-col-1-7 tw-mt-6">
              Jede E-Mail-Adresse kann nur von einem Nutzer verwendet werden. Nutzen Sie eine Adresse, zu der nur Sie
              persönlich Zugang haben.
            </p>

            <aok-input-field #userData label="E-Mail" class="tw-col-1-8 lg:tw-col-1-7" [required]="true">
              <input id="email" formControlName="email" />
            </aok-input-field>

            <aok-input-field
              *ngIf="!isRegisteredDoctor"
              #userData
              label="E-Mail Wiederhohlung"
              class="tw-col-1-8 lg:tw-col-1-7"
              [required]="true"
            >
              <input id="emailRepeat" formControlName="emailRepeat" />
            </aok-input-field>

            <aok-input-field #userData label="Telefonnummer" class="tw-col-1-8 lg:tw-col-1-7">
              <input id="mobilePhone" formControlName="mobilePhone" aokTrim />
            </aok-input-field>

            <h5 class="distance-h5 tw-mb-7 tw-col-1-11 lg:tw-col-1-9">Zugriffsberechtigung</h5>
            <div class="tw-col-1-9">
              <p class="tw-mb-8">
                Legen Sie fest, ob Ihre Praxismitarbeiter über das KVN-Portal auf das AOK-Arztportal zugreifen dürfen.
                Über diesen Zugriffsweg erhalten die Praxismitarbeiter für die Dauer des Zugriffs ein gemeinschaftliches
                Benutzerkonto. Sie können diesen Zugriffsweg nur für alle Praxismitarbeiter gemeinsam festlegen.
              </p>
              <p>
                Diese Funktion setzt eine bereits erfolgte Berechtigung des Praxisteams im KVN-Portal (KVN-PINCard
                Personal) voraus.
              </p>
            </div>

            <aok-input-field id="practiceAccountControl" class="tw-col-1-9">
              <aok-checkbox
                [label]="'Praxismitarbeiter dürfen auf das AOK-Arztportal zugreifen'"
                formControlName="kvnPracticeAccountControl"
              ></aok-checkbox>
            </aok-input-field>
          </div>
        </aok-step>

        <ng-container *ngFor="let control of getOrgControls(); let i = index">
          <aok-step
            #practiceDataStep
            [title]="
              formGroup.get(['organizationDataList', i, 'organizationName'])?.value?.trim() || 'Daten Praxis ' + (i + 1)
            "
            [iconName]="'0000-hospital'"
            #step="step"
          >
            <div formArrayName="organizationDataList" *ngIf="step.active">
              <div [formGroupName]="i" id="practiceDataForm" class="grid-10 lg:grid-8">
                <p class="aok-bold tw-col-1-11 lg:tw-col-1-9">
                  Nachdem der KVN-Zugang eingerichtet wurde, können Sie die vorbelegten Daten im Arztportal ändern.
                </p>
                <p class="mandatory-hint required-label tw-col-1-9">* Pflichtfeld</p>
                <h5 class="tw-col-1-11 lg:tw-col-1-9">Praxisdaten</h5>

                <aok-input-field
                  #orgData
                  label="Betriebsstättennummer"
                  required="true"
                  class="tw-col-1-8 lg:tw-col-1-7"
                >
                  <input id="bsnr" formControlName="bsnr" />
                </aok-input-field>

                <aok-input-field #orgData label="Art der Praxis" required="true" class="tw-col-1-8 lg:tw-col-1-7">
                  <aok-dropdown
                    id="organizationType"
                    [control]="formGroup.get(['organizationDataList', i, 'organizationType'])"
                    [options]="orgTypeOptions"
                  ></aok-dropdown>
                </aok-input-field>

                <div class="form-item-with-popover tw-col-1-8 lg:tw-col-1-7">
                  <aok-input-field #orgData label="Name der Praxis" required="true">
                    <input id="organizationName" formControlName="organizationName" aokTrim />
                  </aok-input-field>
                  <aok-popover
                    *ngIf="!formGroup.get(['organizationDataList', i, 'organizationName']).disabled"
                    width="384"
                    [direction]="POPOVER_DIRECTION.RightTop"
                    [xaxis]="3"
                    [yaxis]="-2"
                  >
                    <aok-svg-icon [name]="'question-circle_filled'" size="20" aok-anchor></aok-svg-icon>
                    <p>Wenn es keinen expliziten Praxisnamen gibt, geben Sie bitte den Namen des Arztes ein.</p>
                  </aok-popover>
                </div>

                <h5 class="distance-h5 tw-col-1-11 lg:tw-col-1-9">Praxisanschrift</h5>
                <ng-container formGroupName="address">
                  <aok-input-field #orgData label="Straße" required="true" class="tw-col-1-7 lg:tw-col-1-6">
                    <input id="streetName" formControlName="streetName" aokTrim />
                  </aok-input-field>
                  <aok-input-field #orgData label="Hausnummer" required="true" class="tw-col-7-11 lg:tw-col-6-9">
                    <input id="streetNumber" formControlName="streetNumber" />
                  </aok-input-field>
                  <aok-input-field #orgData label="PLZ" required="true" class="tw-col-1-5 lg:tw-col-1-4">
                    <input id="zipCode" formControlName="zipCode" />
                  </aok-input-field>
                  <aok-input-field #orgData label="Ort" required="true" class="tw-col-5-11 lg:tw-col-4-9">
                    <input id="location" formControlName="location" aokTrim />
                  </aok-input-field>
                </ng-container>
              </div>
            </div>
          </aok-step>
        </ng-container>

        <aok-step [title]="'Zusammenfassung'" [iconName]="'0000-checklist'">
          <div class="summaryData grid-10 lg:grid-8">
            <div class="tw-col-1-11 lg:tw-col-1-9 tw-flex tw-justify-between tw-items-center tw-pb-7">
              <h5>Persönliche Daten</h5>
              <a class="aok-link-1" aokFocusable (keyup.enter)="goToStep(0)" (click)="goToStep(0)">Ändern</a>
            </div>
            <div class="divider tw-col-1-11 lg:tw-col-1-9"></div>
            <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Name</p>
            <p id="name" class="tw-col-5-11 lg:tw-col-4-9">
              {{ formGroup.get('userData').get('salutation').value | aokUserSalutation : ['NONE'] }}
              {{ formGroup.get('userData').get('salutation').value === 'NONE' ? '' : ' ' }}
              {{
                formGroup.get('userData').getRawValue()
                  | aokFullName
                    : ' TT FF LL'
                    : formGroup.get('userData').value?.practitionerResource ||
                        formGroup.get('userData').value?.practitionerRequest
              }}
            </p>
            <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Rechtlicher Status</p>
            <p id="status" class="tw-col-5-11 lg:tw-col-4-9">
              {{ formGroup.get('userData').get('doctorType').value | aokDoctorType }}
            </p>
            <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">LANR</p>
            <p id="LANR" class="tw-col-5-11 lg:tw-col-4-9">
              {{ formGroup.get('userData').get('lanr').value }}
            </p>
            <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Fachgruppenschlüssel</p>
            <p id="departmentKey" class="tw-col-5-11 lg:tw-col-4-9">
              {{ specializationControlValue }}
            </p>
            <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">E-Mail</p>
            <p id="organizationEmail" class="tw-col-5-11 lg:tw-col-4-9">
              {{ formGroup.get('userData').get('email').value }}
            </p>
            <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Telefonnummer</p>
            <p id="phone" class="tw-col-5-11 lg:tw-col-4-9">
              {{ formGroup.get('userData').get('mobilePhone').value }}
            </p>
            <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Zugriffsberechtigung Praxismitarbeiter</p>
            <p id="kvnPracticeAccountControl" class="tw-col-5-11 lg:tw-col-4-9">
              {{ formGroup.get('userData').get('kvnPracticeAccountControl').value ? 'Zugriff' : 'kein Zugriff' }}
            </p>

            <ng-container *ngFor="let control of getOrgControls(); let i = index">
              <div class="tw-col-1-11 lg:tw-col-1-9 tw-flex tw-justify-between tw-items-center tw-pb-7">
                <h5>
                  {{
                    formGroup.get(['organizationDataList', i, 'organizationName'])?.value?.trim() ||
                      'Daten Praxis ' + (i + 1)
                  }}
                </h5>
                <a class="aok-link-1" aokFocusable (keyup.enter)="goToStep(i + 1)" (click)="goToStep(i + 1)">Ändern</a>
              </div>
              <div class="divider tw-col-1-11 lg:tw-col-1-9"></div>
              <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Betriebsstätennummer</p>
              <p id="bsnr" class="tw-col-5-11 lg:tw-col-4-9">
                {{ formGroup.get(['organizationDataList', i, 'bsnr']).value }}
              </p>
              <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Art der Praxis</p>
              <p id="organizationType" class="tw-col-5-11 lg:tw-col-4-9">
                {{ formGroup.get(['organizationDataList', i, 'organizationType']).value | aokOrgType }}
              </p>
              <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Name der Praxis</p>
              <p id="organizationName" class="tw-col-5-11 lg:tw-col-4-9">
                {{ formGroup.get(['organizationDataList', i, 'organizationName']).value }}
              </p>
              <p class="aok-bold tw-col-1-5 lg:tw-col-1-4">Praxisanschrift</p>
              <p id="organizationAddress" class="tw-col-5-11 lg:tw-col-4-9">
                {{ formGroup.get(['organizationDataList', i, 'address', 'streetName']).value }}
                {{ formGroup.get(['organizationDataList', i, 'address', 'streetNumber']).value }},
                {{ formGroup.get(['organizationDataList', i, 'address', 'zipCode']).value }}
                {{ formGroup.get(['organizationDataList', i, 'address', 'location']).value }}
              </p>
            </ng-container>
          </div>
        </aok-step>
      </aok-stepper>
    </form>
  </ng-container>
</dialog-layout>
