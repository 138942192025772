import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AokBenefit,
  AokBenefitCategory,
  AokBenefitListOptions,
  AokBenefitRegion,
  AokBenefitResponse,
  AokPage,
} from '../schemas';
import { createBenefitFormData } from '../utils';
import { AokBaseBenefitsClient } from './base-benefits.client';

@Injectable({ providedIn: 'root' })
export class AokBenefitClient extends AokBaseBenefitsClient {
  list(options?: AokBenefitListOptions): Observable<AokPage<AokBenefitResponse>> {
    return this.http.get<AokPage<AokBenefitResponse>>(this.resolveUrl(), {
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }

  delete(id: NonNullable<number>): Observable<void> {
    return this.http.delete<void>(this.resolveUrl(id.toString()));
  }

  find(id: NonNullable<number>): Observable<AokBenefitResponse> {
    return this.http.get<AokBenefitResponse>(this.resolveUrl(id.toString()));
  }

  create(benefit: AokBenefit, benefitDocuments?: File[]): Observable<AokBenefitResponse> {
    return this.http.post<AokBenefitResponse>(this.resolveUrl(), createBenefitFormData(benefit, benefitDocuments));
  }

  update(id: number, benefit: AokBenefit, benefitDocuments?: File[]): Observable<AokBenefitResponse> {
    return this.http.put<AokBenefitResponse>(
      this.resolveUrl(id.toString()),
      createBenefitFormData(benefit, benefitDocuments)
    );
  }

  downloadDocument(benefitId: number, documentId: number): Observable<Blob> {
    return this.http.get(this.resolveUrl('download', benefitId.toString(), documentId.toString()), {
      responseType: 'blob',
    });
  }

  toggleFavorite(id: number): Observable<AokPage<AokBenefitResponse>> {
    return this.http.patch<AokPage<AokBenefitResponse>>(this.resolveUrl(id.toString(), 'favorite'), null);
  }

  getCategories(): Observable<AokBenefitCategory[]> {
    return this.http.get<AokBenefitCategory[]>(this.resolveUrl('categories'));
  }

  getRegions(): Observable<AokBenefitRegion[]> {
    return this.http.get<AokBenefitRegion[]>(this.resolveUrl('regions'));
  }

  updateCategories = (categories: AokBenefitCategory[]): Observable<AokBenefitCategory[]> => {
    return this.http.post<AokBenefitCategory[]>(this.resolveUrl('categories', 'bulk'), categories);
  };

  updateRegions = (regions: AokBenefitRegion[]): Observable<AokBenefitRegion[]> => {
    return this.http.post<AokBenefitRegion[]>(this.resolveUrl('regions', 'bulk'), regions);
  };
}
