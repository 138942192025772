import { APP_BOOTSTRAP_LISTENER, Provider } from '@angular/core';
import { SessionExpiryService } from '@aok/components';

export function initSSOSessionTime(pendingExpirationSSOSessionService: SessionExpiryService) {
  return (): void => pendingExpirationSSOSessionService.init();
}

export const AOK_PENDING_EXPIRATION_SSO_SESSION_INIT_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: initSSOSessionTime,
  deps: [SessionExpiryService],
  multi: true,
};
