import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { CONTROL_ERROR_PARAMS, isString } from '@aok/common';

@Component({
  selector: 'aok-email-error-message',
  encapsulation: ViewEncapsulation.None,
  template: `
    <ng-container *ngIf="!hasMessageParams(); else messageTemplate">
      <aok-error-message>Ihre E-Mail-Adresse hat ein falsches Format.</aok-error-message>
    </ng-container>
    <ng-template #messageTemplate>
      <aok-error-message>{{ params }}</aok-error-message>
    </ng-template>
  `,
})
export class AokEmailErrorMessageComponent {
  hasMessageParams(): boolean {
    return isString(this.params);
  }

  constructor(@Inject(CONTROL_ERROR_PARAMS) readonly params: any) {}
}
