import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  A11yUtilsModule,
  AokRemoveDateModule,
  AokRemoveFileExtensionPipe,
  AokRouteDataPipeModule,
  AokSvgIconComponent,
  FeedbackCategory,
} from '@aok/common';
import {
  AokBreadcrumbsModule,
  AokFooterModule,
  AokMenuButtonModule,
  AokNotificationBannerModule,
  AokObjectUrlPipeModule,
  AokPaginationModule,
  AokShareDialog,
  AokStatusDotModule,
  AokTabsModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
} from '@aok/components';
import { REPORTS_CATEGORY_LABELS } from '../../config/reports.config';
import { noReportsResolver } from '../../resolvers/no-reports.resolver';
import { reportsResolver } from '../../resolvers/reports.resolver';
import { NoReportsComponent } from './no-reports.component';
import { ReportsTableComponent } from './reports-table/reports-table.component';
import { ReportsComponent } from './reports.component';

export const APP_REPORTS_ROUTES: Routes = [
  {
    path: '',
    component: ReportsComponent,
    data: {
      category: FeedbackCategory.BERICHTE,
      browserTitle: 'Alle Berichte als Übersicht',
      title: 'Berichte',
      interimRoute: true,
    },
    children: [
      {
        path: 'uebersicht',
        component: NoReportsComponent,
        resolve: {
          reports: noReportsResolver,
        },
        data: {
          sitemap: false,
          browserTitle: 'Keine aktuellen Berichte zur Verfügung.',
        },
      },
      {
        path: ':category',
        component: ReportsTableComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          reports: reportsResolver,
        },
        data: {
          sitemap: false,
          browserTitle: 'Alle Berichte als Übersicht',
          title: (data: { category: string | number }) => REPORTS_CATEGORY_LABELS[data.category],
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'arzneimittel',
      },
    ],
  },
];

@NgModule({
  declarations: [ReportsComponent, ReportsTableComponent, NoReportsComponent],
  exports: [ReportsComponent],
  imports: [
    RouterModule.forChild(APP_REPORTS_ROUTES),
    CommonModule,
    AokRouteDataPipeModule,
    AokObjectUrlPipeModule,
    AokStatusDotModule,
    AokPaginationModule,
    AokNotificationBannerModule,
    AokSvgIconComponent,
    HeaderModule,
    AokFooterModule,
    AokBreadcrumbsModule,
    AokMenuButtonModule,
    CdkTableModule,
    FeedbackButtonModule,
    AokTabsModule,
    A11yUtilsModule,
    AokRemoveDateModule,
    InfoBannerComponent,
    AokRemoveFileExtensionPipe,
    AokShareDialog,
  ],
})
export class ReportsModule {}
