<div class="aok-container">
  <div class="tw-col-1-13">
    <aok-tabs class="tw-mb-3">
      <aok-tab
        class="tw-flex tw-items-center tw-justify-center tw-grow"
        [class.active]="isActive(tabs.OVERVIEW)"
        aokFocusable
        (click)="navigateTo(tabs.OVERVIEW)"
        (keyup.enter)="navigateTo(tabs.OVERVIEW)"
      >
        Alle Leistungen
      </aok-tab>
      <aok-tab
        class="tw-flex tw-items-center tw-justify-center tw-grow"
        [class.active]="isActive(tabs.FAVORITES)"
        aokFocusable
        (click)="navigateTo(tabs.FAVORITES)"
        (keyup.enter)="navigateTo(tabs.FAVORITES)"
      >
        Favoriten ({{ favoriteCount }}/20)
      </aok-tab>
    </aok-tabs>
  </div>
</div>
