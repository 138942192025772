import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AokToTopScrollerComponent } from './to-top-scroller.component';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';

@NgModule({
  declarations: [AokToTopScrollerComponent],
  exports: [AokToTopScrollerComponent],
  imports: [AokSvgIconComponent, CommonModule, A11yUtilsModule],
})
export class AokToTopScrollerModule {}
