import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionExpiryService } from '@aok/components';

@Injectable()
export class PendingExpirationSessionInterceptor implements HttpInterceptor {
  private ongoingRequests = 0;

  constructor(private pendingExpirationSessionService: SessionExpiryService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.ongoingRequests++;

    return next.handle(request).pipe(
      finalize(() => {
        this.ongoingRequests--;

        if (this.ongoingRequests !== 0) {
          return;
        }

        this.pendingExpirationSessionService.calculateIdleTime();
      })
    );
  }
}
