<aok-input-field [label]="label">
  <div class="search-field">
    <aok-svg-icon class="search-icon" name="search" size="16px"></aok-svg-icon>
    <input
      #searchInput
      type="text"
      [placeholder]="placeholder"
      [(ngModel)]="searchString"
      (ngModelChange)="inputEvent.emit(searchString)"
    />
    <aok-svg-icon
      id="clear-search-icon"
      class="clear-icon icon-button"
      aokFocusable
      *ngIf="searchString"
      size="16"
      name="close"
      (click)="clearSearchHandler()"
      (keyup.enter)="clearSearchHandler()"
    ></aok-svg-icon>
    <button class="search-button" [disabled]="!searchString" (click)="search.emit(searchString)">Suchen</button>
  </div>
</aok-input-field>
