import { APP_BOOTSTRAP_LISTENER, Provider } from '@angular/core';
import { HeaderLayoutState } from '@aok/components';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { lastValueFrom } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';

export function initHeaderLayout(keycloak: KeycloakService, headerLayoutState: HeaderLayoutState) {
  return () =>
    lastValueFrom(
      keycloak.keycloakEvents$.pipe(
        // wait for until keycloak is ready, then start initializing the page layout data, if we don't wait for the
        // ready event here we wouldn't have a valid session to get the user information
        filter((e) => e.type === KeycloakEventType.OnReady),
        take(1),
        mergeMap(() => {
          return headerLayoutState.init();
        })
      )
    );
}

export const AOK_HEADER_LAYOUT_INIT_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: initHeaderLayout,
  deps: [KeycloakService, HeaderLayoutState],
  multi: true,
};
