import { KnownOrgType } from '../../schemas';

export const getOrgTypeText = (orgType: KnownOrgType | string): string => {
  switch (orgType) {
    case KnownOrgType.DoctorPractice:
      return 'Einzelpraxis';
    case KnownOrgType.ProfessionalPracticeAssociation:
      return 'Berufsausübungsgemeinschaft (BAG)/Gemeinschaftspraxis';
    case KnownOrgType.CollaborativeDoctorPractice:
      return 'Praxisgemeinschaft';
    case KnownOrgType.MedicalCareCenter:
      return 'Medizinisches Versorgungszentrum (MVZ)';
    default:
      return orgType || '';
  }
};
