<dialog-layout [headerText]="headerText" fullscreen="false" aokFocusFirstChild [autoBlur]="true" cdkTrapFocus>
  <div>
    <p class="tw-mb-9">
      Legen Sie fest, ob Ihre Praxismitarbeiter über das KVN-Portal auf das AOK-Arztportal zugreifen dürfen. Über diesen
      Zugriffsweg erhalten die Praxismitarbeiter für die Dauer des Zugriffs ein gemeinschaftliches Benutzerkonto. Sie
      können diesen Zugriffsweg nur für alle Praxismitarbeiter gemeinsam festlegen.
    </p>
    <p class="tw-mb-9">
      Diese Funktion setzt eine bereits erfolgte Berechtigung des Praxisteams im KVN-Portal (KVN-PINCard Personal)
      voraus.
    </p>
    <p class="tw-mb-9">
      Diese Einstellung hat keinen Einfluss auf direkt im AOK-Arztportal registrierte Praxismitarbeiter. Diese Nutzer
      können das AOK-Arztportal weiterhin verwenden.
    </p>
    <p class="tw-mb-9">
      Unabhängig von dieser Einstellung ist es auch weiterhin möglich, Praxismitarbeiter mit der Aktion
      „,Praxismitarbeiter hinzufügen" direkt in das AOK-Arztportal einzuladen.
    </p>

    <form [formGroup]="formGroup">
      <aok-input-field>
        <aok-checkbox formControlName="kvnPortalAccess">
          <label> Alle Praxismitarbeiter dürfen vom KVN-Portal aus auf das AOK-Arztportal zugreifen. </label>
        </aok-checkbox>
      </aok-input-field>
    </form>
  </div>

  <dialog-footer>
    <button class="secondary" (click)="cancel()">Abbrechen</button>
    <button type="submit" (click)="submit()">Speichern</button>
  </dialog-footer>
</dialog-layout>
