import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { A11yUtilsModule, AokRouteDataPipeModule, scrollToTop } from '@aok/common';
import {
  AokBreadcrumbsModule,
  AokFooterModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
} from '@aok/components';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aok-cockpit-help-page',
  styleUrls: ['./help-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './help-page.component.html',
  standalone: true,
  imports: [
    CommonModule,
    AokRouteDataPipeModule,
    A11yUtilsModule,
    HeaderModule,
    AokFooterModule,
    AokBreadcrumbsModule,
    FeedbackButtonModule,
    InfoBannerComponent,
  ],
})
export class HelpPage implements OnDestroy {
  readonly pageTitle = this.route.data.pipe(map((data) => data['title'][this.route.snapshot.params.category]));

  private ngDestroyed: Subject<void>;

  constructor(readonly router: Router, protected route: ActivatedRoute) {
    this.ngDestroyed = new Subject<void>();
    this.handleParamChange();
  }

  ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  private handleParamChange(): void {
    this.route.params.pipe(takeUntil(this.ngDestroyed)).subscribe(() => scrollToTop());
  }
}
