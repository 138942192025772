import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { AokNotification, AokNotificationService, AokToastService, AokUserNotificationStatus } from '@aok/common';

@Component({
  selector: 'aok-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AokNotificationComponent {
  @Input()
  public notification: AokNotification;

  @Input()
  public contextInfo: string;

  public isCollapsed: boolean;

  private TITLE_LENGTH_COLLAPSED = 35;
  private TITLE_LENGTH_COLLAPSED_READ = 40;

  constructor(
    private service: AokNotificationService,
    private cd: ChangeDetectorRef,
    private toastService: AokToastService
  ) {
    this.isCollapsed = true;
  }

  public deleteNotification(id: string): void {
    this.service.delete(id).subscribe({
      error: () =>
        this.toastService.createErrorToast(
          'Technischer Fehler',
          'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
        ),
    });
  }

  public toggleCollapse(id: string): void {
    this.isCollapsed = !this.isCollapsed;
    if (!this.isRead()) {
      this.service.markAsRead(id).subscribe(() => {
        this.notification.status = AokUserNotificationStatus.READ;
        this.cd.markForCheck();
      });
    }
  }

  public isRead(): boolean {
    return this.notification.status === AokUserNotificationStatus.READ;
  }

  public getTrimLength(): number {
    if (this.isRead()) {
      return this.TITLE_LENGTH_COLLAPSED_READ + 3 >= this.notification.title.length
        ? this.notification.title.length
        : this.TITLE_LENGTH_COLLAPSED_READ;
    } else {
      return this.TITLE_LENGTH_COLLAPSED + 3 >= this.notification.title.length
        ? this.notification.title.length
        : this.TITLE_LENGTH_COLLAPSED;
    }
  }
}
