import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { AokArticleCategoryClient } from '../../clients';
import { AokArticleCategory } from '../../schemas';

@Pipe({ name: 'aokArticleCategories' })
export class AokArticleCategoriesPipe implements PipeTransform {
  constructor(protected client: AokArticleCategoryClient) {}

  transform(categoryIds: NonNullable<number>[]): Observable<AokArticleCategory[]> {
    return categoryIds?.length === 0 ? EMPTY : forkJoin(categoryIds?.map((categoryId) => this.client.find(categoryId)));
  }
}
