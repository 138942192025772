<div class="aok-container">
  <div
    class="tw-col-3-12 tw-mt-2"
    *ngIf="batchPrintingData?.errors?.length >= 1 || batchPrintingData?.insurees?.length >= 1"
  >
    <aok-cockpit-print-collection-table
      *ngIf="batchPrintingData?.errors.length >= 1"
      class="tw-mb-3 error-list"
      [tableName]="'error-list'"
      headLine="{{ batchPrintingData?.errors?.length | i18nPlural : recordsMappingErrorList }} nicht ermittelt werden"
      [batchPrintingData]="batchPrintingData?.errors"
      [displayColumns]="errorListDisplayedColumns"
      (updateRow)="removeErrorTableRow($event)"
      (patientErrorSolved)="patientErrorSolved($event)"
    ></aok-cockpit-print-collection-table>

    <aok-cockpit-print-collection-table
      #printList
      class="print-list"
      *ngIf="batchPrintingData?.insurees.length >= 1"
      headLine="{{ printListLength | i18nPlural : recordsMappingPrintList }}"
      [tableName]="'print-list'"
      [batchPrintingData]="batchPrintingData?.insurees"
      [displayColumns]="printListDisplayedColumns"
      (updateRow)="updateBatchPrintingInsurees($event)"
    >
    </aok-cockpit-print-collection-table>
  </div>

  <ng-container *ngIf="batchPrintingData?.insurees.length >= 1">
    <div class="tw-col-3-11" [ngClass]="{ 'tw-mt-3': batchPrintingData?.insurees.length < 11 }">
      <p class="required-label">*Pflichtfeld</p>

      <h4 class="tw-mb-7">Druckoptionen</h4>
      <aok-input-field>
        <aok-radio [options]="radioOptions" [formControl]="printOptionsControl"></aok-radio>
      </aok-input-field>

      <p class="tw-mt-5" [ngClass]="{ 'tw-mb-5': isDoctor(currentUser.snapshot) }">
        <ng-container *ngIf="printOptionsControl?.value === AokBillingSheetTypeEnum.PrePrinted; else fullPrintTemplate">
          Bitte legen Sie den Vordruck Muster 5 in Ihren Praxisdrucker ein. Der Abrechnungsschein wird direkt darauf
          gedruckt.
        </ng-container>

        <ng-template #fullPrintTemplate>
          Bitte legen Sie Blankopapier in Ihren Praxisdrucker ein. Der Abrechnungsschein wird inklusive des Muster 5
          aufgedruckt.
        </ng-template>
      </p>
    </div>

    <ng-container *ngIf="isDoctor(currentUser.snapshot)">
      <div class="tw-col-3-8">
        <div class="lanr-container">
          <aok-input-field label="LANR wählen oder eingeben" required>
            <aok-dropdown [control]="lanrControl" [mode]="dropdownMode" [options]="lanrOptions"></aok-dropdown>
          </aok-input-field>

          <aok-popover [direction]="popoverDirection" [width]="280">
            <p class="aok-bold">LANR nicht gefunden?</p>
            <p>
              Falls die gesuchte LANR nicht in der Auswahl enthalten ist, können Sie diese auch direkt als 9-stellige
              Ziffernfolge in das Feld eingeben.
            </p>
          </aok-popover>
        </div>
      </div>

      <div class="tw-col-3-11">
        <aok-notification-banner
          *ngIf="displaySpecialization()"
          [bannerLabel]="specialization"
          bannerHeadline="Fachgruppe"
          class="specialization"
          iconName="info-circle"
        >
        </aok-notification-banner>
      </div>
    </ng-container>

    <div class="tw-col-3-11 tw-mt-3">
      <div class="buttons">
        <button
          class="primary"
          [disabled]="isDisabledPrint || (isDoctor(currentUser.snapshot) && lanrControl.invalid)"
          (click)="submit()"
        >
          Abrechnungsschein drucken
        </button>

        <ng-container *ngTemplateOutlet="newQueryButton"></ng-container>
      </div>
    </div>
  </ng-container>

  <div class="tw-col-3-11" *ngIf="batchPrintingData?.insurees.length === 0">
    <ng-container *ngTemplateOutlet="newQueryButton"></ng-container>
  </div>
</div>

<ng-template #newQueryButton>
  <button
    class="secondary"
    [ngClass]="{
      'tw-mt-4': !(batchPrintingData?.errors?.length >= 1 || batchPrintingData?.insurees?.length >= 1)
    }"
    (click)="switchToEntryPage.emit()"
  >
    Neue Abfrage
  </button>
</ng-template>
