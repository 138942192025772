import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { A11yModule } from '@angular/cdk/a11y';
import { AokDialogResult, DialogBase, DialogRef } from '@aok/common';

@Component({
  selector: 'aok-kvn-practice-instructions-add-mode-doctors-dialog',
  standalone: true,
  imports: [CommonModule, AokDialogLayoutModule, A11yModule],
  templateUrl: './kvn-practice-instructions-add-mode-doctors-dialog.component.html',
  styleUrls: ['./kvn-practice-instructions-add-mode-doctors-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KvnPracticeInstructionsAddModeDoctorsDialog implements DialogBase<AokDialogResult> {
  constructor(public readonly dialogRef: DialogRef<AokDialogResult>) {}
}
