<dialog-layout
  fullscreen="false"
  [isClosable]="!!cancelButtonText"
  [headerText]="headerText"
  aokFocusFirstChild
  [autoBlur]="true"
  cdkTrapFocus
>
  <div (click)="handleLinks($event)" (keyup.enter)="handleLinks($event)">
    <ng-container *ngIf="(contentText | typeof) === 'string'">
      <p [innerHTML]="contentText" id="contextText"></p>
    </ng-container>
    <ng-container *ngIf="(contentText | typeof) === 'Array'">
      <ng-container *ngFor="let p of contentText; let i = index">
        <p [innerHTML]="p" [id]="'contextText_' + i"></p>
      </ng-container>
    </ng-container>
  </div>

  <dialog-footer>
    <button *ngIf="cancelButtonText" (click)="cancel()" (keyup.enter)="cancel()" class="secondary">
      {{ cancelButtonText }}
    </button>

    <button *ngIf="confirmButtonText" (click)="confirm()" (keyup.enter)="confirm()">{{ confirmButtonText }}</button>
  </dialog-footer>
</dialog-layout>
