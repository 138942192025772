import { Pipe, PipeTransform } from '@angular/core';
import { AokDoctorResource } from '../../schemas';
import { lanr9Util } from '../../utils';

@Pipe({ name: 'aokLanr9', standalone: true })
export class AokLanr9Pipe implements PipeTransform {
  transform(value: AokDoctorResource): string {
    return lanr9Util(value?.lanr, value?.specialization?.id);
  }
}
