import { Injectable } from '@angular/core';
import { AokReportPageData, BehaviorState } from '@aok/common';

@Injectable({
  providedIn: 'root',
})
export class ReportsState extends BehaviorState<AokReportPageData> {
  sort = {
    hzv: '',
    arzneimittel: '',
    heilmittel: '',
  };

  constructor() {
    super();
  }
}
