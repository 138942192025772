<div class="aok-dropdown">
  <ng-select
    class="aok-select"
    #selectComponent
    [ngClass]="{ filled: selectComponent.searchTerm?.length }"
    [class.has-error]="control.invalid"
    [class.simple-mode]="mode === DropdownMode.SIMPLE"
    [class.grouping-mode]="mode === DropdownMode.GROUPING"
    [clearable]="mode !== DropdownMode.SIMPLE"
    [searchable]="mode !== DropdownMode.SIMPLE"
    [labelForId]="innerid"
    [items]="options"
    [bindValue]="'value'"
    [addTag]="mode === DropdownMode.ENHANCED ? addTag : false"
    addTagText=""
    [editableSearchTerm]="mode !== DropdownMode.SIMPLE"
    [formControl]="control"
    notFoundText="Keine Einträge gefunden"
    (search)="mode === DropdownMode.ENHANCED ? onSearch($event) : triggerDetection()"
    (blur)="mode === DropdownMode.ENHANCED ? onBlur(selectComponent) : triggerDetection()"
    (change)="onChange($event)"
    (clear)="mode === DropdownMode.ENHANCED ? onClear() : triggerDetection()"
    (close)="triggerDetection()"
    (focus)="triggerDetection()"
    (open)="triggerDetection()"
    [placeholder]="placeholder ? placeholder : null"
    [virtualScroll]="virtualScroll"
  >
    <ng-template ng-option-tmp let-item="item">
      <!-- The space after the label is needed to block early ellipsis in Safari -->
      <div [ngClass]="{ 'is-child': item?.isChild }">
        {{ item.label }}&nbsp;
        <aok-svg-icon *ngIf="control.value === item.value" class="selected" size="16px" name="checkmark-circle">
        </aok-svg-icon>
      </div>
    </ng-template>
  </ng-select>

  <ng-content></ng-content>

  <aok-svg-icon
    *ngIf="term && selectComponent.showClear()"
    name="close"
    size="16px"
    class="icon-button"
    (click)="clear()"
    aokFocusable
    (keyup.enter)="clear()"
  ></aok-svg-icon>

  <aok-svg-icon
    *ngIf="!selectComponent.isOpen"
    name="arrow-down"
    size="16px"
    class="icon-button"
    (click)="arrowClick()"
    aokFocusable
    (keyup.enter)="arrowClick()"
  ></aok-svg-icon>

  <aok-svg-icon
    *ngIf="selectComponent.isOpen"
    name="arrow-up"
    size="16px"
    class="icon-button"
    (click)="arrowClick()"
    aokFocusable
    (keyup.enter)="arrowClick()"
  ></aok-svg-icon>
</div>
