import { ComponentRef } from '@angular/core';
import { FormErrorCode } from './form-errors';
import { CONTROL_ERROR_CODE } from './symbols';

/** @internal */
export class FormControlErrorHost {
  /**
   * Gets a {@link Map} of the currently embedded form error controls. Where the key will be the respective {@link FormControl}
   * name and its value should always return the actual (error) {@link ComponentRef} associated with the control name
   */
  protected readonly embeddedControlErrors = new Map<string | number, ComponentRef<any>>();

  /**
   * checks if for the given form control errors are being handled.
   * if error code is given, check additionally if the erorr component handling the form control is specifically for that error code
   */
  doesHandleControlError(formControlName: string | number, errorCode?: FormErrorCode): boolean {
    if (formControlName == null) return false;
    else if (errorCode == null) return this.embeddedControlErrors.has(formControlName);
    else {
      const componentRef = this.embeddedControlErrors.get(formControlName);
      return componentRef != null && errorCode === componentRef.injector.get(CONTROL_ERROR_CODE);
    }
  }

  setControlErrorComponent(formControlName: string | number, componentRef: ComponentRef<any>): void {
    if (formControlName != null && componentRef != null) this.embeddedControlErrors.set(formControlName, componentRef);
  }
  deleteControlErrorComponent(formControlName: string | number): boolean {
    return this.embeddedControlErrors.delete(formControlName);
  }
}
