import { Inject, Injectable } from '@angular/core';
import { AOK_CONTACT } from '../providers';
import { AokContactSchema } from '../schema';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private supportEmail: string;
  private supportTel: string;

  constructor(@Inject(AOK_CONTACT) protected contact: AokContactSchema) {
    this.setupContact();
  }

  public getSupportEmail(): string {
    return this.supportEmail;
  }

  public getSupportTel(): string {
    return this.supportTel;
  }

  private setupContact(): void {
    this.supportEmail = this.contact.email?.['value'] || this.contact.email;
    this.supportTel = this.contact.tel?.['value'] || this.contact.tel;
  }
}
