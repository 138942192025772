import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AokApiBaseClient } from './api-base.client';

export const AOK_BASE_BENEFITS_API_URL = new InjectionToken<string>('AOK_BASE_BENEFITS_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/benefits',
});

@Injectable({ providedIn: 'root' })
export class AokBaseBenefitsClient extends AokApiBaseClient {
  constructor(@Inject(AOK_BASE_BENEFITS_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }
}
