import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from './search-input.component';
import { FormsModule } from '@angular/forms';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokInputFieldModule } from '../cdk';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  declarations: [SearchInputComponent],
  imports: [CommonModule, FormsModule, AokSvgIconComponent, A11yUtilsModule, AokInputFieldModule, A11yModule],
  exports: [SearchInputComponent],
})
export class SearchInputModule {}
