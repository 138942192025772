import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { A11yUtilsModule, AokSvgIconComponent, RouterLinkOrHrefModule } from '@aok/common';
import { TileSchema } from '../../schema';

@Component({
  selector: 'aok-tile',
  styleUrls: ['./tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'tile.component.html',
  standalone: true,
  imports: [CommonModule, AokSvgIconComponent, A11yUtilsModule, RouterLinkOrHrefModule, RouterLink],
})
export class AokTileComponent implements OnChanges {
  @Input() tile: TileSchema;
  safeURL: SafeUrl;

  constructor(private sanitizer: DomSanitizer, public router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.safeURL = changes?.tile?.currentValue?.image
      ? this.sanitizer.bypassSecurityTrustUrl(this.tile.image as string)
      : null;
  }

  protected navigateToLink(): void {
    if (this.tile.isTileClickable && !!this.tile.link) {
      this.router.navigate([this.tile.link]);
    }
  }

  protected getTitleIcon(): string {
    return this.tile?.target === '_blank' ? 'arrow-rounded_rightup' : 'arrow-rounded_right';
  }
}
