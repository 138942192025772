import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  AokPage,
  AokPageQueryParams,
  AokReport,
  AokReportCategory,
  AokReportClient,
  AokReportListOptions,
  AokReportPageData,
} from '@aok/common';
import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { REPORTS_CATEGORY_ORDER } from '../config/reports.config';
import { ReportsState } from '../states/reports.state';
import { getFullPathRouteCommands } from '../utils/route.utils';

@Injectable({ providedIn: 'root' })
export class ReportsService {
  private enabledCategories = {
    hzv: environment.enableHZVReports,
    arzneimittel: environment.enableArzneimittelReports,
    heilmittel: environment.enableHeilmittelReports,
  };

  constructor(private client: AokReportClient, private reportsState: ReportsState, private router: Router) {}

  public isAnyCategoryEnabled(): boolean {
    return Object.values(this.enabledCategories).some((enabled) => enabled);
  }

  public handleReportsRetrievedBefore(
    category: AokReportCategory,
    pageParams: AokPageQueryParams,
    route: ActivatedRouteSnapshot
  ): Observable<AokReportPageData> {
    if (this.shouldRetrieve(pageParams, category)) {
      return this.client.list({ ...pageParams, category }).pipe(
        map((reportsPage) => {
          this.reportsState.sort[category] = pageParams.sort as string;
          return this.updateState(category, reportsPage);
        }),
        tap((reportsData: AokReportPageData) => {
          if (!this.isCategoryEnabled(category) || !this.hasItems(reportsData, category)) {
            this.redirectToActiveTab(reportsData, route);
          }
        })
      );
    } else {
      // if no changes were done to the params check if there were reports in the category before
      const reportsData = this.reportsState.snapshot;
      if (this.hasItems(reportsData, category)) {
        return of(reportsData);
      } else {
        // if there were no categories, redirect to next active tab
        this.redirectToActiveTab(reportsData, route);
      }
    }
  }

  public handleNoReportsRetrievedBefore(
    category: AokReportCategory,
    pageParams: AokPageQueryParams,
    route: ActivatedRouteSnapshot
  ): Observable<AokReportPageData> {
    return this.retrieveReportsData(category, pageParams).pipe(
      tap((reportsData: AokReportPageData) => {
        this.reportsState.patch(reportsData);

        if (!this.isCategoryEnabled(category) || !this.hasItems(reportsData, category)) {
          this.redirectToActiveTab(reportsData, route);
        }
      })
    );
  }

  /**
   * create object observables to retrieve data for each report category
   * return result of all observables
   */
  public retrieveReportsData(routeCategory?: string, pageParams?: AokPageQueryParams): Observable<AokReportPageData> {
    const observables = {} as Record<AokReportCategory, Observable<AokPage<AokReport>>>;

    Object.values(AokReportCategory).forEach((category) => {
      if (this.isCategoryEnabled(category)) {
        let options = {
          category,
        } as AokReportListOptions;

        if (routeCategory && pageParams && category === routeCategory) {
          // only use page params for reports category that is visible
          options = {
            ...options,
            ...pageParams,
          };
        }

        observables[category] = this.client.list(options);
      } else delete observables[category];
    });

    return forkJoin(observables);
  }

  public redirectToActiveTab(
    reportsData: AokReportPageData,
    route: ActivatedRouteSnapshot,
    noReportsPage?: boolean
  ): void {
    let commands = ['berichte', 'uebersicht'];
    const redirect = REPORTS_CATEGORY_ORDER.some((reportCategory) => {
      if (this.isCategoryEnabled(reportCategory) && this.hasItems(reportsData, reportCategory)) {
        commands = this.getCategoryRoute(route, reportCategory);
        return true;
      } else return false;
    });

    // if we are trying to redirect from the no reports page
    if (noReportsPage) {
      if (redirect) {
        // check if we are not redirecting back to no reports page to avoid infinite loop
        this.router.navigate(commands);
      }
    } else {
      // otherwise navigate wherever computed
      this.router.navigate(commands);
    }
  }

  private shouldRetrieve(pageParams: AokPageQueryParams, category: string): boolean {
    // if sorting or page changed or report was deleted -> retrieve new data for category
    const categoryReportsPage = this.reportsState.snapshot[category] as AokPage<AokReport>;
    return (
      pageParams.sort !== this.reportsState.sort[category] ||
      !categoryReportsPage ||
      categoryReportsPage?.page.number !== pageParams.page
    );
  }

  private updateState(category: AokReportCategory, reportsPage: AokPage<AokReport>): AokReportPageData {
    const updatedState = { ...this.reportsState.snapshot };
    updatedState[category] = reportsPage;
    this.reportsState.patch(updatedState);

    return updatedState;
  }

  /**
   * return the route commands for the given category
   */
  private getCategoryRoute(route: ActivatedRouteSnapshot, reportCategory: AokReportCategory): string[] {
    const commands = getFullPathRouteCommands(route);
    commands[commands.length - 1] = reportCategory;
    return commands;
  }

  private isCategoryEnabled(category: AokReportCategory | string): boolean {
    return this.enabledCategories[category];
  }

  private hasItems(reportsData: AokReportPageData, category: AokReportCategory | string): boolean {
    return reportsData[category]._embedded?.items?.length;
  }
}
