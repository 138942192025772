<div
  class="org-context-list-item tw-flex tw-items-center"
  aokFocusable
  (click)="clickEmitter.emit($event)"
  (keyup.enter)="enterEmitter.emit($event)"
>
  <div class="icon" [class.selected]="isSelected">
    <aok-svg-icon [name]="iconName" size="24px"></aok-svg-icon>
  </div>

  <div class="bsnr-info">
    <span class="sub-header">{{ headline }}</span>
    <span class="title tw-flex tw-items-center">
      {{ label }}
      <aok-svg-icon name="arrow-rounded_right" size="16px"></aok-svg-icon>
    </span>
  </div>
</div>
