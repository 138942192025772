<ng-container *ngIf="isKvnDoctor" [ngTemplateOutlet]="kvnPracticeAccountControl"></ng-container>
<div class="aok-container profile-header">
  <h4>
    {{ labelSelectedPractice }}
  </h4>
  <div class="practice-container">
    <div class="accordion-container">
      <aok-accordion-option
        [withoutBorderGap]="true"
        [option]="{ label: selectedOrg?.bsnr + ' | ' + selectedOrg?.name, id: accordionIdPrefix + selectedOrg?.bsnr }"
        [accordionId]="primaryAccordionId"
      >
        <div content>
          <ng-container *ngTemplateOutlet="addEmployeeTemplate"></ng-container>

          <div class="headline-with-popover-container">
            <h5 class="doc-headline">Ärzte</h5>
            <aok-popover [radius]="20" [width]="350" [direction]="popoverDirection">
              <p>Hier können Sie alle registrierten Ärzte sehen.</p>
            </aok-popover>
          </div>

          <ng-container
            *ngTemplateOutlet="
              tableDoctorTemplate;
              context: { $implicit: selectedOrgDoctors || [], isHeaderTemplate: true }
            "
          ></ng-container>

          <div class="headline-with-popover-container">
            <h5 class="mfa-headline">Praxismitarbeiter</h5>
            <aok-popover [radius]="20" [width]="350" [direction]="popoverDirection">
              <p>Hier können Sie alle direkt im Arztportal registrierten Praxismitarbeiter verwalten.</p>
              <br />
              <p>
                Praxismitarbeiter, die ausschließlich über das KVN-Portal zugreifen, werden als "Benutzer der
                Praxiskarte" angezeigt und können nicht bearbeitet werden.
              </p>
            </aok-popover>
          </div>

          <ng-container
            *ngTemplateOutlet="
              tableAssistantTemplate;
              context: {
                $implicit: selectedOrgAssistants || [],
                isHeaderTemplate: true,
                organizationId: selectedOrg.id
              }
            "
          ></ng-container>
          <ng-container *ngTemplateOutlet="addEmployeeTemplate"></ng-container>
        </div>
      </aok-accordion-option>
    </div>

    <ng-container *ngIf="unselectedOrganizationsData?.length">
      <h4>Weitere Praxen</h4>
      <div class="accordion-container">
        <aok-accordion-option
          *ngFor="let unselectedOrgData of unselectedOrganizationsData; trackBy: unselectedOrganizationsTrackBy"
          [withoutBorderGap]="true"
          [option]="{
            label: unselectedOrgData.org.bsnr + ' | ' + unselectedOrgData.org.name,
            id: unselectedOrgData.org.bsnr
          }"
          [accordionId]="accordionIdPrefix + unselectedOrgData.org.bsnr"
        >
          <div content>
            <ng-container
              *ngTemplateOutlet="addEmployeeTemplate; context: { $implicit: unselectedOrgData.org.id }"
            ></ng-container>

            <h5 name="doc-headline">Ärzte</h5>
            <ng-container
              *ngTemplateOutlet="tableDoctorTemplate; context: { $implicit: unselectedOrgData.doctors || [] }"
            ></ng-container>

            <h5 name="mfa-headline">Praxismitarbeiter</h5>
            <ng-container
              *ngTemplateOutlet="
                tableAssistantTemplate;
                context: { $implicit: unselectedOrgData.assistants || [], organizationId: unselectedOrgData.org.id }
              "
            ></ng-container>

            <ng-container
              *ngTemplateOutlet="addEmployeeTemplate; context: { $implicit: unselectedOrgData.org.id }"
            ></ng-container>
          </div>
        </aok-accordion-option>
      </div>
    </ng-container>
  </div>
</div>

<ng-template let-data #tableDoctorTemplate>
  <table
    aria-describedby="Aerzte"
    class="aok"
    cdk-table
    id="userDoctorsTable"
    [dataSource]="data"
    [trackBy]="hcpTrackByFn"
  >
    <ng-container cdkColumnDef="name">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef>Name</th>
      <td cdk-cell *cdkCellDef="let user">
        {{ user | aokFullName : 'TT LL, FF' : user?.practitionerResource || user?.practitionerRequest }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="lanr">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef>LANR</th>
      <td cdk-cell *cdkCellDef="let user">
        {{ user.practitionerResource?.lanr }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="fgs">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef>FGS</th>
      <td cdk-cell *cdkCellDef="let user">
        {{ user.practitionerResource | aokFgs }}
      </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayColumnsDoctors"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayColumnsDoctors"></tr>
  </table>
</ng-template>

<ng-template let-data #tableAssistantTemplate let-organizationId="organizationId">
  <table
    aria-describedby="Praxismitarbeiter"
    class="aok"
    cdk-table
    id="userAssistantsTable"
    [dataSource]="data"
    [trackBy]="assistantsTrackByFn"
  >
    <ng-container cdkColumnDef="name">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef [class.increased-width]="!showOptionColumn">Name</th>
      <td cdk-cell *cdkCellDef="let user" [class.increased-width]="!showOptionColumn">
        {{
          user.userData || user | aokFullName : 'TT LL, FF' : user?.practitionerResource || user?.practitionerRequest
        }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="status">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef [class.descreased-width]="!showOptionColumn">Status</th>
      <td cdk-cell *cdkCellDef="let user" [class.decreased-width]="!showOptionColumn">
        <aok-table-cell
          [status]="getTableCellStatus(user.registrationStatus || user.status)"
          [text]="getTableCellText(user.registrationStatus || user.status)"
        >
        </aok-table-cell>
      </td>
    </ng-container>

    <ng-container *ngIf="showOptionColumn" cdkColumnDef="option">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef>Optionen</th>
      <td cdk-cell *cdkCellDef="let user">
        <aok-menu-button
          *ngIf="getGridOptions(user).length > 0"
          class="gridMenuButton"
          [entries]="getGridOptions(user)"
          (selected)="$event['action'](user, organizationId)"
        >
          Optionen
        </aok-menu-button>
      </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayColumnsAssistants"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayColumnsAssistants"></tr>
  </table>
</ng-template>

<ng-template #addEmployeeTemplate let-data>
  <div class="addEmployee">
    <p>Möchten Sie einen neuen Praxismitarbeiter dieser Praxis hinzufügen?</p>
    <button id="btnOpenMfaInviteOverlay" class="secondary" (click)="inviteMfaDialog(data)">
      Praxismitarbeiter hinzufügen
    </button>
  </div>
</ng-template>

<ng-template #kvnPracticeAccountControl>
  <div class="kvn-practice-account-control tw-mt-2">
    <div class="aok-container">
      <h3 class="tw-mt-5 tw-col-2-12">Zugriffsberechtigung Praxismitarbeiter über KVN-Portal</h3>

      <div class="tw-mt-5 tw-mb-4 grid-10 tw-col-2-12">
        <div class="practice-account-control-info tw-col-1-7">
          <aok-svg-icon
            [name]="isAccessForPMGranted ? '0858-checkmark_circle' : '0108-lock'"
            size="35px"
            class="tw-mr-9"
          ></aok-svg-icon>

          <p>{{ kvnPracticeAccountControlText }}</p>
        </div>

        <div class="tw-col-7-11">
          <button aokFocusable (click)="editPracticeAccountAccess()">Berechtigung bearbeiten</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
