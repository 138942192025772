import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DIALOG_PRESET, DialogPreset } from '@aok/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dialog-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <ng-template #defaultHeaderTemplate>
      <ng-content></ng-content>

      <ng-container *ngIf="closeTemplate.getValue() as closeTemplateRef">
        <ng-container *ngTemplateOutlet="closeTemplateRef"></ng-container>
      </ng-container>
    </ng-template>

    <ng-container *ngIf="!preset?.disableFlexContainer; else defaultHeaderTemplate">
      <div>
        <ng-container *ngTemplateOutlet="defaultHeaderTemplate"></ng-container>
      </div>
    </ng-container>
  `,
})
export class AokDialogHeaderComponent implements OnInit, OnDestroy {
  protected readonly ngDestroys = new Subject<void>();

  readonly closeTemplate = new BehaviorSubject<TemplateRef<unknown> | null>(null);

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(DIALOG_PRESET) readonly preset: /* @dynamic */ DialogPreset
  ) {}

  ngOnInit(): void {
    this.closeTemplate
      .asObservable()
      .pipe(takeUntil(this.ngDestroys))
      .subscribe(() => this.changeDetectorRef.detectChanges());
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }
}
