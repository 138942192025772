import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AokEpisode, AokPodcastListOptions } from '../schemas';

export const AOK_PODCAST_API_URL = new InjectionToken<string>('AOK_PODCAST_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => 'https://aok-praxis-talk.podigee.io/embed/playlist',
});

@Injectable({ providedIn: 'root' })
export class AokPodcastClient {
  constructor(@Inject(AOK_PODCAST_API_URL) readonly baseUrl: string, protected http: HttpClient) {}

  list(options: AokPodcastListOptions): Observable<AokEpisode> {
    return this.http.get<AokEpisode>(this.baseUrl, {
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }
}
