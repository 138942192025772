<div *ngFor="let opt of options" class="aok-radio-button tw-flex tw-justify-between">
  <label
    class="form-control"
    (click)="writeValue(opt.value)"
    (keyup.space)="writeValue(opt.value)"
    (keyup.enter)="writeValue(opt.value)"
    tabIndex="0"
  >
    <input [id]="opt.id" type="radio" [name]="name" tabindex="-1" [checked]="selected === opt.value" />
    {{ opt.label }}
  </label>

  <aok-popover
    *ngIf="opt.popover"
    [id]="opt.id + 'Popover'"
    width="384"
    [direction]="POPOVER_DIRECTION.RightTop"
    [xaxis]="3"
    [yaxis]="-2"
  >
    <div [innerHtml]="sanitize(opt.popover)"></div>
  </aok-popover>
</div>
