import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'aok-header-link',
  templateUrl: './header-link.component.html',
  styleUrls: ['./header-link.component.scss'],
})
export class AokHeaderLinkComponent {
  @Input()
  isOpened: boolean;

  @Input()
  icon: string;

  @Input()
  label: string;

  @Input()
  altTextOpened: string;

  @Input()
  altTextClosed: string;

  @Output()
  toggleLink: EventEmitter<string> = new EventEmitter<string>();
}
