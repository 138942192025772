import { InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

export interface AokTitleStrategyConfig {
  titlePrefix: string;
}

export type AokTitleStrategyFallbackResolver = (
  route: ActivatedRouteSnapshot,
  paramKey: string,
  title: unknown
) => unknown;

export const TITLE_PREFIX = new InjectionToken<string>('titlePrefix');
export const AOK_TITLE_STRATEGY_FALLBACK_RESOLVER = new InjectionToken<AokTitleStrategyFallbackResolver>(
  'AOK_TITLE_STRATEGY_FALLBACK_RESOLVER',
  {
    providedIn: 'root',
    factory: /** @dynamic */ () => (snapshot) => snapshot.url[0]?.path,
  }
);
