import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AokCheckboxComponent } from './checkbox.component';

@NgModule({
  declarations: [AokCheckboxComponent],
  exports: [AokCheckboxComponent],
  imports: [CommonModule, ReactiveFormsModule],
})
export class AokCheckboxModule {}
