<form [formGroup]="form">
  <div class="tw-w-[295px]">
    <aok-input-field [label]="formLabels.authOption" required="true">
      <aok-radio id="authOption" [options]="authOptions" formControlName="authOption"></aok-radio>
    </aok-input-field>
  </div>

  <ng-container *ngIf="displayCardExtra()">
    <div
      *ngIf="contextOrganizations?.length === 1; else bsnrSelector"
      class="tw-mt-7"
      [ngClass]="{ 'text-muted': form.get(SecondFactorAuthFields.BSNR).disabled }"
    >
      <p class="tw-mb-8 aok-text-l3">{{ formLabels.bsnr }}</p>

      <p>
        BSNR | {{ contextState?.bsnr }} | Praxis
        {{ contextState?.practitioner | aokFullName : 'TT LL, FF' : contextState?.practitioner?.practitionerResource }}
      </p>

      <p>{{ contextState?.org.address | aokFullAddress : 'STN SN, ZZ LL' }}</p>
    </div>
  </ng-container>

  <ng-template #bsnrSelector>
    <div class="tw-mt-7 grid-10">
      <div class="tw-ml-[30px] tw-col-1-9">
        <aok-input-field [label]="formLabels.bsnr" id="bsnr">
          <aok-dropdown [control]="form.get(SecondFactorAuthFields.BSNR)" [options]="practiceOptions"></aok-dropdown>
        </aok-input-field>
      </div>

      <p class="tw-ml-[30px] tw-col-1-7" *ngIf="form.get(SecondFactorAuthFields.BSNR).value">
        {{ getSelectedOrgAddress() | aokFullAddress : 'STN SN, ZZ LL' }}
      </p>
    </div>
  </ng-template>

  <div *ngIf="displaySmsExtra()">
    <aok-phone-number-data class="tw-block tw-mt-7" [form]="form" [formLabels]="formLabels"></aok-phone-number-data>
  </div>
</form>
