import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import {
  A11yUtilsModule,
  AokPopoverComponent,
  AokSvgIconComponent,
  findScrollParent,
  PopoverDirection,
  scrollToFragmentWithOffset,
} from '@aok/common';
import { AokInputFieldModule } from '../cdk';
import { AokCheckboxModule } from '../checkbox/checkbox.module';

@Component({
  selector: 'aok-completion-support',
  templateUrl: './completion-support.component.html',
  styleUrls: ['./completion-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AokPopoverComponent,
    AokSvgIconComponent,
    AokCheckboxModule,
    ReactiveFormsModule,
    AokInputFieldModule,
    RouterLink,
    A11yUtilsModule,
  ],
})
export class CompletionSupportComponent {
  public highlightRequiredFields = true;
  public showSampleData = false;

  public popoverContainerWidth = 490;
  public popoverRadius = 20;

  public popoverRightCenter: PopoverDirection = PopoverDirection.RightCenter;
  public popoverRightTop: PopoverDirection = PopoverDirection.RightTop;
  public popoverBottomRight: PopoverDirection = PopoverDirection.BottomRight;
  public popoverBottomCenter: PopoverDirection = PopoverDirection.BottomCenter;
  public popoverBottomLeft: PopoverDirection = PopoverDirection.BottomLeft;
  public popoverTopLeft: PopoverDirection = PopoverDirection.TopLeft;
  public popoverLeftTop: PopoverDirection = PopoverDirection.LeftTop;
  public popoverLeftCenter: PopoverDirection = PopoverDirection.LeftCenter;

  public get hkpPopoverIconName(): string {
    return this.highlightRequiredFields ? 'question-circle_filled' : 'question-circle';
  }

  public get hkpPopoverIconSize(): number {
    return this.highlightRequiredFields ? 23 : 22;
  }

  constructor(private elementRef: ElementRef) {}

  protected scrollToAnchor(location: string): void {
    const parent = findScrollParent(this.elementRef.nativeElement);
    scrollToFragmentWithOffset(location, parent instanceof Window ? 160 : 0, parent);
  }
}
