import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AokPopoverComponent } from '@aok/common';
import { AokRadioComponent } from './radio.component';

@NgModule({
  declarations: [AokRadioComponent],
  exports: [AokRadioComponent],
  imports: [CommonModule, ReactiveFormsModule, AokPopoverComponent],
})
export class AokRadioModule {}
