import { inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { normalizeUrl, traverse } from '@aok/common';
import { AokSitemap, SiteRef } from '../components';

interface ActiveBreadcrumbsResult {
  activeBreadcrumb: SiteRef | undefined;
  activeRoute: ActivatedRouteSnapshot;
  activeBreadcrumbs: SiteRef[];
}

export class BreadcrumbsService {
  private router = inject(Router);

  public findActiveSitePath(
    route: ActivatedRoute,
    sitemap: AokSitemap,
    homeUrl: string,
    includeHomeSite = false
  ): SiteRef[] {
    const { activeRoute, activeBreadcrumb, activeBreadcrumbs } = this.findActiveBreadcrumbs(route, sitemap);

    const { path: activeRoutePath, data: activeRouteData } = activeRoute.routeConfig;

    let activeBreadcrumbTitle: string;
    if (activeRoutePath.includes(':') && activeRoutePath !== activeBreadcrumb?.route.path) {
      const activeRouteWithParentRouteData = { ...route.snapshot.data, ...activeRouteData, ...activeRoute.params };

      activeBreadcrumbTitle =
        typeof activeRouteData.title === 'function'
          ? activeRouteData.title(activeRouteWithParentRouteData)
          : activeRouteData.title;

      activeBreadcrumbs.push({
        title: activeBreadcrumbTitle,
        linkUrl: '.',
      } as SiteRef);
    }

    return activeBreadcrumbs.filter(
      (site) => includeHomeSite || site?.linkUrl !== this.getHomeUrl(homeUrl, sitemap)?.linkUrl
    );
  }

  public getHomeUrl(homeLinkUrl: string, sitemap: AokSitemap): SiteRef | null {
    return homeLinkUrl && Array.from(sitemap).find((site) => site.linkUrl === normalizeUrl(homeLinkUrl));
  }

  public findActiveBreadcrumbs(route: ActivatedRoute, sitemap: AokSitemap): ActiveBreadcrumbsResult {
    const findActiveChildSite = (sites: SiteRef[]): SiteRef => sites?.find((site) => this.isActiveSite(site));

    const activeRootSite = findActiveChildSite(Array.from(sitemap));
    const activeBreadcrumbs: SiteRef[] = [activeRootSite];

    traverse(activeRootSite, (site) => {
      const activeChildSite = findActiveChildSite(site?.children);
      if (activeChildSite) activeBreadcrumbs.push(activeChildSite);

      if (activeBreadcrumbs[activeBreadcrumbs?.length - 1]?.children.length > 0) {
        const activeGrandChildSite = findActiveChildSite(activeBreadcrumbs[activeBreadcrumbs?.length - 1]?.children);
        if (activeGrandChildSite) activeBreadcrumbs.push(activeGrandChildSite);
      }

      return activeChildSite;
    });

    const activeBreadcrumb = activeBreadcrumbs[activeBreadcrumbs?.length - 1];
    const activeRoute = traverse(route.snapshot, (snapshot) => snapshot.firstChild) || route.snapshot;
    return { activeBreadcrumb, activeRoute, activeBreadcrumbs };
  }

  private isActiveSite(site: SiteRef): boolean {
    return this.router.isActive(site.linkUrl, {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }
}
