<aok-cockpit-header></aok-cockpit-header>

<div class="aok-container headline">
  <ng-container *ngIf="kvnError === kvnExistingErrors.NO_KVN_ATTRIBUTE; else generalHeadline">
    <h3>Aktion fehlgeschlagen</h3>
  </ng-container>
  <ng-template #generalHeadline>
    <h3>Zugriff auf das AOK-Arztportal nicht möglich</h3>
  </ng-template>
</div>

<div class="aok-bg-sand">
  <div class="aok-container">
    <div class="icon-wrapper tw-flex tw-justify-center tw-mb-7">
      <aok-svg-icon name="0859-cross_circle" size="54px"></aok-svg-icon>
    </div>

    <ng-container *ngIf="kvnError === kvnExistingErrors.EXISTING_REGISTRATION">
      <h3>Die Registrierung im AOK Arztportal wurde noch nicht abgeschlossen.</h3>
      <p>
        Sie müssen Ihre begonnene Registrierung im AOK Arztportal zuerst abschließen, damit Sie über das KNV-Portal auf
        das Arztportal zugreifen können.
      </p>
    </ng-container>

    <ng-container *ngIf="kvnError === kvnExistingErrors.NO_KVN_ATTRIBUTE">
      <h3>Die Aktion konnte leider nicht ausgeführt werden.</h3>
      <p>Es liegen fehlerhafte oder unvollständige Anmeldedaten vor.</p>
      <p>
        Wenden Sie sich bitte an unser Support-Team unter
        <a href="tel:{{ supportTel }}">
          {{ supportTel }}
        </a>
        oder per E-Mail an
        <a href="mailto:{{ supportEmail }}">
          {{ supportEmail }}
        </a>
      </p>
    </ng-container>

    <ng-container *ngIf="kvnError === kvnExistingErrors.NO_TI_CONNECTION">
      <h3>
        Der Zugriff auf das AOK-Arztportal über die Telematikinfrastruktur ist an diesem Arbeitsplatz nicht
        eingerichtet.
      </h3>
      <p>
        Für die Nutzung der Vertragsverwaltung ist eine Verbindung zur Telematikinfrastruktur erforderlich. Nutzen Sie
        die erweiterte TI-Prüfung, um Hinweise zur Einrichtung zu erhalten.
      </p>

      <div class="tw-col-3-11 tw-mt-3 tw-flex tw-justify-center">
        <button class="secondary" (click)="openExtendedTI()">Erweiterte TI-Prüfung durchführen</button>
      </div>
    </ng-container>

    <ng-container
      *ngIf="[kvnExistingErrors.NO_KVN_DOCTOR_FOUND, kvnExistingErrors.PRACTICE_ACCOUNT_DISABLED].includes(kvnError)"
    >
      <h3>Der Zugang für Praxismitarbeiter ist noch nicht eingerichtet</h3>

      <p>
        Die von der KVN übermittelte Betriebsstätte (BSNR) ist im AOK-Arztportal nicht vorhanden oder enthält noch keine
        Ärzte, die den Zugang für Praxismitarbeiter freigeschaltet haben.
      </p>

      <p>
        Damit Sie das AOK-Arztportal als Praxismitarbeiter nutzen können, sind folgende Schritte
        <span class="aok-bold">durch den delegierenden Arzt</span> durchzuführen:
      </p>

      <ol>
        <li>Der Arzt muss den Zugang über das KVN-Portal zum AOK-Arztportal vollständig einrichten.</li>
        <li>
          Der Arzt muss den Zugriff für KVN-Praxismitarbeiter im AOK-Arztportal freigeben (Freigabe unter: Profil →
          Praxisverwaltung).
        </li>
      </ol>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="kvnError !== kvnExistingErrors.NO_TI_CONNECTION">
  <div class="aok-container help">
    <h5 class="tw-mb-7">Sie haben Fragen zur Einrichtung des KVN-Zugangs?</h5>
    <p class="tw-mb-3">
      Wenden Sie sich an unser Support-Team unter
      <a href="tel:{{ supportTel }}">
        {{ supportTel }}
      </a>
      oder per Mail an
      <a href="mailto:{{ supportEmail }}">
        {{ supportEmail }}
      </a>
    </p>
  </div>
</ng-container>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
