import countryCodes from 'country-codes-list';
import { AuthOption, DropdownSchema } from '../../schemas';

export const isSMSAuthOption = (authOption: AuthOption | string): boolean => authOption === AuthOption.SMS;
export const isCardAuthOption = (authOption: AuthOption | string): boolean => authOption === AuthOption.CARD;
export const isAppAuthOption = (authOption: AuthOption | string): boolean => authOption === AuthOption.APP;

export const getCountryCodes = (): DropdownSchema[] => {
  return countryCodes
    .customArray({
      countryCode: '{countryCode}',
      countryCallingCode: '{countryCallingCode}',
    })
    .map((code) => {
      const paddedCode = code.countryCallingCode.padStart(4, '0');
      return {
        value: paddedCode,
        label: `${code.countryCode} (${paddedCode})`,
      };
    });
};
