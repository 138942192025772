import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DialogRef } from './dialog-ref';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[dialogDispose]' })
export class DialogDisposeDirective<R = any> implements OnInit {
  private _unlisten!: { (): void } | null;

  @Input('dialogDispose') result!: R;

  constructor(protected renderer: Renderer2, protected elementRef: ElementRef, readonly dialogRef: DialogRef<R>) {}

  ngOnInit(): void {
    this.unlisten();
    this._unlisten = this.renderer.listen(this.elementRef.nativeElement, 'click', () =>
      this.dialogRef.dispose(this.result)
    );
  }

  protected unlisten(): void {
    if (this._unlisten != null) {
      this._unlisten();
      this._unlisten = null;
    }
  }
}
