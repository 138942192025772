import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AokSvgIconComponent, DialogOverlayModule } from '@aok/common';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { AokHttpErrorDialogComponent } from './http-error-dialog.component';

@NgModule({
  declarations: [AokHttpErrorDialogComponent],
  exports: [AokHttpErrorDialogComponent],
  imports: [DialogOverlayModule, CommonModule, AokSvgIconComponent, AokDialogLayoutModule],
})
export class AokHttpErrorDialogModule {}
