import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { AokMenuButtonEntry } from '@aok/common';

@Component({
  selector: 'aok-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'aokMenu',
})
export class AokMenuComponent {
  @Input() entries: AokMenuButtonEntry[] = [];

  @Output() selected: EventEmitter<AokMenuButtonEntry> = new EventEmitter<AokMenuButtonEntry>();

  @ViewChild('menu')
  public menu: MatMenu;

  public onClick(entry: AokMenuButtonEntry): void {
    if (!entry.disabled) {
      this.selected.emit(entry);
    }
  }
}
