import { APP_BASE_HREF, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { UrlSerializer, UrlTree } from '@angular/router';
import { KVN_QUERY_PARAM } from '../config/kvn.config';

@Injectable({
  providedIn: 'root',
})
export class AokQueryParamStrategy extends PathLocationStrategy {
  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) baseHref?: string
  ) {
    super(platformLocation, baseHref);
  }

  private get queryString(): string {
    return window.location?.search ?? '';
  }

  prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);
    const existingURLSearchParams = new URLSearchParams(this.queryString);

    const urlTree = this.urlSerializer.parse(path);

    // always preserve following params
    this.preserveParams(urlTree, existingURLSearchParams, [KVN_QUERY_PARAM]);

    return urlTree.toString();
  }

  private preserveParams(urlTree: UrlTree, queryParams: URLSearchParams, params: string[]): void {
    params.forEach((param) => {
      this.setParam(urlTree, queryParams, param);
    });
  }

  private setParam(urlTree: UrlTree, queryParams: URLSearchParams, paramName: string): void {
    const param: string = queryParams.get(paramName);
    if (!param) {
      return;
    }

    // to keep old value: urlTree.queryParams[paramName] = urlTree.queryParams[paramName] || param;
    urlTree.queryParams[paramName] = param;
  }
}
