<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>
<aok-cockpit-header-page-headline [headline]="'Leistungssuche'"></aok-cockpit-header-page-headline>

<aok-cockpit-benefit-search
  *ngIf="displaySearchBar"
  [totalElements]="totalElements"
  [searchActive]="isSearchOrFilterActive"
  [categories]="categories"
  [regions]="regions"
></aok-cockpit-benefit-search>

<aok-cockpit-benefit-tabs
  *ngIf="!isSearchOrFilterActive"
  class="tw-block tw-mt-3"
  [currentTab]="tab"
  [favoriteCount]="favoriteBenefits.value?._embedded?.items?.length || 0"
  (tabChange)="switchTab($event)"
></aok-cockpit-benefit-tabs>

<aok-cockpit-benefit-list
  [benefits]="displayedBenefits | async"
  [isFavoriteEnabled]="isFavoriteEnabled | async"
  [noBenefitsTitle]="noBenefitsTitle"
  [noBenefitsMessage]="noBenefitsMessage"
  (favorite)="toggleFavorite($event)"
></aok-cockpit-benefit-list>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
