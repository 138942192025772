import { KeycloakTokenParsed } from 'keycloak-js';
import { KvnUserTypes } from './kvn.schema';

export enum KnownRoles {
  /** Defines a fully registered and activated HCP user */
  HealthcareProfessional = 'hcp',
  /** Defines a pending (just registered) HCP user that has not yet been activated */
  PendingHealthcareProfessional = 'pending_hcp',
  /** Defines a pending (just registered) MFA user that has not yet been activated */
  PendingAssistant = 'pending_assistant',
  /** Defines a pending KVN HCP user (that has not yet confirmed his email) */
  Assistant = 'assistant',
  /** Defines a literally universally used user */
  Admin = 'aok_admin',
  /** Defines a pending (just registered) KVN user that has not yet been activated */
  PendingKvn = 'pending_kvn',
}

export interface AokKeycloakTokenParsed extends KeycloakTokenParsed {
  email: string;
  bsnr: string | string[];
  lanr: string;
  salutation: string;
  given_name: string; // first name
  family_name: string; // last name
  title: string;
  name: string; // full name
  kvnUniqueId: string;
  kvnApplication: string;
  kvnUserType: KvnUserTypes;
  identity_provider: string;
}

export interface AokKeycloakRoleInitStrategy {
  canInit(roles: string[]): boolean;
  init(roles: string[]): void;
}

export interface AokKeycloakRoleRedirect {
  /** Defines one or multiple keycloak role strings that will be redirected to the url provided by {@link to} */
  redirect: string[] | string;
  /** Defines the url that should be used for the redirect */
  to: string;
}
