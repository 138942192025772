import { NgModule } from '@angular/core';
import { SearchBarComponent } from './search-bar.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokInputFieldModule } from '../cdk';
import { A11yModule } from '@angular/cdk/a11y';
import { SearchInputModule } from '../search-input/search-input.module';

@NgModule({
  declarations: [SearchBarComponent],
  exports: [SearchBarComponent],
  imports: [
    CommonModule,
    FormsModule,
    AokSvgIconComponent,
    A11yUtilsModule,
    AokInputFieldModule,
    A11yModule,
    SearchInputModule,
  ],
})
export class SearchBarModule {}
