import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

export type FormErrorDetectorTickType = 'detectErrors' | 'flush';

@Injectable({ providedIn: 'root' })
export class FormErrorDetectorRef {
  private _markedForCheck = false;
  private _tickStream = new Subject<FormErrorDetectorTickType>();

  readonly ticks = this._tickStream.asObservable();

  constructor(protected ngZone: NgZone) {}

  markForCheck(): void {
    if (!this._markedForCheck) {
      this._markedForCheck = true;
      this.ngZone.onMicrotaskEmpty.pipe(take(1)).subscribe(() => {
        this._markedForCheck = false;
        this.detectErrors();
      });
    }
  }

  detectErrors(): void {
    this._tickStream.next('detectErrors');
  }

  flush(): void {
    this._tickStream.next('flush');
  }
}
