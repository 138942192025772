import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AokBsnrRegistrationClient,
  AokOrg,
  AokOrgTypeIntlPipe,
  AokSpecializationState,
  AokToastService,
  bsnr,
  ContextState,
  DialogBase,
  DialogRef,
  forbiddenTermsValidator,
  getSpecializationDescription,
  lanr9,
} from '@aok/common';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'aok-cockpit-add-bsnr-as-mfa-dialog',
  templateUrl: './add-bsnr-as-mfa-dialog.component.html',
  styleUrls: ['./add-bsnr-as-mfa-dialog.component.scss'],
  providers: [AokOrgTypeIntlPipe],
})
export class AokAddBSNRAsMfaDialog implements OnInit, OnDestroy, DialogBase<boolean> {
  @Input() orgs: AokOrg[];

  public title = 'Weiterer Praxis beitreten';
  public submitLabel1 = `Wenn Sie das Arztportal für eine weitere Betriebsstätte nutzen möchten, geben Sie bitte die
    BSNR und die LANR eines Arztes an dieser Betriebsstätte an.`;
  public submitLabel2 = `Anschließend wird Ihre Anfrage an diesen Arzt übermittelt. Sobald Ihre Anfrage bestätigt
    wurde, ist Ihr Profil dieser Betriebsstätte zugeordnet.`;

  public msgRequiredHint = 'Pflichtfeld';

  public labelBSNRField = 'BSNR';
  public labelLANRField = 'LANR';

  public labelCancelField = 'Abbrechen';
  public labelSubmitField = 'Anfrage senden';

  public specialization: string;
  public specializationOptions = this.specializationState.snapshot;
  public formGroup = new UntypedFormGroup({
    bsnrField: new UntypedFormControl(null, [Validators.required, bsnr]),
    lanrField: new UntypedFormControl(null, [Validators.required, lanr9]),
  });
  private forbiddenCombination: { bsnr: string; lanr: string }[] = [];
  private alreadyRegistered: string[] = [];
  private responseErrorMsgNotExist = 'Invalid input. Bsnr does not exist.';
  private responseErrorMsgCombination = 'Bsnr/Lanr combination is absent.';
  private responseErrorMsgAlreadyRegistered = 'You are already part of this organization.';
  private toastFailHeadline = 'Vorgang fehlgeschlagen';
  private toastFailLabel = `Ihre Anfrage konnte nicht übermittelt werden. Bitte versuchen Sie es erneut oder
    kontaktieren Sie den Support.`;
  private ngDestroys = new Subject<void>();

  constructor(
    public readonly dialogRef: DialogRef<boolean>,
    private client: AokBsnrRegistrationClient,
    private toastService: AokToastService,
    private specializationState: AokSpecializationState,
    private contextState: ContextState
  ) {}

  ngOnInit(): void {
    this.formGroup.get('bsnrField').setValidators([
      Validators.required,
      bsnr,
      forbiddenTermsValidator(
        this.orgs.map((org) => org?.bsnr),
        'alreadyRegistered'
      ),
    ]);

    this.formGroup
      ?.get('lanrField')
      .valueChanges.pipe(
        takeUntil(this.ngDestroys),
        tap(() => {
          this.specialization = this.formGroup.get('lanrField').valid
            ? getSpecializationDescription(this.formGroup.get('lanrField')?.value, this.specializationOptions)
            : null;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  public cancelDialog(): void {
    this.dialogRef.dispose(null);
  }

  public submitDialog(): void {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((formControl) => {
        (formControl as UntypedFormControl).updateValueAndValidity();
      });
    } else {
      const { bsnrField, lanrField } = this.formGroup.value;
      this.client
        .create({
          bsnr: bsnrField,
          lanr: lanrField.slice(0, 7),
          specialization: this.specialization,
          initiatedFromBsnr: this.contextState.bsnr,
        })
        .subscribe({
          next: () => {
            this.dialogRef.dispose(true);
          },
          error: (error: HttpErrorResponse) => {
            const aokError: { message: string }[] = error?.error;
            const errorMsg: string[] = [this.responseErrorMsgCombination, this.responseErrorMsgNotExist];

            if (error.status === 400 && aokError.some(this.checksErrorMsg(errorMsg))) {
              this.forbiddenCombination.push({ bsnr: bsnrField, lanr: lanrField });
              this.formGroup.setValidators([this.bsnrCombinationLanrValidator(this.forbiddenCombination)]);
              this.formGroup.updateValueAndValidity();
            } else if (
              error.status === 400 &&
              aokError.some(this.checksErrorMsg([this.responseErrorMsgAlreadyRegistered]))
            ) {
              this.alreadyRegistered.push(bsnrField);
              this.formGroup
                .get('bsnrField')
                .setValidators([
                  Validators.required,
                  bsnr,
                  forbiddenTermsValidator(this.alreadyRegistered, 'alreadyRegistered'),
                ]);
              this.formGroup.get('bsnrField').updateValueAndValidity();
            } else {
              this.toastService.createSupportErrorToast(this.toastFailHeadline, this.toastFailLabel);
            }
          },
        });
    }
  }

  private checksErrorMsg(forbiddenMsg: string[]) {
    return ({ message }): boolean => {
      return forbiddenMsg.includes(message);
    };
  }

  private bsnrCombinationLanrValidator(forbidden: { bsnr: string; lanr: string }[]) {
    return (group: UntypedFormGroup) => {
      const { bsnrField, lanrField } = group.controls;
      // checks combination which already was used.
      if (forbidden.some((values) => values.bsnr === bsnrField.value && values.lanr === lanrField.value)) {
        bsnrField.setErrors({ combinationLanrBsnr: true });
        lanrField.setErrors({ combinationLanrBsnr: true });
      } else {
        // if no combination was found, then reset both fields.
        if (bsnrField.hasError('combinationLanrBsnr') && !lanrField.hasError('combinationLanrBsnr')) {
          bsnrField.updateValueAndValidity();
        } else if (lanrField.hasError('combinationLanrBsnr') && !bsnrField.hasError('combinationLanrBsnr')) {
          lanrField.updateValueAndValidity();
        }
      }
      return null;
    };
  }
}
