<aok-cockpit-header [isLogoALink]="false"></aok-cockpit-header>

<aok-cockpit-header-page-headline [headline]="headline" [showBreadcrumbs]="false"></aok-cockpit-header-page-headline>

<div class="aok-bg-pastel-blue-2 tw-mb-2 tw-mt-3">
  <div class="aok-container">
    <div class="tw-col-3-11 tw-pt-4 tw-pb-3 text-center">
      <aok-svg-icon class="tw-mb-5" name="0858-checkmark_circle" size="76px"></aok-svg-icon>

      <h3 class="tw-mb-6">Ihre Einrichtung wurde erfolgreich abgeschlossen</h3>

      <p>
        Die Einrichtung ist abgeschlossen. Bitte melden Sie sich erneut im KVN-Portal an, um auf die Vertragsverwaltung
        im AOK-Arztportal abzuspringen.
      </p>

      <ng-content></ng-content>
    </div>
  </div>
</div>

<div class="aok-container">
  <div class="tw-col-1-13 tw-mb-1">
    <h5 class="tw-mb-7">Sie haben Fragen zur Einrichtung des KVN-Zugangs?</h5>
    <p>
      Wenden Sie sich an unser Support-Team unter 0511 285 - 34679 oder per Mail an
      <a href="mailto:aok-arztportal@nds.aok.de">aok-arztportal@nds.aok.de</a>
    </p>
  </div>
</div>

<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
