<dialog-layout
  [fullscreen]="true"
  [isClosable]="true"
  [headerText]="'Zusätzliche Betriebsstätte übernehmen'"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <aok-stepper #stepper [navMode]="NAV_MODE.FREE_SEQUENTIAL" [customButtonBar]="true">
    <ng-container *ngFor="let control of formArray.controls; let i = index; let $first = first">
      <aok-step
        [title]="control.get('organizationName')?.value?.trim() || 'Daten Praxis ' + (i + 1)"
        [description]="'Möchten Sie die Betriebsstätte aus dem KVN-Portal übernehmen? Nach der Aktualisierung können Sie die aktuellen Daten in Ihrem Profil sehen.'"
        [iconName]="'0000-hospital'"
        [validate]="practiceForm.validateForm.bind(practiceForm)"
        [active]="$first"
        cdkFocusInitial
      >
        <aok-practice-form [formGroup]="control" #practiceForm></aok-practice-form>
      </aok-step>
    </ng-container>
    <div
      button-bar
      class="tw-col-1-9 tw-mt-3 tw-flex"
      [ngClass]="stepper.hasMultipleSteps && stepper.isFirstStep ? 'tw-justify-end' : 'tw-justify-between'"
    >
      <button *ngIf="!stepper.isFirstStep" class="secondary" (click)="stepper.previous()">Zurück</button>
      <div class="tw-flex tw-gap-6" [class.tw-flex-row-reverse]="stepper.hasMultipleSteps">
        <div>
          <button *ngIf="stepper.isLastStep" (click)="submit(stepper.currentStep)">Betriebsstätte übernehmen</button>
          <button *ngIf="stepper.hasMultipleSteps && !stepper.isLastStep" (click)="stepper.next()">Weiter</button>
        </div>
        <button class="secondary" (click)="dialogRef.dispose()">Später durchführen</button>
      </div>
    </div>
  </aok-stepper>
</dialog-layout>
