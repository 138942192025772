import { Inject, Injectable } from '@angular/core';

import {
  AokBsnrRegistration,
  AokBsnrRegistrationClient,
  AokBsnrRegistrationStatus,
  AokDialogResult,
  AokMfaToBSNRRegistration,
  AokOrg,
  AokRegistrationOrgData,
  AokRegistrationToOrganisation,
  AokSvsService,
  AokToastService,
  AokUser,
  AokUserClient,
  AokUserRegistration,
  AokUserRegistrationClient,
  DialogOverlay,
  ENVIRONMENT,
  EnvironmentSchema,
} from '@aok/common';
import { EMPTY, mergeMap, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  AddPracticesDialog,
  AokAddBSNRAsDoctorDialog,
  AokAddBSNRAsMfaDialog,
  AokChangeEmailDialog,
  AokCookieDialog,
  AokMfaDialog,
  AokMfaRequestDialog,
  AokSimpleDialog,
  ReportContactDialog,
} from '../components';
import { AokRemovePracticeStaffDialog } from '../components/dialogs/remove-practice-staff-dialog/remove-practice-staff-dialog.component';
import { DialogResult, EditMfaDialogBody, MfaDialogAction, ProfileAssistant, SimpleDialogBody } from '../schema';

@Injectable({
  providedIn: 'root',
})
export class DialogManagerService {
  private msgSuccessfullyOperationHeadline = 'Vorgang erfolgreich';
  private msgSuccessfullyActionHeadline = 'Aktion erfolgreich';
  private msgSuccessfullyTransmittedHeadline = 'Ihre Daten wurden erfolgreich übermittelt.';

  private msgSuccessfullyMfaRequestAcceptedLabel = `Der Praxismitarbeiter wurde Ihrer Praxis zugeordnet.
    Der Praxismitarbeiter wird hierüber informiert.`;
  private msgSuccessfullyMfaRequestRejectedLabel = `Die Anfrage des Praxismitarbeiters wurde abgelehnt. Der
    Praxismitarbeiter wird hierüber informiert.`;
  private msgSuccessfullyDeleteRequestLabel = 'Ihre Anfrage wurde gelöscht.';
  private msgSuccessfullyAddPracticeLabel = `Die Daten wurden erfolgreich übermittelt. Nach  Prüfung der Daten erhalten
    Sie eine Nachricht.`;
  private msgSuccessfullyMfaReceiveMailLabel = 'Der Praxismitarbeiter erhält eine Einladung per E-Mail.';
  private msgSuccessfullyJoinPracticeLabel = `Die Praxisdaten wurden erfolgreich übermittelt. Sobald die Praxisdaten
    geprüft wurden, erhalten Sie eine Nachricht.`;

  private msgFailedHeadline = 'Vorgang fehlgeschlagen';

  private msgFailedMfaRequestLabel = `Die Beitrittsanfrage wurde mittlerweile vom Antragsteller zurückgezogen.`;
  private msgFailedTechnicalErrorLabel = 'Die Daten konnten nicht gelöscht werden. Bitte versuchen Sie es erneut.';

  constructor(
    private dialog: DialogOverlay,
    private toastService: AokToastService,
    private registrationClient: AokUserRegistrationClient,
    private bsnrRegistrationClient: AokBsnrRegistrationClient,
    private userClient: AokUserClient,
    private svsService: AokSvsService,
    @Inject(ENVIRONMENT) private environment: EnvironmentSchema
  ) {}

  public openJoinRequestToPracticeDoctorDialog(
    org: AokRegistrationToOrganisation,
    alreadyJoined: string[],
    alreadyRequested: string[]
  ): Observable<boolean> {
    return this.dialog
      .create(AokAddBSNRAsDoctorDialog, {
        closable: true,
        props: {
          schema: org,
          joinedPractices: alreadyJoined,
          joinRequest: alreadyRequested.filter((request) => request !== org?.organizationData?.bsnr),
        },
      })
      .pipe(
        tap((success) => {
          if (success) {
            this.toastService.createSuccessToast(
              this.msgSuccessfullyOperationHeadline,
              this.msgSuccessfullyAddPracticeLabel
            );
          }
        })
      );
  }

  public openJoinRequestToPracticeMfaDialog(orgs: AokOrg[]): Observable<boolean> {
    return this.dialog.create(AokAddBSNRAsMfaDialog, { closable: true, props: { orgs } }).pipe(
      tap((success) => {
        if (success) {
          this.toastService.createSuccessToast(
            this.msgSuccessfullyActionHeadline,
            this.msgSuccessfullyJoinPracticeLabel
          );
        }
      })
    );
  }

  public openDialogToDeleteMFAJoinBSNRRequest(id: number): Observable<boolean> {
    return this.openSimpleDialog({
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Anfrage löschen',
      headerText: 'Beitrittsanfrage löschen',
      contentText: `Sind Sie sicher, dass Sie diese Anfrage löschen möchten? Wenn Sie auf „Anfrage löschen“ klicken,
          wird Ihre Anfrage beim betreffenden Arzt entfernt. Sie können anschließend eine erneute Beitrittsanfrage stellen.`,
      closable: true,
    }).pipe(
      mergeMap((dialogResponse) => {
        if (dialogResponse === AokDialogResult.CONFIRM) {
          return this.bsnrRegistrationClient.delete(id).pipe(
            map(() => {
              this.toastService.createSuccessToast(
                this.msgSuccessfullyOperationHeadline,
                this.msgSuccessfullyDeleteRequestLabel
              );
              return true;
            }),
            catchError(() => {
              this.toastService.createSupportErrorToast(this.msgFailedHeadline, this.msgFailedTechnicalErrorLabel);
              return of(false);
            })
          );
        } else {
          return of(false);
        }
      })
    );
  }

  public openDialogToDeletePracticeRelation(userId: number, bsnr: string): Observable<boolean> {
    return this.openSimpleDialog({
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Praxiszugehörigkeit löschen',
      headerText: 'Praxiszugehörigkeit löschen',
      contentText: `Sind Sie sicher, dass Sie Ihre Zugehörigkeit zu dieser Praxis löschen möchten? Wenn Sie auf „Praxiszugehörigkeit löschen“ klicken, wird Ihre Verknüpfung mit dieser Praxis und den dazugehörigen Ärzten gelöscht. Sie können anschließend nicht mehr auf diese Praxis zugreifen.`,
      closable: true,
    }).pipe(
      mergeMap((dialogResponse) => {
        if (dialogResponse === AokDialogResult.CONFIRM) {
          return this.userClient.removePMFromPractice(userId, bsnr).pipe(
            map(() => {
              this.toastService.createSuccessToast(
                this.msgSuccessfullyOperationHeadline,
                'Die Praxiszugehörigkeit wurde gelöscht.'
              );
              return true;
            }),
            catchError(() => {
              this.toastService.createSupportErrorToast(
                this.msgFailedHeadline,
                'Die Praxiszugehörigkeit konnte nicht gelöscht werden. Bitte aktualisieren Sie diese Seite und versuchen Sie es erneut. '
              );
              return of(false);
            })
          );
        } else {
          return of(false);
        }
      })
    );
  }

  public openChangeUserMailDialog(activeUser: AokUser): Observable<boolean> {
    return this.dialog
      .create(AokChangeEmailDialog, {
        closable: true,
        props: {
          activeUser,
        },
      })
      .pipe(
        tap((success) => {
          if (success) {
            this.toastService.createSuccessToast(
              this.msgSuccessfullyTransmittedHeadline,
              this.msgSuccessfullyMfaReceiveMailLabel
            );
          }
        })
      );
  }

  public openMfaDialog(body: EditMfaDialogBody): Observable<DialogResult<MfaDialogAction>> {
    const { currentUser, org, mfa } = body;
    return this.dialog.create(AokMfaDialog, {
      closable: true,
      props: {
        user: currentUser,
        org: org,
        schema: mfa,
      },
    });
  }

  public openEditMfaRequestToJoinPracticeDialog(
    requestMember: AokMfaToBSNRRegistration | AokBsnrRegistration
  ): Observable<boolean> {
    return this.dialog
      .create(AokMfaRequestDialog, {
        closable: true,
        props: {
          requestMember: requestMember,
        },
      })
      .pipe(
        mergeMap((result) => {
          if (result) {
            return this.bsnrRegistrationClient.registrationStatusChange(requestMember.id, result).pipe(
              map(() => {
                this.toastService.createSuccessToast(
                  this.msgSuccessfullyOperationHeadline,
                  result === AokBsnrRegistrationStatus.ACCEPTED
                    ? this.msgSuccessfullyMfaRequestAcceptedLabel
                    : this.msgSuccessfullyMfaRequestRejectedLabel
                );
                return true;
              }),
              catchError(() => {
                this.toastService.createErrorToast(this.msgFailedHeadline, this.msgFailedMfaRequestLabel);
                return of(false);
              })
            );
          } else {
            return of(false);
          }
        })
      );
  }

  /**
   * Open dialog for PM removal confirmation
   *
   * @returns true if successfully removed
   * @returns false otherwise
   */
  public openRemovePMDialog(user: ProfileAssistant): Observable<boolean> {
    return this.dialog.create(AokRemovePracticeStaffDialog, {
      closable: true,
      props: {
        user,
      },
    });
  }

  public deletePMInvite(user: AokUserRegistration): Observable<void> {
    return this.openSimpleDialog({
      headerText: 'Einladung löschen',
      contentText:
        'Sind Sie sicher, dass Sie diese Einladung löschen möchten? Wenn Sie auf „Einladung löschen“ klicken, werden Ihre Einladung und alle Daten des betreffenden Praxismitabeiters entfernt. Sie können anschließend erneut einladen.',
      confirmButtonText: 'Einladung löschen',
      cancelButtonText: 'Abbrechen',
    }).pipe(
      mergeMap((dialogResponse) => {
        if (dialogResponse === AokDialogResult.CONFIRM) {
          return this.registrationClient.delete(user.id).pipe(
            tap(() => {
              this.toastService.createSuccessToast(
                'Vorgang erfolgreich',
                'Die Einladung und das Profil des Praxismitarbeiters wurden gelöscht.'
              );
            }),
            catchError((error) => {
              this.toastService.createErrorToast(
                'Vorgang fehlgeschlagen',
                'Der Praxismitarbeiter konnte nicht gelöscht werden. Bitte aktualisieren Sie diese Seite und versuchen Sie es erneut.'
              );

              return throwError(() => error);
            })
          );
        }

        return EMPTY;
      })
    );
  }

  public openAddPracticesOverlay(keycloakId: string, bsnrs: string[]): Observable<boolean> {
    return this.svsService.retrieveSvsPractitionerData(bsnrs).pipe(
      map((svsOrgs) => {
        return svsOrgs.map((svsOrg, index) => svsOrg || { bsnr: bsnrs[index] });
      }),
      map(this.svsService.svsOrgsToOrgRegistrations.bind(this.svsService)),
      mergeMap((svsOrgs: Partial<AokRegistrationOrgData>[]) => {
        return this.dialog.create(AddPracticesDialog, {
          props: {
            keycloakId,
            organizations: svsOrgs,
          },
        });
      })
    );
  }

  public openCookieDialog(): Observable<boolean> {
    return this.dialog.create(AokCookieDialog);
  }

  public openReportContactDialog(): Observable<void> {
    return this.dialog.create(ReportContactDialog, {
      props: {
        enabledCategories: {
          HzV: this.environment.enableHZVReports,
          Arzneimittel: this.environment.enableArzneimittelReports,
          Heilmittel: this.environment.enableHeilmittelReports,
        },
      },
    });
  }

  /**
   * returns observable of the AokDialogResult
   */
  private openSimpleDialog(body: SimpleDialogBody): Observable<AokDialogResult> {
    const {
      headerText,
      contentText,
      cancelButtonText,
      confirmButtonText,
      confirmFunction,
      closeText,
      maxWidth,
      closable,
    } = body;
    return this.dialog.create(AokSimpleDialog, {
      closable: body.closable,
      props: {
        headerText,
        contentText,
        cancelButtonText,
        confirmButtonText,
        confirmFunction,
        closeText,
        maxWidth,
        closable,
      } as never,
    });
  }
}
