import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AokUserClient } from '../clients';
import { AddressData, AokRegistrationOrgData, SvsPractitioner } from '../schemas';

@Injectable({ providedIn: 'root' })
export class AokSvsService {
  constructor(private userClient: AokUserClient) {}

  /**
   * retrieve SVS practitioner data for the given BSNRs
   */
  public retrieveSvsPractitionerData(bsnrs: string[]): Observable<SvsPractitioner[]> {
    const requests: Observable<SvsPractitioner>[] = [];
    bsnrs.forEach((bsnr) => {
      requests.push(
        this.userClient.getPractitionerDetails(bsnr).pipe(
          catchError(() => {
            return of(null);
          })
        )
      );
    });
    return forkJoin(requests);
  }

  public svsOrgsToOrgRegistrations(svsOrgs: SvsPractitioner[]): Partial<AokRegistrationOrgData>[] {
    return svsOrgs.map(this.svsOrgToOrgRegistration.bind(this));
  }

  public getSvsOrgAddress(svsOrg?: SvsPractitioner): AddressData {
    const streetRegex = /(?<streetName>\D*)(?<streetNumber>\d+)/;
    const result = streetRegex.exec(svsOrg?.street);
    return {
      zipCode: svsOrg?.zipCode,
      streetName: svsOrg?.houseNumber ? svsOrg?.street : result?.groups?.streetName?.trim(),
      streetNumber: svsOrg?.houseNumber || result?.groups?.streetNumber?.trim(),
      location: svsOrg?.city,
    };
  }

  private svsOrgToOrgRegistration(org: SvsPractitioner): Partial<AokRegistrationOrgData> {
    return {
      bsnr: org.bsnr,
      address: this.getSvsOrgAddress(org),
    };
  }
}
