export enum MfaDialogMode {
  INVITE = 'INVITE',
  EDIT_INVITE = 'EDIT_INVITE',
  EDIT_USER = 'EDIT_USER',
}

export enum MfaDialogAction {
  DELETE = 'DELETE', // delete invitation
  REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE',
  EDIT = 'EDIT',
  INVITED = 'INVITED',
}

// fixme invite mfa data or any kind of mda dialog data?
export interface MfaDialogFormData {
  salutation: string;
  title: string;
  firstname: string;
  lastname: string;
  mailAddress: string;
  phoneNumber: string;
  consentDeclaration?: boolean;
}
