<div class="wrapper" cdkTrapFocus>
  <div class="header tw-flex tw-justify-between">
    <span>Ihr Feedback</span>
    <span class="icon-button" aokFocusable (click)="dialogRef.dispose(false)" (keyup.enter)="dialogRef.dispose(false)">
      Abbrechen
      <aok-svg-icon name="close-small" class="tw-ml-10" size="32px"></aok-svg-icon>
    </span>
  </div>

  <div class="content" aokFocusFirstChild>
    <div>
      <h6 class="tw-mb-8">Wie sind Ihre Erfahrungen mit der Nutzung des Arztportals?</h6>
      <p>
        Senden Sie uns Ihr Feedback, damit wir das Arztportal weiter verbessern können. Haben Sie Fragen oder benötigen
        Sie Hilfe? Dann nehmen Sie <a href="mailto:{{ supportEmail }}">Kontakt zum Support</a> auf.
      </p>

      <p class="required-label">* Pflichtfeld</p>

      <aok-input-field id="category" label="Kategorie" required="true">
        <aok-dropdown [control]="form.get('category')" [options]="categories"></aok-dropdown>
      </aok-input-field>
    </div>

    <aok-input-field label="Feedback" required class="tw-mb-7">
      <textarea
        [formControl]="form.get('feedback')"
        placeholder="Bitte geben Sie hier nur Ihr Feedback ein. Hilfe zum Arztportal bekommen Sie über den Support."
        [maxlength]="maxCharacters"
        aokTrim
      ></textarea>

      <span *ngIf="form.get('feedback').valid || !form.get('feedback').dirty" class="max-length">
        {{ maxCharacters - feedbackLength }} Zeichen verbleibend
      </span>
    </aok-input-field>

    <aok-input-field id="consent" *ngIf="!kvnState.isKvnPracticeTeam()">
      <aok-checkbox [formControl]="form.get('consent')">
        Ich bin damit einverstanden, dass die AOK mich zu meinem Feedback kontaktiert.
      </aok-checkbox>
    </aok-input-field>

    <p class="tw-mb-8">Bitte beachten Sie die <a routerLink="/datenschutz">Datenschutzbedingungen</a>.</p>

    <button (click)="submit()">
      <aok-svg-icon name="arrow-rounded_right" size="16px"></aok-svg-icon>
      Feedback senden
    </button>
  </div>
</div>
