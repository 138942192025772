<div class="grid-10 lg:grid-8" [formGroup]="formGroup">
  <ng-container *ngIf="errorIds.length">
    <ng-container *ngTemplateOutlet="userMessages"></ng-container>
  </ng-container>
  <div class="full-width required-label">
    <aok-required-mark></aok-required-mark>
    Pflichtfeld
  </div>

  <h5 class="full-width practice-data">Praxisdaten</h5>

  <div class="first-half-width bsnr-number">
    <aok-input-field id="bsnr" [label]="formLabels.bsnr" [required]="true">
      <input formControlName="bsnr" aokTrim />
    </aok-input-field>

    <aok-popover
      *ngIf="formGroup.get('bsnr').enabled"
      [width]="popoverRightDirectionWidth"
      [direction]="popoverDirectionSide"
    >
      <p>Geben Sie die BSNR der Betriebsstätte ein, die Sie Ihrem Profil hinzufügen wollen.</p>
      <br />
      <p>Hinweis: Im AOK-Arztportal erstellte Praxen werden nicht mit dem KVN-Portal synchronisiert.</p>
    </aok-popover>
  </div>

  <div class="first-half-width practice-type">
    <aok-input-field id="organizationType" [label]="formLabels.organizationType" [required]="true">
      <aok-dropdown [control]="formGroup.get('organizationType')" [options]="practiceTypeOption"></aok-dropdown>
    </aok-input-field>
  </div>
  <div class="first-half-width practice-name">
    <aok-input-field id="organizationName" [label]="formLabels.organizationName" [required]="true">
      <input formControlName="organizationName" aokTrim />
    </aok-input-field>
    <aok-popover [width]="popoverRightDirectionWidth" [direction]="popoverDirectionSide">
      <aok-popover-content-template
        [text]="'Wenn es keinen expliziten Praxisnamen gibt, geben Sie bitte den Namen des Arztes an.'"
      ></aok-popover-content-template>
    </aok-popover>
  </div>
  <h5 class="full-width practice-address">Praxisanschrift</h5>
  <div class="street">
    <aok-input-field id="street" [label]="formLabels.street" [required]="true">
      <input formControlName="street" aokTrim />
    </aok-input-field>
  </div>
  <div class="street-number">
    <aok-input-field id="streetNumber" [label]="formLabels.streetNumber" [required]="true">
      <input formControlName="streetNumber" aokTrim />
    </aok-input-field>
  </div>
  <div class="zip-code">
    <aok-input-field id="zipCode" [label]="formLabels.zipCode" [required]="true">
      <input formControlName="zipCode" aokTrim />
    </aok-input-field>
  </div>
  <div class="location">
    <aok-input-field id="location" [label]="formLabels.location" [required]="true">
      <input formControlName="location" aokTrim />
    </aok-input-field>
  </div>
</div>

<ng-template #userMessages>
  <div class="tw-col-1-11 lg:tw-col-1-9">
    <aok-notification-banner
      accent="bad"
      [bannerHeadline]="bannerHeadline"
      [bannerLabel]="bannerLabel"
      [bannerLinks]="errorIds"
    >
    </aok-notification-banner>
  </div>
</ng-template>
