import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { KEYCLOAK_OPTIONS } from './keycloak.initializer';

export const keycloakInitGuard: CanMatchFn | CanActivateFn = (): Observable<boolean> => {
  const keycloak = inject(KeycloakService);
  const options = inject(KEYCLOAK_OPTIONS);

  return from(keycloak.isLoggedIn()).pipe(
    mergeMap((isLoggedIn) => {
      return isLoggedIn ? of(isLoggedIn) : from(keycloak.init(options));
    })
  );
};
