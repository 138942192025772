import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AokBenefit,
  AokBenefitCategory,
  AokBenefitClient,
  AokBenefitRegion,
  AokBenefitSearchResult,
  AokPage,
  DialogOverlay,
} from '@aok/common';
import { AokFooterModule, FeedbackButtonModule, HeaderModule, InfoBannerComponent } from '@aok/components';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BENEFITS_TAB } from '../../../schema/benefits.schema';
import { BenefitsService } from '../../../services/benefits.service';
import { BenefitListComponent } from './benefit-list/benefit-list.component';
import { BenefitSearchComponent } from './benefit-search/benefit-search.component';
import { BenefitTabsComponent } from './benefit-tabs/benefit-tabs.component';

@Component({
  selector: 'aok-cockpit-benefits',
  standalone: true,
  imports: [
    CommonModule,
    HeaderModule,
    InfoBannerComponent,
    FeedbackButtonModule,
    AokFooterModule,
    BenefitSearchComponent,
    BenefitTabsComponent,
    BenefitListComponent,
  ],
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitsComponent implements OnInit, OnDestroy {
  public displayedBenefits = new BehaviorSubject<AokPage<AokBenefitSearchResult | AokBenefit>>(null);
  public overviewBenefits = new BehaviorSubject<AokPage<AokBenefitSearchResult | AokBenefit>>(null);
  public favoriteBenefits = new BehaviorSubject<AokPage<AokBenefit>>(null);
  public tab: BENEFITS_TAB = BENEFITS_TAB.OVERVIEW;
  public totalElements: number;
  public displaySearchBar: boolean;
  public isSearchOrFilterActive: boolean;
  public noBenefitsMessage: string;
  public noBenefitsTitle: string;
  public categories: AokBenefitCategory[] = [];
  public regions: AokBenefitRegion[] = [];

  public get isFavoriteEnabled(): Observable<boolean> {
    return this.benefitsService.isFavoriteEnabled();
  }

  private ngDestroyed = new Subject<void>();

  constructor(
    protected client: AokBenefitClient,
    protected route: ActivatedRoute,
    protected dialog: DialogOverlay,
    protected router: Router,
    protected cd: ChangeDetectorRef,
    private benefitsService: BenefitsService
  ) {}

  ngOnInit(): void {
    this.loadBenefitsData();
  }

  ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  public toggleFavorite(benefitId: number): void {
    this.benefitsService.toggleFavorite(benefitId).subscribe((favorites) => {
      this.favoriteBenefits.next(favorites);
      this.markFavoriteBenefits(this.overviewBenefits.value);
      this.setTabData();
    });
  }

  public switchTab(tab: BENEFITS_TAB): void {
    this.tab = tab;
    this.setTabData();
  }

  private setTabData(): void {
    if (this.isFavoritesTab()) {
      this.displayedBenefits.next(this.favoriteBenefits.value);
      this.setFavoriteTabMessages();
    } else {
      this.displayedBenefits.next(this.overviewBenefits.value);
      this.setOverviewTabMessages();
    }
    this.cd.markForCheck();
  }

  private loadBenefitsData(): void {
    this.favoriteBenefits.next(this.route.snapshot.data.favoriteBenefits);
    this.route.data.pipe(takeUntil(this.ngDestroyed)).subscribe(({ benefits, categories, regions }) => {
      this.categories = categories;
      this.regions = regions;
      this.markFavoriteBenefits(benefits);
      this.updatePageElements(benefits);
      this.setTabData();
    });
  }

  private updatePageElements(overviewBenefits: AokPage<AokBenefitSearchResult | AokBenefit>): void {
    this.updateElements(overviewBenefits);
    this.updateMessages();
  }

  private updateElements(overviewBenefits: AokPage<AokBenefitSearchResult | AokBenefit>): void {
    const { query, category, region } = this.route.snapshot.queryParams;
    this.isSearchOrFilterActive = !!(query || category || region);
    this.displaySearchBar = !!(overviewBenefits?._embedded?.items.length || this.isSearchOrFilterActive);
    this.totalElements = overviewBenefits?.page?.totalElements || 0;
    this.tab = this.isSearchOrFilterActive ? BENEFITS_TAB.OVERVIEW : this.tab;
  }

  private updateMessages(): void {
    if (this.isFavoritesTab()) {
      this.setFavoriteTabMessages();
      return;
    }

    this.setOverviewTabMessages();
    this.cd.markForCheck();
  }

  private markFavoriteBenefits(overviewBenefits: AokPage<AokBenefitSearchResult | AokBenefit>): void {
    const updatedBenefits = this.benefitsService.markFavoriteBenefits(overviewBenefits, this.favoriteBenefits.value);
    this.overviewBenefits.next(updatedBenefits);
  }

  private isFavoritesTab(): boolean {
    return this.tab === BENEFITS_TAB.FAVORITES;
  }

  private setFavoriteTabMessages(): void {
    this.noBenefitsTitle = 'Es sind noch keine Favoriten vorhanden.';
    this.noBenefitsMessage = 'Klicken Sie auf das Stern-Icon einer Leistung, um diese als Favorit zu markieren.';
  }

  private setOverviewTabMessages(): void {
    if (this.isSearchOrFilterActive) {
      // case when the search does not return any results
      this.noBenefitsTitle = 'Es wurde keine passende Leistung gefunden';
      this.noBenefitsMessage = 'Versuchen Sie es mit einer anderen Schreibweise oder einem Synonym des Suchbegriffs.';
    } else {
      //  case when there are no published benefits available at all
      this.noBenefitsTitle = 'Derzeit sind keine Leistungen vorhanden.';
      this.noBenefitsMessage =
        'Die Leistungen werden regelmäßig aktualisiert, bitte überprüfen Sie die Ansicht zu einem späteren Zeitpunkt erneut.';
    }
  }
}
