import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccordionOption } from '../../schema';
import { AccordionService } from '../../services';

@Component({
  selector: 'aok-accordion-option',
  styleUrls: ['./accordion-option.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './accordion-option.component.html',
})
export class AokAccordionOptionComponent implements OnDestroy, OnChanges {
  @Input() option: AccordionOption;
  @Input() index: number;
  @Input() accordionId: string;
  @Input() withoutBorderGap = false;

  private selectedOptionId: string;
  private ngDestroy = new Subject<void>();
  private readonly onChangesGenerator$ = new Subject<void>();
  private readonly onChanges$ = this.onChangesGenerator$.pipe();

  constructor(public accordionService: AccordionService, private changeDetection: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.onChangesGenerator$.next();

    this.accordionService
      .getBehaviorStatus(this.accordionId)
      .pipe(takeUntil(this.onChanges$))
      .subscribe((selectedId) => {
        this.selectedOptionId = selectedId;
        this.changeDetection.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onChangesGenerator$.next();
    this.onChangesGenerator$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public openAccordionOption(event?: Event): void {
    const element = event.target as HTMLElement;

    // We need this check to prevent the accordion from being triggered when using 'keyup.enter' on focusable elements inside the accordion, and to skip click-type events.
    if ((event.type !== 'click' && element?.className?.split(' ').includes('option')) || event.type === 'click') {
      this.accordionService.toggleAccordionOption(this.accordionId, this.option.id);
      this.changeDetection.detectChanges();
    }
  }

  public isOptionOpen(): boolean {
    return this.selectedOptionId === this.option?.id;
  }
}
