<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>

<div class="aok-container body">
  <div class="breadcrumbs">
    <aok-breadcrumbs [homeLinkUrl]="'/uebersicht'"></aok-breadcrumbs>
  </div>
  <h3>{{ pageTitle | async }}</h3>
  <div class="content" [innerHTML]="('article' | aokRouteData | async)?.content"></div>
</div>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
