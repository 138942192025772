import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  TrackByFunction,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ArrayBehaviorState,
  ContextState,
  flattenTree,
  getContextSpecificUrl,
  getKvnStateUrl,
  isString,
  mapAokNavEntryChildren,
  NavigationEntry,
  RouterLinkOrHrefDirective,
} from '@aok/common';
import { KvnState } from '../../states';
import { AokSitemap, SITEMAP } from '../cdk';

export interface AokNavEntry extends NavigationEntry {
  childrenFromSitemap?: boolean;
}

@Component({
  selector: 'aok-nav',
  styleUrls: ['nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './nav.component.html',
})
export class AokNavComponent {
  readonly viewEntriesState = new ArrayBehaviorState<NavigationEntry>();

  readonly viewEntries = this.viewEntriesState
    .asObservable()
    .pipe(mapAokNavEntryChildren((entry) => this.resolveEntryChildrenFromSitemap(entry)));

  activeEntry: (NavigationEntry & { children: any }) | null;

  @Input() appHub;
  @ViewChildren(RouterLinkOrHrefDirective) childLinks;

  @Input()
  set entries(value: NavigationEntry[]) {
    this.viewEntriesState.reset(...value);
  }

  constructor(
    public router: Router,
    protected changeDetector: ChangeDetectorRef,
    @Inject(SITEMAP) protected sitemap: AokSitemap,
    private contextState: ContextState,
    private injector: Injector,
    private kvnState: KvnState,
    private route: ActivatedRoute
  ) {}

  readonly navEntryTracker: TrackByFunction<NavigationEntry> = (index, entry) => entry.linkUrl;

  public isParentEntryAndActive(entry: AokNavEntry): boolean {
    // TODO extend NavigationEntry with children prop?
    const targetEntry = entry as NavigationEntry & { children: NavigationEntry };
    return this.activeEntry === targetEntry && targetEntry?.children?.length > 0;
  }

  public closeOnClickOutside(e: Event): void {
    const clickedOutsideHeader = !e
      .composedPath()
      .includes(document.getElementsByTagName('aok-cockpit-header').item(0));
    if (clickedOutsideHeader) {
      this.closeSubNavigation();
    }
  }

  public handleNavigation(entry: NavigationEntry, manuallyRedirect = false): void {
    if (!this.canActivate(entry)) {
      return;
    }

    this.setActiveLevelEntry(entry);
    if (manuallyRedirect) {
      isString(entry.linkUrl) ? this.router.navigateByUrl(entry.linkUrl) : this.router.navigate(entry.linkUrl);
    }
  }

  public closeSubNavigation(): void {
    this.activeEntry = null;
  }

  public interpolateContext(entry: { linkUrl: string }): string {
    const contextUrl = getContextSpecificUrl(entry.linkUrl, this.contextState);
    return getKvnStateUrl(contextUrl, this.route.snapshot, this.kvnState.isKvn());
  }

  protected resolveEntryChildrenFromSitemap(entry: NavigationEntry): NavigationEntry[] {
    if (!(entry as AokNavEntry).childrenFromSitemap) return [];

    const flatSitemap = flattenTree(Array.from(this.sitemap));
    const site = flatSitemap.find((s) => s.title.toLowerCase() === entry.name.toLowerCase());

    return site?.children?.map(
      ({ linkUrl, title: name, target, contract }) =>
        <NavigationEntry>{
          linkUrl,
          name,
          target,
          contract,
        }
    );
  }

  private setActiveLevelEntry(entry: AokNavEntry): void {
    const newEntry = entry as NavigationEntry & { children: any };
    if (newEntry === this.activeEntry) {
      this.closeSubNavigation();
    } else {
      this.activeEntry = newEntry;
    }

    this.changeDetector.markForCheck();
  }

  /**
   * check whether the nav entry can be activated
   */
  private canActivate(entry: NavigationEntry): boolean {
    if (entry.canActivate) {
      const deps = this.injectDependencies(entry);
      return entry.canActivate(...(deps || []));
    }

    return true;
  }

  private injectDependencies(entry: NavigationEntry): unknown[] {
    const depValues = Object.values(entry.deps || {});
    // TODO check how to use not-deprecated .get() method
    return depValues?.map((dep) => this.injector.get(dep));
  }
}
