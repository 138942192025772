import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { AokFeedbackCategoriesState } from './feedback-categories.state';

export function initFeedbackCategories(feedbackCategoriesState: AokFeedbackCategoriesState) {
  return /** @dynamic */ () => {
    return feedbackCategoriesState.init();
  };
}

@NgModule()
export class AokFeedbackCategoriesModule {
  static forRoot(): ModuleWithProviders<AokFeedbackCategoriesModule> {
    return {
      ngModule: AokFeedbackCategoriesModule,
      providers: [
        AokFeedbackCategoriesState,
        {
          provide: APP_INITIALIZER,
          useFactory: initFeedbackCategories,
          deps: [AokFeedbackCategoriesState],
          multi: true,
        },
      ],
    };
  }
}
