import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { environment } from '../../environments/environment';

export const benefitsEnabledGuard: CanActivateFn | CanActivateChildFn = (): boolean => {
  if (environment.enableBenefits) {
    return true;
  } else {
    inject(Router).navigate(['/uebersicht']);
    return false;
  }
};
