import { AokPageQueryParams } from './page.schema';
import { AokStaticArticle } from './static-article.schema';

export interface AokArticle extends AokStaticArticle, AokArticleEntityBase {
  imageUrl?: string;
  thumbnailUrl?: string;
  categories: AokArticleCategory[];
  status: AokArticleStatus;
  type: AokArticleType;
  version?: number;
  comment?: string;

  links: unknown;

  image?: string | File; // base64 - used for saving
  articleImageState?: ArticleImageState; // used when saving
}

interface AokArticleEntityBase {
  readonly id: number;

  lastModifiedBy: string;
  lastModifiedDate: number;
  createdBy: string;
  createdDate: number;
}

// todo: add remaining static "article" references
export const enum AokArticleType {
  Imprint = 'IMPRINT',
  News = 'NEWS',
  SecuredImprint = 'SECURED_IMPRINT',
}

export const enum AokArticleStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export const enum ArticleImageState {
  NOT_CHANGED = 'NOT_CHANGED',
  CHANGED = 'CHANGED',
  REMOVED = 'REMOVED',
}

export interface AokArticleListOptions extends AokPageQueryParams {
  status?: AokArticleStatus[] | AokArticleStatus;
  type?: AokArticleType[] | AokArticleType;
  category?: string[] | string;
}

export interface AokArticleCategory {
  readonly id?: number;
  name: string;
}

export enum AokKnownArticleCategoryNames {
  GeneralInformation = 'general-information',
  PortalUsage = 'user-guide',
}

export enum AokKnownArticleCategoryRoute {
  GeneralInformation = 'allgemeine-informationen',
  PortalUsage = 'arztportal-nutzung',
}

export enum AokArticleDialogMode {
  CREATE = 'create',
  EDIT = 'edit',
}
