import { InjectionToken } from '@angular/core';

export type SvgIconData = string;

export interface SvgIconImportConfig<Icon = any> {
  icons: Icon[];
  idSelector: string[] | string;
  idRewrite?: Array<{
    replace: RegExp | string;
    with: string;
  }>;
  dataSelector: string[] | string;
}

export interface SvgIconSizeAliases {
  [key: string]: string | number;
}

export const SVG_ICON_IMPORT = new InjectionToken<SvgIconImportConfig>('SVG_ICON_IMPORT');
export const SVG_ICON_SIZE = new InjectionToken<string | number>('SVG_ICON_SIZE');
export const SVG_ICON_SIZE_ALIASES = new InjectionToken<SvgIconSizeAliases>('SVG_ICON_SIZE_ALIASES');
