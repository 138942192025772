import { Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import Keycloak from 'keycloak-js';
import { AsyncSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AokKeycloakTokenParsed, KnownRoles } from '../schemas';

@Injectable({ providedIn: 'root' })
export class UserService {
  keycloakInstance: AsyncSubject<Keycloak> = new AsyncSubject();

  constructor(protected keycloak: KeycloakService) {
    this.keycloak.keycloakEvents$
      .pipe(
        filter((e) => e.type === KeycloakEventType.OnReady),
        take(1)
      )
      .subscribe(async () => {
        this.keycloakInstance.next(this.keycloak.getKeycloakInstance());
        this.keycloakInstance.complete();
      });
  }

  public loadKeycloakInstance(): Observable<Keycloak> {
    return this.keycloakInstance.asObservable();
  }

  public hasRole(role: string): boolean {
    return this.keycloak.isUserInRole(role);
  }

  public hasAnyRole(roles: string[]): boolean {
    return this.keycloak.getUserRoles().some((role: string) => roles.includes(role));
  }

  public getParsedToken(): AokKeycloakTokenParsed {
    return this.keycloak.getKeycloakInstance()?.tokenParsed as AokKeycloakTokenParsed;
  }

  public getParsedToken$(): Observable<AokKeycloakTokenParsed> {
    return this.loadKeycloakInstance().pipe(
      map((keycloakInstance) => keycloakInstance.tokenParsed as AokKeycloakTokenParsed)
    );
  }

  public isPendingUser(): boolean {
    return this.keycloak.getKeycloakInstance() && (this.isPendingHCP() || this.isPendingAssistant());
  }

  public isPendingHCP(): boolean {
    return this.hasRole(KnownRoles.PendingHealthcareProfessional);
  }

  public isPendingAssistant(): boolean {
    return this.hasRole(KnownRoles.PendingAssistant);
  }

  public isHCP(): boolean {
    return this.hasRole(KnownRoles.HealthcareProfessional);
  }

  public isAssistant(): boolean {
    return this.hasRole(KnownRoles.Assistant);
  }

  public getBsnrList(): string[] {
    const { bsnr } = this.getParsedToken();

    return Array.isArray(bsnr) ? [...bsnr] : [bsnr];
  }
}
