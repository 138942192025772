import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';

/**
 * check whether the value of the control equals the value of the given controlName
 * @param controlName
 */
export function equalControl(controlName: string): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.parent) {
      return null;
    }

    const checkedControl = control.parent.get(controlName) as UntypedFormControl;

    if (control.value !== checkedControl.value) {
      return { equalControl: false };
    }

    return null;
  };
}
