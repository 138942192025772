import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { FormControlErrorModule } from '../form-control-error/module';
import { AokDatepickerComponent } from './datepicker.component';

@NgModule({
  declarations: [AokDatepickerComponent],
  exports: [AokDatepickerComponent],
  imports: [
    CommonModule,
    A11yUtilsModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    AokSvgIconComponent,
    FormControlErrorModule,
  ],
})
export class AokDatepickerModule {}
