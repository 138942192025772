import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AokConsentClient, DialogBase, DialogRef } from '@aok/common';

@Component({
  selector: 'aok-cockpit-kvn-practice-account-dialog',
  templateUrl: './kvn-practice-account-dialog.component.html',
})
export class KvnPracticeAccountDialog implements OnInit, DialogBase<boolean> {
  public formGroup: UntypedFormGroup;
  public headerText = 'Zugriffsberechtigung bearbeiten';
  @Input() isKvnAccessForPMGranted: boolean;

  constructor(public readonly dialogRef: DialogRef<boolean>, protected client: AokConsentClient) {}

  public ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      kvnPortalAccess: new UntypedFormControl(this.isKvnAccessForPMGranted, [Validators.requiredTrue]),
    });
  }

  public getKvnPortalAccessControl(): UntypedFormControl {
    return this.formGroup.get('kvnPortalAccess') as UntypedFormControl;
  }

  public cancel(): void {
    this.dialogRef.dispose();
  }

  public submit(): void {
    this.dialogRef.dispose(this.getKvnPortalAccessControl().value);
  }
}
