import { DmpCaseManagementCategory } from '@aok/common';

export const DMP_CM_CATEGORY_LABELS = {
  regular: 'Ausstehende Dokumentationen',
  initial: 'Fehlende Erstdokumentationen',
  enrollment: 'Alle Einschreibungen',
  suspension: 'Ausschreibungen',
};

export const DMP_CM_URLS = {
  regular: 'ausstehende-dokumentationen',
  initial: 'fehlende-erst-dokumentationen',
  enrollment: 'alle-einschreibungen',
  suspension: 'ausschreibungen',
};

export const DMP_CM_CATEGORY_ORDER = [
  DmpCaseManagementCategory.REGULAR,
  DmpCaseManagementCategory.INITIAL,
  DmpCaseManagementCategory.SUSPENSION,
  DmpCaseManagementCategory.ENROLLMENT,
];

export const diagnoseFilterOptions = [
  { label: 'Alle Diagnosen', value: 'Alle' },
  { label: 'AB - Asthma bronchiale', value: 'AB' },
  { label: 'BK - Brustkrebs', value: 'BK' },
  { label: 'COPD - Chronisch obstruktive Lungenerkrankung', value: 'COPD' },
  { label: 'DM1 - Diabetes mellitus Typ 1', value: 'DM1' },
  { label: 'DM2 - Diabetes mellitus Typ 2', value: 'DM2' },
  { label: 'KHK - Koronare Herzkrankheit', value: 'KHK' },
];
