import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { KvnState } from '@aok/components';
import { first, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const notKvnPM: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const kvnState = inject(KvnState);
  const router = inject(Router);

  return kvnState.getKvnInfo$().pipe(
    first(),
    map((kvnInfo) => {
      if (kvnInfo.isKvnPracticeTeam) {
        return router.createUrlTree(['profil', 'praxisdaten']);
      }

      return true;
    })
  );
};
