<dialog-layout
  [headerText]="'eGK-Daten werden gelesen'"
  [fullscreen]="false"
  [autoClose]="false"
  (dispose)="cancel()"
  cdkTrapFocusAutoCapture="true"
  cdkTrapFocus
>
  <p>Bitte haben Sie einen Moment Geduld, die Daten der eGK werden aktuell gelesen.</p>
  <p>
    Falls das Programm "CardToWeb" nicht gestartet ist, erscheint ein Dialog und Sie werden gefragt, ob die Anwendung
    geöffnet werden soll. Bitte bestätigen Sie diese Meldung.
  </p>
  <aok-ring-loader></aok-ring-loader>

  <dialog-footer>
    <button (click)="cancel()">Abbrechen</button>
  </dialog-footer>
</dialog-layout>
