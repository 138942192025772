import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule, AokSvgIconComponent, AokTimeSinceModule } from '@aok/common';
import { AokNotificationComponent } from './notification.component';

@NgModule({
  declarations: [AokNotificationComponent],
  exports: [AokNotificationComponent],
  imports: [A11yUtilsModule, AokSvgIconComponent, CommonModule, AokTimeSinceModule],
})
export class AokNotificationModule {}
