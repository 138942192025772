import { Directive, ElementRef, Inject, Optional, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { AokLoadingState } from './loading.state';
import { ElementState } from '../../utils';

@Directive({ selector: '[aokLoadingState]' })
export class AokLoadingStateDirective extends ElementState {
  private _subs: Subscription[] = [];

  className = 'loading';

  get connected(): boolean {
    return this._subs.length > 0;
  }
  get disconnected(): boolean {
    return !this.connected;
  }

  constructor(
    @Inject(ElementRef)
    elementRef: /** @dynamic */ ElementRef<Element> | /** @dynamic */ Element,
    @Inject(Renderer2) renderer?: Renderer2,
    @Optional() state?: AokLoadingState
  ) {
    super(elementRef, renderer);
    if (state != null) this.connect(state);
  }

  connect(state: AokLoadingState): this {
    this._subs.push(
      state.starts.subscribe(() => this.setTrue()),
      state.stops.subscribe(() => this.setFalse())
    );
    return this;
  }

  disconnect(): void {
    for (const sub of this._subs) {
      if (sub != null && !sub.closed) sub.unsubscribe();
    }
    // reset the subscription array
    this._subs = [];
  }

  dispose(): void {
    this.disconnect();
    super.dispose();
  }
}
