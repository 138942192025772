import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AokArticle, AokArticleClient, AokArticleStatus, AokArticleType } from '@aok/common';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export type DashboardArticleTeasers = [AokArticle?, AokArticle?, AokArticle?];

export const dashboardArticleTeasersResolver: ResolveFn<DashboardArticleTeasers> = () => {
  const client = inject(AokArticleClient);

  return client.list({ size: 3, status: AokArticleStatus.Published, type: AokArticleType.News }).pipe(
    map((page) => page?._embedded?.items as DashboardArticleTeasers),
    catchError(() => of([] as DashboardArticleTeasers))
  );
};
