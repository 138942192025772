import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppLoadingStateEnum } from '../schemas';
import { PrimitiveBehaviorState } from '../utils';

/**
 * State to include app wide information
 */
@Injectable({ providedIn: 'root' })
export class AppState {
  private appState = new PrimitiveBehaviorState<AppLoadingStateEnum>(AppLoadingStateEnum.LOADING);
  private isAccessible = new PrimitiveBehaviorState<boolean>(false);

  public get appLoadingState$(): Observable<AppLoadingStateEnum> {
    return this.appState.asObservable();
  }

  public get isAccessible$(): Observable<boolean> {
    return this.isAccessible.asObservable();
  }

  public get isLoaded(): Observable<boolean> {
    return this.appLoadingState$.pipe(map((state) => state === AppLoadingStateEnum.LOADED));
  }

  public setLoadingState(state: AppLoadingStateEnum): void {
    this.appState.patch(state);
  }

  public setAccess(accessible: boolean): void {
    this.isAccessible.patch(accessible);
  }
}
