import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { AokDoctorRelationship, AokOrg, ContextState, CurrentUserState, isAssistant } from '@aok/common';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalQueryParamService {
  constructor(readonly currentUserState: CurrentUserState, private contextState: ContextState) {}

  /**
   * handle LANR & BSNR query params
   * returns Observable of boolean - whether the query could be handled (context option found) or not
   */
  public handleContextQueryParams(queryParams: Params): Observable<boolean> {
    const { lanr, bsnr } = queryParams;
    if (!bsnr || !lanr) {
      return of(false);
    }

    const currentUser = this.currentUserState.snapshot;

    const lanr7 = lanr?.length > 7 ? lanr?.slice(0, 7) : lanr;

    if (isAssistant(currentUser)) {
      const matchingContext = this.contextState.contextSelectorOptions?.find((relationship: AokDoctorRelationship) => {
        return (
          relationship.userData.practitionerResource?.lanr === lanr7 && relationship.organizationData.bsnr === bsnr
        );
      });

      if (matchingContext) {
        this.contextState.setPractitioner(matchingContext.userData);
        this.contextState.setOrg(matchingContext.organizationData as AokOrg);
        return of(true);
      } else {
        console.log(
          `Unable to restore org practitioner context from url query params for lanr ${lanr7} and bsnr ${bsnr}`
        );
        return of(false);
      }
    }

    const matchingContext = this.contextState.contextSelectorOptions?.find(
      (relationship: AokDoctorRelationship) => relationship.organizationData.bsnr === bsnr
    );

    if (matchingContext && [lanr, lanr7].includes(currentUser.practitionerResource.lanr)) {
      this.contextState.setPractitioner(currentUser);
      this.contextState.setOrg(matchingContext.organizationData as AokOrg);
      return of(true);
    } else {
      console.log(
        `Unable to restore org practitioner context from url query params for lanr ${lanr7} and bsnr ${bsnr}`
      );
      return of(false);
    }
  }
}
