import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import {
  AokInsuranceStatusClient,
  AokInsuranceStatusResponse,
  AokPatientSearchTypeEnum,
  AokServiceCategory,
  AokServiceRequest,
  focusElement,
  NotificationBanner,
  scrollToTop,
  TableData,
} from '@aok/common';
import { ErrorBannerService, ServiceRequestErrors } from './service-request-form/error-banner.service';

@Component({
  selector: 'aok-cockpit-service-page',
  template: ``,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export abstract class ServicePageComponent<R = AokInsuranceStatusResponse> {
  @Input() public previousPatientSearchData: AokServiceRequest = null;

  public readonly AokServiceCategory = AokServiceCategory;

  public response: R;
  public banner: NotificationBanner = null;
  public showDetailedAddressForm = false;
  public patientSearchType: AokPatientSearchTypeEnum = AokPatientSearchTypeEnum.Address;

  protected constructor(
    protected client: AokInsuranceStatusClient,
    protected errorBannerService: ErrorBannerService,
    protected cd: ChangeDetectorRef
  ) {}

  public abstract handlePatientFormData(request: AokServiceRequest): void;

  public prepareResultTableData(): void {
    // method that prepares the data to be displayed in the result table
  }

  public handlePatientFormError(errors: { id: string; label: string }[]): void {
    this.handleErrorBanner(errors);
  }

  public reload(): void {
    this.response = null;
    this.banner = null;
    scrollToTop();
    this.cd.markForCheck();
  }

  protected handleErrorBanner(errors: { id: string; label: string }[]): void {
    this.banner = this.errorBannerService.getErrorBanner(errors);
    scrollToTop();

    // find first element in banner and focus
    if (this.banner) {
      setTimeout(() => this.setFocusOnFirstBannerFocusableElement(), 100);
    }
    this.cd.markForCheck();
  }

  protected resultTableTrackByFn(index: number, tableData: TableData): string {
    return tableData.sectionLabel;
  }

  protected handleResponse() {
    return (response: HttpResponse<R>) => {
      if (response.body) {
        this.response = response.body;
        this.prepareResultTableData();
      } else {
        // no insuree found
        this.banner = this.errorBannerService.notFoundUser;
        setTimeout(() => this.setFocusOnFirstBannerFocusableElement(), 100);
      }
      this.cd.markForCheck();
      setTimeout(() => scrollToTop(), 100);
    };
  }

  protected handleError() {
    return (error: HttpResponse<unknown>) => {
      if (error.status === 300) {
        this.showDetailedAddressForm = true;
      }
      this.banner = this.errorBannerService.handleServerError(error);
      this.cd.markForCheck();
      setTimeout(() => scrollToTop(), 100);
    };
  }

  private setFocusOnFirstBannerFocusableElement(): void {
    const selector = this.banner.id === ServiceRequestErrors.NOT_FOUND ? 'a' : '[aokFocusable]';
    focusElement(`#${this.banner.id} ${selector}`);
  }
}
