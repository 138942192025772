import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  A11yUtilsModule,
  AokFullAddressPipe,
  AokFullNamePipeModule,
  AokPopoverComponent,
  AokSvgIconComponent,
} from '@aok/common';
import { AokInputFieldModule, RequiredMarkModule } from '../../cdk';
import { AokDropdownModule } from '../../dropdown/dropdown.module';
import { FormControlErrorModule } from '../../form-control-error/module';
import { AokNotificationBannerModule } from '../../notification-banner/notification-banner.module';
import { AokRadioModule } from '../../radio-button/radio.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { DirectRegistration2faDialog } from './direct-registration-2fa.component';
import { SecondFactorAuthOptionsComponent } from '../../second-factor-auth-options/second-factor-auth-options.component';

@NgModule({
  declarations: [DirectRegistration2faDialog],
  exports: [DirectRegistration2faDialog],
  imports: [
    A11yModule,
    AokDialogLayoutModule,
    AokDropdownModule,
    AokInputFieldModule,
    AokNotificationBannerModule,
    AokPopoverComponent,
    AokSvgIconComponent,
    CommonModule,
    FormControlErrorModule,
    FormsModule,
    ReactiveFormsModule,
    RequiredMarkModule,
    AokRadioModule,
    AokFullNamePipeModule,
    A11yUtilsModule,
    AokFullAddressPipe,
    SecondFactorAuthOptionsComponent,
  ],
})
export class DirectRegistration2faModule {}
