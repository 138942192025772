import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { A11yUtilsModule, AokFullAddressPipe, AokFullNamePipeModule, AokSvgIconComponent } from '@aok/common';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { AokA11yLinksModule } from '../a11y-links/a11y-links.module';
import { AokBreadcrumbsModule } from '../cdk';
import { DisturbanceMessageComponent } from '../disturbance-message/disturbance-message.component';
import { AokHeaderLinkModule } from '../header-link/header-link.module';
import { AokMenuLinkComponent } from '../menu-link/menu-link.component';
import { AokNavModule } from '../nav/nav.module';
import { AokNotificationBellModule } from '../notification-bell/notification-bell.module';
import { AokOrgContextSelectModule } from '../org-context-select/org-context-select.module';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { HeaderComponent } from './header.component';
import { HeaderPageHeadlineComponent } from './headline/header-pageheadline.component';

@NgModule({
  declarations: [HeaderComponent, HeaderPageHeadlineComponent],
  exports: [HeaderComponent, HeaderPageHeadlineComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    NgClickOutsideDirective,
    AokSvgIconComponent,
    AokA11yLinksModule,
    AokBreadcrumbsModule,
    AokNotificationBellModule,
    A11yUtilsModule,
    AokOrgContextSelectModule,
    AokFullNamePipeModule,
    OverlayModule,
    AokHeaderLinkModule,
    RouterModule,
    AokMenuLinkComponent,
    AokNavModule,
    A11yModule,
    SearchBarModule,
    AokFullAddressPipe,
    DisturbanceMessageComponent,
  ],
})
export class HeaderModule {}
