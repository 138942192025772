<div
  aokFocusable
  class="option"
  [attr.data-testid]="option.testId"
  (keyup.enter)="this.openAccordionOption($event)"
  [ngClass]="index === 0 ? 'top-option' : ''"
>
  <div (click)="this.openAccordionOption($event)" class="accordion-label aok-bold">
    <h6 *ngIf="option.label">{{ option.label }}</h6>
    <ng-content select="[label]"></ng-content>
    <aok-svg-icon [name]="isOptionOpen() ? 'minus' : 'plus'" size="16px"></aok-svg-icon>
  </div>

  <div [ngClass]="isOptionOpen() ? 'expanded' : 'collapsed'" class="right-pad">
    <div [ngClass]="isOptionOpen() ? 'expanded' : 'collapsed'" class="top-margin">
      <p *ngIf="option.content">{{ option.content }}</p>
      <ng-content select="[content]"></ng-content>
    </div>

    <a
      *ngIf="option.link"
      [ngClass]="isOptionOpen() ? 'expanded' : 'collapsed'"
      [routerLinkOrHref]="option.link"
      aokFocusable
      class="aok-link-1 top-margin"
    >
      {{ option.link }}
      <aok-svg-icon class="right" name="arrow-rounded_right" size="16px"></aok-svg-icon>
    </a>
  </div>
</div>
