import { A11yModule } from '@angular/cdk/a11y';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { A11yUtilsModule, DialogBase, DialogRef } from '@aok/common';
import { Observable } from 'rxjs';
import { secondFactorAuthFormLabels } from '../../../utils';
import { RequiredMarkModule } from '../../cdk';
import { PhoneNumberDataComponent } from '../../phone-number-data/phone-number-data.component';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';

@Component({
  selector: 'aok-phone-number-dialog',
  templateUrl: './phone-number-dialog.component.html',
  styleUrls: ['./phone-number-dialog.component.scss'],
  standalone: true,
  imports: [
    AokDialogLayoutModule,
    A11yUtilsModule,
    A11yModule,
    RequiredMarkModule,
    ReactiveFormsModule,
    PhoneNumberDataComponent,
  ],
})
export class PhoneNumberDialog implements OnInit, DialogBase<void> {
  @Input() public formGroup: FormGroup;
  @Input() public submit: (formGroup: FormGroup) => Observable<boolean>;

  protected formLabels = secondFactorAuthFormLabels;

  constructor(public readonly dialogRef: DialogRef<void>) {}

  ngOnInit(): void {}

  public submitDialog(): void {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((formControl) => {
        formControl.updateValueAndValidity();
      });
    } else {
      this.submit(this.formGroup).subscribe((success) => {
        if (success) {
          this.dialogRef.dispose();
        }
      });
    }
  }
}
