import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AokErrorMessageModule } from '../error-message/error-message.module';
import { AokEmailErrorMessageComponent } from './email-error-message.component';

@NgModule({
  declarations: [AokEmailErrorMessageComponent],
  exports: [AokEmailErrorMessageComponent],
  imports: [CommonModule, AokErrorMessageModule],
})
export class AokEmailErrorMessageModule {}
