import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { FORM_ERRORS } from '@aok/common';

@Component({
  selector: 'aok-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AokCheckboxComponent,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AokCheckboxComponent),
      multi: true,
    },
  ],
})
export class AokCheckboxComponent implements ControlValueAccessor, Validator, DoCheck {
  @Input() label = '';
  @Output() checked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public _selected = false; // Specifies if the checkbox selected
  public triggerClass: string;
  @HostBinding('attr.tabindex') tabindex = 0;
  private _isOnFocus = false; // Specifies if the component on focus or not
  private _disabled = false; // Specifies if the checkbox disabled
  private _isTouched = false; // Specifies if the checkbox touched or not
  /**
   * Only a dummy. It would overwrite by a form function from the parent component automatically
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (_): void => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = (): void => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onValidatorChange = (): void => {};

  constructor(
    private ref: ElementRef,
    private cd: ChangeDetectorRef,
    @Optional() @Inject(FORM_ERRORS) readonly message?: string
  ) {}

  @HostListener('focusin') focusin(): void {
    this._isOnFocus = true;
  }

  @HostListener('focusout') focusout(): void {
    this._isOnFocus = false;
  }

  @HostListener('click') click(): void {
    this.changeSelected();
  }

  @HostListener('keydown', ['$event']) selected(event: KeyboardEvent): void {
    if (this._isOnFocus && event.key === ' ') {
      event.preventDefault();
      this.changeSelected();
    } else if (event.key === 'Enter') {
      this.changeSelected();
    }
  }

  ngDoCheck(): void {
    if (this.ref.nativeElement.className !== this.triggerClass) {
      this.triggerClass = this.ref.nativeElement.className;
      this.cd.detectChanges();
    }
  }

  public writeValue(selected: boolean): void {
    this._selected = selected;
    this.cd.detectChanges();
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange; // the "onChange" variable is from the parent
  }

  public changeSelected(): void {
    this.markAsTouched();
    if (!this._disabled) {
      this._selected = !this._selected;
      this.checked.emit(this._selected);
      this.onChange(this._selected);
    }
    this.cd.detectChanges();
  }

  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this._isTouched) {
      this.onTouched();
      this._isTouched = true;
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
