import { NgModule } from '@angular/core';
import { AokFocusFirstChildDirective } from './focus-first-child.directive';
import { AokFocusableDirective } from './focusable.directive';

@NgModule({
  declarations: [AokFocusableDirective, AokFocusFirstChildDirective],
  exports: [AokFocusableDirective, AokFocusFirstChildDirective],
  imports: [],
})
export class A11yUtilsModule {}
