import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { removeHtml } from '../../utils';
import { SearchInputComponent } from '../search-input/search-input.component';

@Component({
  selector: 'aok-cockpit-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchBarComponent implements OnInit, AfterViewInit {
  @Input() public suggestions: string[] = [];
  @Input() public isHeaderSearch = true;
  @Input() public label = 'Wonach möchten Sie suchen?';
  @Input() public placeholder = 'Suche';
  @Output() public submitted = new EventEmitter();
  @Output() public loadSuggestions = new EventEmitter<string>();
  protected focusIndex: number = null; // if null -> focus will be on the search input
  protected searchString = '';
  @ViewChildren('listEntry') private listEntries: QueryList<ElementRef>;
  @ViewChild('searchInputComponent', { static: true }) private searchInputComponent: SearchInputComponent;
  private suggestionsDebouncer = new Subject<string>();
  private destroyRef = inject(DestroyRef);

  constructor(protected route: ActivatedRoute) {
    this.handleSuggestionLoading();
  }

  @HostListener('keydown', ['$event']) selected(event: KeyboardEvent): void {
    if (this.suggestions.length) {
      this.handleNavigationKeyPress(event);
    }

    if (event.key === 'Enter' && document.activeElement.id !== 'clear-search-icon') {
      this.focusIndex !== null ? this.search(this.suggestions[this.focusIndex]) : this.search(null, this.searchString);
    }
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.searchString = this.isHeaderSearch ? '' : value.query;
    });
  }

  ngAfterViewInit(): void {
    this.focusSearchInput();
  }

  public getSearchSuggestions(searchString: string): void {
    this.searchString = searchString;

    if (searchString.length > 2) {
      this.suggestionsDebouncer.next(searchString);
    } else {
      this.suggestions = [];
    }
  }

  public search(suggestion: string, searchString?: string): void {
    if (suggestion) {
      searchString = removeHtml(suggestion);
    }
    const queryParams = searchString ? { query: searchString } : null;
    if (queryParams) {
      this.submitted.emit(queryParams);
      this.suggestions = [];
    }
  }

  /**
   * function used to handle navigation with Tab/ArrowUp/ArrowDown when search suggestions are available
   */
  private handleNavigationKeyPress(event: KeyboardEvent): void {
    const lastIndex = this.suggestions.length - 1;

    switch (event.key) {
      case 'Tab':
        if (this.focusIndex === lastIndex) {
          // when pressing tab while on the last item of the suggestion list, focus search input
          event.preventDefault();
          this.focusSearchInput();
          this.focusIndex = null;
        } else {
          // when pressing tab while not on the last item of the suggestion list, set focus index accordingly in order
          // to allow continuation of navigation with up and down arrows
          this.setFocusIndex(this.focusIndex < lastIndex, 1, -1);
        }
        break;

      case 'ArrowDown':
        event.preventDefault();
        this.setFocusIndex(this.focusIndex < lastIndex, 1, 0);
        this.handleFocus();
        break;

      case 'ArrowUp':
        event.preventDefault();
        this.setFocusIndex(this.focusIndex > 0, -1, lastIndex);
        this.handleFocus();
        break;
    }
  }

  /**
   * modify the focus index
   * @param isInRange condition to see if we go to the next element or reset to start value
   * @param step should be 1 or -1, depending on the key pressed; decides whether we navigate up or down
   * @param start the value the focusIndex will reset to when we reach either end of the suggestion list
   */
  private setFocusIndex(isInRange: boolean, step: number, start: number): void {
    if (this.focusIndex !== null) {
      this.focusIndex = isInRange ? this.focusIndex + step : null;
    } else {
      this.focusIndex = start;
    }
  }

  /**
   * focus the element pointed by the focusIndex value
   * if focusIndex is null, focus search input
   */
  private handleFocus(): void {
    this.focusIndex !== null ? this.listEntries.get(this.focusIndex).nativeElement?.focus() : this.focusSearchInput();
  }

  private focusSearchInput(): void {
    this.searchInputComponent.searchInput.nativeElement.focus();
  }

  /**
   * listen to search term changes and emit event with 300 debounce
   */
  private handleSuggestionLoading(): void {
    this.suggestionsDebouncer.pipe(takeUntilDestroyed(), debounceTime(300)).subscribe((searchTerm) => {
      this.loadSuggestions.emit(searchTerm);
    });
  }
}
