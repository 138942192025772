import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import {
  A11yUtilsModule,
  AokInsuranceStatusClient,
  AokInsuree,
  AokServiceRequest,
  billingQuarter,
  getMinTreatmentDate,
} from '@aok/common';
import {
  AokDatepickerModule,
  AokFooterModule,
  AokInputFieldModule,
  AokNotificationBannerModule,
  FeedbackButtonModule,
  FormControlErrorModule,
  HeaderModule,
  InfoBannerComponent,
  RequiredMarkModule,
} from '@aok/components';
import { ServicePageComponent } from '../service-page.component';
import { ErrorBannerService } from '../service-request-form/error-banner.service';
import { ServiceRequestFormComponent } from '../service-request-form/service-request-form.component';
import { BillingSheetResultComponent } from './billing-sheet-result/billing-sheet-result.component';

@Component({
  selector: 'aok-cockpit-billing-sheet',
  styleUrls: ['billing-sheet.component.scss'],
  templateUrl: 'billing-sheet.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    HeaderModule,
    InfoBannerComponent,
    BillingSheetResultComponent,
    AokNotificationBannerModule,
    RequiredMarkModule,
    A11yUtilsModule,
    AokInputFieldModule,
    AokDatepickerModule,
    FeedbackButtonModule,
    AokFooterModule,
    ServiceRequestFormComponent,
    RouterLink,
    FormControlErrorModule,
  ],
})
export class BillingSheetComponent extends ServicePageComponent<AokInsuree> implements OnInit {
  public treatmentDateFieldData = { id: 'treatmentDate', label: 'Behandlungsdatum' };
  public minTreatmentDate: Date = getMinTreatmentDate(new Date());
  public maxTreatmentDate: Date = new Date();
  public treatmentDateForm: UntypedFormControl = new UntypedFormControl(new Date(), {
    validators: [Validators.required, billingQuarter],
    updateOn: 'blur',
  });

  constructor(
    protected client: AokInsuranceStatusClient,
    protected errorBannerService: ErrorBannerService,
    protected cd: ChangeDetectorRef
  ) {
    super(client, errorBannerService, cd);
    this.handleTreatmentControlChanges();
  }

  ngOnInit(): void {}

  public handlePatientFormError(errors: { id: string; label: string }[]): void {
    if (this.treatmentDateForm.invalid) {
      errors.push(this.treatmentDateFieldData);
    }
    this.handleErrorBanner(errors);
  }

  public handlePatientFormData(request: AokServiceRequest): void {
    if (this.treatmentDateForm.invalid) {
      this.handleErrorBanner([this.treatmentDateFieldData]);
    } else {
      this.previousPatientSearchData = request;
      this.handleRequest(request);
      this.cd.markForCheck();
    }
  }

  private handleRequest(request: AokServiceRequest): void {
    const invoiceDate = this.treatmentDateForm.value;
    this.client.check({ ...request, invoiceDate }).subscribe({
      next: this.handleResponse(),
      error: this.handleError(),
    });
  }

  private handleTreatmentControlChanges(): void {
    this.treatmentDateForm?.valueChanges.pipe(takeUntilDestroyed()).subscribe((date: Date) => {
      const treatmentDate = new Date(date).getTime();
      const maxTreatmentDate = new Date(this.maxTreatmentDate).getTime();
      const minTreatmentDate = new Date(this.minTreatmentDate).getTime();

      if (treatmentDate > maxTreatmentDate || treatmentDate < minTreatmentDate) {
        this.treatmentDateForm.setValue(new Date(), { emitEvent: false });
      }
    });
  }
}
