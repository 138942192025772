import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

const INTERACTIVE_ELEMENT_NAMES: string[] = ['input', 'button', 'textarea']; // can be extended as required

@Directive({
  selector: '[aokFocusFirstChild]',
})
export class AokFocusFirstChildDirective implements AfterViewInit {
  // when we want to trap the focus in a dialog but don't want the focus to be visible straight away
  @Input() autoBlur = false;
  @Input() focusFirstChild = true;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.setFocus();
  }

  public setFocus(): void {
    if (this.focusFirstChild) {
      const formChildren = Array.from(this.el.nativeElement.children);

      formChildren.every((child) => {
        const elem = this.getInteractiveElement(child);

        if (elem) {
          if (elem.nativeElement) {
            elem.nativeElement.focus();
            if (this.autoBlur) {
              elem.nativeElement.blur();
            }
          } else {
            elem.focus();
            if (this.autoBlur) {
              elem.blur();
            }
          }
          return false; // break
        }
        return true; // continue
      });
    }
  }

  private getInteractiveElement(nativeElement: any): any {
    if (!nativeElement || !nativeElement.children) {
      return undefined;
    }

    if (
      !nativeElement.children.length &&
      INTERACTIVE_ELEMENT_NAMES.includes(nativeElement.localName) &&
      !nativeElement.hidden &&
      !nativeElement.disabled
    ) {
      return nativeElement;
    }

    let elem;
    Array.from(nativeElement.children).every((c) => {
      elem = this.getInteractiveElement(c);
      return !elem;
    });

    return elem;
  }
}
