import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AokSvsService, SvsPractitioner, UserService } from '@aok/common';
import { map, mergeMap } from 'rxjs/operators';

export const svsOrganizationsDataResolver: ResolveFn<SvsPractitioner[]> = () => {
  const userService = inject(UserService);
  const svsService = inject(AokSvsService);

  const parsedToken = userService.getParsedToken();
  // TODO use kvn service
  if (!parsedToken.kvnUniqueId || !parsedToken.lanr) {
    return [];
  }

  return userService.loadKeycloakInstance().pipe(
    mergeMap(() => {
      const bsnrs = userService.getBsnrList();
      return svsService.retrieveSvsPractitionerData(bsnrs);
    }),
    map((svsOrgs) => {
      return svsOrgs.filter((svsOrg) => !!svsOrg);
    })
  );
};
