import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { focusElement, NotificationBanner, NotificationBannerLink, scrollToElement } from '@aok/common';

export enum ServiceRequestErrors {
  MULTIPLE_RESULTS = 'error_multiple_patients',
  NOT_FOUND = 'error_patient_not_found',
  MULTIPLE_FORM_ERRORS = 'error_validation_multi',
  FORM_ERROR = 'error_validation_one',
  CARD_READER_ERROR = 'error_card_reader',
}

@Injectable({
  providedIn: 'root',
})
export class ErrorBannerService {
  public multiUser: NotificationBanner = {
    id: ServiceRequestErrors.MULTIPLE_RESULTS,
    bannerHeadline: `Unter den angegebenen Daten sind mehrere Patienten bei der AOK Niedersachsen versichert.`,
    bannerLabel: `Bitte geben Sie weitere Daten ein, um den Abrechnungsschein zu drucken.`,
  };
  private readonly supportPhone = '0800 265-6509';
  public notFoundUser: NotificationBanner = {
    id: ServiceRequestErrors.NOT_FOUND,
    bannerHeadline: 'Versicherungsnehmer nicht gefunden.',
    bannerLabel: `Die angegebenen Daten können wir keinem Versicherten zuordnen. Bitte prüfen Sie Ihre Eingabe. Bei Fragen
              sind wir telefonisch gern unter <a class="secondary" href="tel:${this.supportPhone}">${this.supportPhone}</a>
              für Sie da.`,
  };
  private multiErrors: NotificationBanner = {
    id: ServiceRequestErrors.MULTIPLE_FORM_ERRORS,
    bannerHeadline: 'Leider sind mehrere Fehler aufgetreten.',
    bannerLabel: 'Bitte überprüfen Sie Ihre Eingaben.',
  };
  private singleError: NotificationBanner = {
    id: ServiceRequestErrors.FORM_ERROR,
    bannerHeadline: 'Es ist ein Fehler aufgetreten.',
    bannerLabel: 'Bitte überprüfen Sie Ihre Eingabe.',
  };

  public handleServerError = (error: HttpResponse<unknown>): NotificationBanner => {
    if (error.status === 300) {
      return this.multiUser;
    } else if (error.status === 404) {
      return this.notFoundUser;
    }
  };

  public getErrorBanner(errorIds: { id: string; label: string }[]): NotificationBanner {
    if (errorIds.length === 0) {
      return null;
    } else if (errorIds.length > 1) {
      return { ...this.multiErrors, bannerLinks: this.getBannerLinks(errorIds) };
    } else if (errorIds.length === 1 && errorIds[0].id === 'cardReaderError') {
      return {
        id: ServiceRequestErrors.CARD_READER_ERROR,
        bannerHeadline: null,
        bannerLabel: errorIds[0].label,
      };
    } else {
      return { ...this.singleError, bannerLinks: this.getBannerLinks(errorIds) };
    }
  }

  public getInputFieldsErrorIds(formGroup: UntypedFormGroup): string[] {
    return Object.entries(formGroup.controls).reduce((accu, [id, control]) => {
      if (control.invalid) {
        control.updateValueAndValidity();
        accu.push(id);
      }
      return accu;
    }, [] as string[]);
  }

  private getBannerLinks(errors: { id: string; label: string }[]): NotificationBannerLink[] {
    return errors.reduce((accu, error) => {
      accu.push({
        label: error.label,
        clickHandler: () => {
          scrollToElement(error.id);
          setTimeout(() => focusElement(error.id), 500);
        },
      });
      return accu;
    }, [] as NotificationBannerLink[]);
  }
}
