import { isString } from '../string.util';

export function resolveObjectPropertyPath(obj: any, propertyPath: string[] | string, fallback?: any): any {
  const path = (isString(propertyPath) ? propertyPath.split('.') : propertyPath) || [];
  return (
    obj != null &&
    path.reduce((innerObj, nextProperty) => {
      return Object.prototype.hasOwnProperty.call(innerObj, nextProperty) ? innerObj[nextProperty] : fallback;
    }, obj)
  );
}
