import { clickFeedbackButton } from '../../utils';
import { MenuLink } from '../menu-link/menu-link.component';

export const contactListItems: MenuLink[] = [
  {
    id: 'email',
    href: 'mailto:AOK-Arztportal@nds.aok.de',
    icon: 'mail',
    label: 'E-Mail senden',
    uriScheme: true,
  },
  {
    id: 'feedback',
    internal: true,
    icon: 'text-chat_bubble',
    label: 'Feedback senden',
    onClick: clickFeedbackButton,
  },
  {
    id: 'contact',
    href: 'https://www.aok.de/gp/service/ansprechpartnersuche/?region=niedersachsen',
    icon: 'customer-centre',
    label: 'Ansprechpartnersuche',
  },
];

export const contactListItemsLoggedOut: MenuLink[] = [
  {
    href: 'https://www.aok.de/gp/service/ansprechpartnersuche/?region=niedersachsen',
    icon: 'customer-centre',
    label: 'Ansprechpartnersuche',
  },
];
