import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AokApiBaseClient } from './api-base.client';

@Injectable({ providedIn: 'root' })
export class AokUserExportClient extends AokApiBaseClient {
  downloadRegistrationRequests(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.resolveUrl('registrations', 'export'), {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  downloadUsers(exportType: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.resolveUrl('export'), {
      responseType: 'blob' as 'json',
      observe: 'response',
      params: new HttpParams({
        fromObject: {
          type: exportType,
        },
      }),
    });
  }

  /**
   * Resolves a fully quantified service url using the base ":apiUrl/users"
   *
   * @param segments Additional segments that should be append to the url
   */
  resolveUrl(...segments: string[]): string {
    // normalizing the base url to ":baseUrl/users"
    return super.resolveUrl('users', ...segments);
  }
}
