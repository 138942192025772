import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AokSvgIconComponent, RouterLinkOrHrefModule } from '@aok/common';
import { AokSitemapTreeComponent } from './sitemap-tree.component';

const declarations = [AokSitemapTreeComponent];

@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule, RouterModule, RouterLinkOrHrefModule, AokSvgIconComponent],
})
export class AokSitemapTreeModule {}
