import { Injectable } from '@angular/core';
import { combineLatest, distinctUntilChanged, forkJoin, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AddressData, AokDoctorRelationship, AokOrg, AokUser } from '../schemas';
import { ArrayBehaviorState, BehaviorState, isAssistant, isDoctor, lanr9Util } from '../utils';

export const AOK_PRACTITIONER_CONTEXT_KEY = 'aok_practitioner_context';
export const AOK_ACTIVE_ORG = 'aok_active_org';

@Injectable({ providedIn: 'root' })
export class ContextState {
  protected readonly storage = window.sessionStorage;

  private practitionerState = new BehaviorState<AokUser | Partial<AokUser>>();
  private orgState = new BehaviorState<AokOrg>();
  private contextSelectorOptionsState = new ArrayBehaviorState<AokDoctorRelationship>();

  public get contextSelectorOptions(): AokDoctorRelationship[] {
    return this.contextSelectorOptionsState.snapshot;
  }

  public get practitioner(): AokUser | Partial<AokUser> {
    return this.practitionerState.snapshot;
  }

  public get practitionerLanr(): string | null {
    return this.practitionerState.snapshot?.practitionerResource?.lanr;
  }

  public get practitionerLanr9(): string | null {
    return lanr9Util(this.practitionerLanr, this.practitionerState.snapshot?.practitionerResource?.specialization?.id);
  }

  public get org(): AokOrg {
    return this.orgState.snapshot;
  }

  public get orgId(): number {
    return this.org?.id;
  }

  public get orgAddress(): AddressData | null {
    return this.org?.address;
  }

  public get bsnr(): string | null {
    return this.org?.bsnr;
  }

  public getContextSelectorOptions$(): Observable<AokDoctorRelationship[]> {
    return this.contextSelectorOptionsState.asObservable();
  }

  public getPractitioner$(): Observable<AokUser | Partial<AokUser>> {
    return this.practitionerState.asObservable();
  }

  public getOrg$(): Observable<AokOrg> {
    return this.orgState.asObservable();
  }

  public getFirstOrg$(): Observable<AokOrg> {
    return this.orgState.asObservable().pipe(
      filter((org) => org != null),
      take(1)
    );
  }

  public getFirstPractitioner$(): Observable<AokUser | Partial<AokUser>> {
    return this.practitionerState.asObservable().pipe(
      filter((practitioner) => practitioner != null),
      take(1)
    );
  }

  public isContextReady$(): Observable<boolean> {
    return combineLatest([this.getOrg$(), this.getPractitioner$()]).pipe(
      map(([org, practitioner]) => !!org && !!practitioner),
      distinctUntilChanged()
    );
  }

  /**
   * returns an observable that emits a true value when both the practitioner and org have been set
   * TODO: might be replaceable with first() value of the observable above
   */
  public contextSelected$(): Observable<boolean> {
    return forkJoin([this.getFirstOrg$(), this.getFirstPractitioner$()]).pipe(map(() => true));
  }

  public setContextSelectorOptions(contextSelectorOptions: AokDoctorRelationship[]): void {
    this.contextSelectorOptionsState.reset(...contextSelectorOptions);
  }

  public setPractitioner(practitioner: AokUser | Partial<AokUser>): void {
    this.practitionerState.patch(practitioner);
    if (practitioner) {
      this.storage.setItem(AOK_PRACTITIONER_CONTEXT_KEY, JSON.stringify(practitioner));
    } else {
      this.storage.removeItem(AOK_PRACTITIONER_CONTEXT_KEY);
    }
  }

  public setOrg(organization: AokOrg): void {
    if (organization) {
      this.storage.setItem(AOK_ACTIVE_ORG, JSON.stringify(organization));
    } else {
      this.storage.removeItem(AOK_ACTIVE_ORG);
    }
    this.orgState.patch(organization);
  }

  public clearSelectedContext(): void {
    this.setOrg(null);
    this.setPractitioner(null);
    this.storage.removeItem(AOK_PRACTITIONER_CONTEXT_KEY);
    this.storage.removeItem(AOK_ACTIVE_ORG);
  }

  public resetOrRestoreStoredContext(currentUser: AokUser): boolean {
    const org = this.getStoredOrg();

    if (currentUser.practitionerResource.organizationsIds.includes(org?.id)) {
      // restore org
      this.setOrg(org);
    } else {
      this.clearSelectedContext();
      return false;
    }

    const practitioner = this.getStoredPractitioner();

    if (isDoctor(currentUser) && currentUser.id === practitioner?.id) {
      // restore practitioner
      this.setPractitioner(practitioner);
    } else if (isAssistant(currentUser)) {
      const context = this.contextSelectorOptions.find((item) => item?.userData?.id === practitioner?.id);

      if (context) {
        this.setPractitioner(context?.userData);
      } else {
        this.clearSelectedContext();

        return false;
      }
    } else {
      this.clearSelectedContext();

      return false;
    }

    return true;
  }

  private getStoredOrg(): AokOrg | null {
    return JSON.parse(this.storage.getItem(AOK_ACTIVE_ORG));
  }

  private getStoredPractitioner(): AokUser | null {
    return JSON.parse(this.storage.getItem(AOK_PRACTITIONER_CONTEXT_KEY));
  }
}
