import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ContextState, getParsableQueryParams, isAbsoluteURL, RequiredTask } from '@aok/common';
import { combineLatest, from, Observable, of } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { ConsentState } from '../../states';
import { AokCookieService } from '../cookie/cookie.service';

@Injectable()
export class RedirectToTaskService implements RequiredTask {
  constructor(
    private router: Router,
    private contextState: ContextState,
    private consentState: ConsentState,
    private cookieService: AokCookieService
  ) {}

  public trigger(): Observable<boolean> {
    const queryParams = getParsableQueryParams();

    const redirectTo: string = queryParams.redirectTo;
    if (redirectTo) {
      return this.handleRedirectTo(queryParams, redirectTo);
    }

    return of(true);
  }

  private handleRedirectTo(queryParams: Record<string, string>, redirectTo: string): Observable<boolean> {
    if (isAbsoluteURL(redirectTo)) {
      return this.absoluteRedirectTo(redirectTo);
    } else {
      return from(
        this.router.navigate([redirectTo], {
          queryParams: {
            ...queryParams,
            redirectTo: null,
          },
        })
      );
    }
  }

  private absoluteRedirectTo(redirectTo: string): Observable<boolean> {
    return combineLatest([
      this.consentState.asObservable().pipe(map((consents) => consents?.length > 0)),
      this.cookieService.cookieAccepted$.pipe(map(() => this.cookieService.isAccepted)),
    ]).pipe(
      filter(([consent, cookie]) => consent && cookie),
      first(),
      mergeMap(() => {
        const url = new URL(decodeURIComponent(redirectTo));
        const { bsnr, practitionerLanr: lanr } = this.contextState;
        url.searchParams.set('lanr', lanr);
        url.searchParams.set('bsnr', bsnr);
        window.location.href = url.href;
        return of(false);
      })
    );
  }
}
