import { InjectionToken, Type } from '@angular/core';
import { Observable } from 'rxjs';

export const AOK_REQUIRED_TASKS = new InjectionToken<RequiredTask[]>('AOK_REQUIRED_TASKS');

export interface RequiredTask {
  /**
   * Trigger the processing of the task
   * @returns Observable<boolean> based on which the following tasks are processed (if true) or not (if false)
   */
  trigger(): Observable<boolean>;
}

export interface RequiredTaskConfig {
  type: Type<RequiredTask>;
  config: unknown;
  deps?: unknown[];
}
