import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AokMenuButtonEntry, AokPage, AokReport, AokReportPageData, CurrentUserState, rotate } from '@aok/common';
import { getAriaSort } from '@aok/components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ReportsTableService } from '../../../services/reports-table.service';
import { ReportsState } from '../../../states/reports.state';

@Component({
  selector: 'aok-cockpit-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReportsTableService],
})
export class ReportsTableComponent implements OnDestroy {
  public readonly displayColumns: string[] = ['name', 'sharedIcon', 'ownerName', 'date', 'options'];

  public menuOptions: AokMenuButtonEntry<AokReport>[] = [
    { id: 'openReport', title: 'Bericht öffnen', action: this.openReport.bind(this) },
    { id: 'downloadReport', title: 'Bericht herunterladen', action: this.downloadReport.bind(this) },
    { id: 'shareReport', title: 'Freigabe verwalten', action: (report) => this.service.openSharedDialog(report) },
    { id: 'deleteReport', title: 'Bericht löschen', action: (report) => this.service.openDeleteReportDialog(report) },
  ];
  public readAtRuntime = {};
  public reports: AokPage<AokReport>;

  public sortColumn: string;
  public sortDirection = '';

  public setAriaSort = getAriaSort;

  private ngDestroyed = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private reportsState: ReportsState,
    private cd: ChangeDetectorRef,
    private router: Router,
    protected activeUser: CurrentUserState,
    private service: ReportsTableService
  ) {
    this.updateMenuOptions();

    // when the category changes in URL
    this.route.paramMap.pipe(takeUntil(this.ngDestroyed)).subscribe((value) => {
      this.reports = this.reportsState.snapshot[value.get('category')];
      this.sortColumn = '';
      this.sortDirection = '';
      this.cd.markForCheck();
    });

    // when data changes (pagination)
    this.reportsState
      .asObservable()
      .pipe(takeUntil(this.ngDestroyed))
      .subscribe((reportsData: AokReportPageData) => {
        const data = reportsData[this.route.snapshot.paramMap.get('category')];
        if (data) {
          this.reports = data;
          this.cd.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  public openReport(report: AokReport): void {
    this.readAtRuntime[report.id] = true;
    this.service.openReport(report).subscribe();
  }

  public navigateToContactForm(report: AokReport): void {
    this.router.navigate(['kontaktanfrage', report.reportCategory.toLowerCase()]);
  }

  public getMenuOptionsPerReport(report: AokReport): AokMenuButtonEntry<AokReport>[] {
    if (this.activeUser.snapshot.id !== report.ownerId) {
      return this.menuOptions.filter((entry) => entry.id !== 'shareReport' && entry.id !== 'deleteReport');
    }
    return this.menuOptions;
  }

  public sortBy(column: string): void {
    this.sortDirection = this.sortColumn === column ? rotate[this.sortDirection] : 'asc';
    this.sortColumn = column;
    const sort = this.sortDirection ? { sort: column + ',' + this.sortDirection } : {};
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { ...sort },
    });
  }

  public showSharedIcon(report: AokReport): boolean {
    return report.userIdsWithAccess.length > 1;
  }

  public isSortedBy(column: string): boolean {
    return this.sortDirection && this.sortColumn === column;
  }

  protected reportsTrackByFn(index: number, report: AokReport): number {
    return report.id;
  }

  private downloadReport(report: AokReport): void {
    this.service.downloadReport(report).subscribe(() => {
      this.readAtRuntime[report.id] = true;
    });
  }

  private updateMenuOptions(): void {
    if (environment.enableContactRequest) {
      this.menuOptions.push({
        id: 'contact',
        title: 'Kontakt aufnehmen',
        action: this.navigateToContactForm.bind(this),
      });
    }
  }
}
