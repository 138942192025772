import { NgModule } from '@angular/core';
import { AokAccordionOptionComponent } from './accordion-option.component';
import { CommonModule } from '@angular/common';
import { A11yUtilsModule, AokSvgIconComponent, RouterLinkOrHrefModule } from '@aok/common';

@NgModule({
  declarations: [AokAccordionOptionComponent],
  exports: [AokAccordionOptionComponent],
  imports: [A11yUtilsModule, CommonModule, AokSvgIconComponent, RouterLinkOrHrefModule],
})
export class AokAccordionOptionModule {}
