import { Injectable } from '@angular/core';
import { AokNotification, AokNotificationStateType } from '../schemas';
import { BehaviorState } from '../utils/rx/behavior-state';

@Injectable({
  providedIn: 'root',
})
export class AokNotificationState extends BehaviorState<AokNotificationStateType> {
  constructor() {
    super();
  }

  public setNotifications(notifications: AokNotification[]): void {
    this.patch({ notifications });
  }

  public setNotificationCount(notificationCount: number): void {
    this.patch({ notificationCount });
  }
}
