import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AokSvgIconComponent, InfoBanner } from '@aok/common';
import { InfoBannerState } from '../../states';

@Component({
  selector: 'aok-info-banner',
  standalone: true,
  imports: [CommonModule, AokSvgIconComponent],
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBannerComponent {
  public infoBanner: InfoBanner;

  constructor(private state: InfoBannerState) {
    this.infoBanner = state.snapshot;
  }
}
