<ng-container [ngSwitch]="_templateType">
  <ng-container *ngSwitchCase="'text'">
    <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'image'">
    <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'list'">
    <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
  </ng-container>
</ng-container>

<ng-template #textTemplate>
  <p *ngIf="label" class="aok-text-p1 aok-bold" [innerHTML]="label"></p>
  <p *ngIf="text" class="aok-text-p1" [innerHTML]="text"></p>
</ng-template>

<ng-template #imageTemplate>
  <p class="aok-text-p1" [innerHTML]="text"></p>
  <img [src]="pictureSource" alt="AOK Logo" />
</ng-template>

<ng-template #listTemplate>
  <p class="aok-text-p1 aok-bold" [innerHTML]="label"></p>
  <ul *ngIf="list?.length" id="popoverList">
    <li *ngFor="let element of list">
      <aok-svg-icon *ngIf="getListItemIcon(element) as itemIcon" [name]="itemIcon" [size]="16"> </aok-svg-icon>
      <p [innerHTML]="getListItemText(element)"></p>
    </li>
  </ul>
</ng-template>
