import { OpenedDialogService } from '../../../services/close-dialog.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogConfig } from './dialog-overlay';

// This utility type is designed to extract the type parameter from DialogRef
// used to work with the inner type of DialogRef without knowing what that type is upfront.
// If T is not a DialogRef, the type resolves to never, indicating an invalid or unreachable type state.
export type DialogResponseType<T> = T extends DialogBase<infer R> ? R : never;

export interface DialogBase<R> {
  dialogRef: DialogRef<R>;
}

export class DialogRef<R> extends Observable<R> {
  protected subject = new Subject<R>();

  constructor(readonly overlayRef: OverlayRef, readonly config?: DialogConfig) {
    super((subscriber) => this.subject.subscribe(subscriber));
    // todo: consider another more suitable place for config evaluation?
    if (config && config.disposeOnBackdropClick) {
      overlayRef
        .backdropClick()
        .pipe(take(1))
        .subscribe(() => this.dispose());
    }
  }

  dispose(result?: R): void {
    this.subject.next(result);
    this.subject.complete();
    this.overlayRef.dispose();
  }

  toSubject(): Subject<R> {
    return this.subject;
  }
}

/*
  The ConnectedDialogRef class is defined with two generic types, R and T.
  R is the type of the result when the dialog is closed, and T is the type of the component that is being shown in the dialog.
 */
export class ConnectedDialogRef<R, T = any> extends DialogRef<R> {
  private _componentRef: ComponentRef<T>;

  get componentRef(): ComponentRef<T> {
    return this._componentRef;
  }

  constructor(
    origin: DialogRef<R>,
    readonly openedDialogService: OpenedDialogService,
    readonly portal: ComponentPortal<T>
  ) {
    super(origin.overlayRef, origin.config);

    openedDialogService?.storeDialog(this);

    this._componentRef = this.overlayRef.attach(portal);
    this.subject = origin.toSubject();
    // transfer input property values if there are any
    if (this.config?.props != null) {
      for (const [key, value] of Object.entries(this.config.props)) {
        // todo: consider a property validation using the component factory?!
        (this._componentRef.instance as any)[key] = value;
      }
    }
  }

  reattach(): void {
    if (this.overlayRef.hasAttached()) {
      this._componentRef = this.overlayRef.attach(this.portal);
    }
  }

  detach(): void {
    this.overlayRef.detach();
  }
}
