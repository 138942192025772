import { InjectionToken } from '@angular/core';

export type PageFooterPosition = 'fluid' | 'fixed' | 'none';

export interface PageFooterLink {
  /** Gets or sets the name of the footer link, also used as display name */
  name: string;
  /** Gets or sets the url of the footer link. This property accepts interpolation parameters */
  url: string;

  target?: '_blank' | '_self' | '_parent' | '_top' | string;

  /**
   * Gets or sets further information to the links that can be used when customizing
   * the {@link PAGE_FOOTER_COMPONENT}
   */
  [propertyKey: string]: any;
}

export type PageFooterLinks = PageFooterLink[];

export interface PageFooterPreset {
  /** Gets or sets the position of the page footer. Defaults to "fluid" */
  position?: PageFooterPosition;
  /** Gets or sets the footer links */
  links?: PageFooterLinks;
}

export const PAGE_FOOTER_PRESET = new InjectionToken<PageFooterPreset>('PAGE_FOOTER_PRESET');
