import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AokSvgIconComponent } from '@aok/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'aok-cockpit-ti-error',
  templateUrl: './ti-error.component.html',
  styleUrls: ['./ti-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AokSvgIconComponent],
})
export class TIErrorComponent {
  public publicUrl = environment.baseUrl;

  public redirectToPublicNetwork(): void {
    window.location.href = this.publicUrl;
  }

  public openExtendedTI(): void {
    window.open(environment.svsTIUrl);
  }
}
