import { Routes } from '@angular/router';
import { aokPodcastsPageResolver, articlesPageResolver, FeedbackCategory } from '@aok/common';
import { articleResolver } from '../../resolvers/article.resolver';
import { AokNotAccessiblePage } from '../not-accessible/not-accessible.component';
import { ArticleComponent } from './articles/article/article.component';
import { ArticlesComponent } from './articles/articles.component';
import { PodcastsComponent } from './podcasts/podcasts.component';

export const APP_ARTICLE_ROUTES: Routes = [
  {
    path: '',
    component: AokNotAccessiblePage,
    canActivate: [() => false],
  },
  {
    path: 'artikel',
    component: ArticlesComponent,
    runGuardsAndResolvers: 'always',
    resolve: { articles: articlesPageResolver },
    data: {
      browserTitle: 'Artikel als Übersicht',
      title: 'Artikel',
      category: FeedbackCategory.ARTIKEL,
    },
  },
  {
    path: 'artikel/:articleId',
    component: ArticleComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: { article: articleResolver },
    data: {
      browserTitle: (d) => `Artikel - ${d.article?.title}`,
      title: (d) => d.article?.title,
      sitemap: false,
    },
  },
  {
    path: 'podcasts',
    runGuardsAndResolvers: 'always',
    component: PodcastsComponent,
    resolve: { podcasts: aokPodcastsPageResolver },
    data: {
      browserTitle: 'Podcasts',
      title: 'Podcasts',
      category: FeedbackCategory.PODCASTS,
    },
  },
  {
    path: 'https://www.aok.de/gp/aerzte-psychotherapeuten/aok-praxis-news-arzneimittel-co',
    redirectTo: 'https://www.aok.de/gp/aerzte-psychotherapeuten/aok-praxis-news-arzneimittel-co',
    data: {
      target: '_blank',
      title: 'Newsletter',
    },
  },
];
