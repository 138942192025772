import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CurrentUserState,
  DialogOverlay,
  isAssistantType,
  isDoctorType,
  KnownUserType,
  RequiredTask,
} from '@aok/common';
import { Observable, of } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';
import { KvnPracticeInstructionsAddModeDoctorsDialog } from '../../components';
import { DialogKey } from '../../schema';
import { KvnState } from '../../states';
import { DialogManagerService } from '../dialog-manager.service';
import { ProfileService } from '../profile.service';

@Injectable()
export class OpenDialogTaskService implements RequiredTask {
  constructor(
    private router: Router,
    private dialogService: DialogManagerService,
    private currentUserState: CurrentUserState,
    private kvnState: KvnState,
    private dialog: DialogOverlay,
    private profileService: ProfileService,
    private activatedRoute: ActivatedRoute
  ) {}

  public trigger(): Observable<boolean> {
    const searchParams = new URLSearchParams(window.location.search);

    const openDialog: string = searchParams.get('openDialog');
    if (openDialog) {
      this.handleOpenDialogQueryParam(openDialog);
      this.listenToQueryParamChange(true);
    } else {
      this.listenToQueryParamChange();
    }

    return of(true);
  }

  private handleOpenDialogQueryParam(openDialog: string): void {
    const currentUserType = this.currentUserState.snapshot.userType;
    switch (openDialog) {
      case DialogKey.OPEN_JOIN_REQUEST_TO_PRACTICE_MFA_DIALOG:
        this.handleMfaRequestToPractice(currentUserType);
        break;
      case DialogKey.OPEN_JOIN_REQUEST_TO_PRACTICE_DOCTOR_DIALOG:
        this.handleDoctorRequestToPractice(currentUserType);
        break;
      case DialogKey.OPEN_ADD_PRACTICES_DIALOG: {
        const keycloakId = this.currentUserState.snapshot.keycloakId;
        const pendingBsnrs = this.currentUserState.snapshot.practitionerResource?.pendingSamlBsnrs || [];
        if (pendingBsnrs.length) {
          this.dialogService.openAddPracticesOverlay(keycloakId, pendingBsnrs).subscribe(() => {
            this.removeOpenDialogParam();
          });
        }
        break;
      }
      case DialogKey.OPEN_COOKIE_DIALOG: {
        this.dialogService.openCookieDialog().subscribe(() => {
          this.removeOpenDialogParam();
        });
        break;
      }
      case DialogKey.OPEN_REPORT_CONTACT_DIALOG: {
        this.dialogService.openReportContactDialog().subscribe(() => {
          this.removeOpenDialogParam();
        });
      }
    }
  }

  private handleDoctorRequestToPractice(currentUserType: KnownUserType): void {
    if (isDoctorType(currentUserType)) {
      this.openJoinRequestToPracticeDoctorDialog();
    } else {
      this.removeOpenDialogParam();
    }
  }

  private handleMfaRequestToPractice(currentUserType: KnownUserType): void {
    if (isAssistantType(currentUserType)) {
      if (this.kvnState.isKvnPracticeTeam()) {
        this.openKvnPracticeInstructionsAddModeDoctorsDialog();
      } else {
        this.openJoinRequestToPracticeMfaDialog();
      }
    } else {
      this.removeOpenDialogParam();
    }
  }

  private openJoinRequestToPracticeDoctorDialog(): void {
    const { selected, unselected, bsnrRequests } = this.profileService.organisations.getValue();
    const alreadyJoined = selected?.org?.bsnr ? [selected.org.bsnr] : [];
    alreadyJoined.push(...(unselected?.map(({ org }) => org.bsnr) || []));
    const alreadyRequested = bsnrRequests?.map((request) => request.organizationData.bsnr) || [];

    this.dialogService
      .openJoinRequestToPracticeDoctorDialog(null, alreadyJoined, alreadyRequested)
      .subscribe((successful: boolean) => {
        if (successful) {
          this.profileService.reloadProfileOrganisations();
        }

        this.removeOpenDialogParam();
      });
  }

  /**
   * When navigating to our app with the "openDialog=open-join-request-to-practice-mfa-dialog" query parameter,
   * and if the user is "KVN practice team", we should open an informative dialog.
   * Additionally, we need to remove the "openDialog" query parameters when the user clicks the close button or the 'Schließen' button.
   *
   * TODO: move the creation of the dialog to dialog manager service
   */
  private openKvnPracticeInstructionsAddModeDoctorsDialog(): void {
    this.dialog.create(KvnPracticeInstructionsAddModeDoctorsDialog).subscribe(() => {
      this.removeOpenDialogParam();
    });
  }

  private openJoinRequestToPracticeMfaDialog(): void {
    this.dialogService
      .openJoinRequestToPracticeMfaDialog(this.profileService.getAllOrgs())
      .subscribe((successful: boolean) => {
        if (successful) {
          this.profileService.reloadProfileOrganisations();
        }

        this.removeOpenDialogParam();
      });
  }

  private removeOpenDialogParam(): void {
    this.router.navigate([], { queryParams: { openDialog: null }, queryParamsHandling: 'merge' });
  }

  /**
   * Listen to query param change to open dialogs on the fly
   * @param skipFirst - if the first one is handled immediately
   */
  private listenToQueryParamChange(skipFirst = false): void {
    let listener = this.activatedRoute.queryParams.pipe(
      map((queryParams) => queryParams?.openDialog),
      filter((openDialog: string) => !!openDialog)
    );

    if (skipFirst) {
      // first one is handled immediately
      listener = listener.pipe(skip(1));
    }

    listener.subscribe((openDialog) => {
      this.handleOpenDialogQueryParam(openDialog);
    });
  }
}
