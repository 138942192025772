import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { AOK_TITLE_STRATEGY_FALLBACK_RESOLVER, AokTitleStrategyFallbackResolver, TITLE_PREFIX } from '../../schemas';
import { isString, traverse } from '../../utils';

@Injectable({ providedIn: 'root' })
export class AokTitleStrategyService extends TitleStrategy {
  private _cachedBrowserTitle: string;

  constructor(
    @Inject(TITLE_PREFIX) private titlePrefix: /** @dynamic */ string,
    @Inject(AOK_TITLE_STRATEGY_FALLBACK_RESOLVER)
    private resolveFallback: /** @dynamic */ AokTitleStrategyFallbackResolver,
    private title: Title
  ) {
    super();
    this._cachedBrowserTitle = this.title.getTitle();
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);

    if (title) {
      this.title.setTitle(`${this.titlePrefix} - ${title}`);

      return;
    }

    const lastChild = traverse(
      snapshot.root,
      (s) => s.firstChild,
      (s) => s.firstChild == null
    );

    this.onNavigationChange(lastChild);
  }

  /**
   * This method is responsible for updating the browser's title based on the current navigation snapshot.
   */
  private onNavigationChange(snapshot: ActivatedRouteSnapshot): void {
    // Step 1: Determine the data container aka route.
    // The data container is the closest ActivatedRouteSnapshot that contains either a 'browserTitle' or 'title' in its data.
    // If the current snapshot itself has the required data, it will be used as the data container.
    // Otherwise, we traverse up the snapshot tree to find the closest parent that has the required data.
    const routeSnapshot = this.filterByTitle(snapshot)
      ? snapshot // If the current snapshot has the required data, it becomes the data container.
      : traverse(
          snapshot,
          (s) => s.parent, // Traverses up the snapshot tree by getting the parent.
          (s) => this.filterByTitle(s) // Checks if the snapshot has the required data.
        );

    // Step 2: Process the data from the route to get the browser title.
    if (!routeSnapshot) {
      // If no route is found, there is no relevant title information, so we do nothing.
      return;
    }

    const { data } = routeSnapshot; // Extract the 'data' property from the route

    // Step 3: Extract the title from the data or use the fallback resolver if needed.
    // The title can be either a string or a function that returns a string.
    let title = data?.browserTitle || data?.title; // Check if the data contains either 'browserTitle' or 'title'.

    // If the title is neither a string nor a function, use the fallback resolver to get the title.
    if (!isString(title) && typeof title !== 'function') {
      title = this.resolveFallback(snapshot, data.paramKey, title);
    }

    // If the title is a function, call it with the 'data' object to get the actual title.
    if (typeof title === 'function') {
      title = title(data);
    }

    // Step 4: Update the browser's title.
    // If the title is null, it means no valid title was found, so we revert to the previously cached browser title.
    if (title === null) {
      this.title.setTitle(this._cachedBrowserTitle);
    } else {
      // If we have a valid title, set it as the new browser title, prefixed with the configured title prefix.
      this.title.setTitle(`${this.titlePrefix} - ${title}`);
    }
  }

  /**
   * This method is used to check if the given ActivatedRouteSnapshot contains relevant title information in its data.
   * The method checks if the snapshot's data has either a 'browserTitle' or 'title' property.
   * If any of these properties are present and have a truthy value, it means the snapshot contains valid title information.
   * If the snapshot doesn't have the required data, it means its parent should be checked (since we traverse up the snapshot tree).
   *
   * Note: The term "filter" here refers to filtering out snapshots that don't contain the relevant title data.
   */
  private filterByTitle(snapshot: ActivatedRouteSnapshot): boolean {
    return (
      snapshot.data && // Check if the snapshot has a 'data' property.
      (snapshot.data?.browserTitle || snapshot.data?.title) // Check if either 'browserTitle' or 'title' property exists in the snapshot's data.
    );
  }
}
