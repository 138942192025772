import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewEncapsulation } from '@angular/core';

const MSG_MANDATORY_GENERIC = 'Bitte füllen Sie dieses Pflichtfeld aus.';
const MSG_MANDATORY_RADIO = 'Bitte wählen Sie eine Option.';
const MSG_MANDATORY_DROPDOWN = 'Bitte wählen Sie eine Option.';
const MSG_MANDATORY_CHECKBOX = 'Bitte aktivieren Sie dieses Pflichtfeld.';

@Component({
  selector: 'aok-required-error-message',
  encapsulation: ViewEncapsulation.None,
  template: ` <aok-error-message>{{ message }}</aok-error-message>`,
})
export class AokRequiredErrorMessageComponent implements AfterViewInit {
  message = MSG_MANDATORY_GENERIC;

  constructor(private elementRef: ElementRef, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    const parent = this.elementRef.nativeElement?.parentNode;
    const firstChild = parent?.firstChild;

    /**
     * possible first children:
     *  INPUT           --> this error message belongs to a plain input field
     *  DIV / AOK-RADIO            --> this error message belongs to a combobox
     *  DROPDOWN-INPUT  --> this error message belongs to a dropdown
     *  CHECK_BOX       --> this error message belongs to a checkbox
     */
    // for inputs just use the default message
    if (firstChild && ['DIV', 'AOK-RADIO'].includes(firstChild.nodeName)) {
      this.message = MSG_MANDATORY_RADIO;
      this.cd.detectChanges();
    } else if (firstChild && firstChild.nodeName === 'NG-SELECT') {
      this.message = MSG_MANDATORY_DROPDOWN;
      this.cd.detectChanges();
    } else if (firstChild && firstChild.nodeName === 'CHECK-BOX') {
      this.message = MSG_MANDATORY_CHECKBOX;
      this.cd.detectChanges();
    }
  }
}
