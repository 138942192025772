import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AOK_DATE_FORMATS, AokDateAdapter } from '@aok/common';
import { DatepickerHeaderComponent } from './datepicker-header/datepicker-header.component';

/** @title Basic date range picker */
@Component({
  selector: 'aok-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: AokDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: AOK_DATE_FORMATS },
  ],
})
export class AokDatepickerComponent implements OnInit {
  @Input() innerId: string;
  @Input() placeholder: string;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() allowFutureDate = true;
  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
  @Input() control: FormControl = new FormControl(null, []);

  public header = DatepickerHeaderComponent;
  public isInputHover = false;

  ngOnInit(): void {
    if (!this.allowFutureDate) {
      this.maxDate = new Date();
    }
  }

  public hoverInput(): void {
    this.isInputHover = true;
  }

  public stopInputHover(): void {
    this.isInputHover = false;
  }
}
