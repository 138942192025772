import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogOverlayModule } from '@aok/common';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { AokRemovePracticeStaffDialog } from './remove-practice-staff-dialog.component';

@NgModule({
  declarations: [AokRemovePracticeStaffDialog],
  imports: [CommonModule, DialogOverlayModule, AokDialogLayoutModule, A11yModule],
  exports: [AokRemovePracticeStaffDialog],
})
export class AokRemovePracticeStaffDialogModule {}
