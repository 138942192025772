<aok-cockpit-header-page-headline
  [headline]="'DMP-Fallübersicht'"
  [actionButton]="'Freigaben verwalten'"
  (actionClicked)="openSharingDialog()"
>
  <div headline-additional-info *ngIf="hasSharedDMPs">
    <aok-svg-icon
      class="tw-text-green tw-ml-7 tw-relative tw-top-[3px]"
      name="shared-report"
      size="32px"
      title="Sie haben Ihre DMP-Fälle anderen Ärzten freigegeben"
    ></aok-svg-icon>
  </div>
</aok-cockpit-header-page-headline>

<div class="tw-mt-3 tw-mb-3">
  <div class="aok-container">
    <aok-tabs class="tw-mb-3">
      <aok-tab
        class="tw-flex tw-items-center tw-justify-center"
        *ngFor="let category of categoriesOrder; trackBy: trackByIndex"
        [routerLink]="['/services/dmp-falluebersicht', categoryUrls[category.toLowerCase()]]"
        (keyup.enter)="navigateToCategory(category)"
        routerLinkActive="active"
      >
        {{ categoryLabels[category.toLowerCase()] }}
      </aok-tab>
    </aok-tabs>

    <ng-container *ngIf="showFilteringSection | async">
      <p class="tw-col-1-13 tw-mb-4" [ngSwitch]="dmpCategory">
        <ng-container *ngSwitchCase="dmpCaseCategory.REGULAR">
          Für diese Patienten sind Folgedokumentationen fällig. Bitte erstellen Sie diese bis zum Ende des Quartals.
        </ng-container>

        <ng-container *ngSwitchCase="dmpCaseCategory.INITIAL">
          Für diese Patienten liegt eine gültige Teilnahme- und Einwilligungserklärung vor, aber keine
          Erstdokumentation. Bitte erstellen Sie diese zeitnah.
        </ng-container>

        <ng-container *ngSwitchCase="dmpCaseCategory.SUSPENSION">
          Für diese Patienten wurde der DMP-Fall in den letzten drei Monaten beendet. Sollte die DMP-Teilnahme weiterhin
          gewünscht sein, erstellen Sie bitte eine Neueinschreibung.
        </ng-container>

        <ng-container *ngSwitchCase="dmpCaseCategory.ENROLLMENT">
          Diese Patienten nehmen an einem DMP teil. Bitte erstellen Sie regelmäßig die benötigten Folgedokumentationen.
        </ng-container>
      </p>

      <div class="tw-col-1-7 lg:tw-col-1-16" *ngIf="!hasLanrFilter">
        <aok-input-field label="Sichtbare Diagnose">
          <aok-dropdown
            [mode]="dropdownMode"
            [control]="filterForm.get('dmp')"
            [options]="diagnoseFilterOptions"
          ></aok-dropdown>
        </aok-input-field>
      </div>

      <div class="{{ hasLanrFilter ? 'tw-col-1-11' : 'tw-col-7-13' }}">
        <aok-search-input
          #searchInputComponent
          [label]="'Was suchen Sie?'"
          [searchString]="searchString"
          (search)="search($event)"
          (searchOnEnter)="search($event)"
        ></aok-search-input>
      </div>

      <aok-filter-button
        *ngIf="hasLanrFilter"
        class="tw-col-11-13 tw-h-fit tw-self-end"
        [isAreaOpen]="isOpenFilterArea"
        [counter]="filterCounter"
        (buttonClick)="isOpenFilterArea = !isOpenFilterArea"
      >
      </aok-filter-button>

      <aok-filter-area
        *ngIf="hasLanrFilter"
        class="tw-col-1-13"
        [isOpen]="isOpenFilterArea"
        [hasSelection]="filterCounter > 0"
        (resetFilters)="resetFilters()"
      >
        <div class="tw-col-2-8">
          <aok-input-field label="Sichtbare Diagnose">
            <aok-dropdown
              [mode]="dropdownMode"
              [control]="filterForm.get('dmp')"
              [options]="diagnoseFilterOptions"
            ></aok-dropdown>
          </aok-input-field>
        </div>

        <div class="tw-col-8-12">
          <aok-input-field label="LANR">
            <aok-dropdown
              [mode]="dropdownMode"
              [control]="filterForm.get('doctorId')"
              [options]="lanrFilterOptions"
            ></aok-dropdown>
          </aok-input-field>
        </div>
      </aok-filter-area>

      <div class="tw-col-1-7 results-counter">
        <h6>
          {{ dmpCases?.page.totalElements | i18nPlural : recordsMapping }}
        </h6>
        <a
          *ngIf="isFiltering$ | async"
          class="aok-link-1"
          (click)="resetSearchAndFilters()"
          (keyup.enter)="resetSearchAndFilters()"
        >
          Alle anzeigen
        </a>
      </div>

      <table
        aria-label="Die Buttons in der Tabellenüberschrift dienen zum Sortieren der jeweiligen Spalte."
        aria-describedby="DMP-Falluebersicht"
        *ngIf="dmpCases?._embedded?.items?.length"
        class="aok dmp-table tw-col-1-13"
        [ngClass]="dmpCategory | lowercase"
        cdk-table
        [dataSource]="dmpCases?._embedded?.items"
        [trackBy]="dmpCasesTrackByFn"
      >
        <ng-container *ngFor="let column of getDisplayedColumns; trackBy: columnsTrackByFn">
          <ng-container [cdkColumnDef]="column?.id">
            <th
              scope="col"
              cdk-header-cell
              *cdkHeaderCellDef
              [disable]="isOptions(column?.id)"
              [attr.tabindex]="isOptions(column?.id) ? -1 : 0"
              [ngClass]="{
                clickable: !isOptions(column?.id),
                'events-none': isOptions(column?.id)
              }"
              (keyup.enter)="sortBy(getColumnSortBy(column))"
              (click)="sortBy(getColumnSortBy(column))"
            >
              <div
                [attr.aria-sort]="setAriaSort(isSortedBy(getColumnSortBy(column)), sortDirection)"
                role="button"
                class="flex"
                [ngClass]="{ 'sort-active': isSortedBy(getColumnSortBy(column)) }"
              >
                <span *ngIf="!isOptions(column?.id)">{{ column?.label }}</span>

                <!--                TODO get the sort by directly inside the methods to avoid always calling getColumnSortBy to get the parameter value -->
                <ng-container *ngIf="isSortedBy(getColumnSortBy(column))">
                  <aok-svg-icon
                    [size]="16"
                    [name]="sortDirection === 'asc' ? 'arrow-rounded_up' : 'arrow-rounded_down'"
                  >
                  </aok-svg-icon>
                </ng-container>
              </div>
            </th>

            <td cdk-cell *cdkCellDef="let dmp">
              <ng-container *ngIf="!isOptions(column?.id); else optionsMenu">
                <p>
                  <ng-container *ngIf="isValueDateType(column?.id)">
                    <span>{{ dmp?.[column?.id] | date }}</span>
                  </ng-container>

                  <ng-container *ngIf="isEndReason(column?.id)">
                    <span>{{ dmp?.[column?.id] | slice: 0:100 }}</span>
                    <span *ngIf="dmp?.[column?.id]?.length >= 100">... .</span>
                  </ng-container>

                  <ng-container *ngIf="isName(column?.id)">
                    <span>{{ dmp?.firstName }} {{ dmp?.lastName }}</span>
                  </ng-container>

                  <ng-container *ngIf="isSimpleLabel(column?.id)">
                    <span>{{ dmp?.[column?.id] }}</span>
                  </ng-container>
                </p>
              </ng-container>

              <ng-template #optionsMenu>
                <aok-menu-button [entries]="menuOptions" (selected)="$event['action'](dmp)"></aok-menu-button>
              </ng-template>
            </td>
          </ng-container>
        </ng-container>

        <tr cdk-header-row *cdkHeaderRowDef="getDisplayedColumnIds"></tr>
        <tr cdk-row *cdkRowDef="let row; columns: getDisplayedColumnIds"></tr>
      </table>

      <div class="pagination tw-col-1-13" *ngIf="dmpCases?.page?.totalPages > 1">
        <aok-pagination
          [activePage]="$any(dmpCases.page.number)"
          [totalItems]="dmpCases.page.totalElements"
          [pageSize]="dmpCases.page.size"
          routeQueryParam="page"
        >
        </aok-pagination>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="dmpCases?._embedded?.items?.length === 0" [ngTemplateOutlet]="noDmpCasesTemplate"></ng-container>
</div>

<ng-template #noDmpCasesTemplate>
  <div class="aok-bg-sand tw-col-1-13">
    <div class="aok-container no-data-container" [ngClass]="{ 'with-margins': (isFiltering$ | async) === false }">
      <div class="no-data-headline tw-col-2-10 lg:tw-col-3-11">
        {{
          (isFiltering$ | async)
            ? 'Es wurde kein passender Datensatz gefunden'
            : 'Derzeit sind in dieser Ansicht keine DMP-Fälle vorhanden.'
        }}
      </div>

      <div class="no-data-message tw-col-2-10 lg:tw-col-3-11">
        {{
          (isFiltering$ | async)
            ? 'Überprüfen Sie Ihre Filtereinstellungen'
            : 'Die DMP-Fälle werden regelmäßig aktualisiert, bitte überprüfen Sie die Ansicht zu einem späteren Zeitpunkt erneut.'
        }}
      </div>
    </div>
  </div>
</ng-template>
