import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AokBenefit, AokPage } from '@aok/common';
import { BenefitsService } from '../services/benefits.service';

export const favoriteBenefitsResolver: ResolveFn<AokPage<AokBenefit>> = () => {
  const service = inject(BenefitsService);

  return service.retrieveFavorites();
};
