import { Injectable } from '@angular/core';
import { AokDMP, AokInsuranceStatusClient, AokLanr9Pipe, AokUser, ContextState } from '@aok/common';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, skip, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DmpProgramsService {
  constructor(private contextState: ContextState, private client: AokInsuranceStatusClient) {}

  /**
   * retrieve the DMP programs once - for resolver
   */
  public getDmpPrograms(): Observable<AokDMP[]> {
    return this.contextState.getPractitioner$().pipe(
      filter((practitioner) => practitioner !== undefined),
      take(1),
      mergeMap((practitioner) => {
        return this.retrieveDmpPrograms(practitioner);
      })
    );
  }

  /**
   * retrieve the DMP programs continuously as the context changes
   * the current context is ignored, the programs are retrieved when the context is changed
   */
  public getDmpProgramsOnContextChange(): Observable<AokDMP[]> {
    return this.contextState.getPractitioner$().pipe(
      filter((practitioner) => !!practitioner),
      skip(1),
      mergeMap((practitioner) => {
        return this.retrieveDmpPrograms(practitioner);
      })
    );
  }

  private retrieveDmpPrograms(practitioner: AokUser | Partial<AokUser>): Observable<AokDMP[]> {
    const lanrPipe = new AokLanr9Pipe();
    const lanr = lanrPipe.transform(practitioner?.practitionerResource);
    return this.client.getAllowedDMPs(lanr).pipe(map((allowed) => allowed.programs));
  }
}
