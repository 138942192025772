import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokHeaderLinkComponent } from './header-link.component';

@NgModule({
  declarations: [AokHeaderLinkComponent],
  exports: [AokHeaderLinkComponent],
  imports: [CommonModule, AokSvgIconComponent, A11yUtilsModule],
})
export class AokHeaderLinkModule {}
