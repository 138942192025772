import { Overlay, OverlayConfig, OverlayPositionBuilder, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, Type } from '@angular/core';
import { ConnectedDialogRef, DialogBase, DialogRef, DialogResponseType } from './dialog-ref';
import { OpenedDialogService } from '../../../services/close-dialog.service';

/** Describes available options for the creation of a dialog component instance */
export interface DialogConfig<C = any> {
  disposeOnBackdropClick?: boolean; // todo: consider support for customizing the dispose result?
  overlay?: OverlayConfig;
  closable?: boolean;
  props?: {
    // TODO check why the type is always 'never'
    // eslint-disable-next-line @typescript-eslint/ban-types
    [P in keyof C]?: C[P] extends Function ? never : C[P];
  };
}

@Injectable()
export class DialogOverlay {
  get scrollStrategies(): ScrollStrategyOptions {
    return this.overlay.scrollStrategies;
  }

  constructor(
    protected overlay: Overlay,
    protected injector: Injector,
    protected openedDialogService?: OpenedDialogService
  ) {}

  position(): OverlayPositionBuilder {
    return this.overlay.position();
  }

  create<T extends DialogBase<any>>(
    componentType: Type<T>,
    config: DialogConfig<T> = {}
  ): ConnectedDialogRef<DialogResponseType<T>, T> {
    const overlayRef = this.overlay.create(this.createOverlayConfig(config));
    const dialogRef = new DialogRef<DialogResponseType<T>>(overlayRef, config);
    const portal = new ComponentPortal(
      componentType,
      null,
      Injector.create({
        parent: this.injector,
        providers: [{ provide: DialogRef, useValue: dialogRef }],
      })
    );
    return new ConnectedDialogRef<DialogResponseType<T>, T>(dialogRef, this.openedDialogService, portal);
  }

  createOverlayConfig(config: DialogConfig = {}): OverlayConfig {
    return new OverlayConfig({
      ...config.overlay,
      scrollStrategy: this.scrollStrategies.block(),
      hasBackdrop: true,
    });
  }
}
