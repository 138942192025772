import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  SecurityContext,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'aok-article-content',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ['aok-article-content { display: block; }'],
  template: ` <div class="article-content" [hidden]="tease" #contentContainer></div>
    <div class="article-content-tease" [innerText]="firstNonEmptyParagraph" [hidden]="!tease"></div>`,
})
export class AokArticleContentComponent {
  private _tease = false;
  @ViewChild('contentContainer', { static: true })
  private _contentContainer: ElementRef<HTMLDivElement>;
  private _valueAsHtml: string;
  private _value: string;

  @Input()
  set value(value: string) {
    this._value = value;
    this._valueAsHtml = this.sanitizer.sanitize(SecurityContext.HTML, value);
    this._contentContainer.nativeElement.innerHTML = this._valueAsHtml;
  }
  get value(): string {
    return this._value;
  }

  @Input()
  set tease(value: boolean) {
    this._tease = coerceBooleanProperty(value);
  }
  get tease(): boolean {
    return this._tease;
  }

  get firstNonEmptyParagraph(): string {
    return (
      Array.from(this._contentContainer.nativeElement.querySelectorAll('p') || []).find((p) => {
        return Array.from(p.childNodes || []).filter((child) => !(child instanceof HTMLBRElement))?.length > 0;
      })?.innerText || ''
    );
  }

  constructor(readonly sanitizer: DomSanitizer) {}
}
