import { Pipe, PipeTransform } from '@angular/core';
import { AokDoctorResource, zeroPad } from '@aok/common';

/**
 * pipe for specialization display
 */
@Pipe({ name: 'aokFgs' })
export class AokFgsPipe implements PipeTransform {
  transform(value: AokDoctorResource): string {
    return zeroPad(value?.specialization?.id, 2);
  }
}
