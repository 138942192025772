<ng-container
  *ngTemplateOutlet="
    dialogTemplate;
    context: {
      contentTemplate: sessionRemainTime > 0 ? sessionExpiryPendingTemplate : sessionExpiredTemplate,
      headerText: sessionRemainTime > 0 ? 'Ihre Sitzung läuft bald ab' : 'Sitzung abgelaufen'
    }
  "
></ng-container>

<ng-template let-headerText="headerText" let-contentTemplate="contentTemplate" #dialogTemplate>
  <dialog-layout
    [headerText]="headerText"
    [fullscreen]="false"
    [isClosable]="false"
    cdkTrapFocus
    cdkTrapFocusAutoCapture="true"
  >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </dialog-layout>
</ng-template>

<ng-template #sessionExpiryPendingTemplate>
  <div class="tw-mb-3">
    Sie waren einige Zeit inaktiv und werden in Kürze automatisch abgemeldet. Möchten Sie weiterhin angemeldet bleiben?
  </div>

  <div class="icon-container">
    <div class="count-down-progress">
      <div class="progress" #progress>
        <span class="progress-value"></span>
      </div>

      <h5 class="time">{{ sessionRemainTime }}</h5>
    </div>
  </div>

  <div class="button-container">
    <button class="secondary" (click)="logout(false)">Abmelden</button>
    <button (click)="refreshSession()">Angemeldet bleiben</button>
  </div>
</ng-template>

<ng-template #sessionExpiredTemplate>
  <div class="tw-mb-7">
    Um Ihre Daten zu schützen wurden Sie automatisch abgemeldet. Wenn Sie das Arztportal weiter nutzen möchten, melden
    Sie sich bitte erneut an.
  </div>

  <div class="icon-container">
    <aok-svg-icon [name]="'session-clock'" size="130px"></aok-svg-icon>
  </div>

  <aok-input-field class="block">
    <aok-checkbox #reopenLastPageCheckbox>
      <p>Aktuelle Seite nach dem Anmelden erneut öffnen?</p>
    </aok-checkbox>
  </aok-input-field>

  <div class="button-container">
    <button (click)="logout(reopenLastPageCheckbox._selected)">Neu anmelden</button>
  </div>
</ng-template>
