import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'aokRemoveFileExtension', standalone: true })
export class AokRemoveFileExtensionPipe implements PipeTransform {
  /**
   * removes the file extension from a given filename
   *  If the file extension matches an any item in contentType (or if no content types are specified),
   *  the function removes the file extension (and the preceding period) from the filename
   *  and returns the modified filename.
   * @param filename - the name of the file
   * @param contentType - list of extensions that should be removed (if none specified, all will be removed)
   */
  transform(filename: string, contentType?: string[]): string {
    const contentTypes = contentType?.map((type) => type.toLowerCase());
    const lastDotIndex = filename.lastIndexOf('.');

    // If there's no '.', or it's at the start/end, return the original filename.
    if (lastDotIndex <= 0 || lastDotIndex === filename.length - 1) {
      return filename;
    }

    const extension = filename.substring(lastDotIndex + 1).toLowerCase();

    if (!contentTypes?.length || contentTypes?.includes(extension)) {
      return filename.substring(0, lastDotIndex);
    }

    return filename;
  }
}
