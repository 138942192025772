import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { AOK_STATIC_ARTICLE_RESOLVE_PARAM_KEY } from '../resolvers/static-article.resolver';
import { AokStaticArticleRoute } from '../schemas';

export const existingStaticArticle: CanActivateFn = (route: ActivatedRouteSnapshot): UrlTree | true => {
  const router = inject(Router);
  const routeParamKey = inject(AOK_STATIC_ARTICLE_RESOLVE_PARAM_KEY);

  const isStaticArticleKey = Object.values(AokStaticArticleRoute).includes(route.params[routeParamKey]);
  return isStaticArticleKey || router.parseUrl('/');
};
