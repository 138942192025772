/**
 * Trim the content to the given character count
 * add ellipsis if content longer than character limit
 * @param content to be trimmed
 * @param characterCount to trim at
 * @param removeWhitespaces from the trimmed content
 */
export const trimTo = (content: string, characterCount: number, removeWhitespaces = false): string => {
  if (content?.length > characterCount) {
    let trimmedContent = content.slice(0, characterCount);

    if (removeWhitespaces) {
      trimmedContent = trimmedContent.trim();
    }

    return `${trimmedContent}...`;
  }

  return content;
};

export const isString = (value: unknown): value is string => typeof value === 'string';
