<dialog-layout
  [fullscreen]="true"
  [isClosable]="true"
  [headerText]="'Praxis hinzufügen'"
  aokFocusFirstChild
  cdkTrapFocus
>
  <aok-practice-form [formGroup]="formGroup" #practiceForm></aok-practice-form>
  <div class="tw-col-1-9 tw-mt-3">
    <button (click)="submit()">Daten senden</button>
  </div>
</dialog-layout>
