import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AokKeycloakTokenParsed,
  AokUserClient,
  AokUserRegistrationClient,
  checkTIConnection,
  ENVIRONMENT,
  EnvironmentSchema,
  KvnError,
  KvnInfo,
  KvnPracticeAccountStatus,
  KvnUserTypes,
  UserLanrStatus,
  UserLanrStatusState,
  UserService,
} from '@aok/common';
import { mergeMap, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { KvnState } from '../states';

@Injectable({
  providedIn: 'root',
})
export class KvnService {
  private kvnIDPs = ['kvn-saml', 'kvnportal'];

  constructor(
    @Inject(ENVIRONMENT) private environment: EnvironmentSchema,
    private userService: UserService,
    private userRegistrationClient: AokUserRegistrationClient,
    private lanrStatusState: UserLanrStatusState,
    private userClient: AokUserClient,
    private kvnState: KvnState,
    private http: HttpClient
  ) {}

  /**
   * Sets KVN information including access, roles and origin.
   */
  public getKvnInfo(): Observable<KvnInfo> {
    if (this.kvnState.getKvnInfo()) {
      return this.kvnState.getKvnInfo$();
    }

    return this.isKvnWithoutAccess$().pipe(
      map((isKvnWithoutAccess: boolean) => {
        const kvnInfo: KvnInfo = {
          isKvnWithoutAccess,
          isKvn: this.isKvn(),
          isKvnPracticeTeam: this.isKvnPracticeTeam(),
          isKvnDoctor: this.isKvnDoctor(),
          isKvnOrigin: this.isKvnOrigin(),
        };
        this.kvnState.setKvnInfo(kvnInfo);

        return kvnInfo;
      })
    );
  }

  /**
   * Determines if the current context is within KVN but lacks the necessary access.
   * A KVN user is without access when:
   *  - the kvnApplication field is not present
   *  - logs in via KVN portal but has no TI connection available
   * Doctor
   *  - he is a pending user & there is another registration in progress
   *  Assistant
   *  - no doctor from his practice has joined
   *  - his practice account is disabled by the doctor
   *
   * This method checks for a valid KVN context, then verifies KVN origin and access rights,
   * handling TI connection availability.
   * @returns {Observable<boolean>} An Observable emitting true if KVN without access, false otherwise.
   */
  public isKvnWithoutAccess$(): Observable<boolean> {
    // TODO should we reset the state? is there a case where error cases changes only via app navigation and not refresh?
    this.kvnState.setKvnError(null);

    // if current login is not originating from KVN portal, then the checks are not relevant
    if (!this.isKvnOrigin()) {
      return of(false);
    }

    // Handle the case when the current context is originating from KVN.
    // Checks TI connection if feature enabled (mock true otherwise) and then KVN access based on the connection availability.
    const tiRequest = this.environment.checkTI
      ? checkTIConnection(this.http, this.environment.tiConfig.baseUrl)
      : of(true);

    // returns an Observable indicating lack of access with true if TI connection is unavailable, otherwise defers to `checkKvnAccess`.
    return tiRequest.pipe(
      mergeMap((connectionAvailable) => {
        if (connectionAvailable) {
          return this.checkKvnAccess();
        } else {
          this.kvnState.setKvnError(KvnError.NO_TI_CONNECTION);
          return of(true);
        }
      })
    );
  }

  private checkKvnAccess(): Observable<boolean> {
    if (!this.hasKvnApplicationAttribute()) {
      this.kvnState.setKvnError(KvnError.NO_KVN_ATTRIBUTE);
      return of(true);
    }

    if (this.isKvnDoctor()) {
      if (!this.userService.isPendingHCP()) {
        return of(false);
      }

      return this.isKvnDoctorWithoutAccess$();
    } else {
      return this.isKvnAssistantWithoutAccess$();
    }
  }

  private isKvn(): boolean {
    const parsedToken: AokKeycloakTokenParsed = this.userService.getParsedToken();
    return !!parsedToken?.kvnUniqueId;
  }

  private isKvnPracticeTeam(): boolean {
    const kvnUserTypeAttribute = this.userService.getParsedToken()?.kvnUserType;
    return !!kvnUserTypeAttribute && [KvnUserTypes.Praxismitarbeiter].includes(kvnUserTypeAttribute);
  }

  private isKvnDoctor(): boolean {
    const kvnUserTypeAttribute = this.userService.getParsedToken()?.kvnUserType;
    return !!kvnUserTypeAttribute && [KvnUserTypes.Arzt].includes(kvnUserTypeAttribute);
  }

  private isKvnOrigin(): boolean {
    const identityProvider: string = this.userService.getParsedToken()?.identity_provider;
    return identityProvider && this.kvnIDPs.includes(identityProvider);
  }

  private hasKvnApplicationAttribute(): boolean {
    return !!this.userService.getParsedToken()?.kvnApplication;
  }

  /**
   * check if KVN doctor doesn't have access to the application
   */
  private isKvnDoctorWithoutAccess$(): Observable<boolean> {
    if (this.lanrStatusState.snapshot) {
      const hasRegistration = this.lanrStatusState.snapshot === UserLanrStatus.REGISTRATION;
      this.kvnState.setKvnError(hasRegistration ? KvnError.EXISTING_REGISTRATION : null);
      return of(hasRegistration);
    }

    return this.userRegistrationClient.getUserLanrStatus().pipe(
      tap((lanrStatus) => this.lanrStatusState.patch(lanrStatus)),
      map((lanrStatus) => lanrStatus === UserLanrStatus.REGISTRATION),
      tap((hasRegistration) => this.kvnState.setKvnError(hasRegistration ? KvnError.EXISTING_REGISTRATION : null))
    );
  }

  /**
   * check if KVN assistant doesn't have access to the application
   */
  private isKvnAssistantWithoutAccess$(): Observable<boolean> {
    return this.userClient.getKvnPracticeAccountStatus().pipe(
      map((practiceAccountStatus) => {
        if (practiceAccountStatus === KvnPracticeAccountStatus.ALLOWED) return false;

        this.kvnState.setKvnError(KvnError[practiceAccountStatus.toString()]);
        return true;
      })
    );
  }
}
