import { Injectable } from '@angular/core';
import { UserLanrStatus } from '../schemas';
import { PrimitiveBehaviorState } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class UserLanrStatusState extends PrimitiveBehaviorState<UserLanrStatus> {
  constructor() {
    super();
  }
}
