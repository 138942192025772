import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AokHttpParamEncoder } from '../../utils';

@Injectable()
export class AokHttpParamsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const params = new HttpParams({
      encoder: new AokHttpParamEncoder(),
      fromString: req.params.toString(),
    });
    return next.handle(req.clone({ params }));
  }
}
