import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { ENVIRONMENT_ID, RUNTIME_PROFILE_BASE_URL, AokRuntimeProfileState } from './runtime-profile.state';

export function initRuntimeProfile(runtimeConfig: AokRuntimeProfileState) {
  return /** @dynamic */ () => {
    return runtimeConfig.init();
  };
}

export interface RuntimeProfileModuleConfig {
  environmentId: string;
  baseUrl?: string;
}

@NgModule()
export class AokRuntimeProfileModule {
  static forRoot(config: RuntimeProfileModuleConfig): ModuleWithProviders<AokRuntimeProfileModule> {
    return {
      ngModule: AokRuntimeProfileModule,
      providers: [
        {
          provide: ENVIRONMENT_ID,
          useValue: config.environmentId,
        },
        {
          provide: RUNTIME_PROFILE_BASE_URL,
          useValue: config.baseUrl || '/assets/profiles',
        },
        AokRuntimeProfileState,
        {
          provide: APP_INITIALIZER,
          useFactory: initRuntimeProfile,
          deps: [AokRuntimeProfileState],
          multi: true,
        },
      ],
    };
  }
}
