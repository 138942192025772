import { Injectable } from '@angular/core';
import { AokReminder } from '../schemas';
import { ArrayBehaviorState } from '../utils/rx/behavior-state';

/**
 * state for all the reminders of a user, disregarding the context
 */
@Injectable({
  providedIn: 'root',
})
export class AokReminderState extends ArrayBehaviorState<AokReminder> {
  constructor() {
    super();
  }

  public setReminders(reminders: AokReminder[]): void {
    this.reset(...reminders);
  }
}
