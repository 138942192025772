export enum KvnUserTypes {
  Praxismitarbeiter = 'Praxismitarbeiter',
  Arzt = 'Arzt',
}

export interface KvnInfo {
  isKvn: boolean;
  isKvnPracticeTeam: boolean;
  isKvnDoctor: boolean;
  isKvnOrigin: boolean;
  isKvnWithoutAccess: boolean;
}

// to what kind of user the lanr is connected
export enum UserLanrStatus {
  FULL_USER = 'FULL_USER',
  REGISTRATION = 'REGISTRATION',
  NON_EXISTENT = 'NON_EXISTENT',
}

export enum KvnPracticeAccountStatus {
  NO_KVN_DOCTOR_FOUND = 'NO_KVN_DOCTOR_FOUND',
  PRACTICE_ACCOUNT_DISABLED = 'PRACTICE_ACCOUNT_DISABLED',
  ALLOWED = 'ALLOWED',
}

export enum KvnError {
  EXISTING_REGISTRATION = 'EXISTING_REGISTRATION',
  NO_KVN_ATTRIBUTE = 'NO_KVN_ATTRIBUTE',
  NO_KVN_DOCTOR_FOUND = 'NO_KVN_DOCTOR_FOUND',
  PRACTICE_ACCOUNT_DISABLED = 'PRACTICE_ACCOUNT_DISABLED',
  NO_TI_CONNECTION = 'NO_TI_CONNECTION',
}
