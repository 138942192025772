import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AokBenefit, AokBenefitSearchResult, AokPage, DEFAULT_PAGE_RESPONSE, isSearchResult } from '@aok/common';
import { AokPaginationModule } from '@aok/components';
import { BenefitAccordionComponent } from '../benefit-accordion/benefit-accordion.component';

@Component({
  selector: 'aok-cockpit-benefit-list',
  standalone: true,
  imports: [CommonModule, BenefitAccordionComponent, AokPaginationModule],
  templateUrl: './benefit-list.component.html',
  styleUrls: ['./benefit-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitListComponent {
  @Input() public benefits: AokPage<AokBenefitSearchResult | AokBenefit> = DEFAULT_PAGE_RESPONSE;
  @Input() public isFavoriteEnabled: boolean;
  @Input() public noBenefitsMessage: string;
  @Input() public noBenefitsTitle: string;

  @Output() public favorite = new EventEmitter<number>();

  public trackByFn(index: number, benefit: AokBenefitSearchResult | AokBenefit): number {
    return isSearchResult(benefit) ? benefit.documentId : benefit.id;
  }
}
