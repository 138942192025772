import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AokSvgIconComponent } from '@aok/common';

@Component({
  selector: 'aok-filter-area',
  templateUrl: './filter-area.component.html',
  styleUrls: ['./filter-area.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, AokSvgIconComponent],
})
export class FilterAreaComponent {
  @Input()
  isOpen: boolean;

  @Input()
  hasSelection: boolean;

  @Input()
  resetButtonLabel = 'Filter zurücksetzen';

  @Output()
  resetFilters: EventEmitter<void> = new EventEmitter();

  constructor() {}
}
