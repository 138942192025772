<div class="aok-reminder-carousel aok-container">
  <div class="aok-reminder-carousel__header">
    <a class="aok-link-1" (click)="showAllClick()" (keyup.enter)="showAllClick()" aokFocusable>
      {{ getLinkText() }}
      <aok-svg-icon name="arrow-rounded_right" size="16px"></aok-svg-icon>
    </a>
  </div>

  <div class="aok-reminder-carousel__body">
    <div [@slideInOut]="counter">
      <ng-container *ngFor="let reminder of reminders; let i = index">
        <aok-reminder-teaser
          *ngIf="isActive(i)"
          [reminder]="reminder"
          (actionLinkClicked)="openLink($event)"
        ></aok-reminder-teaser>
      </ng-container>
    </div>
  </div>

  <div *ngIf="reminders.length > 1" class="aok-reminder-carousel__controls tw-flex tw-justify-center tw-items-center">
    <div
      class="aok-reminder-carousel__control"
      id="previous"
      aokFocusable
      (keyup.enter)="previous()"
      (click)="previous()"
    >
      <aok-svg-icon role="img" title="Vorherige Aktion" name="arrow-rounded_left" size="16px"></aok-svg-icon>
    </div>

    <div
      *ngFor="let reminder of reminders; let i = index"
      class="aok-reminder-carousel__step"
      aokFocusable
      role="img"
      title="Aktion {{ i + 1 }}"
      (keyup.enter)="setActiveIndex(i)"
      (click)="setActiveIndex(i)"
    >
      <div [class.active]="isActive(i)"></div>
    </div>

    <div class="aok-reminder-carousel__control" id="next" aokFocusable (keyup.enter)="next()" (click)="next()">
      <aok-svg-icon role="img" title="Nächste Aktion" name="arrow-rounded_right" size="16px"></aok-svg-icon>
    </div>
  </div>
</div>
