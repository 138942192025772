import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  A11yUtilsModule,
  AokFullNamePipeModule,
  AokMenuButtonEntry,
  AokMfaToBSNRRegistration,
  AokOrg,
  AokPopoverComponent,
  AokRegistrationStatusIntlPipe,
  AokSvgIconComponent,
  AokToastService,
  AokUser,
  AokUserClient,
  AokUserRegistration,
  AokUserRegistrationClient,
  AokUserRegistrationStatus,
  CurrentUserState,
  DialogOverlay,
  KnownUserType,
  PopoverDirection,
} from '@aok/common';
import {
  AccordionService,
  AokAccordionOptionModule,
  AokFgsModule,
  AokMenuButtonModule,
  AokTableModule,
  DialogManagerService,
  DialogResult,
  KvnPracticeAccountDialog,
  KvnState,
  MfaDialogAction,
  ProfileAssistant,
  ProfileService,
  TableCellStatus,
} from '@aok/components';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PROFILE_ACCORDION_ID_PREFIX, PROFILE_PRIMARY_ACCORDION_ID } from '../../../config/profile.config';

interface UnselectedOrganizationData {
  org: AokOrg;
  doctors?: AokUser[];
  assistants: ProfileAssistant[];
}

@Component({
  selector: 'aok-cockpit-practice-management',
  styleUrls: ['./practice-management.component.scss'],
  templateUrl: './practice-management.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AokAccordionOptionModule,
    AokPopoverComponent,
    CdkTableModule,
    AokFullNamePipeModule,
    AokFgsModule,
    AokTableModule,
    AokMenuButtonModule,
    AokSvgIconComponent,
    A11yUtilsModule,
  ],
})
export class PracticeManagementComponent implements OnInit {
  public selectedOrgDoctors: AokUser[] = [];
  public selectedOrgAssistants: ProfileAssistant[] = [];
  public displayColumnsDoctors: string[] = ['name', 'lanr', 'fgs'];
  public displayColumnsAssistants: string[] = [];
  public showOptionColumn: boolean;
  public labelSelectedPractice = 'Ausgewählte Praxis';
  public options: AokMenuButtonEntry<ProfileAssistant>[] = [
    { id: 'editMfaData', title: 'Daten bearbeiten', action: this.editMfaDialog.bind(this) },
    { id: 'deleteMfaInvitation', title: 'Einladung löschen', action: this.deleteMfaInvite.bind(this) },
    { id: 'sendReminder', title: 'An Registrierung erinnern', action: this.sendReminder.bind(this) },
    { id: 'openMfaData', title: 'Anfrage öffnen', action: this.openMfaRequestDialog.bind(this) },
    {
      id: 'removePracticeEmployee',
      title: 'Praxismitarbeiter entfernen',
      action: this.removePracticeEmployee.bind(this),
    },
  ];
  public selectedOrg: AokOrg;
  public unselectedOrganizationsData: UnselectedOrganizationData[];
  public accordionIdPrefix = PROFILE_ACCORDION_ID_PREFIX;
  public primaryAccordionId = PROFILE_PRIMARY_ACCORDION_ID;
  public isKvnDoctor: boolean;
  public kvnPracticeAccountControlText: string;
  public isAccessForPMGranted: boolean;
  public popoverDirection: PopoverDirection = PopoverDirection.BottomRight;

  private allAssistantsColumns: string[] = ['name', 'status', 'option'];
  private kvnPmOnlyColumns: string[] = ['name', 'status'];

  constructor(
    @Optional() protected router: Router,
    private dialogService: DialogManagerService,
    private contextUser: CurrentUserState,
    private registerClient: AokUserRegistrationClient,
    private toastService: AokToastService,
    private dialog: DialogOverlay,
    private registrationStatusPipe: AokRegistrationStatusIntlPipe,
    private accordionService: AccordionService,
    private profileService: ProfileService,
    private userClient: AokUserClient,
    private kvnState: KvnState,
    private cd: ChangeDetectorRef
  ) {
    this.updatesUsersAndOrganisations();
    this.setupKvnDetails();
  }

  ngOnInit(): void {}

  public getGridOptions(user: ProfileAssistant): AokMenuButtonEntry<ProfileAssistant>[] {
    if (this.doesUserHaveType(user, KnownUserType.Kvn_Practice_Team)) return [];

    const status = (user as AokMfaToBSNRRegistration).status || (user as AokUserRegistration).registrationStatus;

    switch (status) {
      case AokUserRegistrationStatus.RequestReceived:
        return [this.options[3]];
      case AokUserRegistrationStatus.Invited:
        return this.options.slice(0, 3);
      case AokUserRegistrationStatus.CardCreation:
      default:
        // active assistants
        return [this.options[0], this.options[4]];
    }
  }

  public inviteMfaDialog(orgId: number): void {
    this.openMfaDialog(this.contextUser.snapshot, this.profileService.getOrganisationByIdOrSelected(orgId)).subscribe(
      (result) => {
        if (result?.action === MfaDialogAction.INVITED && result?.success) {
          this.toastService.createSuccessToast(
            'Ihre Daten wurden erfolgreich übermittelt.',
            'Der Praxismitarbeiter erhält eine Einladung per E-Mail.'
          );
        }
      }
    );
  }

  public getTableCellStatus(status: AokUserRegistrationStatus): TableCellStatus {
    switch (status) {
      case AokUserRegistrationStatus.Invited:
      case AokUserRegistrationStatus.CardCreation:
        return 'unclear';
      default:
        return 'warning';
    }
  }

  public getTableCellText(status: AokUserRegistrationStatus): string {
    return this.registrationStatusPipe.transform(status);
  }

  public editPracticeAccountAccess(): void {
    this.dialog
      .create(KvnPracticeAccountDialog, {
        closable: true,
        props: {
          isKvnAccessForPMGranted: this.isAccessForPMGranted,
        },
      })
      .subscribe((response: boolean) => {
        if (response !== undefined) {
          this.userClient.updateKvnPracticeAccountControl(response).subscribe(() => {
            this.isAccessForPMGranted = response;
            this.setupKvnPracticeAccountLabel();
            this.cd.markForCheck();
          });
        }
      });
  }

  protected hcpTrackByFn(index: number, user: AokUser): number {
    return user.id;
  }

  protected assistantsTrackByFn(index: number, assistant: ProfileAssistant): number {
    return assistant.id;
  }

  protected unselectedOrganizationsTrackBy(index: number, orgData: UnselectedOrganizationData): number {
    return orgData.org.id;
  }

  private setupKvnDetails(): void {
    this.isAccessForPMGranted = this.contextUser.snapshot.practitionerResource.kvnPracticeAccountControl;
    this.setupKvnPracticeAccountLabel();
    this.isKvnDoctor = this.kvnState.isKvnDoctor();
    this.cd.markForCheck();
  }

  private setupKvnPracticeAccountLabel(): void {
    this.kvnPracticeAccountControlText = this.isAccessForPMGranted
      ? 'Alle Praxismitarbeiter dürfen vom KVN-Portal aus auf das AOK-Arztportal zugreifen.'
      : 'Praxismitarbeiter dürfen vom KVN-Portal aus nicht auf das AOK-Arztportal zugreifen.';
    this.cd.markForCheck();
  }

  private updatesUsersAndOrganisations(): void {
    this.profileService.organisations
      .asObservable()
      .pipe(takeUntilDestroyed())
      .subscribe(({ selected, unselected }) => {
        this.selectedOrgDoctors = selected.doctors;
        this.selectedOrgAssistants = selected.assistants;
        this.setAssistantDisplayColumns();
        this.selectedOrg = selected.org;
        this.unselectedOrganizationsData = unselected?.map((organizationData) => ({
          ...organizationData,
          assistants: organizationData?.assistants?.map((assist) => ({
            ...assist,
          })),
        }));
        this.resetAccordions();
        this.cd.markForCheck();
      });
  }

  private setAssistantDisplayColumns(): void {
    const containsRegularAssistant = this.selectedOrgAssistants.some((user) =>
      this.doesUserHaveType(user, KnownUserType.Assistant)
    );
    if (containsRegularAssistant) {
      this.displayColumnsAssistants = this.allAssistantsColumns;
      this.showOptionColumn = true;
    } else {
      this.displayColumnsAssistants = this.kvnPmOnlyColumns;
      this.showOptionColumn = false;
    }
    this.cd.markForCheck();
  }

  private doesUserHaveType(user: ProfileAssistant, userType: KnownUserType): boolean {
    const currentUserType =
      (user as AokUser)?.userType ||
      (user as AokUserRegistration)?.userType ||
      (user as AokMfaToBSNRRegistration)?.userData?.userType;
    return (currentUserType as string) === userType;
  }

  private editMfaDialog(mfa: ProfileAssistant, organisationId: number): void {
    this.openMfaDialog(
      this.contextUser.snapshot,
      this.profileService.getOrganisationByIdOrSelected(organisationId),
      mfa
    ).subscribe((result) => {
      if (result?.action === MfaDialogAction.EDIT && result?.success) {
        this.toastService.createSuccessToast('Vorgang erfolgreich', 'Die Daten wurden gespeichert.');
      }
    });
  }

  private openMfaDialog(
    currentUser: AokUser,
    org: AokOrg,
    mfa?: ProfileAssistant
  ): Observable<DialogResult<MfaDialogAction>> {
    return this.dialogService
      .openMfaDialog({
        currentUser,
        org,
        mfa,
      })
      .pipe(
        tap((response) => {
          if (response) {
            this.profileService.loadAllUsers();
          }
        })
      );
  }

  private openMfaRequestDialog(requestMember: AokMfaToBSNRRegistration): void {
    this.dialogService.openEditMfaRequestToJoinPracticeDialog(requestMember).subscribe((reloadUsers: boolean) => {
      if (reloadUsers) {
        this.profileService.loadAllUsers(null, true);
      }
    });
  }

  private deleteMfaInvite(user: AokUserRegistration): void {
    this.dialogService.deletePMInvite(user).subscribe(() => this.profileService.loadAllUsers());
  }

  private sendReminder(user: AokUserRegistration): void {
    this.registerClient.sendReminder(user.id).subscribe({
      next: () => {
        this.toastService.createSuccessToast(
          'Vorgang erfolgreich',
          'Dem Praxismitarbeiter wurde eine  Erinnerung an die ausstehende Registrierung zugesendet.'
        );
      },
      error: () => {
        this.toastService.createErrorToast(
          'Vorgang fehlgeschlagen',
          'Die Erinnerung konnte nicht versendet werden. Bitte aktualisieren Sie diese Seite und versuchen Sie es erneut.'
        );
      },
    });
  }

  private removePracticeEmployee(user: ProfileAssistant): void {
    this.dialogService.openRemovePMDialog(user).subscribe((removed) => {
      if (removed) {
        this.profileService.loadAllUsers(null, true);
      }
    });
  }

  /**
   * reset accordions on practice management
   */
  private resetAccordions(): void {
    this.accordionService.openAccordion(
      PROFILE_PRIMARY_ACCORDION_ID,
      PROFILE_ACCORDION_ID_PREFIX + this.selectedOrg?.bsnr
    );
    // TODO check why list is empty
    this.profileService.getAllOrgs()?.forEach((org) => {
      const bsnr = org?.bsnr;
      if (bsnr !== this.selectedOrg?.bsnr) {
        this.accordionService.closeAccordion(PROFILE_ACCORDION_ID_PREFIX + bsnr);
      }
    });
  }
}
