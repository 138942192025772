import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AokBatchPrintingEntry,
  AokBatchPrintingResponse,
  AokBillingSheetTypeEnum,
  AokFullNamePipe,
  AokInsuranceStatusClient,
  AokInsuree,
  AokLanr9Pipe,
  AokLoadingStateService,
  AokPopoverComponent,
  AokSpecializationState,
  AokUser,
  AokUserClient,
  ContextState,
  CurrentUserState,
  DropdownSchema,
  getSpecializationDescription,
  isDoctor,
  KnownUserType,
  lanr9,
  PopoverDirection,
  scrollToTop,
  specialization,
} from '@aok/common';

import {
  AokDropdownModule,
  AokInputFieldModule,
  AokNotificationBannerModule,
  AokRadioModule,
  DropdownMode,
} from '@aok/components';
import { ReplaySubject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import {
  errorListDisplayedColumns,
  printListDisplayedColumns,
  radioOptions,
} from '../../../../config/billing-sheets-printing-results.config';
import { MatomoCustomVariable } from '../../../../config/matomo.config';
import { MatomoService } from '../../../../services/matomo.service';
import { PrintCollectionTableComponent } from '../print-collection-table/print-collection-table.component';

type BatchPrintingDataType = 'insurees' | 'errors';

@Component({
  selector: 'aok-cockpit-billing-sheets-printing-results',
  templateUrl: './billing-sheets-printing-results.component.html',
  styleUrls: ['./billing-sheets-printing-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    PrintCollectionTableComponent,
    AokInputFieldModule,
    AokRadioModule,
    ReactiveFormsModule,
    AokDropdownModule,
    AokPopoverComponent,
    AokNotificationBannerModule,
  ],
})
export class BillingSheetsPrintingResultsComponent implements OnInit, OnDestroy {
  @ViewChild('printList') printList: PrintCollectionTableComponent;

  @Input() batchPrintingData: AokBatchPrintingResponse;
  @Output() switchToEntryPage = new EventEmitter();

  public radioOptions = radioOptions;
  public readonly errorListDisplayedColumns = errorListDisplayedColumns;
  public readonly printListDisplayedColumns = printListDisplayedColumns;
  public readonly AokBillingSheetTypeEnum = AokBillingSheetTypeEnum;

  public dropdownMode = DropdownMode.ENHANCED;
  public specialization: string;
  public specializationOptions = this.specializationState.snapshot;
  public lanrOptions: DropdownSchema[];
  public isDoctor = isDoctor;
  public popoverDirection = PopoverDirection.RightTop;
  public printOptionsControl = new FormControl(AokBillingSheetTypeEnum.PrePrinted, Validators.required);
  public lanrControl = new FormControl(null, [Validators.required, lanr9, specialization(this.specializationOptions)]);
  public recordsMappingErrorList: { [k: string]: string } = { '=1': '# Patient konnte', other: '# Patienten konnten' };
  public recordsMappingPrintList: { [k: string]: string } = {
    '=0': 'Alle Abrechnungsscheine vom Druck ausgeschlossen',
    '=1': '# Abrechnungsschein kann gedruckt werden',
    other: '# Abrechnungsscheine können gedruckt werden',
  };

  public printListLength: number = undefined;

  private lanr: string;
  private ngDestroys = new ReplaySubject<void>(1);

  public get printListSortedData(): AokBatchPrintingEntry[] {
    return this.getWithUniqId('insurees').sort((a, b) => a?.lastName.localeCompare(b?.lastName));
  }

  public get isDisabledPrint(): boolean {
    return this.printListLength === 0;
  }

  constructor(
    private loader: AokLoadingStateService,
    public contextState: ContextState,
    public currentUser: CurrentUserState,
    private specializationState: AokSpecializationState,
    private userClient: AokUserClient,
    private lanrPipe: AokLanr9Pipe,
    private fullNamePipe: AokFullNamePipe,
    private client: AokInsuranceStatusClient,
    private matomoService: MatomoService,
    private cd: ChangeDetectorRef,
    private platform: Platform
  ) {}

  ngOnInit(): void {
    this.retrieveLanrOptions();
    this.subscribeToLanrControlChanges();
    this.getLanrFromContextState();
    this.prepareBatchPrintingData();
    this.prefillRadioOptions();
    this.catchHttpCallLoading();
    this.radioSwitchHandler();

    this.printListLength = this.batchPrintingData.insurees.length;
    this.matomoService.setMatomoBatchPrintCountVariable(this.printListLength);
  }

  ngOnDestroy(): void {
    this.matomoService.deleteMatomoCustomVariable(MatomoCustomVariable.BATCH_PRINT_COUNT);
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  public submit(): void {
    const printList = this.printList.batchPrintingData
      .filter((printingData) => !printingData?.removeFromPrinting)
      .map((printingData) => printingData.id);

    const lanr = this.lanrControl.value || this.lanr;
    const bsnr = this.contextState.bsnr;

    this.client
      .printBatchBillingSheet({
        insureeIds: printList,
        type: this.printOptionsControl.value,
        lanr,
        bsnr,
      })
      .pipe(takeUntil(this.ngDestroys))
      .subscribe((blob) => {
        const billingSheet = URL.createObjectURL(blob);

        setTimeout(() => {
          const pdfTab = window.open(billingSheet, '_blank');
          const isSafari = this.platform.SAFARI;
          if (pdfTab && !isSafari) {
            pdfTab.print();
          }
        });
      });
  }

  public updateBatchPrintingInsurees(uniqueId: number): void {
    const index = this.batchPrintingData?.insurees.findIndex((element) => element.uniqueId === uniqueId);

    this.batchPrintingData.insurees[index].removeFromPrinting =
      !this.batchPrintingData?.insurees[index]?.removeFromPrinting;

    this.countPrintListLength();

    if (this.isDisabledPrint) {
      this.disablePrintOptions();
    } else {
      this.enablePrintOptions();
    }
  }

  public displaySpecialization(): boolean {
    // specialization name should only be displayed if the LANR got entered manually = not in the original list
    return this.lanrControl.valid && !this.lanrOptions?.map((option) => option?.value).includes(this.lanrControl.value);
  }

  public removeErrorTableRow(uniqueId: number): void {
    const index = this.batchPrintingData?.errors.findIndex((element) => element.uniqueId === uniqueId);

    this.batchPrintingData?.errors.splice(index, 1);
    this.batchPrintingData.errors = [...this.batchPrintingData.errors];
  }

  public patientErrorSolved(solvedPrintErrorEntry: AokInsuree): void {
    this.prepareBatchPrintingData();

    const newEntry: AokBatchPrintingEntry = {
      ...solvedPrintErrorEntry,
      id: Number(solvedPrintErrorEntry.id),
      checkboxIdentityCheck: true,
      errorSolved: true,
    };

    this.batchPrintingData.insurees.unshift(newEntry);

    if (this.isDisabledPrint) {
      this.enablePrintOptions();
    }

    this.countPrintListLength();
  }

  private countPrintListLength(): void {
    this.printListLength = this.batchPrintingData.insurees.filter((entry) => !entry.removeFromPrinting).length;
    this.matomoService.setMatomoBatchPrintCountVariable(this.printListLength);
  }

  private disablePrintOptions(): void {
    this.printOptionsControl.disable();
    this.lanrControl.disable();
  }

  private enablePrintOptions(): void {
    this.printOptionsControl.enable();
    this.lanrControl.enable();
  }

  private radioSwitchHandler(): void {
    this.printOptionsControl.valueChanges
      .pipe(takeUntil(this.ngDestroys))
      .subscribe((value: AokBillingSheetTypeEnum.PrePrinted | AokBillingSheetTypeEnum.FullPrint) => {
        this.matomoService.setMatomoPrintingSheetVariable(value);
      });
  }

  /**
   *  Returns an array of AokBatchPrintingEntry objects with added unique IDs. We need uniqueId for include/exclude logic
   */
  private getWithUniqId(type: BatchPrintingDataType): AokBatchPrintingEntry[] {
    return this.batchPrintingData?.[type].map((entry: AokBatchPrintingEntry, index: number) => ({
      ...entry,
      uniqueId: index + 1,
      errorSolved: false,
    }));
  }

  private prepareBatchPrintingData(): void {
    this.batchPrintingData.errors = this.getWithUniqId('errors');
    this.batchPrintingData.insurees = this.printListSortedData;
  }

  private subscribeToLanrControlChanges(): void {
    this.lanrControl.valueChanges.pipe(takeUntil(this.ngDestroys)).subscribe((lanr) => {
      if (this.lanrControl.valid) this.specialization = getSpecializationDescription(lanr, this.specializationOptions);
    });
  }

  /**
   * retrieve users and build the lanr option or each
   */
  private async retrieveLanrOptions(): Promise<void> {
    this.contextState
      .getOrg$()
      .pipe(
        takeUntil(this.ngDestroys),
        filter((org) => !!org)
      )
      .subscribe((org) => {
        this.userClient
          .list({
            bsnr: org.bsnr,
            userType: [KnownUserType.Doctor, KnownUserType.Kvn_Doctor, KnownUserType.Full_Kvn_Doctor],
            size: 1000,
            sort: 'lastName',
          })
          .pipe(
            map((users) => users._embedded.items),
            map((users: AokUser[]) => users.map((user) => this.getLanrOptionFromUser(user))),
            tap((lanrOptions: DropdownSchema[]) => (this.lanrOptions = lanrOptions))
          )
          .subscribe(() => this.lanrControl.setValue(this.currentUser.lanr9));
      });
  }

  private prefillRadioOptions(): void {
    this.client
      .getBillingSheetPreference()
      .pipe(takeUntil(this.ngDestroys))
      .subscribe((result) => {
        this.printOptionsControl.setValue(result);

        this.cd.detectChanges();
      });
  }

  private catchHttpCallLoading(): void {
    this.loader.isLoading$
      .pipe(
        takeUntil(this.ngDestroys),
        filter((isLoading) => !isLoading)
      )
      .subscribe(() => scrollToTop());
  }

  private getLanrFromContextState(): void {
    this.contextState
      .getPractitioner$()
      .pipe(
        takeUntil(this.ngDestroys),
        filter((user) => !!user)
      )
      .subscribe(async (user) => (this.lanr = this.lanrPipe.transform(user.practitionerResource)));
  }

  /**
   * build lanr dropdown option based on given user
   */
  private getLanrOptionFromUser(user: AokUser): unknown {
    return { value: this.lanrPipe.transform(user.practitionerResource), label: this.getLanrText(user) };
  }

  /**
   * build label for lanr for user
   */
  private getLanrText(user: AokUser): string {
    return `${this.lanrPipe.transform(user.practitionerResource)} - ${this.fullNamePipe.transform(
      user,
      'TT FF LL',
      user.practitionerResource
    )}`;
  }
}
