import { Injectable, Optional, Renderer2, RendererFactory2 } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Accent, NotificationBannerLink } from '../schemas';
import { AokRuntimeProfileState } from '../states';

@Injectable({
  providedIn: 'root',
})
export class AokToastService {
  private renderer: Renderer2;

  constructor(
    private toasterService: ToastrService,
    rendererFactory: RendererFactory2,
    @Optional() private runtimeProfile: AokRuntimeProfileState
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public createToast(
    headline: string,
    label: string,
    accent: Accent,
    links?: NotificationBannerLink[],
    timeToLive = 5000,
    disableTimeOut = false
  ): Observable<void> {
    let config = {};
    if (disableTimeOut || this.shouldDisableTimeOut(accent, timeToLive, links)) {
      config = { disableTimeOut: true };
    } else if (timeToLive) {
      config = { timeOut: timeToLive, extendedTimeOut: timeToLive };
    }

    const toast = this.toasterService.show(label, headline, config);
    toast.toastRef.componentInstance.accent = accent;
    toast.toastRef.componentInstance.links = links;

    this.makeToastAccessible(toast);

    return toast.onHidden; // Return a void observable
  }

  public createSuccessToast(
    headline: string,
    label: string,
    links?: NotificationBannerLink[],
    timeToLive = 5000
  ): void {
    this.createToast(headline, label, Accent.GOOD, links, timeToLive);
  }

  public createErrorToast(headline: string, label: string, links?: NotificationBannerLink[]): Observable<void> {
    return this.createToast(headline, label, Accent.BAD, links, undefined, true);
  }

  public createSupportErrorToast(headline: string, label: string): void {
    const links = [
      {
        label: 'E-Mail an Support',
        clickHandler: () =>
          (window.location.href = `mailto:${this.runtimeProfile?.get('contactEmail') || 'aok-arztportal@nds.aok.de'}`),
      },
    ];
    this.createToast(headline, label, Accent.BAD, links, undefined, true);
  }

  private makeToastAccessible(toast: ActiveToast<unknown>): void {
    toast.portal.location.nativeElement.ariaLabel = 'Benachrichtigung';
    this.renderer.setAttribute(toast.portal.location.nativeElement, 'role', 'alert');
    this.renderer.setAttribute(toast.portal.location.nativeElement, 'tabindex', '0');

    setTimeout(() => {
      toast.portal.location.nativeElement.focus();
    }, 10);
  }

  /**
   * whether the toast should have timeout disabled (doesn't automatically disappear)
   */
  private shouldDisableTimeOut(accent: Accent, timeToLive: number | string, links: unknown[]): boolean {
    return !timeToLive && !(links || accent === Accent.BAD || accent === Accent.WARNING);
  }
}
