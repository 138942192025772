import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AokArticle, AokArticleListOptions, AokArticleType, AokPage } from '../schemas';
import { createAokArticleFormData } from '../utils';
import { AokArticleClientBase } from './base-article.client';

@Injectable({ providedIn: 'root' })
export class AokArticleClient extends AokArticleClientBase {
  find(id: NonNullable<number>): Observable<AokArticle> {
    return this.http.get<AokArticle>(this.resolveUrl(id.toString()));
  }

  list(options?: AokArticleListOptions): Observable<AokPage<AokArticle>> {
    return this.http.get<AokPage<AokArticle>>(this.resolveUrl('/'), {
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }

  listTypes(): Observable<AokArticleType[]> {
    return this.http.get<AokArticleType[]>(this.resolveUrl('types'));
  }

  create(article: Partial<AokArticle>, image?: File): Observable<AokArticle> {
    return this.http.post<AokArticle>(this.resolveUrl('/'), createAokArticleFormData(article, image));
  }

  update(id: NonNullable<number>, article: Partial<AokArticle>, image?: File): Observable<AokArticle> {
    return this.http.put<AokArticle>(this.resolveUrl(id.toString()), createAokArticleFormData(article, image));
  }

  delete(id: NonNullable<number>): Observable<void> {
    return this.http.delete<void>(this.resolveUrl(id.toString()));
  }
}
