import { Component, Inject, InjectionToken, OnDestroy, Optional, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AOK_STATIC_ARTICLE_RESOLVE_PARAM_KEY, AokStaticArticleRoute, scrollToTop } from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeedbackAvailableState } from '../../states';
import { AokSignLanguageVideoData } from './static-article.module';

export const AOK_STATIC_ARTICLE_PAGE_ROUTE_DATA_KEY = new InjectionToken<string>(
  'AOK_STATIC_ARTICLE_PAGE_ROUTE_DATA_KEY'
);

export const AOK_STATIC_SIGN_LANGUAGE_VIDEO_DATA = new InjectionToken<AokSignLanguageVideoData[]>(
  'AOK_STATIC_SIGN_LANGUAGE_VIDEO_DATA'
);

@Component({
  selector: 'aok-static-article-page',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./static-article.page.scss'],
  template: `
    <ng-container *ngIf="routeDataKey | aokRouteData | async as article; else emptyArticleTemplate">
      <aok-cockpit-header></aok-cockpit-header>
      <aok-info-banner></aok-info-banner>
      <div class="aok-container header">
        <div class="breadcrumbs">
          <a class="secondary" target="_self" href="/">
            <aok-svg-icon id="home" size="16px" title="Zurück zur Übersicht"></aok-svg-icon>
          </a>
          <aok-svg-icon id="arrow-right" size="16px"></aok-svg-icon>
          <span class="aok-text-l2"
            >{{
              [StaticArticleKey.SignLanguage].includes(routeParamKey | aokRouteParam | async)
                ? 'Gebärdensprache'
                : article.title
            }}
          </span>
        </div>

        <ng-container
          *ngIf="[StaticArticleKey.SignLanguage].includes(routeParamKey | aokRouteParam | async); else articleContent"
        >
          <h3>Gebärdensprache</h3>

          <ng-container *ngIf="(routeParamKey | aokRouteParam | async) === StaticArticleKey.SignLanguage">
            <div *ngFor="let video of signLanguageVideos" class="tw-col-2-12 lg:tw-col-3-11">
              <video
                class="body-width"
                controls
                controlslist="nodownload"
                width="100%"
                disablePictureInPicture
                [style.margin-top.px]="32"
              >
                <source [src]="video.src" type="video/mp4" />
              </video>
              <div class="video-description">{{ video.description }}</div>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #articleContent>
          <h3>{{ article.title }}</h3>

          <div class="full-width">
            <aok-article-content [value]="article.content"></aok-article-content>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <ng-template #emptyArticleTemplate>
      <aok-cockpit-header></aok-cockpit-header>
      <aok-cockpit-header-page-headline [headline]="'Kein Inhalt'"></aok-cockpit-header-page-headline>
    </ng-template>

    <aok-feedback-button></aok-feedback-button>
    <aok-cockpit-footer-wrapper class="top-space"></aok-cockpit-footer-wrapper>
  `,
})
export class AokStaticArticlePage implements OnDestroy {
  readonly StaticArticleKey = AokStaticArticleRoute;
  public showFeedback: boolean;
  private ngDestroyed: Subject<void>;

  constructor(
    @Inject(AOK_STATIC_ARTICLE_RESOLVE_PARAM_KEY) readonly routeParamKey: string,
    @Inject(AOK_STATIC_ARTICLE_PAGE_ROUTE_DATA_KEY) readonly routeDataKey: string,
    // todo: remove the hardcoded video element as soon as it can be embedded using the article dialo
    @Optional() @Inject(AOK_STATIC_SIGN_LANGUAGE_VIDEO_DATA) readonly signLanguageVideos: AokSignLanguageVideoData[],
    readonly keycloak: KeycloakService,
    private route: ActivatedRoute,
    private feedbackAvailableState: FeedbackAvailableState
  ) {
    this.ngDestroyed = new Subject<void>();
    this.handleParamChange();

    this.feedbackAvailableState.isFeedBackAvailable.asObservable().subscribe((showFeedback) => {
      this.showFeedback = showFeedback;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  private handleParamChange(): void {
    this.route.params.pipe(takeUntil(this.ngDestroyed)).subscribe(() => scrollToTop());
  }
}
