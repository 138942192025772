import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule, AokSvgIconComponent, DialogOverlayModule } from '@aok/common';
import { AokNotificationSidebarComponent } from './notification-sidebar.component';
import { AokNotificationModule } from './notification/notification.module';
import { AokReminderModule } from './reminder/reminder.module';

@NgModule({
  declarations: [AokNotificationSidebarComponent],
  exports: [AokNotificationSidebarComponent],
  imports: [
    DialogOverlayModule,
    A11yUtilsModule,
    A11yModule,
    CommonModule,
    AokReminderModule,
    AokNotificationModule,
    AokSvgIconComponent,
  ],
})
export class AokNotificationSidebarModule {}
