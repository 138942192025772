import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AokBaseNotificationClient } from '../clients/base-notification.client';
import { AokNotificationClient } from '../clients/notification.client';
import { AokNotification, AokNotificationCountResponse, AokNotificationListResponse } from '../schemas';
import { AokNotificationState } from '../states/notification.state';

@Injectable({ providedIn: 'root' })
export class AokNotificationService {
  constructor(
    private notificationClient: AokNotificationClient,
    private baseClient: AokBaseNotificationClient,
    private notificationState: AokNotificationState
  ) {}

  /**
   * retrieve the list of notifications and save them to the state
   */
  public retrieveNotifications(): Observable<AokNotification[]> {
    return this.notificationClient.list().pipe(
      map((response: AokNotificationListResponse) => response.content),
      catchError(() => of([])),
      tap((notifications: AokNotification[]) => this.notificationState.setNotifications(notifications))
    );
  }

  public delete(id: string): Observable<AokNotification[]> {
    return this.notificationClient.delete(id).pipe(mergeMap(() => this.retrieveNotifications()));
  }

  public deleteRead(): Observable<AokNotification[]> {
    return this.notificationClient.deleteRead().pipe(mergeMap(() => this.retrieveNotifications()));
  }

  public markAsRead(id: string): Observable<void> {
    return this.notificationClient.markAsRead(id);
  }

  public getCount(): Observable<AokNotificationCountResponse> {
    return this.baseClient.count().pipe(tap(({ count }) => this.notificationState.setNotificationCount(count)));
  }
}
