export function prepareHtmlForEmailBody(html: string): string {
  const strippedHtml = html
    .replace(/(\s+class="[^"]*")/g, '') // Remove inline styling
    .replace(/<a\s+href="([^"]*)".*?>(.*?)<\/a>/g, '$1') // Replace anchor tags with their href values
    // Add single new lines for block-level elements and list items, and remove them
    .replace(/<\/?(p|div|ul|ol)>\s*/g, '\n') // Add single new line for these tags
    .replace(/<li>\s*/g, '\n- ') // Replace <li> with "- " for list items
    .replace(/<\/li>\s*/g, '\n') // Replace closing </li> tags
    .replace(/<br\s*\/?>\s*/g, '\n') // Handle line breaks
    .replace(/<[^>]+>/g, '') // Remove all HTML tags
    .replace(/\n\s*\n/g, '\n') // Cleanup extra whitespace and new lines
    .trim();

  const encoded = encodeURIComponent(strippedHtml);

  // Remove incomplete encodeURIComponent sequence at the end, if any
  // This regex checks for a '%' followed by zero or one hexadecimal character at the end of the string
  return encoded.replace(/(%[0-9A-Fa-f]?|%)$/, '');
}
