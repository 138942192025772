<dialog-layout
  [main]="false"
  [fullscreen]="true"
  [headerText]="title"
  [isClosable]="true"
  cdkTrapFocus
  aokFocusFirstChild
>
  <div class="aok-container">
    <ng-container *ngTemplateOutlet="bannerHints"></ng-container>
  </div>

  <form [formGroup]="formGroup" class="aok-container">
    <div class="tw-col-2-12 lg:tw-col-3-11">
      <div class="required required-label"><aok-required-mark></aok-required-mark>{{ msgRequiredHint }}</div>
      <h5>Kontaktdaten</h5>

      <div class="emailContainer" id="emailContainer">
        <aok-input-field id="mailAddress" label="E-Mail" [required]="true">
          <input id="email" formControlName="email" />
        </aok-input-field>
        <aok-popover [yaxis]="16" [radius]="20" [width]="350" [direction]="popoverDirection">
          <p>Diese E-Mail wird für Ihren Login verwendet.</p>
        </aok-popover>
      </div>

      <div id="emailRepeatContainer">
        <aok-input-field label="E-Mail Wiederhohlung" required="true">
          <input id="email-repeat" formControlName="emailRepeat" />
        </aok-input-field>
      </div>

      <div id="submitContainer">
        <button (click)="submit()">{{ submitLabel }}</button>
      </div>
    </div>
  </form>
</dialog-layout>

<ng-template #bannerHints>
  <div class="bannerHint tw-col-2-12 lg:tw-col-3-11">
    <aok-notification-banner
      [bannerHeadline]="bannerHeadline"
      [bannerLabel]="bannerContent"
      accent="info"
    ></aok-notification-banner>
  </div>
</ng-template>
