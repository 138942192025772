import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';

import { ContextState, DropdownSchema, getCountryCodes } from '@aok/common';
import { SecondFactorAuthFields } from '../../utils';
import { AokInputFieldComponent, AokInputFieldModule } from '../cdk';
import { AokDropdownModule } from '../dropdown/dropdown.module';
import { FormControlErrorModule } from '../form-control-error/module';

@Component({
  selector: 'aok-phone-number-data',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AokDropdownModule, AokInputFieldModule, FormControlErrorModule],
  templateUrl: './phone-number-data.component.html',
  styleUrls: ['./phone-number-data.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberDataComponent implements OnInit {
  @Input({ required: true }) form: UntypedFormGroup;
  @Input({ required: true }) formLabels: {
    countryCode?: string;
    secondFactorPhoneNumber?: string;
  };

  @ViewChildren(AokInputFieldComponent) public inputFields: QueryList<AokInputFieldComponent>;

  protected readonly SecondFactorAuthFields = SecondFactorAuthFields;
  protected countryCodes: DropdownSchema[];

  constructor(protected contextState: ContextState) {}

  public ngOnInit(): void {
    this.setCountryCodes();
  }

  private setCountryCodes(): void {
    if (this.form.get(SecondFactorAuthFields.COUNTRY_CODE)) {
      this.countryCodes = getCountryCodes();
    }
  }
}
