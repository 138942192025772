import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AokAlertComponent } from './alert.component';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';

@NgModule({
  declarations: [AokAlertComponent],
  exports: [AokAlertComponent],
  imports: [CommonModule, A11yUtilsModule, AokSvgIconComponent],
})
export class AokAlertModule {}
