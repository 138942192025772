import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AokSvgIconComponent, KvnError } from '@aok/common';
import { AokFooterModule, ContactService, FeedbackButtonModule, HeaderModule, KvnState } from '@aok/components';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'aok-cockpit-kvn-error',
  templateUrl: './kvn-error.component.html',
  styleUrls: ['./kvn-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderModule, AokFooterModule, FeedbackButtonModule, CommonModule, AokSvgIconComponent],
})
export class KvnErrorComponent {
  protected kvnExistingErrors = KvnError;
  protected kvnError: KvnError;
  protected supportEmail: string;
  protected supportTel: string;

  constructor(private kvnState: KvnState, private contactService: ContactService) {
    this.kvnError = this.kvnState.getKvnError();
    this.supportEmail = this.contactService.getSupportEmail();
    this.supportTel = this.contactService.getSupportTel();
  }

  protected openExtendedTI(): void {
    window.location.href = environment.svsTIUrl;
  }
}
