import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokFeedbackButtonComponent } from './feedback-button.component';

@NgModule({
  declarations: [AokFeedbackButtonComponent],
  exports: [AokFeedbackButtonComponent],
  imports: [CommonModule, AokSvgIconComponent, A11yUtilsModule],
})
export class FeedbackButtonModule {}
