import {
  AokBenefit,
  AokBenefitCategory,
  AokBenefitRegion,
  AokPage,
  ArrayBehaviorState,
  BehaviorState,
} from '@aok/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BenefitsState {
  private favoriteBenefits = new BehaviorState<AokPage<AokBenefit>>();
  private regions = new ArrayBehaviorState<AokBenefitRegion>();
  private categories = new ArrayBehaviorState<AokBenefitCategory>();

  public getFavoriteBenefits(): AokPage<AokBenefit> {
    return this.favoriteBenefits.snapshot;
  }
  public getFavoriteBenefits$(): Observable<AokPage<AokBenefit>> {
    return this.favoriteBenefits.asObservable();
  }

  public getCategories(): AokBenefitCategory[] {
    return this.categories.snapshot;
  }

  public getRegions(): AokBenefitRegion[] {
    return this.regions.snapshot;
  }

  public setFavoriteBenefits(favorites: AokPage<AokBenefit>): void {
    this.favoriteBenefits.resetObjState(favorites);
  }

  public setCategories(categories: AokBenefitCategory[]): void {
    this.categories.reset(...categories);
  }

  public setRegions(regions: AokBenefitRegion[]): void {
    this.regions.reset(...regions);
  }
}
