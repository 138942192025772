import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AokOrg, AokOrgClient } from '@aok/common';
import { ProfileService } from '@aok/components';
import { map, tap } from 'rxjs/operators';

export const ownPracticesResolver: ResolveFn<AokOrg[]> = () => {
  const client = inject(AokOrgClient);
  const profileService = inject(ProfileService);

  return client.listMine({ size: 1000 }).pipe(
    map((page) => page?._embedded?.items),
    tap((orgs) => profileService.setOrganisations(orgs))
  );
};
