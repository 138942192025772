import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AokConsentClient, DialogBase, DialogRef, FORM_ERRORS } from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { ConsentState } from '../../../states';
import { FormErrorsBannerUtil } from '../../../utils';

/* eslint-disable @angular-eslint/template/label-has-associated-control */
@Component({
  selector: 'aok-usage-consent-dialog',
  styleUrls: ['consent-dialog.component.scss'],
  templateUrl: 'consent-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: FORM_ERRORS,
      useValue: {
        required: 'Bitte aktivieren Sie dieses Pflichtfeld.',
      },
    },
  ],
})
export class AokConsentDialog extends FormErrorsBannerUtil implements DialogBase<boolean> {
  readonly formGroup = new UntypedFormGroup({
    terms: new UntypedFormControl(false, [Validators.requiredTrue]),
    privacy: new UntypedFormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    public readonly dialogRef: DialogRef<boolean>,
    readonly keycloak: KeycloakService,
    protected client: AokConsentClient,
    private consentState: ConsentState
  ) {
    super();
  }

  public commit(): void {
    if (this.formGroup.invalid) {
      this.handleErrors({ form: this.formGroup });
      return;
    }

    this.client.commit().subscribe((consent) => {
      this.dialogRef.dispose(true);
      this.consentState.updateConsent(consent);
    });
  }

  public getFormControl(key: string): UntypedFormControl {
    return this.formGroup.get(key) as UntypedFormControl;
  }

  public followLink(event: KeyboardEvent, url: string): void {
    event.stopPropagation();
    window.open(url, '_blank');
  }
}
