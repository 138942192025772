<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>
<aok-cockpit-header-page-headline [headline]="'Handouts und Ausfüllhilfen'"> </aok-cockpit-header-page-headline>

<div class="aok-container">
  <div class="tw-mt-3 tw-col-3-11">
    <h4 class="tw-mb-6">Informationen für Praxen und Patienten</h4>
    <p class="tw-mb-7">Hier finden Sie kompakt aufbereitete Informationen zu verschiedenen Themen.</p>
    <p class="tw-mb-7">Die Dokumente können Sie als PDF zur weiteren Verwendung herunterladen.</p>
    <p class="tw-mb-7">
      Die Handouts im Bereich „Informationen für Patienten“ können gern an Patienten weitergegeben werden.
    </p>

    <div class="flex-end tw-mr-3">
      <button class="tw-mt-3" id="viewHandouts" (click)="navigateToHandoutsAokPage()">
        Handouts anzeigen
        <aok-svg-icon name="arrow-rounded_rightup" size="16px"></aok-svg-icon>
      </button>
    </div>
  </div>

  <div class="tw-mt-3 tw-mb-3 tw-col-3-11">
    <h4 class="tw-mb-6">Verordnung häuslicher Krankenpflege</h4>
    <p class="tw-mb-7">
      Voraussetzung für die Verordnung ist, dass sich der Hausarzt vom Zustand des Patienten und der Notwendigkeit
      häuslicher Krankenpflege persönlich überzeugt hat oder ihm Beides aus der laufenden Behandlung bekannt ist.
    </p>
    <p class="tw-mb-7">
      Wir bieten Ihnen eine interaktive Ausfüllhilfe an, die Sie dabei unterstützt, das Formular schnell und korrekt
      auszufüllen.
    </p>
    <p class="tw-mb-7">
      Es werden Ausfüllnotwendigkeiten dargestellt, welche die Nachfragen seitens der Krankenkasse reduzieren.
    </p>

    <div class="flex-end tw-mr-3">
      <button
        class="tw-mt-3"
        id="completionSupport"
        (keydown.enter)="$event.preventDefault()"
        (keyup.enter)="openCompletionSupportDialog()"
        (click)="openCompletionSupportDialog()"
      >
        Ausfüllhilfe anzeigen
        <aok-svg-icon name="arrow-rounded_right" size="16px"></aok-svg-icon>
      </button>
    </div>
  </div>
</div>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
