import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AokDropdownComponent } from './dropdown.component';
import { AokRequiredErrorMessageModule } from '../form-error-message/required-error-message/required-error-message.module';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { FormControlErrorModule } from '../form-control-error/module';

@NgModule({
  declarations: [AokDropdownComponent],
  exports: [AokDropdownComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormControlErrorModule,
    AokRequiredErrorMessageModule,
    AokSvgIconComponent,
    A11yUtilsModule,
  ],
})
export class AokDropdownModule {}
