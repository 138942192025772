<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>
<aok-cockpit-header-page-headline [headline]="'Versichertenmerkmal'" [hint]="headerHint">
</aok-cockpit-header-page-headline>

<ng-container *ngTemplateOutlet="response ? resultTemplate : requestTemplate"></ng-container>

<ng-template #resultTemplate>
  <div [attr.id]="resultTableData?.length ? 'patientFound' : undefined" class="aok-container">
    <aok-cockpit-table-type
      *ngFor="let value of resultTableData; trackBy: resultTableTrackByFn"
      class="tw-col-2-12 lg:tw-col-3-11 tw-mt-2"
      [sectionLabel]="value.sectionLabel"
      [values]="value.table"
    >
    </aok-cockpit-table-type>
  </div>
  <div class="aok-container">
    <div class="tw-col-2-11 lg:tw-col-3-11 tw-mt-3">
      <button (click)="reload()" id="newQueryButton">Neue Abfrage</button>
    </div>
  </div>
</ng-template>

<ng-template #requestTemplate>
  <div *ngIf="banner" class="aok-container">
    <aok-notification-banner
      [attr.id]="banner.id ? banner.id : undefined"
      class="tw-col-2-12 lg:tw-col-3-11 tw-mt-3"
      [accent]="banner.bannerAccent || 'bad'"
      [bannerHeadline]="banner.bannerHeadline"
      [bannerLabel]="banner.bannerLabel"
      [bannerLinks]="banner.bannerLinks"
    >
    </aok-notification-banner>
  </div>

  <div class="aok-container" aokFocusFirstChild>
    <div class="required-label tw-col-2-12 lg:tw-col-3-11 tw-mt-3">
      <aok-required-mark></aok-required-mark>
      Pflichtfeld
    </div>

    <h5 class="tw-col-2-12 lg:tw-col-3-11 tw-mt-9">Abfrageoptionen festlegen</h5>
    <p
      class="tw-col-2-12 lg:tw-col-3-11 tw-mt-10"
      [innerHtml]="'Bitte beachten Sie, dass die Abfrage nur tagesaktuell gültig ist.'"
    ></p>

    <div class="tw-col-2-7 lg:tw-col-3-7 tw-mt-7">
      <aok-input-field>
        <aok-checkbox [formControl]="masterCheckbox">
          <p class="aok-bold">Alle wählen</p>
        </aok-checkbox>
      </aok-input-field>
    </div>

    <ng-container [formGroup]="serviceOptionsForm">
      <div class="tw-col-2-7 lg:tw-col-3-7 tw-mt-8 tw-flex tw-items-center">
        <aok-input-field>
          <aok-checkbox [class.disabled]="!isDmpAllowed" [formControlName]="'dmpStatus'">
            {{ AokServiceCategory.DmpStatus }}
          </aok-checkbox>
        </aok-input-field>
        <aok-popover [radius]="20" [width]="350" class="tw-ml-8">
          <aok-popover-content-template
            [label]="dmpStatusPopoverHeader"
            [list]="dmpPopoverList"
          ></aok-popover-content-template>
        </aok-popover>
      </div>

      <div class="tw-col-7-12 lg:tw-col-7-11 tw-mt-8">
        <aok-input-field>
          <aok-checkbox [formControlName]="'paymentExemption'">
            {{ AokServiceCategory.PaymentExemption }}
          </aok-checkbox>
        </aok-input-field>
      </div>

      <div class="tw-col-2-7 lg:tw-col-3-7 tw-mt-8">
        <aok-input-field>
          <aok-checkbox [formControlName]="'careLevel'">
            {{ AokServiceCategory.CareLevel }}
          </aok-checkbox>
        </aok-input-field>
      </div>

      <div class="tw-col-7-12 lg:tw-col-7-11 tw-mt-8 tw-flex tw-items-center">
        <aok-input-field>
          <aok-checkbox [class.disabled]="true" [formControlName]="'membershipStatus'">
            {{ AokServiceCategory.MembershipStatus }}
          </aok-checkbox>
        </aok-input-field>
        <aok-popover [radius]="20" [width]="350" class="tw-ml-8">
          <aok-popover-content-template [label]="membershipStatusPopover"></aok-popover-content-template>
        </aok-popover>
      </div>
    </ng-container>

    <p class="tw-col-2-12 lg:tw-col-3-11 tw-mt-3">Patient mit folgenden Daten ermitteln:</p>
  </div>

  <aok-cockpit-service-request-form
    [showDetailedAddressForm]="showDetailedAddressForm"
    [patientSearchType]="patientSearchType"
    [showCardReaderOption]="true"
    (patientData)="handlePatientFormData($event)"
    (patientFormErrors)="handlePatientFormError($event)"
    (cardReaderError)="handleCardReaderError($event)"
    (radioOptionChanged)="handleRadioOptionChanged($event)"
  ></aok-cockpit-service-request-form>
</ng-template>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
