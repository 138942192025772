import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'typeof' })
export class AokTypeofPipe implements PipeTransform {
  transform(value: any): any {
    if (value && value instanceof Array) {
      return 'Array';
    }
    return typeof value;
  }
}
