import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, skip, tap } from 'rxjs/operators';
import { AokReminderClient } from '../clients';
import { AokReminder } from '../schemas';
import { AokReminderState, ContextState } from '../states';

@Injectable({
  providedIn: 'root',
})
export class AokReminderService {
  constructor(private client: AokReminderClient, private state: AokReminderState, private contextState: ContextState) {}

  /**
   * retrieve the list of reminders for the current context
   */
  public retrieveDashboardRemindersOnceForContext(): Observable<AokReminder[]> {
    const org$ = this.contextState.getFirstOrg$();
    const practitioner$ = this.contextState.getFirstPractitioner$();

    return forkJoin([org$, practitioner$]).pipe(
      mergeMap(([org, user]) => {
        if (org && user) {
          return this.client.listDashboardReminders({
            bsnr: org?.bsnr,
            lanr: user?.practitionerResource?.lanr,
          });
        }
        return of(null);
      }),
      map((response) => {
        if (response) {
          return response.content;
        }
        return [];
      }),
      catchError(() => of([]))
    );
  }

  /**
   * retrieve the list of reminders continuously as the context changes
   * the current context is ignored and reminders are only retrieved when the context is changed
   */
  public retrieveDashboardRemindersOnContextChange(): Observable<AokReminder[]> {
    const org$ = this.contextState.getOrg$();
    const practitioner$ = this.contextState.getPractitioner$();
    const state = this.state.asObservable();

    return combineLatest([org$, practitioner$, state]).pipe(
      skip(1),
      mergeMap(([org, practitioner]) => {
        if (org && practitioner) {
          return this.client.listDashboardReminders({
            bsnr: org?.bsnr,
            lanr: practitioner?.practitionerResource.lanr,
          });
        }
        return of(null);
      }),
      map((response) => {
        if (response) {
          return response.content;
        }
        return [];
      })
    );
  }

  public retrieveReminders(): Observable<AokReminder[]> {
    return this.client.list().pipe(
      map((result) => result.content),
      catchError(() => of([])),
      tap((reminders) => this.state.setReminders(reminders))
    );
  }
}
