import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FORM_ERRORS, PopoverDirection } from '@aok/common';
import { v4 as uuid } from 'uuid';

export interface RadioOptions {
  label: string;
  value: string;
  popover?: string;
  id?: string;
}

@Component({
  selector: 'aok-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AokRadioComponent,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AokRadioComponent),
      multi: true,
    },
  ],
})
export class AokRadioComponent implements ControlValueAccessor, Validator {
  @Input() options: RadioOptions[];
  @Input() name: string = uuid();
  @Output() checked: EventEmitter<string> = new EventEmitter<string>();

  public selected: string;

  public readonly POPOVER_DIRECTION = PopoverDirection;

  get _isDisabled(): boolean {
    return this._disabled;
  }

  @Input()
  @HostBinding('class.disabled')
  set _isDisabled(value: boolean) {
    this._disabled = value;
  }

  private _isTouched = false;
  private _disabled = false; // Specifies if the radio disabled

  constructor(private sanitizer: DomSanitizer, @Optional() @Inject(FORM_ERRORS) readonly message?: string) {}

  public writeValue(selected: string): void {
    this.markAsTouched();
    if (!this._disabled) {
      this.selected = selected;
      this.checked.emit(selected);
      this.onChange(selected);
    }
  }

  public markAsTouched(): void {
    if (!this._isTouched) {
      this.onTouched();
      this._isTouched = true;
    }
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  public sanitize(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (_): void => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = (): void => {};
}
