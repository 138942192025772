import { DatePipe } from '@angular/common';
import {
  AokFullAddressPipe,
  AokFullNamePipe,
  AokInsuranceStatusPipe,
  AokInsuree,
  AokServiceCategory,
  TableTypeRow,
} from '@aok/common';

export function isCareLevel(serviceCategory: AokServiceCategory): boolean {
  return serviceCategory === AokServiceCategory.CareLevel;
}

export function isBatchPrinting(serviceCategory: AokServiceCategory): boolean {
  return serviceCategory === AokServiceCategory.BatchPrinting;
}

export function isDMP(serviceCategory: AokServiceCategory): boolean {
  return serviceCategory === AokServiceCategory.DmpStatus;
}

export function isBillingSheet(serviceCategory: AokServiceCategory): boolean {
  return serviceCategory === AokServiceCategory.BillingSheet;
}

export enum ServicePagesLabels {
  BILLING_SHEET_SECTION = 'Abrechnungsschein',
  PATIENT_DATA_SECTION = 'Persönliche Daten',
  INSURANCE_DATA_SECTION = 'Versicherungsdaten',
  REQUEST_OPTIONS_STATUS = 'Status der Abfrageoptionen',
}

/**
 * Returns the personal entries for the service result page table
 * @param insuree
 * @private
 */
export function getPersonalResultTable(insuree: AokInsuree): TableTypeRow[] {
  const datePipe = new DatePipe('de');
  const fullNamePipe = new AokFullNamePipe();
  const addressPipe = new AokFullAddressPipe();

  const baseTable = [
    {
      label: 'Patient',
      value: fullNamePipe.transform(insuree),
      id: 'patientName',
    },
    {
      label: 'Geburtsdatum',
      value: datePipe.transform(insuree.dateOfBirth, 'dd.MM.yyyy'),
      id: 'birthDate',
    },
  ];

  const { street, streetNumber, zip, city } = insuree;
  if (street || streetNumber || zip || city) {
    const address = {
      label: 'Adresse',
      value: addressPipe.transform(insuree),
      id: 'patientAddress',
    };
    return [...baseTable, address];
  } else {
    return baseTable;
  }
}

export function getInsuranceDataTable(insuree: AokInsuree, displayInsuranceType = true): TableTypeRow[] {
  const { institutionName, insuranceNumber, insuranceStatus } = insuree;
  const memberStatus = new AokInsuranceStatusPipe();

  const baseTable = [
    {
      label: 'Versicherung',
      value: institutionName,
      id: 'institutionName',
    },
    {
      label: 'Versichertennummer',
      value: insuranceNumber,
      id: 'insuranceNumber',
    },
  ];

  if (displayInsuranceType) {
    baseTable.push({
      label: 'Versicherungsart',
      value: memberStatus.transform(insuranceStatus),
      id: 'insuranceType',
    });
  }

  return baseTable;
}
