<div
  class="visible-controller"
  [ngClass]="{ hover: hover, popoverOpen: visiblePopover }"
  aokFocusable
  #visibleController
  (blur)="closeFocus()"
>
  <div class="center-popover" [ngStyle]="{ top: centerPositionTop, left: centerPositionLeft }">
    <div
      class="popover-container"
      [ngStyle]="{
        width: _width,
        left: xAxesPopover,
        top: yAxesPopover,
        transform: yReverse
      }"
    >
      <div
        name="pointer"
        [ngClass]="{
          'arrow-left': popoverSide === 'right',
          'arrow-right': popoverSide === 'left',
          'arrow-top': popoverSide === 'bottom',
          'arrow-bottom': popoverSide === 'top'
        }"
        [ngStyle]="{
          top: yTopPointer,
          bottom: yBottomPointer,
          left: xRightPointer,
          right: xLeftPointer
        }"
      ></div>
      <div>
        <ng-content select="aok-popover-content-template" #popoverTemplate></ng-content>
        <ng-container *ngIf="!popoverContentExist">
          <ng-content></ng-content>
        </ng-container>
      </div>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>

  <div (click)="togglePopover()">
    <aok-svg-icon *ngIf="anchor" aok-anchor [name]="iconName" [size]="iconSize"></aok-svg-icon>
    <ng-container *ngIf="!anchor">
      <ng-content select="[aok-anchor]"></ng-content>
    </ng-container>
  </div>
</div>
