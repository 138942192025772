<dialog-layout [fullscreen]="false" [headerText]="headline" [main]="false" cdkTrapFocus cdkTrapFocusAutoCapture="true">
  <div class="shared-dialog-container">
    <p *ngIf="description" [innerHTML]="description"></p>

    <aok-notification-banner
      class="tw-mt-5 tw-block"
      [bannerHeadline]="'Freigabefunktion nicht verfügbar'"
      [bannerLabel]="hint"
    ></aok-notification-banner>
  </div>

  <dialog-footer>
    <button (click)="cancel()">Schließen</button>
  </dialog-footer>
</dialog-layout>
