<div class="aok-ti-error aok-container">
  <div class="aok-branding" title="AOK Arztportal - Übersicht">
    <img [src]="publicUrl + '/assets/img/aok-logo-small.svg'" alt="AOK Logo" />
    <div class="aok-header-slogan">
      <h6>AOK-Arztportal</h6>
      <span class="aok-text-l3">Vernetzte Versorgung für Niedersachsen</span>
    </div>
  </div>

  <div class="content tw-flex tw-flex-col tw-items-center tw-justify-center">
    <h3>Telematikinfrastruktur nicht mehr verfügbar</h3>
    <p>
      Sie können das Arztportal weiterhin verwenden, momentan aber nicht auf die Bereiche zugreifen, welche die
      Telematikinfrastruktur benötigen.
    </p>

    <div class="icon-wrapper tw-flex tw-items-center tw-justify-center">
      <aok-svg-icon name="0132-antenna" size="120px"></aok-svg-icon>
      <div class="crossing-line"></div>
    </div>

    <div>
      <p>Bitte versuchen Sie später erneut, die Verbindung über die Telematikinfrastruktur aufzubauen.</p>
      <p class="tw-mt-7">Nutzen Sie die erweiterte TI-Prüfung, um Hinweise zur Einrichtung zu erhalten.</p>
    </div>

    <div class="tw-flex tw-gap-6">
      <button (click)="redirectToPublicNetwork()">Zurück zum Arztportal</button>
      <button class="secondary" (click)="openExtendedTI()">
        Erweiterte TI-Prüfung durchführen
        <aok-svg-icon class="tw-ml-10" name="arrow-rounded_rightup" size="16px"></aok-svg-icon>
      </button>
    </div>
  </div>
</div>
