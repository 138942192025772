import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AokFullNamePipeModule, AokUser, AokUserClient, DialogBase, DialogOverlayModule, DialogRef } from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AokInputFieldModule } from '../../cdk';
import { AokCheckboxModule } from '../../checkbox/checkbox.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';

@Component({
  selector: 'aok-cockpit-share-dialog',
  styleUrls: ['./share-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'share-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DialogOverlayModule,
    AokDialogLayoutModule,
    AokCheckboxModule,
    AokInputFieldModule,
    ReactiveFormsModule,
    AokFullNamePipeModule,
    A11yModule,
  ],
})
export class AokShareDialog implements OnInit, OnDestroy, DialogBase<number[]> {
  @Input() public headline = 'Freigabe verwalten';
  @Input() public description: string;
  @Input() public docs: AokUser[] = [];
  @Input() public mfas: AokUser[] = [];
  @Input() userIdsWithAccess: Array<number> = [];
  public form = new FormGroup({
    docs: new FormArray<FormControl<boolean>>([]),
    mfas: new FormArray<FormControl<boolean>>([]),
  });

  public masterForm: FormGroup;

  private onDestroy = new Subject<void>();

  constructor(
    public readonly dialogRef: DialogRef<number[]>,
    protected userClient: AokUserClient,
    protected keycloak: KeycloakService
  ) {}

  public ngOnInit(): void {
    this.createControls();

    this.handleValueChanges('docs');
    this.handleValueChanges('mfas');
  }

  ngOnDestroy(): void {
    this.onDestroy.unsubscribe();
  }

  public getArray(key: 'docs' | 'mfas'): FormArray<FormControl<boolean>> {
    return this.form.get(key) as FormArray<FormControl<boolean>>;
  }

  public cancel(): void {
    this.dialogRef.dispose();
  }

  public submit(): void {
    const selected = this.getArray('docs')
      .controls.map((control, index) => {
        if (!control.disabled && control.value) return this.docs[index];
        return null;
      })
      .concat(
        this.getArray('mfas').controls.map((control, index) => {
          if (!control.disabled && control.value) return this.mfas[index];
          return null;
        })
      )
      .filter((user) => !!user)
      .map((user) => user.id);
    this.dialogRef.dispose(selected);
  }

  private createControls(): void {
    this.docs.forEach((user) => {
      this.createFormControl('docs', this.userIdsWithAccess.includes(user.id));
    });
    this.mfas.forEach((user) => {
      this.createFormControl('mfas', this.userIdsWithAccess.includes(user.id));
    });

    const allDocs = this.getArray('docs').value.every((value) => value);
    const allMfas = this.getArray('mfas').value.every((value) => value);

    this.masterForm = new FormGroup({
      docs: new FormControl<boolean>(allDocs),
      mfas: new FormControl<boolean>(allMfas),
    });
  }

  private handleValueChanges(controlName: 'docs' | 'mfas'): void {
    this.getArray(controlName)
      .valueChanges.pipe(takeUntil(this.onDestroy))
      .subscribe((values: boolean[]) => {
        this.masterForm.get(controlName).setValue(
          values.every((value) => value),
          { emitEvent: false }
        );
      });

    this.masterForm
      .get(controlName)
      .valueChanges.pipe(takeUntil(this.onDestroy))
      .subscribe((value: boolean) => {
        this.getArray(controlName).controls.forEach((control) => {
          if (!control.disabled && control.value !== value) control.setValue(value, { emitEvent: false });
        });
      });
  }

  private createFormControl(controlName: 'docs' | 'mfas', isSelected: boolean): void {
    this.getArray(controlName).push(new FormControl<boolean>(isSelected));
  }
}
