import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  A11yUtilsModule,
  AokOrgTypeIntlPipe,
  AokPopoverComponent,
  AokPopoverContentTemplateComponent,
  DropdownSchema,
  KnownOrgType,
  PopoverDirection,
  TrimControlDirective,
} from '@aok/common';
import { FormErrorsBannerUtil } from '../../utils';
import { AokInputFieldModule, RequiredMarkModule } from '../cdk';
import { AokDropdownModule } from '../dropdown/dropdown.module';
import { FormControlErrorModule } from '../form-control-error/module';
import { AokNotificationBannerModule } from '../notification-banner/notification-banner.module';

@Component({
  selector: 'aok-practice-form',
  templateUrl: './practice-form.component.html',
  styleUrls: ['./practice-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  exportAs: 'practiceForm',
  providers: [AokOrgTypeIntlPipe],
  imports: [
    AokDropdownModule,
    AokInputFieldModule,
    AokPopoverComponent,
    AokPopoverContentTemplateComponent,
    FormControlErrorModule,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    RequiredMarkModule,
    AokNotificationBannerModule,
    NgTemplateOutlet,
    A11yUtilsModule,
    TrimControlDirective,
  ],
})
export class PracticeFormComponent extends FormErrorsBannerUtil {
  @Input() public formGroup: FormGroup;

  public practiceTypeOption: DropdownSchema[] = this.getOrgTypeOptions();
  public popoverDirectionSide: PopoverDirection = PopoverDirection.RightCenter;
  public popoverRightDirectionWidth = 384;

  public formLabels = {
    bsnr: 'Betriebsstättennummer',
    organizationType: 'Art der Praxis',
    organizationName: 'Name der Praxis',
    street: 'Straße',
    streetNumber: 'Hausnummer',
    zipCode: 'PLZ',
    location: 'Ort',
  };

  constructor(private orgTypePipe: AokOrgTypeIntlPipe, private cd: ChangeDetectorRef) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (event.target.outerWidth < 1000) this.popoverRightDirectionWidth = 372;
    else this.popoverRightDirectionWidth = 384;
  }

  /**
   * trigger form validation
   * @return false - if form is invalid
   * @return true - if form is valid
   */
  public validateForm(): boolean {
    if (this.formGroup.invalid) {
      this.handleErrors({ form: this.formGroup });
      this.cd.markForCheck();
      return false;
    }

    this.errorIds = [];
    this.cd.markForCheck();
    return true;
  }

  private getOrgTypeOptions(): DropdownSchema[] {
    return Object.values(KnownOrgType)
      .map((type) => ({ label: this.orgTypePipe.transform(type), value: type }))
      .filter((option) => option?.value && option?.label);
  }
}
