import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokNotificationBellComponent } from './notification-bell.component';

@NgModule({
  declarations: [AokNotificationBellComponent],
  exports: [AokNotificationBellComponent],
  imports: [AokSvgIconComponent, CommonModule, A11yUtilsModule],
})
export class AokNotificationBellModule {}
