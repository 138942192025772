<div class="benefit-entry grid-12">
  <div class="tw-col-1-12 grid-11">
    <div class="tw-col-1-11 tw-mb-9 benefit-link">
      <a
        aokFocusable
        class="aok-link-1 tw-mt-8"
        [target]="'_self'"
        [routerLinkOrHref]="'/services/leistungssuche/' + benefitId"
      >
        <span>{{ benefitTitle }}</span>
        <aok-svg-icon class="right" name="arrow-rounded_right" size="16px"></aok-svg-icon>
      </a>
    </div>

    <div class="tw-col-1-12 grid-11 info-panel">
      <div
        aokFocusable
        (click)="toggleAccordion()"
        (keyup.enter)="toggleAccordion()"
        class="accordion-trigger tw-col-1-4"
      >
        <aok-svg-icon [name]="isOpen ? 'minus' : 'plus'" size="18px"></aok-svg-icon>
        <a>Indikation</a>
      </div>
      <div class="tw-col-5-8">
        <aok-svg-icon class="tw-text-grey-5" [name]="'0239-price_tag_2'" size="16px"></aok-svg-icon>
        {{ benefitCategory }}
      </div>
      <div class="tw-col-9-12">
        <aok-svg-icon class="tw-text-grey-5" [name]="'0317-location_5'" size="16px"></aok-svg-icon>
        {{ benefitRegion }}
      </div>
    </div>
  </div>
  <div class="tw-col-12-13 tw-self-center">
    <aok-svg-icon
      class="tw-text-green mark-favorite"
      [class.disabled]="!canMarkFavorite"
      [title]="favoriteIconTitle"
      [class.favorite]="benefitFavorite"
      [name]="benefitFavorite ? 'star-filled' : 'star'"
      size="20px"
      [aokFocusable]="canMarkFavorite"
      (click)="markFavorite(benefitId)"
      (keyup.enter)="markFavorite(benefitId)"
    ></aok-svg-icon>
  </div>
  <div [ngClass]="isOpen ? 'expanded' : 'collapsed'" class="tw-col-1-13 indications">
    <ul>
      <li *ngFor="let indication of benefitIndications">{{ indication }}</li>
    </ul>
  </div>
</div>
