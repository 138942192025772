import { AbstractControl, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { isString } from '../string.util';

const { pattern, maxLength } = Validators;

const patternZipCode = pattern(/^\d{5}$/);
const patternPhoneDevice = maxLength(20);
const patternPhoneNumber = pattern(/^[0-9() .-]*$/);
const patternEmail = pattern(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/);
const patternBsnr = pattern(/^\d{9}$/);
const patternHouseNumber = pattern(/^\d+(\d|[a-zA-Z]|\s|-|\/)*$/);
const patternName = pattern(
  /([0-9]|°|\^|!|"|§|\$|%|&|\/|\(|\)|=|\?|´|`|\\|}|]|\[|\{|³|²|\*|\+|~|#|\||<|>|µ|;|,|:|\.|_)/
);
/**
 * check if the date "dd.mm.yyyy"
 */
const patternDateFormat = pattern(
  /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g
);
const insurancePattern = pattern(/^([a-zA-Z]+(\d{9}))$/);
const patternLanr = pattern(/^\d{7}$/);
const patternLanr9 = pattern(/^\d{9}$/);

/**
 * At the moment not in used, because the validation for ZipCode changes to maxLength 10, with all numbers, chars and so on
 * @return { zipCode: false } | null
 */
export function zipCode(control: AbstractControl): ValidationErrors | null {
  return patternZipCode(control) && { zipCode: false };
}

/**
 * @return { phoneDevice: false } | null
 */
export function phoneDevice(control: AbstractControl): ValidationErrors | null {
  return patternPhoneDevice(control) && { phoneDevice: false };
}

/**
 * @return { phoneDevice: false } | null
 */
export function phoneNumber(control: AbstractControl): ValidationErrors | null {
  return patternPhoneNumber(control) && { phoneDevice: false };
}

/**
 * @return { email: false } | null
 */
export function email(control: AbstractControl): ValidationErrors | null {
  return patternEmail(control) && { email: false };
}

/**
 * @return { bsnr: false } | null
 */
export function bsnr(control: AbstractControl): ValidationErrors | null {
  return patternBsnr(control) && { bsnr: false };
}

/**
 * @return { houseNumber: false } | null
 */
export function houseNumber(control: AbstractControl): ValidationErrors | null {
  return patternHouseNumber(control) && { houseNumber: false };
}

/**
 * @return {[errorKeyWord]: false} | null
 */
export function name(errorKeyWord: 'firstname' | 'lastname') {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;
    return !patternName(control) && { [errorKeyWord]: false };
  };
}

/**
 * @return { dateFormat: false } | null
 */
export function dateFormatValidator(control: AbstractControl | UntypedFormControl): ValidationErrors {
  if (!control.value || (control.value && !patternDateFormat(control))) {
    return null;
  }
  return { dateFormat: false };
}

/**
 * @return { insuranceFormat: false } | null
 */
export function insuranceFormat(control: UntypedFormControl): ValidationErrors {
  return insurancePattern(control) && { insuranceFormat: false };
}

export function lanr(control: AbstractControl): ValidationErrors | null {
  return patternLanr(control) && { lanr: false };
}

export function lanr9(control: AbstractControl): ValidationErrors | null {
  if (!isString(control.value)) {
    return null;
  }
  return (patternLanr9(control) || lanr9Checksum(control)) && { lanr: false };
}

function lanr9Checksum(control: AbstractControl): boolean {
  const digits = Array.from(control.value.toString(), Number);
  let checksum =
    10 - ((digits[0] * 4 + digits[1] * 9 + digits[2] * 4 + digits[3] * 9 + digits[4] * 4 + digits[5] * 9) % 10);
  checksum = checksum === 10 ? 0 : checksum;
  return digits[6] !== checksum;
}
