import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'aok-tab',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div>
      <ng-content></ng-content>
    </div>
  `,
})
export class TabComponent {}
