import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { CONTROL_ERROR_MESSAGE } from '@aok/common';

@Component({
  selector: 'aok-form-error-message',
  encapsulation: ViewEncapsulation.None,
  template: ` <aok-error-message>{{ message }}</aok-error-message>`,
})
export class AokDefaultErrorMessageComponent {
  constructor(@Optional() @Inject(CONTROL_ERROR_MESSAGE) readonly message?: string) {}
}
