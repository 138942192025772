<span
  [ngClass]="triggerClass"
  aokFocusable
  *ngIf="aokm?.menu && entries?.length"
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="aokm.menu"
  (keyup.enter)="menuTrigger.openMenu()"
  (menuClosed)="updateView()"
  aria-label="menu trigger"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <aok-svg-icon *ngIf="!menuTrigger.menuOpen" name="arrow-down" size="16px"></aok-svg-icon>
  <aok-svg-icon *ngIf="menuTrigger.menuOpen" name="arrow-up" size="16px"></aok-svg-icon>
</span>
<span [ngClass]="triggerClass" aokFocusable *ngIf="!entries?.length" aria-label="menu trigger no content">
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <aok-svg-icon name="arrow-down" size="16px"></aok-svg-icon>
</span>
<aok-menu #aokm="aokMenu" [entries]="entries" (selected)="onSelect($event)"></aok-menu>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
