import { Injectable } from '@angular/core';
import { AokDmpCaseManagementClient, ContextState, DmpOverviewData } from '@aok/common';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, skip, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DmpCaseManagementOverviewService {
  constructor(private contextState: ContextState, private client: AokDmpCaseManagementClient) {}

  /**
   * retrieve the DMP overview page data once - for resolver
   */
  public getDmpPageOverviewDataOnceForContext(): Observable<DmpOverviewData> {
    return this.contextState.getPractitioner$().pipe(
      filter((practitioner) => !!practitioner),
      take(1),
      mergeMap((practitioner) => this.retrieveDmpOverviewData(practitioner?.practitionerResource?.lanr))
    );
  }

  /**
   * retrieve the DMP overview page data continuously as the context changes
   * the current context is ignored, the overview data is retrieved when the context is changed
   */
  public getDmpPageOverviewDataOnContextChange(): Observable<DmpOverviewData> {
    return this.contextState.getPractitioner$().pipe(
      filter((practitioner) => !!practitioner),
      skip(1),
      mergeMap((practitioner) => this.retrieveDmpOverviewData(practitioner?.practitionerResource?.lanr))
    );
  }

  public retrieveDmpOverviewData(lanr: string): Observable<DmpOverviewData> {
    const lastUpdatedDate = this.client.getLastUpdatedDate().pipe(catchError(() => of(null)));

    return forkJoin([this.client.getCounts(lanr), lastUpdatedDate]).pipe(
      map(([counts, lastUpdatedDate]) => {
        return {
          counts,
          lastUpdatedDate,
        } as DmpOverviewData;
      })
    );
  }
}
