import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { FeedbackRequestSchema } from '../schemas';
import { AokApiBaseClient } from './api-base.client';

export const AOK_FEEDBACK_API_URL = new InjectionToken<string>('AOK_FEEDBACK_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/email/feedback',
});

@Injectable({ providedIn: 'root' })
export class AokFeedbackClient extends AokApiBaseClient {
  constructor(@Inject(AOK_FEEDBACK_API_URL) readonly baseUrl: string, protected httpClient: HttpClient) {
    super(baseUrl, httpClient);
  }

  public submit(feedbackRequest: FeedbackRequestSchema): Observable<void> {
    return this.httpClient.post<void>(this.resolveUrl(), feedbackRequest);
  }
}
