export interface InfoBanner {
  status: InfoBannerStatus;
  startDate: number; // timestamp
  endDate: number; // timestamp
  content: string;
  // Field present only when object is received from BE
  active?: boolean; // whether the info banner is active to the cockpit users
}

export enum InfoBannerStatus {
  DISPLAYED = 'DISPLAYED',
  HIDDEN = 'HIDDEN',
}

export interface Time {
  hours: number;
  minutes: number;
}
