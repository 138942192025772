<dialog-layout
  fullscreen="false"
  isClosable="true"
  [headerText]="'Mobilfunknummer bearbeiten'"
  aokFocusFirstChild
  cdkTrapFocus
  class="aok-phone-number-dialog"
>
  <p>An diese Mobilfunknummer wird per SMS der Bestätigungscode der Zwei-Faktor-Authentifizierung gesendet.</p>
  <p>
    Überprüfen Sie Ihre Änderungen an der Mobilfunknummer sorgfältig, da Sie durch eine fehlerhafte Nummer nicht mehr
    auf das Arztportal zugreifen können.
  </p>

  <div class="required-label">
    <aok-required-mark></aok-required-mark>
    Pflichtfeld
  </div>

  <form [formGroup]="formGroup">
    <aok-phone-number-data
      class="tw-block tw-mt-7"
      [form]="formGroup"
      [formLabels]="formLabels"
    ></aok-phone-number-data>
  </form>

  <div class="button-container">
    <button (click)="submitDialog()">Änderungen speichern</button>
  </div>
</dialog-layout>
