import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AppLoadingStateEnum,
  AppState,
  ContextState,
  CurrentUserState,
  getParsableQueryParams,
  RequiredTask,
  UserService,
} from '@aok/common';
import { combineLatest, from, Observable, of } from 'rxjs';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { ContextService } from '../context.service';
import { GlobalQueryParamService } from '../global-query-param.service';

@Injectable()
export class ContextSelectorTaskService implements RequiredTask {
  constructor(
    private router: Router,
    private userService: UserService,
    private contextService: ContextService,
    private contextState: ContextState,
    private currentUser: CurrentUserState,
    private globalQueryParamService: GlobalQueryParamService,
    private appState: AppState
  ) {}

  public trigger(): Observable<boolean> {
    return this.shouldTakeAction().pipe(
      mergeMap((redirect) => {
        if (redirect) {
          this.redirect().subscribe();
          return this.contextState.contextSelected$().pipe(
            filter((contextSelected$) => contextSelected$),
            first()
          );
        } else {
          return of(true);
        }
      })
    );
  }

  private redirect(): Observable<boolean> {
    const queryParams = getParsableQueryParams();

    return from(
      this.router.navigate(['kontext'], {
        queryParams: {
          // default redirect after context selection if no other redirect is specified
          // we are using slice to remove / from location.pathname
          redirectTo: location.pathname.slice(1) || 'uebersicht',
          ...queryParams,
        },
      })
    ).pipe(
      tap((navigationResult) => {
        if (navigationResult) {
          this.appState.setLoadingState(AppLoadingStateEnum.LOADED);
        }
      })
    );
  }

  // TODO for future consider adding roles/route properties, to only run some strategies for some roles/routes accessed
  private shouldTakeAction(): Observable<boolean> {
    if (this.userService.isPendingUser()) {
      return of(false);
    }

    return this.currentUser.first$.pipe(
      mergeMap(() => {
        return this.contextService.retrieveContextOptions().pipe(
          mergeMap(() => {
            // getCurrentNavigation query params in case navigation is in progress
            // routerState query params in case navigation is completed
            const queryParams =
              this.router.getCurrentNavigation()?.initialUrl?.queryParams ||
              this.router.routerState.snapshot.root.queryParams;
            return from(this.globalQueryParamService.handleContextQueryParams(queryParams));
          }),
          map((handled) => {
            return handled || this.contextState.resetOrRestoreStoredContext(this.currentUser.snapshot);
          }),
          mergeMap((handled) => {
            return handled
              ? of(false)
              : combineLatest([this.contextState.getPractitioner$(), this.contextState.getOrg$()]).pipe(
                  // unsubscribe from continuous context change notifications after first one
                  first(),
                  mergeMap(([practitioner, org]) => {
                    const contextOptions = this.contextState.contextSelectorOptions;

                    if (!practitioner && !org) {
                      if (contextOptions.length === 1) {
                        this.contextService.setDefaultOption(contextOptions);
                        return of(false);
                      } else {
                        // need to select an option
                        return of(true);
                      }
                    } else {
                      // already has context selected
                      return of(false);
                    }
                  })
                );
          })
        );
      })
    );
  }
}
