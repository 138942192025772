import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule, AokFullAddressPipe, AokFullNamePipeModule, AokSvgIconComponent } from '@aok/common';
import { OrgContextSelectItemComponent } from './org-context-select-item/org-context-select-item.component';
import { AokOrgContextSelectComponent } from './org-context-select.component';

@NgModule({
  declarations: [AokOrgContextSelectComponent, OrgContextSelectItemComponent],
  exports: [AokOrgContextSelectComponent, OrgContextSelectItemComponent],
  imports: [
    CommonModule,
    AokFullNamePipeModule,
    OverlayModule,
    AokSvgIconComponent,
    A11yUtilsModule,
    A11yModule,
    AokFullAddressPipe,
  ],
})
export class AokOrgContextSelectModule {}
