import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, QueryList, ViewChildren } from '@angular/core';
import { A11yUtilsModule, AokSvgIconComponent, RouterLinkOrHrefModule } from '@aok/common';
import { WINDOW } from '@ng-web-apis/common';

export interface MenuLink {
  id?: string;
  href?: string;
  icon: string;
  label: string;
  uriScheme?: boolean;
  onClick?: () => unknown;
  internal?: boolean;
}

@Component({
  selector: 'aok-menu-link',
  templateUrl: './menu-link.component.html',
  styleUrls: ['./menu-link.component.scss'],
  standalone: true,
  imports: [CommonModule, AokSvgIconComponent, A11yUtilsModule, RouterLinkOrHrefModule],
})
export class AokMenuLinkComponent implements AfterViewInit {
  @Input()
  items: MenuLink[];

  @ViewChildren('link') menuLinks: QueryList<ElementRef>;

  constructor(@Inject(WINDOW) public window: Window) {}

  ngAfterViewInit(): void {
    this.menuLinks.first.nativeElement.focus();
  }
}
