import { Pipe, PipeTransform } from '@angular/core';
import { AokReportCategory } from '../../schemas';
import { getReportCategoryText } from '../../utils';

@Pipe({ name: 'aokReportCategory', standalone: true })
export class AokReportCategoryIntlPipe implements PipeTransform {
  transform(value: AokReportCategory): string {
    return getReportCategoryText(value);
  }
}
