import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, EnvironmentSchema, FeedbackCategory, SVSFeedbackCategory } from '../../schemas';
import { ArrayBehaviorState } from '../../utils/rx/behavior-state';

const CONTRACTS_FEEDBACK_CATEGORIES_MAPPING = {
  enableContractsOverview: [SVSFeedbackCategory.VERTRAGE],
  enableFamilyDoctorCenterCare: [
    SVSFeedbackCategory.HZV,
    SVSFeedbackCategory.MODULE,
    SVSFeedbackCategory.PATIENTENVERWALTUNG,
    SVSFeedbackCategory.QUARTALSABSCHLUSS,
  ],
};

const STATIC_FEEDBACK_CATEGORIES = [
  FeedbackCategory.ALLGEMEIN,
  FeedbackCategory.BERICHTE,
  FeedbackCategory.ABRECHNUNGSSCHEIN,
  FeedbackCategory.ARTIKEL,
  FeedbackCategory.HILFE,
  FeedbackCategory.PROFILVERWALTUNG,
  FeedbackCategory.PODCASTS,
  FeedbackCategory.FORM_COMPLETION_SUPPORT,
  FeedbackCategory.SAMMELDRUCK,
  FeedbackCategory.VERSICHERTENMERKMAL,
];

@Injectable()
export class AokFeedbackCategoriesState extends ArrayBehaviorState<string> {
  constructor(@Inject(ENVIRONMENT) readonly environment: EnvironmentSchema) {
    super();
  }

  public init(): void {
    const baseFeedbackCategories = STATIC_FEEDBACK_CATEGORIES;

    if (this.environment.enableDmpCaseManagement) {
      baseFeedbackCategories.push(FeedbackCategory.DMP_FALLUBERSICHT);
    }

    if (this.environment.enableBenefits) {
      baseFeedbackCategories.push(FeedbackCategory.LEISTUNGSSUCHE);
    }

    if (this.isSVSEnabled()) {
      const svsFeedbackCategories = Object.values(this.getSVSCategories());
      this.patch([...baseFeedbackCategories, ...svsFeedbackCategories]);
    } else {
      this.patch(baseFeedbackCategories);
    }
  }

  private isSVSEnabled(): boolean {
    return (
      this.environment.appHub &&
      (this.environment.enableContractsOverview || this.environment.enableFamilyDoctorCenterCare)
    );
  }

  private getSVSCategories(): SVSFeedbackCategory[] {
    return Object.entries(CONTRACTS_FEEDBACK_CATEGORIES_MAPPING)
      .filter(([subEntry]) => this.environment[subEntry as keyof EnvironmentSchema])
      .flatMap(([, subEntryCategories]) => {
        return subEntryCategories;
      });
  }
}
