import { Pipe, PipeTransform } from '@angular/core';

export function formatFileSize(bytes: number): string {
  if (bytes == null) return '';
  else if (bytes > 1_000_000_000) return `${(bytes / 1_000_000_000).toFixed(2)} GB`;
  else if (bytes > 1_000_000) return `${(bytes / 1_000_000).toFixed(2)} MB`;
  else if (bytes > 1_000) return `${(bytes / 1_000).toFixed(2)} KB`;
  else return `${bytes} Bytes`;
}

@Pipe({ name: 'fileSize', standalone: true })
export class FileSizePipe implements PipeTransform {
  transform(bytes: number): string {
    return formatFileSize(bytes);
  }
}
