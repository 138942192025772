import { Routes } from '@angular/router';
import { notKvnPM } from '../../guards/not-kvn-pm.guard';
import { practiceManagementGuard } from '../../guards/practice-management.guard';
import { allowDirectRegistrationResolver } from '../../resolvers/allow-direct-registration.resolver';
import { currentUserResolver } from '../../resolvers/current-user.resolver';
import { PracticeDataComponent } from './practice-data/practice-data.component';
import { PracticeManagementComponent } from './practice-management/practice-management.component';
import { ProfileDataComponent } from './profile-data/profile-data.component';
import { ProfileComponent } from './profile.component';

export const APP_PROFILE_ROUTES: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: 'praxisverwaltung',
        runGuardsAndResolvers: 'always',
        component: PracticeManagementComponent,
        canActivate: [practiceManagementGuard],
        data: {
          title: 'Praxisverwaltung',
          breadcrumb: false,
        },
      },
      {
        path: 'praxisdaten',
        runGuardsAndResolvers: 'always',
        component: PracticeDataComponent,
        data: {
          title: 'Praxisdaten',
          breadcrumb: false,
        },
      },
      {
        path: 'profildaten',
        runGuardsAndResolvers: 'always',
        component: ProfileDataComponent,
        canActivate: [notKvnPM],
        resolve: {
          user: currentUserResolver,
          allowDirectRegistration: allowDirectRegistrationResolver,
        },
        data: {
          title: 'Profildaten',
          breadcrumb: false,
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'praxisverwaltung',
      },
    ],
  },
];
