import { Pipe, PipeTransform } from '@angular/core';
import { KnownOrgType } from '../../schemas';
import { getOrgTypeText } from '../../utils';

@Pipe({ name: 'aokOrgType', standalone: true })
export class AokOrgTypeIntlPipe implements PipeTransform {
  constructor() {}

  transform(value: KnownOrgType | string): string {
    return getOrgTypeText(value);
  }
}
