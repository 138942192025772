<dialog-layout fullscreen="true" cdkTrapFocus aokFocusFirstChild isClosable="true" [main]="false">
  <div class="aok-container">
    <h3 class="tw-col-1-11">Nachricht zum DMP-Fall {{ dmpCase.firstName }} {{ dmpCase.lastName }}</h3>

    <ng-container *ngIf="errorIds.length">
      <aok-notification-banner
        class="tw-col-1-11 tw-mb-3"
        accent="bad"
        [bannerHeadline]="bannerError"
        [bannerLabel]="bannerLabel"
        [bannerLinks]="errorIds"
      >
      </aok-notification-banner>
    </ng-container>
  </div>

  <form class="aok-container" [formGroup]="feedbackForm">
    <div class="tw-mb-5 tw-col-1-11">
      <aok-required-mark></aok-required-mark>
      Pflichtfeld
    </div>

    <aok-input-field
      class="tw-mb-7 block tw-col-1-5"
      id="dmpCaseFeedbackCategory"
      [label]="label['dmpCaseFeedbackCategory']"
      [required]="true"
    >
      <aok-dropdown
        [control]="feedbackForm.get('dmpCaseFeedbackCategory')"
        [mode]="dropdownMode.GROUPING"
        [options]="dmpCaseFeedbackCategoryOptions"
      ></aok-dropdown>
    </aok-input-field>

    <aok-input-field
      class="block tw-mb-4 tw-col-1-11"
      id="message"
      [label]="label['message']"
      [required]="isMessageRequired"
    >
      <textarea formControlName="message" placeholder="Weitere Informationen" maxlength="500" aokTrim></textarea>
    </aok-input-field>

    <h5 class="tw-col-1-11 tw-mb-8">Gesprächspartner angeben</h5>

    <aok-input-field
      class="block tw-mb-3 tw-col-1-5"
      id="selectedUserId"
      [label]="label['selectedUserId']"
      [required]="true"
    >
      <aok-dropdown
        [control]="feedbackForm.get('selectedUserId')"
        [mode]="dropdownMode.ENHANCED"
        [options]="contactPersonOptions"
      ></aok-dropdown>
    </aok-input-field>

    <div class="tw-col-1-8 tw-mb-6">
      <h5>Kontaktkanal festlegen</h5>
      <p class="tw-mt-4">Wenn wir Sie kontaktieren sollen, geben Sie bitte den gewünschten Kontaktkanal an.</p>
    </div>

    <aok-input-field class="block tw-mb-7 tw-col-1-5" id="phoneCheckbox" [label]="label['phoneCheckbox']">
      <aok-checkbox formControlName="phoneCheckbox">
        <p>Telefon</p>
      </aok-checkbox>
    </aok-input-field>

    <aok-input-field class="block tw-col-1-5" id="phoneNumber">
      <input type="text" formControlName="phoneNumber" placeholder="Telefonnummer angeben" aokTrim />
    </aok-input-field>

    <aok-input-field class="tw-mt-7 tw-mb-8 block tw-col-1-5">
      <aok-checkbox formControlName="letterCheckbox">
        <p>Brief</p>
      </aok-checkbox>
    </aok-input-field>

    <ng-container *ngIf="contextOrganizations?.length === 1; else dropdownTemplate">
      <div class="tw-mt-7 tw-col-1-5" [ngClass]="{ 'text-muted': feedbackForm.get('selectedPracticeId').disabled }">
        <p class="tw-mb-8">An folgende Adresse senden</p>

        <p>
          BSNR | {{ contextState?.bsnr }} | Praxis
          {{
            contextState?.practitioner | aokFullName : 'TT LL, FF' : contextState?.practitioner?.practitionerResource
          }}
        </p>

        <p>{{ contextState?.org.address | aokFullAddress : 'STN SN, ZZ LL' }}</p>
      </div>
    </ng-container>

    <ng-template #dropdownTemplate>
      <aok-input-field class="block tw-mt-7 tw-mb-8 tw-col-1-11" [label]="label['selectedPracticeId']">
        <aok-dropdown [control]="feedbackForm.get('selectedPracticeId')" [options]="practiceOptions"></aok-dropdown>
      </aok-input-field>

      <p class="tw-col-1-11" *ngIf="feedbackForm.get('selectedPracticeId').value">
        {{ getSelectedOrgAddress() | aokFullAddress : 'STN SN, ZZ LL' }}
      </p>
    </ng-template>

    <div class="privacy-notice tw-mt-4 tw-col-1-11">
      <h6>Datenschutzhinweis</h6>
      <p class="aok-text-l2">
        Wir verarbeiten und nutzen Ihre Daten ausschließlich für die Bearbeitung und Beantwortung Ihrer Anfrage. Ihre
        Daten werden anschließend gelöscht. Allgemeine Informationen zur Datenverarbeitung und zu Ihren Rechten finden
        Sie unter
        <a class="aok-link-1" aokFocusable (keyup.enter)="openPrivacyPage()" (click)="openPrivacyPage()">Datenschutz</a>
      </p>
    </div>

    <div class="tw-col-1-11">
      <button class="tw-mt-4" (click)="submitDialog()">Absenden</button>
    </div>
  </form>
</dialog-layout>
