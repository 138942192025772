import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { A11yUtilsModule, AokSvgIconComponent, DialogOverlayModule } from '@aok/common';
import { AokHintModule, AokInputFieldModule } from '../../cdk';
import { FormControlErrorModule } from '../../form-control-error/module';
import { AokNotificationBannerModule } from '../../notification-banner/notification-banner.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { AokResendEmailDialog } from './resend-email-dialog.component';

@NgModule({
  declarations: [AokResendEmailDialog],
  imports: [
    CommonModule,
    DialogOverlayModule,
    AokHintModule,
    ReactiveFormsModule,
    FormControlErrorModule,
    AokInputFieldModule,
    A11yModule,
    A11yUtilsModule,
    AokSvgIconComponent,
    AokDialogLayoutModule,
    AokNotificationBannerModule,
  ],
})
export class AokResendEmailDialogModule {}
