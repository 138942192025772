import { AokReportCategory } from '@aok/common';

export const REPORTS_CATEGORY_ORDER = [
  AokReportCategory.ARZNEIMITTEL,
  AokReportCategory.HEILMITTEL,
  AokReportCategory.HZV,
];

export const REPORTS_CATEGORY_LABELS = {
  hzv: 'Hausarztzentrierte Versorgung',
  arzneimittel: 'Arzneimittel',
  heilmittel: 'Heilmittel',
};
