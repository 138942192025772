import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { focusElement, isString, NotificationBannerLink, scrollToElement, scrollToTop } from '@aok/common';

type HandleErrorsOptions = {
  form: UntypedFormGroup | UntypedFormArray;
  formControlName?: string;
  hasCustomHeadline?: boolean;
};

export class FormErrorsBannerUtil {
  public errorIds: NotificationBannerLink[] = [];
  public bannerLabel = 'Bitte überprüfen Sie Ihre Eingabe.';
  public bannerHeadline = null;
  public formLabels: Record<string, unknown>;

  public bannerSingleError = 'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingabe.';
  public bannerMultiError = 'Es sind mehrere Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingabe.';

  /**
   * @formControlName is the name of the form control for which we want to skip calling "updateValueAndValidity" without using "setTimeout."
   * @formControlName serves as an asynchronous validator in the MFA contact form.
   * you can read more info in else statement comment below
   */
  protected handleErrors(options: HandleErrorsOptions): void {
    scrollToTop();

    const controls = Object.entries(options?.form.controls).map(([key, formControl]) => {
      // fixme this is used for the email on the mfa contact form - to not trigger its validation
      if (key !== options?.formControlName) {
        formControl.updateValueAndValidity();
      } else {
        // In this "else" statement, there is an async validator.
        // When we click the submit button, an HTTP call is triggered.
        // The "updateValueAndValidity()" method does not wait for the call to finish and puts this form control in a pending status.
        // That's why we need to use a "setTimeout" to wait for the HTTP call to finish.
        setTimeout(() => formControl.updateValueAndValidity());
      }

      return key;
    });

    this.errorIds = controls
      .filter((key) => options?.form.get(key).invalid)
      .map((key) => {
        let label: string;
        if (isString(this.formLabels?.[key])) label = this.formLabels?.[key] as string;
        else label = (this.formLabels?.[key] as any)?.alternative as string;
        return {
          label,
          clickHandler: () => {
            scrollToElement(key);
            setTimeout(() => focusElement(key), 500);
          },
        } as NotificationBannerLink;
      }, this.formLabels);

    if (!options?.hasCustomHeadline) {
      this.bannerHeadline = this.errorIds.length > 1 ? this.bannerMultiError : this.bannerSingleError;
    }
  }
}
