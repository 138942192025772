import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DIALOG_PRESET, DialogOverlay, DialogPreset, DialogRef } from '@aok/common';
import { AokDialogFooterComponent } from './dialog-footer.component';
import { AokDialogHeaderComponent } from './dialog-header.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dialog-layout',
  styleUrls: ['./dialog-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dialog-layout.component.html',
})
export class DialogLayoutComponent implements AfterContentChecked, AfterViewInit, OnDestroy {
  @Input() headerText: string;
  @Input() isClosable = true;
  @Input() autoClose = true;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('main') useMainContainer = true;
  @Output() readonly dispose = new EventEmitter<unknown>();

  @ContentChild(AokDialogHeaderComponent, { static: true }) private _staticHeader!: AokDialogHeaderComponent;
  @ContentChild(AokDialogHeaderComponent, { static: false }) private _dynamicHeader!: AokDialogHeaderComponent;

  @ContentChild(AokDialogFooterComponent, { static: true }) private _staticFooter!: AokDialogFooterComponent;
  @ContentChild(AokDialogFooterComponent, { static: false }) private _dynamicFooter!: AokDialogFooterComponent;

  @ViewChild('closeTemplate') private _closeTemplateRef!: TemplateRef<unknown>;

  private _fullscreen = false;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected renderer: Renderer2,
    @Optional() protected readonly dialog: DialogOverlay,
    @Optional() protected readonly dialogRef: DialogRef<any>,
    @Optional() @Inject(DIALOG_PRESET) readonly preset: /* @dynamic */ DialogPreset
  ) {}

  get fullscreen(): boolean {
    return this._fullscreen;
  }

  @Input()
  @HostBinding('class.dialog-fullscreen')
  set fullscreen(value: boolean) {
    this._fullscreen = coerceBooleanProperty(value);
    this.updateFullscreenOverlayConfig();
  }

  /** Gets the {@link AokDialogHeaderComponent} component instance, preferring any dynamically added references */
  get header(): AokDialogHeaderComponent | null {
    return this._dynamicHeader || this._staticHeader;
  }

  /** Gets the {@link AokDialogFooterComponent} component instance, preferring any dynamically added references */
  get footer(): AokDialogFooterComponent | null {
    return this._dynamicFooter || this._staticFooter;
  }

  get closable(): boolean {
    if (this.dialogRef?.config?.closable) {
      return this.dialogRef.config.closable;
    }
    return this.isClosable;
  }

  // TODO check if content CHECKED is needed or Init + timeout would suffice
  ngAfterContentChecked(): void {
    if (this.header && this.header.closeTemplate.getValue() != this._closeTemplateRef)
      this.header.closeTemplate.next(this._closeTemplateRef);
  }

  ngAfterViewInit(): void {
    this.renderer.addClass(document.body.parentElement, 'no-scroll');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body.parentElement, 'no-scroll');
  }

  public close(result?: unknown): void {
    if (this.autoClose && this.dialogRef != null) {
      this.dialogRef.dispose(result);
    }
    this.dispose.emit(result);
  }

  protected updateFullscreenOverlayConfig(): void {
    this.dialogRef.overlayRef.updatePositionStrategy(
      this.fullscreen
        ? this.dialog.position().global().top().left()
        : this.dialog.position().global().centerVertically().centerHorizontally()
    );
    this.dialogRef.overlayRef.updateSize({
      height: this.fullscreen ? '100vh !important' : undefined,
      width: this.fullscreen ? '100vw !important' : undefined,
    });
  }
}
