import { FormControl, FormGroup, Validators } from '@angular/forms';
import { countryCodeDE } from '../../constants';
import { phoneNumber } from '../validators/pattern.validator';

export const secondFactorAuthFormLabels = {
  authOption: 'Authentifizierungsart',
  countryCode: 'Ländervorwahl',
  secondFactorPhoneNumber: 'Mobilfunknummer',
};

export const SecondFactorAuthFields = {
  AUTH_OPTION: 'authOption',
  BSNR: 'bsnr',
  COUNTRY_CODE: 'countryCode',
  SECOND_FACTOR_PHONE_NUMBER: 'secondFactorPhoneNumber',
};

export function getSmsDataControls(
  countryCode = countryCodeDE,
  secondFactorPhoneNumber?: string,
  enabled = true
): Record<string, FormControl> {
  return {
    [SecondFactorAuthFields.COUNTRY_CODE]: new FormControl<string>(
      {
        value: countryCode,
        disabled: !enabled,
      },
      [Validators.required]
    ),
    [SecondFactorAuthFields.SECOND_FACTOR_PHONE_NUMBER]: new FormControl<string>(
      {
        value: secondFactorPhoneNumber,
        disabled: !enabled,
      },
      [Validators.required, phoneNumber]
    ),
  };
}

export const toggleSmsDataControls = (formGroup: FormGroup, enable = true): void => {
  if (enable) {
    formGroup.get(SecondFactorAuthFields.COUNTRY_CODE).enable();
    formGroup.get(SecondFactorAuthFields.SECOND_FACTOR_PHONE_NUMBER).enable();
  } else {
    formGroup.get(SecondFactorAuthFields.COUNTRY_CODE).disable();
    formGroup.get(SecondFactorAuthFields.SECOND_FACTOR_PHONE_NUMBER).disable();
  }
};
