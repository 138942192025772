import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { APP_BOOTSTRAP_LISTENER, ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { A11yUtilsModule, AokSvgIconComponent, DialogOverlayModule } from '@aok/common';
import { AOK_COOKIE_CONSENT_DIALOG, AokCookieService } from '../../../services';
import { KvnState } from '../../../states';
import { AokAccordionOptionModule } from '../../accordion/accordion-option.module';
import { AokCheckboxModule } from '../../checkbox/checkbox.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { createCookieBootstrapListener } from './cookie-check.listener';
import { AokCookieDialog } from './cookie-dialog.component';

@NgModule({
  declarations: [AokCookieDialog],
  imports: [
    DialogOverlayModule,
    AokCheckboxModule,
    CommonModule,
    AokAccordionOptionModule,
    A11yUtilsModule,
    RouterModule,
    ReactiveFormsModule,
    AokSvgIconComponent,
    AokDialogLayoutModule,
    A11yModule,
  ],
})
export class AokCookieDialogModule {
  static forRoot(): ModuleWithProviders<AokCookieDialogModule> {
    return {
      ngModule: AokCookieDialogModule,
      providers: [
        AokCookieService,
        {
          provide: AOK_COOKIE_CONSENT_DIALOG,
          useValue: AokCookieDialog,
        },
        {
          provide: APP_BOOTSTRAP_LISTENER,
          useFactory: createCookieBootstrapListener,
          deps: [AokCookieService, KvnState],
          multi: true,
        },
      ],
    };
  }
}
