<aok-notification-banner
  id="toastr"
  [bannerHeadline]="title"
  [bannerLabel]="message"
  [bannerLinks]="links"
  [accent]="accent"
  [closable]="true"
  (closed)="onClose()"
>
</aok-notification-banner>
