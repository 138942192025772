<dialog-layout fullscreen="false" isClosable="true" [headerText]="title" aokFocusFirstChild cdkTrapFocus>
  <p>{{ submitLabel1 }}</p>
  <p>{{ submitLabel2 }}</p>

  <div class="required-label">
    <aok-required-mark></aok-required-mark>
    {{ msgRequiredHint }}
  </div>

  <form [formGroup]="formGroup">
    <aok-input-field id="bsnr-field" [label]="labelBSNRField" [required]="true">
      <input formControlName="bsnrField" />
    </aok-input-field>

    <aok-input-field id="lanr-field" [label]="labelLANRField" [required]="true">
      <input formControlName="lanrField" />
    </aok-input-field>

    <aok-notification-banner
      *ngIf="specialization"
      class="specialization"
      bannerHeadline="Fachgruppe"
      iconName="info-circle"
      [bannerLabel]="specialization"
    >
    </aok-notification-banner>
  </form>

  <div class="button-container">
    <button class="secondary" (click)="cancelDialog()">{{ labelCancelField }}</button>
    <button (click)="submitDialog()">{{ labelSubmitField }}</button>
  </div>
</dialog-layout>
