import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { KvnState } from '@aok/components';
import { Observable, first } from 'rxjs';
import { map } from 'rxjs/operators';

export const kvnAllowedGuard: CanMatchFn = (): Observable<boolean> => {
  return inject(KvnState)
    .getKvnInfo$()
    .pipe(
      first(),
      map((kvnInfo) => !kvnInfo.isKvnWithoutAccess)
    );
};
