import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AokNotificationCountResponse,
  AokNotificationService,
  AokNotificationState,
  AokUser,
  CurrentUserState,
  isAssistant,
  KnownUserType,
  NavigationEntry,
} from '@aok/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeaderLayoutState, KvnState } from '../../states';
import { MenuLink } from '../menu-link/menu-link.component';

@Injectable({
  providedIn: 'root',
})
export class AokHeaderService {
  private hasAnyRole = false;
  private hiddenContext = false;

  constructor(
    private notificationService: AokNotificationService,
    private currentUser: CurrentUserState,
    private notificationState: AokNotificationState,
    private headerLayoutState: HeaderLayoutState,
    private router: Router,
    private kvnState: KvnState
  ) {}

  public getNavigationEntries(): Observable<NavigationEntry[]> {
    return this.headerLayoutState.getNavigationEntries$();
  }

  public hasRole(): boolean {
    return this.hasAnyRole;
  }

  public getHiddenContext(): boolean {
    return this.hiddenContext;
  }

  public getNotificationCount(): Observable<number> {
    return this.notificationState.asObservable().pipe(map((notificationState) => notificationState?.notificationCount));
  }

  public updateNotificationCount(): Observable<AokNotificationCountResponse> {
    return this.notificationService.getCount();
  }

  public setHiddenContext(hidden: boolean): AokHeaderService {
    this.hiddenContext = hidden;
    return this;
  }

  public async onUserReady(user: AokUser): Promise<void> {
    this.hasAnyRole = [
      KnownUserType.Doctor,
      KnownUserType.Kvn_Doctor,
      KnownUserType.Full_Kvn_Doctor,
      KnownUserType.Assistant,
      KnownUserType.Kvn_Practice_Team,
    ].includes(user.userType);
  }

  public getProfileMenuLinks(): MenuLink[] {
    const profileLinks = [
      {
        internal: true,
        href: '/profil/praxisdaten',
        icon: 'office',
        label: 'Praxisdaten',
        onClick: () => this.router.navigateByUrl('/profil/praxisdaten'),
      },
    ];

    if (this.kvnState.isKvnPracticeTeam()) {
      return profileLinks;
    }

    profileLinks.push({
      internal: true,
      href: '/profil/profildaten',
      icon: 'login',
      label: 'Profildaten',
      onClick: () => this.router.navigateByUrl('/profil/profildaten'),
    });

    if (isAssistant(this.currentUser.snapshot)) {
      return profileLinks;
    }

    profileLinks.unshift({
      internal: true,
      href: '/profil/praxisverwaltung',
      icon: 'settings',
      label: 'Praxisverwaltung',
      onClick: () => this.router.navigateByUrl('/profil/praxisverwaltung'),
    });

    return profileLinks;
  }
}
