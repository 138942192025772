import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AokConsent } from '../schemas';
import { AokApiBaseClient } from './api-base.client';

export const AOK_CONSENT_BASE_URL = new InjectionToken<string>('AOK_CONSENT_BASE_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/users/my/consents',
});

@Injectable({ providedIn: 'root' })
export class AokConsentClient extends AokApiBaseClient {
  constructor(@Inject(AOK_CONSENT_BASE_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  public list(): Observable<AokConsent[]> {
    return this.http.get<AokConsent[]>(this.resolveUrl());
  }

  public commit(): Observable<AokConsent> {
    return this.http.post<AokConsent>(this.resolveUrl(), null);
  }
}
