import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import {
  AokMfaToBSNRRegistration,
  AokOrg,
  AokPage,
  AokUser,
  AokUserClient,
  AokUserRegistration,
  AokUserRegistrationClient,
  ContextState,
  CurrentUserState,
  isDoctor,
  KnownUserType,
} from '@aok/common';
import { ProfileAssistant, ProfileService } from '@aok/components';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

export const practiceEmployeesResolver: ResolveFn<AokPage<ProfileAssistant>> = () => {
  const currentUser = inject(CurrentUserState);
  const profileService = inject(ProfileService);
  const clientUser = inject(AokUserClient);
  const clientReg = inject(AokUserRegistrationClient);
  const contextState = inject(ContextState);

  return currentUser.first$.pipe(
    mergeMap((user) => callAllEmployees(user, clientUser, clientReg, contextState)),
    tap((employees) => {
      if (employees) {
        profileService.setUsers(employees?._embedded?.items || []);
      }
    })
  );
};

const callAllEmployees = (
  currentUser: AokUser,
  clientUser: AokUserClient,
  clientReg: AokUserRegistrationClient,
  contextState: ContextState
): Observable<AokPage<ProfileAssistant>> => {
  if (isDoctor(currentUser)) {
    return contextState.bsnr
      ? getAllEmployees(contextState.bsnr, clientUser, clientReg)
      : contextState.getFirstOrg$().pipe(
          mergeMap((org: AokOrg) => {
            return getAllEmployees(org.bsnr, clientUser, clientReg);
          })
        );
  } else {
    return of(null);
  }
};

/**
 * Get all assistants, those that are registered, those that are invited, that have bsnr registration request - users and registrations
 * @param bsnr bsnr to query upon
 * @param clientUser client service
 * @param clientReg registration service
 */
const getAllEmployees = (
  bsnr: string,
  clientUser: AokUserClient,
  clientReg: AokUserRegistrationClient
): Observable<AokPage<ProfileAssistant>> => {
  return forkJoin([
    clientReg.list({
      bsnr,
      userType: KnownUserType.Assistant,
      size: 1000,
    }),
    clientUser.list({
      bsnr,
      size: 1000,
    }),
    clientUser.getRequestedMfa(bsnr),
  ]).pipe(
    map<[AokPage<AokUserRegistration>, AokPage<AokUser>, AokPage<AokMfaToBSNRRegistration>], AokPage<ProfileAssistant>>(
      (results) => {
        let items: ProfileAssistant[] = results[0]._embedded ? [...results[0]._embedded.items] : [];
        items = results[1]._embedded ? items.concat(results[1]._embedded.items) : items;
        items = results[2]._embedded ? items.concat(results[2]._embedded.items) : items;

        return {
          _embedded: { items },
          content: undefined,
          links: [],
          page: { number: 0, size: 1000, totalElements: items.length, totalPages: 1 },
        } as AokPage<ProfileAssistant>;
      }
    )
  );
};
