import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationEntryDefDirective } from './navigation-entry-def.directive';
import { NavigationEntryLinkDirective } from './navigation-entry-link.directive';
import { NavigationEntryOutletComponent } from './navigation-entry-outlet.component';
import { StringInterpolateModule } from '../../utils';

const declarations = [NavigationEntryDefDirective, NavigationEntryOutletComponent, NavigationEntryLinkDirective];

/**
 * Copy of PDS module
 * https://git.vitasystems.dev/vitagroup/pds-angular/-/tree/develop/libs/pds-components/layout/src/navigation
 */
@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule, StringInterpolateModule, RouterModule],
})
export class NavigationModule {}
