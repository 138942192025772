import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokReminderComponent } from './reminder.component';

@NgModule({
  declarations: [AokReminderComponent],
  exports: [AokReminderComponent],
  imports: [CommonModule, A11yUtilsModule, AokSvgIconComponent],
})
export class AokReminderModule {}
