import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AokArticle, AokArticleCategory, AokArticleStatus, AokArticleType, ArticleImageState } from '../../schemas';
import { acceptMimeTypes } from '../validators/accept-mime-types.validator';
import { maxFileSize } from '../validators/max-file-size.validator';
import { FormSchemaControls } from './form-utils/form-schema-map';

export function createAokArticleForm(
  schema?: AokArticle,
  acceptImageMimeTypes: string[] = ['image/jpeg', 'image/png'],
  maxImageBytes = 10 * 1024 * 1024
): FormGroup {
  const { required } = Validators;
  return new FormGroup({
    id: new FormControl<number>(schema?.id),
    title: new FormControl<string>(schema?.title, [required]),
    content: new FormControl<string>(schema?.content, [required]),
    type: new FormControl<AokArticleType>(schema?.type || AokArticleType.News, [required]),
    status: new FormControl<AokArticleStatus>(schema?.status || AokArticleStatus.Draft, [required]),
    categories: new FormControl<AokArticleCategory[]>(schema?.categories),
    articleImageState: new FormControl<ArticleImageState>(ArticleImageState.NOT_CHANGED),
    image: new FormControl<string | File>(schema?.imageUrl, [
      acceptMimeTypes(acceptImageMimeTypes),
      maxFileSize(maxImageBytes),
    ]),
  } as FormSchemaControls<AokArticle>);
}

export function createAokArticleFormData(article: Partial<AokArticle>, image?: File): FormData {
  const formData = new FormData();

  for (const key of Object.keys(article)) {
    switch (key) {
      case 'image':
        if (image) {
          formData.append('image', image);
        }
        break;
      case 'categories':
        formData.append('categoryIds', article.categories?.map((c) => c.id).join() || '');
        break;
      default:
        formData.append(key, article[key as keyof AokArticle] as string);
    }
  }

  return formData;
}
