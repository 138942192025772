import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AokApiBaseClient } from './api-base.client';

interface AokSSOSession {
  ssoSessionTimeInMinutes: number;
}

export const SSO_SESSION_IDLE = new InjectionToken<string>('SSO_SESSION_IDLE', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/',
});

@Injectable({ providedIn: 'root' })
export class AokSSOSessionIdleClient extends AokApiBaseClient {
  constructor(@Inject(SSO_SESSION_IDLE) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  public getSsoSessionIdle(): Observable<AokSSOSession> {
    return this.http.get<AokSSOSession>(
      this.resolveUrl('keycloak/auth/realms/AOKNAPE/get_sso_session_time/sso-session-time')
    );
  }
}
