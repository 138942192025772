import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { AokReminder, SVG_ICON_SIZE } from '@aok/common';

const left = [
  query(
    ':enter, :leave',
    style({
      position: 'absolute',
      top: 0,
      width: 'calc(100% + 60px)', // we need to substract the width around the teaser because the transition requires absolute positioning.
      // 60 = 2*30px (teaser padding)
    }),
    { optional: true }
  ),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('.6s ease-out', style({ transform: 'translateX(0%)', opacity: 1 })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('.6s ease-out', style({ transform: 'translateX(100%)', opacity: 0 })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

const right = [
  query(
    ':enter, :leave',
    style({
      position: 'absolute',
      top: 0,
      width: 'calc(100% + 60px)', // we need to substract the width around the teaser because the transition requires absolute positioning.
      // 60 = 2*30px (teaser padding)
    }),
    { optional: true }
  ),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('.6s ease-out', style({ transform: 'translateX(0%)', opacity: 1 })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('.6s ease-out', style({ transform: 'translateX(-100%)', opacity: 0 })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

@Component({
  selector: 'aok-reminder-carousel',
  styleUrls: ['./reminder-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './reminder-carousel.component.html',
  providers: [{ provide: SVG_ICON_SIZE, useValue: 42 }],
  animations: [trigger('slideInOut', [transition(':increment', right), transition(':decrement', left)])],
})
export class AokReminderCarouselComponent implements OnChanges {
  @Input()
  public reminders: AokReminder[];

  @Output()
  public showAll: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public actionLinkClicked: EventEmitter<AokReminder> = new EventEmitter<AokReminder>();

  public counter = 0;

  private activeIndex = 0;

  ngOnChanges(): void {
    this.setActiveIndex(0);
  }

  public getLinkText(): string {
    const length = this.reminders.length;
    if (length === 1) {
      return '1 offene Aktion, die Ihre Aufmerksamkeit benötigt';
    } else {
      return `${length} offene Aktionen, die Ihre Aufmerksamkeit benötigen`;
    }
  }

  public next(): void {
    this.counter++;
    this.activeIndex = this.getActiveIndex();
  }

  public previous(): void {
    this.counter--;
    this.activeIndex = this.getActiveIndex();
  }

  public setActiveIndex(index: number): void {
    if (this.activeIndex === index) {
      return;
    }

    if (index > this.activeIndex) {
      this.counter++;
    } else if (index < this.activeIndex) {
      this.counter--;
    }

    this.activeIndex = index;
  }

  public showAllClick(): void {
    this.showAll.emit();
  }

  public isActive(index: number): boolean {
    return index === this.activeIndex;
  }

  public openLink(reminder: AokReminder): void {
    this.actionLinkClicked.emit(reminder);
  }

  private getActiveIndex(): number {
    const remindersCount = this.reminders.length;
    const counterModulo = Math.abs(this.counter % remindersCount);

    if (this.counter >= 0 || counterModulo === 0) {
      return counterModulo;
    } else {
      return remindersCount - counterModulo;
    }
  }
}
