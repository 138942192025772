import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  AokCardReaderError,
  AokServiceRequest,
  DialogOverlay,
  ENVIRONMENT,
  EgkData,
  EnvironmentSchema,
  removeUnusedAttributes,
} from '@aok/common';
import { Observable, Subject, catchError, filter, mergeMap, of, takeUntil, tap, timer } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { AokCardReaderDownloadDialog, AokCardReaderProgressDialog } from '../components';

const defaultCardReaderErrorStatus = '500';
const defaultCardReaderErrorContext = 'CardToWeb-App';
const defaultCardReaderErrorMessage =
  'Es ist ein unerwarteter Fehler in der CardToWeb-Anwendung aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support';

@Injectable({
  providedIn: 'root',
})
export class CardReaderService {
  private stopTryingSubject: Subject<void>;

  // This identifier is meant to identify the client app that uses the CardToWeb-App.
  // First it is used when starting the CardToWeb-App from the Angular application.
  // Then it is used when checking the connection availability (PING request) and then tries to read the insurance card data (EGK request).
  // It is regenerated every time the Angular application is reloaded.
  private cardToWebAppIdentifier = uuid();

  constructor(
    private dialog: DialogOverlay,
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT) private environment: EnvironmentSchema
  ) {}

  public tryStartWebBridgeAndReadEgkData(
    successEmitter: EventEmitter<AokServiceRequest>,
    errorEmitter: EventEmitter<AokCardReaderError>
  ): void {
    this.stopTryingSubject = new Subject<void>();
    let isAppManuallyStarted = false;
    const progressDialogRef = this.dialog.create(AokCardReaderProgressDialog, {
      closable: true,
      props: {
        stopSubject: this.stopTryingSubject,
      },
    });

    timer(0, 1000)
      .pipe(
        takeUntil(this.stopTryingSubject),
        mergeMap(() => this.checkIsWebBridgeReachable()),
        tap((isReachable) => {
          if (!isReachable && !isAppManuallyStarted) {
            window.location.href = `cardtoweb://start/${this.cardToWebAppIdentifier}`;
            isAppManuallyStarted = true;
          }
        }),
        filter((isReachable) => isReachable),
        mergeMap(() => this.tryReadEgkData()),
        tap({
          next: () => {
            progressDialogRef.dispose();
            this.stopTrying(this.stopTryingSubject);
          },
          complete: () => progressDialogRef.dispose(),
          error: () => this.handleTimeout(progressDialogRef, successEmitter, errorEmitter),
        }),
        catchError((error) => this.handleEgkDataError(error)),
        takeUntil(timer(10000).pipe(tap(() => this.handleTimeout(progressDialogRef, successEmitter, errorEmitter))))
      )
      .subscribe((data: EgkData | AokCardReaderError) => {
        if (data instanceof AokCardReaderError) {
          errorEmitter.emit(data as AokCardReaderError);
        } else if (data?.isEGK) {
          const patientData = this.buildInsuranceCardPatientRequest(data);
          successEmitter.emit(patientData);
        }
      });
  }

  public handleTimeout(
    dialogRef: any,
    successEmitter: EventEmitter<AokServiceRequest>,
    errorEmitter: EventEmitter<AokCardReaderError>
  ): void {
    this.stopTrying(this.stopTryingSubject);
    dialogRef.dispose();

    this.dialog.create(AokCardReaderDownloadDialog, { closable: true }).subscribe((result) => {
      if (result) {
        this.tryStartWebBridgeAndReadEgkData(successEmitter, errorEmitter);
      }
    });
  }

  private buildInsuranceCardPatientRequest(egkData: EgkData): AokServiceRequest {
    if (egkData?.egK_PersoenlicheVersichertendaten?.versicherter) {
      const dateValue = egkData.egK_PersoenlicheVersichertendaten.versicherter.person.geburtsdatum;
      const formattedDateString = `${dateValue.slice(0, 4)}-${dateValue.slice(4, 6)}-${dateValue.slice(6, 8)}`;
      const dateOfBirth = new Date(formattedDateString);
      const insuranceNumber = egkData.egK_PersoenlicheVersichertendaten.versicherter.versicherten_ID;
      return removeUnusedAttributes({
        checkboxIdentityCheck: true,
        dateOfBirth,
        insuranceNumber,
      }) as AokServiceRequest;
    }
  }

  private checkIsWebBridgeReachable(): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.environment.cardReaderApiUrl}/PING/${this.cardToWebAppIdentifier}`, {})
      .pipe(catchError(() => of(false)));
  }

  private stopTrying(stopTryingSubject: Subject<void>): void {
    if (!stopTryingSubject.closed) {
      stopTryingSubject.next(null);
      stopTryingSubject.complete();
    }
  }

  private tryReadEgkData(): Observable<EgkData | AokCardReaderError> {
    return this.httpClient
      .post<EgkData>(`${this.environment.cardReaderApiUrl}/EGK/${this.cardToWebAppIdentifier}`, {})
      .pipe(catchError(this.handleEgkDataError));
  }

  private handleEgkDataError(response: HttpErrorResponse): Observable<AokCardReaderError> {
    return of(
      new AokCardReaderError(
        response.error?.ErrorCode || defaultCardReaderErrorStatus,
        response.error?.ErrorMsg || defaultCardReaderErrorMessage,
        response.error?.Kontext || defaultCardReaderErrorContext
      )
    );
  }
}
