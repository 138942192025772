import { Inject, Injectable, InjectionToken, Type } from '@angular/core';
import { Router } from '@angular/router';
import { AokCookieUtils as cookies, DialogBase, DialogOverlay, getExpirationDate, getParentDomain } from '@aok/common';
import { MatomoTracker } from 'ngx-matomo';
import { BehaviorSubject, Observable } from 'rxjs';

export const AOK_CONSENT_COOKIE_NAME = 'AOK_COOKIE_CONSENT_FN';
export const AOK_COOKIE_CONSENT_DIALOG = new InjectionToken<Type<DialogOverlay>>('AOK_COOKIE_CONSENT_DIALOG');

@Injectable()
export class AokCookieService {
  private cookieAccepted = new BehaviorSubject(false);

  constructor(
    protected router: Router,
    protected matomoTracker: MatomoTracker,
    protected dialog: DialogOverlay,
    @Inject(AOK_COOKIE_CONSENT_DIALOG) protected dialogType: Type<DialogBase<unknown>>
  ) {}

  get isAccepted(): boolean {
    return cookies.get(AOK_CONSENT_COOKIE_NAME)?.trim() === 'true';
  }

  get cookieAccepted$(): Observable<boolean> {
    return this.cookieAccepted.asObservable();
  }

  public accept(useMarketing?: boolean, hoursToExpire?: number): void {
    const domain = getParentDomain();
    const yearInHours = 365 * 24;

    cookies.set(
      AOK_CONSENT_COOKIE_NAME,
      true.toString(),
      `expires=${getExpirationDate(hoursToExpire ?? yearInHours)};path=/;domain=${domain}`
    );

    if (useMarketing) {
      this.rememberConsentGiven(yearInHours);
    } else {
      // could still exist from a past visit, we need to remove it. If we call the 'forgetConsentGiven' function
      // without the mtm_consent_given cookie set, matomo logs an error to console. Therefore, this check is mandatory.
      if (cookies.exists('mtm_consent')) {
        this.matomoTracker.forgetConsentGiven();
      }
    }
    this.cookieAccepted.next(true);
  }

  public check(): void {
    if (!this.isAccepted) {
      this.openCookieDialog();
    } else {
      this.cookieAccepted.next(this.isAccepted);
    }
  }

  public hasMarketing(): Promise<boolean> {
    return this.matomoTracker.hasCookies();
  }

  public openCookieDialog(): void {
    const dialogRef = this.dialog.create(this.dialogType);

    dialogRef.componentRef.changeDetectorRef.detectChanges();
    dialogRef.subscribe();
  }

  private rememberConsentGiven(hoursToExpire: number): void {
    this.matomoTracker.rememberConsentGiven(hoursToExpire);
  }
}
