import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { AokPractitionerSpecializationEntry } from '../../schemas';
import { getSpecializationIdLanr9 } from '../specialization.util';
import { zeroPad } from '../zero-pad.util';

export function specialization(specializations: AokPractitionerSpecializationEntry[]): ValidatorFn {
  const error: ValidationErrors = { specialization: true };
  const isValidSpecialization = (specialization: string): AokPractitionerSpecializationEntry =>
    specializations.find((value) => zeroPad(value.id) === specialization);

  return (control) => {
    const specialization = getSpecializationIdLanr9(control.value);
    return specialization && isValidSpecialization(specialization) ? null : error;
  };
}
