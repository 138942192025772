import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DialogBase, DialogRef, KnownUserType } from '@aok/common';

@Component({
  selector: 'aok-delete-kvn-profile',
  templateUrl: './delete-kvn-profile-dialog.component.html',
  styleUrls: ['./delete-kvn-profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteKvnProfileDialog implements DialogBase<{ userType: KnownUserType | null }> {
  @Input() headerText: string;
  @Input() cancelButtonText: string;
  @Input() cancelInfo: string;
  @Input() confirmButtonText: string;
  @Input() isLastDoctorAtBSNRLocation: boolean;

  public aokProfile = false;
  public kvnProfile = false;

  constructor(public readonly dialogRef: DialogRef<{ userType: KnownUserType | null }>, private router: Router) {}

  public cancelDialog(): void {
    this.dialogRef.dispose(null);
  }

  public submitDialog(): void {
    if (this.aokProfile && this.kvnProfile) {
      this.dialogRef.dispose({ userType: null });
      return;
    }

    this.dialogRef.dispose({ userType: this.aokProfile ? KnownUserType.Doctor : KnownUserType.Kvn_Doctor });
  }

  public navigateToDataProtectionPage(): void {
    this.cancelDialog();
    this.router.navigate(['datenschutz']);
  }
}
