import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AokAllowedPrograms,
  AokBatchPrintingRequest,
  AokBillingSheetRequest,
  AokBillingSheetTypeEnum,
  AokInsuranceStatusResponse,
  AokInsuree,
  AokPatientDMPData,
  AokPrintRequest,
  AokServiceRequest,
} from '../schemas';
import { AokApiBaseClient } from './api-base.client';

export const AOK_INSURANCE_STATUS_API_URL = new InjectionToken<string>('AOK_INSURANCE_STATUS_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/insurance',
});

@Injectable({ providedIn: 'root' })
export class AokInsuranceStatusClient extends AokApiBaseClient {
  constructor(@Inject(AOK_INSURANCE_STATUS_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  check(request: AokServiceRequest & { invoiceDate: any }): Observable<HttpResponse<AokInsuree>> {
    return this.http.post<AokInsuree>(this.resolveUrl('status-check'), request, { observe: 'response' });
  }

  getInsureeDetails(request: AokServiceRequest): Observable<HttpResponse<AokInsuree>> {
    return this.http.post<AokInsuree>(this.resolveUrl('insuree'), request, { observe: 'response' });
  }

  getFullInsuranceStatus(request: AokServiceRequest): Observable<HttpResponse<AokInsuranceStatusResponse>> {
    return this.http.post<AokInsuranceStatusResponse>(this.resolveUrl('fullInsurance'), request, {
      observe: 'response',
    });
  }

  /** Fetches an image preview of the billing sheet for the given */
  getBillingSheetPreview(
    insureeId: NonNullable<string>,
    bsnr: NonNullable<string> = '',
    lanr: NonNullable<string> = '',
    invoiceDate: NonNullable<string> = ''
  ): Observable<Blob> {
    const body: AokBillingSheetRequest = { bsnr, lanr, invoiceDate };
    return this.http.post<Blob>(this.resolveUrl('billing-sheet', 'preview', insureeId), body, {
      responseType: 'blob',
    } as any) as any;
  }

  getBillingSheetPreference(): Observable<AokBillingSheetTypeEnum> {
    return this.http.get<AokBillingSheetTypeEnum>(this.resolveUrl('billing-sheet', 'my', 'preference'));
  }

  /** Fetches the PDF of the billing sheet for the given */
  getBillingSheet(
    insureeId: NonNullable<string>,
    bsnr: NonNullable<string> = '',
    lanr: NonNullable<string> = '',
    invoiceDate: NonNullable<string> = '',
    type: NonNullable<AokBillingSheetTypeEnum> = AokBillingSheetTypeEnum.PrePrinted
  ): Observable<Blob> {
    const body: AokBillingSheetRequest = { bsnr, lanr, invoiceDate, type };
    return this.http.post<Blob>(this.resolveUrl('billing-sheet', 'print', insureeId), body, {
      responseType: 'blob',
    } as any) as any;
  }

  getAllowedDMPs(lanr9: NonNullable<string>): Observable<AokAllowedPrograms> {
    const body = { lanr: lanr9 };
    return this.http.post<AokAllowedPrograms>(this.resolveUrl('dmp', 'programs'), body);
  }

  getPatientDMP(body: AokServiceRequest & { lanr: string }): Observable<HttpResponse<AokPatientDMPData>> {
    return this.http.post<AokPatientDMPData>(this.resolveUrl('dmp'), body, { observe: 'response' });
  }

  public getBatchPrintData(payload: AokBatchPrintingRequest): Observable<AokBatchPrintingRequest> {
    return this.http.post<AokBatchPrintingRequest>(this.resolveUrl('batch-print'), payload);
  }

  public printBatchBillingSheet(payload: AokPrintRequest): Observable<Blob> {
    return this.http.post<Blob>(this.resolveUrl('billing-sheet', 'print'), payload, {
      responseType: 'blob' as 'json',
    });
  }
}
