import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { CONTROL_ERROR_MESSAGE } from '@aok/common';

@Component({
  selector: 'aok-form-error',
  styleUrls: ['./form-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `{{ message }}`,
})
export class FormErrorComponent {
  constructor(@Inject(CONTROL_ERROR_MESSAGE) public message: string) {}
}
