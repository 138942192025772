import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokStepComponent } from './step.component';
import { AokStepperCarouselComponent } from './stepper-carousel/stepper-carousel.component';
import { AokStepperComponent } from './stepper.component';

const declarations = [AokStepperComponent, AokStepComponent, AokStepperCarouselComponent];

@NgModule({
  declarations,
  imports: [CommonModule, A11yUtilsModule, AokSvgIconComponent],
  exports: declarations,
})
export class AokStepperModule {}
