import { EnvironmentSchema, KnownRoles, NavigationEntry } from '@aok/common';
import { KvnState } from '../states';

/**
 * list of navigation entries to be used in case of kvn errors
 */
export const kvnErrorNavigationEntries: NavigationEntry[] = [{ name: 'Start', linkUrl: '/kvn-fehler' }];

/**
 * returns list of navigation entries accessible by logged-in users, filterable by roles
 */
export const generalNavigationEntries = (kvnState: KvnState, environment: EnvironmentSchema): NavigationEntry[] => [
  {
    name: `Übersicht`,
    linkUrl: '/uebersicht',
    roles: [KnownRoles.HealthcareProfessional, KnownRoles.Assistant],
  },
  {
    name: `Berichte`,
    linkUrl: '/berichte',
    roles: [KnownRoles.HealthcareProfessional, KnownRoles.Assistant],
    childrenFromSitemap: true,
  },
  {
    name: `Verträge`,
    linkUrl: '/contracts',
    roles: [KnownRoles.HealthcareProfessional, KnownRoles.Assistant],
    visible: environment.appHub && (environment.enableContractsOverview || environment.enableFamilyDoctorCenterCare),
    childrenFromSitemap: true,
  },
  {
    name: `Services`,
    linkUrl: '/services',
    roles: [KnownRoles.HealthcareProfessional, KnownRoles.Assistant],
    childrenFromSitemap: true,
  },
  {
    name: `Aktuelles`,
    linkUrl: '/aktuelles',
    roles: [KnownRoles.HealthcareProfessional, KnownRoles.Assistant],
    childrenFromSitemap: true,
  },
  {
    name: kvnState.isKvn() ? 'KVN-Zugang einrichten' : `Registrierungsstatus`,
    linkUrl: '/registrierungsanfrage',
    roles: [KnownRoles.PendingHealthcareProfessional, KnownRoles.PendingAssistant],
  },
];
