<div class="input-field-header" *ngIf="!disableHeader">
  <ng-container *ngIf="label; else projectLabelContent">
    <label *ngIf="elementId === null">{{ label }}</label>
    <label *ngIf="elementId !== null" [for]="elementId">{{ label }}</label>
  </ng-container>
  <ng-template #projectLabelContent>
    <ng-content select="label"></ng-content>
  </ng-template>
  <aok-required-mark *ngIf="required"></aok-required-mark>
</div>
<div class="input-field-control" [class.input-control-prefix]="hasPrefix" [class.input-control-suffix]="hasSuffix">
  <div class="input-field-prefix" *ngIf="hasPrefix">
    <ng-content select="[aokInputFieldPrefix]"></ng-content>
  </div>
  <div #contentWrapper class="input-field-infix">
    <ng-content></ng-content>
  </div>
  <div class="input-field-suffix" *ngIf="hasSuffix">
    <ng-content select="[aokInputFieldSuffix]"></ng-content>
  </div>
</div>
