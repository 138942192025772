import { Component, Inject, Input, Optional, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextState, getContextSpecificUrl, getKvnStateUrl } from '@aok/common';
import { KvnState } from '../../../states';
import { AokSitemap, SITEMAP, SiteRef } from './sitemap';
import { AokSitemapIntlService } from './sitemap-intl.service';

@Component({
  selector: 'aok-sitemap-tree',
  styleUrls: ['./sitemap-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'sitemap-tree.component.html',
})
export class AokSitemapTreeComponent {
  @Input() showReports = true;

  constructor(
    private contextState: ContextState,
    private kvnState: KvnState,
    private route: ActivatedRoute,
    @Inject(SITEMAP) readonly sitemap: /** @dynamic */ AokSitemap,
    @Optional() protected intl: AokSitemapIntlService
  ) {}

  resolveTitle(ref: SiteRef): string | null {
    return this.showReports || ref.title !== 'Berichte' || (ref.title === 'Berichte' && ref.children.length !== 1)
      ? ref.title || this.intl?.getSiteRefTitle(ref) || ref.key
      : undefined;
  }

  public filterChildren(children: SiteRef[]): SiteRef[] {
    return this.showReports ? children : children.filter((child) => child.title !== 'Berichte');
  }

  public interpolateContext(entry: SiteRef): string {
    const contextUrl = getContextSpecificUrl(entry.linkUrl, this.contextState);
    return getKvnStateUrl(contextUrl, this.route.snapshot, this.kvnState.isKvn());
  }

  protected isExternalRef(ref: SiteRef): boolean {
    return ref?.target === '_blank';
  }

  protected getRouteUrl(siteRef: SiteRef): string | null {
    if (siteRef.interimRoute) {
      return null;
    }
    return siteRef.contract ? this.interpolateContext(siteRef) : siteRef.linkUrl;
  }
}
