import { Pipe, PipeTransform } from '@angular/core';
import { DoctorType } from '../../schemas';
import { getDoctorType } from '../../utils';

@Pipe({ name: 'aokDoctorType', standalone: true })
export class AokDoctorTypeIntlPipe implements PipeTransform {
  transform(value: DoctorType | string): string {
    return getDoctorType(value);
  }
}
