import { Unit } from '../pipes';

const UnitLabel = {
  second: {
    singular: 'Sekunde',
    plural: 'Sekunden',
  },
  minute: {
    singular: 'Minute',
    plural: 'Minuten',
  },
  hour: {
    singular: 'Stunde',
    plural: 'Stunden',
  },
  day: {
    singular: 'Tag',
    plural: 'Tagen',
  },
  week: {
    singular: 'Woche',
    plural: 'Wochen',
  },
  month: {
    singular: 'Monat',
    plural: 'Monaten',
  },
  year: {
    singular: 'Jahr',
    plural: 'Jahren',
  },
};

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;

export const computeValue = (date: Date, now: Date, startAt: Unit = 'second'): { value: number; unit: Unit } => {
  const nowMillis = now.valueOf();
  const dateMillis = date.valueOf();

  const seconds = Math.round(Math.abs(nowMillis - dateMillis) / 1000);

  let value, unit;

  if (seconds < MINUTE && shouldStartAtSeconds(startAt)) {
    value = seconds;
    unit = 'second';
  } else if (seconds < HOUR && shouldStartAtMinutes(startAt)) {
    value = Math.floor(seconds / MINUTE);
    unit = 'minute';
  } else if (seconds < DAY && shouldStartAtHours(startAt)) {
    value = Math.floor(seconds / HOUR);
    unit = 'hour';
  } else if (seconds < WEEK) {
    value = Math.floor(seconds / DAY);
    unit = 'day';
  } else if (seconds <= 4 * WEEK || isWithinAMonth(date, seconds)) {
    value = Math.floor(seconds / WEEK);
    unit = 'week';
  } else if (isWithinAYear(date, seconds)) {
    const years = now.getFullYear() - date.getFullYear();
    const months = years * 12 - date.getMonth() + now.getMonth();
    const nowToCheck = new Date(now).setFullYear(date.getFullYear(), date.getMonth());
    value = nowToCheck >= date.valueOf() ? months : months - 1;
    unit = 'month';
  } else {
    const years = now.getFullYear() - date.getFullYear();
    const nowToCheck = new Date(now).setFullYear(date.getFullYear());
    value = nowToCheck >= date.valueOf() ? years : years - 1;
    unit = 'year';
  }

  // show at least one unit when it's not exactly the same date
  if (value === 0 && unit !== 'second') {
    value = 1;
  }

  return {
    value,
    unit: unit as Unit,
  };
};

export const toDisplay = (value: number, unit: Unit): string => {
  const unitLabel = value === 1 ? UnitLabel[unit].singular : UnitLabel[unit].plural;

  return 'vor ' + value + ' ' + unitLabel;
};

/**
 * check if the difference in seconds resides still in a one-month time, depending on the length of the month
 * @param date the date for which the difference is checked
 * @param seconds the difference in seconds from date to now
 */
const isWithinAMonth = (date: Date, seconds: number): boolean => {
  const endDate = new Date(date.getFullYear(), (date.getMonth() + 1) % 12, 0).getDate();

  return seconds < 4 * WEEK + (endDate - 28) * DAY;
};

const isWithinAYear = (date: Date, seconds: number): boolean => {
  let daysToConsider;

  const month = date.getMonth();
  const year = date.getFullYear();
  if (month > 1 && (year + 1) % 4 === 0) {
    daysToConsider = 366;
  } else if (((month === 1 && date.getDate() < 27) || month < 1) && year % 4 === 0) {
    daysToConsider = 366;
  } else {
    daysToConsider = 365;
  }

  return seconds < daysToConsider * DAY;
};

const shouldStartAtSeconds = (startAt: Unit): boolean => {
  return startAt === 'second';
};

const shouldStartAtMinutes = (startAt: Unit): boolean => {
  return shouldStartAtSeconds(startAt) || startAt === 'minute';
};

const shouldStartAtHours = (startAt: Unit): boolean => {
  return shouldStartAtMinutes(startAt) || startAt === 'hour';
};
