<ng-template #ngContentTemplate>
  <ng-content></ng-content>
</ng-template>

<ng-template #defaultHeaderTemplate>
  <dialog-header>
    <div class="aok-container" *ngIf="fullscreen; else popupHeader">
      <img [src]="'assets/img/aok-logo-small.svg'" alt="AOK-Arztportal (Link zur Übersicht)" class="header-logo" />

      <div *ngIf="closable" id="close">
        <aok-svg-icon
          aokFocusable
          (click)="close()"
          (keyup.enter)="close()"
          class="icon-button"
          size="32"
          id="close-small"
        >
        </aok-svg-icon>
      </div>
      <h3 *ngIf="headerText" class="tw-col-2-12 lg:tw-col-3-11">{{ headerText }}</h3>
    </div>

    <ng-template #popupHeader>
      <div *ngIf="closable" id="close">
        <aok-svg-icon
          aokFocusable
          (click)="close()"
          (keyup.enter)="close()"
          class="icon-button"
          size="32"
          id="close-small"
        >
        </aok-svg-icon>
      </div>
      <h4 *ngIf="headerText">{{ headerText }}</h4>
    </ng-template>
  </dialog-header>
</ng-template>

<ng-container *ngIf="header; else defaultHeaderTemplate">
  <ng-content select="dialog-header"></ng-content>
</ng-container>

<ng-container *ngIf="useMainContainer; else ngContentTemplate">
  <main #main>
    <div>
      <ng-container *ngTemplateOutlet="ngContentTemplate"></ng-container>
    </div>
  </main>
</ng-container>

<ng-template #defaultFooterTemplate>
  <div *ngIf="fullscreen">
    <div id="footer" class="aok-container">
      <aok-to-top-scroller></aok-to-top-scroller>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="footer; else defaultFooterTemplate">
  <ng-content select="dialog-footer"></ng-content>
</ng-container>
