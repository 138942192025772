<div
  aokFocusable
  class="header-link"
  *ngIf="!isOpened"
  (click)="toggleLink.emit()"
  (keyup.enter)="toggleLink.emit()"
  title="{{ altTextClosed }}"
>
  <aok-svg-icon [name]="icon" [size]="20"></aok-svg-icon>
  <span>{{ label }}</span>
</div>

<div
  aokFocusable
  *ngIf="isOpened"
  class="opened header-link"
  (click)="toggleLink.emit()"
  (keyup.enter)="toggleLink.emit()"
  title="{{ altTextOpened }}"
>
  <aok-svg-icon [name]="'cancel-circle_filled'" [size]="20"></aok-svg-icon>
  <span>{{ label }}</span>
</div>
