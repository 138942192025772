<dialog-layout
  fullscreen="true"
  cdkTrapFocus
  cdkTrapFocusAutoCapture="true"
  [headerText]="msgDialogHeadline"
  id="dialog"
>
  <p>{{ msgDialogText }}</p>

  <aok-notification-banner [bannerHeadline]="msgBannerHeadline" [bannerLabel]="msgBannerLabel" accent="warning">
  </aok-notification-banner>

  <aok-cockpit-table-type [sectionLabel]="msgMfaTableHeadline" [values]="msgMfaTableValues"> </aok-cockpit-table-type>
  <aok-cockpit-table-type [sectionLabel]="msgPracticeTableHeadline" [values]="msgPracticeTableValues">
  </aok-cockpit-table-type>

  <div class="button-container">
    <button class="secondary" (click)="firstLevelRejection()" cdkFocusInitial>{{ msgButtonRequired }}</button>
    <button (click)="acceptSubmit()">{{ msgButtonAccept }}</button>
  </div>
</dialog-layout>
