import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AccordionService {
  public behaviorStatus: { [accordionId: string]: BehaviorSubject<string> } = {};

  public toggleAccordionOption(accordionId: string, accordionOptionId: string): void {
    if (this.behaviorStatus?.[accordionId]?.value === accordionOptionId) {
      this.closeAccordion(accordionId);
    } else {
      this.openAccordion(accordionId, accordionOptionId);
    }
  }

  public closeAccordion(accordionId: string): void {
    this.behaviorStatus?.[accordionId]?.next(null);
  }

  public openAccordion(accordionId: string, accordionOptionId: string): void {
    if (this.behaviorStatus?.[accordionId]) {
      this.behaviorStatus[accordionId].next(accordionOptionId);
    } else {
      this.behaviorStatus[accordionId] = new BehaviorSubject(accordionOptionId);
    }
  }

  public getOpenOption(accordionId: string): string {
    return this.behaviorStatus?.[accordionId]?.value;
  }

  public getBehaviorStatus(accordionId: string): BehaviorSubject<string> {
    if (!this.behaviorStatus?.[accordionId]) {
      this.behaviorStatus[accordionId] = new BehaviorSubject<string>(null);
    }

    return this.behaviorStatus[accordionId];
  }
}
