import { APP_BOOTSTRAP_LISTENER, Provider } from '@angular/core';
import { AppState, ContextState, UserService } from '@aok/common';
import { KvnService } from '@aok/components';
import { lastValueFrom } from 'rxjs';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

/**
 * Cockpit is accessible if:
 *    context selected
 *    not pending user - contextSelected$ will not emit if user pending => using default value (false)
 *    not kvn error OR kvn error but doctor registered (verified email)
 */
export function initCockpitAccess(user: UserService, context: ContextState, kvn: KvnService, appState: AppState) {
  return (): Promise<boolean> => {
    return lastValueFrom(
      user.loadKeycloakInstance().pipe(
        mergeMap(() => context.isContextReady$()),
        withLatestFrom(kvn.isKvnWithoutAccess$()),
        map(([contextSelected, isKvnWithoutAccess]) => contextSelected && (!isKvnWithoutAccess || user.isHCP())),
        tap((accessible) => appState.setAccess(accessible))
      )
    );
  };
}

export const AOK_COCKPIT_ACCESS_INIT_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: initCockpitAccess,
  deps: [UserService, ContextState, KvnService, AppState],
  multi: true,
};
