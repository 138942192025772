import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { AOK_REQUIRED_TASKS, RequiredTask, RequiredTaskConfig } from '../../schemas';
import { AOK_REQUIRED_TASKS_LISTENER } from './required-tasks.listener';

@NgModule()
export class RequiredTasksModule {
  static forRoot(config: (Type<RequiredTask> | RequiredTaskConfig)[]): ModuleWithProviders<RequiredTasksModule> {
    const initialRedirectProviders = config.map((task) => {
      if (isConfig(task)) {
        return {
          provide: AOK_REQUIRED_TASKS,
          useFactory: provideWithConfig(task.type, task.config),
          deps: [...task.deps],
          multi: true,
        };
      }
      return {
        provide: AOK_REQUIRED_TASKS,
        useClass: task,
        multi: true,
      };
    });
    return {
      ngModule: RequiredTasksModule,
      providers: [AOK_REQUIRED_TASKS_LISTENER, ...initialRedirectProviders],
    };
  }
}

const isConfig = (obj: Type<RequiredTask> | RequiredTaskConfig): obj is RequiredTaskConfig => {
  return 'type' in obj && 'config' in obj;
};

const provideWithConfig = (type: Type<RequiredTask>, config: unknown) => {
  return (...deps: unknown[]) => {
    // provide config as the first dependency of the type
    const allDeps: unknown[] = [config, ...deps];
    return createInstance(type, allDeps);
  };
};

const createInstance = (constructor: new (...args: unknown[]) => RequiredTask, deps: unknown[]): RequiredTask => {
  return new constructor(...deps);
};
