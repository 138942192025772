import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { A11yModule } from '@angular/cdk/a11y';
import { A11yUtilsModule, AokDocumentsClient, DialogBase, DialogRef } from '@aok/common';
import { AokDialogLayoutModule } from '../../dialog-layout/dialog-layout.module';

@Component({
  selector: 'aok-card-reader-download-dialog',
  templateUrl: './card-reader-download-dialog.component.html',
  styleUrls: ['./card-reader-download-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [A11yModule, A11yUtilsModule, AokDialogLayoutModule],
})
export class AokCardReaderDownloadDialog implements OnDestroy, DialogBase<boolean> {
  public appDownloadUrl = '/api/documents/download/card-reader.msi';
  public manualDownloadUrl = '/api/documents/download/user-manual.pdf';

  private onDestroy = new Subject<void>();

  constructor(private documentsClient: AokDocumentsClient, public dialogRef: DialogRef<boolean>) {}

  ngOnDestroy(): void {
    this.onDestroy.unsubscribe();
  }

  public downloadAppInstaller(): void {
    this.documentsClient.downloadDocumentByName('card-reader.msi').subscribe();
  }

  public downloadUserManual(): void {
    this.documentsClient.downloadDocumentByName('user-manual.pdf').subscribe();
  }

  public startCardReaderApp(): void {
    this.dialogRef.dispose(true);
  }

  public cancel(): void {
    this.dialogRef.dispose(false);
  }
}
