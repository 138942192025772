import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AokStepComponent } from '../step.component';

@Component({
  selector: 'aok-stepper-carousel',
  templateUrl: './stepper-carousel.component.html',
  styleUrls: ['./stepper-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AokStepperCarouselComponent implements AfterViewInit {
  @Input() public steps: QueryList<AokStepComponent>;
  @Output() public selectStep = new EventEmitter<AokStepComponent>();
  @ViewChild('stepsCarousel') stepsCarousel: ElementRef;

  public currentSlide = 0;
  public nrOfStepsDisplayed: number;
  private player: AnimationPlayer;
  private itemWidth = 184;
  private itemSeparatorWidth = 50;
  private timing = '250ms ease-in';

  constructor(private builder: AnimationBuilder, private cd: ChangeDetectorRef) {}
  public ngAfterViewInit(): void {
    setTimeout(() => {
      const carouselWidth = this.stepsCarousel.nativeElement.getBoundingClientRect().width;
      this.nrOfStepsDisplayed = Math.round(
        (carouselWidth + this.itemSeparatorWidth) / (this.itemWidth + this.itemSeparatorWidth)
      );
      this.cd.detectChanges();
    });
  }

  public carouselBack(): void {
    if (this.currentSlide === 0) return;

    this.currentSlide--;
    const offset = this.currentSlide * (this.itemWidth + this.itemSeparatorWidth);

    const animation: AnimationFactory = this.buildAnimation(offset);
    this.player = animation.create(this.stepsCarousel.nativeElement);
    this.player.play();
  }

  public carouselNext(): void {
    const carouselWidth = this.stepsCarousel.nativeElement.getBoundingClientRect().width;
    this.nrOfStepsDisplayed = Math.round(
      (carouselWidth + this.itemSeparatorWidth) / (this.itemWidth + this.itemSeparatorWidth)
    );
    if (this.currentSlide >= this.steps.length - this.nrOfStepsDisplayed) return;
    this.currentSlide++;
    const offset = this.currentSlide * (this.itemWidth + this.itemSeparatorWidth);
    const animation: AnimationFactory = this.buildAnimation(offset);
    this.player = animation.create(this.stepsCarousel.nativeElement);
    this.player.play();
  }

  private buildAnimation(offset): AnimationFactory {
    return this.builder.build([animate(this.timing, style({ transform: `translateX(-${offset}px)` }))]);
  }
}
