import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { A11yUtilsModule, AokSvgIconComponent, RouterLinkOrHrefModule } from '@aok/common';
import { AokA11yLinksComponent } from './a11y-links.component';

const declarations = [AokA11yLinksComponent];

@NgModule({
  declarations,
  exports: declarations,
  imports: [AokSvgIconComponent, CommonModule, RouterModule, RouterLinkOrHrefModule, A11yUtilsModule],
})
export class AokA11yLinksModule {}
