<ng-container *ngIf="contextState.getOrg$() | async as org">
  <div class="aok-container description">
    <h5>{{ activeLabels.title }}</h5>
    <span>{{ activeLabels.description }}</span>

    <aok-svg-icon
      id="close"
      size="20px"
      aokFocusable
      (keyup.enter)="closeBSNRList.emit()"
      (click)="closeBSNRList.emit()"
    ></aok-svg-icon>
  </div>

  <div class="aok-container context-container" [ngClass]="{ 'tw-pb-3': kvnState.isKvnPracticeTeam() }">
    <ng-container *ngFor="let option of options">
      <ng-container
        *ngTemplateOutlet="
          isAssistantType(mode) ? assistantOptionTemplate : doctorOptionTemplate;
          context: { $implicit: option }
        "
      >
      </ng-container>
    </ng-container>
  </div>

  <div class="aok-container actions">
    <span>
      {{ activeLabels.addPracticeDescription }}
    </span>

    <button class="secondary" (click)="openAddPracticeDialog()">
      {{ activeLabels.addPracticeButton }}
    </button>
  </div>
</ng-container>

<ng-template #assistantOptionTemplate let-option>
  <aok-org-context-select-item
    [headline]="'BSNR ' + option.organizationData.bsnr + ' | ' + option.organizationData.name"
    [label]="option.userData | aokFullName : 'TT LL, FF' : option.userData.practitionerResource"
    iconName="0287-user"
    [isSelected]="isSelected(option)"
    (clickEmitter)="selectAssistantContext(option)"
    (enterEmitter)="selectAssistantContext(option)"
  ></aok-org-context-select-item>
</ng-template>

<ng-template #doctorOptionTemplate let-option>
  <aok-org-context-select-item
    [headline]="'BSNR ' + option.organizationData?.bsnr + ' | ' + option.organizationData?.name"
    [label]="option.organizationData?.address | aokFullAddress : 'STN SN'"
    [iconName]="isSelected(option) ? 'check-circle' : '0000-hospital'"
    [isSelected]="isSelected(option)"
    (clickEmitter)="selectDoctorContext(option)"
    (enterEmitter)="selectDoctorContext(option)"
  ></aok-org-context-select-item>
</ng-template>
