import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  InjectionToken,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { ArrayBehaviorState, isAbsoluteURL, normalizeUrl } from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { AokA11yLink } from '../../schema';

export const AOK_A11Y_LINKS = new InjectionToken<AokA11yLink[]>('AOK_A11Y_LINKS');

@Component({
  selector: 'aok-a11y-links',
  styleUrls: ['./a11y-links.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './a11y-links.component.html',
})
export class AokA11yLinksComponent {
  readonly links = new ArrayBehaviorState<AokA11yLink>();

  @Input() absolute = false;

  constructor(
    @Inject(DOCUMENT) protected document: /* @dynamic */ Document,
    @Optional() @Inject(AOK_A11Y_LINKS) links: /* @dynamic */ AokA11yLink[],
    @Optional() readonly keycloak?: KeycloakService
  ) {
    if (links != null) this.links.reset(...links);
  }

  public resolveLinkUrl(link: AokA11yLink): string {
    const { url } = link;
    if (this.absolute && !isAbsoluteURL(url)) {
      return normalizeUrl(this.document.baseURI, url);
    }

    if (!this.keycloak?.getKeycloakInstance()?.authenticated) {
      return `/publik${url}`;
    }

    return url;
  }
}
