import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputFieldPrefixDirective, InputFieldSuffixDirective } from '../../../directives';

@Component({
  selector: 'aok-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AokInputFieldComponent implements AfterContentInit, AfterViewInit, OnDestroy {
  public elementId: string | null;
  @ViewChild('contentWrapper')
  contentWrapper: ElementRef;
  @Input() label: string | null;
  protected readonly ngDestroys = new Subject<void>();

  @ContentChildren(InputFieldPrefixDirective, { descendants: true })
  protected prefix: QueryList<InputFieldPrefixDirective>;
  @ContentChildren(InputFieldSuffixDirective, { descendants: true })
  protected suffix: QueryList<InputFieldPrefixDirective>;
  private _required = false;
  private _disableHeader = false;

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  get required(): boolean {
    return this._required;
  }

  @Input()
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  get disableHeader(): boolean {
    return this._disableHeader;
  }

  @Input()
  set disableHeader(value: boolean) {
    this._disableHeader = coerceBooleanProperty(value);
  }

  get hasPrefix(): boolean {
    return this.prefix != null && this.prefix.length > 0;
  }

  get hasSuffix(): boolean {
    return this.suffix != null && this.suffix.length > 0;
  }

  ngAfterContentInit(): void {
    merge(this.prefix.changes, this.suffix.changes)
      .pipe(takeUntil(this.ngDestroys))
      .subscribe(() => this.changeDetectorRef.markForCheck());
  }

  ngAfterViewInit(): void {
    this.elementId = this.getFirstContentElementId(this.contentWrapper.nativeElement);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  private getFirstContentElementId(element: Element): string | null {
    if (element?.id) {
      return element.id;
    }

    for (const child of Array.from(element?.children || [])) {
      const childId = this.getFirstContentElementId(child);
      if (childId) {
        return childId;
      }
    }

    return null;
  }
}
