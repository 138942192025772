<aok-alert [closable]="closable" class="{{ accent }}" (closed)="onClose()">
  <aok-svg-icon [name]="bannerIcon" size="20px"></aok-svg-icon>
  <div>
    <h6 *ngIf="!!bannerHeadline">{{ bannerHeadline }}</h6>
    <div [innerHTML]="bannerLabel"></div>
    <div *ngIf="bannerLinks && bannerLinks.length" class="links tw-flex tw-flex-wrap tw-gap-x-7">
      <div *ngFor="let link of bannerLinks">
        <a *ngIf="link.routerLink" class="secondary" [routerLink]="link.routerLink">{{ link.label }}</a>
        <a
          *ngIf="link.clickHandler"
          class="aok-link-1 secondary"
          aokFocusable
          (keyup.enter)="link.clickHandler()"
          (click)="link.clickHandler()"
          >{{ link.label }}</a
        >
      </div>
    </div>
  </div>
</aok-alert>
