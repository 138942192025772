import { Pipe, PipeTransform } from '@angular/core';
import { computeValue, toDisplay } from '../../utils';

export type Unit = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';

@Pipe({
  name: 'aokTimeSince',
})
export class AokTimeSincePipe implements PipeTransform {
  transform(date: number | string | Date, referenceDate?: number | string | Date, startAt: Unit = 'second'): string {
    const parsedDate = new Date(date);
    const now = referenceDate ? new Date(referenceDate) : new Date();
    const valueInfo = computeValue(parsedDate, now, startAt);

    return toDisplay(valueInfo.value, valueInfo.unit);
  }
}
