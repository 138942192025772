import { NgModule } from '@angular/core';
import { AokArticleTeaserListComponent } from './article-teaser-list.component';
import { CommonModule } from '@angular/common';
import { AokArticleTeaserModule } from './article-teaser/article-teaser.module';

@NgModule({
  declarations: [AokArticleTeaserListComponent],
  exports: [AokArticleTeaserListComponent],
  imports: [CommonModule, AokArticleTeaserModule],
})
export class ArticleTeaserListModule {}
