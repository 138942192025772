import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AokApiBaseClient } from './api-base.client';
import { downloadBlob } from '../utils';

export const DOCUMENTS_API_URL = new InjectionToken<string>('DOCUMENTS_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/documents',
});

@Injectable({ providedIn: 'root' })
export class AokDocumentsClient extends AokApiBaseClient {
  constructor(@Inject(DOCUMENTS_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  public downloadDocumentByName(documentName: string): Observable<Blob> {
    return this.downloadDocumentBlob(documentName).pipe(
      tap((file) => {
        downloadBlob(file, documentName);
      })
    );
  }

  private downloadDocumentBlob(documentName: string): Observable<Blob> {
    return this.http.get(this.resolveUrl('download', documentName), {
      responseType: 'blob',
    });
  }
}
