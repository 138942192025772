import { Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { FormErrorDetectorRef } from '@aok/common';

// TODO check how it should be used automatically with buttons type=submit
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'button[type=submit], [formSubmit]' })
export class FormSubmitDirective implements OnDestroy {
  protected readonly unlistenElementClicks: () => void;

  constructor(
    protected errorDetectorRef: FormErrorDetectorRef,
    protected elementRef: ElementRef,
    protected renderer: Renderer2
  ) {
    this.unlistenElementClicks = renderer.listen(elementRef.nativeElement, 'click', this.onClick.bind(this));
  }

  ngOnDestroy(): void {
    this.unlistenElementClicks();
  }

  onClick(): void {
    this.errorDetectorRef.detectErrors();
  }
}
