<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>

<aok-cockpit-header-page-headline
  [headline]="msgHeader"
  [hint]="msgHeaderHint"
  [actionButton]="showContactRequest ? msgHeaderButton : null"
  (actionClicked)="openContactForm()"
></aok-cockpit-header-page-headline>

<ng-container *ngIf="hasReports() && !isKvnPracticeTeam">
  <div class="aok-container has-reports">
    <aok-tabs>
      <ng-container *ngFor="let category of categoriesOrder">
        <aok-tab
          *ngIf="enabledCategories[category]"
          class="tw-flex tw-items-center tw-justify-center"
          [routerLink]="[category]"
          (keyup.enter)="router.navigate([category], { relativeTo: route })"
          [class.disabled]="hasNoReports(category)"
          routerLinkActive="active"
        >{{ categoryLabels[category] }}
        </aok-tab>
      </ng-container>
    </aok-tabs>

    <router-outlet></router-outlet>
  </div>
</ng-container>

<div class="aok-bg-sand" *ngIf="!hasReports() && !isKvnPracticeTeam">
  <div class="aok-container">
    <h5 class="tw-text-green">{{ noReportsHeadline }}</h5>
  </div>
</div>

<div class="aok-bg-sand kvn-no-reports" *ngIf="isKvnPracticeTeam">
  <div class="aok-container">
    <aok-svg-icon class="tw-col-1-13" name="0859-cross_circle" size="60px"></aok-svg-icon>

    <h3 class="tw-col-1-13 tw-mt-7">Sie können keine Berichte einsehen</h3>
    <p class="tw-mt-8 tw-col-1-13">
      Praxismitarbeiter, die über das KVN-Portal Zugang zum Arztportal haben, können keine Berichte einsehen. Um auf
      Berichte zugreifen zu können, müssen Sie von einem Arzt als eigenständiger Nutzer ins Arztportal eingeladen
      werden.
    </p>
  </div>
</div>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
