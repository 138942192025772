import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AokLoadingStateService,
  AokUserClient,
  DialogBase,
  DialogRef,
  email,
  isString,
  matchFormFieldsValidator,
  NotificationBannerLink,
  scrollToElement,
  scrollToTop,
} from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'aok-resend-email-dialog',
  templateUrl: './resend-email-dialog.component.html',
  styleUrls: ['./resend-email-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AokResendEmailDialog implements DialogBase<boolean> {
  public errorIds: NotificationBannerLink[] = [];
  public bannerLabel = 'Bitte überprüfen Sie Ihre Eingabe.';
  public bannerError = null;

  public form = new UntypedFormGroup(
    {
      email: new UntypedFormControl(null, [Validators.required, email], []),
      newEmail: new UntypedFormControl(null, [Validators.required, email], []),
    },
    {
      validators: matchFormFieldsValidator('email', 'newEmail'),
    }
  );

  public label = {
    email: 'E-Mail-Adresse',
    newEmail: 'E-Mail-Adresse wiederholen',
  };

  constructor(
    public readonly dialogRef: DialogRef<boolean>,
    protected client: AokUserClient,
    protected keycloak: KeycloakService,
    protected loadingOverlay: AokLoadingStateService
  ) {}

  public submitDialog(): void {
    if (this.form.invalid) {
      this.handleErrors();
    } else {
      this.errorIds = [];

      const { newEmail } = this.form.value;

      this.loadingOverlay.attach();

      this.client
        .updateEmail(newEmail)
        .pipe(
          catchError(() => {
            this.loadingOverlay.detach();

            return EMPTY;
          })
        )
        .subscribe(() => {
          this.dialogRef.dispose(true);
          this.loadingOverlay.detach();
          location.reload();
        });
    }
  }

  private handleErrors(): void {
    scrollToTop();

    const controls = Object.keys(this.form.controls).map((key) => {
      this.form.get(key).updateValueAndValidity();
      return key;
    });

    this.errorIds = controls
      .filter((key) => this.form.get(key).invalid)
      .map((key) => {
        let label: string;
        if (isString(this.label?.[key])) label = this.label?.[key] as string;
        else label = this.label[key].alternative as string;
        return {
          label,
          clickHandler: () => scrollToElement(key),
        } as NotificationBannerLink;
      }, this.label);
  }
}
