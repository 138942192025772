import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AokConsentClient,
  AokStaticArticleRoute,
  AppLoadingStateEnum,
  AppState,
  DialogOverlay,
  getParsableQueryParams,
  RequiredTask,
} from '@aok/common';
import { from, Observable, of } from 'rxjs';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { AokConsentDialog } from '../../components';
import { ConsentState } from '../../states';

@Injectable()
export class ConsentChangesTaskService implements RequiredTask {
  constructor(
    private router: Router,
    private consentClient: AokConsentClient,
    private dialog: DialogOverlay,
    private consentState: ConsentState,
    private appState: AppState
  ) {}

  public trigger(): Observable<boolean> {
    return this.shouldTakeAction().pipe(
      mergeMap((redirect) => {
        if (redirect) {
          this.redirect().subscribe();
          return this.consentState.latestConsentAccepted$().pipe(
            filter((latestConsentAccepted) => latestConsentAccepted),
            first()
          );
        } else {
          return of(true);
        }
      })
    );
  }

  private redirect(): Observable<boolean> {
    const queryParams = getParsableQueryParams();

    return from(
      this.router.navigate(['aenderungen', AokStaticArticleRoute.Legal], {
        queryParams: {
          // default redirect after context selection if no other redirect is specified
          // we are using slice to remove / from location.pathname
          redirectTo: location.pathname.slice(1) || 'uebersicht',
          ...queryParams,
        },
      })
    ).pipe(
      tap((navigationResult) => {
        if (navigationResult) {
          this.appState.setLoadingState(AppLoadingStateEnum.LOADED);
        }
      })
    );
  }

  private shouldTakeAction(): Observable<boolean> {
    return this.consentClient.list().pipe(
      tap((consents) => {
        this.consentState.reset(...consents);
      }),
      mergeMap((consents) => {
        if (consents.length) {
          // should only be at most one consent
          return this.consentState.latestConsentAccepted$().pipe(
            first(),
            map((latestConsentAccepted) => !latestConsentAccepted)
          );
        } else {
          this.dialog.create(AokConsentDialog).subscribe();
          return of(false);
        }
      })
    );
  }
}
