import { Pipe, PipeTransform } from '@angular/core';
import { AOK_STATIC_ARTICLE_KEY_ROUTE_MAPPING, AokStaticArticleKey, AokStaticArticleRoute } from '../../schemas';
import { getStaticArticleTitle } from '../../utils';

@Pipe({ name: 'aokStaticArticleTitle', standalone: true })
export class AokStaticArticleTitleIntlPipe implements PipeTransform {
  transform(route: AokStaticArticleRoute | string): string {
    const key = AOK_STATIC_ARTICLE_KEY_ROUTE_MAPPING.find((mapping) => mapping.route === route)
      ?.key as AokStaticArticleKey;
    return getStaticArticleTitle(key);
  }
}
