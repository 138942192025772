<div *ngIf="_path?.length" class="tw-flex tw-flex-wrap tw-items-center tw-gap-8">
  <ng-container *ngIf="!!homeSite">
    <a class="aok-link-1 secondary" aokFocusable (click)="navigateHome()" (keyup.enter)="navigateHome()">
      <aok-svg-icon id="home" size="16px" title="Zurück zur Übersicht"></aok-svg-icon>
    </a>
  </ng-container>

  <ng-container *ngFor="let siteSegment of _path; let last = last">
    <aok-svg-icon name="arrow-right" size="16px"></aok-svg-icon>

    <a
      class="secondary"
      *ngIf="!isStaticBreadcrumb(last, siteSegment); else staticBreadcrumb"
      [routerLink]="siteSegment.linkUrl"
    >
      {{ siteSegment?.title }}
    </a>

    <ng-template #staticBreadcrumb>
      <span class="static-breadcrumb">
        {{ siteSegment?.title }}
      </span>
    </ng-template>
  </ng-container>
</div>
