import { AokPatientDMPData } from './dmp';

export interface AokServiceRequest extends AokInsureeData, AokInsureeAddressData, AokPaymentConsentFields {
  insuranceDataRequestTypeList?: string[];
  lanr?: string;
}

interface AokInsureeData {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  insuranceNumber?: string;
}

interface AokInsureeAddressData {
  zip?: string;
  street?: string;
  streetNumber?: string;
  city?: string;
}

interface AokPaymentConsentFields {
  checkboxIdentityCheck: boolean;
}

export const enum AokInsuranceStatus {
  Member = 'MEMBER',
  FamilyMember = 'FAMILYMEMBER',
  SocialCare = 'SOCIAL_CARE',
  Pensioner = 'PENSIONER',
  SupervisedBVG = 'SUPERVISED_BVG',
  SupervisedDomestic = 'SUPERVISED_DOMESTIC',
}

export enum AokBillingSheetTypeEnum {
  PrePrinted = 'PRE_PRINTED',
  FullPrint = 'FULL_PRINT',
}

export enum AokPatientSearchTypeEnum {
  Address = 'ADDRESS',
  Insurance = 'INSURANCE',
  InsuranceCard = 'INSURANCE_CARD',
}

export enum AokServiceCategory {
  BillingSheet = 'Abrechnungsschein',
  BatchPrinting = 'Sammeldruck von Abrechnungsscheinen',
  PaymentExemption = 'Status Zuzahlungsbefreiung',
  DmpStatus = 'DMP-Statusabfrage',
  CareLevel = 'Pflegegradabfrage',
  MembershipStatus = 'Mitgliedschaftsstatus',
}

export interface AokInsuree extends AokInsureeAddressData, AokInsureeData {
  readonly id: string;
  gender: any;
  institutionId: string;
  institutionName: string;
  insuranceStatus: AokInsuranceStatus;
  invoiceDate: any;
  lastSynced: string;
  source: string; // import_file.uuid

  // careLevel?: number;
  // exempted?: boolean;
}

export interface AokBillingSheetRequest {
  bsnr: string;
  lanr: string;
  invoiceDate: string;
  type?: AokBillingSheetTypeEnum;
}

export interface AokBatchPrintingRequest {
  batchPrintInput: string;
  identityCheck: boolean;
}

export interface AokBatchPrintingResponse {
  insurees: AokBatchPrintingEntry[];
  errors: AokBatchPrintingEntry[];
}

export interface AokBatchPrintingEntry extends AokServiceRequest {
  id: number;
  errorSolved?: boolean;
  removeFromPrinting?: boolean;
  uniqueId?: number;
}

export interface AokPrintRequest {
  bsnr: string;
  lanr: string;
  type: string;
  insureeIds: number[];
}

export interface AokInsuranceStatusResponse {
  insuree: AokInsuree;
  dmpResponse: AokPatientDMPData;
  careLevelResponse: { careLevel: boolean };
  exemptionResponse: { exempted: boolean };
}

export enum INSURANCE_DATA_REQUEST_TYPES {
  dmpStatus = 'DMP_STATUS',
  careLevel = 'DMP_CARE_LEVEL',
  paymentExemption = 'EXEMPTION',
  membershipStatus = 'INSURANCE',
}
