import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AokArticle } from '@aok/common';

@Component({
  selector: 'aok-article-teaser-list',
  styleUrls: ['./article-teaser-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div [ngClass]="horizontal ? '' : 'aok-container'">
      <aok-article-teaser
        [ngClass]="{ bordered: horizontal, placeholder: isNullImage(article) && horizontal }"
        *ngFor="let article of articles"
        [horizontal]="horizontal"
        [article]="article"
      >
      </aok-article-teaser>
    </div>
  `,
})
export class AokArticleTeaserListComponent {
  @Input() articles: AokArticle[];

  @Input() horizontal = false;

  public isNullImage(article: AokArticle): boolean {
    return !(article?.imageUrl || article?.thumbnailUrl);
  }
}
