import { ModuleWithProviders, NgModule } from '@angular/core';

import { ActivatedRouteSnapshot, TitleStrategy } from '@angular/router';
import { AOK_TITLE_STRATEGY_FALLBACK_RESOLVER, AokTitleStrategyConfig, TITLE_PREFIX } from '../../schemas';
import { AokTitleStrategyService } from './title-strategy.service';

function resolveRouteTitleFallback(snapshot: ActivatedRouteSnapshot, paramKey: string, title: string): string {
  return title?.[snapshot?.params?.[paramKey]];
}

@NgModule({})
export class AokTitleStrategyModule {
  static withConfig(config: AokTitleStrategyConfig): ModuleWithProviders<AokTitleStrategyModule> {
    return {
      ngModule: AokTitleStrategyModule,
      providers: [
        { provide: TitleStrategy, useClass: AokTitleStrategyService },
        {
          provide: AOK_TITLE_STRATEGY_FALLBACK_RESOLVER,
          useValue: resolveRouteTitleFallback,
        },
        { provide: TITLE_PREFIX, useValue: config.titlePrefix },
      ],
    };
  }
}
