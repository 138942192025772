import { APP_INITIALIZER, InjectionToken, Provider } from '@angular/core';
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';

export const KEYCLOAK_OPTIONS = new InjectionToken<KeycloakOptions>('KEYCLOAK_OPTIONS');

export function createKeycloakInitializer(keycloak: KeycloakService, options: KeycloakOptions): () => Promise<unknown> {
  return () => keycloak.init(options);
}

// not currently in use due to pages that need to be public
export const KEYCLOAK_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  useFactory: createKeycloakInitializer,
  deps: [KeycloakService, KEYCLOAK_OPTIONS],
  multi: true,
};
