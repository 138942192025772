<h4 class="tw-mb-7">{{ headLine }}</h4>

<table
  aria-describedby="Versichertendetails"
  *ngIf="batchPrintingData?.length >= 1"
  class="aok"
  cdk-table
  [dataSource]="visibleBatchPrintingItems"
  [trackBy]="batchEntriesTrackByFn"
>
  <ng-container *ngFor="let column of displayColumns; trackBy: columnsTrackByFn">
    <ng-container [cdkColumnDef]="column.id">
      <th cdk-header-cell *cdkHeaderCellDef>
        {{ column.label }}
      </th>

      <td cdk-cell *cdkCellDef="let element">
        <ng-container [ngSwitch]="column.id">
          <ng-container *ngSwitchCase="'name'">
            <p>{{ element?.firstName }} {{ element?.lastName }}</p>
          </ng-container>

          <ng-container *ngSwitchCase="'address'">
            {{ element | aokFullAddress }}
          </ng-container>

          <ng-container *ngSwitchCase="'dateOfBirth'">
            {{ element?.dateOfBirth | date : 'dd.MM.yyyy' }}
          </ng-container>

          <ng-container *ngSwitchCase="'insuranceNumber'">
            <p>{{ element?.insuranceNumber }}</p>
          </ng-container>

          <ng-container *ngSwitchCase="'options'">
            <ng-container *ngIf="tableName === 'error-list'; else printListActionButtons">
              <div class="options">
                <a
                  aokFocusable
                  class="aok-link-1 action-link"
                  (click)="openPatientSearchDialog(element)"
                  (keyup.enter)="openPatientSearchDialog(element)"
                >
                  {{ column.label }}
                </a>

                <aok-svg-icon
                  class="icon-button"
                  title="Patient entfernen"
                  id="close"
                  size="16"
                  aokFocusable
                  (click)="updateRow.emit(element?.uniqueId)"
                  (keyup.enter)="updateRow.emit(element?.uniqueId)"
                ></aok-svg-icon>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #printListActionButtons>
          <div class="options">
            <p>
              {{ element?.[column.id] }}
            </p>

            <ng-container *ngIf="element?.removeFromPrinting; else removeFromPrintTemplate">
              <aok-svg-icon
                class="icon-button medium-size"
                title="Abrechnungsschein drucken"
                id="0419-undo"
                size="20"
                aokFocusable
                (click)="updateRow.emit(element?.uniqueId)"
                (keyup.enter)="updateRow.emit(element?.uniqueId)"
              ></aok-svg-icon>
            </ng-container>

            <ng-template #removeFromPrintTemplate>
              <aok-svg-icon
                class="icon-button medium-size"
                title="Abrechnungsschein nicht drucken"
                id="no-print"
                size="20"
                aokFocusable
                (click)="updateRow.emit(element?.uniqueId)"
                (keyup.enter)="updateRow.emit(element?.uniqueId)"
              ></aok-svg-icon>
            </ng-template>
          </div>
        </ng-template>
      </td>
    </ng-container>
  </ng-container>

  <tr cdk-header-row *cdkHeaderRowDef="getDisplayedColumnIds"></tr>
  <tr
    cdk-row
    *cdkRowDef="let row; columns: getDisplayedColumnIds"
    [ngClass]="{
      'text-muted': row?.removeFromPrinting,
      'aok-bg-sand': row?.errorSolved && !row?.removeFromPrinting
    }"
  ></tr>
</table>

<div class="pagination" *ngIf="batchPrintingData?.length > pageSize">
  <aok-pagination
    [activePage]="activePage + 1"
    [totalItems]="batchPrintingData?.length"
    [pageSize]="pageSize"
    [isFrontendSidePagination]="true"
    (pageChange)="pageChange($event)"
  >
  </aok-pagination>
</div>
