import { NgModule } from '@angular/core';
import { AokFullNamePipe } from './full-name.pipe';

// TODO transform in standalone pipe
@NgModule({
  declarations: [AokFullNamePipe],
  exports: [AokFullNamePipe],
  providers: [AokFullNamePipe],
})
export class AokFullNamePipeModule {}
