<ng-container *ngIf="benefits._embedded?.items?.length; else noBenefits">
  <div class="aok-container">
    <div class="tw-col-1-13">
      <aok-cockpit-benefit-accordion
        *ngFor="let benefit of benefits._embedded?.items; trackBy: trackByFn"
        [benefit]="benefit"
        [favoriteEnabled]="isFavoriteEnabled"
        (favorite)="favorite.emit($event)"
      ></aok-cockpit-benefit-accordion>

      <div class="tw-flex tw-justify-center">
        <aok-pagination
          *ngIf="benefits?.page?.totalPages > 1"
          [activePage]="$any(benefits.page.number)"
          [pageSize]="benefits.page.size"
          [totalItems]="benefits.page.totalElements"
          [totalPages]="benefits.page.totalPages"
          routeQueryParam="page"
        >
        </aok-pagination>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noBenefits>
  <div class="aok-bg-sand tw-mt-3">
    <div class="aok-container">
      <h5 class="tw-col-3-11 tw-mt-3 tw-text-green">{{ noBenefitsTitle }}</h5>
      <p class="tw-col-3-11 tw-mt-8 tw-mb-3">{{ noBenefitsMessage }}</p>
    </div>
  </div>
</ng-template>
