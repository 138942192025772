import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactRequestSchema } from '../schemas/contact/contact-request.schema';
import { AokApiBaseClient } from './api-base.client';

export const AOK_CONTACT_API_URL = new InjectionToken<string>('AOK_CONTACT_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/email/contact/report',
});

@Injectable({ providedIn: 'root' })
export class AokContactClient extends AokApiBaseClient {
  constructor(@Inject(AOK_CONTACT_API_URL) readonly baseUrl: string, protected httpClient: HttpClient) {
    super(baseUrl, httpClient);
  }

  public submit(contactRequest: ContactRequestSchema): Observable<void> {
    return this.httpClient.post<void>(this.resolveUrl(), contactRequest);
  }
}
