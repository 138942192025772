import { NgModule } from '@angular/core';
import { TabComponent } from './tab.component';
import { TabsComponent } from './tabs.component';
import { A11yUtilsModule } from '@aok/common';

const declarations = [TabsComponent, TabComponent];

@NgModule({
  declarations,
  imports: [A11yUtilsModule],
  exports: declarations,
})
export class AokTabsModule {}
