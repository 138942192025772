import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import {
  AokArticle,
  AokArticleClient,
  AokArticleType,
  AokKnownArticleCategoryNames,
  AokKnownArticleCategoryRoute,
} from '@aok/common';
import { map } from 'rxjs/operators';

const AOK_KNOWN_ARTICLE_CATEGORY_NAME_ROUTE_MAPPING = [
  {
    route: AokKnownArticleCategoryRoute.GeneralInformation,
    name: AokKnownArticleCategoryNames.GeneralInformation,
  },
  {
    route: AokKnownArticleCategoryRoute.PortalUsage,
    name: AokKnownArticleCategoryNames.PortalUsage,
  },
];

export const helpResolver: ResolveFn<AokArticle> = (route: ActivatedRouteSnapshot) => {
  const client = inject(AokArticleClient);

  const { category } = route.params;
  const categoryName = AOK_KNOWN_ARTICLE_CATEGORY_NAME_ROUTE_MAPPING.find(
    (mapping) => mapping.route === category
  )?.name;

  return client
    .list({ category: categoryName, type: AokArticleType.SecuredImprint, size: 1 })
    .pipe(map((page) => (page._embedded?.items || page.content)?.[0]));
};
