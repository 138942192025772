import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { A11yUtilsModule, AokSvgIconComponent, RouterLinkOrHrefModule } from '@aok/common';
import { AokToTopScrollerModule } from '../cdk';
import { FeedbackButtonModule } from '../feedback-button/feedback-button.module';
import { AokFooterWrapperComponent } from './footer-wrapper/footer-wrapper.component';
import { AokFooterComponent } from './footer.component';

const declarations = [AokFooterComponent, AokFooterWrapperComponent];

@NgModule({
  declarations,
  exports: declarations,
  imports: [
    CommonModule,
    AokSvgIconComponent,
    AokToTopScrollerModule,
    FeedbackButtonModule,
    RouterModule,
    RouterLinkOrHrefModule,
    A11yUtilsModule,
  ],
})
export class AokFooterModule {}
