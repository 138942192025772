import {
  Attribute,
  Directive,
  ElementRef,
  Inject,
  InjectionToken,
  Injector,
  Input,
  Optional,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { RouterLinkOrHrefDirective } from '../../directives';
import { NavigationEntry } from './navigation-entry.schema';
import { isString, StringInterpolator, URL_PARAM_INTERPOLATION_SCHEME } from '../../utils';

export const NAVIGATION_LINK_URL_PARAMS = new InjectionToken<Params>('NAVIGATION_LINK_URL_PARAMS');

@Directive({
  selector: '[aokNavEntryLink]',
  providers: [{ provide: RouterLink, useExisting: NavigationEntryLinkDirective }],
})
export class NavigationEntryLinkDirective extends RouterLinkOrHrefDirective {
  private _entry: NavigationEntry;

  constructor(
    // see {@link RouterLinkOrHrefDirective} comment...
    protected router2: Router,
    protected route2: ActivatedRoute,
    renderer: Renderer2,
    protected element: ElementRef,
    protected sanitizer: DomSanitizer,
    protected injector: Injector,
    protected stringInterpolator: StringInterpolator,
    @Inject(WINDOW) protected window: /* @dynamic */ Window,
    @Attribute('tab-index') tabIndex: string,
    @Optional()
    @Inject(NAVIGATION_LINK_URL_PARAMS)
    readonly linkUrlParams?: unknown
  ) {
    super(router2, route2, renderer, element, sanitizer, window, tabIndex);
  }

  get entry(): NavigationEntry {
    return this._entry;
  }

  @Input('aokNavEntryLink')
  set entry(value: NavigationEntry) {
    if (isString(value?.linkUrl)) {
      this.routerLinkOrHref = this.interpolateLinkUrl(value?.linkUrl);
    } else {
      this.routerLinkOrHref = value?.linkUrl;

      if (value?.linkUrl != null)
        this.routerLinkOrHref = this.interpolateLinkUrl(this.router2.serializeUrl(this.urlTree));
    }

    this._entry = value;
  }

  interpolateLinkUrl(value: string): string {
    return this.stringInterpolator.interpolate(value, this.linkUrlParams, URL_PARAM_INTERPOLATION_SCHEME);
  }

  onClick(button: number, ctrlKey: boolean, shiftKey: boolean, altKey: boolean, metaKey: boolean): boolean {
    if (typeof this._entry.action === 'function') {
      const depValues = Object.values(this._entry.deps || {});
      const deps = depValues?.map((dep: unknown) => this.injector.get(dep));
      this._entry.action(...(deps || []));
    }
    return super.onClick(button, ctrlKey, shiftKey, altKey, metaKey);
  }
}
