<div [ngClass]="resultBannerBackground" class="banner-container">
  <div class="aok-container">
    <h5 class="tw-col-2-12 lg:tw-col-3-11 tw-mb-8">
      {{ resultBannerHeadline }}
    </h5>
    <p *ngIf="resultBannerHint" class="tw-col-2-12 lg:tw-col-3-11">
      {{ resultBannerHint }}
    </p>
  </div>
</div>

<div *ngIf="showBillingSheetInformation()" class="aok-container billing-sheet-container" id="patientIsAokInsured">
  <div class="billing-sheet-preview-wrapper tw-col-2-8 lg:tw-col-3-8">
    <img
      (load)="previewLoaded = true"
      *ngIf="!showErrorMsg"
      [class.hidden]="!previewLoaded"
      [src]="billingSheetPreview"
      alt="Vorschaubild des Abrechnungsscheins"
      class="billing-sheet-preview"
    />
  </div>
  <h5 class="tw-col-8-12 lg:tw-col-8-11">
    <ng-container *ngTemplateOutlet="printOptionsLabels"></ng-container>
  </h5>
  <p class="tw-col-8-12 lg:tw-col-8-11">
    Der Abrechnungsschein verbleibt danach in Ihrer Praxis und wird dort aufbewahrt.<br /><br />
    Ein Versand an die Krankenkasse ist nicht vorgesehen.<br /><br />
    Die Verfahrensanweisungen zur Abgabe der Quartalsabrechnung sind zu beachten.
  </p>
</div>

<ng-container *ngIf="isAssistant(currentUser.snapshot) && showBillingSheetInformation()">
  <div class="aok-container print-header tw-col-2-12 lg:tw-col-3-11">
    <h5>Druckoptionen</h5>
  </div>
  <ng-container *ngTemplateOutlet="printOptions"></ng-container>
</ng-container>

<ng-container *ngIf="isDoctor(currentUser.snapshot) && showBillingSheetInformation()">
  <div class="aok-container print-form">
    <p class="required-label tw-col-2-12 lg:tw-col-3-11">*Pflichtfeld</p>
    <h5 class="tw-col-2-12 lg:tw-col-3-11">Druckoptionen</h5>
  </div>

  <ng-container *ngTemplateOutlet="printOptions"></ng-container>

  <div class="aok-container print-form">
    <div class="lanr-container tw-col-2-8 lg:tw-col-3-8">
      <aok-input-field label="LANR wählen oder eingeben" required>
        <aok-dropdown [control]="lanrControl" [mode]="dropdownMode" [options]="lanrOptions"></aok-dropdown>
      </aok-input-field>
      <aok-popover [direction]="popoverDirection" [width]="280">
        <p class="aok-bold">LANR nicht gefunden?</p>
        <p>
          Falls die gesuchte LANR nicht in der Auswahl enthalten ist, können Sie diese auch direkt als 9-stellige
          Ziffernfolge in das Feld eingeben.
        </p>
      </aok-popover>
    </div>

    <aok-notification-banner
      *ngIf="displaySpecialization()"
      class="specialization tw-col-2-8 lg:tw-col-3-11"
      [bannerLabel]="specialization"
      bannerHeadline="Fachgruppe"
      iconName="info-circle"
    >
    </aok-notification-banner>
  </div>
</ng-container>

<div *ngIf="showBillingSheetInformation()" class="aok-container print-button-container">
  <div class="tw-col-2-12 lg:tw-col-3-11">
    <button (click)="printBillingSheet()" [disabled]="lanrControl.invalid">Abrechnungsschein drucken</button>
  </div>
</div>

<div [attr.id]="resultTableData?.length ? 'patientFound' : undefined" class="aok-container">
  <aok-cockpit-table-type
    *ngFor="let value of resultTableData; trackBy: resultTableTrackByFn"
    class="tw-col-2-12 lg:tw-col-3-11 tw-mt-3"
    [sectionLabel]="value.sectionLabel"
    [values]="value.table"
  >
  </aok-cockpit-table-type>
</div>

<ng-template #printOptions>
  <aok-radio [formControl]="radioSwitchForm" [options]="switchRadioOptions" class="aok-container"></aok-radio>
  <div class="aok-container">
    <div class="print-info tw-col-2-12 lg:tw-col-3-11">
      <ng-container *ngTemplateOutlet="printOptionsLabels"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #printOptionsLabels>
  <ng-container *ngIf="prePrinted">
    Bitte legen Sie den Vordruck Muster 5 in Ihren Praxisdrucker ein. Der Abrechnungsschein wird direkt darauf gedruckt.
  </ng-container>
  <ng-container *ngIf="!prePrinted">
    Bitte legen Sie Blankopapier in Ihren Praxisdrucker ein. Der Abrechnungsschein wird inklusive des Muster 5
    aufgedruckt.
  </ng-container>
</ng-template>
