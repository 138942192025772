import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Accent,
  AokBsnrRegistrationClient,
  AokRegistrationOrgData,
  AokRegistrationToOrganisation,
  AokToastService,
  ContextState,
  createSimplePracticeForm,
  DialogBase,
  DialogRef,
} from '@aok/common';
import { catchError, EMPTY } from 'rxjs';
import { PracticeFormComponent } from '../../practice-form/practice-form.component';

@Component({
  selector: 'aok-cockpit-add-bsnr-as-doctor-dialog',
  templateUrl: './add-bsnr-as-doctor-dialog.component.html',
  styleUrls: ['./add-bsnr-as-doctor-dialog.component.scss'],
})
export class AokAddBSNRAsDoctorDialog implements OnInit, DialogBase<boolean> {
  @Input() schema?: AokRegistrationToOrganisation;
  @Input() joinedPractices: string[] = [];
  @Input() joinRequest: string[] = [];
  @ViewChild('practiceForm') public practiceForm: PracticeFormComponent;

  public formGroup: FormGroup;

  private toastFailLabelAddPractice = `Die Praxisdaten konnten nicht übermittelt werden. Bitte versuchen Sie es erneut
    oder kontaktieren Sie den Support.`;
  private toastFailHeadlineAddPractice = 'Vorgang fehlgeschlagen';
  private toastInfoHeadlineAlreadyJoined = 'Praxis bereits vorhanden';
  private toastInfoLabelAlreadyJoined = 'Die Praxis wurde Ihnen bereits zugewiesen. Bitte prüfen Sie Ihre Praxisdaten.';
  private toastInfoHeadlineAlreadyRequested = 'Praxis bereits angefragt';
  private toastInfoLabelAlreadyRequested = `Die Praxis wurde bereits von Ihnen angefragt.
    Bitte prüfen Sie Ihre Praxisdaten.`;

  constructor(
    public readonly dialogRef: DialogRef<boolean>,
    private client: AokBsnrRegistrationClient,
    private toastService: AokToastService,
    private contextState: ContextState
  ) {}

  ngOnInit(): void {
    this.formGroup = createSimplePracticeForm(this.schema?.organizationData);
  }

  public submit(): void {
    if (!this.practiceForm.validateForm()) {
      return;
    }

    // TODO create service & move logic to service
    if (this.joinedPractices.some((bsnr) => this.formGroup.get('bsnr').value === bsnr)) {
      this.toastService.createToast(this.toastInfoHeadlineAlreadyJoined, this.toastInfoLabelAlreadyJoined, Accent.INFO);
    } else if (this.joinRequest.some((bsnr) => this.formGroup.get('bsnr').value === bsnr)) {
      this.toastService.createToast(
        this.toastInfoHeadlineAlreadyRequested,
        this.toastInfoLabelAlreadyRequested,
        Accent.INFO
      );
    } else {
      const { bsnr, organizationType, organizationName, street, streetNumber, location, email } = this.formGroup.value;
      const requestObject: AokRegistrationOrgData = {
        bsnr: bsnr,
        organizationType,
        organizationName,
        address: {
          streetName: street,
          streetNumber,
          zipCode: this.formGroup.value.zipCode,
          location,
        },
        email,
        initiatedFromBsnr: this.contextState.bsnr,
        organizationId: this.contextState.orgId,
      };
      // TODO create service & move logic to service
      if (this.schema) {
        this.client
          .update(this.schema.id, requestObject)
          .pipe(
            catchError(() => {
              this.dialogRef.dispose(false);
              this.toastService.createSupportErrorToast(
                this.toastFailHeadlineAddPractice,
                this.toastFailLabelAddPractice
              );

              return EMPTY;
            })
          )
          .subscribe(() => {
            this.dialogRef.dispose(true);
          });
      } else {
        this.client
          .create(requestObject)
          .pipe(
            catchError(() => {
              this.dialogRef.dispose(false);
              this.toastService.createSupportErrorToast(
                this.toastFailHeadlineAddPractice,
                this.toastFailLabelAddPractice
              );

              return EMPTY;
            })
          )
          .subscribe(() => {
            this.dialogRef.dispose(true);
          });
      }
    }
  }
}
