import { Inject, Injectable } from '@angular/core';
import { AppState, ENVIRONMENT, EnvironmentSchema } from '@aok/common';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FeedbackAvailableState {
  public isFeedBackAvailable = new BehaviorSubject<boolean>(false);

  constructor(private appState: AppState, @Inject(ENVIRONMENT) private environment: EnvironmentSchema) {}

  public init(): void {
    this.setState();
  }

  /**
   * Feedback is available if:
   *    cockpit accessible
   *    feedback enabled
   */
  private setState(): void {
    this.appState.isAccessible$
      .pipe(
        distinctUntilChanged(),
        map((isCockpitAccessible) => isCockpitAccessible && this.environment.enableFeedback)
      )
      .subscribe((isFeedbackAvailable) => this.isFeedBackAvailable.next(isFeedbackAvailable));
  }
}
