import {
  AokBaseUser,
  AokPractitionerSpecializationEntry,
  AokPractitionerSpecializations,
  AokUser,
  KnownUserType,
} from '../schemas';

export const createPractitionerSpecializationEntries = (
  specializations: AokPractitionerSpecializations
): AokPractitionerSpecializationEntry[] => {
  return Object.entries(specializations || []).map(([id, description]) => {
    return { id, description };
  });
};

/**
 * check if the user is a doctor type
 */
export const isDoctor = (user: AokBaseUser): boolean => {
  return isDoctorType(user?.userType);
};

/**
 * check if the type is doctor
 */
export const isDoctorType = (userType: KnownUserType): boolean => {
  return userType === KnownUserType.Doctor || isKvnOrFullDoctor(userType);
};

/**
 * check if the user is an assistant type
 */
export const isAssistant = (user: AokUser): boolean => {
  return isAssistantType(user?.userType);
};

/**
 * check if the type is assistant
 */
export const isAssistantType = (userType: KnownUserType): boolean => {
  return [KnownUserType.Assistant, KnownUserType.Kvn_Practice_Team].includes(userType);
};

export const isKvnAssistant = (userType: KnownUserType): boolean => {
  return userType === KnownUserType.Kvn_Practice_Team;
};

export const isPractitionerType = (userType: KnownUserType): boolean => {
  return isAssistantType(userType) || isDoctorType(userType);
};

export const isKvnDoctor = (userType: KnownUserType): boolean => {
  return userType === KnownUserType.Kvn_Doctor;
};

export const isKvnOrFullDoctor = (userType: KnownUserType): boolean => {
  return isKvnDoctor(userType) || isKvnFullDoctor(userType);
};
export const isKvnFullDoctor = (userType: KnownUserType): boolean => {
  return userType === KnownUserType.Full_Kvn_Doctor;
};

export const isAokEmployee = (userType: KnownUserType): boolean => userType === KnownUserType.Employee;
