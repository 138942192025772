import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Aok2FAPatch,
  AokAssistantRegistrationRequest,
  AokChangeableUserRegistrationStatus,
  AokExportStatus,
  AokHttpHeaders,
  AokKvnUserRegistration,
  AokPage,
  AokRegistrationListOptions,
  AokUserRegistration,
  AokUserRegistrationPin,
  AokUserRegistrationStatus,
  UserLanrStatus,
} from '../schemas';

import { AokApiBaseClient } from './api-base.client';

@Injectable({ providedIn: 'root' })
export class AokUserRegistrationClient extends AokApiBaseClient {
  list(options?: AokRegistrationListOptions, headers?: AokHttpHeaders): Observable<AokPage<AokUserRegistration>> {
    return this.http.get<AokPage<AokUserRegistration>>(this.resolveUrl(), {
      headers,
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }

  create(request: AokUserRegistration): Observable<AokUserRegistration> {
    return this.http.post<AokUserRegistration>(this.resolveUrl(), request);
  }

  createKvnRegistration(request: AokKvnUserRegistration): Observable<AokKvnUserRegistration> {
    return this.http.post<AokKvnUserRegistration>(this.resolveUrl('kvn'), request);
  }

  createAssistant(request: AokAssistantRegistrationRequest): Observable<AokAssistantRegistrationRequest> {
    return this.http.post<AokUserRegistration>(this.resolveUrl(), request);
  }

  updateRegistration(request: Partial<AokUserRegistration>): Observable<AokUserRegistration> {
    return this.http.put<AokUserRegistration>(this.resolveUrl(request.id.toString()), request);
  }

  find(id: NonNullable<number>): Observable<AokUserRegistration> {
    return this.http.get<AokUserRegistration>(this.resolveUrl(id.toString()));
  }

  registrationStatusChange(
    id: NonNullable<string | number>,
    status: AokChangeableUserRegistrationStatus
  ): Observable<AokUserRegistrationPin> {
    return this.http.patch<AokUserRegistrationPin>(this.resolveUrl(id.toString(), 'status'), { status });
  }

  reject(id: NonNullable<number>, reason: string): Observable<unknown> {
    return this.http.patch<unknown>(this.resolveUrl(id.toString(), 'status'), {
      status: AokUserRegistrationStatus.Rejected,
      reason,
    });
  }

  delete(id: NonNullable<number>): Observable<void> {
    return this.http.delete<void>(this.resolveUrl(id.toString()));
  }

  requestPin(id: NonNullable<number>): Observable<AokUserRegistrationPin> {
    return this.http.get<AokUserRegistrationPin>(this.resolveUrl(id.toString(), 'pin'));
  }

  requestNewPin(id: NonNullable<number> | string): Observable<AokUserRegistrationPin> {
    return this.http.put<AokUserRegistrationPin>(this.resolveUrl(id.toString(), 'pin'), {});
  }

  sendPinCode(id: NonNullable<number>, pinCode: string): Observable<void> {
    return this.http.post<void>(this.resolveUrl(id.toString(), 'pin'), { pin: pinCode });
  }

  setDisplayCardKey(id: NonNullable<number>, displayCardKey: string): Observable<void> {
    return this.http.post<void>(this.resolveUrl(id.toString(), 'seed'), { seedValue: displayCardKey });
  }

  getSessionRegistration(): Observable<AokUserRegistration> {
    return this.http.get<AokUserRegistration>(this.resolveUrl('my'));
  }

  updateSessionRegistration(request: Partial<AokUserRegistration>): Observable<AokUserRegistration> {
    return this.http.put<AokUserRegistration>(this.resolveUrl('my'), request);
  }

  update2FARegistration(request: Aok2FAPatch): Observable<AokUserRegistration> {
    return this.http.patch<AokUserRegistration>(this.resolveUrl('my', '2fa-option'), request);
  }

  deleteMyRegistration(): Observable<void> {
    return this.http.delete<void>(this.resolveUrl('my'));
  }

  exportStatus(): Observable<AokExportStatus> {
    return this.http.get<AokExportStatus>(this.resolveUrl('export', 'status'));
  }

  sendReminder(id: number): Observable<void> {
    return this.http.post<void>(this.resolveUrl(id.toString(), 'reminder-invitation'), {});
  }

  /**
   * check if KVN-doctors are already registered as normal doctors in the portal
   */
  getUserLanrStatus(): Observable<UserLanrStatus> {
    return this.http.get<UserLanrStatus>(this.resolveUrl('lanr-status'));
  }

  select2fa(request: Aok2FAPatch): Observable<void> {
    return this.http.post<void>(this.resolveUrl('select-2fa'), request);
  }

  count(): Observable<number> {
    return this.http.get<number>(this.resolveUrl('count'));
  }

  /**
   * Resolves a fully quantified service url using the base ":apiUrl/users/registrations"
   *
   * @param segments Additional segments that should be append to the url
   */
  resolveUrl(...segments: string[]): string {
    // normalizing the base url to ":baseUrl/users/registrations"
    return super.resolveUrl('users', 'registrations', ...segments);
  }
}
