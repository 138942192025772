import { APP_BOOTSTRAP_LISTENER, Provider } from '@angular/core';
import { FeedbackAvailableState } from '@aok/components';

export function initFeedbackAvailable(feedBackAvailableState: FeedbackAvailableState) {
  return (): void => feedBackAvailableState.init();
}

export const AOK_FEEDBACK_AVAILABLE_INIT_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: initFeedbackAvailable,
  deps: [FeedbackAvailableState],
  multi: true,
};
