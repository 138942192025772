import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AOK_STATIC_ARTICLE_RESOLVE_PARAM_KEY,
  AokRouteDataPipeModule,
  AokRouteParamPipeModule,
  AokSvgIconComponent,
} from '@aok/common';
import {
  AokArticleContentModule,
  AokBreadcrumbsModule,
  AokFooterModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
} from '../../components';
import {
  AOK_STATIC_ARTICLE_PAGE_ROUTE_DATA_KEY,
  AOK_STATIC_SIGN_LANGUAGE_VIDEO_DATA,
  AokStaticArticlePage,
} from './static-article.page';

export interface AokSignLanguageVideoData {
  src: string;
  description: string;
}

export interface AokStaticArticleModuleConfig {
  routeParamKey: string;
  routeDataKey: string;
  staticSignLanguageVideos?: AokSignLanguageVideoData[];
}

const declarations = [AokStaticArticlePage];

@NgModule({
  declarations,
  exports: declarations,
  imports: [
    RouterModule,
    CommonModule,
    AokRouteDataPipeModule,
    AokRouteParamPipeModule,
    AokArticleContentModule,
    AokSvgIconComponent,
    HeaderModule,
    AokFooterModule,
    AokBreadcrumbsModule,
    FeedbackButtonModule,
    InfoBannerComponent,
  ],
})
export class AokStaticArticleModule {
  static forRoot(config: AokStaticArticleModuleConfig): ModuleWithProviders<AokStaticArticleModule> {
    return {
      ngModule: AokStaticArticleModule,
      providers: [
        {
          provide: AOK_STATIC_ARTICLE_PAGE_ROUTE_DATA_KEY,
          useValue: config.routeDataKey,
        },
        {
          provide: AOK_STATIC_ARTICLE_RESOLVE_PARAM_KEY,
          useValue: config.routeParamKey,
        },
        {
          provide: AOK_STATIC_SIGN_LANGUAGE_VIDEO_DATA,
          useValue: config.staticSignLanguageVideos,
        },
      ],
    };
  }
}
