import { APP_BOOTSTRAP_LISTENER, Provider } from '@angular/core';
import { FooterLayoutState } from '@aok/components';

export function initFooterLayout(footerLayoutState: FooterLayoutState) {
  return (): void => footerLayoutState.init();
}

export const AOK_FOOTER_LAYOUT_INIT_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: initFooterLayout,
  deps: [FooterLayoutState],
  multi: true,
};
