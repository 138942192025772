import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AokBenefitCategory } from '@aok/common';
import { BenefitsService } from '../services/benefits.service';

export const benefitCategoriesResolver: ResolveFn<AokBenefitCategory[]> = () => {
  const service = inject(BenefitsService);

  return service.retrieveCategories();
};
