import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'aok-org-context-select-item',
  styleUrls: ['./org-context-select-item.component.scss'],
  templateUrl: './org-context-select-item.component.html',
})
export class OrgContextSelectItemComponent {
  @Input() iconName: string;
  @Input() headline: string;
  @Input() label: string;
  @Input() isSelected = false;

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() enterEmitter: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
}
