<div class="grid-10">
  <div class="tw-col-1-4">
    <aok-input-field [label]="formLabels.countryCode" required>
      <aok-dropdown
        [control]="form.get(SecondFactorAuthFields.COUNTRY_CODE)"
        [options]="countryCodes"
        [virtualScroll]="true"
        id="countryCode"
      ></aok-dropdown>
    </aok-input-field>
  </div>
  <div class="tw-col-4-9">
    <aok-input-field [label]="formLabels.secondFactorPhoneNumber" required>
      <input
        type="text"
        [formControl]="form.get(SecondFactorAuthFields.SECOND_FACTOR_PHONE_NUMBER)"
        id="secondFactorPhoneNumber"
      />
    </aok-input-field>
  </div>
</div>
