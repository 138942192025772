import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationBannerLink } from '@aok/common';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'aok-toast',
  styleUrls: ['toast.component.scss'],
  templateUrl: './toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        })
      ),
      transition(
        'inactive => active',
        animate(
          '300ms ease-in',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0)',
              opacity: 0,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '1000ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0)',
              opacity: 0,
            }),
          ])
        )
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class AokToastComponent extends Toast {
  readonly accent: string;
  readonly links: NotificationBannerLink[];

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  public onClose(): void {
    this.toastrService.clear(this.toastPackage.toastId);
  }
}
