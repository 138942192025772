import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AokApiBaseClient } from './api-base.client';
import { AokNotificationListResponse } from '../schemas';

export const AOK_NOTIFICATION_API_URL = new InjectionToken<string>('AOK_NOTIFICATION_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/notification/notifications',
});

@Injectable({ providedIn: 'root' })
export class AokNotificationClient extends AokApiBaseClient {
  constructor(@Inject(AOK_NOTIFICATION_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  /**
   * retrieve notifications for the current user
   */
  public list(): Observable<AokNotificationListResponse> {
    return this.http.get<AokNotificationListResponse>(this.resolveUrl('me'));
  }

  public markAsRead(id: string): Observable<void> {
    return this.http.patch<void>(this.resolveUrl(`me/${id}/status`), {
      messageId: id,
    });
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(this.resolveUrl(`me/${id}`));
  }

  public deleteRead(): Observable<void> {
    return this.http.delete<void>(this.resolveUrl('me/status/read'));
  }
}
