import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AokSvgIconComponent } from '@aok/common';
import { AokReminderTeaserComponent } from './reminder-teaser.component';

@NgModule({
  declarations: [AokReminderTeaserComponent],
  exports: [AokReminderTeaserComponent],
  imports: [RouterModule, CommonModule, AokSvgIconComponent],
})
export class AokReminderTeaserModule {}
