import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  A11yUtilsModule,
  AokRouteDataPipeModule,
  AokSvgIconComponent,
  AuthService,
  DialogBase,
  DialogRef,
  SessionExpiryDialogState,
} from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AokInputFieldModule } from '../../cdk';
import { AokCheckboxModule } from '../../checkbox/checkbox.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';

@Component({
  selector: 'aok-session-expiry-dialog',
  templateUrl: './session-expiry-dialog.component.html',
  styleUrls: ['./session-expiry-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AokDialogLayoutModule,
    A11yModule,
    A11yUtilsModule,
    AokRouteDataPipeModule,
    AokCheckboxModule,
    AokInputFieldModule,
    AokSvgIconComponent,
  ],
})
export class SessionExpiryDialog implements AfterViewInit, DialogBase<string> {
  @ViewChild('progress') progress: ElementRef<HTMLElement>;

  public sessionRemainTime: number;

  private readonly initialSessionRemainTime = 60;

  private countdownStopNotifier = new Subject<void>();

  constructor(
    public readonly dialogRef: DialogRef<string>,
    private keycloak: KeycloakService,
    private authService: AuthService,
    private sessionExpiryDialogState: SessionExpiryDialogState,
    private cd: ChangeDetectorRef
  ) {
    this.startSessionExpiryCountdown();
  }

  ngAfterViewInit(): void {
    this.updateCountDownAnimation();
    this.startCountDown();
  }

  public async logout(reopenLastPage: boolean): Promise<void> {
    this.stopCountDown();
    this.dialogRef.dispose();
    await this.authService.logout(reopenLastPage);
  }

  public refreshSession(): void {
    this.stopCountDown();
    this.keycloak.updateToken(this.sessionRemainTime).then(() => this.dialogRef.dispose('refresh'));
  }

  private startCountDown(): void {
    interval(1000)
      .pipe(takeUntil(this.countdownStopNotifier))
      .subscribe(() => {
        if (this.sessionRemainTime === 0) {
          this.sessionRemainTime = 0;

          this.stopCountDown();

          return;
        }

        this.sessionRemainTime--;

        this.updateCountDownAnimation();

        this.cd.detectChanges();
      });
  }

  private updateCountDownAnimation(): void {
    this.progress.nativeElement.style.background = `conic-gradient(#005e3f ${this.transformToPercentage(
      this.sessionRemainTime
    )}%,
    #dfe3e6 ${this.transformToPercentage(this.sessionRemainTime)}%)`;
  }

  private transformToPercentage(value: number): number {
    return (value / this.initialSessionRemainTime) * 100;
  }

  /**
    Starts the countdown for the session expiry by subscribing to the sessionExpiryDialogState observable.
    It calculates the remaining time for the session to expire and updates the sessionRemainTime variable (in seconds).
    If the remaining time is positive or zero, it continues the countdown. Otherwise, it stops the countdown and sets the sessionRemainTime to 0.
    The countdown stops when the countdownStopNotifier emits a value.
  */
  private startSessionExpiryCountdown(): void {
    this.sessionExpiryDialogState
      .asObservable()
      .pipe(takeUntil(this.countdownStopNotifier))
      .subscribe((data) => {
        // Checking if timeRemaining is positive or zero
        if ([1, 0].includes(Math.sign(data?.timeRemaining))) {
          // Calculate the remaining time for the session to expire (in seconds)
          this.sessionRemainTime = Math.round(data?.timeRemaining / 1000);

          return;
        }

        // If the remaining time is negative, stop the countdown and set sessionRemainTime to 0
        this.sessionRemainTime = 0;
        this.stopCountDown();
        this.cd.detectChanges();
      });
  }

  private stopCountDown(): void {
    this.countdownStopNotifier.next();
    this.countdownStopNotifier.complete();
  }
}
