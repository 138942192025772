import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { AokDialogResult, DialogBase, DialogRef } from '@aok/common';

@Component({
  selector: 'aok-simple-dialog',
  styleUrls: ['./simple-dialog.component.scss'],
  templateUrl: './simple-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AokSimpleDialog implements DialogBase<AokDialogResult> {
  @Input() headerText: string;
  @Input() contentText: string | string[];
  @Input() cancelButtonText: string; // button text for close the dialog direct
  @Input() confirmButtonText: string; // button text for submit the dialog
  @Input() confirmFunction: (...args: any[]) => any;
  @Input() closeText: string;
  @Input() maxWidth: number | string;

  @Output() submitEvent: EventEmitter<AokDialogResult> = new EventEmitter<AokDialogResult>();

  constructor(
    public readonly dialogRef: DialogRef<AokDialogResult>,
    protected changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {}

  public confirm(): void {
    this.submitEvent.emit();
    if (this.confirmFunction != null) this.confirmFunction();
    this.dialogRef.dispose(AokDialogResult.CONFIRM);
  }

  public cancel(): void {
    this.dialogRef.dispose(AokDialogResult.CANCEL);
  }

  public close(): void {
    this.dialogRef.dispose();
  }

  public handleLinks(e): void {
    const target = e.target?.getAttribute('target');
    const href = e.target?.getAttribute('href');
    if (target === '_self' && href) {
      e.preventDefault();
      this.router.navigate([href]);
      this.close();
    }
  }
}
