<dialog-layout
  fullscreen="true"
  [headerText]="'E-Mail erneut senden'"
  [isClosable]="true"
  aokFocusFirstChild
  [autoBlur]="true"
  [main]="false"
  cdkTrapFocus
>
  <div class="aok-container">
    <p class="tw-col-1-13">
      Geben Sie hier Ihre E-Mail-Adresse ein, an die wir die E-Mail mit dem Bestätigungslink senden sollen.
    </p>

    <div class="tw-col-3-11 tw-mt-3" *ngIf="errorIds.length">
      <aok-notification-banner
        accent="bad"
        [bannerHeadline]="bannerError"
        [bannerLabel]="bannerLabel"
        [bannerLinks]="errorIds"
      >
      </aok-notification-banner>
    </div>

    <form class="tw-col-3-11 tw-mt-3" [formGroup]="form">
      <p class="required-label">*Pflichtfeld</p>

      <h5 class="tw-mb-7 tw-mt-7">Kontaktdaten</h5>

      <aok-input-field class="tw-mb-7 block" id="email" [label]="label['email']" [required]="true">
        <input type="text" formControlName="email" />
      </aok-input-field>

      <aok-input-field class="tw-mb-3 block" id="newEmail" [label]="label['newEmail']" [required]="true">
        <input type="text" formControlName="newEmail" />
      </aok-input-field>

      <button (keyup.enter)="submitDialog()" (click)="submitDialog()">E-Mail erneut senden</button>
    </form>
  </div>
</dialog-layout>
