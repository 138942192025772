import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { AokUserClient } from '../../clients';
import {
  AokKvnUserRegistration,
  AokPractitionerSpecializationEntry,
  AokRegistrationOrgData,
  AokUserRegistration,
} from '../../schemas';

import { FormSchemaControls } from './form-utils/form-schema-map';
import { createOrganizationDataForm, createUserDataForm } from './registration-dialog.util';

export function createKVNRegistrationRequestForm(
  specializations: AokPractitionerSpecializationEntry[],
  userClient: AokUserClient,
  schema?: Partial<AokKvnUserRegistration>,
  isRegisteredDoctor?: boolean
): UntypedFormGroup {
  return new UntypedFormGroup({
    organizationDataList: createKvnOrganizationDataListForm(schema?.organizationDataList),
    userData: createUserDataForm(specializations, schema, true, isRegisteredDoctor, userClient),
  } as FormSchemaControls<AokUserRegistration>);
}

function createKvnOrganizationDataListForm(orgDataList: AokRegistrationOrgData[]): UntypedFormArray {
  const formArray = new UntypedFormArray([]);

  orgDataList?.forEach((orgData) => {
    formArray.push(createOrganizationDataForm(orgData, true));
  });

  return formArray;
}
