import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokMenuButtonComponent } from './menu-button.component';
import { AokMenuModule } from './menu/menu.module';

@NgModule({
  declarations: [AokMenuButtonComponent],
  exports: [AokMenuButtonComponent],
  imports: [CommonModule, AokSvgIconComponent, MatMenuModule, AokMenuModule, A11yUtilsModule],
})
export class AokMenuButtonModule {}
