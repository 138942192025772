import { Injectable } from '@angular/core';
import { AokSSOSessionIdleClient, DialogOverlay, OpenedDialogService, SessionExpiryDialogState } from '@aok/common';
import { SessionExpiryDialog } from '../components';
import { KeycloakService } from 'keycloak-angular';
import { fromEvent } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionExpiryService {
  public ssoSessionTimeInMinutes: number = undefined;

  private ssoSessionTimeoutId: ReturnType<typeof setTimeout> = undefined;

  private expireSessionTimestamp: number;
  private timeoutRemainingTime: number;

  constructor(
    protected keycloak: KeycloakService,
    private openedDialogService: OpenedDialogService,
    private sessionIdleClient: AokSSOSessionIdleClient,
    private dialog: DialogOverlay,
    private sessionExpiryDialogState: SessionExpiryDialogState
  ) {
    this.addVisibilityChangeEventListener();
  }

  public openPendingExpirationSessionDialog(): void {
    this.dialog.create(SessionExpiryDialog).subscribe((response) => {
      if (response === 'refresh') {
        // call calculateIdleTime function to start counting the expiration session after clicking refresh session button
        this.calculateIdleTime();
      }
    });
  }

  /**
    Calculates the remaining idle time for the session and schedules a dialog to open when the session is about to expire.
    It checks if the user is logged in, calculates the session duration, and sets up a timeout to open the expiration dialog.
    The dialog is shown 60 seconds before the session expires.
  */
  public calculateIdleTime(): void {
    this.keycloak.isLoggedIn().then((isLogged) => {
      if (!isLogged) {
        return;
      }

      // convert minutes to milliseconds
      const sessionDuration = this.ssoSessionTimeInMinutes * 60 * 1000;

      if (!sessionDuration) {
        return;
      }

      // Calculate the session expiry timestamp by adding the session duration to the current timestamp
      this.expireSessionTimestamp = Date.now() + sessionDuration;

      clearTimeout(this.ssoSessionTimeoutId);

      // Calculate the remaining time until the session expires, subtracting 60 seconds as a buffer
      this.timeoutRemainingTime = sessionDuration - 60 * 1000;

      // Set up a timeout to open the expiration dialog
      this.setTimeoutExpirationDialog();
    });
  }

  private setTimeoutExpirationDialog(): void {
    this.ssoSessionTimeoutId = setTimeout(() => {
      this.openedDialogService.closeDialog();
      // 60 seconds in milliseconds(animation's default duration)
      this.sessionExpiryDialogState.patch({ timeRemaining: 60 * 1000 });

      this.openPendingExpirationSessionDialog();

      // Show the dialog 60 seconds before the session expires
    }, this.timeoutRemainingTime);
  }

  public init(): void {
    this.sessionIdleClient
      .getSsoSessionIdle()
      .subscribe(({ ssoSessionTimeInMinutes }) => (this.ssoSessionTimeInMinutes = ssoSessionTimeInMinutes));
  }

  /**
    Adds an event listener to detect changes in document visibility and perform actions accordingly.
    This function is used to track when the document/tab goes from hidden to visible.
    It calculates the remaining time for the session to expire and updates the session expiry dialog state.
  */
  private addVisibilityChangeEventListener(): void {
    fromEvent(document, 'visibilitychange').subscribe(() => {
      if (document.visibilityState === 'hidden') {
        return;
      }

      // When the document becomes visible
      // Calculate the remaining time for the session to expire (subtracting 60 seconds as a buffer)
      const timeRemaining = this.expireSessionTimestamp - Date.now() - 60;

      // Extraction 60 secondes
      this.timeoutRemainingTime = Math.sign(this.timeoutRemainingTime) === 1 ? timeRemaining - 60 * 1000 : null;

      if (Math.sign(this.timeoutRemainingTime) === 1) {
        clearTimeout(this.ssoSessionTimeoutId);
        this.setTimeoutExpirationDialog();
      }

      // Update the session expiry dialog state with the remaining time
      this.sessionExpiryDialogState.patch({ timeRemaining });
    });
  }
}
