import { AokInsuranceStatus } from '../../schemas';

export const getInsuranceStatusText = (status: AokInsuranceStatus): string => {
  switch (status) {
    case AokInsuranceStatus.Member:
      return 'Mitglied';
    case AokInsuranceStatus.FamilyMember:
      return 'Familienversicherter';
    case AokInsuranceStatus.SocialCare:
      return 'Sozialhilfe';
    case AokInsuranceStatus.Pensioner:
      return 'Rentner';
    case AokInsuranceStatus.SupervisedBVG:
      return 'Betreute BVG';
    case AokInsuranceStatus.SupervisedDomestic:
      return 'Betreute Inländer';
    default:
      return 'unklar';
  }
};
