import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AokArticleContentComponent } from './article-content.component';

const declarations = [AokArticleContentComponent];

@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule],
})
export class AokArticleContentModule {}
