import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule } from '@aok/common';
import { CompletionSupportComponent } from '../../completion-support/completion-support.component';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';

import { CompletionSupportDialog } from './completion-support-dialog.component';

@NgModule({
  declarations: [CompletionSupportDialog],
  exports: [CompletionSupportDialog],
  imports: [CommonModule, CompletionSupportComponent, AokDialogLayoutModule, A11yModule, A11yUtilsModule],
})
export class CompletionSupportDialogModule {}
