import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  AokDoctorRelationship,
  AokOrg,
  ContextState,
  CurrentUserState,
  DialogOverlay,
  isAssistantType,
  isDoctor,
  isDoctorType,
  KnownUserType,
} from '@aok/common';
import { DialogManagerService, ProfileService } from '../../services';
import { KvnState } from '../../states';
import { KvnPracticeInstructionsAddModeDoctorsDialog } from '../dialogs';

const LABELS = {
  doctor: {
    title: 'Praxisübersicht',
    description:
      'Wenn Sie für weitere Betriebsstätten arbeiten, können Sie hier die BSNR hinzufügen. Anschließend können Sie über dieses Menü die Betriebsstätte auswählen, für die Sie gerade arbeiten:',
    addPracticeDescription: 'Wollen Sie eine weitere Praxis der Liste hinzufügen?',
    addPracticeButton: 'Weitere Praxis hinzufügen',
  },
  mfa: {
    title: 'Praxisübersicht',
    description:
      'Wenn Sie für mehrere Ärzte arbeiten, können Sie hier alle Ärzte hinzufügen. Anschließend können Sie über dieses Menü den Arzt auswählen, für den Sie gerade arbeiten:',
    addPracticeDescription: 'Wollen Sie einen weiteren Arzt der Liste hinzufügen?',
    addPracticeButton: 'Weiteren Arzt hinzufügen',
  },
};

@Component({
  selector: 'aok-org-context-select',
  styleUrls: ['org-context-select.component.scss'],
  templateUrl: 'org-context-select.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AokOrgContextSelectComponent implements OnInit {
  public activeLabels = {
    title: '',
    description: '',
    addPracticeDescription: '',
    addPracticeButton: '',
  };
  isActive = false;
  public selectedBSNR: string;
  public isAssistantType = isAssistantType;

  @Input() options: AokDoctorRelationship[];
  @Input() mode: KnownUserType = KnownUserType.Assistant;
  @Output() closeBSNRList: EventEmitter<void> = new EventEmitter<void>();

  private alreadyJoined: string[] = []; // Array with BSNRs of already joined Practices
  private alreadyRequested: string[] = []; // Array with BSNRs of already requested Practices

  constructor(
    readonly currentUser: CurrentUserState,
    private dialogService: DialogManagerService,
    protected router: Router,
    public contextState: ContextState,
    // TODO this component should be decoupled from the profile service
    private profileService: ProfileService,
    protected kvnState: KvnState,
    private dialog: DialogOverlay
  ) {}

  /**
   * ngOnChange is not needed, because this component is always initialized when the contextMeno is open
   * While the context are open, it is really improbable that the context are changed
   */
  ngOnInit(): void {
    if (isAssistantType(this.mode)) {
      this.activeLabels = LABELS.mfa;
    } else {
      this.activeLabels = LABELS.doctor;
    }
    this.selectedBSNR = this.contextState.bsnr;
  }

  public selectDoctorContext(context: AokDoctorRelationship): void {
    if (this.contextState.orgId !== context.organizationData.id) {
      this.contextState.setOrg(context.organizationData as AokOrg);
    }
    this.isActive = false;
    this.closeBSNRList.emit();
  }

  public selectAssistantContext(context: AokDoctorRelationship): void {
    if (this.contextState.orgId !== context.organizationData.id) {
      this.contextState.setOrg(context.organizationData as AokOrg);
    }
    if (this.contextState.practitioner.id !== context.userData.id) {
      this.contextState.setPractitioner(context.userData);
    }
    this.isActive = false;
    this.closeBSNRList.emit();
  }

  public isSelected(option: AokDoctorRelationship): boolean {
    if (isDoctor(this.currentUser.snapshot)) {
      return option.organizationData.bsnr === this.contextState.bsnr;
    } else {
      return (
        option.userData.id === this.contextState.practitioner.id &&
        option.organizationData.id === this.contextState.orgId
      );
    }
  }

  public openAddPracticeDialog(): void {
    this.closeBSNRList.emit();
    const { selected, unselected, bsnrRequests } = this.profileService.organisations.getValue();
    this.alreadyJoined = selected?.org?.bsnr ? [selected.org.bsnr] : [];
    this.alreadyJoined.push(...(unselected?.map(({ org }) => org.bsnr) || []));
    this.alreadyRequested = bsnrRequests?.map((request) => request.organizationData.bsnr) || [];

    if (this.kvnState.isKvnPracticeTeam()) {
      this.dialog.create(KvnPracticeInstructionsAddModeDoctorsDialog);
      return;
    }

    if (isDoctorType(this.mode)) {
      this.dialogService
        .openJoinRequestToPracticeDoctorDialog(null, this.alreadyJoined, this.alreadyRequested)
        .subscribe((successful: boolean) => {
          if (successful) {
            this.profileService.reloadProfileOrganisations();
          }
        });
    } else {
      this.dialogService
        .openJoinRequestToPracticeMfaDialog(this.profileService.getAllOrgs())
        .subscribe((successful: boolean) => {
          if (successful) {
            this.profileService.reloadProfileOrganisations();
          }
        });
    }
  }
}
