import { NgModule } from '@angular/core';
import { AokMenuComponent } from './menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { A11yUtilsModule } from '@aok/common';

@NgModule({
  declarations: [AokMenuComponent],
  exports: [AokMenuComponent],
  imports: [MatMenuModule, CommonModule, A11yUtilsModule],
})
export class AokMenuModule {}
