import { Injectable } from '@angular/core';
import {
  AokMfaToBSNRRegistration,
  AokOrg,
  AokOrgClient,
  AokPage,
  AokRegistrationToOrganisation,
  AokUser,
  AokUserClient,
  AokUserRegistration,
  AokUserRegistrationClient,
  ContextState,
  CurrentUserState,
  isDoctor,
  KnownUserType,
} from '@aok/common';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProfileAssistant, UserByOrg } from '../schema';
import { sortEmployees, splitEmployees } from '../utils';

interface UserObservables {
  registered?: Observable<AokPage<AokUser>>;
  register?: Observable<AokPage<AokUserRegistration>>;

  [orgId: number]: Observable<AokPage<AokMfaToBSNRRegistration>>;
}

interface UserResponse {
  registered?: AokPage<AokUser>;
  register?: AokPage<AokUserRegistration>;

  [orgId: number]: AokPage<AokMfaToBSNRRegistration>;
}

interface ProfileBehaviorSubject {
  selected: {
    org: AokOrg;
    doctors?: AokUser[];
    assistants?: ProfileAssistant[];
  };
  unselected: {
    org: AokOrg;
    doctors?: AokUser[];
    assistants?: ProfileAssistant[];
  }[];
  bsnrRequests?: AokRegistrationToOrganisation[]; //Only if the currentUser MFA
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public organisations: BehaviorSubject<ProfileBehaviorSubject> = new BehaviorSubject<ProfileBehaviorSubject>(
    undefined
  );

  private allOrganisations: AokOrg[];
  private bsnrRequests: AokRegistrationToOrganisation[];
  private organisationEmployeeConnection: UserByOrg = {};

  constructor(
    private contextState: ContextState,
    private userClient: AokUserClient,
    private registerClient: AokUserRegistrationClient,
    private currentUser: CurrentUserState,
    private orgClient: AokOrgClient
  ) {
    // TODO make this service local to the profile component and then unsubscribe once the component is destroyed
    this.contextState
      .getOrg$()
      .pipe(filter((org) => !!org))
      .subscribe(() => {
        this.updateBehavior();
      });
  }

  public setOrganisations(orgs: AokOrg[]): ProfileService {
    this.allOrganisations = orgs.sort((orgA, orgB) => (orgA.bsnr > orgB.bsnr ? 1 : -1));
    if (this.contextState.org) this.updateBehavior();
    return this;
  }

  public setUsers(users: ProfileAssistant[], orgs?: { bsnr: string; orgId: number }[]): ProfileService {
    let sortedUsers: UserByOrg;
    if (orgs && Array.isArray(orgs) && orgs.length) {
      sortedUsers = this.sortUsers(users, orgs);
    } else {
      sortedUsers = this.sortUsers(
        users,
        [this.getOrganisationByIdOrSelected()].map((org) => ({ bsnr: org.bsnr, orgId: org.id }))
      );
    }
    this.organisationEmployeeConnection = { ...this.organisationEmployeeConnection, ...sortedUsers };
    this.updateBehavior();
    return this;
  }

  public setJoinRequestsToBSNR(bsnrRequests: AokRegistrationToOrganisation[]): void {
    this.bsnrRequests = bsnrRequests;
    this.updateBehavior();
  }

  /**
   * returns the organisation by id (or undefined, if no organisation was found
   * if the id empty then returns the organisation, selected by header
   * @param orgId
   * @private
   */
  public getOrganisationByIdOrSelected(orgId?: number): AokOrg {
    if (orgId) {
      return this.allOrganisations?.filter((org) => org.id === orgId)?.[0] || this.contextState.org;
    } else {
      return this.contextState.org;
    }
  }

  public getOrganisations(): { selected: AokOrg; unselected: AokOrg[] } {
    return this.splitSelectedOrganisations();
  }

  public getAllOrgs(): AokOrg[] {
    return [].concat(this.allOrganisations);
  }

  public loadAllUsers(orgs?: AokOrg[], overwrite = false): void {
    let loadOrgs: { bsnr: string; orgId: number }[];
    if (orgs) loadOrgs = orgs.map((org) => ({ bsnr: org.bsnr, orgId: org.id }));
    else loadOrgs = this.allOrganisations.map((org) => ({ bsnr: org.bsnr, orgId: org.id }));

    if (loadOrgs.length) {
      this.initUsersRequest(loadOrgs).subscribe(({ registered, register, ...response }) => {
        const employees: (AokMfaToBSNRRegistration | AokUserRegistration | AokUser)[] = (
          (registered?._embedded?.items || []) as (AokUser | AokUserRegistration)[]
        )
          .concat(register?._embedded?.items)
          .filter((user) => !!user);
        if (overwrite) {
          this.organisationEmployeeConnection = this.sortUsers(
            loadOrgs.reduce((accu, { orgId }) => {
              return accu.concat(response[orgId]?._embedded?.items || []);
            }, employees),
            loadOrgs
          );
        } else {
          this.organisationEmployeeConnection = {
            ...this.organisationEmployeeConnection,
            ...this.sortUsers(
              loadOrgs.reduce((accu, { orgId }) => {
                return accu.concat(response[orgId]?._embedded?.items || []);
              }, employees),
              loadOrgs
            ),
          };
        }
        this.updateBehavior();
      });
    }
  }

  public reloadMFARequests(): void {
    this.userClient.listBsnrRegistrations().subscribe({
      next: (registered: AokPage<AokRegistrationToOrganisation>) => {
        this.setJoinRequestsToBSNR(registered?._embedded?.items || []);
      },
    });
  }

  public reloadProfileOrganisations(): void {
    forkJoin({
      myOrganisations: this.orgClient.listMine({ size: 1000 }),
      requestsOrganisations: this.userClient.listBsnrRegistrations(),
    })
      .pipe(
        map(({ myOrganisations, requestsOrganisations }) => {
          return {
            myOrganisations: myOrganisations?._embedded?.items,
            requestsOrganisations: requestsOrganisations?._embedded?.items,
          };
        })
      )
      .subscribe(({ myOrganisations, requestsOrganisations }) => {
        this.setOrganisations(myOrganisations);
        this.setJoinRequestsToBSNR(requestsOrganisations);
      });
  }

  private sortUsers(
    employees: (AokMfaToBSNRRegistration | AokUserRegistration | AokUser)[],
    loadOrgs: { bsnr: string; orgId: number }[]
  ): UserByOrg {
    const sortedEmployees = Object.entries(employees.reduce(splitEmployees, {} as UserByOrg)).reduce(
      (accu, [key, value]) => {
        accu[Number.parseInt(key, null)] = {
          assistants: value.assistants,
          doctors: value.doctors,
        };
        return accu;
      },
      {} as UserByOrg
    );

    const orgIds = loadOrgs.map((org) => org.orgId);
    Object.keys(sortedEmployees).forEach((key) => {
      const numberKey = Number.parseInt(key, null);
      if (!orgIds.includes(numberKey)) delete sortedEmployees[numberKey];
    });
    return sortedEmployees;
  }

  /**
   * This function starts the request to the server.
   * @return Observable<UserResponse>
   * @param orgs
   * @private
   */
  private initUsersRequest(orgs: { bsnr: string; orgId: number }[]): Observable<UserResponse> {
    const callRegisteredMfas: UserObservables = orgs.reduce((accu, { bsnr, orgId }) => {
      accu[orgId] = this.userClient.getRequestedMfa(bsnr);
      return accu;
    }, {} as { [bsnr: number]: Observable<AokPage<AokMfaToBSNRRegistration>> });
    callRegisteredMfas['registered'] = this.userClient.list({
      bsnr: orgs.map((org) => org.bsnr),
      size: 1000,
      userType: [
        KnownUserType.Doctor,
        KnownUserType.Kvn_Doctor,
        KnownUserType.Full_Kvn_Doctor,
        KnownUserType.Assistant,
      ],
    });
    callRegisteredMfas['register'] = this.registerClient.list({
      bsnr: orgs.map((org) => org.bsnr),
      size: 1000,
      userType: KnownUserType.Assistant,
    });
    return forkJoin({ ...callRegisteredMfas });
  }

  private splitSelectedOrganisations(): { selected: AokOrg; unselected: AokOrg[] } {
    if (this.contextState.org && this.allOrganisations?.length) {
      return this.allOrganisations.reduce(
        (accu, org) => {
          if (this.contextState.orgId === org.id) {
            accu.selected = org;
          } else {
            accu.unselected.push(org);
          }
          return accu;
        },
        { selected: undefined, unselected: [] } as { selected: AokOrg; unselected: AokOrg[] }
      );
    } else {
      return { selected: undefined, unselected: this.allOrganisations || [] };
    }
  }

  private updateBehavior(): void {
    const orgs = this.splitSelectedOrganisations();
    if (!orgs.selected) {
      orgs.selected = this.contextState.org;
    }
    if (isDoctor(this.currentUser.snapshot)) {
      this.organisations.next({
        selected: {
          org: orgs.selected,
          doctors: orgs?.selected?.id
            ? this.organisationEmployeeConnection?.[orgs.selected.id]?.doctors.sort(sortEmployees)
            : undefined,
          assistants: orgs?.selected?.id
            ? this.organisationEmployeeConnection?.[orgs.selected.id]?.assistants.sort(sortEmployees)
            : undefined,
        },
        unselected: orgs.unselected.map((org) => {
          return {
            org: org,
            doctors: this.organisationEmployeeConnection?.[org.id]?.doctors.sort(sortEmployees),
            assistants: this.organisationEmployeeConnection?.[org.id]?.assistants.sort(sortEmployees),
          };
        }),
        bsnrRequests: this.bsnrRequests || [],
      });
    } else {
      this.organisations.next({
        selected: {
          org: orgs.selected || this.contextState.org,
        },
        unselected: orgs.unselected.map((org) => {
          return {
            org: org,
          };
        }),
        bsnrRequests: this.bsnrRequests || [],
      });
    }
  }
}
