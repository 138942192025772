import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'aokRemoveDateType' })
export class AokRemoveDateTypePipe implements PipeTransform {
  /**
   * remove date suffix from a filename
   * Attantion
   * @param filename - The name of the file
   */
  transform(filename: string): string {
    const underscoreIndex = filename.lastIndexOf('_');
    if (underscoreIndex === -1) {
      return filename;
    }

    const timestamp = filename.slice(underscoreIndex + 1);
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}-\d{2}-\d{2}$/;

    if (timestamp.match(regex)) {
      return filename.slice(0, underscoreIndex);
    }

    return filename;
  }
}
