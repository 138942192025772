import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AokDialogResult,
  AokDoctorRelationship,
  AokFullNamePipe,
  AokMessage,
  AokNotification,
  AokNotificationService,
  AokNotificationState,
  AokReminder,
  AokReminderState,
  AokToastService,
  ContextState,
  CurrentUserState,
  DialogRef,
  isDoctor,
} from '@aok/common';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aok-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideLeft', [
      // the "visible" style determines the "resting" state of the element when it is visible.
      state(
        'visible',
        style({
          transform: 'translate3d(0,0,0)',
        })
      ),

      // fade in when created
      transition(':enter', [
        style({
          transform: 'translate3d(100%,0,0)',
        }),
        animate(200),
      ]),
    ]),
  ],
})
export class AokNotificationSidebarComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class.slideRight') public addAnimation = false;
  @ViewChild('content', { read: ElementRef })
  public content: ElementRef;
  @ViewChild('notificationHeader', { read: ElementRef })
  public notificationHeader: ElementRef;
  public reminders: AokReminder[];
  public notifications: AokNotification[];
  public headerHeight: number;
  private contextOptions: AokDoctorRelationship[];
  private _fullNamePipe = new AokFullNamePipe();
  private listener: () => void;
  private ngDestroyed: EventEmitter<void>;

  constructor(
    readonly dialogRef: DialogRef<AokDialogResult>,
    private currentUser: CurrentUserState,
    private notificationState: AokNotificationState,
    private notificationService: AokNotificationService,
    protected toastService: AokToastService,
    private reminderState: AokReminderState,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    private contextState: ContextState
  ) {
    this.ngDestroyed = new EventEmitter<void>();
    // depending on how many headers we have the full header height differs
    this.headerHeight = this.reminders?.length ? 160 : 96;
    this.contextOptions = this.contextState.contextSelectorOptions;
    this.getReminders();
    this.getNotifications();
  }

  @HostBinding('@slideLeft') get slideIn(): string {
    return 'visible';
  }

  public ngAfterViewInit(): void {
    if (this.notificationHeader) {
      let initialElementPosition;
      this.listener = this.renderer.listen(this.content.nativeElement, 'scroll', (event) => {
        // if we delete all notifications then the notification header will not exist anymore
        if (this.notificationHeader) {
          const elementPosition = this.notificationHeader.nativeElement.offsetTop;
          if (!initialElementPosition || elementPosition > 160) {
            initialElementPosition = elementPosition;
          }
          const scrolledHeight = event.target.scrollTop + event.target.offsetTop;

          if (scrolledHeight >= initialElementPosition) {
            this.renderer.addClass(this.notificationHeader.nativeElement, 'sticky');
            this.renderer.setStyle(this.notificationHeader.nativeElement, 'top', `${this.headerHeight}px`);
          } else {
            this.renderer.removeClass(this.notificationHeader.nativeElement, 'sticky');
          }
        } else {
          // remove listener if no header
          this.listener?.();
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.listener?.();
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  public deleteReadNotifications(): void {
    this.notificationService.deleteRead().subscribe({
      error: () =>
        this.toastService.createErrorToast(
          'Technischer Fehler',
          'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
        ),
    });
  }

  public closeSidebar(): void {
    this.addAnimation = true;
    // timeout needed for the animation to occur
    setTimeout(() => {
      this.dialogRef.dispose();
    }, 200);
  }

  public getContextInfo(notification: AokReminder | AokNotification): string {
    let result = 'An: ';
    if (notification.context.lanr !== '*') {
      if (isDoctor(this.currentUser.snapshot)) {
        result += this._fullNamePipe.transform(this.currentUser.snapshot, 'TT FF LL');
      } else {
        if (notification.context.assistantId) {
          result += this._fullNamePipe.transform(this.currentUser.snapshot, 'TT FF LL');
        } else {
          const notificationContext = this.contextOptions.find(
            (option: AokDoctorRelationship) => option.userData.practitionerResource.lanr === notification.context.lanr
          );
          result += this._fullNamePipe.transform(notificationContext?.userData, 'TT FF LL');
        }
      }
    } else {
      if (notification.context.bsnr !== '*') {
        const org = this.contextOptions.find(
          (option: AokDoctorRelationship) => option.organizationData?.bsnr === notification.context.bsnr
        )?.organizationData;
        result += org.name;
      } else {
        result += 'Alle Praxen';
      }
    }
    return result;
  }

  /**
   * listen to updates from notifications state and update the notification list
   */
  private getNotifications(): void {
    this.notificationState
      .asObservable()
      .pipe(takeUntil(this.ngDestroyed))
      .subscribe(({ notifications }) => {
        this.notifications = this.filterMessagesWithoutContext(notifications) as AokNotification[];
        this.cd.markForCheck();
      });
  }

  private getReminders(): void {
    this.reminderState
      .asObservable()
      .pipe(takeUntil(this.ngDestroyed))
      .subscribe((reminders) => {
        this.reminders = this.filterMessagesWithoutContext(reminders) as AokReminder[];
        this.cd.markForCheck();
      });
  }

  private filterMessagesWithoutContext(messages: AokMessage[]): AokMessage[] {
    if (isDoctor(this.currentUser.snapshot)) {
      const bsnrs = this.contextOptions.map((option: AokDoctorRelationship) => option.organizationData?.bsnr);
      return messages.filter((message) => bsnrs.includes(message.context.bsnr) || this.isWildcardMessage(message));
    } else {
      return messages.filter(
        (message) =>
          this.isWildcardMessage(message) ||
          message.context.assistantId ||
          this.contextOptions.find((option: AokDoctorRelationship) => {
            return (
              option.organizationData.bsnr === message.context.bsnr &&
              option.userData.practitionerResource.lanr === message.context.lanr
            );
          })
      );
    }
  }

  private isWildcardMessage(message: AokMessage): boolean {
    return message.context.bsnr === '*' || message.context.lanr === '*';
  }
}
