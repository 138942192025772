import { Component, Input, ViewEncapsulation } from '@angular/core';
import { focusFirstElement, scrollToTop } from '@aok/common';

@Component({
  selector: 'aok-to-top-scroller',
  styleUrls: ['./to-top-scroller.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <a class="aok-link-1" aokFocusable (click)="scrollToTop()" (keyup.enter)="scrollToTop()">
      {{ label }}
      <aok-svg-icon class="right" name="arrow-rounded_up" [title]="label" size="16px"></aok-svg-icon>
    </a>
  `,
})
export class AokToTopScrollerComponent {
  @Input() label = 'Zurück zum Seitenanfang';

  public scrollToTop(): void {
    scrollToTop();
    focusFirstElement();
  }
}
