<ng-template #siteLiTemplate let-siteRef let-isChild="isChild">
  <li *ngIf="resolveTitle(siteRef)">
    <a
      [class.aok-bold]="siteRef.interimRoute"
      [class.aok-link-1]="!siteRef.interimRoute"
      [ngClass]="{ child: isChild }"
      [target]="siteRef.target ?? '_self'"
      [routerLinkOrHref]="getRouteUrl(siteRef)"
    >
      {{ resolveTitle(siteRef) }}
      <aok-svg-icon
        *ngIf="isExternalRef(siteRef)"
        class="tw-ml-10"
        [name]="'arrow-rounded_rightup'"
        [size]="16"
      ></aok-svg-icon>
    </a>
    <ul *ngIf="siteRef.children?.length > 0">
      <ng-container *ngFor="let childSiteRef of filterChildren(siteRef.children)">
        <ng-container
          *ngTemplateOutlet="siteLiTemplate; context: { $implicit: childSiteRef, isChild: true }"
        ></ng-container>
      </ng-container>
    </ul>
  </li>
</ng-template>

<ul>
  <ng-container *ngFor="let siteRef of sitemap">
    <ng-container *ngTemplateOutlet="siteLiTemplate; context: { $implicit: siteRef }"></ng-container>
  </ng-container>
</ul>
