import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  A11yUtilsModule,
  AokDialogResult,
  AokSvgIconComponent,
  DialogBase,
  DialogRef,
  ENVIRONMENT,
  EnvironmentSchema,
} from '@aok/common';
import { AokDialogLayoutModule } from '../dialogs';

@Component({
  selector: 'aok-cockpit-ti-overlay',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, A11yModule, A11yUtilsModule, AokDialogLayoutModule, AokSvgIconComponent],
  templateUrl: './ti-overlay.component.html',
  styleUrls: ['./ti-overlay.component.scss'],
})
export class TIOverlayComponent implements DialogBase<AokDialogResult> {
  constructor(
    public readonly dialogRef: DialogRef<AokDialogResult>,
    @Inject(ENVIRONMENT) private environment: EnvironmentSchema
  ) {}

  public close(): void {
    this.dialogRef.dispose();
  }

  public openExtendedTI(): void {
    window.open(this.environment.svsTIUrl);
  }
}
