import { Injectable } from '@angular/core';
import { AokInfoBannerClient, BehaviorState, InfoBanner } from '@aok/common';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InfoBannerState extends BehaviorState<InfoBanner> {
  constructor(private client: AokInfoBannerClient) {
    super();
  }

  public init(): Observable<InfoBanner> {
    return this.client.get().pipe(tap((infoBanner) => this.patch(infoBanner)));
  }
}
