import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { PrimitiveBehaviorState, SVG_ICON_SIZE } from '@aok/common';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, skip, takeUntil, tap } from 'rxjs/operators';
import { AokPager } from '../../schema/pager.schema';
import { PaginationService } from '../../services/pagination.service';

@Component({
  selector: 'aok-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: SVG_ICON_SIZE, useValue: 18 }],
})
export class AokPaginationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() totalItems: number;
  @Input() totalPages: number;
  @Input() pageSize = 10;
  @Input() routeQueryParam?: string;
  @Input() isFrontendSidePagination = false;

  @Output() pageChange = new EventEmitter();

  public pager: AokPager;
  protected readonly ngDestroys = new Subject<void>();
  private readonly state = new PrimitiveBehaviorState<number>();

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    private paginationService: PaginationService,
    @Optional() protected router: Router
  ) {}

  get activePage(): number {
    return this.state.snapshot;
  }

  @Input()
  set activePage(value: number) {
    this.state.patch(coerceNumberProperty(value, 0));
  }

  ngOnInit(): void {
    if (this.router != null && !this.isFrontendSidePagination) {
      if (this.routeQueryParam?.trim()) {
        const routeQueryParamValue = this.router.routerState.root.snapshot.queryParamMap.get(this.routeQueryParam);
        const pageRestoreValue = parseInt(routeQueryParamValue, 10);
        if (pageRestoreValue) this.state.patch(pageRestoreValue);
      }

      this.state
        .asObservable()
        .pipe(
          takeUntil(this.ngDestroys),
          tap(() => this.changeDetectorRef.markForCheck()),
          debounceTime(750),
          filter(() => !!this.routeQueryParam?.trim()),
          distinctUntilChanged(),
          skip(1) // skip the initial change to prevent any unexpected router navigations
        )
        .subscribe((activePage) => {
          this.router.navigate([], {
            queryParamsHandling: 'merge',
            queryParams: {
              [this.routeQueryParam]: activePage,
            },
          });
        });
      this.setPage(this.activePage + 1);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalPages || changes.totalItems) {
      this.updatePager(this.activePage);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  public setPage(page: number): void {
    this.updatePager(page);

    this.activePage = page - 1;

    if (this.isFrontendSidePagination) {
      this.pageChange.emit(this.activePage);
    }
  }

  private updatePager(page: number): void {
    // get new pager object for specified page
    this.pager = this.paginationService.paginate(this.totalItems, this.totalPages, page, this.pageSize, 5);
  }
}
