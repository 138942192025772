<div class="aok-reminder-teaser">
  <div class="aok-reminder-teaser__preview tw-col-1-11 lg:tw-col-1-10">
    <h4 class="aok-reminder-teaser__title">{{ reminder?.title }}</h4>
    <div class="aok-reminder-teaser__content" [innerHtml]="reminder.content"></div>
    <div class="aok-reminder-teaser__buttons">
      <button class="secondary" (click)="openLink()">
        {{ reminder.actionButtonLabel }}
        <aok-svg-icon name="arrow-rounded_right" size="16px"></aok-svg-icon>
      </button>
    </div>
  </div>

  <div class="aok-reminder-teaser__image">
    <div *ngIf="!reminder.teaserThumbnail">
      <aok-svg-icon id="0393-calendar_31" size="64px"></aok-svg-icon>
    </div>
    <img *ngIf="reminder.teaserThumbnail" [src]="reminder.teaserThumbnail" [alt]="reminder.altTextTeaserThumbnail" />
  </div>
</div>
