import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AokDialogResult,
  AokHttpErrorDialog,
  AokHttpErrorSchema,
  AokLoadingStateService,
  DialogBase,
  DialogRef,
} from '@aok/common';

@Component({
  selector: 'aok-http-error-dialog',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./http-error-dialog.component.scss'],
  templateUrl: './http-error-dialog.component.html',
})
export class AokHttpErrorDialogComponent implements AokHttpErrorDialog, OnInit, DialogBase<AokDialogResult> {
  closable = true;
  headerText: string;
  error: AokHttpErrorSchema;

  constructor(
    public readonly dialogRef: DialogRef<AokDialogResult>,
    protected loadingOverlay: AokLoadingStateService
  ) {}

  get isServerError(): boolean {
    return this.error?.response?.status >= 500;
  }

  ngOnInit(): void {
    this.loadingOverlay.detach();
    this.headerText = this.isServerError ? 'Ein Serverfehler ist aufgetreten' : 'Ein Problem ist aufgetreten';
  }
}
