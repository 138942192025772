import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AokCmsReport,
  AokPage,
  AokPageQueryParams,
  AokReport,
  AokReportCategory,
  AokReportListOptions,
} from '../schemas';
import { AokHttpParamEncoder } from '../utils';
import { AokApiBaseClient } from './api-base.client';

export const REPORT_API_URL = new InjectionToken<string>('REPORT_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/reports',
});

@Injectable({ providedIn: 'root' })
export class AokReportClient extends AokApiBaseClient {
  constructor(@Inject(REPORT_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  list(options?: AokReportListOptions): Observable<AokPage<AokReport>> {
    return this.http.get<AokPage<AokReport>>(this.resolveUrl('/'), {
      params: new HttpParams({
        encoder: new AokHttpParamEncoder(),
        fromObject: { ...options },
      }),
    });
  }

  cmsList(options?: AokPageQueryParams): Observable<AokPage<AokCmsReport>> {
    return this.http.get<AokPage<AokCmsReport>>(this.resolveUrl('/'), {
      headers: { Accept: 'application/vnd.arztportal.cms.reports.v1+json' },
      params: new HttpParams({
        encoder: new AokHttpParamEncoder(),
        fromObject: { ...options },
      }),
    });
  }

  share(reportId: NonNullable<number>, userIds: Array<number>): Observable<AokReport> {
    return this.http.post<AokReport>(this.resolveUrl('share', reportId.toString()), userIds);
  }

  /**
   * a (possibly empty) list of AokReportCategory’s
   */
  categories(): Observable<AokReportCategory[]> {
    return this.http.get<AokReportCategory[]>(this.resolveUrl('categories'));
  }

  /**
   * returns reports for current doctor and given category
   * @param categoryId - id of the specific category
   */
  category(categoryId: number): Observable<AokReport[]> {
    return this.http.get<AokReport[]>(this.resolveUrl('category', categoryId.toString()));
  }

  /**
   * Triggers a 'download response' for the specified file
   * @param reportId - id of report to download
   */
  downloadReport(reportId: number): Observable<Blob> {
    return this.http.get(this.resolveUrl('download', reportId.toString()), {
      responseType: 'blob',
    });
  }

  deleteReport(reportId: number): Observable<void> {
    return this.http.delete<void>(this.resolveUrl(reportId.toString()));
  }

  createReport(file: File, report: Partial<AokReport>): Observable<AokReport> {
    const formData = new FormData();
    formData.append('fileContent', file);
    formData.append('ownerId', String(report.ownerId));
    formData.append('fileName', report.fileName);
    formData.append('reportCategory', report.reportCategory);
    return this.http.post<AokReport>(this.resolveUrl('/'), formData);
  }

  updateReportProps(report: Partial<AokReport>): Observable<AokReport> {
    return this.http.put<AokReport>(this.resolveUrl(String(report.id)), report);
  }
}
