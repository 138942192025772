import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, ElementRef, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AokToastService, DialogOverlay } from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FeedbackAvailableState } from '../../states';
import { FeedbackPopoverComponent } from '../feedback-popover/feedback-popover.component';

@Component({
  selector: 'aok-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AokFeedbackButtonComponent implements OnDestroy {
  public _opened = new BehaviorSubject<boolean>(false); // Specifies if the feedback form is opened
  private dialogRef;
  private readonly ngDestroys = new Subject<void>();

  constructor(
    public keycloak: KeycloakService,
    private dialog: DialogOverlay,
    private toastService: AokToastService,
    private route: ActivatedRoute,
    private element: ElementRef,
    private feedbackAvailableState: FeedbackAvailableState
  ) {
    this.toggleFeedbackButtonClass();
  }

  @Input()
  set open(value: boolean) {
    this._opened = new BehaviorSubject<boolean>(coerceBooleanProperty(value));
  }

  public get showFeedback(): Observable<boolean> {
    return this.feedbackAvailableState.isFeedBackAvailable.asObservable();
  }

  public ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  public openFeedbackPopover(event): void {
    if (this._opened.value) {
      this._opened.next(!this._opened.value);
      this.toggleFeedbackButtonClass();
      this.dialogRef.dispose();
      return;
    }

    // The button is floating whenever it's in the bottom right corner of the screen, event.y is the click's location
    const floating = event.y > window.innerHeight - 100;
    // isTrusted will be true only when the action comes from the user and not propagated from other events (header or footer "send feedback" links)
    const delay = event.isTrusted && floating ? 0 : 600;

    const feedback = document.getElementsByClassName('feedback-button').item(0) as HTMLElement;
    setTimeout(() => {
      feedback.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 0);
    setTimeout(() => {
      this._opened.next(!this._opened.value);
      this.toggleFeedbackButtonClass();
      const position = this.dialog
        .position()
        .flexibleConnectedTo(feedback)
        .withPositions([
          {
            // here, top-left of the overlay is connected to bottom-left of the origin;
            // of course, you can change this object or generate it dynamically;
            // moreover, you can specify multiple objects in this array for CDK to find the most suitable option
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
            offsetY: -8,
            offsetX: -8,
          } as ConnectedPosition,
        ])
        .withLockedPosition(true);

      this.dialogRef = this.dialog.create(FeedbackPopoverComponent, {
        closable: true,
        overlay: {
          positionStrategy: position,
          panelClass: 'feedback',
        },
        disposeOnBackdropClick: true,
        props: {
          route: this.route,
        },
      });

      this.dialogRef.subscribe((value) => {
        this._opened.next(false);
        this.toggleFeedbackButtonClass();
        if (value) {
          this.toastService.createSuccessToast(
            'Vielen Dank für Ihr Feedback!',
            'Ihr Feedback wurde erfolgreich versendet.'
          );
        }
      });
      //  Add timeout in order to allow the window to smoothly scroll until the popup can be visible
    }, delay);
  }

  private toggleFeedbackButtonClass(): void {
    const classes = this.element.nativeElement.classList;
    if (this._opened.value) {
      classes.remove('container-closed');
    } else {
      classes.add('container-closed');
    }
  }
}
