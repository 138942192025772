import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PrimitiveBehaviorState } from '@aok/common';

@Component({
  selector: 'aok-alert',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-content select="aok-svg-icon"></ng-content>
    <div class="alert-content">
      <ng-content></ng-content>
    </div>
    <aok-svg-icon
      *ngIf="closable"
      class="alert-close icon-button"
      name="close-small"
      size="32px"
      title="Schließen"
      aokFocusable
      (keyup.enter)="_closed.patch(true)"
      (click)="_closed.patch(true)"
    ></aok-svg-icon>
  `,
})
export class AokAlertComponent implements OnDestroy {
  public readonly _closed = new PrimitiveBehaviorState<boolean>();

  private _closable = true;
  private ngDestroy: Subject<void>;

  @HostBinding('class.hidden')
  private isHidden: boolean;

  @Input()
  set closable(value: boolean) {
    this._closable = coerceBooleanProperty(value);
  }

  get closable(): boolean {
    return this._closable;
  }

  @Output()
  public closed: EventEmitter<void>;

  constructor() {
    this.ngDestroy = new Subject<void>();
    this.closed = new EventEmitter<void>();
    this._closed
      .asObservable()
      .pipe(takeUntil(this.ngDestroy))
      .subscribe((value: boolean) => {
        this.isHidden = value;
        this.closed.emit();
      });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
