<div class="aok-notification">
  <div class="tw-flex tw-justify-between tw-items-center">
    <span class="time-since">
      {{ notification.createdDate | aokTimeSince : undefined : 'minute' }}
    </span>

    <aok-svg-icon
      [name]="isCollapsed ? 'arrow-down' : 'arrow-up'"
      class="icon-button"
      size="16px"
      (click)="toggleCollapse(notification.messageId)"
      aokFocusable
      (keyup.enter)="toggleCollapse(notification.messageId)"
    ></aok-svg-icon>
  </div>

  <div class="context">{{ contextInfo }}</div>

  <div class="tw-flex tw-justify-between tw-items-center">
    <a
      [class]="isRead() ? 'aok-text-p1 aok-bold' : 'aok-link-1'"
      class="padded-content"
      (click)="toggleCollapse(notification.messageId)"
      aokFocusable
      (keyup.enter)="toggleCollapse(notification.messageId)"
      [ngClass]="{ 'collapsed-title': isCollapsed }"
    >
      {{ notification.title }}
    </a>

    <aok-svg-icon
      *ngIf="!isCollapsed"
      id="delete"
      class="icon-button"
      size="16px"
      (click)="deleteNotification(notification.messageId)"
      aokFocusable
      (keyup.enter)="deleteNotification(notification.messageId)"
    ></aok-svg-icon>
  </div>

  <div
    class="content aok-text-p2 padded-content"
    [ngClass]="{ 'collapsed-notification': isCollapsed }"
    [innerHtml]="notification.content"
  ></div>
</div>
