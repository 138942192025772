import { Pipe, PipeTransform } from '@angular/core';
import { AokBsnrRegistrationStatus, AokUserRegistrationStatus } from '../../schemas';
import { getRegistrationStatusText } from '../../utils';

@Pipe({ name: 'aokRegistrationStatus', standalone: true })
export class AokRegistrationStatusIntlPipe implements PipeTransform {
  transform(value: AokUserRegistrationStatus | AokBsnrRegistrationStatus | string): string {
    return getRegistrationStatusText(value);
  }
}
