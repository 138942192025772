import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AokBenefit,
  AokBenefitCategory,
  AokBenefitRegion,
  AokBenefitResponse,
  BenefitDocumentRequest,
  IcdCode,
} from '../../schemas';
import { acceptMimeTypes } from '../validators/accept-mime-types.validator';
import { maxFileSize } from '../validators/max-file-size.validator';

export function createAokBenefitForm(schema?: AokBenefitResponse): FormGroup {
  const { required } = Validators;
  return new FormGroup({
    id: new FormControl<number>(schema?.id),
    name: new FormControl<string>(schema?.name, [required]),
    benefitCategory: new FormControl<AokBenefitCategory>(schema?.benefitCategory, [required]),
    benefitRegion: new FormControl<AokBenefitRegion>(schema?.benefitRegion, [required]),
    icdCodes: new FormControl<IcdCode[]>(schema?.icdCodes, [required]),
    keywords: new FormControl<string>(schema?.keywords, Validators.maxLength(1000)),
    serviceDescription: new FormControl<string>(schema?.serviceDescription, [required]),
    participationRequirements: new FormControl<string>(schema?.participationRequirements, [required]),
    additionalInformation: new FormControl<string>(schema?.additionalInformation),
    patientInformation: new FormControl<string>(schema?.patientInformation),
    benefitDocuments: createBenefitDocumentsInfoFormArray(schema),
    pdfDocuments: createBenefitDocumentsFormControl(),
  });
}

const createBenefitDocumentsInfoFormArray = (schema?: AokBenefitResponse): FormArray => {
  const formArray = new FormArray([]);
  if (schema?.benefitDocuments?.length) {
    schema?.benefitDocuments.forEach((document) => {
      formArray.push(new FormControl<BenefitDocumentRequest>(document));
    });
  }
  return formArray;
};

/**
 * Method used to create the form control for the file uploads
 */
export const createBenefitDocumentsFormControl = (): FormControl => {
  return new FormControl(null, [acceptMimeTypes(['application/pdf']), maxFileSize(10 * 1024 * 1024, false)]);
};

export function createBenefitFormData(benefit: Partial<AokBenefit>, documents: File[]): FormData {
  const formData = new FormData();
  formData.append(
    'benefitRequest',
    new Blob([JSON.stringify(benefit)], {
      type: 'application/json',
    })
  );
  if (documents?.length) {
    documents.forEach((document) => {
      formData.append('pdfDocuments', document);
    });
  }
  return formData;
}
