import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { A11yUtilsModule, AokSvgIconComponent, ContextState, CurrentUserState, isDoctor } from '@aok/common';
import {
  AccordionService,
  AokAccordionOptionModule,
  AokFooterModule,
  AokMenuButtonModule,
  AokTabsModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
  KvnState,
  ProfileService,
} from '@aok/components';
import { KeycloakService } from 'keycloak-angular';
import { PROFILE_ACCORDION_ID_PREFIX, PROFILE_PRIMARY_ACCORDION_ID } from '../../config/profile.config';

@Component({
  selector: 'aok-cockpit-profile',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AokAccordionOptionModule,
    AokMenuButtonModule,
    A11yUtilsModule,
    HeaderModule,
    InfoBannerComponent,
    AokSvgIconComponent,
    AokTabsModule,
    RouterModule,
    FeedbackButtonModule,
    AokFooterModule,
  ],
})
export class ProfileComponent implements OnInit {
  msgHeadline = 'Profil';
  private accordionIdPrefix = PROFILE_ACCORDION_ID_PREFIX;

  get isDoctor(): boolean {
    return isDoctor(this.currentUser.snapshot);
  }

  constructor(
    private currentUser: CurrentUserState,
    protected keycloak: KeycloakService,
    private accordionService: AccordionService,
    public router: Router,
    public route: ActivatedRoute,
    private contextState: ContextState,
    private profileService: ProfileService,
    protected kvnState: KvnState
  ) {}

  ngOnInit(): void {
    this.initialSetup();
  }

  private initialSetup(): void {
    const orgList = this.profileService.getAllOrgs();
    if (orgList?.length) {
      orgList.forEach((org) => {
        if (this.accordionService.getOpenOption(this.accordionIdPrefix + org.bsnr)) {
          this.accordionService.toggleAccordionOption(
            this.accordionIdPrefix + org.bsnr,
            this.accordionIdPrefix + org.bsnr
          );
        }
      });
      if (this.contextState.org && !this.accordionService.getOpenOption(PROFILE_PRIMARY_ACCORDION_ID)) {
        this.accordionService.openAccordion(
          PROFILE_PRIMARY_ACCORDION_ID,
          this.accordionIdPrefix + this.contextState.bsnr
        );
      }
      if (this.isDoctor) {
        this.profileService.loadAllUsers(this.profileService.getOrganisations().unselected);
      }
    }
  }
}
