import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { PrimitiveBehaviorState, scrollToTop } from '@aok/common';
import { AokStepComponent } from './step.component';

export interface StepChangeEvent {
  currentStep: AokStepComponent;
  newStep: AokStepComponent;
}

export enum StepperNavigationMode {
  FREE_SEQUENTIAL = 'FREE_SEQUENTIAL', // Forwards only possible for the following step. Backwards possible for all previous steps.
  STRICT_SEQUENTIAL = 'STRICT_SEQUENTIAL', // Forwards only possible for the following step. Backwards only possible for the previous step.
  FREE = 'FREE', // Forwards possible for all steps, backwards possible for all steps.
}

@Component({
  selector: 'aok-stepper',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./stepper.component.scss'],
  templateUrl: './stepper.component.html',
})
export class AokStepperComponent implements AfterContentInit {
  @Input() navMode = StepperNavigationMode.FREE_SEQUENTIAL;
  @Input() customButtonBar = false;
  @Input() btnTitleNext = 'Weiter';
  @Input() btnTitleBack = 'Zurück';
  @Input() btnTitleSubmit = 'Daten übermitteln';
  @Input() btnTitleSummary = 'Zur Zusammenfassung';

  @Output() submitted = new EventEmitter();
  @Output() stepChange = new EventEmitter<StepChangeEvent>();
  @Output() stepChanged = new EventEmitter<StepChangeEvent>();

  @ContentChildren(AokStepComponent) steps: QueryList<AokStepComponent>;

  public currentStep: AokStepComponent;
  readonly indexState = new PrimitiveBehaviorState<number>(0);

  constructor(private cd: ChangeDetectorRef) {}

  get isFirstStep(): boolean {
    return this.indexState.snapshot === 0;
  }

  get isLastStep(): boolean {
    return !this.steps?.length || this.indexState.snapshot === this.steps.length - 1;
  }

  get isSecondLastStep(): boolean {
    return this.indexState.snapshot === this.steps.length - 2;
  }

  get hasMultipleSteps(): boolean {
    return this.steps?.length > 1;
  }

  ngAfterContentInit(): void {
    // set the index for each step
    this.steps.toArray().forEach((element, index) => (element.index = index));
    // set active step
    const actives = this.steps.filter((step) => step.active);
    if (!actives.length && this.steps.length) {
      this.currentStep = this.steps.first;
      this.currentStep.active = true;
    } else {
      this.currentStep = actives[0];
    }
    this.cd.markForCheck();
    this.indexState.patch(this.currentStep.index);
  }

  public select(step: AokStepComponent): void {
    const currentIndex = this.currentStep.index;
    const newIndex = step.index;
    if (currentIndex === newIndex) {
      return;
    }
    // when strict sequential, we only allow visiting neighbour steps
    if (this.navMode === StepperNavigationMode.STRICT_SEQUENTIAL && !this.isNeighbour(currentIndex, newIndex)) {
      return;
    }
    // when free sequential we allow visiting neighbour steps and all previous steps
    if (
      this.navMode === StepperNavigationMode.FREE_SEQUENTIAL &&
      !this.isValidSuccessorOrPredecessor(currentIndex, newIndex)
    ) {
      return;
    }
    // if there is a validation function provided for that step: In error case we only allow jumping back to previous steps
    if (this.isSuccessor(currentIndex, newIndex) && this.currentStep.validate && !this.currentStep.validate()) {
      return;
    }
    // inform that we are about to change the step: If the event gets canceled, we stop
    const changeStepArgs = { currentStep: this.currentStep, newStep: step };
    this.stepChange.emit(changeStepArgs);
  }

  public activateStep(step: AokStepComponent): void {
    const currentIndex = this.currentStep.index;
    const newIndex = step.index;

    if (this.isSuccessor(currentIndex, newIndex)) {
      this.currentStep.visited = true;
    }

    this.steps.forEach((s) => (s.active = false));
    step.active = true;
    this.currentStep = step;
    this.indexState.patch(step.index);
    this.stepChanged.emit({ currentStep: this.currentStep, newStep: step });
    this.cd.markForCheck();
    scrollToTop();
  }

  public next(): void {
    if (!this.isLastStep) {
      this.select(this.steps.toArray()[this.indexState.snapshot + 1]);
    } else {
      this.select(this.steps.toArray()[0]);
    }

    setTimeout(() => this.setFocusOnFirstStepperInput());
  }

  public previous(): void {
    if (!this.isFirstStep) {
      this.select(this.steps.toArray()[this.indexState.snapshot - 1]);
    } else {
      this.select(this.steps.toArray()[this.steps.length - 1]);
    }

    setTimeout(() => this.setFocusOnFirstStepperInput());
  }

  public submit(): void {
    this.submitted.emit();
  }

  private isNeighbour(ind1: number, ind2: number): boolean {
    return Math.abs(ind1 - ind2) <= 1;
  }

  private isValidSuccessorOrPredecessor(ind1: number, ind2: number): boolean {
    // if the new step is after the current one, check if all intermediate steps are already valid
    if (ind1 < ind2) {
      let allValid = true;
      for (let i = ind1 + 1; i < ind2; i++) {
        if (!this.getStepByIndex(i).valid) {
          allValid = false;
          break;
        }
      }
      return allValid;
    }
    // true, if the new step is before the current step
    return ind1 > ind2;
  }

  private isSuccessor(ind1: number, ind2: number): boolean {
    return ind2 - ind1 > 0;
  }

  private getStepByIndex(ind: number): AokStepComponent {
    return this.steps.find((step) => step.index === ind);
  }

  private setFocusOnFirstStepperInput(): void {
    const element: HTMLElement = document.querySelector('aok-stepper input');
    element?.focus();
  }
}
