import { Provider } from '@angular/core';
import { AokKnownArticleCategoryRoute, AokStaticArticleRoute } from '@aok/common';
import { AokSitemap, SITEMAP } from '@aok/components';
import { APP_ROOT_ROUTES } from '../app.routes';
import { APP_HELP_ROUTES } from '../pages/help/help.module';
import { APP_ARTICLE_ROUTES } from '../pages/news/news.module';
import { APP_PROFILE_ROUTES } from '../pages/profile/profile.module';
import { APP_REPORTS_ROUTES } from '../pages/reports/reports.module';
import { APP_SERVICES_ROUTES } from '../pages/services/services.module';

export function createAppSitemap(): AokSitemap {
  return new AokSitemap({
    routes: APP_ROOT_ROUTES,
    lazyChildren: {
      berichte: { routes: APP_REPORTS_ROUTES },
      services: { routes: APP_SERVICES_ROUTES },
      hilfe: {
        routes: APP_HELP_ROUTES,
        routeParamOptions: {
          ':category': Object.values(AokKnownArticleCategoryRoute),
        },
      },
      profil: { routes: APP_PROFILE_ROUTES },
      aktuelles: { routes: APP_ARTICLE_ROUTES },
    },
    routeParamOptions: {
      ':articleIdOrKey': Object.values(AokStaticArticleRoute),
      'publik/:articleIdOrKey': Object.values({}), // we do not want to have the imprint pages in twice. So we only use the ones from ':articleIdOrKey'
    },
  });
}

export const APP_SITEMAP_PROVIDER: Provider = {
  provide: SITEMAP,
  useFactory: createAppSitemap,
};
