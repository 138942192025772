import { AokRegistrationOrgData, AokRegistrationUrgency, AokRegistrationUserData } from '.';

export interface AokBsnrRegistration {
  readonly id?: number;
  userData: AokRegistrationUserData;
  organizationData: AokRegistrationOrgData;
  currentOrganizations?: AokRegistrationOrgData[]; // fixme not on list
  status: AokBsnrRegistrationStatus;
  bsnrMessage?: string; // fixme not on list
  lanr: string; // fixme not on list
  lockedBy?: string; // keycloak Id
  lockedUntil?: number; // timestamp
  createdDate: number;
  urgency: AokRegistrationUrgency;
  lastModifiedByEmployee?: string;
  lastModifiedByEmployeeDate?: number;
}

export enum AokBsnrRegistrationStatus {
  FOR_REVIEW = 'FOR_REVIEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  INTERNAL_REVIEW = 'INTERNAL_REVIEW',
  REQUEST_RECEIVED = 'REQUEST_RECEIVED',
}

export enum AokBsnrUrgencyTooltipMessages {
  INTERNAL_REVIEW = 'hohe Dringlichkeit',
  FOR_REVIEW = 'hohe Dringlichkeit',
  REJECTED = 'niedrige Dringlichkeit',
}
