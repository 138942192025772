<!-- Main Navigation layer -->
<nav class="nav-elevation">
  <div class="nav-entry-list aok-container flex">
    <ng-container *ngFor="let entry of viewEntries | async; trackBy: navEntryTracker; let i = index">
      <div
        class="link-container"
        aokFocusable
        [ngClass]="{ 'parent-active': isParentEntryAndActive(entry) }"
        [routerLink]="entry?.linkUrl"
        [routerLinkActive]="(entry?.linkUrl && 'active') || ''"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="handleNavigation(entry)"
        (keyup.enter)="handleNavigation(entry, true)"
      >
        <ng-container *ngTemplateOutlet="link; context: { $implicit: entry }"></ng-container>
      </div>
    </ng-container>
  </div>
</nav>

<!-- Second Navigation layer-->
<ng-container *ngIf="activeEntry?.children as childEntryList" (clickOutside)="closeOnClickOutside($event)">
  <div class="nav-layer-1-container" cdkTrapFocus cdkTrapFocusAutoCapture="true">
    <div class="nav-layer-1 aok-container">
      <aok-svg-icon
        id="close"
        size="20px"
        aokFocusable
        (keyup.enter)="closeSubNavigation()"
        (click)="closeSubNavigation()"
      ></aok-svg-icon>
      <ng-container *ngFor="let entry of childEntryList; trackBy: navEntryTracker; let i = index">
        <div
          class="secondary-link-container"
          [target]="entry.target ?? '_self'"
          [routerLinkOrHref]="entry.contract ? interpolateContext(entry) : entry.linkUrl"
          (keyup.enter)="closeSubNavigation()"
          (click)="closeSubNavigation()"
        >
          <ng-container *ngTemplateOutlet="link; context: { $implicit: entry }"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template let-entry #link>
  <a title="{{ entry.name }}">
    {{ entry.name }}
    <aok-svg-icon *ngIf="entry.target === '_blank'" [name]="'arrow-rounded_rightup'" size="16px"></aok-svg-icon>
  </a>
</ng-template>
