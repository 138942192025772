import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'aok-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent {
  @Input() label = 'Wonach möchten Sie suchen?';
  @Input() placeholder = 'Suche';
  @Input() searchString = '';

  @Output() inputEvent = new EventEmitter();
  @Output() search = new EventEmitter();
  @Output() searchOnEnter = new EventEmitter();
  @Output() clearSearch = new EventEmitter();

  @ViewChild('searchInput') searchInput: ElementRef;

  @HostListener('keydown', ['$event']) selected(event: KeyboardEvent): void {
    if (document.activeElement.id === 'clear-search-icon') {
      return;
    }
    if (event.key === 'Enter' && this.searchString) {
      this.searchOnEnter.emit(this.searchString);
    }
  }

  public clearSearchHandler(): void {
    this.searchString = '';
    this.clearSearch.emit();
  }
}
