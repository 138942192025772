import { Injectable, inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ContextState, CurrentUserState } from '../states';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private keycloak = inject(KeycloakService);
  private contextState = inject(ContextState);
  private currentUser = inject(CurrentUserState);

  public async logout(reopenLastPage?: boolean): Promise<void> {
    this.currentUser.clear();
    this.contextState.clearSelectedContext();
    const keycloakLoginOptions = reopenLastPage
      ? { redirectUri: window.location.href }
      : {
          redirectUri: `${window.location.origin}/${window.location.search}`,
        };

    await this.keycloak.logout(this.keycloak.getKeycloakInstance()?.createLoginUrl(keycloakLoginOptions));
  }
}
