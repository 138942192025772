import { AokMessage } from '..';

export enum AokUserNotificationStatus {
  // BE might have more statuses; now we are only interested on the Read status
  READ = 'Read',
  VIEWED = 'Viewed',
}

export interface AokNotification extends AokMessage {
  messageId?: string;
  createdDate?: string;
  status: AokUserNotificationStatus;
}

export interface AokNotificationListResponse {
  content: AokNotification[];
}

export interface AokNotificationCountResponse {
  count: number;
}

export interface AokNotificationStateType {
  notificationCount: number;
  notifications: AokNotification[];
}
