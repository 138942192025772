import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  A11yUtilsModule,
  AokBenefit,
  AokBenefitCategory,
  AokBenefitRegion,
  AokBenefitSearchResult,
  AokSvgIconComponent,
  isSearchResult,
  RouterLinkOrHrefModule,
} from '@aok/common';

@Component({
  selector: 'aok-cockpit-benefit-accordion',
  standalone: true,
  imports: [CommonModule, AokSvgIconComponent, A11yUtilsModule, RouterLinkOrHrefModule],
  templateUrl: './benefit-accordion.component.html',
  styleUrls: ['./benefit-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitAccordionComponent {
  @Input() benefit: AokBenefitSearchResult | AokBenefit;
  @Input() favoriteEnabled: boolean;
  @Output() favorite = new EventEmitter<number>();

  public isOpen = false;
  private isFavoriteBlocked = false;

  constructor(private cd: ChangeDetectorRef) {}

  public get favoriteIconTitle(): string {
    if (!this.canMarkFavorite) {
      return 'Die maximale Anzahl von Favoriten wurde erreicht.';
    }

    return this.benefitFavorite ? 'Favorit entfernen' : 'Favorit erstellen';
  }

  public get benefitId(): number {
    return isSearchResult(this.benefit) ? this.benefit.documentId : this.benefit.id;
  }

  public get benefitTitle(): string {
    return isSearchResult(this.benefit) ? this.benefit.title : this.benefit.name;
  }

  public get benefitFavorite(): boolean {
    return isSearchResult(this.benefit) ? this.benefit.benefitFavorite : this.benefit.isFavorite;
  }

  public get benefitCategory(): string | AokBenefitCategory {
    return isSearchResult(this.benefit) ? this.benefit.benefitCategory : this.benefit.benefitCategory.categoryName;
  }

  public get benefitRegion(): string | AokBenefitRegion {
    return isSearchResult(this.benefit) ? this.benefit.benefitRegion : this.benefit.benefitRegion.regionName;
  }

  public get benefitIndications(): string[] {
    return isSearchResult(this.benefit)
      ? this.benefit.benefitIndications
      : this.benefit.icdCodes?.map((icdCode) => icdCode.icdCodeDescription);
  }

  public get canMarkFavorite(): boolean {
    return this.favoriteEnabled || this.benefitFavorite;
  }

  public toggleAccordion(): void {
    this.isOpen = !this.isOpen;
  }

  public markFavorite(benefitId: number): void {
    if (!this.isFavoriteBlocked && this.canMarkFavorite) {
      this.isFavoriteBlocked = true;
      this.favorite.emit(benefitId);
      // block multiple subsequent requests
      setTimeout(() => {
        this.isFavoriteBlocked = false;
        this.cd.markForCheck();
      }, 1000);
    }
  }
}
