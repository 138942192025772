import { AokBillingSheetTypeEnum, TableColumnDef } from '@aok/common';
import { RadioOptions } from '@aok/components';

export const radioOptions: RadioOptions[] = [
  {
    label: 'Muster 5 einlegen',
    value: AokBillingSheetTypeEnum.PrePrinted,
  },
  {
    label: 'Muster 5 ausdrucken',
    value: AokBillingSheetTypeEnum.FullPrint,
  },
];

export const errorListDisplayedColumns: TableColumnDef[] = [
  {
    label: 'Name',
    id: 'name',
  },
  {
    label: 'Geburtsdatum',
    id: 'dateOfBirth',
  },
  {
    label: 'Versichertennummer',
    id: 'insuranceNumber',
  },
  {
    label: 'Detailsuche',
    id: 'options',
  },
];

export const printListDisplayedColumns: TableColumnDef[] = [
  {
    label: 'Name',
    id: 'name',
  },
  {
    label: 'Adresse',
    id: 'address',
  },
  {
    label: 'Geburtsdatum',
    id: 'dateOfBirth',
  },
  {
    label: 'Versichertennummer',
    id: 'insuranceNumber',
  },
  {
    label: '',
    id: 'options',
  },
];
