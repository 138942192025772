import { inject } from '@angular/core';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { ConsentState } from '@aok/components';
import { first, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const consentChangedGuard: CanMatchFn = (): Observable<boolean | UrlTree> => {
  const consentState = inject(ConsentState);
  const router = inject(Router);

  return consentState.latestConsentAccepted$().pipe(
    first(),
    map((latestConsentAccepted) => {
      return !latestConsentAccepted || router.parseUrl('/');
    })
  );
};
