import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import {
  AokBenefit,
  AokBenefitClient,
  AokBenefitSearchOptions,
  AokBenefitSearchResult,
  AokPage,
  AokSearchClient,
  ContentType,
  DEFAULT_PAGE_RESPONSE,
} from '@aok/common';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const benefitsResolver: ResolveFn<AokPage<AokBenefitSearchResult> | AokPage<AokBenefit>> = (
  route: ActivatedRouteSnapshot
) => {
  const searchClient = inject(AokSearchClient);
  const benefitsClient = inject(AokBenefitClient);
  const { page = 0, size = 20, query = '', category = '', region = '' } = route.queryParams;

  if (query || category || region) {
    const searchOptions: AokBenefitSearchOptions = {
      page,
      size,
      query,
      category,
      region,
      documentType: ContentType.BENEFIT,
      enableHighlights: false,
    };
    return searchClient.list<AokBenefitSearchResult>(searchOptions);
  }

  return benefitsClient.list({ page, size }).pipe(catchError(() => of(DEFAULT_PAGE_RESPONSE)));
};
