import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { normalizeUrl } from '../utils/browser.util';

export const AOK_API_BASE_URL = new InjectionToken<string>('AOK_API_BASE_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api',
});

@Injectable()
export abstract class AokApiBaseClient {
  constructor(@Inject(AOK_API_BASE_URL) readonly baseUrl: string, protected http: HttpClient) {}

  resolveUrl(...segments: string[]): string {
    return normalizeUrl(this.baseUrl, ...segments);
  }
}
