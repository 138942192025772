import { Pipe, PipeTransform } from '@angular/core';
import { KnownUserSalutation } from '../../schemas';
import { getSalutationText } from '../../utils';

@Pipe({ name: 'aokUserSalutation', standalone: true })
export class AokUserSalutationIntlPipe implements PipeTransform {
  transform(value: KnownUserSalutation | string, exclude?: Array<KnownUserSalutation | string>): string {
    return exclude?.includes(value) ? '' : getSalutationText(value);
  }
}
