<dialog-layout [fullscreen]="false" [headerText]="headline" [main]="false" cdkTrapFocus cdkTrapFocusAutoCapture="true">
  <div class="shared-dialog-container">
    <p *ngIf="description" [innerHTML]="description"></p>

    <div class="selected-container" [formGroup]="form">
      <ng-container>
        <div *ngIf="docs.length > 0">
          <aok-input-field *ngIf="docs.length > 1">
            <aok-checkbox [formControl]="masterForm.get('docs')">
              <p class="aok-bold">Alle Ärzte auswählen</p>
            </aok-checkbox>
          </aok-input-field>

          <p class="aok-bold" *ngIf="docs.length === 1">Arzt auswählen</p>

          <ng-container [formArrayName]="'docs'">
            <div class="selectable-user" *ngFor="let mfa of getArray('docs').controls; let i = index">
              <aok-input-field>
                <aok-checkbox [class.disabled]="form.get(['docs', i]).disabled" [formControlName]="i">
                  {{ docs[i] | aokFullName : 'TT FF LL' : docs[i]?.practitionerResource }}
                </aok-checkbox>
              </aok-input-field>
            </div>
          </ng-container>
        </div>

        <div>
          <aok-input-field *ngIf="mfas.length > 1">
            <aok-checkbox [formControl]="masterForm.get('mfas')">
              <p class="aok-bold">Alle Mitarbeiter auswählen</p>
            </aok-checkbox>
          </aok-input-field>

          <p class="aok-bold" *ngIf="mfas.length === 1">Mitarbeiter auswählen</p>

          <ng-container [formArrayName]="'mfas'">
            <div class="selectable-user" *ngFor="let mfa of getArray('mfas').controls; let i = index">
              <aok-input-field>
                <aok-checkbox [class.disabled]="form.get(['mfas', i]).disabled" [formControlName]="i">
                  {{ mfas[i] | aokFullName : 'FF LL' }}</aok-checkbox
                >
              </aok-input-field>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <dialog-footer>
    <button class="secondary" (click)="cancel()">Abbrechen</button>
    <button (click)="submit()">Übernehmen</button>
  </dialog-footer>
</dialog-layout>
