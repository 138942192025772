import { Directive, ViewContainerRef } from '@angular/core';
import { FormControlErrorHost } from '@aok/common';

/**
 * Should be used on the container where you want the error message of a form to be displayed.
 * If not used, the error will be displayed inside a default container
 */
@Directive({ selector: '[aokFormErrorContainer]' })
export class FormErrorContainerDirective extends FormControlErrorHost {
  constructor(public readonly viewContainer: ViewContainerRef) {
    super();
  }
}
