import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AokReportCategory, AokReportPageData } from '@aok/common';
import { KvnState } from '@aok/components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { REPORTS_CATEGORY_LABELS, REPORTS_CATEGORY_ORDER } from '../../config/reports.config';
import { ReportsState } from '../../states/reports.state';

@Component({
  selector: 'aok-cockpit-reports',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsComponent implements OnDestroy {
  public msgHeader = 'Berichte';
  public msgHeaderHint = 'Hier finden Sie die Quartalsberichte der AOK Niedersachsen zu den angezeigten Themen.';
  public msgHeaderButton = 'Kontakt aufnehmen';

  public noReportsHeadline = 'Derzeit stehen keine aktuellen Berichte zur Verfügung.';

  public reportsData: AokReportPageData;
  public categoryLabels = REPORTS_CATEGORY_LABELS;
  public categoriesOrder = REPORTS_CATEGORY_ORDER;
  public showContactRequest: boolean;

  public enabledCategories = {
    hzv: environment.enableHZVReports,
    arzneimittel: environment.enableArzneimittelReports,
    heilmittel: environment.enableHeilmittelReports,
  };

  protected isKvnPracticeTeam: boolean;

  private ngDestroyed = new Subject<void>();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private reportsState: ReportsState,
    private kvnState: KvnState
  ) {
    this.isKvnPracticeTeam = this.kvnState.isKvnPracticeTeam();
    this.showContactRequest = environment.enableContactRequest && !this.isKvnPracticeTeam;
    this.reportsState
      .asObservable()
      .pipe(takeUntil(this.ngDestroyed))
      .subscribe((reportsData: AokReportPageData) => {
        this.reportsData = reportsData;
      });
  }

  ngOnDestroy(): void {
    this.reportsState.patch(null);
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  public openContactForm(): void {
    const commands = ['kontaktanfrage'];

    // the first child holds the category param on route
    const category = this.route.snapshot.firstChild?.paramMap?.get('category');

    if (category && this.hasReports()) {
      commands.push(category.toLowerCase());
    }

    this.router.navigate(commands);
  }

  public hasNoReports(category?: AokReportCategory): boolean {
    return !this.reportsData[category]?._embedded?.items?.length;
  }

  public hasReports(): boolean {
    if (!this.reportsData) {
      return false;
    }

    return Object.values(AokReportCategory).some((category) => !!this.reportsData[category]?._embedded?.items?.length);
  }
}
