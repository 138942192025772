import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AokReminder, AokReminderService } from '@aok/common';
import { environment } from '../../environments/environment';

export const dashboardRemindersResolver: ResolveFn<AokReminder[]> = () => {
  const reminderService = inject(AokReminderService);

  const enableNotifications = environment.enableNotifications;
  if (!enableNotifications) {
    return [];
  }

  return reminderService.retrieveDashboardRemindersOnceForContext();
};
