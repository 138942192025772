import { Routes } from '@angular/router';
import {
  AokRegistrationStatusIntlPipe,
  AokStaticArticleRoute,
  existingStaticArticle,
  FeedbackCategory,
  keycloakInitGuard,
  keycloakInitIfLoggedGuard,
  keycloakRoleGuard,
  KnownRoles,
  staticArticleResolver,
} from '@aok/common';
import { AokStaticArticlePage, DialogKey } from '@aok/components';
import { environment } from '../environments/environment';
import { CONTRACT_ROUTES } from './config/contracts.routes';
import { consentChangedGuard } from './guards/consent-changed.guard';
import { contactRequestGuard } from './guards/contact-request.guard';
import { contextSelectedGuard } from './guards/context-selected.guard';
import { kvnAllowedGuard } from './guards/kvn-allowed.guard';
import { kvnNotAllowedGuard } from './guards/kvn-not-allowed.guard';
import { tiErrorGuard } from './guards/ti-error.guard';
import { AokNotAccessiblePage } from './pages/not-accessible/not-accessible.component';
import { bsnrRegistrationsResolver } from './resolvers/bsnr-registrations.resolver';
import { currentUserResolver } from './resolvers/current-user.resolver';
import { dashboardArticleTeasersResolver } from './resolvers/dashboard-article-teasers.resolver';
import { dashboardRemindersResolver } from './resolvers/dashboard-reminders.resolver';
import { existingOrganizationsDataResolver } from './resolvers/existing-organizations-data.resolver';
import { isCardOrderingEnabledResolver } from './resolvers/is-card-ordering-enabled.resolver';
import { ownPracticesResolver } from './resolvers/own-practices.resolver';
import { practiceEmployeesResolver } from './resolvers/practice-employees.resolver';
import { searchResultResolver } from './resolvers/search-result.resolver';
import { svsOrganizationsDataResolver } from './resolvers/svs-organizations-data.resolver';

export const APP_ROOT_ROUTES: Routes = [
  {
    path: 'not-allowed',
    component: AokNotAccessiblePage,
    data: {
      sitemap: false,
    },
  },
  {
    path: 'uebersicht',
    loadComponent: () => import('./pages/dashboard/dashboard.component').then((com) => com.DashboardComponent),
    canMatch: [keycloakInitGuard, contextSelectedGuard, kvnAllowedGuard],
    data: {
      title: 'Übersicht',
      category: FeedbackCategory.ALLGEMEIN,
    },
    resolve: {
      articleTeasers: dashboardArticleTeasersResolver,
      reminders: dashboardRemindersResolver,
    },
  },
  {
    path: 'berichte',
    loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule),
    canMatch: [keycloakInitGuard],
    data: {
      browserTitle: 'Alle Berichte als Übersicht',
      title: 'Berichte',
      interimRoute: true,
      category: FeedbackCategory.BERICHTE,
    },
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/services/services.module').then((m) => m.ServicesModule),
    canMatch: [keycloakInitGuard],
    data: {
      browserTitle: 'Alle Services als Übersicht',
      title: 'Services',
      interimRoute: true,
    },
  },
  {
    path: 'aktuelles',
    loadChildren: () => import('./pages/news/news.module').then((m) => m.APP_ARTICLE_ROUTES),
    canMatch: [keycloakInitGuard],
    data: {
      browserTitle: 'Aktuelle News als Übersicht',
      title: 'Aktuelles',
      interimRoute: true,
      category: FeedbackCategory.ARTIKEL,
    },
  },
  {
    path: 'profil',
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.APP_PROFILE_ROUTES),
    canMatch: [keycloakInitGuard],
    resolve: {
      ownOrgs: ownPracticesResolver,
      employees: practiceEmployeesResolver,
      bsnrRegistration: bsnrRegistrationsResolver,
      selectedUser: currentUserResolver,
    },
    data: {
      title: 'Profil',
      interimRoute: true,
      category: FeedbackCategory.PROFILVERWALTUNG,
    },
    providers: [AokRegistrationStatusIntlPipe],
  },
  {
    path: 'kontaktanfrage/:category',
    canMatch: [keycloakInitGuard, contactRequestGuard],
    redirectTo: `/berichte/:category?openDialog=${DialogKey.OPEN_REPORT_CONTACT_DIALOG}`,
    data: {
      sitemap: false,
    },
  },
  {
    path: 'kontaktanfrage',
    canMatch: [keycloakInitGuard, contactRequestGuard],
    redirectTo: `/berichte/uebersicht?openDialog=${DialogKey.OPEN_REPORT_CONTACT_DIALOG}`,
    data: {
      sitemap: false,
    },
  },
  {
    path: 'suchergebnis',
    loadComponent: () => import('./pages/search/search.component').then((com) => com.SearchComponent),
    canMatch: [keycloakInitGuard],
    data: {
      title: 'Suchergebnisse',
      category: FeedbackCategory.ALLGEMEIN,
      sitemap: false,
    },
    runGuardsAndResolvers: 'always',
    resolve: { result: searchResultResolver },
  },
  {
    path: 'registrierungsanfrage',
    loadChildren: () => import('./pages/pending/app-pending.module').then((m) => m.AppPendingModule),
    canMatch: [keycloakInitGuard, keycloakRoleGuard],
    resolve: {
      svsOrganizationsData: svsOrganizationsDataResolver,
      existingOrganizationsData: existingOrganizationsDataResolver,
      isCardOrderingEnabled: isCardOrderingEnabledResolver,
    },
    data: {
      roles: [KnownRoles.PendingHealthcareProfessional, KnownRoles.PendingAssistant, KnownRoles.PendingKvn],
      sitemap: false,
    },
  },
  {
    path: 'hilfe',
    loadChildren: () => import('./pages/help/help.module').then((m) => m.APP_HELP_ROUTES),
    canMatch: [keycloakInitGuard],
    data: {
      browserTitle: 'Hilfe',
      title: 'Hilfe',
      interimRoute: true,
      category: FeedbackCategory.HILFE,
    },
  },
  {
    path: 'sitemap',
    loadComponent: () => import('./pages/sitemap/sitemap.component').then((com) => com.AokSitemapPage),
    canMatch: [keycloakInitGuard],
    data: {
      title: 'Sitemap',
      category: FeedbackCategory.ALLGEMEIN,
    },
  },
  {
    path: 'kontext',
    loadChildren: () => import('./pages/context-selector/context-selector.module').then((m) => m.ContextSelectorModule),
    canMatch: [keycloakInitGuard],
    data: {
      title: 'Praxis wählen',
      sitemap: false,
    },
  },
  {
    // changes to mandatory articles/consents
    path: 'aenderungen/:articleIdOrKey',
    loadChildren: () => import('./pages/consent-changes/consent-changes.module').then((m) => m.ConsentChangesModule),
    canMatch: [keycloakInitGuard, consentChangedGuard],
    resolve: {
      article: staticArticleResolver,
    },
    data: {
      paramKey: 'articleIdOrKey',
      title: {
        [AokStaticArticleRoute.Legal]: 'geänderte Nutzungsbedingungen',
      },
      sitemap: false,
    },
  },
  {
    path: 'contracts',
    component: AokNotAccessiblePage,
    canActivate: [() => false],
    children: CONTRACT_ROUTES,
    data: {
      title: 'Verträge',
      sitemap: environment.appHub,
      interimRoute: true,
    },
  },
  {
    path: 'barriere-melden',
    loadChildren: () => import('./pages/a11y-feedback/app-a11y-feedback.module').then((m) => m.AppA11yFeedbackModule),
    canMatch: [keycloakInitGuard],
    data: {
      title: 'Barriere melden',
      category: FeedbackCategory.ALLGEMEIN,
    },
  },
  {
    path: 'ti-fehler',
    loadComponent: () => import('./pages/ti-error/ti-error.component').then((com) => com.TIErrorComponent),
    canActivate: [keycloakInitGuard, tiErrorGuard],
    data: {
      sitemap: false,
    },
  },
  {
    path: 'kvn-fehler',
    loadComponent: () => import('./pages/kvn-error/kvn-error.component').then((com) => com.KvnErrorComponent),
    canActivate: [keycloakInitGuard, kvnNotAllowedGuard],
    data: { sitemap: false },
  },
  {
    path: 'publik/kvnregistriert',
    loadComponent: () =>
      import('./pages/kvn-after-email-confirmation-page/kvn-after-email-confirmation-page.component').then(
        (com) => com.KvnAfterEmailConfirmationPage
      ),
    data: { title: 'Registrierung abgeschlossen', sitemap: false },
  },
  {
    path: 'publik/vollregistriert',
    loadComponent: () =>
      import('./pages/kvn-already-directly-registered/kvn-already-directly-registered.component').then(
        (com) => com.KvnAlreadyDirectlyRegisteredComponent
      ),
    data: { title: 'Registrierung abgeschlossen', sitemap: false },
  },
  {
    path: 'publik/hkp',
    loadComponent: () =>
      import('./pages/form-completion-support-page/page-completion-support.component').then(
        (m) => m.PageCompletionSupportComponent
      ),
    data: {
      title: 'Ausfüllhilfe für die Verordnung häuslicher Krankenpflege',
      sitemap: false,
    },
  },
  {
    path: 'publik/leichte-sprache',
    loadChildren: () => import('./pages/easy-speech/easy-speech.module').then((m) => m.EasySpeechModule),
    data: { title: 'Leichte Sprache', sitemap: false },
  },
  {
    path: 'publik/:articleIdOrKey',
    component: AokStaticArticlePage,
    canActivate: [existingStaticArticle],
    resolve: {
      article: staticArticleResolver,
    },
    data: {
      paramKey: 'articleIdOrKey',
      title: {
        [AokStaticArticleRoute.Accessibility]: 'Erklärung zur Barrierefreiheit',
        [AokStaticArticleRoute.SignLanguage]: 'Gebärdensprache',
        [AokStaticArticleRoute.Legal]: 'Nutzungsbedingungen',
        [AokStaticArticleRoute.Privacy]: 'Datenschutz',
        [AokStaticArticleRoute.Imprint]: 'Impressum',
      },
    },
  },
  {
    path: 'leichte-sprache',
    loadChildren: () => import('./pages/easy-speech/easy-speech.module').then((m) => m.EasySpeechModule),
    canActivate: [keycloakInitIfLoggedGuard],
    data: { title: 'Leichte Sprache', sitemap: false },
  },
  {
    path: ':articleIdOrKey',
    component: AokStaticArticlePage,
    canActivate: [keycloakInitIfLoggedGuard, existingStaticArticle],
    resolve: {
      article: staticArticleResolver,
    },
    data: {
      paramKey: 'articleIdOrKey',
      title: {
        [AokStaticArticleRoute.Accessibility]: 'Erklärung zur Barrierefreiheit',
        [AokStaticArticleRoute.EasySpeech]: 'Leichte Sprache',
        [AokStaticArticleRoute.SignLanguage]: 'Gebärdensprache',
        [AokStaticArticleRoute.Legal]: 'Nutzungsbedingungen',
        [AokStaticArticleRoute.Privacy]: 'Datenschutz',
        [AokStaticArticleRoute.Imprint]: 'Impressum',
      },
      category: FeedbackCategory.ALLGEMEIN,
      showFeedback: environment.enableFeedback,
    },
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'uebersicht',
  },
];
