import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { DIALOG_PRESET, DialogPreset } from '@aok/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dialog-footer',
  encapsulation: ViewEncapsulation.None,
  template: ` <ng-template #ngContentTemplate>
      <ng-content></ng-content>
    </ng-template>
    <ng-container *ngIf="!preset?.disableFlexContainer; else ngContentTemplate">
      <ng-container *ngTemplateOutlet="ngContentTemplate"></ng-container>
    </ng-container>`,
})
export class AokDialogFooterComponent {
  constructor(@Optional() @Inject(DIALOG_PRESET) readonly preset: /* @dynamic */ DialogPreset) {}
}
