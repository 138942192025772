import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AokReport,
  AokReportClient,
  AokToastService,
  AokUser,
  AokUserClient,
  ContextState,
  DialogOverlay,
  downloadBlob,
  isDoctor,
} from '@aok/common';
import { AokShareDialog, AokSimpleDialog, ShareNotAvailableDialog } from '@aok/components';
import { KeycloakService } from 'keycloak-angular';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ReportsState } from '../states/reports.state';

interface ReportSharingUsers {
  docs: AokUser[];
  mfas: AokUser[];
}

@Injectable()
export class ReportsTableService {
  constructor(
    private client: AokReportClient,
    private reportsState: ReportsState,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: AokToastService,
    private dialog: DialogOverlay,
    private userClient: AokUserClient,
    private keycloak: KeycloakService,
    private contextState: ContextState,
    private reportClient: AokReportClient
  ) {}

  public downloadReport(report: AokReport): Observable<Blob> {
    return this.getReportFile(report.id).pipe(
      tap((file) => {
        downloadBlob(file, addFiletypeExtension(report.fileName));
      })
    );
  }

  public openReport(report: AokReport): Observable<Blob> {
    return this.getReportFile(report.id).pipe(
      tap((file) => {
        const fileUrl = URL.createObjectURL(new Blob([file], { type: 'application/pdf' }));
        window.open(fileUrl, '_blank');
      })
    );
  }

  public openDeleteReportDialog(report: AokReport): void {
    // TODO maybe use create simple dialog method from dialog manager
    this.dialog
      .create(AokSimpleDialog, {
        props: {
          headerText: 'Wollen Sie den Bericht jetzt löschen?',
          confirmButtonText: 'Bericht löschen',
          contentText:
            'Wenn Sie auf “Bericht löschen” klicken, wird der Bericht endgültig gelöscht und kann nicht wiederhergestellt werden.',
          cancelButtonText: 'Abbrechen',
          confirmFunction: (() => {
            this.deleteReport(report.id).subscribe();
          }) as never,
        },
      })
      .subscribe();
  }

  public openSharedDialog(report: AokReport): void {
    this.getUsersForSharing()
      .pipe(
        mergeMap((reportSharingUsers) => {
          return this.displayShareDialog(reportSharingUsers, report);
        }),
        mergeMap((shared: number[]) => {
          if (shared) {
            shared.push(report.ownerId);
            return this.reportClient.share(report.id, shared);
          }

          return EMPTY;
        })
      )
      .subscribe(() => {
        this.refreshData();
      });
  }

  private getUsersForSharing(): Observable<ReportSharingUsers> {
    const email = this.keycloak.getKeycloakInstance()?.tokenParsed?.email;

    // Get all users that have access
    return this.userClient
      .list({
        bsnr: this.contextState.bsnr,
        size: 1000,
      })
      .pipe(
        map((users) => users._embedded?.items?.filter((user) => user.email !== email)),
        map((users: AokUser[]) => {
          return users.reduce(
            (cache, user) => {
              if (isDoctor(user)) {
                cache.docs.push(user);
              } else {
                cache.mfas.push(user);
              }
              return cache;
            },
            {
              mfas: [],
              docs: [],
            } as ReportSharingUsers
          );
        })
      );
  }

  private displayShareDialog({ docs, mfas }: ReportSharingUsers, report: AokReport): Observable<number[] | void> {
    const description = `<p class="tw-px-11">Name: ${report.fileName} </p>`;
    if (docs.length || mfas.length) {
      return this.dialog.create(AokShareDialog, {
        closable: true,
        props: {
          userIdsWithAccess: report.userIdsWithAccess,
          docs,
          mfas,
          description: `${description}<p class="tw-px-11 tw-mt-8">Für die folgenden Personen freigegeben:</p>`,
        },
      });
    }

    return this.dialog.create(ShareNotAvailableDialog, {
      closable: true,
      props: {
        description,
        hint: 'Ihrer Praxis sind keine weiteren Personen zugeordnet. Sobald sich weitere Ärzte oder Praxismitarbeiter Ihrer Praxis registrieren, können Sie die Freigabeoptionen verwenden.',
      },
    });
  }

  private deleteReport(reportId: number): Observable<void> {
    return this.client.deleteReport(reportId).pipe(
      tap(() => {
        this.toastService.createSuccessToast('Vorgang erfolgreich', 'Das Dokument wurde erfolgreich gelöscht.');
        this.refreshData();
      }),
      catchError(() => {
        this.toastService.createSupportErrorToast(
          'Vorgang fehlgeschlagen',
          'Das Dokument konnte nicht gelöscht werden. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
        );
        return EMPTY;
      })
    );
  }

  private getReportFile(reportId: number): Observable<Blob> {
    return this.client.downloadReport(reportId).pipe(
      catchError(() => {
        this.toastService.createErrorToast(
          'Bericht nicht mehr verfügbar',
          'Der ausgewählte Bericht ist nicht mehr verfügbar.'
        );

        return EMPTY;
      })
    );
  }

  /**
   * Force update of the currently opened category
   */
  private refreshData(): void {
    const newReportsData = this.reportsState.snapshot;
    delete newReportsData[this.route.snapshot.paramMap.get('category')];
    this.reportsState.patch(newReportsData);
    this.router.navigate(['./'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }
}

function addFiletypeExtension(fileName: string): string {
  return fileName.toLowerCase().split('.').includes('pdf') ? fileName : fileName + '.pdf';
}
