import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { A11yUtilsModule, AokSvgIconComponent, TrimControlDirective } from '@aok/common';
import { AokInputFieldModule } from '../cdk';
import { AokCheckboxModule } from '../checkbox/checkbox.module';
import { AokDropdownModule } from '../dropdown/dropdown.module';
import { FormControlErrorModule } from '../form-control-error/module';
import { FeedbackPopoverComponent } from './feedback-popover.component';

@NgModule({
  declarations: [FeedbackPopoverComponent],
  exports: [FeedbackPopoverComponent],
  imports: [
    AokSvgIconComponent,
    AokInputFieldModule,
    AokDropdownModule,
    ReactiveFormsModule,
    AokCheckboxModule,
    FormControlErrorModule,
    CommonModule,
    A11yModule,
    A11yUtilsModule,
    RouterModule,
    TrimControlDirective,
  ],
})
export class FeedbackPopoverModule {}
