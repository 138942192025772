import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'aokRouteParam' })
export class AokRouteParamPipe implements PipeTransform {
  constructor(protected route: ActivatedRoute) {}

  transform(paramKey: string): Observable<string | null> {
    return this.route.params.pipe(map((params) => params[paramKey]));
  }
}
