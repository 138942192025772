<dialog-layout
  [headerText]="'Etwas hat nicht geklappt'"
  [fullscreen]="false"
  cdkTrapFocusAutoCapture="true"
  cdkTrapFocus
>
  <p>
    Die Verbindung zur Anwendung CardToWeb konnte nicht hergestellt werden. Entweder ist die Anwendung nicht installiert
    oder diese konnte nicht gestartet werden.
  </p>
  <p>
    Falls die CardToWeb-Anwendung nicht installiert ist, können Sie sie über den folgenden Link herunterladen und
    installieren:
    <a class="aok-link-1" aokFocusable (click)="downloadAppInstaller()" (keyup.enter)="downloadAppInstaller()"
      >Download</a
    >
  </p>
  <p>
    Über den folgenden Link können Sie die Anleitung herunterladen:
    <a class="aok-link-1" aokFocusable (click)="downloadUserManual()" (keyup.enter)="downloadUserManual()">Anleitung</a>
  </p>

  <p>
    Sollte die Anwendung nicht gestartet sein, klicken Sie auf diesen Link:
    <a class="aok-link-1" aokFocusable (click)="startCardReaderApp()" (keyup.enter)="startCardReaderApp()">Starten</a>
  </p>

  <dialog-footer>
    <button (click)="cancel()">OK</button>
  </dialog-footer>
</dialog-layout>
