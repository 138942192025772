import { ElementRef, Renderer2 } from '@angular/core';
import { ElementStateFilter } from './element-state-filter';
import { EventUnlistener } from '../event-manager/event-manager-plugin';
import { PrimitiveBehaviorState } from '../rx/behavior-state';

export abstract class ElementState extends PrimitiveBehaviorState<boolean> {
  private _unlistener: EventUnlistener[] = [];

  /** Gets os sets the class name that's applied and removed to the {@link nativeElement} */
  abstract className: string;

  /** Gets whether the state has a valid class name or not */
  get hasClassName(): boolean {
    return this.className != null && this.className.trim() != '';
  }

  get true(): boolean {
    return this.snapshot;
  }
  get false(): boolean {
    return !this.snapshot;
  }

  /** Gets the {@link Element} the state is attached to */
  readonly nativeElement: Element;

  /**
   * Gets the {@link Renderer2} of the element state instance. If this property is `null` than
   * the implementation will fall back to the native {@link Element} features. This includes
   * event listening as well as class rendering
   */
  readonly renderer: Renderer2 | null;

  constructor(element: ElementRef<Element> | Element, renderer?: Renderer2) {
    super(false);
    this.renderer = renderer;
    this.nativeElement = element instanceof ElementRef ? element.nativeElement : element;
  }

  setTrue(): void {
    if (this.hasClassName) {
      if (this.renderer instanceof Renderer2) {
        // renderer2 impl
        this.renderer.addClass(this.nativeElement, this.className);
      } else {
        // native DOM impl
        this.nativeElement.classList.add(this.className);
      }
    }
    if (!this.snapshot) this.subject.next(true);
  }
  setFalse(): void {
    if (this.hasClassName) {
      if (this.renderer instanceof Renderer2) {
        // renderer2 impl
        this.renderer.removeClass(this.nativeElement, this.className);
      } else {
        // native DOM impl
        this.nativeElement.classList.remove(this.className);
      }
    }
    if (this.snapshot) this.subject.next(false);
  }

  dispose(): void {
    this.unlistenAll();
  }

  protected trueOn(eventName: string, filter?: ElementStateFilter): EventUnlistener {
    return this.listen(eventName, (e) => {
      if (filter == null || filter(e)) this.setTrue();
    });
  }
  protected falseOn(eventName: string, filter?: ElementStateFilter): EventUnlistener {
    return this.listen(eventName, (e) => {
      if (filter == null || filter(e)) this.setFalse();
    });
  }

  protected listen(eventName: string, listener: EventListener): EventUnlistener {
    const unlistener = this.renderer.listen(this.nativeElement, eventName, listener);

    this._unlistener.push(unlistener);
    return unlistener;
  }
  protected unlistenAll(): void {
    for (const unlisten of this._unlistener) unlisten();
  }
}
