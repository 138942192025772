import { APP_BOOTSTRAP_LISTENER, Provider } from '@angular/core';
import { UserService } from '@aok/common';
import { InfoBannerState } from '@aok/components';
import { mergeMap, take } from 'rxjs/operators';

export function initializeInfoBanner(userService: UserService, infoBannerState: InfoBannerState) {
  return (): void => {
    userService
      .loadKeycloakInstance()
      .pipe(
        // wait for until keycloak is ready, then start initializing the specialization data, if we don't wait for the
        // ready event here we wouldn't have a valid session to fetch the specializations provider with
        take(1),
        mergeMap(() => infoBannerState.init())
      )
      .subscribe();
  };
}

export const AOK_INFO_BANNER_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: initializeInfoBanner,
  deps: [UserService, InfoBannerState],
  multi: true,
};
