import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional, Type } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ConnectedDialogRef, DialogOverlay } from '../../cdk';
import { AokHttpErrorDialog, AokHttpErrorSchema, ENVIRONMENT, EnvironmentSchema } from '../../schemas';

export const AOK_HTTP_ERROR_DIALOG = new InjectionToken<Type<AokHttpErrorDialog>>('AOK_HTTP_ERROR_DIALOG');

@Injectable()
export class AokHttpErrorInterceptor implements HttpInterceptor {
  dialogRef: ConnectedDialogRef<any> | null;
  private ignoredUrls: string[] = [];

  constructor(
    protected dialog: DialogOverlay,
    @Inject(AOK_HTTP_ERROR_DIALOG) readonly errorDialogType: Type<AokHttpErrorDialog>,
    @Inject(ENVIRONMENT) private environment: EnvironmentSchema,
    @Optional() protected keycloak?: KeycloakService
  ) {
    this.ignoredUrls.push(`${this.environment.cardReaderApiUrl}`);
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // enforcing existence of a json error object here, sometimes there are blobs
        // inside the error payload, this might be due to the response type used in some
        // http client requests, especially the report client ones
        return error.error instanceof Blob
          ? of(error).pipe(
              mergeMap(() => from((error.error as Blob).text())),
              mergeMap((text) => throwError(() => new HttpErrorResponse({ ...error, error: JSON.parse(text) })))
            )
          : throwError(() => error);
      }),
      catchError((error: HttpErrorResponse) => {
        const { status, url } = error;

        if (this.ignoredUrls.some((ignoredUrl) => url.includes(ignoredUrl))) {
          return throwError(() => error);
        }

        // on such a generic error handling level we only want to cover 400 and 500+ error responses the response will
        // still get thrown "down" to the declaration level the http call originated from
        if (this.keycloak?.getKeycloakInstance()?.authenticated && (status === 400 || status >= 500)) {
          const aokError: AokHttpErrorSchema = Array.isArray(error.error) ? error.error[0] : error.error;

          // this impl implicitly accepts error schemas without the display field set at all
          if (aokError != null && (aokError.display == null || aokError.display))
            this.dialogRef = this.dialog.create(this.errorDialogType, {
              props: { error: { ...aokError, response: error } },
            });
        }

        return throwError(() => error);
      })
    );
  }
}
