import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AokEmailNotificationRequest } from '../schemas';
import { AokApiBaseClient } from './api-base.client';

export const AOK_EMAIL_NOTIFICATION_API_URL = new InjectionToken<string>('AOK_EMAIL_NOTIFICATION_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/email/notifications',
});

@Injectable({ providedIn: 'root' })
export class AokEmailNotificationClient extends AokApiBaseClient {
  constructor(@Inject(AOK_EMAIL_NOTIFICATION_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  /**
   * sends a message via the notification service to its specified destination. Not to be mixed up with system notifications!
   * @param request
   * @returns
   */
  submit(request: AokEmailNotificationRequest): Observable<void> {
    return this.http.post<void>(this.resolveUrl(), request);
  }
}
