import { AokEmailErrorMessageComponent } from './email-error-message/email-error-message.component';
import { AokRequiredErrorMessageComponent } from './required-error-message/required-error-message.component';

export const DEFAULT_ERRORS = {
  firstname: 'Der Vorname enthält unzulässige Zeichen wie Zahlen oder Sonderzeichen.',
  lastname: 'Der Nachname enthält unzulässige Zeichen wie Zahlen oder Sonderzeichen.',
  required: AokRequiredErrorMessageComponent,
  email: AokEmailErrorMessageComponent,
  zipCode: 'Ungültige PLZ',
  phoneDevice: 'Bitte geben Sie eine gültige Nummer an',
  houseNumber: 'Ihre Eingabe darf nur Zahlen, Buchstaben sowie “/” und “-” enthalten.',
  requiredTrue: 'Bitte aktivieren Sie dieses Pflichtfeld.',
  wrongBillingQuarter: 'Die Erstellung des Abrechnungsscheines ist nur für das aktuelle Quartal möglich.',
  dateFormat: "Das Datum hat ein falsches Format. Die Schreibweise ist '01.01.2000'.",
  matDatepickerParse: 'Das Datum hat ein falsches Format: Es muss dem Format "TT.MM.JJJJ" entsprechen.',
  matDatepickerMax: 'Das Datum muss in der Vergangenheit liegen.',
  birthdayRange: 'Das Datum muss in der Vergangenheit liegen.',
  emailsNotMatching: 'Die E-Mails stimmen nicht überein.',
  insuranceFormat: 'Die Versichertennummer hat ein falsches Format. Bitte überprüfen Sie Ihre Eingabe.',
  existMail: 'Die E-Mail-Adresse wird bereits für einen eingeladenen oder registrierten Nutzer verwendet.',
  bsnr: 'Bitte geben Sie eine gültige 9-stellige BSNR an',
  lanr: 'Bitte geben Sie eine gültige LANR an.',
  combinationLanrBsnr: 'Die Kombination aus BSNR und LANR ist ungültig.',
  alreadyRegistered: 'Sie sind bereits dieser BSNR zugeordnet.',
  specialization: 'Bitte geben Sie einen gültigen Fachgruppenschlüssel an.',
  equalControl: 'Stimmen nicht überein.',
};
