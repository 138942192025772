import { Injectable } from '@angular/core';
import {
  AokBenefit,
  AokBenefitCategory,
  AokBenefitClient,
  AokBenefitRegion,
  AokBenefitSearchResult,
  AokPage,
  DEFAULT_PAGE_RESPONSE,
  isSearchResult,
} from '@aok/common';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BenefitsState } from '../states/benefits.state';

@Injectable({
  providedIn: 'root',
})
export class BenefitsService {
  constructor(private client: AokBenefitClient, private benefitsState: BenefitsState) {}

  public retrieveFavorites(): Observable<AokPage<AokBenefit>> {
    const favoriteBenefits = this.benefitsState.getFavoriteBenefits();
    if (favoriteBenefits) {
      return of(favoriteBenefits);
    }

    const options = {
      page: 0,
      size: 20,
      favorite: true,
    };

    return this.client.list(options).pipe(
      catchError(() => of(DEFAULT_PAGE_RESPONSE)),
      tap((favorites) => this.benefitsState.setFavoriteBenefits(favorites))
    );
  }

  public retrieveCategories(): Observable<AokBenefitCategory[]> {
    const categories = this.benefitsState.getCategories();
    if (categories.length) {
      return of(categories);
    }

    return this.client
      .getCategories()
      .pipe(tap((categoriesResponse) => this.benefitsState.setCategories(categoriesResponse)));
  }

  public retrieveRegions(): Observable<AokBenefitRegion[]> {
    const regions = this.benefitsState.getRegions();
    if (regions.length) {
      return of(regions);
    }

    return this.client.getRegions().pipe(tap((regionsResponse) => this.benefitsState.setRegions(regionsResponse)));
  }

  public toggleFavorite(benefitId: number): Observable<AokPage<AokBenefit>> {
    return this.client.toggleFavorite(benefitId).pipe(
      tap((updatedFavorites) => this.benefitsState.setFavoriteBenefits(updatedFavorites)),
      catchError(() => {
        return this.retrieveFavorites();
      })
    );
  }

  public isFavoriteEnabled(): Observable<boolean> {
    return this.benefitsState.getFavoriteBenefits$().pipe(
      map((favorites) => favorites?._embedded?.items?.length || 0),
      map((favoritesCount) => favoritesCount < 20)
    );
  }

  public markFavoriteBenefits(
    benefits: AokPage<AokBenefitSearchResult | AokBenefit>,
    favorites: AokPage<AokBenefit>
  ): AokPage<AokBenefitSearchResult | AokBenefit> {
    const favoriteBenefits = favorites?._embedded?.items || [];
    const benefitsList: (AokBenefitSearchResult | AokBenefit)[] = benefits._embedded?.items || [];

    const updatedBenefitsList: (AokBenefitSearchResult | AokBenefit)[] = benefitsList.map((benefit) => {
      const [benefitId, attribute] = this.getBenefitInfo(benefit);
      const favorite = favoriteBenefits?.find((f) => f.id === benefitId);

      benefit[attribute] = !!favorite;

      return { ...benefit };
    });

    return { ...benefits, _embedded: { items: [...updatedBenefitsList] } };
  }

  private getBenefitInfo(benefit: AokBenefitSearchResult | AokBenefit): (number | string)[] {
    return isSearchResult(benefit) ? [benefit.documentId, 'benefitFavorite'] : [benefit.id, 'isFavorite'];
  }
}
