import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AokReminder, AokUser } from '@aok/common';
import { ReminderActionService } from '../../../services';

@Component({
  selector: 'aok-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AokReminderComponent {
  @Input()
  public reminder: AokReminder;

  @Input()
  public showContext: boolean;

  @Input()
  public contextInfo: string;

  @Output()
  public actionClicked = new EventEmitter<void>();

  public isCollapsed: boolean;

  public context: AokUser;

  constructor(private reminderActionService: ReminderActionService) {
    this.isCollapsed = true;
  }

  public toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public openLink(): void {
    this.reminderActionService.handleReminderLinkClick(this.reminder);
    this.actionClicked.emit();
  }

  public hasAction(): boolean {
    return !!(this.reminder.actionLink && this.reminder.actionButtonLabel);
  }
}
