import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  Predicate,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationEntries } from './navigation-entries.state';
import { NavigationEntryContainer } from './navigation-entry-container.directive';
import { NavigationEntryDefContext } from './navigation-entry-def.directive';
import { NavigationEntry } from './navigation-entry.schema';

@Component({
  selector: 'aok-nav-entry-outlet',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-template #fallbackEntryTemplate let-entry>
      <div class="nav-entry" [aokNavEntryLink]="entry" routerLinkActive="active" target="_blank">
        {{ entry.name }}
      </div>
    </ng-template>

    <ng-container *ngFor="let entry of viewEntries | async">
      <ng-container
        *ngTemplateOutlet="resolveEntryTemplate(entry) || fallbackEntryTemplate; context: { $implicit: entry }"
      >
      </ng-container>
    </ng-container>
  `,
})
export class NavigationEntryOutletComponent extends NavigationEntryContainer {
  /** Gets or sets a filter {@link Predicate} for the rendered {@link NavigationEntry}s */
  @Input() filter: Predicate<NavigationEntry>;

  get entries(): NavigationEntry[] {
    return this.customState.snapshot || this.state.snapshot;
  }

  @Input()
  set entries(value: NavigationEntry[]) {
    this.customState.reset(...value);
  }

  /**
   * An instance of custom {@link NavigationEntries} to use for this outlet. This will not overwrite
   * any existing entries stored in {@link NavigationEntries}. Those will only be preferred for
   * rendering and stored locally on the component instance. Any existing {@link filter} value will
   * be applied nevertheless
   */
  protected readonly customState = new NavigationEntries();
  /** Gets the {@link Observable} of {@link NavigationEntry}s that's used for rendering */
  protected viewEntries: Observable<NavigationEntry[]>;

  constructor(protected injector: Injector, protected readonly state: NavigationEntries) {
    super();
    this.viewEntries = merge(this.customState.asObservable(), this.state.asObservable()).pipe(
      map(() => {
        const entries = this.customState.length ? this.customState.snapshot : this.state.snapshot;
        return typeof this.filter === 'function' ? entries.filter(this.filter) : entries;
      })
    );
  }

  resolveEntryTemplate(entry: NavigationEntry): TemplateRef<NavigationEntryDefContext> | undefined {
    return super.resolveEntryTemplate(entry);
  }
}
