import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AOK_HTTP_ERROR_DIALOG, AokHttpErrorInterceptor } from './http-error.interceptor';
import { AokHttpErrorInterceptorModuleConfig } from '../../schemas';

@NgModule()
export class AokHttpErrorInterceptorModule {
  static forRoot(config: AokHttpErrorInterceptorModuleConfig): ModuleWithProviders<AokHttpErrorInterceptorModule> {
    return {
      ngModule: AokHttpErrorInterceptorModule,
      providers: [
        {
          provide: AOK_HTTP_ERROR_DIALOG,
          useValue: config.dialogComponent,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AokHttpErrorInterceptor,
          multi: true,
        },
      ],
    };
  }
}
