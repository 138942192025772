export const dmpCaseFeedbackCategoryOptions = [
  { label: 'DMP nicht sinnvoll', value: 'DMP_NOT_SENSIBLE', disabled: true },
  { label: 'Medizinisch nicht sinnvoll', value: 'MEDICALLY_NOT_USEFUL', isChild: true },
  { label: 'Keine Mitwirkung', value: 'NO_PARTICIPATION', isChild: true },
  { label: 'Keine DMP-Diagnose', value: 'NO_DMP_DIAGNOSIS', isChild: true },
  { label: 'Patient hat kein Interesse', value: 'PATIENT_NO_INTEREST', isChild: true },
  { label: 'Anderer Grund', value: 'OTHER_REASON', isChild: true },
  { label: 'Wohnortwechsel', value: 'CHANGE_RESIDENCE' },
  { label: 'Einschreibung in anderes DMP (Diagnosewechsel)', value: 'OTHER_ENROLMENT', disabled: true },
  {
    label: 'Asthma bronchiale',
    value: 'BRONCHIAL_ASTHMA',
    isChild: true,
  },
  {
    label: 'Chronisch obstruktive Lungenerkrankung',
    value: 'CHRONIC_PULMONARY',
    isChild: true,
  },
  {
    label: 'Diabetes mellitus Typ 1',
    value: 'DIABETES_TYPE_1',
    isChild: true,
  },
  {
    label: 'Diabetes mellitus Typ 2',
    value: 'DIABETES_TYPE_2',
    isChild: true,
  },

  { label: 'Arztwechsel zu', value: 'CHANGE_OF_DOCTOR' },
  { label: 'Dokumentation erstellt', value: 'DOCUMENTATION_CREATED' },
  { label: 'Neueinschreibung erstellt', value: 'NEW_ENROLMENT_CREATED' },

  { label: 'Termin bereits vereinbart für', value: 'APPOINTMENT_ALREADY_MADE', disabled: true },
  { label: 'Aktuelles Quartal', value: 'CURRENT_QUARTER', isChild: true },
  { label: 'Folgendes Quartal', value: 'NEXT_QUARTER', isChild: true },
  { label: 'Anderer Zeitraum', value: 'OTHER_PERIOD', isChild: true },

  { label: 'Sonstiges', value: 'OTHER' },
];
