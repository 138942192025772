import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FORM_ERRORS, FormErrorsMapping } from '@aok/common';
import { FormControlErrorDirective } from './form-control-error.directive';
import { FormErrorContainerDirective } from './form-error-container.directive';
import { FormErrorComponent } from './form-error.component';
import { FormSubmitDirective } from './form-submit.directive';

export interface FormErrorsModuleConfig {
  errors: FormErrorsMapping;
}

const declarations = [FormErrorComponent, FormErrorContainerDirective, FormControlErrorDirective, FormSubmitDirective];

@NgModule({
  declarations,
  exports: declarations,
  imports: [ReactiveFormsModule, CommonModule, FormsModule],
})
export class FormControlErrorModule {
  static withConfig(config: FormErrorsModuleConfig): ModuleWithProviders<FormControlErrorModule> {
    return {
      ngModule: FormControlErrorModule,
      providers: [{ provide: FORM_ERRORS, useValue: config.errors }],
    };
  }
}
