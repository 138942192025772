import { Injectable } from '@angular/core';
import { AokDoctorRelationship, AokOrg, AokUserClient, ContextState, CurrentUserState, isDoctor } from '@aok/common';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  constructor(
    private userClient: AokUserClient,
    private currentUser: CurrentUserState,
    private contextState: ContextState
  ) {}

  public retrieveContextOptions(): Observable<AokDoctorRelationship[]> {
    return this.userClient.getContextMenuOptions().pipe(tap((relations) => this.setContextOptions(relations)));
  }

  public setDefaultOption(contextOptions: AokDoctorRelationship[]): void {
    isDoctor(this.currentUser.snapshot)
      ? this.setDefaultOptionForDoctor(contextOptions)
      : this.setDefaultOptionForAssistant(contextOptions);
  }

  private setContextOptions(relationships?: AokDoctorRelationship[]): void {
    this.contextState.setContextSelectorOptions(relationships);
  }

  private setDefaultOptionForDoctor(contextOptions: AokDoctorRelationship[]): void {
    this.contextState.setPractitioner(this.currentUser.snapshot);
    this.contextState.setOrg(contextOptions[0].organizationData as AokOrg);
  }

  private setDefaultOptionForAssistant(contextOptions: AokDoctorRelationship[]): void {
    const firstOption = contextOptions[0];
    this.contextState.setPractitioner(firstOption.userData);
    this.contextState.setOrg(firstOption.organizationData as AokOrg);
  }
}
