<dialog-layout fullscreen="false" [isClosable]="closable" [headerText]="headerText" aokFocusFirstChild cdkTrapFocus>
  <div id="errorMessage">
    <aok-svg-icon id="exclamation-triangle" size="24"></aok-svg-icon>
    <ng-container *ngIf="isServerError; else customErrorMessage">
      <p>
        Ein interner Serverfehler ist aufgetreten. Bitte kontaktieren Sie einen Systemadministrator, sollte das Problem
        weiter bestehen.
      </p>
    </ng-container>
    <ng-template #customErrorMessage>
      <p>{{ error.message }}</p>
    </ng-template>
  </div>
  <dialog-footer>
    <div>
      <button class="secondary" dialogDispose>Schließen</button>
    </div>
  </dialog-footer>
</dialog-layout>
