import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  A11yUtilsModule,
  AokBenefitClient,
  AokBenefitResponse,
  AokRemoveFileExtensionPipe,
  AokSvgIconComponent,
  BenefitDocumentResponse,
  downloadBlob,
  FileSizePipe,
  scrollToFragmentWithOffset,
} from '@aok/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import {
  AokArticleContentModule,
  AokFooterModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
} from '@aok/components';
import { prepareHtmlForEmailBody } from '../../../../utils/strip-html.util';

interface Section {
  title: string;
  id: string;
}

@Component({
  selector: 'aok-cockpit-benefit-detail-view',
  standalone: true,
  imports: [
    CommonModule,
    AokArticleContentModule,
    AokFooterModule,
    InfoBannerComponent,
    HeaderModule,
    FeedbackButtonModule,
    RouterLink,
    AokSvgIconComponent,
    FileSizePipe,
    A11yUtilsModule,
    AokRemoveFileExtensionPipe,
  ],
  templateUrl: './benefit-detail-view.component.html',
  styleUrls: ['./benefit-detail-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitDetailViewComponent implements OnInit, OnDestroy {
  public benefit: AokBenefitResponse;
  public sections: Section[] = [];

  public optionalSections = {
    additionalInformation: { title: 'Zusätzliche Informationen', id: 'additionalInformation' },
    patientInformation: { title: 'Patienteninformation', id: 'patientInformation' },
    benefitDocuments: { title: 'Passende Dokumente zum Download', id: 'benefitDocuments' },
  };

  public mandatorySections = {
    overview: { title: 'Überblick', id: 'overview' },
    serviceDescription: { title: 'Leistungsbeschreibung', id: 'serviceDescription' },
    participationRequirements: {
      title: 'Teilnahmevoraussetzungen',
      id: 'participationRequirements',
    },
  };

  private ngDestroy = new Subject<void>();

  constructor(private route: ActivatedRoute, private client: AokBenefitClient) {}

  ngOnInit(): void {
    this.route.data
      .pipe(
        takeUntil(this.ngDestroy),
        map((data) => data.benefit)
      )
      .subscribe((benefit: AokBenefitResponse) => {
        this.benefit = benefit;
        this.prepareSections();
      });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public sendEmail(): void {
    const subject: string = 'Patienteninformation: ' + this.benefit.name;
    // keep only first 1950 characters of msg  to avoid mailto link length limit of 2000 characters
    const strippedContent: string = prepareHtmlForEmailBody(this.benefit.patientInformation);
    const msg = strippedContent.length >= 1950 ? strippedContent.substring(0, 1947) + '...' : strippedContent;

    window.location.href = `mailto:?subject=${subject}&body=${msg}`;
  }

  public downloadDocument(document: BenefitDocumentResponse): void {
    this.client
      .downloadDocument(this.benefit.id, document.id)
      .pipe(
        catchError(() => {
          return EMPTY;
        })
      )
      .subscribe((file) => {
        downloadBlob(file, document.pdfName);
      });
  }

  public handleContentOverviewClick(title: string): void {
    scrollToFragmentWithOffset(title);
  }

  private prepareSections(): void {
    this.sections.push(...Object.values(this.mandatorySections));

    Object.keys(this.optionalSections).forEach((section) => {
      const content = this.benefit[section];
      if (content?.length) {
        this.sections.push(this.optionalSections[section]);
      }
    });
  }
}
