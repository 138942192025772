<div class="aok-container">
  <aok-radio
    class="tw-col-2-12 lg:tw-col-3-11 tw-flex tw-flex-col tw-mt-7 tw-space-y-7"
    [options]="switchRadioOptions"
    [formControl]="radioSwitchControl"
  ></aok-radio>
</div>

<div class="aok-container" aokFocusFirstChild [focusFirstChild]="focusFirstChild">
  <ng-container [ngSwitch]="radioSwitchControl.value">
    <ng-container *ngSwitchCase="AokPatientSearchTypeEnum.Address" [formGroup]="addressForm">
      <div class="input-field tw-col-2-7 lg:tw-col-3-7 tw-ml-6">
        <aok-input-field
          [required]="addressForm.get('firstname').hasValidator(requiredValidator)"
          [label]="fieldData.firstname.label"
        >
          <input [id]="fieldData.firstname.id" [formControlName]="fieldData.firstname.id" />
        </aok-input-field>
      </div>

      <div class="input-field tw-col-7-12 lg:tw-col-7-11">
        <aok-input-field
          [required]="addressForm.get('lastname').hasValidator(requiredValidator)"
          [label]="fieldData.lastname.label"
        >
          <input [id]="fieldData.lastname.id" [formControlName]="fieldData.lastname.id" />
        </aok-input-field>
      </div>

      <div class="input-field tw-col-2-7 lg:tw-col-3-7 tw-ml-6">
        <aok-input-field
          [required]="addressForm.get('birthday').hasValidator(requiredValidator)"
          [label]="fieldData.birthday.label"
        >
          <aok-datepicker
            [innerId]="fieldData.birthday.id"
            [allowFutureDate]="false"
            placeholder="TT.MM.JJJJ"
            [control]="getController(fieldData.birthday.id)"
            [startView]="'multi-year'"
          ></aok-datepicker>
        </aok-input-field>
      </div>

      <div class="input-field tw-col-7-12 lg:tw-col-7-11">
        <aok-input-field
          [required]="addressForm.get('postCode').hasValidator(requiredValidator)"
          [label]="fieldData.postCode.label"
        >
          <input [id]="fieldData.postCode.id" [formControlName]="fieldData.postCode.id" />
        </aok-input-field>
      </div>

      <ng-container *ngIf="showDetailedAddressForm" class="row-container">
        <div class="input-field tw-col-2-7 lg:tw-col-3-7 tw-ml-6">
          <aok-input-field
            [required]="addressForm.get('street').hasValidator(requiredValidator)"
            [label]="fieldData.street.label"
          >
            <input [id]="fieldData.street.id" [formControlName]="fieldData.street.id" />
          </aok-input-field>
        </div>
        <div class="input-field tw-col-7-12 lg:tw-col-7-11">
          <aok-input-field
            [required]="addressForm.get('streetNumber').hasValidator(requiredValidator)"
            [label]="fieldData.streetNumber.label"
          >
            <input [id]="fieldData.streetNumber.id" [formControlName]="fieldData.streetNumber.id" />
          </aok-input-field>
        </div>
      </ng-container>
    </ng-container>

    <ng-container [formGroup]="insuranceForm" *ngSwitchCase="AokPatientSearchTypeEnum.Insurance">
      <div class="input-field tw-col-2-7 lg:tw-col-3-7 tw-ml-6">
        <aok-input-field [label]="fieldData.insuranceNumber.label" [required]="true">
          <input [id]="fieldData.insuranceNumber.id" [formControlName]="fieldData.insuranceNumber.id" />
        </aok-input-field>
      </div>

      <div class="input-field tw-col-7-12 lg:tw-col-7-11">
        <aok-input-field [label]="fieldData.birthday.label" [required]="true">
          <aok-datepicker
            [allowFutureDate]="false"
            placeholder="TT.MM.JJJJ"
            [innerId]="fieldData.birthday.id"
            [control]="getController(fieldData.birthday.id)"
            [startView]="'multi-year'"
          >
          </aok-datepicker>
        </aok-input-field>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="AokPatientSearchTypeEnum.InsuranceCard">
      <div class="tw-col-2-12 lg:tw-col-3-11 tw-mt-3 tw-ml-6">
        <button id="readInsuranceCardData" (click)="readInsuranceCardData()">Patient ermitteln</button>
        <p class="tw-mt-4">
          Mit dem Einlesen der Versichertenkarte bestätige ich, dass der Patient der Datenverarbeitung zugestimmt hat.
        </p>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="radioSwitchControl.value !== AokPatientSearchTypeEnum.InsuranceCard">
    <ng-container *ngIf="identityCheck">
      <div class="tw-col-2-12 lg:tw-col-3-11 tw-mt-3 tw-ml-6">
        <aok-input-field>
          <aok-checkbox
            [id]="fieldData.identityCheck.id"
            [formControl]="identityCheckControl"
            [label]="identityCheckLabel"
            aokFocusable
          ></aok-checkbox>
        </aok-input-field>
      </div>
    </ng-container>

    <div class="tw-col-2-12 lg:tw-col-3-11 tw-mt-3 tw-ml-6">
      <button id="startPatientSearch" (click)="submit()">{{ submitButtonText }}</button>
    </div>
  </ng-container>
</div>
