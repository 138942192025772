import { AokKeycloakTokenParsed, AokRegistrationUserData, KnownUserSalutation } from '../schemas';

export function extractAokRegistrationUserData(parsedToken: AokKeycloakTokenParsed): Partial<AokRegistrationUserData> {
  const {
    family_name: lastName,
    given_name: firstName,
    lanr,
    salutation: germanSalutationStr,
    title,
    gender,
  } = parsedToken;
  const salutation = handleSalutation(germanSalutationStr, gender);

  return { firstName, lastName, lanr, salutation, title };
}

/**
 * gender here is expected from KVN as 'M' or 'F'
 */
const handleSalutation = (germanSalutationStr: string, gender: string): string => {
  let salutation;

  if (isMale(germanSalutationStr, gender)) {
    salutation = KnownUserSalutation.Mr;
  } else if (isFemale(germanSalutationStr, gender)) {
    salutation = KnownUserSalutation.Mrs;
  }

  return salutation;
};

const isMale = (germanSalutationStr: string, gender: string): boolean => {
  return germanSalutationStr?.toLowerCase().trim() === 'herr' || gender === 'M';
};

const isFemale = (germanSalutationStr: string, gender: string): boolean => {
  return germanSalutationStr?.toLowerCase().trim() === 'frau' || gender === 'F';
};
