import { InjectionToken, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AokStaticArticleClient } from '../clients';
import { AokStaticArticle, AOK_STATIC_ARTICLE_KEY_ROUTE_MAPPING, AokStaticArticleKey } from '../schemas';

export const AOK_STATIC_ARTICLE_RESOLVE_PARAM_KEY = new InjectionToken<string>('AOK_STATIC_ARTICLE_RESOLVE_PARAM_KEY');

export const staticArticleResolver: ResolveFn<AokStaticArticle> = (route: ActivatedRouteSnapshot) => {
  const client = inject(AokStaticArticleClient);
  const routeParamKey = inject(AOK_STATIC_ARTICLE_RESOLVE_PARAM_KEY);

  const paramValue = route.params[routeParamKey];
  const key = AOK_STATIC_ARTICLE_KEY_ROUTE_MAPPING.find((mapping) => mapping.route === paramValue)
    ?.key as AokStaticArticleKey;

  return client.find(key).pipe(catchError(() => of(null)));
};
