import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AokToastService,
  AokUserClient,
  AokUserRegistration,
  AokUserRegistrationClient,
  AokUserRegistrationStatus,
  ContextState,
  DialogBase,
  DialogRef,
} from '@aok/common';
import { Observable } from 'rxjs';
import { ProfileAssistant } from '../../../schema';

@Component({
  selector: 'aok-cockpit-remove-practice-staff-dialog',
  templateUrl: './remove-practice-staff-dialog.component.html',
  styleUrls: ['./remove-practice-staff-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AokRemovePracticeStaffDialog implements OnInit, DialogBase<boolean> {
  @Input() user: ProfileAssistant;

  public singleLink: boolean;
  public confirmButtonText: string;
  public contentText: string;
  public profileDeletionNote =
    'Hinweis: Wir bewahren die Daten nach der Löschung für 3 Jahre gesperrt auf. Die Daten werden nicht weiterverarbeitet und nach Ablauf dieser Frist gelöscht. Weiteres entnehmen Sie bitte unserer ';

  private removePMSuccessToastHeadline = 'Vorgang erfolgreich';
  private msgConfirmRemovePracticeStaff =
    'Sind Sie sicher, dass Sie den Praxismitarbeiter aus dieser Praxis entfernen möchten? ' +
    'Der Praxismitarbeiter kann anschließend für keine zu dieser BSNR gehörigen Ärzte mehr tätig werden und es werden alle Daten des Praxismitarbeiters aus dieser Praxis entfernt.';
  private msgConfirmRemoveAndDeleteUser =
    'Sind Sie sicher, dass Sie den Praxismitarbeiter löschen möchten? ' +
    'Wenn Sie auf „Praxismitarbeiter löschen“ klicken, kann der Praxismitarbeiter das Arztportal nicht länger nutzen.';
  private errorToastHeadline = 'Vorgang fehlgeschlagen';
  private errorToastMessage =
    'Der Praxismitarbeiter konnte nicht gelöscht werden. ' +
    'Bitte aktualisieren Sie diese Seite und versuchen Sie es erneut.';
  private successToastMessage: string;

  constructor(
    public readonly dialogRef: DialogRef<boolean>,
    private toastService: AokToastService,
    private usersClient: AokUserClient,
    protected registrationClient: AokUserRegistrationClient,
    private contextState: ContextState
  ) {}

  ngOnInit(): void {
    this.singleLink =
      !this.user['practitionerResource'] || this.user['practitionerResource'].organizationsIds.length === 1;
    this.contentText = this.singleLink ? this.msgConfirmRemoveAndDeleteUser : this.msgConfirmRemovePracticeStaff;
    this.confirmButtonText = this.singleLink ? 'Praxismitarbeiter löschen' : 'Praxismitarbeiter entfernen';
    this.successToastMessage = this.singleLink
      ? 'Der Praxismitarbeiter wurde gelöscht.'
      : 'Der Praxismitarbeiter wurde aus der Praxis entfernt.';
  }

  public cancel(): void {
    this.dialogRef.dispose();
  }

  public submit(): void {
    this.removePracticeStaffByStatus().subscribe({
      next: () => {
        this.toastService.createSuccessToast(this.removePMSuccessToastHeadline, this.successToastMessage);
        this.dialogRef.dispose(true);
      },
      error: () => {
        this.toastService.createErrorToast(this.errorToastHeadline, this.errorToastMessage);
        this.dialogRef.dispose(false);
      },
    });
  }

  private removePracticeStaffByStatus(): Observable<unknown> {
    switch ((this.user as AokUserRegistration).registrationStatus) {
      case AokUserRegistrationStatus.CardCreation: {
        return this.registrationClient.delete(this.user.id);
      }
      case undefined: {
        return this.usersClient.removePMFromPractice(this.user.id, this.contextState.bsnr);
      }
    }
  }
}
