import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { AokReportPageData } from '@aok/common';
import { KvnState } from '@aok/components';
import { tap } from 'rxjs/operators';
import { ReportsState } from '../states/reports.state';
import { ReportsService } from '../services/reports.service';

// TODO check if the logic here can/should be integrated into reports.resolver due to its 'already retrieved' reports logic
export const noReportsResolver: ResolveFn<AokReportPageData> = (route: ActivatedRouteSnapshot) => {
  const reportsState = inject(ReportsState);
  const reportsService = inject(ReportsService);
  const kvnState = inject(KvnState);

  if (reportsService.isAnyCategoryEnabled() && !reportsState.snapshot && !kvnState.isKvnPracticeTeam()) {
    return reportsService.retrieveReportsData().pipe(
      tap((reportsData: AokReportPageData) => {
        reportsState.patch(reportsData);
        reportsService.redirectToActiveTab(reportsData, route, true);
      })
    );
  }

  return null;
};
