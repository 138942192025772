import { APP_BOOTSTRAP_LISTENER, Optional, Provider } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { of } from 'rxjs';
import { concatMap, filter, finalize, mergeMap, take, takeWhile, tap } from 'rxjs/operators';
import { AppLoadingStateEnum } from '../../schemas';
import { AOK_REQUIRED_TASKS, RequiredTask } from '../../schemas/required-tasks.schema';
import { AppState } from '../../states';
import { isIterable } from '../../utils';

const handleRequiredTasks = (keycloak: KeycloakService, appState: AppState, tasks?: RequiredTask[]) => {
  return () => {
    // TODO maybe user service loadKeycloakInstance case be used
    return keycloak.keycloakEvents$
      .pipe(
        // wait for until keycloak is ready, then start initializing the active user data, if we don't wait for the
        // ready event here we wouldn't have a valid session to fetch the active user provider with
        filter((e) => e.type === KeycloakEventType.OnReady),
        take(1),
        tap(() => {
          if (tasks != null && !isIterable(tasks)) {
            throw new Error(
              `Invalid value for AOK_REQUIRED_TASKS provider! Please make sure to set multi to "true" when using this provider`
            );
          }
        }),
        mergeMap(() => {
          // create stream with each task
          return of(...tasks);
        }),
        concatMap((task) => {
          appState.setLoadingState(AppLoadingStateEnum.LOADING);
          return task.trigger();
        }),
        takeWhile((resumeTask) => resumeTask),
        finalize(() => {
          appState.setLoadingState(AppLoadingStateEnum.LOADED);
        })
      )
      .subscribe();
  };
};

export const AOK_REQUIRED_TASKS_LISTENER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: handleRequiredTasks,
  deps: [KeycloakService, AppState, [new Optional(), AOK_REQUIRED_TASKS]],
  multi: true,
};
