import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';

/**
 * returns the start of the current quarter (based on the given date) as a date object
 * @param date
 * @returns
 */
export function getCurrentQuarterStartDate(date?: Date): Date {
  if (!date) date = new Date();
  const month = Number(Math.floor(date.getMonth() / 3) * 3);
  return new Date(date.getFullYear(), month, 1);
}

/**
 * returns the current quarter based on the current date as a number in [1, 4]
 * @returns
 */
export function getCurrentQuarter(): number {
  const date = new Date();
  return Math.floor((date.getMonth() + 3) / 3);
}

/**
 * returns the start of the previous quarter (based on the given date) as a date object
 * @param date
 * @returns
 */
export function getPreviousQuarterStartDate(date?: Date): Date {
  if (!date) date = new Date();
  const curr = getCurrentQuarterStartDate(date);
  return new Date(date.getFullYear(), curr.getMonth() - 3, 1);
}

export function getFormattedDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function billingQuarter(control: AbstractControl | UntypedFormControl): ValidationErrors {
  // at first, check if the value are a valid date.
  if (control?.value && isValidDate(control.value)) {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentDay = now.getUTCDate();
    const controlDate = new Date(control.value);
    const lastQuarter =
      (currentMonth === 0 || currentMonth === 3 || currentMonth === 6 || currentMonth === 9) && currentDay < 11;

    return lastQuarter
      ? validateDateInPreviousQuarter(controlDate, now)
      : validateDateInCurrentQuarter(controlDate, now);
  }
  return null;
}

export const validateDateInCurrentQuarter = (controlDate: Date, now: Date): ValidationErrors => {
  const currentQuarterStartDate: Date = getCurrentQuarterStartDate();
  return currentQuarterStartDate <= controlDate && now >= controlDate ? null : { wrongBillingQuarter: true };
};

export const validateDateInPreviousQuarter = (controlDate: Date, now: Date): ValidationErrors => {
  const previousQuarterStartDate: Date = getPreviousQuarterStartDate();
  return previousQuarterStartDate <= controlDate && now >= controlDate ? null : { wrongBillingQuarter: true };
};

/**
 * check whether first param date is before the second param date
 * do not take into account time
 * @param date - date that is supposed to be smaller
 * @param dateToCompare - date that is supposed to be bigger
 */
export const dateLessThan = (date: Date, dateToCompare: Date): boolean => {
  const smallerDate = new Date(date);
  smallerDate.setHours(0, 0, 0, 0);

  const biggerDate = new Date(dateToCompare);
  biggerDate.setHours(0, 0, 0, 0);

  return smallerDate < biggerDate;
};

/**
 * Returns the closest date with :30 minutes in the future
 */
export const roundToNext30 = (date = new Date()): Date => {
  const minutes = 30;
  const ms = 1000 * 60 * minutes;

  const differenceFromLast30 = date.getTime() % ms;
  const differenceToNext30 = ms - differenceFromLast30;

  return new Date(date.getTime() + differenceToNext30);
};

/**
 * Calculate the expiration date and time by adding the specified hours to the current date and time.
 *
 * @param {number} hoursToExpire - The number of hours to add.
 * @returns {string} UTC-formatted expiration date.
 *
 * @example
 * const hoursToExpire = 24;
 * const expirationDate = getExpirationDate(hoursToExpire); // Example: "Tue, 18 Oct 2023 03:45:00 GMT"
 */
export function getExpirationDate(hoursToExpire: number): string {
  const today = new Date();
  today.setHours(today.getHours() + hoursToExpire);

  return today.toUTCString();
}

/**
 * A date string is valid if the browser can interpret it
 */
export function isValidDate(dateString: string): boolean {
  const date = new Date(dateString);
  return date && !isNaN(date.getTime());
}
