import { AokPageQueryParams } from '../page.schema';

export enum DmpCaseManagementCategory {
  REGULAR = 'REGULAR',
  INITIAL = 'INITIAL',
  ENROLLMENT = 'ENROLLMENT',
  SUSPENSION = 'SUSPENSION',
}

export interface DMPCaseManagementCounts {
  regular: number;
  initial: number;
  enrollment: number;
  suspension: number;
}

export interface DMPCase {
  id: number;
  insuranceNumber: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  startDate?: Date;
  endDate?: Date;
  endReason?: string;
  dmp?: string;
  dmpCategory: DmpCaseManagementCategory;
}

export interface TableColumnDef {
  label?: string;
  id: string;
  show?: boolean;
  sortBy?: string[];
}

export interface AokDmpCaseListOptions extends AokPageQueryParams {
  lanr: string;
  dmpCategory: string;
}

export interface DmpOverviewData {
  counts: DMPCaseManagementCounts;
  lastUpdatedDate: number;
}

export interface DmpCaseFeedbackRequestSchema {
  dmpCaseId: number;
  dmpCaseFeedbackCategory: string;
  message?: string;
  lanrContext: string;
  bsnrContext: string;
  phoneCheckbox?: boolean;
  phoneNumber?: string;
  letterCheckbox?: boolean;
  selectedPracticeId?: number;
  selectedUserId: number;
}
