import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';

import {
  AddressData,
  AokAuthOptionPipe,
  AokFullAddressPipe,
  AokFullNamePipeModule,
  AokOrg,
  AuthOption,
  ContextState,
  DropdownSchema,
} from '@aok/common';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { AuthFieldsConfig, authOptionHints, SecondFactorAuthFields } from '../../utils';
import { AokInputFieldComponent, AokInputFieldModule, RequiredMarkModule } from '../cdk';
import { AokDropdownModule } from '../dropdown/dropdown.module';
import { FormControlErrorModule } from '../form-control-error/module';
import { PhoneNumberDataComponent } from '../phone-number-data/phone-number-data.component';
import { RadioOptions } from '../radio-button/radio.component';
import { AokRadioModule } from '../radio-button/radio.module';

@Component({
  selector: 'aok-second-factor-auth-options',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AokDropdownModule,
    AokFullAddressPipe,
    AokFullNamePipeModule,
    AokInputFieldModule,
    AokRadioModule,
    FormControlErrorModule,
    RequiredMarkModule,
    PhoneNumberDataComponent,
  ],
  templateUrl: './second-factor-auth-options.component.html',
  styleUrls: ['./second-factor-auth-options.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondFactorAuthOptionsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() formLabels: {
    authOption: string;
    bsnr?: string;
    countryCode?: string;
    secondFactorPhoneNumber?: string;
  };
  @Input() isCardOrderingEnabled: boolean;

  @ViewChildren(AokInputFieldComponent)
  public inputFields: QueryList<AokInputFieldComponent>;
  @ViewChild(PhoneNumberDataComponent)
  public phoneNumberDataComponent: PhoneNumberDataComponent;

  public authOptions: RadioOptions[];

  public contextOrganizations: Partial<AokOrg>[];
  public practiceOptions: DropdownSchema[];

  protected readonly SecondFactorAuthFields = SecondFactorAuthFields;

  private authOptionPipe = new AokAuthOptionPipe();
  private destroyRef = inject(DestroyRef);

  public get inputFieldComponents(): AokInputFieldComponent[] {
    const inputs = [...this.inputFields];
    if (this.displaySmsExtra() && this.phoneNumberDataComponent) {
      inputs.push(...this.phoneNumberDataComponent.inputFields);
    }
    return inputs;
  }

  constructor(protected contextState: ContextState) {}

  public ngOnInit(): void {
    this.setAuthOptions();
    this.setPracticeOptions();

    this.watchAuthOptionChanges();
  }

  protected getSelectedOrgAddress(): AddressData {
    return this.contextState.contextSelectorOptions.find(({ organizationData }) => {
      return organizationData.bsnr === this.form.get(SecondFactorAuthFields.BSNR).value;
    })?.organizationData?.address;
  }

  protected isCardAuthOption(authOption?: AuthOption): boolean {
    return (authOption ?? this.form.value.authOption) === AuthOption.CARD;
  }

  protected isSmsAuthOption(authOption?: AuthOption): boolean {
    return (authOption ?? this.form.value.authOption) === AuthOption.SMS;
  }

  protected displayCardExtra(): boolean {
    return this.isCardAuthOption() && !!this.form.get(SecondFactorAuthFields.BSNR);
  }

  protected displaySmsExtra(): boolean {
    return (
      this.isSmsAuthOption() &&
      !!this.form.get(SecondFactorAuthFields.COUNTRY_CODE) &&
      !!this.form.get(SecondFactorAuthFields.SECOND_FACTOR_PHONE_NUMBER)
    );
  }

  private setAuthOptions(): void {
    this.authOptions = Object.keys(AuthOption)
      .filter((key) => this.isCardOrderingEnabled || key !== AuthOption.CARD)
      .map((key) => {
        return {
          label: this.authOptionPipe.transform(key),
          value: key,
          popover: authOptionHints[key],
          id: `${key.toLowerCase()}`,
        } as RadioOptions;
      });
  }

  private setPracticeOptions(): void {
    if (this.isCardOrderingEnabled && this.form.get(SecondFactorAuthFields.BSNR)) {
      this.contextOrganizations = this.contextState.contextSelectorOptions
        .map(({ organizationData }) => organizationData)
        .filter(({ bsnr }, index, array) => {
          return index === array.findIndex((org) => org.bsnr === bsnr);
        });

      this.practiceOptions = this.contextOrganizations.map((option) => {
        return {
          value: option?.bsnr,
          label: `BSNR ${option?.bsnr} | Praxis ${option?.name} `,
        };
      });

      this.form.get(SecondFactorAuthFields.BSNR).setValue(this.contextState.org.bsnr);
    }
  }

  private watchAuthOptionChanges(): void {
    this.form
      .get(SecondFactorAuthFields.AUTH_OPTION)
      .valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        filter((authOption) => !!authOption)
      )
      .subscribe((authOption) => {
        this.handleFieldVisibility(authOption);
      });
  }

  private handleFieldVisibility(selectedAuthOption: string): void {
    const fieldsToEnable = AuthFieldsConfig[selectedAuthOption];

    Object.keys(this.form.controls).forEach((fieldName) => {
      if (fieldsToEnable.includes(fieldName)) {
        this.form.get(fieldName).enable({ emitEvent: false });
      } else if (
        fieldName !== SecondFactorAuthFields.AUTH_OPTION &&
        Object.values(SecondFactorAuthFields).includes(fieldName)
      ) {
        this.form.get(fieldName).disable({ emitEvent: false });
      }
    });
  }
}
