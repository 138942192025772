import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'aok-step',
  encapsulation: ViewEncapsulation.None,
  template: ` <div *ngIf="active"><ng-content></ng-content></div>`,
  exportAs: 'step',
})
export class AokStepComponent {
  public visited = false;
  public valid = false;
  public index = 0;
  @Input() iconName: string;
  @Input() title: string;
  @Input() description: string;
  @Input() active = false;
  @Input() allowLinebreakInTitle = true;
  private _validate: () => boolean;

  get validate(): () => boolean {
    this.valid = this._validate ? this._validate() : true;
    return this._validate;
  }

  @Input() set validate(validationFunction: () => boolean) {
    this._validate = validationFunction;
  }
}
