import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, catchError, mergeMap, of } from 'rxjs';
import { DmpCaseManagementDetailViewService } from '../services/dmp-case-management-detail-view.service';
import { CurrentUserState, AokUser, isDoctor, DmpCaseManagementCategory } from '@aok/common';

export const lanrFilterOptionsResolver: ResolveFn<AokUser[]> = (route: ActivatedRouteSnapshot) => {
  const currentUser = inject(CurrentUserState);
  const dmpCaseDetailService = inject(DmpCaseManagementDetailViewService);
  const dmpCategory = route.data.dmpCategory;

  return currentUser.first$.pipe(
    mergeMap((user) => (!isDoctor(user) ? of([]) : getLanrFilterOptions(dmpCategory, dmpCaseDetailService)))
  );
};

const getLanrFilterOptions = (
  dmpCategory: DmpCaseManagementCategory,
  dmpCaseDetailService: DmpCaseManagementDetailViewService
): Observable<AokUser[]> => {
  return dmpCaseDetailService.getLanrFilterOptions(dmpCategory).pipe(catchError(() => of([] as AokUser[])));
};
