import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AokNavComponent } from './nav.component';
import { A11yUtilsModule, AokSvgIconComponent, NavigationModule, RouterLinkOrHrefModule } from '@aok/common';
import { A11yModule } from '@angular/cdk/a11y';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@NgModule({
  declarations: [AokNavComponent],
  exports: [AokNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    AokSvgIconComponent,
    A11yUtilsModule,
    NgClickOutsideDirective,
    RouterLinkOrHrefModule,
    NavigationModule,
    A11yModule,
  ],
})
export class AokNavModule {}
