import { AokPractitionerSpecializationEntry } from '../schemas';
import { zeroPad } from './zero-pad.util';

export function getSpecializationIdLanr9(lanr: string | number): string {
  const lanrValue = lanr?.toString();
  if (lanrValue?.length === 9) {
    return lanrValue.slice(-2);
  }
  return null;
}

export function getSpecializationDescription(
  lanr: string,
  specializationOptions: AokPractitionerSpecializationEntry[]
): string {
  const specializationId = getSpecializationIdLanr9(lanr);
  return specializationOptions.find((option) => zeroPad(option.id) === specializationId)?.description;
}
