import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AokPopoverComponent, PopoverDirection } from '@aok/common';
import { DisturbanceService } from '../../services';

@Component({
  selector: 'aok-disturbance-message',
  standalone: true,
  imports: [CommonModule, AokPopoverComponent],
  templateUrl: './disturbance-message.component.html',
  styleUrls: ['./disturbance-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisturbanceMessageComponent implements AfterViewInit, OnDestroy {
  @ViewChild('popover') popover: AokPopoverComponent;
  protected popoverDirection = PopoverDirection.BottomCenter;

  constructor(private service: DisturbanceService) {}

  ngOnDestroy(): void {
    window.removeEventListener('click', this.windowClickListener);
  }

  ngAfterViewInit(): void {
    if (this.service.shouldDisplay) {
      window.addEventListener('click', this.windowClickListener);
      this.popover.togglePopover();
      this.service.markAsOpened();
    }
  }

  private handleWindowClick(): void {
    window.removeEventListener('click', this.windowClickListener);
    if (this.service.opened) {
      this.popover.closeFocus();
      this.service.markAsClosed();
    }
  }

  private windowClickListener = (): void => {
    this.handleWindowClick();
  };
}
