import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AokOrg, AokRegistrationOrgData } from '../../schemas';
import { bsnr, email, houseNumber, phoneDevice, zipCode } from '../validators/pattern.validator';
import { FormSchemaControls } from './form-utils/form-schema-map';

export function createOrgMasterDataForm(schema?: Partial<AokOrg>): UntypedFormGroup {
  const { required } = Validators;
  return new UntypedFormGroup({
    id: new UntypedFormControl(schema?.id),
    name: new UntypedFormControl(schema?.name, [required]),
    bsnr: new UntypedFormControl(schema?.bsnr, [required, bsnr]),
    organizationType: new UntypedFormControl(schema?.organizationType, [required]),
  } as FormSchemaControls<AokOrg>);
}

export function createOrgContactForm(schema?: Partial<AokOrg>): UntypedFormGroup {
  return new UntypedFormGroup({
    contactData: createOrgContactDataForm(schema?.contactData),
    address: createOrgAddressDataForm(schema?.address),
  } as FormSchemaControls<AokOrg>);
}

export function createOrgAddressDataForm(schema?: Partial<AokOrg['address']>): UntypedFormGroup {
  const { required } = Validators;
  return new UntypedFormGroup({
    location: new UntypedFormControl(schema?.location, [required]),
    streetName: new UntypedFormControl(schema?.streetName, [required]),
    streetNumber: new UntypedFormControl(schema?.streetNumber, [required, houseNumber]),
    zipCode: new UntypedFormControl(schema?.zipCode, [required, zipCode]),
  } as FormSchemaControls<AokOrg['address']>);
}

export function createOrgContactDataForm(schema?: Partial<AokOrg['contactData']>): UntypedFormGroup {
  return new UntypedFormGroup({
    email: new UntypedFormControl(schema?.email, [email]),
    mobilePhone: new UntypedFormControl(schema?.mobilePhone, [phoneDevice]),
    telephone: new UntypedFormControl(schema?.telephone, [phoneDevice]),
    fax: new UntypedFormControl(schema?.fax, [phoneDevice]),
  } as FormSchemaControls<AokOrg['contactData']>);
}

// TODO possibly update the used schema
export function createSimplePracticeForm(
  organizationData?: AokRegistrationOrgData | Partial<AokRegistrationOrgData>
): FormGroup {
  const address = organizationData?.address;

  return new FormGroup({
    bsnr: new FormControl<string>(
      {
        value: organizationData?.bsnr,
        disabled: !!organizationData?.bsnr,
      },
      [Validators.required, bsnr]
    ),
    organizationType: new FormControl<string>(organizationData?.organizationType, Validators.required),
    organizationName: new FormControl<string>(organizationData?.organizationName, Validators.required),
    street: new FormControl<string>(
      {
        value: address?.streetName,
        disabled: !!address?.streetName,
      },
      Validators.required
    ),
    streetNumber: new FormControl<string>(
      {
        value: address?.streetNumber,
        disabled: !!address?.streetNumber,
      },
      [Validators.required, houseNumber]
    ),
    zipCode: new FormControl<string>(
      {
        value: address?.zipCode,
        disabled: !!address?.zipCode,
      },
      [Validators.required, zipCode]
    ),
    location: new FormControl<string>(
      {
        value: address?.location,
        disabled: !!address?.location,
      },
      Validators.required
    ),
  });
}
