import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AokKeycloakRoleRedirect, RequiredTask } from '../../schemas';
import { isAbsoluteURL } from '../../utils';

export const AOK_KEYCLOAK_ROLE_REDIRECTS = new InjectionToken<AokKeycloakRoleRedirect[]>('AOK_KEYCLOAK_ROLE_REDIRECTS');

@Injectable()
export class RoleRedirectTaskService implements RequiredTask {
  private readonly rolesWithRedirects: string[];

  constructor(
    @Inject(AOK_KEYCLOAK_ROLE_REDIRECTS) private roleRedirects: AokKeycloakRoleRedirect[],
    private router: Router,
    private keycloak: KeycloakService
  ) {
    // flattens all role values into a DISTINCT collection of roles to properly check whether we can init a given role
    this.rolesWithRedirects = this.roleRedirects
      .reduce<string[]>((acc, { redirect }) => acc.concat(redirect), [])
      .filter((role, i, roles) => roles.indexOf(role) === i);
  }

  trigger(): Observable<boolean> {
    return this.shouldRedirect().pipe(
      mergeMap((redirect) => {
        if (redirect) {
          this.redirect().subscribe();
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }

  private redirect(): Observable<boolean> {
    const roles = this.keycloak.getUserRoles();
    for (const role of roles) {
      const redirectUrl = this.resolveFirstMatchingRoleRedirect(role)?.to;
      // early out of the loop iteration if no redirect is available for the given role
      if (redirectUrl == null) continue;

      if (!isAbsoluteURL(redirectUrl) && this.router != null) {
        return from(this.router.navigateByUrl(redirectUrl));
      } else {
        window.location.href = redirectUrl;
        return of(true);
      }
    }

    return of(null);
  }

  private shouldRedirect(): Observable<boolean> {
    const hasRedirect = this.keycloak.getUserRoles()?.some((role) => this.rolesWithRedirects.includes(role));
    return of(hasRedirect);
  }

  private resolveFirstMatchingRoleRedirect(role: string): AokKeycloakRoleRedirect {
    return this.roleRedirects.find(({ redirect }) => {
      return redirect != null && Array.isArray(redirect) ? redirect.includes(role) : redirect === role;
    });
  }
}
