<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>
<aok-cockpit-header-page-headline [headline]="AokServiceCategory.BillingSheet">
  <p hint-content>
    Bitte geben Sie die Versichertendaten ein, um den Versichertenstatus abzufragen und den Abrechnungsschein
    herunterzuladen. Um mehrere Abrechnungsscheine in einem Arbeitsgang zu drucken, nutzen Sie den
    <a class="aok-link-1" routerLink="/services/sammeldruck">Sammeldruck von Abrechnungsscheinen</a>.
  </p>
</aok-cockpit-header-page-headline>

<ng-container *ngTemplateOutlet="response ? resultTemplate : requestTemplate"></ng-container>

<ng-template #resultTemplate>
  <aok-cockpit-billing-sheet-result [response]="response"> </aok-cockpit-billing-sheet-result>
  <div class="aok-container">
    <div class="tw-col-2-11 lg:tw-col-3-11 tw-mt-3">
      <button (click)="reload()" id="newQueryButton">Neue Abfrage</button>
    </div>
  </div>
</ng-template>

<ng-template #requestTemplate>
  <div *ngIf="banner" class="aok-container">
    <aok-notification-banner
      [attr.id]="banner.id ? banner.id : undefined"
      class="tw-col-2-12 lg:tw-col-3-11 tw-mt-2"
      [accent]="banner.bannerAccent || 'bad'"
      [bannerHeadline]="banner.bannerHeadline"
      [bannerLabel]="banner.bannerLabel"
      [bannerLinks]="banner.bannerLinks"
    >
    </aok-notification-banner>
  </div>

  <div class="aok-container" aokFocusFirstChild>
    <div class="required-label tw-col-2-12 lg:tw-col-3-11 tw-mt-3">
      <aok-required-mark></aok-required-mark>
      Pflichtfeld
    </div>

    <div class="input-field tw-col-2-7 lg:tw-col-3-7 tw-mt-7">
      <aok-input-field [label]="treatmentDateFieldData.label" required>
        <aok-datepicker
          id="treatmentDate"
          [allowFutureDate]="false"
          placeholder="TT.MM.JJJJ"
          [innerId]="treatmentDateFieldData.id"
          [control]="treatmentDateForm"
          [minDate]="minTreatmentDate"
          [maxDate]="maxTreatmentDate"
        ></aok-datepicker>
      </aok-input-field>
    </div>

    <p class="tw-col-2-12 lg:tw-col-3-11 tw-mt-3">Patient mit folgenden Daten ermitteln:</p>
  </div>

  <aok-cockpit-service-request-form
    [showDetailedAddressForm]="showDetailedAddressForm"
    (patientData)="handlePatientFormData($event)"
    (patientFormErrors)="handlePatientFormError($event)"
  ></aok-cockpit-service-request-form>
</ng-template>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
