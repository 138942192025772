<ng-container *ngIf="showFeedback | async">
  <div id="first-focus-element"></div>

  <div class="aok-container feedback-button-container">
    <div class="feedback-button-wrapper">
      <div
        class="feedback-button"
        *ngIf="keycloak.getKeycloakInstance()?.authenticated"
        [ngClass]="{ opened: _opened | async }"
        tabindex="1"
        (click)="openFeedbackPopover($event)"
        (keyup.enter)="openFeedbackPopover($event)"
      >
        <aok-svg-icon name="text-chat_bubble" size="24"></aok-svg-icon>
        <span>Feedback</span>
      </div>
    </div>
  </div>
</ng-container>
