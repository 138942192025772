import { Injectable } from '@angular/core';
import { AokPractitionerSpecializationEntry } from '../schemas';
import { ArrayBehaviorState } from '../utils/rx/behavior-state';
import { AokUserClient } from '../clients';
import { map, tap } from 'rxjs/operators';
import { createPractitionerSpecializationEntries } from '../utils';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AokSpecializationState extends ArrayBehaviorState<AokPractitionerSpecializationEntry> {
  constructor(private userClient: AokUserClient) {
    super();
  }

  public init(): Promise<AokPractitionerSpecializationEntry[]> {
    return lastValueFrom(
      this.userClient.listSpecializations().pipe(
        map((specializations) => createPractitionerSpecializationEntries(specializations)),
        tap((specializations) => this.patch(specializations))
      )
    );
  }
}
