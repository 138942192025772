import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AokBsnrRegistration,
  AokBsnrRegistrationStatus,
  AokDialogResult,
  AokFullAddressPipe,
  AokFullNamePipe,
  AokMfaToBSNRRegistration,
  AokOrgTypeIntlPipe,
  DialogBase,
  DialogOverlay,
  DialogRef,
  TableTypeRow,
} from '@aok/common';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { AokSimpleDialog } from '../simple-dialog/simple-dialog.component';

@Component({
  selector: 'aok-cockpit-mfa-request-dialog',
  templateUrl: './mfa-request-dialog.component.html',
  styleUrls: ['./mfa-request-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AokOrgTypeIntlPipe, AokFullAddressPipe, AokDialogLayoutModule],
})
export class AokMfaRequestDialog implements OnInit, DialogBase<AokBsnrRegistrationStatus> {
  @Input() requestMember: AokMfaToBSNRRegistration | AokBsnrRegistration;

  public msgDialogHeadline = 'Beitrittsanfrage bearbeiten';
  public msgDialogText = 'Bitte überprüfen Sie die Angaben sorgfältig, bevor Sie den Praxismitarbeiter hinzufügen.';

  public msgBannerHeadline = 'Der unten genannte Praxismitarbeiter möchte Ihrer Betriebsstätte zugeordnet werden.';
  public msgBannerLabel = `Mit der Freigabe bestätigen Sie, dass der Praxismitarbeiter im Namen aller Ärzte Ihrer
    Betriebsstätte Aktivitäten auf dem Arztportal durchführen kann.`;

  public msgMfaTableHeadline = 'Praxismitarbeiter';
  public msgMfaTableValues: Array<TableTypeRow> = [];

  public msgPracticeTableHeadline = 'Zuordnung zu Praxis';
  public msgPracticeTableValues: Array<TableTypeRow> = [];

  public msgButtonRequired = 'Anfrage ablehnen';
  public msgButtonAccept = 'Anfrage freigeben';

  constructor(
    public readonly dialogRef: DialogRef<AokBsnrRegistrationStatus>,
    private fullNamePipe: AokFullNamePipe,
    private practiceTypePipe: AokOrgTypeIntlPipe,
    private fullAddressPipe: AokFullAddressPipe,
    private dialog: DialogOverlay
  ) {}

  ngOnInit(): void {
    this.msgMfaTableValues = [
      {
        label: 'Name',
        value: this.fullNamePipe.transform(this.requestMember, 'FF LL', this.requestMember.userData),
        id: 'name',
      },
    ];
    this.msgPracticeTableValues = [
      {
        label: 'Betriebsstättennummer',
        value: this.requestMember.organizationData.bsnr,
        id: 'bsnr',
      },
      {
        label: 'Art der Praxis',
        value: this.practiceTypePipe.transform(this.requestMember.organizationData.organizationType),
        id: 'practice_type',
      },
      {
        label: 'Name der Praxis',
        value: this.requestMember.organizationData.organizationName,
        id: 'practice_name',
      },
      {
        label: 'Praxisanschrift',
        value: this.fullAddressPipe.transform(this.requestMember.organizationData.address),
        id: 'practice_address',
      },
    ];
    if (this.requestMember.organizationData.email) {
      this.msgPracticeTableValues.push({
        label: 'E-Mail',
        value: this.requestMember.organizationData?.email,
        id: 'email',
      });
    }
  }

  public acceptSubmit(): void {
    this.dialogRef.dispose(AokBsnrRegistrationStatus.ACCEPTED);
  }

  public firstLevelRejection(): void {
    this.dialog
      .create(AokSimpleDialog, {
        closable: true,
        props: {
          headerText: 'Beitrittsanfrage ablehnen',
          contentText: `Sind Sie sicher, dass Sie diese Anfrage ablehnen möchten? Wenn Sie auf „Anfrage ablehnen“
          klicken, wird die Anfrage gelöscht. Der Praxismitarbeiter erhält eine Benachrichtigung mit der Aufforderung
          sich mit Ihnen in Verbindung zu setzen oder eine neue Anfrage zu stellen.`,
          cancelButtonText: 'Abbrechen',
          confirmButtonText: 'Anfrage ablehnen',
        },
      })
      .subscribe((secondLevelRejection: AokDialogResult) => {
        if (secondLevelRejection === AokDialogResult.CONFIRM) {
          this.dialogRef.dispose(AokBsnrRegistrationStatus.REJECTED);
        }
      });
  }
}
