import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthOption, getSmsDataControls } from '@aok/common';

export const secondFactorAuthFormLabels = {
  authOption: 'Authentifizierungsart',
  countryCode: 'Ländervorwahl',
  secondFactorPhoneNumber: 'Mobilfunknummer',
};

export const SecondFactorAuthFields = {
  AUTH_OPTION: 'authOption',
  BSNR: 'bsnr',
  COUNTRY_CODE: 'countryCode',
  SECOND_FACTOR_PHONE_NUMBER: 'secondFactorPhoneNumber',
};

export const AuthFieldsConfig = {
  [AuthOption.SMS]: [SecondFactorAuthFields.COUNTRY_CODE, SecondFactorAuthFields.SECOND_FACTOR_PHONE_NUMBER],
  [AuthOption.CARD]: [SecondFactorAuthFields.BSNR],
  [AuthOption.APP]: [],
};

export const authOptionHints = {
  APP: `<p id="smartphonePopoverContent">
            Neben den Logindaten wird ein temporär gültiger Zahlencode abgefragt.
            Dieser wird von einer kostenlosen "Authenticator" App erzeugt,
            die während der Registrierung mit dem AOK-Arztportal verknüpft  wird.
        </p>`,
  CARD: `<p id="cardPopoverContent">
            Neben den Logindaten wird ein temporär gültiger Zahlencode abgefragt.
            Dieser wird mit einer Displaykarte erzeugt, die nach erfolgreichem
            Abschluss der Registrierung postalisch zugestellt wird.
        </p>`,
  SMS: `<p id="smsPopoverContent">
            Neben den Logindaten wird ein temporär gütiger Zahlencode abgefragt.
            Dieser wird per SMS an die angegebene Mobilfunknummer gesendet.
        </p>
        <p>
          Die Mobilfunknummer wird im entsprechenden Eingabefeld ohne führende
          Nullen und Trennzeichen eingegeben.
        </p>`,
};

export const secondFactorAuthHint = `Mit einer Zwei-Faktor-Authentifizierung (2FA) schützen Sie den Zugang zum Arztportal durch einen zweiten Faktor in Form eines zusätzlichen Verifizierungs-Codes. Dieser Code wird bei jeder erneuten Anmeldung zum Arztportal abgefragt. Sie erhalten den Verifizierungs-Code über Ihre gewählte Authentifizierungsart.`;

export function createAuthOptionsForm(selectBsnr = false): FormGroup {
  const formGroup = new FormGroup({
    [SecondFactorAuthFields.AUTH_OPTION]: new FormControl<AuthOption>(null, [Validators.required]),
    ...getSmsDataControls(),
  });

  if (selectBsnr) {
    formGroup.addControl(SecondFactorAuthFields.BSNR, new FormControl<string>(''));
  }

  return formGroup;
}
