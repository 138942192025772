import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { tap } from 'rxjs/operators';
import { isAbsoluteURL, normalizeUrl, resolveObjectPropertyPath } from '../../utils';
import { BehaviorState } from '../../utils/rx/behavior-state';
import { lastValueFrom } from 'rxjs';

export const ENVIRONMENT_ID = new InjectionToken<string>('ENVIRONMENT_ID');
export const RUNTIME_PROFILE_BASE_URL = new InjectionToken<string>('RUNTIME_PROFILE_BASE_URL');

@Injectable()
export class AokRuntimeProfileState<T = any> extends BehaviorState<T> {
  constructor(
    @Inject(ENVIRONMENT_ID) readonly environmentId: string,
    @Inject(RUNTIME_PROFILE_BASE_URL) readonly baseUrl: string,
    protected http: HttpClient
  ) {
    super();
  }

  get<R = any>(path: string[] | string): R {
    return resolveObjectPropertyPath(this.snapshot, path);
  }

  /** Loads an arbitrary json file as current "runtime profile" and returns it */
  load(path: string): Promise<T> {
    if (isAbsoluteURL(path)) throw new Error(`RuntimeConfig does not support absolute config loading!`);

    return lastValueFrom(this.http.get<any>(path, { responseType: 'json' }).pipe(tap((config) => this.patch(config))));
  }

  /** Initializes the runtime profile state using the provided {@link environmentId} */
  async init(): Promise<T> {
    return await this.load(normalizeUrl(this.baseUrl, `${this.environmentId}.json`));
  }
}
