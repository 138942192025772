import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokReminderCarouselComponent } from './reminder-carousel.component';
import { AokReminderTeaserModule } from './reminder-teaser/reminder-teaser.module';

@NgModule({
  declarations: [AokReminderCarouselComponent],
  exports: [AokReminderCarouselComponent],
  imports: [CommonModule, A11yUtilsModule, AokReminderTeaserModule, AokSvgIconComponent],
})
export class AokReminderCarouselModule {}
