<mat-menu #menu="matMenu" xPosition="before">
  <span
    mat-menu-item
    role="menuitem"
    [aokFocusable]="!entry.disabled"
    disableRipple="true"
    *ngFor="let entry of entries"
    (click)="onClick(entry)"
    (keyup.enter)="onClick(entry)"
    [disabled]="entry.disabled"
    [id]="entry.id ? entry.id : entry.title"
  >
    {{ entry.title }}
  </span>
</mat-menu>
