<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>
<div class="aok-container header">
  <div class="breadcrumbs">
    <aok-breadcrumbs [homeLinkUrl]="'/uebersicht'"></aok-breadcrumbs>
  </div>
</div>

<div class="aok-container body">
  <div class="aok-text-l1 created-date">{{ article.createdDate | date : 'dd.MM.yyyy' }}</div>
  <h3 class="title">{{ article.title }}</h3>
  <div class="content">
    <div class="image" *ngIf="article.imageUrl">
      <img [src]="article.imageUrl" alt="Titelbild" />
    </div>
    <aok-article-content [value]="article.content"></aok-article-content>
  </div>
</div>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
