import { APP_BOOTSTRAP_LISTENER, Provider } from '@angular/core';
import { AokSpecializationState } from '../../states/specialization.state';
import { filter, mergeMap, take } from 'rxjs/operators';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { AokPractitionerSpecializationEntry } from '../../schemas';
import { lastValueFrom } from 'rxjs';

export function initializeSpecializations(keycloak: KeycloakService, specializationState: AokSpecializationState) {
  return (): Promise<AokPractitionerSpecializationEntry[]> =>
    lastValueFrom(
      keycloak.keycloakEvents$.pipe(
        // wait for until keycloak is ready, then start initializing the specialization data, if we don't wait for the
        // ready event here we wouldn't have a valid session to fetch the specializations provider with
        filter((e) => e.type === KeycloakEventType.OnReady),
        take(1),
        mergeMap(() => specializationState.init())
      )
    );
}

export const AOK_SPECIALIZATIONS_INIT_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: initializeSpecializations,
  deps: [KeycloakService, AokSpecializationState],
  multi: true,
};
