<div
  class="aok-notification-sidebar"
  cdkTrapFocus
  cdkTrapFocusAutoCapture="true"
  [ngStyle]="{ height: 'calc(100% - ' + this.headerHeight + 'px)' }"
>
  <div class="header-container">
    <div class="header tw-flex tw-justify-between tw-items-center">
      <span>Benachrichtigungen</span>
      <aok-svg-icon
        aokFocusable
        class="icon-button"
        id="close"
        size="16px"
        (keyup.enter)="closeSidebar()"
        (click)="closeSidebar()"
      >
      </aok-svg-icon>
    </div>
    <div *ngIf="reminders?.length" class="action-header">
      <span>Aktion erforderlich</span>
    </div>
  </div>
  <div
    #content
    class="content"
    [ngClass]="{ 'content-cropped-height': reminders?.length }"
    *ngIf="notifications?.length || reminders?.length"
  >
    <aok-reminder
      class="aok-bg-sand"
      *ngFor="let reminder of reminders"
      [reminder]="reminder"
      [contextInfo]="getContextInfo(reminder)"
      (actionClicked)="closeSidebar()"
    >
    </aok-reminder>
    <ng-container *ngIf="notifications?.length">
      <div #notificationHeader class="header-container">
        <div class="notification-header tw-flex tw-justify-between tw-items-center">
          <span>Nachrichten</span>
          <div
            class="tw-flex"
            (click)="deleteReadNotifications()"
            aokFocusable
            (keyup.enter)="deleteReadNotifications()"
          >
            <a class="aok-link-1 secondary">Gelesene löschen</a>
            <aok-svg-icon id="delete" class="icon-button" size="16px"></aok-svg-icon>
          </div>
        </div>
      </div>
      <aok-notification
        *ngFor="let notification of notifications"
        [notification]="notification"
        [contextInfo]="getContextInfo(notification)"
      ></aok-notification>
    </ng-container>
  </div>
  <div
    *ngIf="!notifications?.length && !reminders?.length"
    class="no-data tw-flex tw-flex-col tw-items-center tw-gap-6"
  >
    <div class="tw-flex tw-justify-center tw-items-center">
      <aok-svg-icon id="bell" size="48px"></aok-svg-icon>
    </div>
    <h6>Keine neuen Nachrichten vorhanden</h6>
    <a class="aok-link-1" (click)="dialogRef.dispose()" aokFocusable (keyup.enter)="dialogRef.dispose()">
      Benachrichtigungen schließen
    </a>
  </div>
</div>
