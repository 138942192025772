<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>
<ng-container *ngIf="kvnState.isKvnPracticeTeam(); else componentTemplate">
  <div class="aok-container">
    <div class="custom-breadcrumbs">
      <a class="secondary" target="_self" href="/">
        <aok-svg-icon id="home" size="16px" title="Zurück zur Übersicht"></aok-svg-icon>
      </a>
      <aok-svg-icon id="arrow-right" size="16px"></aok-svg-icon>
      <span class="aok-text-l2">Profil</span>
    </div>
  </div>
</ng-container>

<ng-template #componentTemplate>
  <aok-cockpit-header-page-headline [headline]="msgHeadline"></aok-cockpit-header-page-headline>
</ng-template>

<aok-tabs class="aok-container flex" *ngIf="!kvnState.isKvnPracticeTeam()">
  <aok-tab
    *ngIf="isDoctor"
    routerLink="./praxisverwaltung"
    (keyup.enter)="router.navigate(['praxisverwaltung'], { relativeTo: this.route })"
    routerLinkActive="active"
  >Praxisverwaltung
  </aok-tab>
  <aok-tab
    routerLink="./praxisdaten"
    (keyup.enter)="router.navigate(['praxisdaten'], { relativeTo: this.route })"
    routerLinkActive="active"
  >Praxisdaten
  </aok-tab>
  <aok-tab
    routerLink="./profildaten"
    (keyup.enter)="router.navigate(['profildaten'], { relativeTo: this.route })"
    routerLinkActive="active"
  >Profildaten
  </aok-tab>
</aok-tabs>

<router-outlet></router-outlet>
<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
