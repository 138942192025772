import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import {
  A11yUtilsModule,
  AokInsuranceStatusClient,
  AokPopoverComponent,
  AokPopoverContentTemplateComponent,
  AokSvgIconComponent,
  explicitlyRequiredTrue,
  PopoverDirection,
  scrollToTop,
  setFocusOnFirstBannerFocusableElement,
  TrimControlDirective,
} from '@aok/common';
import {
  AokCheckboxModule,
  AokFooterModule,
  AokInputFieldModule,
  AokNotificationBannerModule,
  FeedbackButtonModule,
  FormControlErrorModule,
  FormErrorsBannerUtil,
  HeaderModule,
  InfoBannerComponent,
  RequiredMarkModule,
} from '@aok/components';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BillingSheetsPrintingResultsComponent } from './billing-sheets-printing-results/billing-sheets-printing-results.component';

@Component({
  selector: 'aok-cockpit-billing-sheets-print-collection',
  templateUrl: './billing-sheets-print-collection.component.html',
  styleUrls: ['./billing-sheets-print-collection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    HeaderModule,
    InfoBannerComponent,
    BillingSheetsPrintingResultsComponent,
    ReactiveFormsModule,
    AokNotificationBannerModule,
    RequiredMarkModule,
    A11yUtilsModule,
    AokPopoverComponent,
    AokSvgIconComponent,
    AokPopoverContentTemplateComponent,
    AokInputFieldModule,
    TrimControlDirective,
    AokCheckboxModule,
    FeedbackButtonModule,
    AokFooterModule,
    FormControlErrorModule,
  ],
})
export class BillingSheetsPrintCollectionComponent extends FormErrorsBannerUtil implements OnInit, OnDestroy {
  public popoverPosition = PopoverDirection.BottomRight;
  public popoverHeader =
    'Jeder Patient muss in einer eigenen Zeile stehen. Satz- und Sonderzeichen werden automatisch herausgefiltert.';
  public hintText =
    'Sie können bis zu 100 Patienten in den Eingabebereich kopieren oder eintragen und anschließend alle Abrechnungsscheine in einem Arbeitsgang drucken.';

  public bannerHeadline = 'Leider sind mehrere Fehler aufgetreten';

  public popoverList = [
    'Eingabebeispiele:',
    'A123456789 31.07.1970',
    'Mustermann 31.07.1970',
    'Muster-Müller, 31.07.1970',
  ];

  public formLabels = {
    batchPrintInput: 'Patienten',
    identityCheck: 'Identitätsprüfung',
  };

  public readonly maxLines = 100;
  public readonly maxLineCharacters = 1000;

  public linesLength = 0;

  public form = new UntypedFormGroup({
    batchPrintInput: new UntypedFormControl(null, [Validators.required]),
    identityCheck: new UntypedFormControl(false, explicitlyRequiredTrue),
  });

  public batchPrintingData$ = new BehaviorSubject(null);

  private ngDestroys = new ReplaySubject<void>(1);

  constructor(private router: Router, private client: AokInsuranceStatusClient, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToListChanges();
    this.subscribeToRouteChanges();
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  public submit(): void {
    if (this.form.invalid) {
      this.handleErrors({ form: this.form, hasCustomHeadline: true });
      setTimeout(() => setFocusOnFirstBannerFocusableElement(), 500);
    } else {
      this.errorIds = [];
      this.client
        .getBatchPrintData(this.form.value)
        .pipe(takeUntil(this.ngDestroys))
        .subscribe((response) => {
          this.batchPrintingData$.next(response);

          this.form.reset();

          this.cd.detectChanges();
        });
    }
  }

  /**
   * We return true as long as the linesLength < maxLines to prevent adding new lines in the textarea
   * */
  public onEnterEvent(): boolean {
    return this.linesLength < this.maxLines;
  }

  public switchToEntryPage(): void {
    this.batchPrintingData$.next(null);

    scrollToTop();
  }

  private subscribeToListChanges(): void {
    this.form
      .get('batchPrintInput')
      .valueChanges.pipe(takeUntil(this.ngDestroys))
      .subscribe((value) => {
        if (!value) {
          this.linesLength = 0;

          return;
        }

        let lines: string[] = value?.split('\n');

        if (lines.length > this.maxLines) {
          lines = lines.slice(0, this.maxLines);
          this.form.get('batchPrintInput').setValue(lines.join('\n'), { emitEvent: false });
        }

        this.linesLength = lines.length;

        if (!lines.some((line: string) => line.length > this.maxLineCharacters)) {
          return;
        }

        this.sliceLineCharacters(lines);
      });
  }

  /**
   * The sliceLineCharacters function limits the number of characters in each string in an array to a maximum number.
   * It creates a new array, iterates through each string, and modifies any strings that exceed the maximum character count.
   * The modified strings are joined together into a single string and assigned to a form control.
   * */
  private sliceLineCharacters(filteredLines: string[]): void {
    const data = [];

    filteredLines.forEach((line: string) => {
      if (line.length > this.maxLineCharacters) {
        data.push(line.trim().slice(0, this.maxLineCharacters));
      } else {
        data.push(line.trim());
      }
    });

    this.form.get('batchPrintInput').setValue(data.join('\n'), { emitEvent: false });
  }

  private subscribeToRouteChanges(): void {
    this.router.events
      .pipe(
        takeUntil(this.ngDestroys),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => this.batchPrintingData$.next(null));
  }
}
