import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AokFeedbackCategoriesState,
  AokFeedbackClient,
  ContextState,
  CurrentUserState,
  DialogBase,
  DialogRef,
  DropdownSchema,
  FeedbackCategory,
  FeedbackRequestSchema,
  sortAlphabetical,
  SVSFeedbackCategory,
} from '@aok/common';
import { lastValueFrom, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ContactService } from '../../services';
import { KvnState } from '../../states';

@Component({
  selector: 'aok-cockpit-feedback-popover',
  templateUrl: './feedback-popover.component.html',
  styleUrls: ['./feedback-popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FeedbackPopoverComponent implements OnInit, OnDestroy, DialogBase<boolean> {
  @Input() public route: ActivatedRoute;
  @Output() public submitEvent = new EventEmitter<unknown>();
  protected form: UntypedFormGroup;
  protected categories: DropdownSchema[];
  protected maxCharacters = 1000;
  protected supportEmail: string;
  private ngDestroyed: Subject<void>;

  protected get feedbackLength(): number {
    const feedback = this.form.get('feedback').value;
    return feedback ? feedback.length : 0;
  }

  constructor(
    public readonly dialogRef: DialogRef<boolean>,
    protected kvnState: KvnState,
    private feedbackClient: AokFeedbackClient,
    private currentUser: CurrentUserState,
    private feedbackCategoriesState: AokFeedbackCategoriesState,
    private router: Router,
    private contextState: ContextState,
    private contactService: ContactService
  ) {
    this.setCategories();
    this.supportEmail = this.contactService.getSupportEmail();
    this.ngDestroyed = new Subject<void>();
  }

  public ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.ngDestroyed)).subscribe((result) => this.createFormGroup(result.category));
    this.closeDialogOnRouteChange();
  }

  public ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  public submit(): void {
    if (this.form.valid) {
      lastValueFrom(
        this.feedbackClient.submit({
          category: this.form.get('category').value,
          feedback: this.form.get('feedback').value,
          consent: this.form.get('consent').value,
          lanr: this.currentUser.lanr || this.contextState.practitionerLanr,
          bsnr: this.contextState.bsnr,
        } as FeedbackRequestSchema)
      );

      this.dialogRef.dispose(true);
    } else {
      this.form.get('feedback').markAsDirty();
      this.form.get('feedback').updateValueAndValidity();
    }
  }

  private createFormGroup(category?: FeedbackCategory): void {
    this.form = new UntypedFormGroup({
      category: new UntypedFormControl(this.getCategoryKey(category), [Validators.required]),
      feedback: new UntypedFormControl(null, Validators.required),
      consent: new UntypedFormControl(false),
    });
  }

  private setCategories(): void {
    this.categories = sortAlphabetical(
      this.feedbackCategoriesState.snapshot.map((value) => {
        return {
          label: value,
          value: this.getCategoryKey(value),
        } as DropdownSchema;
      }),
      'label'
    );
  }

  /**
   * Get the category key in order to use it as dropdown values
   * @param value category label
   * @private
   */
  private getCategoryKey(value: FeedbackCategory | string): string {
    return [...Object.keys(FeedbackCategory), ...Object.keys(SVSFeedbackCategory)].find(
      (item) => FeedbackCategory[item] === value || SVSFeedbackCategory[item] === value
    );
  }

  private closeDialogOnRouteChange(): void {
    this.router.events
      .pipe(
        takeUntil(this.ngDestroyed),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => this.dialogRef.dispose(false));
  }
}
