export class AokCookieUtils {
  protected static get entries(): [string, string][] {
    return document.cookie
      ?.split(';')
      .map((pair) => pair.trim())
      .map((pair) => pair.split('=')) as [string, string][];
  }

  static get(name: string): string {
    return this.entries?.find(([key]) => key === name)?.[1];
  }

  static set(name: string, value: string, params?: string): void {
    const cookie = `${name}=${value}`;
    document.cookie = params ? `${cookie};${params}` : cookie;
  }

  static unset(name: string): void {
    this.set(name, '');
  }

  /**
   *
   * @param name removes the whole cookie. If you just want to remove its value, use the 'unset' function
   */
  static remove(name: string): void {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }

  static exists(name: string): boolean {
    return this.entries?.filter(([key]) => key === name).length > 0;
  }
}
