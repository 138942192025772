import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  A11yUtilsModule,
  AokBatchPrintingEntry,
  AokFullAddressPipe,
  AokInsuree,
  AokSvgIconComponent,
  DialogOverlay,
  TableColumnDef,
} from '@aok/common';
import { AokPaginationModule } from '@aok/components';
import { columnsTrackByFn } from '../../../../utils/trackBy.util';
import { PatientDetailSearchDialog } from '../../patient-detail-search-dialog/patient-detail-search-dialog.component';

@Component({
  selector: 'aok-cockpit-print-collection-table',
  templateUrl: './print-collection-table.component.html',
  styleUrls: ['./print-collection-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CdkTableModule,
    AokFullAddressPipe,
    A11yUtilsModule,
    AokSvgIconComponent,
    AokPaginationModule,
  ],
})
export class PrintCollectionTableComponent implements OnInit, OnChanges {
  @Input() batchPrintingData: AokBatchPrintingEntry[];
  @Input() tableName: string;
  @Input() headLine: string;
  @Input() displayColumns: TableColumnDef[];

  @Output() updateRow: EventEmitter<number> = new EventEmitter();
  @Output() patientErrorSolved: EventEmitter<AokInsuree> = new EventEmitter();

  public visibleBatchPrintingItems: AokBatchPrintingEntry[];

  public activePage = 0;
  public readonly pageSize = 10;
  protected readonly columnsTrackByFn = columnsTrackByFn;

  public get getDisplayedColumnIds(): string[] {
    return this.displayColumns?.map((column) => column?.id);
  }

  constructor(protected dialog: DialogOverlay) {}

  ngOnInit(): void {
    this.visibleBatchPrintingItems = this.batchPrintingData?.slice(0, this.pageSize);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.batchPrintingData) {
      const maxPageIndex = Math.ceil(this.batchPrintingData?.length / this.pageSize) - 1;
      this.activePage = Math.min(this.activePage, maxPageIndex);

      const startIndex = this.activePage * this.pageSize;
      this.visibleBatchPrintingItems = this.batchPrintingData?.slice(startIndex, startIndex + this.pageSize);
    }
  }

  public isOptions(column: string): boolean {
    return column === 'options';
  }

  public pageChange(activePage: number): void {
    const startIndex = activePage * this.pageSize;
    this.visibleBatchPrintingItems = this.batchPrintingData?.slice(startIndex, startIndex + this.pageSize);
    this.activePage = activePage;
  }

  public openPatientSearchDialog(patientWithError: AokBatchPrintingEntry): void {
    this.dialog
      .create(PatientDetailSearchDialog, {
        closable: true,
        props: {
          previousPatientSearchData: patientWithError,
        },
      })
      .subscribe((result) => {
        if (result) {
          this.updateRow.emit(patientWithError.uniqueId);
          this.patientErrorSolved.emit(result);
        }
      });
  }

  protected batchEntriesTrackByFn(index: number, batchEntry: AokBatchPrintingEntry): number {
    return batchEntry.id;
  }
}
