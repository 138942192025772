export enum PopoverDirection {
  LeftTop = 'left-top',
  LeftBottom = 'left-bottom',
  LeftCenter = 'left-center',
  RightTop = 'right-top',
  RightBottom = 'right-bottom',
  RightCenter = 'right-center',
  TopLeft = 'top-left',
  TopRight = 'top-right',
  TopCenter = 'top-center',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
  BottomCenter = 'bottom-center',
}

export type PopoverList = PopoverListItem[];
export type PopoverListItem =
  | {
      icon?: string;
      text: string;
    }
  | string;
