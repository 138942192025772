import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from '../header/header.module';
import { AokFooterModule } from '../footer/footer.module';
import { AokSvgIconComponent } from '@aok/common';

@Component({
  selector: 'aok-kvn-user-status-component',
  standalone: true,
  imports: [CommonModule, HeaderModule, AokFooterModule, AokSvgIconComponent],
  templateUrl: './kvn-user-status-component.html',
  styleUrls: ['./kvn-user-status-component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KvnUserStatusComponent {
  @Input() headline = '';
}
