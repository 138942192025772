import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AokInputFieldComponent } from './input-field.component';
import { RequiredMarkModule } from '../required-mark/required-mark.module';
import { InputFieldAffixModule } from '../../../directives';

@NgModule({
  declarations: [AokInputFieldComponent],
  exports: [AokInputFieldComponent],
  imports: [CommonModule, RequiredMarkModule, InputFieldAffixModule],
})
export class AokInputFieldModule {}
