import { Routes } from '@angular/router';
import { AokKnownArticleCategoryRoute } from '@aok/common';
import { helpResolver } from '../../resolvers/help.resolver';
import { AokNotAccessiblePage } from '../not-accessible/not-accessible.component';
import { HelpPage } from './help-page.component';

export const APP_HELP_ROUTES: Routes = [
  {
    path: '',
    component: AokNotAccessiblePage,
    canActivate: [() => false],
  },
  {
    path: ':category',
    component: HelpPage,
    resolve: {
      article: helpResolver,
    },
    data: {
      browserTitle: (d) => `${d.article?.title}`,
      title: {
        [AokKnownArticleCategoryRoute.GeneralInformation]: 'Allgemeine Informationen',
        [AokKnownArticleCategoryRoute.PortalUsage]: 'Nutzung des Arztportals',
      },
    },
  },
];
