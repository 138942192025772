import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { InfoBanner } from '../schemas';

import { AokApiBaseClient } from './api-base.client';

export const INFO_BANNER_API_URL = new InjectionToken<string>('INFO_BANNER_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/notification/info-banner',
});

@Injectable({
  providedIn: 'root',
})
export class AokInfoBannerClient extends AokApiBaseClient {
  constructor(@Inject(INFO_BANNER_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  get(): Observable<InfoBanner> {
    return this.http.get<InfoBanner>(this.resolveUrl());
  }

  save(infoBanner: InfoBanner): Observable<InfoBanner> {
    return this.http.put<InfoBanner>(this.resolveUrl(), infoBanner);
  }
}
