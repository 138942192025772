export interface TreeNode<T> {
  children?: (TreeNode<T> & T)[];
}
export type Tree<T> = (TreeNode<T> & T)[] | (TreeNode<T> & T);

export interface FlatTreeNode {
  level: number;
  hasChildren: boolean;
}
export type FlatTree<T> = (FlatTreeNode & T)[];

export function flattenTree<T>(tree: Tree<T>, baseLevel?: number): FlatTree<T> {
  if (tree == null) {
    return [];
  }

  return (Array.isArray(tree) ? tree : [tree]).reduce((flatTree, node) => {
    let childTree: FlatTree<T> = [];

    const lvl = baseLevel || 0;
    const nodeClone = Object.assign({}, node);
    const children = node.children;
    const hasChildren = children != null && children.length > 0;
    if (hasChildren) childTree = flattenTree<T>(children as Tree<T>, lvl + 1);

    // delete nodeClone.children;

    return [...flatTree, { ...nodeClone, level: lvl, hasChildren }, ...childTree];
  }, []);
}

export function restoreTree<T>(flatTree: FlatTree<T>): TreeNode<T>[] {
  let workingIndex = 0;
  return (
    flatTree &&
    flatTree.reduce<TreeNode<T>[]>((tree, node, i) => {
      if (i < workingIndex) return tree;

      let lastChildIndex = i + 1;
      const nodeClone = Object.assign({}, node);
      const nextNode = flatTree[lastChildIndex];
      const hasChildren = (nextNode && nextNode.level > node.level) || node.hasChildren;
      const parentLevel = node.level;

      delete nodeClone.hasChildren;
      delete nodeClone.level;

      let insertion: TreeNode<T>;
      if (hasChildren) {
        const descendants: FlatTreeNode[] = [];
        let nextChild: FlatTreeNode = nextNode;
        while (nextChild != null && nextChild.level > parentLevel) {
          descendants.push(nextChild);
          nextChild = flatTree[++lastChildIndex];
        }

        const children = restoreTree(descendants);
        insertion = { ...nodeClone, children } as any;
      } else {
        insertion = nodeClone as any;
      }
      workingIndex = lastChildIndex;

      return [...tree, insertion];
    }, [])
  );
}
