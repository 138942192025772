import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AokLoadingStateService } from '../../services';
import { ENVIRONMENT, EnvironmentSchema } from '../../schemas';

@Injectable()
export class AokLoaderInterceptor implements HttpInterceptor {
  private ongoingRequests = 0;
  private attachTimeoutId: ReturnType<typeof setTimeout> = undefined;
  private detachTimeoutId: ReturnType<typeof setTimeout> = undefined;
  private excludedUrls = ['/notification/count/me'];

  constructor(private loader: AokLoadingStateService, @Inject(ENVIRONMENT) private environement: EnvironmentSchema) {
    this.excludedUrls.push(this.environement.cardReaderApiUrl);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.loader.strategy !== 'onHttpEvent' || this.isExcludedRequest(request)) {
      return next.handle(request);
    }

    this.setupLoader();
    this.ongoingRequests++;

    return next.handle(request).pipe(
      finalize(() => {
        this.ongoingRequests--;

        if (this.ongoingRequests === 0) {
          this.detachLoader();
        }
      })
    );
  }

  private isExcludedRequest(request: HttpRequest<unknown>): boolean {
    return this.excludedUrls.some((excludedUrl) => request.url.includes(excludedUrl));
  }

  private setupLoader(): void {
    clearTimeout(this.detachTimeoutId);
    this.detachTimeoutId = undefined;
    this.attachTimeoutId = this.attachTimeoutId ?? setTimeout(() => this.loader.attach(), 300);
  }

  private detachLoader(): void {
    clearTimeout(this.attachTimeoutId);
    this.attachTimeoutId = undefined;
    this.detachTimeoutId = this.detachTimeoutId ?? setTimeout(() => this.loader.detach(), 500);
  }
}
