import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import {
  AokSearchClient,
  AuthService,
  ContextState,
  CurrentUserState,
  ENVIRONMENT,
  EnvironmentSchema,
  isAssistantType,
  isDoctor,
  KEYCLOAK_OPTIONS,
  KnownUserType,
} from '@aok/common';
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { AOK_CONTACT } from '../../providers/aok-contact.providers';
import { NOTIFICATIONS_ENABLED, NOTIFICATIONS_POLL } from '../../providers/notification.providers';
import { AokContactSchema } from '../../schema/aok-contact.schema';
import { NotificationSidebarService } from '../../services/notification-sidebar.service';
import { FeedbackAvailableState, KvnState } from '../../states';
import { MenuLink } from '../menu-link/menu-link.component';
import { contactListItems, contactListItemsLoggedOut } from './header.config';
import { AokHeaderService } from './header.service';

enum MenuType {
  PROFILE = 'Profil',
  CONTACT = 'Kontakt',
  BSNR = 'BSNR',
  SEARCH = 'Suche',
  EMPTY = '',
}

@Component({
  selector: 'aok-cockpit-header',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnDestroy, OnInit {
  @Input() isLogoALink = true;

  public notificationCount: Observable<number>;
  public MenuType = MenuType;
  public openedMenu: MenuType = MenuType.EMPTY;
  public profileListItems: MenuLink[];

  public phoneNumber: string;
  public userType: KnownUserType;
  public isKvnWithoutAccess: boolean;
  public isDoctor = isDoctor;
  public isAssistantType = isAssistantType;
  public searchSuggestions: string[] = [];
  private showFeedback: boolean;
  private readonly ngDestroys = new Subject<void>();
  private intervalId: ReturnType<typeof setTimeout> = undefined;

  public get hideStartEntry(): boolean {
    return ['/publik/kvnregistriert', '/publik/vollregistriert'].includes(location.pathname);
  }

  public get authenticated(): boolean {
    return this.keycloak.getKeycloakInstance()?.authenticated;
  }

  constructor(
    @Inject(AOK_CONTACT) contact: AokContactSchema,
    @Inject(KEYCLOAK_OPTIONS) readonly options: KeycloakOptions,
    @Inject(NOTIFICATIONS_ENABLED) readonly notificationEnabled: boolean,
    @Inject(NOTIFICATIONS_POLL) readonly notificationPoll: number,
    @Inject(ENVIRONMENT) private environment: EnvironmentSchema,
    private notificationSidebarService: NotificationSidebarService,
    private readonly currentUser: CurrentUserState,
    private keycloak: KeycloakService,
    protected service: AokHeaderService,
    private router: Router,
    protected contextState: ContextState,
    private kvnState: KvnState,
    private authService: AuthService,
    private feedbackAvailableState: FeedbackAvailableState,
    private client: AokSearchClient
  ) {
    this.phoneNumber = contact.tel as string;
    this.profileListItems = this.service.getProfileMenuLinks();
    this.feedbackAvailableState.isFeedBackAvailable
      .asObservable()
      .pipe(takeUntil(this.ngDestroys))
      .subscribe((showFeedback) => {
        this.showFeedback = showFeedback;
      });
  }

  public ngOnInit(): void {
    this.loadUserInformation();
    this.setupRoutingListener();
    this.scrollbarController();
  }

  public ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
    clearInterval(this.intervalId);
  }

  public hasFullHeader(): boolean {
    return this.service.hasRole() && !this.service.getHiddenContext() && !this.isKvnWithoutAccess;
  }

  public openNotificationSidebar(): void {
    this.notificationSidebarService.openNotificationSidebar().subscribe(() => {
      this.updateNotificationCount();
    });
  }

  public getNotificationCount(): void {
    this.notificationCount = this.service.getNotificationCount();
    this.updateNotificationCount();

    this.intervalId = setInterval(() => {
      // prevent poll from extending the session
      if (!this.keycloak.isTokenExpired(20)) {
        this.updateNotificationCount();
      }
    }, this.notificationPoll);
  }

  public toggleMenu(menu?: MenuType): void {
    this.openedMenu = menu && this.openedMenu !== menu ? menu : MenuType.EMPTY;
    this.scrollbarController();
  }

  public logout(): void {
    this.authService.logout();
  }

  public navigateHome(): void {
    if (!this.isLogoALink) {
      return;
    }

    this.service
      .getNavigationEntries()
      .pipe(first())
      .subscribe((entries) => {
        if (entries?.length === 1) {
          this.router.navigate([entries[0].linkUrl]);
        } else {
          this.router.navigate(['']);
        }
      });
  }

  public redirectToLogin(): void {
    const newOptions = this.options;
    newOptions.initOptions.redirectUri = window.location.href.slice(0, window.location.href.indexOf('/publik'));
    this.keycloak.init(newOptions);
  }

  public isMenuClosed(): boolean {
    return this.openedMenu === MenuType.EMPTY;
  }

  public closeOnClickOutside(e: Event): void {
    const clickedOutsideHeader = !e
      .composedPath()
      .includes(document.getElementsByTagName('aok-cockpit-header').item(0));
    if (clickedOutsideHeader) {
      this.openedMenu = MenuType.EMPTY;
      this.scrollbarController();
    }
  }

  public getContactMenuLinks(): MenuLink[] {
    if (this.authenticated) {
      return this.showFeedback ? contactListItems : contactListItems.filter((navLink) => navLink.id !== 'feedback');
    }

    return contactListItemsLoggedOut;
  }

  public handleSearch(queryParams: Params): void {
    this.toggleMenu(MenuType.SEARCH);
    this.router.navigate(['/suchergebnis'], { queryParams: { ...queryParams } });
  }

  public retrieveSearchSuggestions(searchString: string): void {
    this.client
      .listSuggestions({
        query: searchString,
      })
      .subscribe((result) => (this.searchSuggestions = result));
  }

  protected showDisturbanceMessage(contextSelected: boolean): boolean {
    return this.environment.enableDisturbanceMessage && this.hasFullHeader() && contextSelected;
  }

  private loadUserInformation(): void {
    this.kvnState
      .getKvnError$()
      .pipe(takeUntil(this.ngDestroys))
      .subscribe((kvnError) => (this.isKvnWithoutAccess = !!kvnError));

    this.currentUser
      .asObservable()
      .pipe(filter((user) => user != null))
      .subscribe((user) => {
        this.service.onUserReady(user);
        this.userType = user.userType;

        if (this.notificationEnabled && this.service.hasRole()) {
          this.getNotificationCount();
        }
      });
  }

  private setupRoutingListener(): void {
    this.router.events
      .pipe(
        takeUntil(this.ngDestroys),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.closeMenu();
        this.scrollbarController();
      });
  }

  private closeMenu(): void {
    // check if the menu is opened and if it's opened we close the menu
    !this.isMenuClosed() && (this.openedMenu = MenuType.EMPTY);
  }

  /**
   * retrieve and set the count field
   */
  private updateNotificationCount(): void {
    this.service.updateNotificationCount().subscribe();
  }

  private scrollbarController(): void {
    const htmlBody = document.getElementsByTagName('body').item(0);
    if (this.openedMenu !== MenuType.EMPTY && !htmlBody.classList.contains('hiddenScroll')) {
      htmlBody.classList.add('hiddenScroll');
    } else if (this.openedMenu === MenuType.EMPTY && htmlBody.classList.contains('hiddenScroll'))
      htmlBody.classList.remove('hiddenScroll');
  }
}
