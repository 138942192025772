import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokAlertModule } from '../cdk';
import { AokNotificationBannerComponent } from './notification-banner.component';

@NgModule({
  declarations: [AokNotificationBannerComponent],
  exports: [AokNotificationBannerComponent],
  imports: [CommonModule, AokAlertModule, RouterModule, A11yUtilsModule, AokSvgIconComponent],
})
export class AokNotificationBannerModule {}
