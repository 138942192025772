import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AokErrorMessageModule } from '../error-message/error-message.module';
import { AokRequiredErrorMessageComponent } from './required-error-message.component';

@NgModule({
  declarations: [AokRequiredErrorMessageComponent],
  exports: [AokRequiredErrorMessageComponent],
  imports: [CommonModule, AokErrorMessageModule],
})
export class AokRequiredErrorMessageModule {}
