import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  A11yUtilsModule,
  AokAuthOptionPipeModule,
  AokDoctorTypeIntlPipe,
  AokFullNamePipeModule,
  AokOrgTypeIntlPipe,
  AokPopoverComponent,
  AokSvgIconComponent,
  AokUserSalutationIntlPipe,
  AokZeroPadPipeModule,
  DialogOverlayModule,
  TrimControlDirective,
} from '@aok/common';
import { AokStepDefModule } from '../../../../directives';
import { AokHintModule, AokInputFieldModule } from '../../../cdk';
import { AokCheckboxModule } from '../../../checkbox/checkbox.module';
import { AokDropdownModule } from '../../../dropdown/dropdown.module';
import { FormControlErrorModule } from '../../../form-control-error/module';
import { AokNotificationBannerModule } from '../../../notification-banner/notification-banner.module';
import { AokRadioModule } from '../../../radio-button/radio.module';
import { AokStepperModule } from '../../../stepper/stepper.module';
import { AokDialogLayoutModule } from '../../dialog-layout/dialog-layout.module';
import { AokRegistrationKVNDialog } from './kvn-registration-dialog.component';

@NgModule({
  declarations: [AokRegistrationKVNDialog],
  imports: [
    DialogOverlayModule,
    AokStepperModule,
    AokDropdownModule,
    AokRadioModule,
    CommonModule,
    AokHintModule,
    ReactiveFormsModule,
    FormControlErrorModule,
    AokFullNamePipeModule,
    AokZeroPadPipeModule,
    AokStepDefModule,
    AokNotificationBannerModule,
    AokAuthOptionPipeModule,
    AokInputFieldModule,
    A11yModule,
    A11yUtilsModule,
    AokSvgIconComponent,
    AokDialogLayoutModule,
    AokPopoverComponent,
    AokCheckboxModule,
    RouterModule,
    TrimControlDirective,
    AokOrgTypeIntlPipe,
    AokUserSalutationIntlPipe,
    AokDoctorTypeIntlPipe,
  ],
})
export class AokRegistrationDialogKVNModule {}
