import { OverlayModule } from '@angular/cdk/overlay';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { AokLoadingStateDirective } from './loading-state.directive';
import { LOADING_INDICATOR_COMPONENT, AokLoadingIndicatorOutletDirective } from './loading-state-indicator.directive';
import { LOADING_OVERLAY_STRATEGY, LoadingOverlayStrategy } from '../../services';

const declarations = [AokLoadingIndicatorOutletDirective, AokLoadingStateDirective];

export interface LoadingStateModuleConfig {
  /**
   * Sets the type of component that should be embedded as loading indicator. The value
   * will be used as "root" value for the {@link LOADING_INDICATOR_COMPONENT} injection
   * token used by any {@link AokLoadingIndicatorOutletDirective} instance. The injection token can
   * be overwritten at any component tree level
   */
  indicator: Type<any>;
  /**
   * Optionally sets the strategy that should be used for the {@link AokLoadingStateService} provider.
   * The overlay will globally block the user from using any elements as long as the operation
   * persists
   */
  overlayStrategy?: LoadingOverlayStrategy;
}

@NgModule({
  declarations,
  exports: declarations,
  imports: [OverlayModule],
})
export class AokLoadingStateModule {
  static forRoot(config: LoadingStateModuleConfig): ModuleWithProviders<AokLoadingStateModule> {
    return {
      ngModule: AokLoadingStateModule,
      providers: [
        { provide: LOADING_INDICATOR_COMPONENT, useValue: config.indicator },
        { provide: LOADING_OVERLAY_STRATEGY, useValue: config.overlayStrategy },
      ],
    };
  }
}
