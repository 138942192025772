import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AokBenefitRegion } from '@aok/common';
import { BenefitsService } from '../services/benefits.service';

export const benefitRegionsResolver: ResolveFn<AokBenefitRegion[]> = () => {
  const service = inject(BenefitsService);

  return service.retrieveRegions();
};
