import { Pipe, PipeTransform } from '@angular/core';
import { AddressData } from '../../schemas';
import { getStringFormat } from '../../utils';

@Pipe({ name: 'aokFullAddress', standalone: true })
export class AokFullAddressPipe implements PipeTransform {
  transform(addressData: AddressData | any, format = 'STN SN, ZZ LL'): string {
    return getStringFormat(addressData, format, {
      STN: (ad) => ad.streetName || ad.street,
      SN: (ad) => ad.streetNumber,
      ZZ: (ad) => ad.zipCode || ad.zip,
      LL: (ad) => ad.location || ad.city,
    });
  }
}
