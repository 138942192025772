<dialog-layout
  class="tw-flex tw-flex-col"
  fullscreen="false"
  aokFocusFirstChild
  cdkTrapFocus
  [isClosable]="!!cancelButtonText"
  [headerText]="headerText"
  [autoBlur]="true"
>
  <div class="tw-mb-7">
    <ng-container *ngIf="isLastDoctorAtBSNRLocation; else deleteUserTemplate">
      Sind Sie sicher, dass Sie Ihr Profil löschen möchten? Wenn Sie auf "Profil löschen" klicken, werden Ihre
      ausgewählten Profile entfernt. Falls Sie beide Profile löschen, können Sie danach das Arztportal nicht länger
      nutzen.<br /><br />

      Da Sie der einzige Arzt sind, der aktuell für diese Praxis im Arztportal registriert ist, wird mit der Löschung
      beider Profile auch die Praxis mitsamt aller dafür registrierten Praxismitarbeiter gelöscht. Gelöschte Nutzer
      können das Arztportal nicht länger nutzen. <br /><br />

      Hinweis: Wir bewahren Ihre Daten nach der Löschung für drei Jahre gesperrt auf. Die Daten werden nicht
      weiterverarbeitet und nach Ablauf dieser Frist gelöscht. Weiteres entnehmen Sie bitte unserer
      <a class="aok-link-1" (click)="navigateToDataProtectionPage()" (keypress.enter)="navigateToDataProtectionPage()"
        >Datenschutzerklärung</a
      >.
    </ng-container>

    <ng-template #deleteUserTemplate>
      Sind Sie sicher, dass Sie Ihr Profil löschen möchten? Wenn Sie auf "Profil löschen" klicken, werden Ihre
      ausgewählten Profile entfernt. Falls Sie beide Profile löschen, können Sie danach das Arztportal nicht länger
      nutzen.<br /><br />
      Hinweis: Wir bewahren Ihre Daten nach der Löschung für drei Jahre gesperrt auf. Die Daten werden nicht
      weiterverarbeitet und nach Ablauf dieser Frist gelöscht. Weiteres entnehmen Sie bitte unserer

      <a class="aok-link-1" (click)="navigateToDataProtectionPage()" (keypress.enter)="navigateToDataProtectionPage()"
        >Datenschutzerklärung</a
      >.
    </ng-template>

    <br /><br />
    Bitte wählen Sie die Profile aus, die Sie löschen möchten:
  </div>

  <aok-input-field class="block tw-mb-7">
    <aok-checkbox [(ngModel)]="aokProfile">
      <p>Profil AOK-Arztportal</p>
    </aok-checkbox>
  </aok-input-field>

  <aok-input-field class="block tw-mb-7">
    <aok-checkbox [(ngModel)]="kvnProfile">
      <p>Profil KVN-Zugang</p>
    </aok-checkbox>
  </aok-input-field>

  <dialog-footer>
    <button *ngIf="cancelButtonText" (click)="cancelDialog()" (keyup.enter)="cancelDialog()" class="secondary">
      {{ cancelButtonText }}
    </button>

    <button
      *ngIf="confirmButtonText"
      [disabled]="!aokProfile && !kvnProfile"
      (click)="submitDialog()"
      (keyup.enter)="submitDialog()"
    >
      {{ confirmButtonText }}
    </button>
  </dialog-footer>
</dialog-layout>
