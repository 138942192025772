<div
  class="cockpit-meta-header-container"
  [ngClass]="{ 'menu-open': !isMenuClosed() }"
  *ngIf="authenticated"
  (clickOutside)="closeOnClickOutside($event)"
>
  <div class="cockpit-meta-header aok-container">
    <div class="header">
      <div class="tw-flex">
        <div
          class="aok-branding"
          title="AOK-Arztportal (Link zur Übersicht)"
          aokFocusable
          (click)="navigateHome()"
          (keyup.enter)="navigateHome()"
        >
          <img [src]="'assets/img/aok-logo-small.svg'" alt="AOK-Arztportal (Link zur Übersicht)" />
          <div *ngIf="!hasFullHeader()" class="aok-header-slogan">
            <h6>AOK-Arztportal</h6>
            <span class="aok-text-l3">Vernetzte Versorgung für Niedersachsen</span>
          </div>
        </div>

        <div
          *ngIf="hasFullHeader() && contextState.contextSelected$() | async"
          class="bsnr-selector"
          aokFocusable
          (keyup.enter)="toggleMenu(MenuType.BSNR)"
          (click)="toggleMenu(MenuType.BSNR)"
        >
          <span class="bsnr">BSNR {{ contextState.bsnr }} | {{ contextState.org?.name }}</span>
          <div class="tw-flex tw-items-center">
            <ng-container *ngIf="contextState.getPractitioner$() | async as user; else doctorContextInfo">
              <ng-container *ngIf="user && isAssistantType(userType); else doctorContextInfo">
                <ng-container *ngIf="isDoctor(user)">
                  <span class="title">{{ user | aokFullName : 'TT ff LL' : user.practitionerResource }}</span>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-template #doctorContextInfo>
              <span class="title">{{ contextState.orgAddress | aokFullAddress : 'STN SN' }}</span>
            </ng-template>

            <aok-svg-icon
              class="org-context-toggle"
              [name]="openedMenu === MenuType.BSNR ? 'angle-up' : 'angle-down'"
              size="20px"
            ></aok-svg-icon>
          </div>
        </div>
      </div>

      <div class="cockpit-meta-header-controls">
        <aok-disturbance-message
          *ngIf="showDisturbanceMessage(contextState.contextSelected$() | async)"
        ></aok-disturbance-message>

        <aok-notification-bell
          *ngIf="notificationEnabled && hasFullHeader()"
          [counter]="notificationCount | async"
          aokFocusable
          (click)="openNotificationSidebar()"
          (keyup.enter)="openNotificationSidebar()"
        ></aok-notification-bell>
        <aok-a11y-links></aok-a11y-links>

        <aok-header-link
          [isOpened]="openedMenu === MenuType.CONTACT"
          [icon]="'phone'"
          [label]="MenuType.CONTACT"
          (toggleLink)="toggleMenu(MenuType.CONTACT)"
          [altTextOpened]="'Kontakt-Optionen schließen'"
          [altTextClosed]="'Kontakt-Optionen öffnen'"
        ></aok-header-link>

        <aok-header-link
          *ngIf="hasFullHeader()"
          [isOpened]="openedMenu === MenuType.PROFILE"
          [icon]="'login'"
          [label]="MenuType.PROFILE"
          (toggleLink)="toggleMenu(MenuType.PROFILE)"
          [altTextOpened]="'Profil-Optionen schließen'"
          [altTextClosed]="'Profil-Optionen öffnen'"
        ></aok-header-link>

        <aok-header-link
          *ngIf="hasFullHeader()"
          [isOpened]="openedMenu === MenuType.SEARCH"
          [icon]="'search'"
          [label]="MenuType.SEARCH"
          (toggleLink)="toggleMenu(MenuType.SEARCH)"
          [altTextOpened]="'Suche-Optionen schließen'"
          [altTextClosed]="'Suche-Optionen öffnen'"
        ></aok-header-link>

        <aok-header-link
          *ngIf="!hasFullHeader()"
          [isOpened]="false"
          [icon]="'exit'"
          [label]="'Abmelden'"
          (toggleLink)="logout()"
        ></aok-header-link>
      </div>
    </div>
  </div>
  <aok-nav [entries]="service.getNavigationEntries() | async" *ngIf="isMenuClosed()"></aok-nav>

  <div *ngIf="openedMenu === MenuType.CONTACT" (keydown.escape)="toggleMenu(MenuType.CONTACT)" class="open-menu">
    <div class="aok-container contact">
      <div class="description">
        <div class="tw-flex tw-items-center">
          <aok-svg-icon [name]="'phone'" [size]="16"></aok-svg-icon>
          <span>Sie haben Fragen oder Feedback zum Arztportal?</span>
        </div>
        <p>
          Für Fragen rund um das Arztportal sind wir von Montag bis Freitag von 9.00 - 17.00 Uhr (Donnerstag bis 18.00
          Uhr) telefonisch für Sie erreichbar.
        </p>
        <h3 class="aok-bold">{{ phoneNumber }}</h3>
      </div>
      <aok-menu-link [items]="getContactMenuLinks()"></aok-menu-link>
    </div>
  </div>

  <div *ngIf="openedMenu === MenuType.PROFILE" (keydown.escape)="toggleMenu(MenuType.PROFILE)" class="open-menu">
    <div class="aok-container profile">
      <div class="description">
        <div class="tw-flex tw-items-center">
          <aok-svg-icon [name]="'exit'" [size]="16"></aok-svg-icon>
          <span>Sie möchten sich abmelden?</span>
        </div>
        <button (click)="logout()" aokFocusable>Abmelden</button>
      </div>
      <aok-menu-link [items]="profileListItems"></aok-menu-link>
    </div>
  </div>

  <div *ngIf="openedMenu === MenuType.SEARCH" (keydown.escape)="toggleMenu(MenuType.SEARCH)" class="open-menu">
    <div class="aok-container search">
      <div class="tw-col-1-13">
        <aok-cockpit-search-bar
          (submitted)="handleSearch($event)"
          (loadSuggestions)="retrieveSearchSuggestions($event)"
          [suggestions]="searchSuggestions"
        ></aok-cockpit-search-bar>
      </div>
    </div>
  </div>

  <aok-org-context-select
    cdkTrapFocus
    cdkTrapFocusAutoCapture="true"
    *ngIf="openedMenu === MenuType.BSNR"
    [mode]="userType"
    [options]="contextState.contextSelectorOptions"
    (closeBSNRList)="toggleMenu(MenuType.BSNR)"
    (keydown.escape)="toggleMenu(MenuType.BSNR)"
  ></aok-org-context-select>
</div>

<!--Header visible if the user is not fully logged in-->
<div class="cockpit-meta-header-container" *ngIf="!authenticated">
  <div class="cockpit-meta-header aok-container">
    <div class="header">
      <div class="tw-flex">
        <div
          [ngClass]="{ '!tw-cursor-default': !isLogoALink }"
          class="aok-branding"
          title="AOK-Arztportal (Link zur Startseite)"
          aokFocusable
          (click)="navigateHome()"
          (keyup.enter)="navigateHome()"
        >
          <img [src]="'assets/img/aok-logo-small.svg'" alt="AOK-Arztportal (Link zur Startseite)" />
          <div class="aok-header-slogan">
            <h6>AOK-Arztportal</h6>
            <span class="aok-text-l3">Vernetzte Versorgung für Niedersachsen</span>
          </div>
        </div>
      </div>
      <div class="cockpit-meta-header-controls">
        <aok-a11y-links></aok-a11y-links>

        <aok-header-link
          [isOpened]="openedMenu === MenuType.CONTACT"
          [icon]="'phone'"
          [label]="MenuType.CONTACT"
          [altTextOpened]="'Kontakt-Optionen öffnen'"
          [altTextClosed]="'Kontakt-Optionen schließen'"
          (toggleLink)="toggleMenu(MenuType.CONTACT)"
        ></aok-header-link>
      </div>
    </div>
  </div>

  <nav *ngIf="isMenuClosed()" class="nav-elevation aok-container">
    <div class="link-container" [ngClass]="{ 'tw-invisible tw-pointer-events-none': hideStartEntry }">
      <a class="aok-link-1" title="Start" aokFocusable (click)="redirectToLogin()">Start</a>
    </div>
  </nav>
  <div class="aok-container contact" *ngIf="openedMenu === MenuType.CONTACT">
    <div class="description">
      <div class="tw-flex tw-items-center">
        <aok-svg-icon [name]="'phone'" [size]="16"></aok-svg-icon>
        <span>Sie haben Fragen oder Feedback zum Arztportal?</span>
      </div>
      <p>
        Für Fragen rund um das Arztportal sind wir von Montag bis Freitag von 9.00 - 17.00 Uhr (Donnerstag bis 18.00
        Uhr) telefonisch für Sie erreichbar.
      </p>
      <h3 class="aok-bold">{{ phoneNumber }}</h3>
    </div>
    <aok-menu-link [items]="getContactMenuLinks()"></aok-menu-link>
  </div>
</div>
