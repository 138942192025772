<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>

<aok-cockpit-header-page-headline
  [headline]="'Sammeldruck von Abrechnungsscheinen'"
  [hint]="(batchPrintingData$ | async) ? '' : hintText"
>
</aok-cockpit-header-page-headline>

<ng-container *ngIf="batchPrintingData$ | async; else entryPage">
  <aok-cockpit-billing-sheets-printing-results
    class="tw-mb-3"
    [batchPrintingData]="batchPrintingData$ | async"
    (switchToEntryPage)="switchToEntryPage()"
  ></aok-cockpit-billing-sheets-printing-results>
</ng-container>

<ng-template #entryPage>
  <ng-container *ngTemplateOutlet="entryPageTemplate"></ng-container>
</ng-template>

<ng-template #entryPageTemplate>
  <form class="tw-mt-3 tw-mb-3" [formGroup]="form">
    <div class="aok-container">
      <ng-container *ngIf="errorIds.length">
        <aok-notification-banner
          class="tw-col-3-10 tw-mb-3"
          accent="bad"
          [bannerHeadline]="bannerHeadline"
          [bannerLabel]="bannerLabel"
          [bannerLinks]="errorIds"
        >
        </aok-notification-banner>
      </ng-container>

      <div class="tw-col-3-12" aokFocusFirstChild>
        <div class="full-width required-label tw-mb-8">
          <aok-required-mark></aok-required-mark>
          Pflichtfeld
        </div>

        <h5 class="tw-mb-5">
          Patient mit folgenden Daten ermitteln:
          <aok-popover
            class="tw-ml-8"
            width="384"
            [anchor]="false"
            [direction]="popoverPosition"
            [xaxis]="3"
            [yaxis]="-2"
          >
            <aok-svg-icon [name]="'question-circle_filled'" size="19" aok-anchor> </aok-svg-icon>
            <aok-popover-content-template [label]="popoverHeader" [list]="popoverList"></aok-popover-content-template>
          </aok-popover>
        </h5>

        <aok-input-field [label]="formLabels['batchPrintInput']" required="true">
          <textarea
            id="batchPrintInput"
            placeholder="KV-Nr. und Geburtsdatum oder Nachname und Geburtsdatum eingeben (Enter-Taste für neue Zeile)"
            formControlName="batchPrintInput"
            (keydown.enter)="onEnterEvent()"
            aokTrim
          ></textarea>

          <p class="aok-text-l3 tw-mt-11">
            {{ maxLines - linesLength ?? 0 }} {{ maxLines - linesLength === 1 ? 'Zeile' : 'Zeilen' }} verbleibend
          </p>
        </aok-input-field>

        <h5 class="tw-mt-5 tw-mb-5">Identitätsprüfung</h5>

        <aok-input-field>
          <aok-checkbox formControlName="identityCheck" id="identityCheck" aokFocusable>
            <p>Hiermit bestätige ich die Überprüfung der Identität aller Patienten. *</p>
          </aok-checkbox>
        </aok-input-field>

        <div class="tw-mt-3">
          <button (click)="submit()" class="primary">Status abfragen</button>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
