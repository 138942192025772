import { Injectable } from '@angular/core';
import { BehaviorState, KvnError, KvnInfo, PrimitiveBehaviorState } from '@aok/common';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class KvnState {
  private kvnErrorState = new PrimitiveBehaviorState<KvnError>();
  private kvnInfoState = new BehaviorState<KvnInfo>();

  public getKvnError$(): Observable<KvnError> {
    return this.kvnErrorState.asObservable();
  }

  public getKvnError(): KvnError {
    return this.kvnErrorState.snapshot;
  }

  public setKvnError(kvnError: KvnError): void {
    this.kvnErrorState.patch(kvnError);
  }

  public getKvnInfo$(): Observable<KvnInfo> {
    return this.kvnInfoState.asObservable().pipe(filter((kvnInfo) => !!kvnInfo));
  }
  public getKvnInfo(): KvnInfo {
    return this.kvnInfoState.snapshot;
  }

  public isKvn(): boolean {
    return this.kvnInfoState.snapshot?.isKvn;
  }

  public isKvnPracticeTeam(): boolean {
    return this.kvnInfoState.snapshot?.isKvnPracticeTeam;
  }

  public isKvnDoctor(): boolean {
    return this.kvnInfoState.snapshot?.isKvnDoctor;
  }

  public isKvnWithoutAccess(): boolean {
    return this.kvnInfoState.snapshot.isKvnWithoutAccess;
  }

  public isKvnOrigin(): boolean {
    return this.kvnInfoState.snapshot.isKvnOrigin;
  }

  public setKvnInfo(kvnInfo: KvnInfo): void {
    this.kvnInfoState.patch(kvnInfo);
  }
}
