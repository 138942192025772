<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>
<aok-cockpit-header-page-headline [headline]="benefit.name"></aok-cockpit-header-page-headline>

<div class="aok-container">
  <div class="tw-col-2-12 mandatory-sections">
    <h4>Inhalte</h4>
    <div class="tw-flex tw-flex-col content-overview">
      <ul>
        <li *ngFor="let section of sections">
          <a
            aokFocusable
            class="aok-link-1"
            (click)="handleContentOverviewClick(section.id)"
            (keyup.enter)="handleContentOverviewClick(section.id)"
          >
            <aok-svg-icon id="arrow-rounded_down" size="16px"></aok-svg-icon>
            <span>{{ section.title }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="aok-container body">
  <div class="tw-col-2-12 mandatory-sections">
    <h4 [id]="mandatorySections.overview.id">{{ mandatorySections.overview.title }}</h4>
    <h6>Indikation</h6>
    <ul>
      <li *ngFor="let indication of benefit.icdCodes">{{ indication.icdCodeDescription }}</li>
    </ul>

    <h6>Kategorie</h6>
    <span>{{ benefit.benefitCategory.categoryName }}</span>

    <h6>Region</h6>
    <span>{{ benefit.benefitRegion.regionName }}</span>

    <h4 [id]="mandatorySections.serviceDescription.id">{{ mandatorySections.serviceDescription.title }}</h4>
    <div class="tw-mb-10" [innerHtml]="benefit.serviceDescription"></div>

    <h4 [id]="mandatorySections.participationRequirements.id">
      {{ mandatorySections.participationRequirements.title }}
    </h4>
    <div class="tw-mb-10" [innerHtml]="benefit.participationRequirements"></div>
  </div>
</div>

<ng-container *ngIf="benefit.additionalInformation">
  <div class="aok-container">
    <div class="tw-col-2-12">
      <h4 class="tw-mt-3 tw-mb-8" [id]="optionalSections.additionalInformation.id">
        {{ optionalSections.additionalInformation.title }}
      </h4>
      <div class="tw-mb-10" [innerHtml]="benefit.additionalInformation"></div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="benefit.patientInformation">
  <div class="patient-information body">
    <div class="aok-container">
      <h4 class="tw-col-2-12 tw-mb-8" [id]="optionalSections.patientInformation.id">
        {{ optionalSections.patientInformation.title }}
      </h4>
      <div class="tw-col-2-12 tw-mb-10" [innerHTML]="benefit.patientInformation"></div>
    </div>
  </div>
  <div class="aok-container body">
    <div class="tw-col-2-12 send-information">
      <h5 class="tw-mb-5">Senden Sie diese Informationen an Ihre Patienten weiter</h5>
      <button id="emailButton" (click)="sendEmail()">E-Mail senden</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="benefit.benefitDocuments?.length">
  <div class="aok-container body">
    <div class="tw-col-2-12">
      <div class="download-documents">
        <h4 class="tw-mb-6" [id]="optionalSections.benefitDocuments.id">
          {{ optionalSections.benefitDocuments.title }}
        </h4>
        <ng-container *ngFor="let document of benefit.benefitDocuments">
          <div
            aokFocusable
            class="tw-items-center document"
            (click)="downloadDocument(document)"
            (keyup.enter)="downloadDocument(document)"
          >
            <aok-svg-icon id="download" size="52"></aok-svg-icon>
            <div class="tw-flex-col">
              <p>{{ document.pdfName | aokRemoveFileExtension }}</p>
              <span>PDF ({{ document.pdfSize | fileSize }})</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
