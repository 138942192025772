import { Pipe, PipeTransform } from '@angular/core';
import { AokInsuranceStatus } from '../../schemas';
import { getInsuranceStatusText } from '../../utils';

@Pipe({ name: 'aokInsuranceStatus', standalone: true })
export class AokInsuranceStatusPipe implements PipeTransform {
  transform(value: AokInsuranceStatus): string {
    return getInsuranceStatusText(value);
  }
}
