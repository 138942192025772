import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { KvnState } from '@aok/components';
import { first, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const kvnNotAllowedGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const kvnState = inject(KvnState);
  const router = inject(Router);

  return kvnState.getKvnInfo$().pipe(
    first(),
    map((kvnInfo) => kvnInfo.isKvnWithoutAccess || router.parseUrl('/uebersicht'))
  );
};
