import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'aok-cockpit-header-page-headline',
  styleUrls: ['header-pageheadline.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="aok-container header">
      <div class="breadcrumbs" *ngIf="showBreadcrumbs">
        <aok-breadcrumbs [homeLinkUrl]="'/uebersicht'"></aok-breadcrumbs>
      </div>
      <div class="header-popover-container">
        <h3 class="aok-bold" [ngClass]="{ withoutBreadcrumbs: !showBreadcrumbs }">{{ headline }}</h3>
        <ng-content select="[headline-additional-info]"></ng-content>
        <button *ngIf="actionButton" (click)="actionClicked.emit()">{{ actionButton }}</button>
      </div>

      <p *ngIf="hint" [innerHtml]="hint"></p>

      <ng-content select="[hint-content]"></ng-content>
    </div>
  `,
})
export class HeaderPageHeadlineComponent {
  @Input() headline: string;
  @Input() hint: string;
  @Input() showBreadcrumbs = true;
  @Input() actionButton: string;

  @Output() actionClicked = new EventEmitter<void>();

  constructor() {}
}
