import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AokOrg, AokOrgClient, UserService } from '@aok/common';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const existingOrganizationsDataResolver: ResolveFn<AokOrg[]> = () => {
  const userService = inject(UserService);
  const orgClient = inject(AokOrgClient);

  const parsedToken = userService.getParsedToken();
  // TODO use kvn service
  if (!parsedToken.kvnUniqueId) {
    return [];
  }

  return orgClient.listMine().pipe(
    map((page) => page?._embedded?.items),
    catchError(() => of([]))
  );
};
