import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AokDefaultErrorMessageComponent } from './default-error-message.component';
import { AokErrorMessageModule } from '../error-message/error-message.module';
import { DEFAULT_FORM_ERROR_COMPONENT } from '../../form-control-error/form-control-error.directive';

@NgModule({
  declarations: [AokDefaultErrorMessageComponent],
  exports: [AokDefaultErrorMessageComponent],
  imports: [CommonModule, AokErrorMessageModule],
})
export class AokDefaultErrorMessageModule {
  static forRoot(): ModuleWithProviders<AokDefaultErrorMessageModule> {
    return {
      ngModule: AokDefaultErrorMessageModule,
      providers: [{ provide: DEFAULT_FORM_ERROR_COMPONENT, useValue: AokDefaultErrorMessageComponent }],
    };
  }
}
