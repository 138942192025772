import { Injectable } from '@angular/core';
import { PrimitiveBehaviorState } from '@aok/common';

enum DisturbanceState {
  INITIAL = 'INITIAL',
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
}

@Injectable({
  providedIn: 'root',
})
export class DisturbanceService {
  private displayedState = new PrimitiveBehaviorState<DisturbanceState>(DisturbanceState.INITIAL);

  public get shouldDisplay(): boolean {
    return this.displayedState.snapshot === DisturbanceState.INITIAL;
  }

  public get opened(): boolean {
    return this.displayedState.snapshot === DisturbanceState.OPENED;
  }

  public markAsOpened(): void {
    this.displayedState.patch(DisturbanceState.OPENED);
  }

  public markAsClosed(): void {
    this.displayedState.patch(DisturbanceState.CLOSED);
  }
}
