import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Inject,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import {
  AokSvgIconComponent,
  BehaviorState,
  CONTROL_ERROR_CODE,
  CONTROL_ERROR_MESSAGE,
  CONTROL_ERROR_PARAMS,
  FORM_ERRORS,
  FormErrorCode,
  FormErrorDetectorRef,
  FormErrorsMapping,
  isString,
  VALIDATION_ERRORS,
} from '@aok/common';
import { merge, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { FormErrorContainerDirective } from './form-error-container.directive';
import { FormErrorComponent } from './form-error.component';

export type FormControlErrorHandling = 'none' | 'hidden' | 'default';

export const DEFAULT_FORM_ERROR_COMPONENT = new InjectionToken<Type<unknown>>('DEFAULT_FORM_ERROR_COMPONENT', {
  providedIn: 'root',
  factory: /* @dynamic */ () => FormErrorComponent,
});
export const DEFAULT_FORM_ERROR_HANDLING = new InjectionToken<FormControlErrorHandling>('DEFAULT_FORM_ERROR_HANDLINE', {
  providedIn: 'root',
  factory: /* @dynamic */ () => 'default',
});

/**
 * Directive which detects form controls and adds all the error display logic
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlName], [formControl]',
  exportAs: 'formControlError',
})
export class FormControlErrorDirective extends BehaviorState<ValidationErrors> implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('formControlErrorClass') errorClass = 'error';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('formControlErrorHandling') errorHandling: FormControlErrorHandling;
  protected readonly onDestroy = new Subject<void>();
  /**
   * mapping between an error and the message/component to be displayed
   */
  protected readonly errorMapping: FormErrorsMapping;
  protected readonly detectorTicks = this.errorDetectorRef.ticks;
  private _errorIconComponentRef: ComponentRef<AokSvgIconComponent>;
  private _errorComponentRef: ComponentRef<unknown>;
  // complete this list with dropdown, search, etc once we have the components
  private tagsWithIcon = ['input', 'textarea', 'ng-select'];

  constructor(
    public readonly renderer: Renderer2,
    public readonly viewContainer: ViewContainerRef,
    @Inject(DEFAULT_FORM_ERROR_COMPONENT) protected readonly defaultFormErrorComponent: Type<any>,
    @Inject(DEFAULT_FORM_ERROR_HANDLING)
    defaultControlErrorHandling: /* @dynamic */ FormControlErrorHandling,
    @Optional() @Inject(FORM_ERRORS) _rawErrorMapping: /* @dynamic */ FormErrorsMapping,
    @Optional() protected controlContainer: FormErrorContainerDirective,
    protected factoryResolver: ComponentFactoryResolver,
    protected errorDetectorRef: FormErrorDetectorRef,
    protected injector: Injector,
    protected ngControl: NgControl
  ) {
    super();
    this.errorMapping = _rawErrorMapping || {};
    this.errorHandling = defaultControlErrorHandling;
  }

  /**
   * container where the error component will be created
   */
  protected get preferredViewContainer(): ViewContainerRef {
    return this.controlContainer != null ? this.controlContainer.viewContainer : this.viewContainer;
  }

  ngOnInit(): void {
    // update value and validity of the control the directive is applied on
    this.detectorTicks
      .pipe(
        filter((tickType) => tickType === 'detectErrors'),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => this.ngControl.control.updateValueAndValidity());

    // emit the errors on status change if error handling is turned on
    const validationError$ = this.ngControl.statusChanges.pipe(
      takeUntil(this.onDestroy),
      filter((status) => status !== 'PENDING' && this.errorHandling !== 'none'),
      map(() => this.ngControl.errors),
      tap((errors) => this.subject.next(errors))
    );

    // condition when the control has errors
    const erroneousValidation$ = validationError$.pipe(
      filter((errors) => errors != null && Object.keys(errors).length !== 0)
    );
    // condition when the control is valid
    const emptyValidation$ = merge(
      validationError$.pipe(filter((errors) => errors == null)),
      this.detectorTicks.pipe(filter((tickType) => tickType === 'flush'))
    );

    erroneousValidation$.subscribe((errors) => this.onControlError(errors));
    emptyValidation$.subscribe(() => this.onControlValid());
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * setup error related content/styles
   */
  protected onControlError(errors: ValidationErrors): void {
    this.renderer.addClass(this.viewContainer.element.nativeElement, this.errorClass);
    if (this.errorHandling !== 'hidden') {
      const errorCode = this.gerErrorCode(Object.keys(errors));
      if (this._errorComponentRef != null) {
        // check whether we got the same error code here, so we can persist the existing component instance
        const currentErrorCode = this._errorComponentRef.injector.get(CONTROL_ERROR_CODE);
        if (errorCode === currentErrorCode) return;
      }

      this.createErrorComponent(errorCode);
    }
  }

  /**
   * cleanup error related content/styles
   */
  protected onControlValid(): void {
    this.renderer.removeClass(this.viewContainer.element.nativeElement, this.errorClass);
    this.clearErrorComponent();
  }

  protected createErrorComponent(errorCode: FormErrorCode): void {
    // do nothing if there is no error or is already being taken care of
    if (
      !errorCode ||
      (this.controlContainer != null && this.controlContainer.doesHandleControlError(this.ngControl.name, errorCode))
    ) {
      return;
    }

    const errors = this.snapshot;
    const errorParams = errors[errorCode];

    const messageOrComponent = this.errorMapping[errorCode];
    const message = isString(messageOrComponent) ? messageOrComponent : null;

    // either a custom component or the default component where the message variable will be displayed
    const componentType = isString(messageOrComponent)
      ? this.defaultFormErrorComponent
      : messageOrComponent || this.defaultFormErrorComponent;

    this.clearErrorComponent();

    if (this.displayIcon()) {
      this.createIconComponent();
    }

    this._errorComponentRef = this.preferredViewContainer.createComponent<FormErrorsMapping>(componentType, {
      index: null,
      injector: Injector.create({
        parent: this.injector,
        providers: [
          { provide: VALIDATION_ERRORS, useValue: errors },
          { provide: CONTROL_ERROR_MESSAGE, useValue: message },
          { provide: CONTROL_ERROR_PARAMS, useValue: errorParams },
          { provide: CONTROL_ERROR_CODE, useValue: errorCode },
        ],
      }),
    });
    this._errorComponentRef.changeDetectorRef.markForCheck();
    if (this.controlContainer != null)
      this.controlContainer.setControlErrorComponent(this.ngControl.name, this._errorComponentRef);
  }

  protected displayIcon(): boolean {
    return this.tagsWithIcon.includes(this.viewContainer.element.nativeElement?.tagName?.toLowerCase());
  }

  protected createIconComponent(): void {
    const iconFactory = this.factoryResolver.resolveComponentFactory<AokSvgIconComponent>(AokSvgIconComponent);
    this._errorIconComponentRef = this.preferredViewContainer.createComponent<AokSvgIconComponent>(iconFactory);
    this._errorIconComponentRef.instance.name = 'alert-circle';
    this._errorIconComponentRef.instance.size = '16px';
  }

  /**
   * remove the error component
   */
  protected clearErrorComponent(): void {
    if (this._errorComponentRef != null) {
      if (this.controlContainer != null) {
        this.controlContainer.deleteControlErrorComponent(this.ngControl.name);
      }
      this._errorComponentRef.destroy();
      this._errorComponentRef = null;
    }

    if (this._errorIconComponentRef) {
      this._errorIconComponentRef.destroy();
      this._errorIconComponentRef = null;
    }
  }

  protected gerErrorCode(errorCodes: string[]): string {
    // what's to do when more than just one error view matches?!
    // if the control has a date format error, we should first display that, otherwise display first error
    const dateFormatErrorCode = 'matDatepickerParse';
    return errorCodes.includes(dateFormatErrorCode) ? dateFormatErrorCode : errorCodes[0];
  }
}
