<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>
<aok-cockpit-header-page-headline headline="Artikel"></aok-cockpit-header-page-headline>

<ng-container *ngIf="'articles' | aokRouteData | async as articleList; else noArticlesMessage">
  <div class="aok-container body">
    <ng-container *ngIf="articleList._embedded?.items?.length; else noArticlesMessage">
      <aok-article-teaser-list
        [articles]="articleList._embedded?.items"
        [horizontal]="true"
      ></aok-article-teaser-list>

      <aok-pagination
        *ngIf="articleList.page.totalElements > articleList.page.size"
        [activePage]="$any(articleList.page.number)"
        [pageSize]="articleList.page.size"
        [totalItems]="articleList.page.totalElements"
        [totalPages]="articleList.page.totalPages"
        routeQueryParam="page"
      >
      </aok-pagination>
    </ng-container>
  </div>
</ng-container>

<ng-template #noArticlesMessage>
  <p class="disabled">Keine Artikel vorhanden</p>
</ng-template>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
