import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AokSvgIconComponent, DialogOverlay } from '@aok/common';
import {
  AokFooterModule,
  CompletionSupportDialog,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
} from '@aok/components';
import { AppConfig } from '../../../config/app.config';

@Component({
  selector: 'aok-cockpit-handouts',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './handouts.component.html',
  styleUrls: ['./handouts.component.scss'],
  standalone: true,
  imports: [HeaderModule, InfoBannerComponent, AokSvgIconComponent, FeedbackButtonModule, AokFooterModule],
})
export class HandoutsComponent {
  constructor(private dialog: DialogOverlay) {}

  public navigateToHandoutsAokPage(): void {
    window.open(AppConfig.aokHandoutsPage, '_blank');
  }

  public openCompletionSupportDialog(): void {
    this.dialog.create(CompletionSupportDialog, {}).subscribe();
  }
}
