import { AfterContentInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

export type TableCellStatus = 'success' | 'warning' | 'bad' | 'nothing' | 'unclear';

@Component({
  selector: 'aok-table-cell',
  styleUrls: ['table-cell.component.scss'],
  template: `
    <ng-container *ngIf="header; else headerElement">
      <h6 [innerHTML]="header" #headerContent></h6>
    </ng-container>
    <ng-template #headerElement>
      <ng-content select="h6"></ng-content>
    </ng-template>

    <ng-container *ngIf="text && !link; else contentElement">
      <p [innerHTML]="text" [ngClass]="status" #inputContent></p>
    </ng-container>
    <ng-template #contentElement>
      <ng-container *ngIf="!link">
        <ng-content select="p"></ng-content>
      </ng-container>
    </ng-template>

    <div>
      <ng-container *ngIf="icon && link; else iconElement">
        <aok-svg-icon [id]="icon" size="16"></aok-svg-icon>
      </ng-container>
      <ng-template #iconElement>
        <ng-container *ngIf="link">
          <ng-content select="aok-svg-icon"></ng-content>
        </ng-container>
      </ng-template>

      <ng-container *ngIf="link; else linkElement">
        <a [href]="link">{{ text }}</a>
      </ng-container>
      <ng-template #linkElement>
        <ng-content select="a"></ng-content>
      </ng-template>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class AokTableCellComponent implements OnInit, AfterContentInit {
  @Input() icon: string;
  @Input() text: string;
  @Input() link: string;
  @Input() status: TableCellStatus = 'nothing';
  @Input() header: string;
  @ViewChild('inputContent', { static: true }) private _staticContent: ElementRef;
  @ViewChild('inputContent', { static: false }) private _dynamicContent: ElementRef;

  constructor(private ref: ElementRef) {}

  ngOnInit(): void {
    if (this._staticContent) this._staticContent.nativeElement.classList.add(this.ref.nativeElement.classList);
  }

  ngAfterContentInit(): void {
    if (this._dynamicContent) this._dynamicContent.nativeElement.classList.add(this.ref.nativeElement.classList);
  }
}
