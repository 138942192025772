import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AokDialogResult, DialogBase, DialogRef } from '@aok/common';

@Component({
  selector: 'aok-completion-support-dialog',
  templateUrl: './completion-support-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletionSupportDialog implements DialogBase<AokDialogResult> {
  constructor(public readonly dialogRef: DialogRef<AokDialogResult>) {}
}
