import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  AokKvnUserRegistration,
  AokOrgClient,
  AokSpecializationState,
  AokToastService,
  AokUserClient,
  AokUserRegistrationClient,
  createKVNRegistrationRequestForm,
  DialogBase,
  DialogRef,
  FORM_ERRORS,
  FormErrorDetectorRef,
} from '@aok/common';
import { EMPTY, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, filter, first, map, takeUntil } from 'rxjs/operators';
import { AokInputFieldComponent } from '../../../cdk';
import { DEFAULT_ERRORS } from '../../../form-error-message/default-errors';
import { AokStepComponent } from '../../../stepper/step.component';
import { AokStepperComponent, StepChangeEvent } from '../../../stepper/stepper.component';
import { AokBaseRegistrationDialog } from '../base-registration.component';

@Component({
  selector: 'aok-kvn-registration-dialog',
  styleUrls: ['./kvn-registration-dialog.component.scss'],
  templateUrl: './kvn-registration-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: FORM_ERRORS,
      useValue: {
        ...DEFAULT_ERRORS,
        ...{
          equalControl: 'Die E-Mail-Adressen stimmen nicht überein.',
        },
      },
    },
  ],
})
export class AokRegistrationKVNDialog
  extends AokBaseRegistrationDialog
  implements OnInit, AfterViewInit, OnDestroy, DialogBase<Partial<AokKvnUserRegistration>>
{
  @Input() formValue: AokKvnUserRegistration;
  @Input() isRegisteredDoctor: boolean;

  @ViewChild(AokStepperComponent) stepper: AokStepperComponent;
  @ViewChildren('userData') userDataFields: QueryList<AokInputFieldComponent>;
  @ViewChildren('orgData') orgDataFields: QueryList<AokInputFieldComponent>;
  @ViewChildren('practiceDataStep') practiceDataSteps: QueryList<AokStepComponent>;

  protected readonly destroys = new Subject<void>();

  constructor(
    orgClient: AokOrgClient,
    dialogRef: DialogRef<Partial<AokKvnUserRegistration>>,
    specializationState: AokSpecializationState,
    cd: ChangeDetectorRef,
    private userRegistrationClient: AokUserRegistrationClient,
    private errorDetectorRef: FormErrorDetectorRef,
    private toastService: AokToastService,
    private userClient: AokUserClient
  ) {
    super(dialogRef, specializationState, cd, orgClient);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.formGroup = createKVNRegistrationRequestForm(
      this.specializationOptions,
      this.userClient,
      this.formValue,
      this.isRegisteredDoctor
    );

    this.formGroup
      .get('userData')
      .statusChanges.pipe(takeUntil(this.destroys))
      .subscribe(() => {
        this.validateUserData(true);
      });

    this.getOrgControls().forEach((orgControl, index) => {
      orgControl.valueChanges.pipe(takeUntil(this.destroys)).subscribe(() => this.validateOrgData(true, index));
    });

    this.setupLanrSpecializationHandler();
  }

  public ngOnDestroy(): void {
    this.destroys.next();
    this.destroys.complete();
  }

  public ngAfterViewInit(): void {
    this.stepper.stepChange
      .pipe(
        switchMap((event) => {
          if (event.newStep.index <= event.currentStep.index) {
            return of(event);
          }

          if (event.currentStep.index === 0) {
            return this.validateUserData(false).pipe(map((valid) => (valid ? event : null)));
          }

          if (event.currentStep.index > 0 && this.validateOrgData(false, event.currentStep.index - 1)) {
            return of(event);
          }

          return of(null);
        }),
        filter((stepChangeEvent) => !!stepChangeEvent)
      )
      .subscribe((event: StepChangeEvent) => {
        this.changeStep(event.newStep);
      });

    this.stepper.submitted.subscribe(() => this.submit());
  }

  public getOrgControls(): FormGroup[] {
    return (this.formGroup.get('organizationDataList') as FormArray)?.controls as FormGroup[];
  }

  private validateUserData(byValueChanges?: boolean): Observable<boolean> {
    if (!byValueChanges) {
      this.formGroup.updateValueAndValidity();
      this.errorDetectorRef.detectErrors();
    }

    if (!this.formGroup.get('userData').pending) {
      this.bannerLinks = this.getElementLinksFromInvalids(
        this.userDataFields,
        this.formGroup.get('userData') as FormGroup
      );
      this.cd.markForCheck();

      return of(this.validate(this.bannerLinks, byValueChanges));
    }

    return this.formGroup.get('userData').statusChanges.pipe(
      filter((status) => status !== 'PENDING'),
      first(),
      map(() => {
        this.bannerLinks = this.getElementLinksFromInvalids(
          this.userDataFields,
          this.formGroup.get('userData') as FormGroup
        );
        this.cd.markForCheck();

        return this.validate(this.bannerLinks, byValueChanges);
      })
    );
  }

  private validateOrgData(byValueChanges = false, index?: number): boolean {
    const currentIndex: number = this.stepper.indexState.snapshot - 1;
    if (currentIndex !== index) {
      return;
    }

    if (!byValueChanges) {
      this.errorDetectorRef.detectErrors();
    }

    this.bannerLinks = this.getElementLinksFromInvalids(
      this.orgDataFields,
      (this.formGroup.get('organizationDataList') as FormArray).at(currentIndex) as FormGroup
    );
    return this.validate(this.bannerLinks, byValueChanges);
  }

  private submit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.isErrorBannerEnabled = false;
    const registration = this.buildRegistration();

    this.userRegistrationClient
      .createKvnRegistration(registration as AokKvnUserRegistration)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.handleRequestErrors(error);
          return EMPTY;
        })
      )
      .subscribe((registration) => {
        this.dialogRef.dispose(registration);
        if (this.isRegisteredDoctor) {
          window.location.replace('publik/vollregistriert');
        } else {
          window.location.reload();
        }
      });
  }

  private handleRequestErrors(error: HttpErrorResponse): void {
    if (error.status === 400 && error.error?.[0]?.field === 'email') {
      this.toastService.createErrorToast(
        'E-Mail-Adresse bereits vorhanden',
        'Bitte verwenden sie eine andere E-Mail-Adresse'
      );
    } else {
      this.toastService.createErrorToast(
        'Technischer Fehler',
        'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
      );
    }
  }
}
