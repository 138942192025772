import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AokReminder, SVG_ICON_SIZE } from '@aok/common';

@Component({
  selector: 'aok-reminder-teaser',
  styleUrls: ['./reminder-teaser.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './reminder-teaser.component.html',
  providers: [{ provide: SVG_ICON_SIZE, useValue: 96 }],
})
export class AokReminderTeaserComponent {
  @Input()
  public reminder: AokReminder;

  @Output()
  actionLinkClicked: EventEmitter<AokReminder> = new EventEmitter<AokReminder>();

  public openLink(): void {
    this.actionLinkClicked.emit(this.reminder);
  }
}
