import { ActivatedRouteSnapshot } from '@angular/router';
import { ContextState } from '../states';

export const getParsableQueryParams = (queryString?: string): Record<string, string> => {
  const searchParams = new URLSearchParams(queryString || window.location.search);
  const queryParams: Record<string, string> = {};

  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
};

export const getContextSpecificUrl = (url: string, contextState: ContextState): string => {
  const { practitionerLanr: lanr, bsnr } = contextState;
  return `${url}?lanr=${lanr}&bsnr=${bsnr}`;
};

export const getKvnStateUrl = (url: string, routeSnapshot: ActivatedRouteSnapshot, isKvn: boolean): string => {
  const hasBa = routeSnapshot.queryParams?.ba === '22';
  return isKvn && hasBa ? `${url}&ba=22` : url;
};
