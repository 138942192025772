import { ActivatedRouteSnapshot } from '@angular/router';

/**
 * return full url for the activated route
 */
export const getFullPathRouteCommands = (activatedRoute: ActivatedRouteSnapshot): string[] => {
  return activatedRoute.pathFromRoot
    .map((r) => r.url)
    .filter((f) => !!f[0])
    .map(([f]) => f.path);
};
