import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AokReminderListOptions, AokReminderListResponse } from '../schemas';

import { AokApiBaseClient } from './api-base.client';

export const REMINDER_API_URL = new InjectionToken<string>('REMINDER_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/notification/reminders',
});

@Injectable({
  providedIn: 'root',
})
export class AokReminderClient extends AokApiBaseClient {
  constructor(@Inject(REMINDER_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  listDashboardReminders(reminderOptionsList?: AokReminderListOptions): Observable<AokReminderListResponse> {
    const params = new HttpParams({
      fromObject: { ...reminderOptionsList },
    });
    return this.http.get<AokReminderListResponse>(this.resolveUrl('me/dashboard'), { params });
  }

  list(): Observable<AokReminderListResponse> {
    return this.http.get<AokReminderListResponse>(this.resolveUrl('me'));
  }
}
