import { A11yModule } from '@angular/cdk/a11y';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokInputFieldModule, RequiredMarkModule } from '../../cdk';
import { AokCheckboxModule } from '../../checkbox/checkbox.module';
import { FormControlErrorModule } from '../../form-control-error/module';
import { AokRequiredErrorMessageModule } from '../../form-error-message/required-error-message/required-error-message.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { AokConsentDialog } from './consent-dialog.component';

@NgModule({
  declarations: [AokConsentDialog],
  exports: [AokConsentDialog],
  imports: [
    AokCheckboxModule,
    AokInputFieldModule,
    ReactiveFormsModule,
    FormsModule,
    FormControlErrorModule,
    AokRequiredErrorMessageModule,
    RequiredMarkModule,
    A11yModule,
    A11yUtilsModule,
    AokSvgIconComponent,
    AokDialogLayoutModule,
  ],
})
export class AokConsentDialogModule {
  static forRoot(): ModuleWithProviders<AokConsentDialogModule> {
    return {
      ngModule: AokConsentDialogModule,
      // providers: [AOK_CONSENT_CHECK_LISTENER],
    };
  }
}
