import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { EnvironmentSchema } from '../schemas';
import { AokCookieUtils } from '../services';
import { getDomain, getParentDomain } from './browser.util';

export const redirectToTI = (environment: EnvironmentSchema): void => {
  if (environment.checkTI && isTIAccessible() && !isTINetwork(environment)) {
    window.location.href = window.location.href?.replace(
      getDomain(window.location.href),
      getDomain(environment.tiConfig.baseUrl)
    );
  }
};

export const isTIAccessible = (): boolean => {
  return AokCookieUtils.get('AOK_TI_ACCESSIBLE') === 'true';
};

export const isTINetwork = (environment: EnvironmentSchema): boolean => {
  return window.location.href.includes(environment.tiConfig.baseUrl);
};

export const shouldCheckTI = (environment: EnvironmentSchema): boolean => {
  if (isTINetwork(environment)) {
    if (!isTIAccessible()) {
      setTICookie(true);
    }
    return false;
  }
  return environment.checkTI && !isTIAccessible();
};

export const setTICookie = (value: boolean): void => {
  const domain = getParentDomain();

  document.cookie = `AOK_TI_ACCESSIBLE=${value};path=/;domain=${domain}`;
};

/**
 * Checks the connection to the TI server.
 *
 * used to check if the service at tiBaseUrl is accessible within a 4 seconds timeframe.
 * The result is saved in a cookie through setTICookie function and emitted to subscribers of this
 * function's return Observable.
 *
 * @param {HttpClient} httpClient - The HttpClient instance to make the request.
 * @param {string} tiBaseUrl - The base URL of the TI server.
 * @returns {Observable<boolean>} - An Observable that emits a boolean value indicating the connection status.
 */
export const checkTIConnection = (httpClient: HttpClient, tiBaseUrl: string): Observable<boolean> => {
  const headers = new HttpHeaders().set('Cache-Control', 'no-cache, no-store, must-revalidate').set('Expires', '0');
  return httpClient
    .head(tiBaseUrl, {
      observe: 'response',
      headers,
    })
    .pipe(
      timeout(4000),
      map(() => {
        setTICookie(true);
        return true;
      }),
      catchError(() => {
        setTICookie(false);
        return of(false);
      })
    );
};
