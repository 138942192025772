import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AokUserClient } from '@aok/common';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const isCardOrderingEnabledResolver: ResolveFn<boolean> = () => {
  const userClient = inject(AokUserClient);

  return userClient.isCardOrderingEnabled().pipe(catchError(() => of(false)));
};
