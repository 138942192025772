import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AokApiBaseClient } from './api-base.client';

export const AOK_ARTICLE_API_URL = new InjectionToken<string>('AOK_ARTICLE_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/articles',
});

@Injectable()
export abstract class AokArticleClientBase extends AokApiBaseClient {
  constructor(@Inject(AOK_ARTICLE_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }
}
