import { lastValueFrom } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { AokCookieService } from '../../../services';
import { KvnState } from '../../../states';

export function createCookieBootstrapListener(cookieConsent: AokCookieService, kvnState: KvnState): () => void {
  return () => {
    // statically (publicly) accessible routes do not constrain to the cookie consent check, since
    // the check is waiting for the keycloak module to emit a ready event, which doesn't happen on
    // "external" pages
    lastValueFrom(
      kvnState.getKvnInfo$().pipe(
        first(),
        tap((kvnInfo) => {
          if (kvnInfo.isKvnWithoutAccess) {
            return;
          }

          cookieConsent.check();
        })
      )
    );
  };
}
