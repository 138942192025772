<div class="aok-datepicker-header">
  <aok-svg-icon
    name="arrow-left"
    size="16px"
    aokFocusable
    [class.disabled]="!previousEnabled()"
    (click)="previousClicked()"
    (keyup.enter)="previousClicked()"
    [attr.aria-label]="prevButtonLabel"
  ></aok-svg-icon>
  <span
    aokFocusable
    class="aok-text-l1 aok-bold"
    [class.light]="!isMonthView"
    [attr.aria-label]="periodButtonLabel"
    (click)="currentPeriodClicked()"
    (keyup.enter)="currentPeriodClicked()"
  >
    {{ periodButtonText.toLocaleLowerCase() }}
  </span>
  <aok-svg-icon
    name="arrow-right"
    size="16px"
    aokFocusable
    [class.disabled]="!nextEnabled()"
    (click)="nextClicked()"
    (keyup.enter)="nextClicked()"
    [attr.aria-label]="nextButtonLabel"
  ></aok-svg-icon>
</div>
