<ng-container *ngFor="let link of links.asObservable() | async">
  <a
    aokFocusable
    class="a11y-link aok-link-1"
    target="_self"
    routerLinkActive="active"
    [routerLinkOrHref]="resolveLinkUrl(link)"
  >
    <aok-svg-icon [name]="link.icon" [title]="link.alt" [size]="20"></aok-svg-icon>
  </a>
</ng-container>
