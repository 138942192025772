import { registerLocaleData } from '@angular/common';
import localeDataDe from '@angular/common/locales/de';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { redirectToTI } from '@aok/common';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

redirectToTI(environment);

registerLocaleData(localeDataDe, 'de');

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
