<div class="tw-flex tw-gap-7">
  <div class="teaser-image">
    <div *ngIf="!reminder.teaserThumbnail">
      <aok-svg-icon id="0393-calendar_31" [size]="'24px'"></aok-svg-icon>
    </div>
    <img *ngIf="reminder.teaserThumbnail" [src]="reminder.teaserThumbnail" [alt]="reminder.altTextTeaserThumbnail" />
  </div>
  <div class="reminder-info tw-grow">
    <div class="context">{{ contextInfo }}</div>
    <div class="tw-flex tw-justify-between tw-items-center">
      <a
        [class]="isCollapsed ? 'aok-link-1' : 'aok-text-p1 aok-bold'"
        [ngClass]="{ 'collapsed-reminder': isCollapsed }"
        class="padded-content reminder-title"
        (click)="toggleCollapse()"
        aokFocusable
        (keyup.enter)="toggleCollapse()"
      >
        {{ reminder.title }}
      </a>
      <aok-svg-icon
        [name]="isCollapsed ? 'arrow-down' : 'arrow-up'"
        class="collapse-toggle icon-button"
        size="16px"
        (click)="toggleCollapse()"
        aokFocusable
        (keyup.enter)="toggleCollapse()"
      ></aok-svg-icon>
    </div>
    <div
      class="reminder-content aok-text-p2 padded-content"
      [ngClass]="{ 'collapsed-reminder': isCollapsed }"
      [innerHtml]="reminder.content"
    ></div>
    <button
      *ngIf="hasAction()"
      class="secondary tw-mt-7"
      (click)="openLink()"
      (keyup.enter)="openLink()"
    >
      {{ reminder.actionButtonLabel }}
      <aok-svg-icon name="arrow-rounded_right" size="16px"></aok-svg-icon>
    </button>
  </div>
</div>
