import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { AokReportPageData } from '@aok/common';
import { KvnState } from '@aok/components';
import { first, mergeMap, of } from 'rxjs';
import { ReportsService } from '../services/reports.service';
import { ReportsState } from '../states/reports.state';

export const reportsResolver: ResolveFn<AokReportPageData> = (route: ActivatedRouteSnapshot) => {
  const reportsService = inject(ReportsService);
  const router = inject(Router);
  const kvnState = inject(KvnState);

  const category = route.params.category;
  const { page = 0, size, sort = '', query = '' } = route.queryParams;
  const pageParams = { page, size, sort, query };
  const reportsState = inject(ReportsState).snapshot;

  return kvnState.getKvnInfo$().pipe(
    first(),
    mergeMap((kvnInfo) => {
      if (kvnInfo.isKvnPracticeTeam) {
        router.navigate(['berichte', 'uebersicht']);
        return of(null);
      }

      if (reportsService.isAnyCategoryEnabled()) {
        return reportsState
          ? reportsService.handleReportsRetrievedBefore(category, pageParams, route)
          : reportsService.handleNoReportsRetrievedBefore(category, pageParams, route);
      }

      router.navigate(['berichte', 'uebersicht']);
    })
  );
};
