export interface FeedbackRequestSchema {
  category: string;
  feedback: string;
  consent: boolean;
  bsnr: string;
  lanr: string;
}

export enum FeedbackCategory {
  ALLGEMEIN = 'Allgemein',
  BERICHTE = 'Berichte',
  ABRECHNUNGSSCHEIN = 'Abrechnungsschein',
  ARTIKEL = 'Artikel',
  HILFE = 'Hilfe',
  PROFILVERWALTUNG = 'Profilverwaltung',
  PODCASTS = 'Podcasts',
  FORM_COMPLETION_SUPPORT = 'Handouts und Ausfüllhilfen',
  DMP_FALLUBERSICHT = 'DMP-Fallübersicht',
  SAMMELDRUCK = 'Sammeldruck',
  VERSICHERTENMERKMAL = 'Versichertenmerkmal',
  LEISTUNGSSUCHE = 'Leistungssuche',
}
export enum SVSFeedbackCategory {
  HZV = 'HzV',
  MODULE = 'Module (AMT, Neuropad,...)',
  PATIENTENVERWALTUNG = 'Patientenverwaltung',
  QUARTALSABSCHLUSS = 'Quartalsabschluss',
  VERTRAGE = 'Verträge',
}
