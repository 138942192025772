import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule } from '@aok/common';
import { AokNotificationBannerModule } from '../../notification-banner/notification-banner.module';
import { PracticeFormComponent } from '../../practice-form/practice-form.component';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { AokAddBSNRAsDoctorDialog } from './add-bsnr-as-doctor-dialog.component';
import { AppAddBSNRDialogDirective } from './add-bsnr-as-doctor-dialog.directive';

@NgModule({
  declarations: [AokAddBSNRAsDoctorDialog, AppAddBSNRDialogDirective],
  exports: [AokAddBSNRAsDoctorDialog, AppAddBSNRDialogDirective],
  imports: [
    CommonModule,
    AokDialogLayoutModule,
    A11yModule,
    A11yUtilsModule,
    PracticeFormComponent,
    AokNotificationBannerModule,
  ],
})
export class AokCockpitAddBSNRDialogModule {}
