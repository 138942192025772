import { AokPage } from '../schemas';

export const DEFAULT_PAGE_RESPONSE: AokPage<never> = {
  page: {
    totalElements: 0,
    size: 0,
    totalPages: 0,
    number: 0,
  },
  _embedded: { items: [] },
};
