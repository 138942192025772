import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[aokFocusable]',
})
export class AokFocusableDirective implements OnInit, OnChanges {
  private enabled = true;

  @Input() set aokFocusable(value: unknown) {
    this.enabled = coerceBooleanProperty(value);
  }

  constructor(private el: ElementRef, private rend: Renderer2) {}

  ngOnInit(): void {
    // check if element is disabled
    if (!this.el.nativeElement.attributes.disabled && this.enabled) {
      this.addFocusProperties();
    }
  }

  ngOnChanges(): void {
    if (!this.el.nativeElement.attributes.disabled && this.enabled) {
      this.addFocusProperties();
    } else {
      this.removeFocusProperties();
    }
  }

  private addFocusProperties(): void {
    this.rend.setAttribute(this.el.nativeElement, 'tabindex', '0');
    this.rend.setStyle(this.el.nativeElement, 'cursor', 'pointer');
  }

  private removeFocusProperties(): void {
    this.rend.removeAttribute(this.el.nativeElement, 'tabindex');
    this.rend.removeStyle(this.el.nativeElement, 'cursor');
  }
}
