import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export function getInvalidFormElements(element: AbstractControl): UntypedFormControl[] {
  const invalidElements: UntypedFormControl[] = [];
  // AbstractControl is either FormArray, FormGroup or FormControl
  if (element instanceof UntypedFormGroup || element instanceof UntypedFormArray) {
    Object.values(element.controls).forEach((value: AbstractControl) => {
      invalidElements.push(...getInvalidFormElements(value));
    });
  } else if (element.errors) {
    invalidElements.push(element as UntypedFormControl);
  }
  return invalidElements;
}

export function getNumberErrorsFromForm(element: AbstractControl, multiErrorPerElement = false): number {
  let errors = 0;
  // AbstractControl is either FormArray, FormGroup or FormControl
  if (element instanceof UntypedFormGroup || element instanceof UntypedFormArray) {
    Object.values(element.controls).forEach((value: AbstractControl) => {
      errors += getNumberErrorsFromForm(value);
    });
  } else {
    // note: the FormControl can have more than one error but for validation we only count one error
    errors += element.errors ? (multiErrorPerElement ? element.errors.length : 1) : 0;
  }
  return errors;
}

export function resetErrors(formElement: AbstractControl): void {
  if (formElement instanceof UntypedFormGroup || formElement instanceof UntypedFormArray) {
    Object.keys(formElement.controls).forEach((key) => {
      resetErrors(formElement.get(key));
    });
  } else {
    formElement.setErrors(null);
  }
}
