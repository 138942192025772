import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  A11yUtilsModule,
  AokBenefitCategory,
  AokBenefitRegion,
  AokSearchClient,
  AokSvgIconComponent,
  ContentType,
  DropdownSchema,
} from '@aok/common';
import {
  AokDropdownModule,
  AokInputFieldModule,
  DropdownMode,
  FilterAreaComponent,
  FilterButtonComponent,
  SearchBarModule,
} from '@aok/components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aok-cockpit-benefit-search',
  standalone: true,
  imports: [
    CommonModule,
    AokSvgIconComponent,
    SearchBarModule,
    A11yUtilsModule,
    FilterButtonComponent,
    FilterAreaComponent,
    AokInputFieldModule,
    AokDropdownModule,
  ],
  templateUrl: './benefit-search.component.html',
  styleUrls: ['./benefit-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitSearchComponent implements OnInit, OnDestroy {
  @Input() totalElements: number;
  @Input() searchActive: boolean;
  public searchSuggestions: string[] = [];
  public pluralMapping: { [k: string]: string } = { '=1': '# Leistung', other: '# Leistungen' };
  public categoryOptions: DropdownSchema[] = [];
  public regionOptions: DropdownSchema[] = [];
  public isOpenFilterArea = false;
  public filterCounter = 0;
  public dropdownMode = DropdownMode.SIMPLE;
  public filterForm: FormGroup;
  private ngDestroyed = new Subject<void>();

  @Input() set categories(value: AokBenefitCategory[]) {
    this.categoryOptions = [
      { label: 'Alle Kategorien', value: '' },
      ...value.map((category) => ({ label: category.categoryName, value: category.categoryName })),
    ];
  }

  @Input() set regions(value: AokBenefitRegion[]) {
    this.regionOptions = [
      { label: 'Alle Regionen', value: '' },
      ...value.map((region) => ({ label: region.regionName, value: region.regionName })),
    ];
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private searchClient: AokSearchClient
  ) {
    this.filterForm = new FormGroup({
      category: new FormControl<string>(''),
      region: new FormControl<string>(''),
    });
  }

  ngOnInit(): void {
    this.subscribeToRouteParams();
    this.subscribeToFilterFormChanges();
  }

  ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  public handleSearchAndFilter(queryParams: Params): void {
    this.router.navigate(['.'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
  }

  public retrieveSearchSuggestions(query: string): void {
    this.searchClient
      .listSuggestions({ query, documentType: ContentType.BENEFIT, enableHighlights: false })
      .subscribe((suggestions) => {
        this.searchSuggestions = suggestions;
        this.cd.detectChanges();
      });
  }

  private subscribeToRouteParams(): void {
    this.route.queryParams.pipe(takeUntil(this.ngDestroyed)).subscribe((params) => {
      const { category, region } = params;
      this.filterForm.patchValue(
        {
          category: category || this.categoryOptions[0].value,
          region: region || this.regionOptions[0].value,
        },
        { emitEvent: false }
      );

      this.updateFilterCounter();
    });
  }

  private subscribeToFilterFormChanges(): void {
    this.filterForm.valueChanges.pipe(takeUntil(this.ngDestroyed)).subscribe(({ category, region }) => {
      this.handleSearchAndFilter({ category, region, page: 0 });
    });
  }

  private updateFilterCounter(): void {
    this.filterCounter = Object.values(this.filterForm.controls).reduce(
      (count, control) => (control.value ? count + 1 : count),
      0
    );
  }
}
