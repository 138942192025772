export const AppConfig = {
  notificationPoll: 10 * 60 * 1000, // 10 minutes in millis
  aokHandoutsPage:
    'https://www.aok.de/gp/aerzte-psychotherapeuten/informationen-zu-arznei-und-heilmitteln-zum-herunterladen?cid=nied_Arztportal',
  aokPodcastsLink: 'https://aok-praxis-talk.podigee.io/',
  tiCheckInterval: 30_000, // 30s
};

export const LOGOUT_AFTER_MS = 6_000; // 6s

export const oldSafariVersion = '16.4.1';
