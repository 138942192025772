import { InjectionToken } from '@angular/core';
import { BuiltInFormErrorCode, FormErrorsMapping } from './form-errors';
import { ValidationErrors } from '@angular/forms';

/**
 * Should be used to provide messages or components to be displayed for different errors.
 * Can be provided at component level.
 * Should be of the form {@link FormErrorsMapping}
 */
export const FORM_ERRORS = new InjectionToken<FormErrorsMapping>('FORM_ERRORS');

// injectable into the error component:
export const VALIDATION_ERRORS = new InjectionToken<ValidationErrors>('VALIDATION_ERRORS');
export const CONTROL_ERROR_CODE = new InjectionToken<BuiltInFormErrorCode | string>('CONTROL_ERROR_CODE');
export const CONTROL_ERROR_PARAMS = new InjectionToken<any>('CONTROL_ERROR_PARAMS');
export const CONTROL_ERROR_MESSAGE = new InjectionToken<string>('CONTROL_ERROR_MESSAGE');
