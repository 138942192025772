import { AokMfaToBSNRRegistration, AokUser, AokUserRegistration, isAssistant, isDoctor } from '@aok/common';
import { ProfileAssistant, UserByOrg } from '../schema';

function sortAokUser(accu: UserByOrg, user: AokUser): UserByOrg {
  if (isDoctor(user)) {
    (user.practitionerRequest || user.practitionerResource).organizationsIds.forEach((orgId) =>
      addsToObject(accu, user, orgId, 'doctors')
    );
  } else if (isAssistant(user)) {
    (user.practitionerRequest || user.practitionerResource).organizationsIds.forEach((orgId) =>
      addsToObject(accu, user, orgId, 'assistants')
    );
  }
  return accu;
}

function addsToObject(accu: UserByOrg, user: AokUser, orgId: number, type: 'assistants' | 'doctors'): UserByOrg {
  if (accu?.[orgId]?.[type]) {
    accu[orgId][type].push(user);
  } else {
    accu[orgId] = type === 'assistants' ? { doctors: [], assistants: [user] } : { doctors: [user], assistants: [] };
  }
  return accu;
}

function sortRegistrationUser(accu: UserByOrg, user: AokUserRegistration | AokMfaToBSNRRegistration): UserByOrg {
  if (accu?.[user.organizationData?.organizationId]) {
    accu?.[user.organizationData?.organizationId].assistants.push(user);
  } else {
    accu[user.organizationData?.organizationId] = { doctors: [], assistants: [user] };
  }
  return accu;
}

function isUser(user: ProfileAssistant): boolean {
  return 'practitionerRequest' in user || 'practitionerResource' in user;
}

function sortString(first: string, second: string): number {
  if (first > second) return 1;
  else if (first < second) return -1;
  else return 0;
}

export function sortEmployees(employeeA: ProfileAssistant, employeeB: ProfileAssistant): number {
  if (isUser(employeeA) && isUser(employeeB)) {
    const a = employeeA as AokUser;
    const b = employeeB as AokUser;
    return sortString(a.lastName + a.firstName, b.lastName + b.firstName);
  } else if (isUser(employeeA) && !isUser(employeeB)) {
    const a = employeeA as AokUser;
    const b = employeeB as AokUserRegistration;
    return sortString(a.lastName + a.firstName, b.userData.lastName + b.userData.firstName);
  } else if (!isUser(employeeA) && isUser(employeeB)) {
    const a = employeeA as AokUserRegistration;
    const b = employeeB as AokUser;
    return sortString(a.userData.lastName + a.userData.firstName, b.lastName + b.firstName);
  } else {
    const a = employeeA as AokUserRegistration;
    const b = employeeB as AokUserRegistration;
    return sortString(a.userData.lastName + a.userData.firstName, b.userData.lastName + b.userData.firstName);
  }
}

export function splitEmployees(accu: UserByOrg, user: ProfileAssistant): UserByOrg {
  if (isUser(user)) {
    return sortAokUser(accu, user as AokUser);
  } else {
    return sortRegistrationUser(accu, user as AokUserRegistration);
  }
}
