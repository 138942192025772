import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PrimitiveBehaviorState } from '../../utils';

@Injectable({ providedIn: 'root' })
export class AokLoadingState extends PrimitiveBehaviorState<boolean> {
  constructor() {
    super();
  }

  readonly starts: Observable<boolean> = this.asObservable().pipe(filter((b) => b as true));
  readonly stops: Observable<boolean> = this.asObservable().pipe(filter((b) => !b as false));

  /** Starts loading by setting the state to `true` */
  start(): void {
    this.patch(true);
  }

  /** Stops loading by setting the state to `false` */
  stop(): void {
    this.patch(false);
  }
}
