import { ContentChildren, Directive, OnDestroy, QueryList, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationEntry } from './navigation-entry.schema';
import { NavigationEntryDefDirective, NavigationEntryDefContext } from './navigation-entry-def.directive';

@Directive()
export abstract class NavigationEntryContainer implements OnDestroy {
  protected readonly ngDestroys = new Subject<void>();

  @ContentChildren(NavigationEntryDefDirective, { descendants: true })
  protected readonly contentEntryDefs: QueryList<NavigationEntryDefDirective>;

  get defaultEntryTemplate(): TemplateRef<NavigationEntryDefContext> | undefined {
    return this.contentEntryDefs?.find((entryDef) => entryDef.when == null)?.template;
  }

  resolveEntryTemplate(entry: NavigationEntry): TemplateRef<NavigationEntryDefContext> | undefined {
    return this.contentEntryDefs?.find((entryDef) => entryDef.when?.(entry))?.template || this.defaultEntryTemplate;
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }
}
