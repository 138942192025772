import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AokUserClient } from '../../clients';

export function isEmailInUse(userClient: AokUserClient): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return userClient.isEmailInUse(control.value).pipe(map((inUse) => (inUse ? { existMail: true } : null)));
  };
}
