import { AddressData } from '../contact';
import { AokPageQueryParams } from '../page.schema';
import { AokPractitionerSpecializationEntry, DoctorType, KnownUserType } from './user.schema';

export interface AokMfaToBSNRRegistration {
  readonly id: number;
  readonly createdDate: any;
  lanr: string;
  organizationData: {
    organizationId: number;
    organizationName: string;
    organizationType: string;
    bsnr: string;
    email: string; // fixme optional
    address: AddressData;
  };
  status: AokUserRegistrationStatus; // fixme use bsnr registration status
  // fixme urgency
  userData: {
    authOption: string;
    email: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    salutation: string;
    title: string;
    userId: number;
    userType: string;
    // fixme keycloakId
  };
}

export interface AokUserRegistration {
  readonly id?: number;
  readonly bsnrMessage?: string;
  readonly lanrMessage?: string;
  readonly lastModifiedByEmployee: string;
  readonly lastModifiedByEmployeeDate: number;
  readonly approvedBy: string;
  readonly approvalDate: number;
  userData: AokRegistrationUserData;
  organizationData: AokRegistrationOrgData;
  registrationStatus: AokUserRegistrationStatus;
  registrationUrgency: AokRegistrationUrgency;
  lockedBy: string; // keycloak Id
  lockedUntil: number; // timestamp
  pinExpiryDate?: string;
  createdDate: number;
  userType?: KnownUserType;
}

export interface AokKvnUserRegistration {
  readonly id?: number;
  userData: AokRegistrationUserData;
  organizationDataList: AokRegistrationOrgData[];
}

export interface AokAssistantRegistrationRequest {
  userData: AokRegistrationUserData;
  organizationData: Partial<AokRegistrationOrgData>;
}

export interface AokRegistrationListOptions extends AokPageQueryParams {
  id?: Array<string | number> | string | number;
  userType?: KnownUserType[] | KnownUserType;
  email?: string[] | string;
  lanr?: string[] | string;
  bsnr?: string[] | string;
  keycloakId?: string;
}

// TODO update this to only hold valid values
export enum AokUserRegistrationStatus {
  Pending = 'FOR_REVIEW',
  Approved = 'PROCESSING_COMPLETE',
  Rejected = 'REJECTED',
  PinExpired = 'PIN_EXPIRED',
  PinMarked = 'PIN_MARKED_FOR_LETTER_DISPATCH',
  PinInLetterDispatch = 'PIN_IN_LETTER_DISPATCH',
  FailedExport = 'FAILED_EXPORT',
  CardCreation = 'FOR_CARD_CREATION',
  Invited = 'INVITED',
  RequestReceived = 'REQUEST_RECEIVED',
  InternalReview = 'INTERNAL_REVIEW',
}

export enum AokRegistrationUrgency {
  HIGH = 'HIGH_URGENCY', //'hohe Dringlichkeit',
  NORMAL = 'NORMAL_URGENCY', //'normale Dringlichkeit',
  LOW = 'LOW_URGENCY', //'niedrige Dringlichkeit',
  NONE = 'NO_URGENCY', //'keine Dringlichkeit'
}

export enum UrgencyTooltipMessages {
  HIGH_URGENCY = 'hohe Dringlichkeit',
  NORMAL_URGENCY = 'normale Dringlichkeit',
  LOW_URGENCY = 'niedrige Dringlichkeit',
  NO_URGENCY = 'keine Dringlichkeit',
}

export enum AokChangeableUserRegistrationStatus {
  PIN_MARKED_FOR_LETTER_DISPATCH = 'PIN_MARKED_FOR_LETTER_DISPATCH',
  PIN_IN_LETTER_DISPATCH = 'PIN_IN_LETTER_DISPATCH',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum RegistrationRequestOption {
  OPEN = 'OPEN',
  SHOW_PIN = 'SHOW_PIN',
  GENERATE_PIN = 'GENERATE_PIN',
  EXPIRED_PIN = 'EXPIRED_PIN',
  SENT_MANUALLY = 'SENT_MANUALLY',
  GENERATE_CARD_KEY = 'GENERATE_CARD_KEY',
  OPEN_REGISTRATION_HISTORY = 'OPEN_REGISTRATION_HISTORY',
}

// used for BSNR registration & regular registration
export interface AokRegistrationUserData {
  lanr?: string;
  firstName: string;
  lastName: string;
  title?: string;
  salutation: string;
  specialization?: AokPractitionerSpecializationEntry;
  mobilePhone?: string;
  email: string;
  authOption?: AuthOption | string;
  countryCode?: string;
  secondFactorPhoneNumber?: string;
  userType: KnownUserType;
  doctorType?: DoctorType;
  keycloakId?: string;
  kvnPracticeAccountControl?: boolean; // not on bsnr-registration list
}

// TODO check if this is used for more than Bsnr registration
export interface AokRegistrationOrgData {
  bsnr: string;
  organizationType: string;
  organizationName: string;
  organizationId?: number; // fixme optional on doctor bsnr request
  address: AddressData;
  email?: string; // fixme optional
  initiatedFromBsnr?: string; // fixme not on bsnr-registration list - only used on request?
}

// TODO create base and extend with Doctor & PM
export interface AokRegistrationOrgAsMfaDataRequest {
  bsnr: string;
  lanr: string;
  specialization: string;
  initiatedFromBsnr: string;
}

export interface AokRegistrationToOrganisation {
  createdDate: number;
  id: number;
  lanr: string; // fixme missing from the http response
  organizationData: AokRegistrationOrgData;
  status: AokUserRegistrationStatus; //fixme different type
  // fixme urgency, lastModifiedByEmployee
  userData: {
    authOption: string;
    email: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    salutation: string;
    title: string;
    userId: number;
    userType: KnownUserType;
    // fixme lanr, doctorType, specialization, keycloakId
  };
}

export enum AuthOption {
  APP = 'APP',
  CARD = 'CARD',
  SMS = 'SMS',
}

export enum AokPinStatus {
  Active = 'ACTIVE',
}

export enum AokRegistrationLockStatus {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
}

export interface AokExportStatus {
  createdDate: number;
  successfulExport: boolean;
  errorMessage: string;
  registrationIds: Array<number>;
}

export interface AokUserRegistrationPin {
  createdDate: string;
  createdBy: string;
  expirationDateTime: string;
  pinStatus: AokPinStatus;
  pin: string;
}

export interface Aok2FAPatch {
  twoFactorAuthOption: AuthOption;
  bsnr?: string;
  countryCode?: string;
  secondFactorPhoneNumber?: string;
}

export interface RegistrationApprovalDialogResult {
  result?: AokUserRegistrationPin;
  closeType: string;
}
