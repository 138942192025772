import { Injectable } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';
import { MatomoCustomVariable } from '../config/matomo.config';

/** The values are expected like so in Matomo, should not be changed **/
export enum PrintTypeMatomoMapping {
  PRE_PRINTED = 'Blanko',
  FULL_PRINT = 'Muster 5',
}

export enum SearchTypeMatomoMapping {
  ADDRESS = 'Pat.Daten',
  INSURANCE = 'Vers.Nr.',
  INSURANCE_CARD = 'Vers.Karte',
}
/** ---------------------------------------------------------------- **/

@Injectable({
  providedIn: 'root',
})
export class MatomoService {
  constructor(private matomoTracker: MatomoTracker) {}

  public setMatomoServiceSearchVariable(searchType: string[]): void {
    const searchTypeString = searchType.join(', ');
    this.matomoTracker.setCustomVariable(1, MatomoCustomVariable.SERVICE_SEARCH_TYPE, searchTypeString, 'page');
    window[MatomoCustomVariable.SERVICE_SEARCH_TYPE] = searchTypeString;
  }

  public setMatomoPrintingSheetVariable(printType: string): void {
    this.setMatomoCustomVariableByType(MatomoCustomVariable.PRINTING_SHEET_TYPE, printType, PrintTypeMatomoMapping);
  }

  public setMatomoPatientSearchVariable(searchType: string): void {
    this.setMatomoCustomVariableByType(MatomoCustomVariable.PATIENT_SEARCH_TYPE, searchType, SearchTypeMatomoMapping);
  }

  public setMatomoBatchPrintCountVariable(printNumber: number): void {
    this.matomoTracker.setCustomVariable(1, MatomoCustomVariable.BATCH_PRINT_COUNT, String(printNumber), 'page');
    window[MatomoCustomVariable.BATCH_PRINT_COUNT] = printNumber;
  }

  public deleteMatomoCustomVariable(customVariable: string): void {
    this.matomoTracker.deleteCustomVariable(1, 'page');
    delete window[customVariable];
  }

  private setMatomoCustomVariableByType(
    customVariable: string,
    type: string,
    mapper: typeof PrintTypeMatomoMapping | typeof SearchTypeMatomoMapping
  ): void {
    this.matomoTracker.setCustomVariable(1, customVariable, mapper[type], 'page');
    window[customVariable] = mapper[type];
  }
}
