import { Injectable } from '@angular/core';
import { AppState, ArrayBehaviorState, BehaviorState } from '@aok/common';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  footerContactBase,
  footerInfoGroup,
  footerInfoLinks,
  footerPresetBaseGroups,
  getFooterPresetBaseLinks,
} from '../providers';
import { AokFooterContact, AokFooterLink, AokFooterLinkGroup } from '../schema';
import { AokCookieService } from '../services';
import { FeedbackAvailableState } from './feedback-available.state';

@Injectable({ providedIn: 'root' })
export class FooterLayoutState {
  private contactState = new BehaviorState<AokFooterContact>();
  private linksState = new ArrayBehaviorState<AokFooterLink>();
  private linkGroupsState = new ArrayBehaviorState<AokFooterLinkGroup>();

  constructor(
    private cookieConsent: AokCookieService,
    private feedbackAvailableState: FeedbackAvailableState,
    private appState: AppState
  ) {}

  public init(): void {
    this.appState.isAccessible$
      .pipe(distinctUntilChanged())
      .subscribe((isCockpitAccessible) => this.setStates(isCockpitAccessible));
  }

  public getContact$(): Observable<AokFooterContact> {
    return this.contactState.asObservable();
  }

  public getLinks$(): Observable<AokFooterLink[]> {
    return this.linksState.asObservable();
  }

  public getLinkGroups$(): Observable<AokFooterLinkGroup[]> {
    return this.linkGroupsState.asObservable();
  }

  private setStates(isCockpitAccessible: boolean): Observable<void> {
    this.setContact();
    this.setLinksAndGroups(isCockpitAccessible);

    return of(undefined);
  }

  private setContact(): void {
    if (this.feedbackAvailableState.isFeedBackAvailable.getValue()) {
      this.contactState.resetObjState({
        ...footerContactBase,
        feedback: {
          title: 'Feedback senden',
        },
      });
    } else {
      this.contactState.resetObjState(footerContactBase);
    }
  }

  private setLinksAndGroups(isCockpitAccessible: boolean): void {
    if (isCockpitAccessible) {
      this.linksState.reset(...getFooterPresetBaseLinks(this.cookieConsent), ...footerInfoLinks);
      this.linkGroupsState.reset(footerInfoGroup, ...footerPresetBaseGroups);
    } else {
      this.linksState.reset(...getFooterPresetBaseLinks(this.cookieConsent));
      this.linkGroupsState.reset(...footerPresetBaseGroups);
    }
  }
}
