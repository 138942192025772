import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DropdownSchema, isString } from '@aok/common';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DropdownMode } from '../../schema';

@Component({
  selector: 'aok-dropdown',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['dropdown.component.scss'],
  templateUrl: './dropdown.component.html',
})
export class AokDropdownComponent implements OnInit {
  @Input() options: DropdownSchema[];
  @Input() control: UntypedFormControl;
  @Input() mode: DropdownMode = DropdownMode.SIMPLE;
  @Input() virtualScroll = false;
  // has to be lower case to work as web element
  @Input() innerid: string = null;

  @Input() placeholder: string = null;

  // has to be lower case to work as web element
  @Output() valuechange: EventEmitter<unknown>;

  @ViewChild('selectComponent') selectComponent: NgSelectComponent;

  public term: string;
  public readonly DropdownMode = DropdownMode;

  constructor(private cd: ChangeDetectorRef) {
    this.valuechange = new EventEmitter<unknown>();
  }

  ngOnInit(): void {
    if (!this.control) {
      this.control = new UntypedFormControl();
    }
  }

  public addTag(value: string): DropdownSchema {
    return { value: value, label: value };
  }

  public onSearch($event: { term: string; items: any[] }): void {
    this.term = $event.term;
    if (isString(this.term)) {
      this.control.patchValue(this.term);
    }
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  public onBlur(selectComponent: NgSelectComponent): void {
    if (!this.term) {
      return;
    }

    if (this.mode === DropdownMode.FILTERABLE) {
      this.clear();
      return;
    }

    if (isString(this.term)) {
      if (!this.options.find((option) => option.value === this.term)) {
        this.options = [...this.options, this.addTag(this.term)];
      }
      selectComponent.itemsList.setItems(this.options);
    }
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  public onChange($event: any): void {
    if (this.mode === DropdownMode.ENHANCED) {
      this.term = $event;
    }
    this.valuechange.emit($event?.value);
    this.triggerDetection();
  }

  /**
   * responds to clear event
   */
  public onClear(): void {
    this.term = null;
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  /**
   * manually clear the input
   */
  public clear(): void {
    this.selectComponent.handleClearClick();
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  public arrowClick(): void {
    this.selectComponent.handleArrowClick();
  }

  public triggerDetection(): void {
    this.cd.detectChanges();
  }
}
