import { NgModule } from '@angular/core';
import { RouterLinkOrHrefDirective } from './router-link-or-href.directive';

/**
 * Copy of PDS module
 * https://git.vitasystems.dev/vitagroup/pds-angular/-/blob/develop/libs/common/src/router-link-or-href/router-link-or-href.ts
 */
@NgModule({
  declarations: [RouterLinkOrHrefDirective],
  exports: [RouterLinkOrHrefDirective],
})
export class RouterLinkOrHrefModule {}
