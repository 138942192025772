import { AokBsnrRegistrationStatus, AokUserRegistrationStatus } from '../../schemas';

export const getRegistrationStatusText = (registrationStatus: AokUserRegistrationStatus | string): string => {
  switch (registrationStatus) {
    case AokUserRegistrationStatus.Approved:
      return 'Bearbeitung abgeschlossen';
    case AokUserRegistrationStatus.Pending:
    case AokBsnrRegistrationStatus.FOR_REVIEW:
      return 'Zur Prüfung';
    case AokUserRegistrationStatus.Rejected:
    case AokBsnrRegistrationStatus.REJECTED:
      return 'Abgelehnt';
    case AokUserRegistrationStatus.PinExpired:
      return 'PIN abgelaufen';
    case AokUserRegistrationStatus.PinMarked:
      return 'PIN vorgemerkt für Briefversand';
    case AokUserRegistrationStatus.PinInLetterDispatch:
      return 'PIN in Briefversand';
    case AokUserRegistrationStatus.CardCreation:
      return 'Displaykarte wird erstellt';
    case AokUserRegistrationStatus.FailedExport:
      return 'PIN vorgemerkt für Briefversand';
    case AokUserRegistrationStatus.Invited:
      return 'eingeladen';
    case AokUserRegistrationStatus.RequestReceived:
    case AokBsnrRegistrationStatus.REQUEST_RECEIVED:
      return 'Anfrage eingegangen';
    case AokUserRegistrationStatus.InternalReview:
    case AokBsnrRegistrationStatus.INTERNAL_REVIEW:
      return 'Interne Prüfung';
    default:
      return registrationStatus || '';
  }
};
