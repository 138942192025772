import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AokHttpParamsInterceptor } from './http-params.interceptor';

@NgModule()
export class AokHttpParamInterceptorModule {
  static forRoot(): ModuleWithProviders<AokHttpParamInterceptorModule> {
    return {
      ngModule: AokHttpParamInterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AokHttpParamsInterceptor,
          multi: true,
        },
      ],
    };
  }
}
