import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AokRouteDataPipeModule, AokSvgIconComponent } from '@aok/common';
import {
  AokFooterModule,
  AokPaginationModule,
  ArticleTeaserListModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
} from '@aok/components';

@Component({
  selector: 'aok-cockpit-articles',
  styleUrls: ['./articles.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './articles.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AokRouteDataPipeModule,
    AokPaginationModule,
    FeedbackButtonModule,
    AokFooterModule,
    HeaderModule,
    InfoBannerComponent,
    AokSvgIconComponent,
    ArticleTeaserListModule,
  ],
})
export class ArticlesComponent {}
