import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { CurrentUserState, isDoctor } from '@aok/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const practiceManagementGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const currentUser = inject(CurrentUserState);
  const router = inject(Router);

  return currentUser.first$.pipe(map((user) => isDoctor(user) || router.createUrlTree(['profil', 'praxisdaten'])));
};
