import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'aok-notification-bell',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['notification-bell.component.scss'],
  template: `
    <div [ngClass]="{ active: counter > 0 }">
      <aok-svg-icon [name]="'0344-bell'" [title]="'Benachrichtigungen'" [size]="20"></aok-svg-icon>
      <div *ngIf="counter > 0" class="badge">
        <span>{{ counter < 10 ? counter : '9+' }}</span>
      </div>
    </div>
  `,
})
export class AokNotificationBellComponent {
  @Input() counter = 0;
}
