import { AokPage, AokPageQueryParams } from './page.schema';

export interface AokReport {
  id: number;
  authorFirstName: string;
  authorLastName: string;
  ownerId: number;
  ownerTitle: string;
  ownerFirstName: string;
  ownerLastName: string;
  fileName: string;
  lastModifiedBy: string;
  lastModifiedDate: number;
  readAt: number;
  createdDate: number;
  reportCategory: AokReportCategory;
  userIdsWithAccess: number[];
}

export enum AokReportCategory {
  HZV = 'hzv',
  ARZNEIMITTEL = 'arzneimittel',
  HEILMITTEL = 'heilmittel',
}

export interface AokCmsReport {
  id: number;
  createdDate: number;
  fileName: string;
  owner: string;
  author: string;
}

export interface AokReportListOptions extends AokPageQueryParams {
  category?: AokReportCategory;
}

export interface AokReportPageData {
  hzv: AokPage<AokReport>;
  arzneimittel: AokPage<AokReport>;
  heilmittel: AokPage<AokReport>;
}
