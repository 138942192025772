import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogRef } from '../cdk';

@Injectable({ providedIn: 'root' })
export class OpenedDialogService {
  private openedDialog$ = new BehaviorSubject<DialogRef<any>>(null);

  public closeDialog(): void {
    this.openedDialog$.value?.dispose(false);
    this.openedDialog$.next(null);
  }

  public storeDialog(param: DialogRef<any>): void {
    this.openedDialog$.next(param);
  }
}
