export interface AokStaticArticle {
  readonly id: number;

  content: string;
  title: string;
}

export enum AokStaticArticleRoute {
  Accessibility = 'erklaerung-zur-barrierefreiheit',
  EasySpeech = 'leichte-sprache',
  SignLanguage = 'gebaerdensprache',
  Legal = 'nutzungsbedingungen',
  Privacy = 'datenschutz',
  Imprint = 'impressum',
}

export const AOK_STATIC_ARTICLE_KEY_ROUTE_MAPPING = [
  {
    route: AokStaticArticleRoute.Accessibility,
    key: 'accessibility',
  },
  {
    route: AokStaticArticleRoute.EasySpeech,
    key: 'easy-speech',
  },
  {
    route: AokStaticArticleRoute.SignLanguage,
    key: 'sign-language',
  },
  {
    route: AokStaticArticleRoute.Privacy,
    key: 'privacy',
  },
  {
    route: AokStaticArticleRoute.Legal,
    key: 'legal',
  },
  {
    route: AokStaticArticleRoute.Imprint,
    key: 'imprint',
  },
];

export type AokStaticArticleKey = 'imprint' | 'privacy' | 'legal' | 'sign-language' | 'accessibility';
