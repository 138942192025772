import { ModuleWithProviders, NgModule } from '@angular/core';
import { AOK_CURRENT_USER_INIT_PROVIDER } from './current-user.listener';

@NgModule()
export class AokActiveAccountModule {
  static forRoot(): ModuleWithProviders<AokActiveAccountModule> {
    return {
      ngModule: AokActiveAccountModule,
      providers: [AOK_CURRENT_USER_INIT_PROVIDER],
    };
  }
}
