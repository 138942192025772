import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { PopoverList } from '../../../schemas';
import { isString } from '../../../utils';
import { AokSvgIconComponent } from '../../svg-icon/svg-icon.component';

@Component({
  selector: 'aok-popover-content-template',
  templateUrl: './popover-content-template.component.html',
  styleUrls: ['./popover-content-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, AokSvgIconComponent],
})
export class AokPopoverContentTemplateComponent implements OnChanges {
  @Input() label: string;
  @Input() text: string;
  @Input() list: PopoverList;
  @Input() generalListIcon: string;
  @Input() pictureSource: Blob | string;

  public _templateType: 'text' | 'image' | 'list' = 'text';

  ngOnChanges(): void {
    if (!this.list && !this.pictureSource && (this.text || this.label)) {
      this._templateType = 'text';
    } else if (!this.label && !this.list && this.text && this.pictureSource) {
      this._templateType = 'image';
    } else if (!this.text && !this.pictureSource && this.label && this.list) {
      this._templateType = 'list';
    } else {
      this._templateType = null;
    }
  }

  public getListItemText(element: { icon?: string; text: string } | string): string {
    return isString(element) ? element : element?.text;
  }
  public getListItemIcon(element: { icon?: string; text: string } | string): string {
    return isString(element) || !element?.icon ? this.generalListIcon : element?.icon;
  }
}
