import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlTree } from '@angular/router';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { lastValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export const keycloakRoleGuard: CanMatchFn = async (route: Route): Promise<boolean | UrlTree> => {
  const router = inject(Router);
  const keycloak = inject(KeycloakService);

  if (keycloak.getKeycloakInstance() == null)
    await lastValueFrom(
      keycloak.keycloakEvents$.pipe(
        filter((e) => e.type === KeycloakEventType.OnReady),
        take(1)
      )
    );

  const authenticated = await keycloak.isLoggedIn();

  // Force the user to log in if currently unauthenticated.
  if (!authenticated) {
    await keycloak.login();
  }

  const roles = keycloak.getUserRoles();
  // Get the roles required from the route.
  const requiredRoles = route.data.roles;

  // Allow the user to proceed if no additional roles are required to access the route.
  if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
    return true;
  }

  // Allow the user to proceed if any of the required roles are present.
  return requiredRoles.some((role) => roles.includes(role)) || router.parseUrl('/');
};
