import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { BreadcrumbsService } from '../../../services';
import { AokSitemap, SITEMAP, SiteRef } from '../sitemap-tree/sitemap';
import { AokHeaderService } from '../../header/header.service';

@Component({
  selector: 'aok-breadcrumbs',
  styleUrls: ['breadcrumbs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './breadcrumbs.component.html',
})
export class AokBreadcrumbsComponent implements OnInit, OnDestroy {
  _path: SiteRef[];
  @Input() homeLinkUrl: string;
  protected readonly ngDestroys = new Subject<void>();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected changeDetectorRef: ChangeDetectorRef,
    private breadcrumbService: BreadcrumbsService,
    private headerService: AokHeaderService,
    @Inject(SITEMAP) protected sitemap: AokSitemap
  ) {}

  get homeSite(): SiteRef | null {
    return this.breadcrumbService.getHomeUrl(this.homeLinkUrl, this.sitemap);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.ngDestroys)
      )
      .subscribe(() => {
        this._path = this.breadcrumbService.findActiveSitePath(this.route, this.sitemap, this.homeLinkUrl);
        this.changeDetectorRef.detectChanges();
      });

    this._path = this.breadcrumbService.findActiveSitePath(this.route, this.sitemap, this.homeLinkUrl);
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  public isStaticBreadcrumb(isLast: boolean, siteRef: SiteRef): boolean {
    return isLast || siteRef.route.data?.interimRoute;
  }

  public navigateHome(): void {
    this.headerService
      .getNavigationEntries()
      .pipe(first())
      .subscribe((entries) => this.router.navigate([entries[0].linkUrl]));
  }
}
