import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AokToastService,
  AokUser,
  AokUserClient,
  DialogBase,
  DialogRef,
  email,
  FormErrorDetectorRef,
  getNumberErrorsFromForm,
  matchFormFieldsValidator,
  PopoverDirection,
} from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aok-cockpit-change-email-dialog',
  templateUrl: './change-email-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./change-email-dialog.component.scss'],
})
export class AokChangeEmailDialog implements OnInit, OnDestroy, DialogBase<boolean> {
  @Input() schema?: AokUser;
  @Input() activeUser: AokUser;
  public title = 'E-Mail bearbeiten';
  public submitLabel = 'E-Mail speichern';
  public msgRequiredHint = 'Pflichtfeld';
  public popoverDirection: PopoverDirection = PopoverDirection.TopLeft;
  public bannerHeadline = 'Änderung Ihrer Anmeldedaten';
  public bannerContent =
    'Wenn Sie fortfahren, können Sie sich nicht mehr mit Ihrer alten E-Mail-Adresse einloggen.<br><br>' +
    'Im nächsten Schritt senden wir Ihnen eine E-Mail zur Verifizierung der neuen Adresse. Bitte prüfen Sie Ihre ' +
    'neue Adresse deshalb vor dem Speichern auf Korrektheit.<br><br> Bei Problemen oder Fragen wenden Sie sich bitte ' +
    'an unser Support-Team unter <a href="tel:0511 285 34679">0511 285-34679</a> oder ' +
    '<a href="mailto:aok-arztportal@nds.aok.de">aok-arztportal@nds.aok.de</a>.';
  public msgMultiError = 'Es sind mehrere Fehler aufgetreten';
  public msgSingleError = 'Es ist ein Fehler aufgetreten';
  errorCount = 0;
  isErrorBannerEnabled = false;
  formGroup = new UntypedFormGroup(
    {
      email: new UntypedFormControl(null, [Validators.required, email], []),
      emailRepeat: new UntypedFormControl(null, [Validators.required, email], []),
    },
    {
      validators: matchFormFieldsValidator('email', 'emailRepeat'),
    }
  );
  protected readonly ngDestroy = new Subject<void>();
  // formGroup: FormGroup = createChangeEmailGroup();

  constructor(
    public readonly dialogRef: DialogRef<boolean>,
    protected client: AokUserClient,
    protected errorDetectorRef: FormErrorDetectorRef,
    protected keycloak: KeycloakService,
    private toastService: AokToastService
  ) {}

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      if (this.isErrorBannerEnabled) {
        this.errorCount = getNumberErrorsFromForm(this.formGroup);
        // once the errors got resolved, we only want to show the banner after another submit call
        if (this.errorCount === 0) {
          this.isErrorBannerEnabled = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  submit(): void {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => control.updateValueAndValidity());
      // todo: it don't exist any template how it should looks with errors so I only mark the fields
      // this.isErrorBannerEnabled = true;
      // this.errorDetectorRef.detectErrors();
      // this.errorCount = getNumberErrorsFromForm(this.formGroup);
      // scrollToTop();
    } else {
      const emailAddress = this.formGroup.get('email').value;
      this.client
        .updateEmail(emailAddress)
        .pipe(
          catchError(() => {
            this.showEmailChangeErrorToast();
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.dialogRef.dispose(true);
          location.reload();
        });
    }
  }

  private showEmailChangeErrorToast(): void {
    this.toastService.createErrorToast(
      'Es ist ein Fehler aufgetreten.',
      'Bitte verwenden Sie eine andere E-Mail-Adresse oder wenden Sie sich an unser Support-Team.'
    );
  }
}
