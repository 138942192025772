export function getMinTreatmentDate(date: Date): Date {
  // Get the year and month
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();

  // Determine the current quarter
  const quarter = Math.floor(month / 3);

  // Calculate the start of the current quarter
  const quarterStart = new Date(Date.UTC(year, quarter * 3, 1));

  // Calculate days since the start of the quarter
  const daysSinceQuarterStart = Math.floor((date.getTime() - quarterStart.getTime()) / (1000 * 60 * 60 * 24));

  if (daysSinceQuarterStart < 10) {
    // If it's within the first 10 days of the quarter, go to previous quarter
    return new Date(Date.UTC(year, quarter * 3 - 3, 1));
  } else {
    // If it's after the first 10 days, use the current quarter
    return new Date(Date.UTC(year, quarter * 3, 1));
  }
}
