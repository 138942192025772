import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { A11yUtilsModule, AokPopoverComponent, AokSvgIconComponent } from '@aok/common';
import { AokHintModule, AokInputFieldModule, RequiredMarkModule } from '../../cdk';
import { AokDropdownModule } from '../../dropdown/dropdown.module';
import { FormControlErrorModule } from '../../form-control-error/module';
import { AokEmailErrorMessageModule } from '../../form-error-message/email-error-message/email-error-message.module';
import { AokRequiredErrorMessageModule } from '../../form-error-message/required-error-message/required-error-message.module';
import { AokNotificationBannerModule } from '../../notification-banner/notification-banner.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { AokAddBSNRAsMfaDialog } from './add-bsnr-as-mfa-dialog.component';
import { AppAddBSNRAsMfaDialogDirective } from './add-bsnr-as-mfa-dialog.directive';

@NgModule({
  declarations: [AokAddBSNRAsMfaDialog, AppAddBSNRAsMfaDialogDirective],
  exports: [AokAddBSNRAsMfaDialog, AppAddBSNRAsMfaDialogDirective],
  imports: [
    FormControlErrorModule,
    AokRequiredErrorMessageModule,
    AokEmailErrorMessageModule,
    ReactiveFormsModule,
    CommonModule,
    AokHintModule,
    AokInputFieldModule,
    AokNotificationBannerModule,
    AokSvgIconComponent,
    AokDialogLayoutModule,
    RequiredMarkModule,
    AokPopoverComponent,
    AokDropdownModule,
    A11yModule,
    A11yUtilsModule,
  ],
})
export class AokCockpitAddBSNRAsMfaDialogModule {}
