import { MonoTypeOperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { flattenTree, FlatTree, restoreTree } from '../tree.util';
import { NavigationEntry } from '../../components';

export function mapAokNavEntryChildren(
  resolver: (entry: NavigationEntry) => NavigationEntry[],
  prepend?: (entry: NavigationEntry) => NavigationEntry[] | null
): MonoTypeOperatorFunction<NavigationEntry[]> {
  return (source) =>
    source.pipe(
      map((entries: NavigationEntry[]) => {
        entries = flattenTree(entries).reduce((acc, entry) => {
          const mapEntryLevel = (childEntry: NavigationEntry) => ({ ...childEntry, level: entry.level + 1 });

          const children = resolver(entry)?.map(mapEntryLevel);
          const prependedEntries = prepend?.(entry)?.map(mapEntryLevel) || [];

          acc = acc.concat(entry, ...prependedEntries);

          return children?.length ? acc.concat(children) : acc;
        }, []);
        return restoreTree(entries as FlatTree<NavigationEntry>);
      }) as any
    );
}
