export class AokCardReaderError {
  ErrorCode: string;
  ErrorMsg: string;
  Kontext: string;

  public constructor(ErrorCode: string, ErrorMsg: string, Kontext: string) {
    this.ErrorCode = ErrorCode;
    this.ErrorMsg = ErrorMsg;
    this.Kontext = Kontext;
  }
}
