import { HttpParameterCodec } from '@angular/common/http';

export class AokHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return (value && encodeURIComponent(value)) || '';
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return (value && decodeURIComponent(value)) || '';
  }
}
