// TODO update all (non-Simple?) Dialogs to use this as a result
import { AokOrg, AokUser } from '@aok/common';
import { ProfileAssistant } from './profile.schema';

export interface DialogResult<T = string> {
  action?: T;
  success: boolean;
}

export enum DialogKey {
  MFA_REQUEST_TO_JOIN = 'bsnr-registrations',
  OPEN_JOIN_REQUEST_TO_PRACTICE_MFA_DIALOG = 'open-join-request-to-practice-mfa-dialog',
  OPEN_JOIN_REQUEST_TO_PRACTICE_DOCTOR_DIALOG = 'open-join-request-to-practice-doctor-dialog',
  OPEN_ADD_PRACTICES_DIALOG = 'open-add-practices-dialog',
  OPEN_COOKIE_DIALOG = 'open-cookie-dialog',
  OPEN_REPORT_CONTACT_DIALOG = 'open-report-contact-dialog',
}

export interface SimpleDialogBody {
  headerText: string;
  contentText: string | string[];
  cancelButtonText?: string; // button text for close the dialog direct
  confirmButtonText?: string; // button text for submit the dialog
  confirmFunction?: (...args) => unknown;
  closeText?: string;
  maxWidth?: number | string;
  closable?: boolean;
}

export interface EditMfaDialogBody {
  currentUser: AokUser;
  org: AokOrg;
  mfa: ProfileAssistant;
}
