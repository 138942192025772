import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { AokArticle, normalizeUrl } from '@aok/common';
import { TileSchema } from '../../../schema';

@Component({
  selector: 'aok-article-teaser',
  styleUrls: ['./article-teaser.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <aok-tile class="clickable" [ngClass]="horizontal ? 'horizontal' : ''" [tile]="getTileFromArticle()">
      <div class="article-preview-header" metaData>
        <div class="metadata aok-text-l1">
          {{ article?.lastModifiedDate | date : 'dd.MM.yyyy' }}
        </div>
      </div>
      <aok-article-content [value]="article?.content" tease="true" content></aok-article-content>
    </aok-tile>
  `,
})
export class AokArticleTeaserComponent {
  @Input() article: AokArticle;
  @Input() articleDetailsBaseUrl = '/aktuelles/artikel';
  @Input() horizontal = false;

  public getTileFromArticle(): TileSchema {
    return {
      link: this.getArticleLink(),
      title: this.article.title,
      image: this.article.thumbnailUrl || this.article.imageUrl,
      isTileClickable: true,
    };
  }

  private getArticleLink(): string {
    return normalizeUrl(this.articleDetailsBaseUrl, this.article.id.toString());
  }
}
