<dialog-layout
  [fullscreen]="false"
  [headerText]="'Weiteren Arzt hinzufügen'"
  cdkTrapFocus
  cdkTrapFocusAutoCapture="true"
>
  <p>
    Damit der Arzt, für den Sie arbeiten, im Auswahlmenü sichtbar ist, sind folgende Schritte durch den Arzt
    durchzuführen:
  </p>

  <p>1. Der Arzt muss den Zugang über das KVN-Portal zum AOK-Arztportal vollständig einrichten.</p>

  <p>
    2. Der Arzt muss den Zugriff für KVN-Praxismitarbeiter im AOK-Arztportal freigeben (Freigabe unter: Profil →
    Praxisverwaltung).
  </p>

  <p>Anschließend ist der Arzt beim erneuten Zugriff auf das AOK-Arztportal im Auswahlmenü vorhanden.</p>

  <dialog-footer>
    <button (click)="dialogRef.dispose()">Schließen</button>
  </dialog-footer>
</dialog-layout>
