<div class="filter-area tw-col-1-13 aok-container" [class.hidden]="!isOpen">
  <ng-content></ng-content>

  <a
    class="aok-link-1 tw-col-10-12"
    [class.disabled]="!hasSelection"
    (click)="resetFilters.emit()"
    (keyup.enter)="resetFilters.emit()"
  >
    {{ resetButtonLabel }}
  </a>
</div>
