import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AokNotificationCountResponse } from '../schemas';
import { AokApiBaseClient } from './api-base.client';

export const AOK_BASE_NOTIFICATION_API_URL = new InjectionToken<string>('AOK_BASE_NOTIFICATION_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/notification',
});

@Injectable({ providedIn: 'root' })
export class AokBaseNotificationClient extends AokApiBaseClient {
  constructor(@Inject(AOK_BASE_NOTIFICATION_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  public count(): Observable<AokNotificationCountResponse> {
    return this.http.get<AokNotificationCountResponse>(this.resolveUrl('count/me'));
  }
}
