import { Routes } from '@angular/router';
import { environment } from '../../environments/environment';

const CONTRACT_ROUTES_ENTRIES = [
  {
    path: `${environment.svsAppEntrypointUrl}/vertraege/`,
    redirectTo: `${environment.svsAppEntrypointUrl}/vertraege/`,
    data: {
      title: 'Übersicht Verträge',
      contract: true,
    },
  },
  {
    path: `${environment.svsAppEntrypointUrl}/hzv/`,
    redirectTo: `${environment.svsAppEntrypointUrl}/hzv`,
    data: {
      title: 'Hausarztzentrierte Versorgung',
      contract: true,
    },
  },
];

function getContractRoutes(): Routes {
  const contractsRoutes = [];
  const enablers = [environment.enableContractsOverview, environment.enableFamilyDoctorCenterCare];

  enablers.forEach((toggle, index) => {
    if (toggle) {
      contractsRoutes.push(CONTRACT_ROUTES_ENTRIES[index]);
    }
  });

  return contractsRoutes;
}

export const CONTRACT_ROUTES = getContractRoutes();
