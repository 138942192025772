<ng-container *ngIf="allowDirectRegistration" [ngTemplateOutlet]="registerDirectlyContainer"></ng-container>
<div class="aok-container">
  <aok-notification-banner
    *ngIf="hasBothRoles"
    class="tw-col-2-12 tw-mt-3"
    bannerHeadline="KVN-Zugang und direkte Registrierung im Arztportal vorhanden"
    bannerLabel="Sie können über das KVN-Portal auf das Arztportal zugreifen oder sich direkt am Arztportal anmelden."
    iconName="info-circle"
    accent="info"
  >
  </aok-notification-banner>

  <aok-cockpit-table-type
    [sectionLabel]="'Persönliche Daten'"
    [values]="getPersonalDataKeyValuePair() | async"
    [entries]="[{ id: 'editMyData', title: 'Daten bearbeiten', disabled: !isDoctor(currentUser.snapshot) }]"
    (selected)="onPracticeDataOptionSelect()"
  >
  </aok-cockpit-table-type>

  <aok-cockpit-table-type
    *ngIf="hasBothRoles || !isKvn"
    [sectionLabel]="'Zugangsdaten'"
    [values]="getAccessDataKeyValuePair() | async"
    [entries]="optionEntries"
    (selected)="onAccessDataOptionSelect($event)"
  >
  </aok-cockpit-table-type>

  <div class="tw-mt-3 tw-col-2-9 lg:tw-col-2-11">
    <ng-container *ngIf="isKvnOrigin && (isKvn || hasBothRoles); else deleteProfileTemplate">
      Sie möchten Ihr Profil vollständig löschen? Bitte beachten Sie, dass eine Wiederherstellung nicht möglich ist.
    </ng-container>

    <ng-template #deleteProfileTemplate>
      Wenn Sie auf "Profil löschen" klicken, wird Ihr Nutzerprofil vollständig gelöscht und kann nicht wieder
      hergestellt werden.
    </ng-template>

    <p
      class="aok-link-1 block tw-mt-9"
      aokFocusable
      (click)="deleteProfile()"
      (keyup.enter)="deleteProfile()"
    >
      Profil löschen
    </p>
  </div>
</div>

<ng-template #registerDirectlyContainer>
  <div class="banner-container tw-mt-2">
    <div class="aok-container">
      <h3 class="tw-mt-5 tw-col-2-12">Ergänzende Registrierung im AOK-Arztportal</h3>
      <div class="tw-mt-5 tw-mb-4 grid-10 tw-col-2-12">
        <div class="banner-info tw-col-1-7">
          <aok-svg-icon [name]="'user-check'" size="35px" class="tw-mr-9"></aok-svg-icon>
          <p>
            Sie können sich nach der ergänzenden Registrierung über das KVN-Portal oder direkt über das Arztportal
            anmelden.
          </p>
        </div>
        <div class="tw-col-7-11">
          <button aokFocusable (click)="open2FASelection()">Registrierung ergänzen</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
