import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AokFullNamePipe,
  AokMenuButtonEntry,
  AokRuntimeProfileState,
  AokToastService,
  AokUser,
  AokUserClient,
  countryCodeDE,
  CurrentUserState,
  DialogOverlay,
  getDoctorType,
  getSmsDataControls,
  isDoctor,
  isKvnDoctor,
  isKvnFullDoctor,
  isSMSAuthOption,
  KnownUserType,
  sanitizePhoneNumber,
  TableTypeRow,
} from '@aok/common';
import { AokFgsPipe, AokSimpleDialog, DeleteKvnProfileDialog, KvnState, PhoneNumberDialog } from '@aok/components';
import { KeycloakService } from 'keycloak-angular';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LOGOUT_AFTER_MS } from '../config/app.config';
import {
  OPT_CHANGE_MAIL,
  OPT_CHANGE_PW,
  OPT_CHANGE_SMS_2FA_DATA,
} from '../pages/profile/profile-data/profile-data.config';
import { buildSms2faData } from '../utils/2fa.util';

@Injectable({
  providedIn: 'root',
})
export class ProfileDataService {
  constructor(
    private runtimeProfile: AokRuntimeProfileState,
    private currentUser: CurrentUserState,
    private userClient: AokUserClient,
    private toastService: AokToastService,
    private dialog: DialogOverlay,
    private keycloak: KeycloakService,
    private kvnState: KvnState
  ) {}

  public openEditOrganizationEmail(): void {
    const recipient = this.runtimeProfile.get<string>('contactEmail'); // 'aok-arztportal@nds.aok.de';
    const subject = 'Änderung der Profildaten im AOK-Arztportal';
    const msg: string =
      'Sehr geehrtes Arztportal-Team,\n\n' +
      'hiermit sende ich Ihnen eine Anfrage zur Änderung der persönlichen Daten für mein Nutzerprofil mit der LANR ' +
      this.currentUser.snapshot.practitionerResource?.lanr +
      '.\n\n' +
      'Bitte übernehmen Sie die folgenden Daten in Ihr System:\n' +
      '\t→Bitte fügen Sie hier Ihre neuen Daten ein\n\n' +
      'Mit freundlichen Grüßen\n\n' +
      '---\n\n' +
      'Daten zur Zuordnung der Anfrage:\n' +
      '-LANR ' +
      this.currentUser.snapshot.practitionerResource?.lanr;

    window.location.href = `mailto:${recipient}?subject=${subject}&body=${encodeURIComponent(msg)}`;
  }

  public createPersonalDataKeyValuePair(user: AokUser): TableTypeRow[] {
    const namePipe = new AokFullNamePipe();
    const { practitionerResource, mobilePhone } = user;
    if (isDoctor(user)) {
      const fgsPipe = new AokFgsPipe();
      const kvnOnlyPairs = isKvnDoctor(user.userType) ? [{ label: 'E-Mail', value: user.email }] : [];
      return [
        { label: 'Name', value: namePipe.transform(user) },
        { label: 'Rechtlicher Status', value: getDoctorType(practitionerResource?.doctorType) },
        { label: 'LANR', value: practitionerResource?.lanr },
        {
          label: 'Fachgruppenschlüssel',
          value: fgsPipe.transform(practitionerResource) + ' ' + practitionerResource?.specialization?.description,
        },
        { label: 'Telefonnummer', value: mobilePhone },
        ...kvnOnlyPairs,
      ];
    } else {
      return [
        { label: 'Name', value: namePipe.transform(user) },
        { label: 'Telefonnummer', value: mobilePhone },
      ];
    }
  }

  public createAccessDataKeyValuePair(user: AokUser): TableTypeRow[] {
    const accessData: TableTypeRow[] = [
      { label: 'E-Mail', value: user.email },
      { label: 'Passwort', value: '● ● ● ● ● ● ● ●' },
    ];

    if (isSMSAuthOption(user.authOption)) {
      accessData.push({
        label: 'Mobilfunknummer (2FA)',
        value: buildSms2faData(user),
      });
    }

    return accessData;
  }

  public openProfileDeleteDialog(isLastDoctorAtBSNRLocation: boolean): void {
    if (isKvnFullDoctor(this.currentUser.snapshot.userType)) {
      this.dialog
        .create(DeleteKvnProfileDialog, {
          props: {
            headerText: 'Profil löschen',
            confirmButtonText: 'Profil löschen',
            cancelButtonText: 'Abbrechen',
            isLastDoctorAtBSNRLocation,
          },
        })
        .subscribe(async (response) => {
          if (!response) return;
          await lastValueFrom(this.userClient.deleteMyUser(response.userType ? response : null));
          this.checkUserType(
            response.userType,
            this.kvnState.isKvnOrigin() ? KnownUserType.Kvn_Doctor : KnownUserType.Doctor
          );
        });

      return;
    }

    if (isLastDoctorAtBSNRLocation) {
      this.dialog
        .create(AokSimpleDialog, {
          props: {
            headerText: 'Profil und Praxis löschen',
            confirmButtonText: 'Profil und Praxis löschen',
            contentText: `Sind Sie sicher, dass Sie Ihr Profil und die Praxis, für die Sie registriert sind, löschen
              möchten? Da Sie der einzige Arzt sind, der aktuell für diese Praxis im Arztportal registriert ist,
              wird mit der Löschung des Profils auch die Praxis mitsamt aller dafür registrierten Praxismitarbeiter
              gelöscht. Gelöschte Nutzer können das Arztportal nicht länger nutzen.<br><br>
              Hinweis: Wir bewahren Ihre Daten nach der Löschung für 3 Jahre gesperrt auf. Die Daten werden nicht
              weiterverarbeitet und nach Ablauf dieser Frist gelöscht. Weiteres entnehmen Sie bitte unserer
              <a href="/datenschutz" target="_blank">Datenschutzerklärung</a>.`,
            cancelButtonText: 'Abbrechen',
            confirmFunction: this.deleteProfile(true) as never,
          },
        })
        .subscribe();

      return;
    }

    this.dialog
      .create(AokSimpleDialog, {
        props: {
          headerText: 'Profil löschen',
          confirmButtonText: 'Profil löschen',
          contentText: `Sind Sie sicher, dass Sie Ihr Profil löschen möchten? Wenn Sie auf „Profil löschen“ klicken
              wird Ihr Profil entfernt. Danach können Sie das Arztportal nicht länger nutzen.<br><br>
              Hinweis: Wir bewahren Ihre Daten nach der Löschung für 3 Jahre gesperrt auf. Die Daten werden nicht
              weiterverarbeitet und nach Ablauf dieser Frist gelöscht. Weiteres entnehmen Sie bitte unserer
              <a href="/datenschutz" target="_blank">Datenschutzerklärung</a>.`,
          cancelButtonText: 'Abbrechen',
          confirmFunction: this.deleteProfile(false) as never,
        },
      })
      .subscribe();
  }

  public openChange2faData(): void {
    const countryCode: string = this.currentUser.snapshot.countryCode || countryCodeDE;
    const secondFactorPhoneNumber: string = this.currentUser.snapshot.secondFactorPhoneNumber;

    const formGroup = new FormGroup(getSmsDataControls(countryCode, secondFactorPhoneNumber));

    this.dialog
      .create(PhoneNumberDialog, {
        props: {
          formGroup,
          submit: this.change2faData.bind(this) as never,
        },
      })
      .subscribe();
  }

  public redirectToPasswordPage(): void {
    this.keycloak.login({
      action: 'UPDATE_PASSWORD',
    });
  }

  public getOptionEntries(): AokMenuButtonEntry[] {
    const options = [OPT_CHANGE_MAIL, OPT_CHANGE_PW];
    if (isSMSAuthOption(this.currentUser.snapshot?.authOption)) {
      options.push(OPT_CHANGE_SMS_2FA_DATA);
    }

    return options;
  }

  private deleteProfile(lastDoctor: boolean): () => void {
    return (): void => {
      this.userClient.deleteMyUser().subscribe(() => {
        setTimeout(() => this.keycloak.logout(), LOGOUT_AFTER_MS);
        this.showProfileDeletedConfirmation(lastDoctor);
      });
    };
  }

  private showProfileDeletedConfirmation(lastDoctor: boolean): void {
    if (lastDoctor) {
      this.dialog
        .create(AokSimpleDialog, {
          props: {
            headerText: 'Ihr Profil und Ihre Praxis wurden gelöscht',
            contentText: 'Ihr Profil und Ihre Praxis wurden gelöscht. Sie werden in Kürze automatisch abgemeldet.',
            confirmButtonText: 'Jetzt abmelden',
            confirmFunction: (() => this.keycloak.logout()) as never,
          },
        })
        .subscribe();
    } else {
      this.dialog
        .create(AokSimpleDialog, {
          props: {
            headerText: 'Profil gelöscht',
            contentText: 'Ihr Profil wurde gelöscht. Sie werden in Kürze automatisch abgemeldet.',
            confirmButtonText: 'Jetzt abmelden',
            confirmFunction: (() => this.keycloak.logout()) as never,
          },
        })
        .subscribe();
    }
  }

  /**
   * Updates the 2fa information for the user using the given form group
   * Returns a boolean indicating if the update was successful
   */
  private change2faData(form: FormGroup): Observable<boolean> {
    const { countryCode, secondFactorPhoneNumber } = form.value;
    return this.userClient.update2faSmsData(countryCode, sanitizePhoneNumber(secondFactorPhoneNumber)).pipe(
      map((user) => {
        this.currentUser.resetObjState(user);
        this.toastService.createSuccessToast('Vorgang erfolgreich', 'Die Änderungen wurden gespeichert.');
        return true;
      }),
      catchError(() => {
        this.toastService.createSupportErrorToast(
          'Vorgang fehlgeschlagen',
          'Die Daten konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.'
        );
        return of(false);
      })
    );
  }

  private checkUserType(userType: string, KnownUserType: string): void {
    if (userType === KnownUserType || userType === null) {
      this.keycloak.logout();
    } else {
      location.reload();
    }
  }
}
