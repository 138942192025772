import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AokDmpCaseManagementClient,
  AokPage,
  AokPageQueryParams,
  calculateSumOfObjectProperties,
  ContextState,
  DEFAULT_PAGE_RESPONSE,
  DMPCase,
  DmpOverviewData,
} from '@aok/common';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, skip, tap } from 'rxjs/operators';
import { DmpCaseState } from '../states/dmp-case.state';
import { DmpCaseManagementOverviewService } from './dmp-case-management-overview.service';

@Injectable({
  providedIn: 'root',
})
export class DmpCaseService {
  constructor(
    private router: Router,
    private dmpCaseState: DmpCaseState,
    private dmpCaseManagementClient: AokDmpCaseManagementClient,
    private contextState: ContextState,
    private dmpOverviewService: DmpCaseManagementOverviewService
  ) {}

  public loadDmpCases(
    dmpCategory: string,
    pageParams: AokPageQueryParams,
    lanr?: string
  ): Observable<AokPage<DMPCase>> {
    return this.dmpCaseManagementClient.list({ ...pageParams, dmpCategory, lanr }).pipe(
      tap((data) => {
        if (data?._embedded) {
          this.dmpCaseState.patch(data);
        } else {
          this.dmpCaseState.patch(DEFAULT_PAGE_RESPONSE);
        }
      }),
      catchError(() => of(this.dmpCaseState.patch(DEFAULT_PAGE_RESPONSE)))
    );
  }

  public markAsDone(id: number): Observable<void> {
    return this.dmpCaseManagementClient.markAsDone(id);
  }

  public updateTableOnContextChange(dmpCategory: string): Observable<AokPage<DMPCase>> {
    return this.contextState.getPractitioner$().pipe(
      filter((practitioner) => !!practitioner),
      skip(1),
      mergeMap(() => this.dmpOverviewService.retrieveDmpOverviewData(this.contextState.practitionerLanr)),
      map((data: DmpOverviewData) => calculateSumOfObjectProperties(data?.counts) >= 1),
      mergeMap((hasDmpCases) => {
        if (hasDmpCases) {
          this.loadDmpCases(dmpCategory, {}, this.contextState.practitionerLanr);
        } else {
          this.router.navigate(['/services/dmp-falluebersicht']);

          return EMPTY;
        }
      }),
      tap((page: AokPage<DMPCase>) => this.dmpCaseState.patch(page))
    );
  }
}
