import {
  ComponentRef,
  Directive,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AokLoadingState } from './loading.state';

export const LOADING_INDICATOR_COMPONENT = new InjectionToken<Type<any>>('LOADING_INDICATOR_COMPONENT');

@Directive({ selector: '[aokLoadingStateIndicatorOutlet], aok-loading-state-indicator' })
export class AokLoadingIndicatorOutletDirective implements OnInit, OnDestroy {
  protected readonly ngDestroys = new Subject<void>();
  private _componentRef: ComponentRef<any>;

  constructor(
    readonly state: AokLoadingState,
    @Inject(LOADING_INDICATOR_COMPONENT)
    protected readonly componentType: Type<any>,
    protected viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.state.starts.pipe(takeUntil(this.ngDestroys)).subscribe(() => this.insert());
    this.state.stops.pipe(takeUntil(this.ngDestroys)).subscribe(() => this.clear());
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  insert(): void {
    this._componentRef = this.viewContainerRef.createComponent(this.componentType);
    this._componentRef.changeDetectorRef.markForCheck();
  }

  clear(): void {
    this._componentRef.destroy();
  }
}
