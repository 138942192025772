import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { AokToTopScrollerModule } from '../../cdk';
import { AokDialogFooterComponent } from './dialog-footer.component';
import { AokDialogHeaderComponent } from './dialog-header.component';
import { DialogLayoutComponent } from './dialog-layout.component';

const declarations = [DialogLayoutComponent, AokDialogHeaderComponent, AokDialogFooterComponent];

@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule, AokSvgIconComponent, A11yUtilsModule, AokToTopScrollerModule],
})
export class AokDialogLayoutModule {}
