import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { DialogDisposeDirective } from './dialog-dispose.directive';
import { DialogOverlay } from './dialog-overlay';

const declarations = [DialogDisposeDirective];

@NgModule({
  declarations,
  exports: declarations,
  imports: [OverlayModule],
  providers: [DialogOverlay],
})
export class DialogOverlayModule {}
