import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AokPopoverComponent, AokPopoverContentTemplateComponent, AokSvgIconComponent } from '@aok/common';
import { AokMenuButtonModule } from '../../menu-button/menu-button.module';
import { AokTableCellComponent } from './table-cell/table-cell.component';
import { TableTypeComponent } from './table-type/table-type.component';

const declarations = [AokTableCellComponent, TableTypeComponent];

@NgModule({
  declarations: declarations,
  exports: declarations,
  imports: [
    CommonModule,
    AokSvgIconComponent,
    AokMenuButtonModule,
    AokPopoverComponent,
    AokPopoverContentTemplateComponent,
  ],
})
export class AokTableModule {}
