import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import { A11yUtilsModule, DialogBase, DialogRef, RingLoaderModule } from '@aok/common';
import { AokDialogLayoutModule } from '../../dialog-layout/dialog-layout.module';
import { Subject } from 'rxjs';

@Component({
  selector: 'aok-card-reader-progress-dialog',
  templateUrl: './card-reader-progress-dialog.component.html',
  styleUrls: ['./card-reader-progress-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [A11yModule, A11yUtilsModule, AokDialogLayoutModule, RingLoaderModule],
})
export class AokCardReaderProgressDialog implements OnDestroy, DialogBase<void> {
  @Input()
  public stopSubject: Subject<void>;

  private onDestroy = new Subject<void>();

  constructor(public dialogRef: DialogRef<void>) {}

  ngOnDestroy(): void {
    this.onDestroy.unsubscribe();
  }

  public cancel(): void {
    this.stopSubject.next(null);
    this.stopSubject.complete();
    this.dialogRef.dispose();
  }
}
