<div class="search-bar">
  <aok-search-input
    #searchInputComponent
    [searchString]="searchString"
    [label]="label"
    [placeholder]="placeholder"
    (search)="search(null, $event)"
    (inputEvent)="getSearchSuggestions($event)"
    (clearSearch)="suggestions = []"
  ></aok-search-input>

  <div *ngIf="suggestions.length" [ngClass]="{ 'result-page-suggestions aok-container': !isHeaderSearch }">
    <ul class="suggestion-list">
      <li *ngFor="let suggestion of suggestions; let i = index" (click)="search(suggestion)" #listEntry aokFocusable>
        <div class="flex suggestion-entry">
          <aok-svg-icon class="search-icon" name="search" size="18px"></aok-svg-icon>
          <div [innerHtml]="suggestion"></div>
        </div>
      </li>
    </ul>
  </div>
</div>
<ng-content></ng-content>
