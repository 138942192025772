<dialog-layout
  [headerText]="'Patient suchen'"
  [isClosable]="true"
  [main]="false"
  fullscreen="true"
  cdkTrapFocus
  cdkTrapFocusAutoCapture="true"
>
  <div class="patient-detail-search">
    <div class="aok-container">
      <div class="hint tw-col-2-12 lg:tw-col-3-11">
        {{ msgHeaderHint }}
      </div>
    </div>

    <ng-container *ngTemplateOutlet="response ? resultTemplate : requestPage"></ng-container>

    <ng-template #requestPage>
      <div *ngIf="banner" class="aok-container">
        <aok-notification-banner
          [attr.id]="banner.id ? banner.id : undefined"
          class="tw-col-2-12 lg:tw-col-3-11"
          [accent]="banner.bannerAccent || 'bad'"
          [bannerHeadline]="banner.bannerHeadline"
          [bannerLabel]="banner.bannerLabel"
          [bannerLinks]="banner.bannerLinks"
        >
        </aok-notification-banner>
      </div>

      <div class="aok-container">
        <div class="required-label tw-col-2-12 lg:tw-col-3-11 tw-mt-5">
          <aok-required-mark></aok-required-mark>
          Pflichtfeld
        </div>
        <p class="tw-col-2-12 lg:tw-col-3-11 tw-mt-7">Patient mit folgenden Daten ermitteln:</p>
      </div>

      <aok-cockpit-service-request-form
        [searchData]="previousPatientSearchData"
        [showDetailedAddressForm]="showDetailedAddressForm"
        [submitButtonText]="'Patient suchen'"
        [focusFirstChild]="false"
        [identityCheck]="false"
        (patientData)="handlePatientFormData($event)"
        (patientFormErrors)="handlePatientFormError($event)"
      ></aok-cockpit-service-request-form>
    </ng-template>

    <ng-template #resultTemplate>
      <div [attr.id]="resultTableData?.length ? 'patientFound' : undefined" class="aok-container">
        <aok-cockpit-table-type
          *ngFor="let value of resultTableData; trackBy: resultTableTrackByFn"
          class="tw-col-2-12 lg:tw-col-3-11 tw-mt-3"
          [sectionLabel]="value.sectionLabel"
          [values]="value.table"
        >
        </aok-cockpit-table-type>
      </div>

      <div class="aok-container">
        <div class="tw-flex tw-col-2-12 lg:tw-col-3-11 tw-mt-3">
          <button (click)="patientTakeOver()" class="tw-mr-3" id="takeOverButton">Patient übernehmen</button>
          <button (click)="reload()" class="secondary" id="newQueryButton">Neue Abfrage</button>
        </div>
      </div>
    </ng-template>
  </div>
</dialog-layout>
