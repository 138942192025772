import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { A11yUtilsModule } from '@aok/common';
import { AokTabsModule } from '@aok/components';
import { BENEFITS_TAB } from '../../../../schema/benefits.schema';

@Component({
  selector: 'aok-cockpit-benefit-tabs',
  standalone: true,
  imports: [CommonModule, AokTabsModule, RouterLink, RouterLinkActive, A11yUtilsModule],
  templateUrl: './benefit-tabs.component.html',
  styleUrls: ['./benefit-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitTabsComponent {
  @Input() public favoriteCount: number;
  @Input() public currentTab: BENEFITS_TAB;
  @Output() public tabChange = new EventEmitter<BENEFITS_TAB>();

  protected tabs = BENEFITS_TAB;

  public navigateTo(tab: BENEFITS_TAB): void {
    this.tabChange.emit(tab);
  }

  public isActive(tab: BENEFITS_TAB): boolean {
    return this.currentTab === tab;
  }
}
