import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function matchFormFieldsValidator(
  controlName: string,
  matchingControlName: string
): (form: UntypedFormGroup) => ValidationErrors | null {
  return (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    // checks if both controls exist
    if (!control || !matchingControl) return null;

    // check if matchingControl have other errors than 'emailsNotMatching'
    const errors = matchingControl?.errors;
    if (errors && Object.keys(errors).filter((error) => error !== 'emailsNotMatching').length) return null;

    // set error on matchingControl if validation fails
    if (
      !control.value ||
      !matchingControl.value ||
      control.value.toString().toLocaleLowerCase() !== matchingControl.value.toString().toLocaleLowerCase()
    ) {
      matchingControl.setErrors({ emailsNotMatching: true });
      return { emailsNotMatching: true };
    } else {
      matchingControl.setErrors(null);
      return null;
    }
  };
}
