import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DialogBase, DialogOverlayModule, DialogRef } from '@aok/common';
import { AokNotificationBannerModule } from '../../notification-banner/notification-banner.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';

@Component({
  selector: 'aok-cockpit-share-not-available-dialog',
  styleUrls: ['./share-not-available-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'share-not-available-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DialogOverlayModule, AokDialogLayoutModule, A11yModule, AokNotificationBannerModule],
})
export class ShareNotAvailableDialog implements DialogBase<void> {
  @Input() public headline = 'Freigabe verwalten';
  @Input() public description: string;
  @Input() public hint: string;

  constructor(public readonly dialogRef: DialogRef<void>) {}

  public cancel(): void {
    this.dialogRef.dispose();
  }
}
