import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class AokDateAdapter extends NativeDateAdapter {
  // triggered by date overlay: prevent timezone-issues here by creating UTC dates
  createDate(year: number, month: number, date: number): Date {
    return new Date(Date.UTC(year, month, date));
  }

  /**
   * return monday as first day
   */
  getFirstDayOfWeek(): number {
    return 1;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'narrow') {
      return ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'];
    }

    return super.getDayOfWeekNames(style);
  }

  parse(value: string): Date | null {
    if (value.indexOf('.') > -1) {
      const parts: string[] = value.split('.');
      if (parts.length === 3 && parts[2].length > 0) {
        const d: number = this.toInt(parts[0]);
        const m: number = this.toInt(parts[1]) - 1;
        const yyyy: number = this.toFourDigitYear(this.toInt(parts[2]));

        const date: Date = new Date(Date.UTC(yyyy, m, d));
        // special case: if someone entered a future date, we need to go back to the
        // last century. This has been specified as default birthdate picker behaviour
        if (date.getTime() > new Date().getTime()) {
          date.setFullYear(date.getFullYear() - 100);
        }
        return date;
      } else {
        return null;
      }
    } // 8 - digit input
    else if (/^\d{8}$/.test(value)) {
      const valAsString = String(value);
      const d: number = this.toInt(valAsString.substring(0, 2));
      const m: number = this.toInt(valAsString.substring(2, 4)) - 1;
      const yyyy: number = this.toInt(valAsString.substring(4));
      return new Date(Date.UTC(yyyy, m, d));
    }
    return value ? new Date(Date.parse(value)) : null;
  }

  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'MMM YYYY') {
      const a = [{ month: 'short' }, { year: 'numeric' }];
      return this.join(date, a, ' ');
    }
    if (displayFormat === 'MMMM YYYY') {
      const a = [{ month: 'long' }, { year: 'numeric' }];
      return this.join(date, a, ' ');
    }
    if (displayFormat === 'DD.MM.YYYY') {
      const a = [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }];
      return this.join(date, a, '.');
    }
    if (displayFormat === 'LL') {
      const a = [{ day: '2-digit' }, { month: 'short' }, { year: 'numeric' }];
      return this.join(date, a, '.');
    }
    return super.format(date, displayFormat);
  }

  private join(date: Date, a: unknown[], s: string): string {
    function format(m: unknown): string {
      const f = new Intl.DateTimeFormat('de', m);
      return f.format(date);
    }

    return a.map(format).join(s);
  }

  private toInt(value: string): number {
    return parseInt(value, 10);
  }

  private toFourDigitYear(val: number): number {
    if (val > 999 && val < 9999) {
      return val;
    }
    if (val > 99) {
      return Math.round(new Date().getFullYear() / 1000) * 1000 + val;
    }
    if (val > 0) {
      return Math.round(new Date().getFullYear() / 100) * 100 + val;
    }
    // year is unset or out of bounds: we just return the current year
    return new Date().getFullYear();
  }
}

export const AOK_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
