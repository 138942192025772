import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { AokOrgClient } from '../clients/org.client';
import { AokPage, AokOrg, AokCmsOrg } from '../schemas';

export const aokOrgsPageResolver: ResolveFn<AokPage<AokOrg>> = (route: ActivatedRouteSnapshot) => {
  const client = inject(AokOrgClient);
  const { page = 0, size, sort = '', query = '' } = route.queryParams;

  return client.list({ page, size, sort, query });
};

export const aokCmsOrgsPageResolver: ResolveFn<AokPage<AokCmsOrg>> = (route: ActivatedRouteSnapshot) => {
  const client = inject(AokOrgClient);

  const { page = 0, size, sort = 'name,asc', query = '' } = route.queryParams;
  return client.cmsList({ page, size, sort, query });
};
