import { NgModule } from '@angular/core';
import { AokErrorMessageComponent } from './error-message.component';
import { AokSvgIconComponent } from '@aok/common';

@NgModule({
  declarations: [AokErrorMessageComponent],
  exports: [AokErrorMessageComponent],
  imports: [AokSvgIconComponent],
})
export class AokErrorMessageModule {}
