import { InjectionToken, Provider } from '@angular/core';
import { AokContactSchema } from '../schema/aok-contact.schema';

export const AOK_CONTACT = new InjectionToken<AokContactSchema>('AOK_CONTACT');

export const AOK_CONTACT_PROVIDER: Provider = {
  provide: AOK_CONTACT,
  useValue: {
    email: {
      title: 'Support kontaktieren',
      value: 'aok-arztportal@nds.aok.de',
    },
    tel: '0511 285-34679',
  },
};
