<div class="aok-container">
  <div class="container-fixed-width tw-mt-3 tw-mb-3">
    <h3 class="tw-mb-8">Ausfüllhilfe für die Verordnung häuslicher Krankenpflege</h3>
    <p class="tw-mb-6">
      Aktivieren Sie die hervorgehobenen Bereiche mit Maus oder Tastatur, um weitere Hinweise zu erhalten. Die
      Informationen unterstützen Sie dabei, das Formular korrekt auszufüllen und Bearbeitungszeiten zu reduzieren.
    </p>

    <div class="display-options">
      <div class="headline-with-popover-container">
        <h4>Anzeigeoptionen</h4>
        <aok-popover [radius]="popoverRadius" [width]="547" [direction]="popoverRightTop">
          <p class="aok-bold tw-mb-6">Legende</p>

          <div class="display-flex tw-mb-6">
            <aok-svg-icon class="popover-icon question-circle" name="question-circle" size="23"></aok-svg-icon>
            <p>Ausfüllhinweise bieten Informationen und Tipps zu den erforderlichen Angaben an.</p>
          </div>

          <div class="display-flex">
            <aok-svg-icon class="popover-icon" name="question-circle_filled" size="24"></aok-svg-icon>
            <p>
              Hervorgehobene Ausfüllhinweise weisen zusätzlich auf häufig fehlende Angaben hin. Diese führen zu
              Nachfragen seitens der Krankenkasse.
            </p>
          </div>
        </aok-popover>
      </div>

      <div class="checkboxes tw-mt-6">
        <aok-input-field>
          <aok-checkbox [(ngModel)]="highlightRequiredFields" data-testid="hkp-faq-checkbox">
            <p>Häufig fehlende Angaben hervorheben</p>
          </aok-checkbox>
        </aok-input-field>

        <aok-input-field>
          <aok-checkbox [(ngModel)]="showSampleData" data-testid="sample-data-checkbox">
            <p>Beispieldaten anzeigen</p>
          </aok-checkbox>
        </aok-input-field>
      </div>
    </div>

    <div class="form">
      <!--      TODO update name to be back to the old one once also the prefilled resources is in place-->
      <img
        src="assets/img/completion-support{{ showSampleData ? '_edited' : '' }}.png"
        [alt]="showSampleData ? 'Prefilled HKP form image' : 'HKP form image'"
      />

      <aok-popover
        class="absolute popover-1"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        [iconSize]="hkpPopoverIconSize"
        [iconName]="hkpPopoverIconName"
      >
        <p class="aok-bold">Verordnungsrelevante Diagnosen</p>
        <p>
          Hier sind die Diagnose(n) anzugeben, die die Notwendigkeit der häuslichen Krankenpflege medizinisch begründen.
          Die Diagnosen sind nach ICD-10-GM zu verschlüsseln.
        </p>

        <p class="aok-bold tw-mt-8">Häufig fehlende Angabe:</p>
        <p>
          Insbesondere bei der Medikamentengabe ist die Angabe einer nachvollziehbaren Diagnose zwingend erforderlich.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute popover-2"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        [iconSize]="hkpPopoverIconSize"
        [iconName]="hkpPopoverIconName"
      >
        <p class="aok-bold">Einschränkungen</p>
        <p>
          Einschränkungen, die häusliche Krankenpflege erforderlich machen. Damit bestimmte Leistungen als Maßnahmen der
          Behandlungspflege verordnungsfähig sind (z. B. Medikamentengabe, s. c. Injektionen, Blutzucker messen,
          Kompressionsstrümpfe anziehen), muss der Versicherte stark bzw. hochgradig eingeschränkt sein. Die
          Einschränkungen (der Sehfähigkeit, der Grob- und Feinmotorik, der körperlichen bzw. geistigen
          Leistungsfähigkeit) sind dem Leistungskatalog der HKP-Richtlinie zugeordnet und in der Spalte „Bemerkungen“
          erläutert.
        </p>

        <p class="aok-bold tw-mt-8">Häufig fehlende Angabe:</p>
        <p>
          Einschränkungen des Patienten sind genau anzugeben (Demenz, Senilität oder Mobilitätseinschränkungen ist zu
          allgemein)
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-3"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        iconName="question-circle"
        iconSize="22"
      >
        <p class="aok-bold">Erstverordnungen</p>
        <p>
          Erstverordnungen sind in der Regel für längstens 14 Tage auszustellen (§ 5 (1) HKP-RL). Ist eine längere
          Verordnungsdauer erforderlich, soll sich der Grund hierfür aus den verordnungsrelevanten Diagnosen und den
          Einschränkungen ergeben.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-4"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        iconName="question-circle"
        iconSize="22"
      >
        <p class="aok-bold">Folgeverordnungen</p>
        <p>
          Folgeverordnungen (§ 5 (2) HKP-RL) können für einen längeren Zeitraum, auch über das Ende des Quartals hinaus,
          ausgestellt werden. Maßgeblich für den Zeitraum der Verordnung ist der Zustand des Versicherten, den der
          Vertragsarzt überprüft hat. Die Verordnung ist innerhalb der letzten drei Arbeitstage (Montag bis Freitag,
          wenn diese nicht gesetzliche Feiertage sind) vor Ablauf des verordneten Zeitraums auszustellen.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-5"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        iconName="question-circle"
        iconSize="22"
      >
        <p class="aok-bold">Unfall</p>
        <p>
          Ergibt sich die Notwendigkeit der häuslichen Krankenpflege infolge eines Unfalls, ist dies hier anzugeben.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-5-1"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomCenter"
        iconName="question-circle"
        iconSize="22"
      >
        <p class="aok-bold">SER-Feld (Soziales Entschädigungsrecht)</p>
        <p>
          Bei Vorliegen einer anerkannten gesundheitlichen Schädigung (Schädigungsfolge) ist dies entsprechend zu
          kennzeichnen. Darunter werden alle Krankheiten oder gesundheitlichen Beeinträchtigungen verstanden, die im
          Zusammenhang mit Gewalttaten, Kriegsauswirkungen beider Weltkriege, der Ableistung des Zivildienstes und
          Schutzimpfungen oder anderen Maßnahmen der spezifischen Prophylaxe stehen und für die eine Schädigungsfolge
          von der Verwaltungsbehörde anerkannt ist. Vor dem 31.12.2023 anerkannte gesundheitlichen Schädigungen auf
          Grundlage folgender bis dahin geltender Entschädigungsgesetze
        </p>

        <ul class="tw-mt-7">
          <li>Bundesversorgungsgesetz (BVG),</li>
          <li>Infektionsschutzgesetz (IfSG),</li>
          <li>Opferentschädigungsgesetz (OEG),</li>
          <li>Soldatenversorgungsgesetz (SVG) sowie</li>
          <li>Zivildienstgesetz (ZDG)</li>
        </ul>

        <p class="tw-mt-7">
          erhalten ebenfalls diese Kennzeichnung. Der Patient hat dem verordnenden Vertragsarzt einen Nachweis über die
          anerkannte Schädigungsfolge bei der Inanspruchnahme von Leistungen vorzulegen. Eine Verordnung im Rahmen des
          Sozialen Entschädigungsrechts ist auf die anerkannte Schädigungsfolge beschränkt und von der Zuzahlung
          befreit.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-6"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomLeft"
        iconName="question-circle"
        iconSize="22"
      >
        <p class="aok-bold">Verordnungszeitraum</p>
        <p>
          Angabe des Zeitraums, in dem die Maßnahme erbracht wird. Rückwirkende Verordnungen sind nicht zulässig.
          Ausnahmefälle sind besonders zu begründen.
        </p>

        <p class="aok-bold tw-mt-7">Keine „Blanko-Verordnung“:</p>
        <p>Häufigkeit und Dauer werden vom Arzt festgelegt.</p>

        <p class="aok-bold tw-mt-7">„Blanko-Verordnung“:</p>
        <p>
          Maßnahmen, bei denen gekennzeichnet ist, dass die Pflegefachkraft die Häufigkeit und Dauer selbst bestimmen
          soll. Die Angabe wird auf der Verordnung von der Vertragsärztin oder Vertragsarzt offen gelassen und ist dann
          ausschließlich durch eine qualifizierte Pflegefachkraft unter dem Punkt: Abweichung von Dauer der gesamten
          Verordnung einzutragen.
        </p>

        <p class="aok-bold tw-mt-7">Bitte um Beachtung:</p>
        <p>
          Für bestimmte Positionen können in der häuslichen Krankenpflege Langzeitverordnungen (bis zu 12 Monate) und
          auch abweichend vom Quartal und sogar über den Jahreswechsel verordnet werden. Die
          <a
            class="aok-link-1"
            aokFocusable
            (click)="scrollToAnchor('tooltip6')"
            (keyup.enter)="scrollToAnchor('tooltip6')"
          >
            Positionen und Beispielzeiträume
          </a>
          finden Sie unten stehend.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-7"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        iconName="question-circle"
        iconSize="22"
      >
        <p class="aok-bold">Behandlungspflege</p>
        <p>
          Behandlungspflege als Sicherungspflege hat das Ziel, die ambulante ärztliche Behandlung zu ermöglichen und
          deren Ergebnis zu sichern. Grundsätzlich sind alle verordnungsfähigen Maßnahmen (Nr. 6 bis Nr. 31) der HKP-RL
          im Leistungsverzeichnis aufgeführt.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute popover-8"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        [iconSize]="hkpPopoverIconSize"
        [iconName]="hkpPopoverIconName"
      >
        <p class="aok-bold">Medikamentengabe</p>
        <p>
          Bei Medikamentengabe (Nr. 26 der HKP-RL) ist neben den einzelnen zu verabreichenden Präparaten die Dauer und
          Häufigkeit der Gabe anzugeben. Die Angaben zu den Präparaten können alternativ auf einem gesonderten Dokument
          als Anlage zur Verordnung erfolgen. Vorab ist immer zu bewerten, ob:
        </p>

        <p>- das Richten in einer Tages- oder Wochenbox ausreichend ist (Herrichten der Medikamentenbox),</p>

        <p class="tw-mt-8">
          - Personen im Haushalt sind, die die Leistung (teilweise) übernehmen können, - durch Medikamentenanpassung (z.
          B. Verordnung von Depotpräparaten) tägliche Einsätze reduziert werden können. Bei s. c. Injektionen ist zu
          begründen, warum der Patient die Injektion nicht selbst durchführen kann (vgl. "Einschränkungen").
        </p>

        <p class="aok-bold tw-mt-8">Häufig fehlende Angaben:</p>
        <ul>
          <li>Medikamentenplan wird nicht beigefügt</li>
          <li>Angabe der zu verabreichenden Präparate</li>
          <li>bei Injektionen fehlen häufig die Insulinart und die zu injizierenden Präparate</li>
        </ul>
      </aok-popover>

      <aok-popover
        class="absolute popover-9"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomLeft"
        [iconSize]="hkpPopoverIconSize"
        [iconName]="hkpPopoverIconName"
      >
        <p class="aok-bold">Häufigkeit/Dauer</p>
        <p>
          Die Angaben der Häufigkeit (täglich, wöchentlich, monatlich) und Dauer (vom - bis) orientieren sich am
          Leistungsverzeichnis der HKP-Richtlinie.
        </p>
        <p class="tw-mt-7">
          Sofern die Pflegefachkraft die Häufigkeit und Dauer festlegen soll, dürfen in diesen Feldern von der
          Vertragsärztin oder Vertragsarzt keine Angaben eingetragen werden.
        </p>

        <p class="aok-bold tw-mt-7">Häufig fehlende Angaben:</p>
        <ul>
          <li>
            Leistungen/Verrichtungen nicht mehr aktuell, es werden noch Leistungen verordnet, die der Pflegedienst nicht
            mehr erbringt.
          </li>
          <li>
            Fehlende Frequenzen oder alter Stand (es muss sowohl die tägliche als auch die wöchentliche Frequenz
            angegeben werden).
          </li>
        </ul>
      </aok-popover>

      <aok-popover
        class="absolute popover-9-1"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverLeftTop"
        iconName="question-circle"
        iconSize="22"
      >
        <p class="aok-bold">Häufigkeit/Dauer von Pflegefachkraft</p>
        <p>
          Die Verordnung wird vom Arzt ausgestellt und ein Vermerk vorgenommen, dass die Häufigkeit und Dauer durch eine
          qualifizierte Pflegefachkraft entschieden werden darf. Sofern jedoch wichtige medizinische Gründe vorliegen,
          die gegen eine Bestimmung der erforderlichen Häufigkeit und Dauer durch die Pflegefachkraft sprechen, hat die
          Vertragsärztin oder Vertragsarzt die Häufigkeit und Dauer selber auf der Verordnung anzugeben.
        </p>
        <p class="tw-mt-7">
          Eine
          <a
            class="aok-link-1"
            aokFocusable
            (click)="scrollToAnchor('tooltip9-1')"
            (keyup.enter)="scrollToAnchor('tooltip9-1')"
          >
            Blanko-Verordnung
          </a>
          ist für nachfolgende Positionen möglich.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute popover-10"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        [iconSize]="hkpPopoverIconSize"
        [iconName]="hkpPopoverIconName"
      >
        <p>
          In der Regel trifft nur eine der beiden Verrichtungen "Medikamentengabe" oder "Medikamentenbox richten" zu.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-11"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        iconName="question-circle"
        iconSize="22"
      >
        <p class="aok-bold">Blutzuckermessung</p>
        <p>
          Blutzuckermessung (Nr. 11 der HKP-RL) kann bei Erst- und Neueinstellung eines (insulin- oder
          tablettenpflichtigen) Diabetes für max. 3x täglich über max. 4 Wochen verordnet werden. Bei der
          Folgeverordnung ist der HbA 1c Wert zu berücksichtigen (siehe "Medikamentengabe"). Routinemäßige
          Dauermessungen sind nur zur Fortsetzung der intensivierten Insulintherapie möglich.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute popover-12"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        [iconSize]="hkpPopoverIconSize"
        [iconName]="hkpPopoverIconName"
      >
        <p class="aok-bold inline">Kompressionsbehandlung</p>
        &nbsp;
        <span>(Nr. 31 der HKP-RL)</span>

        <p>
          Die Kompressionsbehandlung ist im Rahmen der häuslichen Krankenpflege ab Kompressionsklasse I
          verordnungsfähig. Das Anlegen eines Kompressionsverbandes ist grundsätzlich 1 x täglich verordnungsfähig.
          Unter diesem Punkt ist auch das An- u. Ausziehen von Kompressionsstrümpfen zu verordnen. Das Anlegen von
          stützenden und stabilisierenden Verbänden kommt nur bei akuten Ereignissen (z.B. Distorsion) in Betracht und
          ist deshalb zeitlich nur begrenzt verordnungsfähig. Das Anlegen von Orthesen, Prothesen und Bandagen ist der
          Grundpflege (Körperpflege) zuzuordnen (siehe "Grundpflege und hauswirtschaftliche Versorgung").
        </p>

        <p class="aok-bold tw-mt-8">Häufig fehlende Angaben:</p>

        <ul>
          <li>Angabe der Körperseite (links/rechts/beidseitig)</li>
          <li>nachvollziehbare Diagnose, warum eine Kompressionstherapie erfolgen muss</li>
          <li>
            Einschränkungen des Patienten sind genau anzugeben (Demenz, Senilität oder Mobilitätseinschränkungen ist zu
            allgemein)
          </li>
        </ul>
      </aok-popover>

      <aok-popover
        class="absolute popover-13"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        [iconSize]="hkpPopoverIconSize"
        [iconName]="hkpPopoverIconName"
      >
        <p class="aok-bold inline">Wundversorgung</p>
        &nbsp;
        <span>(Nr. 31 der HKP-RL)</span>

        <p class="tw-mb-8">
          Werden Leistungen der Wundversorgung verordnet, sind der aktuelle Wundbefund, die Lokalisation, Art der
          Wundbehandlung und die einzelnen
        </p>

        <p>
          Präparate sowie Dauer und Häufigkeit der Versorgung anzugeben. Angaben zu den Präparaten sowie zum Wundbefund
          können alternativ auf einem gesonderten Dokument als Anlage zur Verordnung erfolgen.
        </p>

        <p class="aok-bold tw-mt-8">Häufig fehlende Angaben/ nicht beachtete Vorgaben:</p>

        <ul>
          <li>Verordnung ist maximal für einen Zeitraum von 28 Tagen möglich</li>
          <li>keine oder unvollständige Angaben zu Wunden (Größe der Wunde, Länge, Breite, Tiefe, Lokalisation)</li>
          <li>
            Angaben müssen bei Folgeverordnungen aktualisiert werden (Anzahl der Frequenz/ aktueller Wundstatus inkl.
            Größe und Grad)
          </li>
          <li>keine Angaben zum Wundmaterial</li>

          <li>keine Angaben ob akute oder chronische Wunde</li>
        </ul>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-14"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        iconSize="22"
        iconName="question-circle"
      >
        <p class="aok-bold">Sonstige Maßnahmen der Behandlungspflege</p>

        <p>
          Hier können weitere, nicht auf der HKP-Verordnung aufgeführte Maßnahmen der Behandlungspflege (z. B.
          MRSA-Sanierung (Nr. 26a), spez. Krankenbeobachtung, (Nr. 24) oder Bedienung und Überwachung des
          Beatmungsgerätes (Nr.8) verordnet werden. Hinweis zu den Nummern 8 und 24 der HKP-RL: Hier ist der zeitliche
          Bedarf (Angabe in Stunden) anzugeben. Dabei ist der Zeitanteil, der von im Haushalt lebenden Personen
          geleistet wird, in Abzug zu bringen.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-15"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        iconSize="22"
        iconName="question-circle"
      >
        <p class="aok-bold inline">Anleitung zur Behandlungspflege</p>
        &nbsp;
        <span>(Nr. 7 der HKP-RL)</span>

        <p>
          Anleitung zu Maßnahmen der Behandlungspflege der Versicherten und/oder Angehörigen durch den Pflegedienst, ist
          unter Nennung der Maßnahmen anzugeben.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-16"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverLeftCenter"
        iconSize="22"
        iconName="question-circle"
      >
        <p class="aok-bold">Grundpflege und hauswirtschaftliche Versorgung</p>

        <p>
          Grundpflege und hauswirtschaftliche Versorgung können nur im Rahmen der Unterstützungspflege oder der
          Krankenhausvermeidungspflege (vgl. "Unterstützungspflege", "Krankenhausvermeidungspflege") verordnet werden.
          Im Rahmen der Sicherungspflege setzt eine Verordnung von Grundpflege und hauswirtschaftlicher Versorgung
          voraus, dass die Satzung der AOK diese Leistungen vorsieht und soweit keine Pflegebedürftigkeit mit Pflegegrad
          2, 3, 4 oder 5 nach dem SGB XI vorliegt.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-17"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverRightCenter"
        iconSize="22"
        iconName="question-circle"
      >
        <p class="aok-bold inline">Unterstützungspflege</p>
        &nbsp;
        <span>(§ 37 Abs. 1a SGB V)</span>

        <p>
          Die Verordnung von Unterstützungspflege ist bei schwerer Krankheit oder wegen akuter Verschlimmerung einer
          Krankheit, insbesondere nach a4 einem Krankenhausaufenthalt, nach einer ambulanten Operation oder nach einer
          ambulanten Krankenhausbehandlung möglich, soweit keine
        </p>

        <p class="tw-mt-8">
          Pflegebedürftigkeit mit Pflegegrad 2, 3, 4 oder 5 nach dem SGB XI vorliegt. Unterstützungspflege umfasst
          Grundpflege und ggf. hauswirtschaftliche Versorgung. Der Anspruch auf Unterstützungspflege besteht maximal bis
          zu vier Wochen je Krankheitsfall und kann von der Krankenkasse in begründeten Ausnahmefällen nach Einschaltung
          des MDK verlängert werden.
        </p>
        <p class="tw-mt-8">
          Wenn die Voraussetzungen der Unterstützungspflege erfüllt sind, muss zusätzlich angegeben werden, ob
          Grundpflege und ggf. hauswirt- schaftliche Versorgung erbracht werden sollen.
        </p>

        <p class="tw-mt-8">
          Leistungen der hauswirtschaftlichen Versorgung können im Rahmen der Unterstützungspflege nicht eigenständig,
          sondern nur im Zusammenhang mit der erforderlichen Grundpflege verordnet werden. Grundpflege kann im Rahmen
          der Unterstützungspflege eigenständig verordnet werden.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-18"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverRightCenter"
        iconSize="22"
        iconName="question-circle"
      >
        <p class="aok-bold inline">Krankenhausvermeidungspflege</p>
        &nbsp;
        <span>(§ 37 Abs. 1 SGB V)</span>

        <p>
          Häusliche Krankenpflege als Krankenhausvermeidungspflege kann verordnet werden, wenn Krankenhausbehandlung
          geboten, aber nicht ausführbar ist oder vermieden werden kann. Die Krankenhausvermeidungspflege umfasst
          Behandlungs- und Grundpflege sowie ggfs.
        </p>

        <p class="tw-mt-8">
          hauswirtschaftliche Versorgung. Der Anspruch der Versicherten auf Krankenhausvermeidungspflege ist maximal auf
          vier Wochen begrenzt und kann von der Krankenkasse in begründeten Ausnahmefällen nach Einschaltung des MDK
          verlängert werden.
        </p>
        <p class="tw-mt-8">
          Wenn die Voraussetzungen der Krankenhausvermeidungspflege erfüllt sind, kann zusätzlich angegeben werden, ob
          neben der Behandlungspflege auch Grundpflege und/oder hauswirtschaftliche Versorgung notwendig sind.
        </p>
      </aok-popover>

      <aok-popover
        class="absolute question-circle popover-19"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverBottomRight"
        [iconSize]="hkpPopoverIconSize"
        [iconName]="hkpPopoverIconName"
      >
        <p class="aok-bold">Weitere Hinweise</p>

        <p>
          Im vorgesehenen Freitextfeld im unteren Formularabschnitt kann die verordnende Ärztin/der verordnende Arzt
          weitere Hinweise zur Verordnung (Behandlungspflege, Grundpflege oder hauswirtschaftliche Versorgung) geben.
        </p>

        <p class="aok-bold tw-mt-8">Häufig fehlende Angaben:</p>
        <p>Zusätzliche relevante Hinweise und Hintergrundinformationen zur Verordnung</p>
      </aok-popover>

      <aok-popover
        class="absolute popover-20"
        [radius]="popoverRadius"
        [width]="popoverContainerWidth"
        [direction]="popoverTopLeft"
        [iconSize]="hkpPopoverIconSize"
        [iconName]="hkpPopoverIconName"
      >
        <p>
          Die Unterschrift durch den Arzt ist zwingend erforderlich. Bei fehlender Unterschrift wird die Verordnung an
          den Verordner zurückgeschickt.
        </p>
      </aok-popover>
    </div>

    <div class="information">
      <h3 id="tooltip6" class="tw-mt-[56px]">Mögliche Positionen einer Langzeitverordnung</h3>
      <ul class="custom-list tw-mt-9">
        <li data-bullet="18">Injektion s.c.</li>
        <li data-bullet="19">Richten von Injektionen</li>
        <li data-bullet="23">Katheterisierung</li>
        <li data-bullet="26.2">Verabreichung/Eingeben von Medikamenten</li>
        <li data-bullet="26.3">Richten von Medikamenten</li>
        <li data-bullet="31.5">Kompressionsstrümpfe/-strumpfhosen an- bzw. ausziehen</li>
      </ul>

      <h3 class="tw-mt-[36px]">Beispielzeiträume einer Langzeitverordnung</h3>
      <ul class="tw-mt-9">
        <li>25.03.2024 - 13.11.2024 (33 Wochen)</li>
        <li>17.06.2024 - 10.06.2025 (51 Wochen)</li>
        <li>18.07.2024 - 17.07.2025 (12 Monate)</li>
        <li>19.11.2024 - 18.05.2025 (6 Monate)</li>
      </ul>
      <p>Alle Konstellationen sind möglich. Sie haben die Wahl.</p>

      <h3 id="tooltip9-1" class="tw-mt-[43px]">Mögliche Positionen einer Blanko-Verordnung</h3>
      <p class="tw-mt-7">
        Soll die Häufigkeit und Dauer durch die Pflegefachkraft bestimmt werden sollen, ist dies ärztlich durch
        Ankreuzen auf der Verordnung kenntlich zu machen. Dies ist für folgende Leistungen möglich:
      </p>

      <ul class="custom-list tw-mt-11">
        <li data-bullet="1">Anleitung bei der Grundpflege in der Häuslichkeit</li>
        <li data-bullet="2">Ausscheidungen</li>
        <li data-bullet="3">Ernährung (nur orale Verabreichung)</li>
        <li data-bullet="4">Körperpflege</li>
        <li data-bullet="5">Hauswirtschaftliche Versorgung</li>
        <li data-bullet="6">Absaugen (nur Absaugen der oberen Luftwege)</li>
        <li data-bullet="7">Anleitung bei der Behandlungspflege</li>
        <li data-bullet="12">Positionswechsel zur Dekubitusbehandlung</li>
        <li data-bullet="13">Drainagen (Überprüfen, Versorgen)</li>
        <li data-bullet="14">Einlauf / Klistier / Klysma /digitale Enddarmausräumung</li>
        <li data-bullet="21">Auflegen von Kälteträgern</li>
        <li data-bullet="22">Versorgung eines suprapubischen Katheters</li>
        <li data-bullet="23">Katheterisierung der Harnblase</li>
        <li data-bullet="27">Perkutane endoskopische Gastrostomie (PEG)</li>
        <li data-bullet="28">Stomabehandlung</li>
        <li data-bullet="30">Pflege des zentralen Venenkatheters</li>
        <li data-bullet="31">Wundversorgung einer akuten Wunde</li>
        <li data-bullet="31b">Kompressionsstrümpfe/ Kompressionsverband</li>
        <li data-bullet="31c">Stützende Verbände</li>
        <li data-bullet="31d">Bandagen und Orthesen</li>
      </ul>

      <p class="tw-mt-5">
        Die im HKP-Leistungsverzeichnis zu einigen Leistungen angegebenen Empfehlungen des G-BA zur Häufigkeit und Dauer
        der Maßnahmen gelten analog auch für die Bestimmung durch qualifizierte Pflegefachkräfte.
      </p>
    </div>
  </div>
</div>
