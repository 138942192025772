import { DMPCaseManagementCounts } from '../schemas';

export function calculateSumOfObjectProperties(obj: DMPCaseManagementCounts): number {
  let sum = 0;

  for (const key in obj) {
    if (typeof obj[key as keyof DMPCaseManagementCounts] === 'number') {
      sum += obj[key as keyof DMPCaseManagementCounts];
    }
  }

  return sum;
}
