import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AokUserRegistrationClient, CurrentUserState, KnownUserType } from '@aok/common';
import { KvnState } from '@aok/components';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const allowDirectRegistrationResolver: ResolveFn<boolean> = () => {
  const kvnState = inject(KvnState);
  const registrationClient = inject(AokUserRegistrationClient);
  const currentUser = inject(CurrentUserState);

  return (
    kvnState.isKvn() &&
    currentUser.snapshot.userType !== KnownUserType.Full_Kvn_Doctor &&
    registrationClient.getSessionRegistration().pipe(
      map(() => false),
      catchError(() => of(true))
    )
  );
};
