import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { AokPodcastClient } from '../clients';
import { AokEpisode } from '../schemas';

export const aokPodcastsPageResolver: ResolveFn<AokEpisode> = (route: ActivatedRouteSnapshot) => {
  const client = inject(AokPodcastClient);

  const { page = 0, size = 10 } = route.queryParams;
  return client.list({ page_size: size, offset: page * size });
};
