import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AokInsuranceStatusClient, AokInsuree, AokServiceRequest, DialogBase, DialogRef, TableData } from '@aok/common';
import {
  AokDialogLayoutModule,
  AokNotificationBannerModule,
  AokTableModule,
  RequiredMarkModule,
} from '@aok/components';
import { getInsuranceDataTable, getPersonalResultTable, ServicePagesLabels } from '../../../utils/service-pages.util';
import { ServicePageComponent } from '../service-page.component';
import { ErrorBannerService } from '../service-request-form/error-banner.service';
import { ServiceRequestFormComponent } from '../service-request-form/service-request-form.component';

@Component({
  selector: 'aok-cockpit-patient-detail-search-dialog',
  templateUrl: './patient-detail-search-dialog.component.html',
  styleUrls: ['./patient-detail-search-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AokDialogLayoutModule,
    A11yModule,
    AokNotificationBannerModule,
    RequiredMarkModule,
    ServiceRequestFormComponent,
    AokTableModule,
  ],
})
export class PatientDetailSearchDialog
  extends ServicePageComponent<AokInsuree>
  implements AfterViewInit, DialogBase<AokInsuree>
{
  @ViewChild(ServiceRequestFormComponent) serviceRequestForm: ServiceRequestFormComponent;

  public msgHeaderHint = 'Nach Anpassung der Daten wird der Patient in die Sammeldruckansicht übernommen.';

  public resultTableData: TableData[] = [];

  constructor(
    public readonly dialogRef: DialogRef<AokInsuree>,
    protected client: AokInsuranceStatusClient,
    protected errorBannerService: ErrorBannerService,
    protected cd: ChangeDetectorRef
  ) {
    super(client, errorBannerService, cd);
  }

  ngAfterViewInit(): void {
    this.serviceRequestForm.invalidCheck();
    if (!this.banner) {
      this.handlePatientFormData({ ...this.previousPatientSearchData, checkboxIdentityCheck: true });
    }
  }

  public handlePatientFormData(request: AokServiceRequest): void {
    this.previousPatientSearchData = request;

    const invoiceDate = new Date();
    this.client.check({ ...request, invoiceDate }).subscribe({
      next: this.handleResponse(),
      error: this.handleError(),
    });
  }

  public handlePatientFormError(errors: { id: string; label: string }[]): void {
    this.handleErrorBanner(errors);
  }

  public patientTakeOver(): void {
    this.dialogRef.dispose(this.response);
  }

  public prepareResultTableData(): void {
    this.resultTableData = [
      {
        sectionLabel: ServicePagesLabels.PATIENT_DATA_SECTION,
        table: getPersonalResultTable(this.response),
      },
      {
        sectionLabel: ServicePagesLabels.INSURANCE_DATA_SECTION,
        table: getInsuranceDataTable(this.response),
      },
    ];
  }
}
