import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AokLoaderInterceptor } from './loader.interceptor';

@NgModule()
export class AokLoaderInterceptorModule {
  static forRoot(): ModuleWithProviders<AokLoaderInterceptorModule> {
    return {
      ngModule: AokLoaderInterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AokLoaderInterceptor,
          multi: true,
        },
      ],
    };
  }
}
