import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AokNotificationService, AokReminderService, DialogOverlay } from '@aok/common';
import { AokNotificationSidebarComponent } from '../components';

@Injectable({ providedIn: 'root' })
export class NotificationSidebarService {
  constructor(
    private dialog: DialogOverlay,
    private reminderService: AokReminderService,
    private notificationService: AokNotificationService
  ) {}

  public openNotificationSidebar(): Observable<any> {
    return forkJoin({
      notifications: this.notificationService.retrieveNotifications(),
      reminders: this.reminderService.retrieveReminders(),
    }).pipe(
      tap({
        next: () => {
          this.displayNotificationSidebar();
        },
      }),
      catchError((x) => {
        return of(x);
      })
    );
  }

  /**
   * display the notification sidebar component
   */
  public displayNotificationSidebar(): void {
    this.dialog.create(AokNotificationSidebarComponent, {
      closable: true,
      disposeOnBackdropClick: true,
      overlay: {
        panelClass: 'aok-notification-sidebar-panel',
      },
    });
  }
}
