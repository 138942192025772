<dialog-layout fullscreen="false" [isClosable]="false" aokFocusFirstChild [autoBlur]="true" cdkTrapFocus data-testid="cookie-banner">
  <dialog-header>
    <p class="aok-text-l2">Datenschutzhinweis: Die AOK nutzt Cookies</p>
    <h4 class="tw-mt-11">Wir bitten um Ihre Zustimmung</h4>
  </dialog-header>

  <ng-template #introTemplate>
    <p>
      Die Seite www.arztportal-niedersachsen.de nutzt eigene Cookies, um die Seite bereitstellen zu können („funktionale
      Cookies“) und Marketing-Cookies mit deren Hilfe wir die Inhalte und Services unserer Website besser an Ihre
      Interessen und Ihre Nutzungsweise anpassen und unser Angebot für Sie verbessern können.
    </p>

    <p>
      Mit einem Klick auf die Schaltfläche „Alle akzeptieren“ erklären Sie sich damit einverstanden. Ihr Einverständnis
      kann jederzeit mit Wirkung für die Zukunft widerrufen oder geändert werden. Funktionale Cookies werden auch ohne
      Ihr Einverständnis ausgeführt.
    </p>

    <p>
      <a
        class="aok-link-1"
        (click)="acceptAndClose(false, hoursToExpire)"
        (keyup.enter)="acceptAndClose(false, hoursToExpire)"
        aokFocusable
      >
        Mit ausschließlich funktionalen Cookies fortfahren.
      </a>
    </p>

    <p>
      Mit einem Klick auf die Schaltfläche „Auswählen“ können persönliche Einstellungen vorgenommen werden. Unter

      <a target="_blank" [href]="safeUrl('/datenschutz')">Datenschutz</a>

      informieren wir ausführlich über Art und Umfang der Datenverarbeitung sowie Ihre Rechte. Weitere Informationen
      finden Sie unter

      <a target="_blank" [href]="safeUrl('/impressum')"> Impressum</a> und
      <a target="_blank" [href]="safeUrl('/erklaerung-zur-barrierefreiheit')">Barrierefreiheit</a>.
    </p>
  </ng-template>

  <div *ngIf="viewCustomPreferences.asObservable() | async; else introTemplate">
    <div>
      <p>Damit Sie diese Webseite bestmöglich nutzen können, setzen wir zwei Arten von Cookies ein.</p>

      <div class="accordionContainer">
        <div *ngFor="let option of options; let i = index">
          <aok-accordion-option [option]="option" [index]="i" [accordionId]="option.id">
            <div label>
              <ng-container *ngIf="option.id === FUNCTIONAL_COOKIES">
                <aok-checkbox #ckbx class="disabled" [formControl]="this.form.get(FUNCTIONAL_COOKIES)">
                  <label>{{ option.customLabel }}</label>
                </aok-checkbox>
              </ng-container>

              <ng-container *ngIf="option.id === MARKETING_COOKIES">
                <aok-checkbox
                  (click)="$event.stopPropagation()"
                  (keyup.enter)="$event.stopPropagation()"
                  [formControl]="this.form.get(MARKETING_COOKIES)"
                >
                  <label>{{ option.customLabel }}</label>
                </aok-checkbox>
              </ng-container>
            </div>
            <div content [innerHTML]="option.customContent"></div>
          </aok-accordion-option>
        </div>
      </div>

      <p class="tw-mb-7">Bitte beachten Sie:</p>

      <ul>
        <li>
          Sie können Ihre Zustimmung jederzeit unter
          <a [href]="safeUrl('/datenschutz')" target="_blank"> Datenschutz</a> widerrufen oder Ihre Einstellungen erneut
          anpassen.
        </li>
        <li>Funktionale Cookies sind auch ohne Ihre Zustimmung aktiv, da dieses technisch erforderlich sind.</li>
        <li>
          Weitere Informationen finden Sie unter <a [href]="safeUrl('/impressum')" target="_blank">Impressum</a> und
          <a [href]="safeUrl('/erklaerung-zur-barrierefreiheit')" target="_blank">Barrierefreiheit</a>
        </li>
      </ul>
    </div>
  </div>

  <dialog-footer>
    <button
      *ngIf="!viewCustomPreferences.snapshot"
      class="secondary"
      (click)="patchValue(true)"
      title="Auswählen"
      data-testid="choose-cookies-btn"
    >
      Auswählen
    </button>

    <button
      *ngIf="viewCustomPreferences.snapshot"
      class="secondary"
      (click)="acceptAndClose(false)"
      title="Ausgewählte akzeptieren"
      data-testid="accept-selected-cookies-btn"
    >
      Ausgewählte akzeptieren
    </button>

    <button (click)="acceptAndClose(true)" title="Alle akzeptieren" data-testid="accept-all-cookies-btn">
      Alle akzeptieren
    </button>
  </dialog-footer>
</dialog-layout>
