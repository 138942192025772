import { inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { KEYCLOAK_OPTIONS } from './keycloak.initializer';

// TODO needs to be adjusted as currently is the same check as for keycloakInitGuard
/**
 * initialize keycloak if the user is already logged in
 * the guard is meant to be used on public pages that display some extra information when the user is logged in (e.g. navigation)
 */
export const keycloakInitIfLoggedGuard = (): Observable<boolean> => {
  const keycloak = inject(KeycloakService);
  const options = inject(KEYCLOAK_OPTIONS);

  return from(keycloak.isLoggedIn()).pipe(
    mergeMap((isLoggedIn) => (isLoggedIn ? of(true) : from(keycloak.init(options))))
  );
};
