import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { checkTIConnection, isTIAccessible } from '@aok/common';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class TIInterceptor implements HttpInterceptor {
  constructor(private router: Router, private http: HttpClient) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!isTIAccessible()) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status !== 0) {
          checkTIConnection(this.http, environment.tiConfig.baseUrl).subscribe((connectionAvailable) => {
            if (!connectionAvailable) {
              this.router.navigate(['ti-fehler']);
            }
          });
        }
        throw error;
      })
    );
  }
}
