import { UntypedFormControl, ValidationErrors } from '@angular/forms';

/**
 * checks if the value of the controller equal with one word of the forbiddenTerms.
 * If yes, then the controller receive an error with the errorKey. Use the errorKey to selects the errorMsg at app-error-msg-providers
 * @param forbiddenTerms
 * @param errorKey
 */
export function forbiddenTermsValidator(forbiddenTerms: string[], errorKey: 'alreadyRegistered') {
  return (control: UntypedFormControl): ValidationErrors => {
    if (forbiddenTerms.some((value) => control.value === value)) return { [errorKey]: true };
    return null;
  };
}
