import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AokFooterModule, FeedbackButtonModule, HeaderModule, InfoBannerComponent } from '@aok/components';

@Component({
  selector: 'aok-cockpit-dmp-case-management',
  templateUrl: './dmp-case-management.component.html',
  styleUrls: ['./dmp-case-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderModule, InfoBannerComponent, RouterOutlet, FeedbackButtonModule, AokFooterModule],
})
export class DmpCaseManagementComponent {}
