import { Directive, Input, Predicate, TemplateRef } from '@angular/core';
import { NavigationEntry } from './navigation-entry.schema';

export interface NavigationEntryDefContext {
  $implicit: NavigationEntry;
}

@Directive({ selector: '[aokNavEntryDef]' })
export class NavigationEntryDefDirective {
  @Input('aokNavEntryDefWhen') when: Predicate<NavigationEntry>;

  constructor(readonly template: TemplateRef<NavigationEntryDefContext>) {}
}
