import { AokPageQueryParams } from './page.schema';

export enum ContentType {
  PAGE = 'PAGE',
  DOCUMENT = 'DOCUMENT',
  MULTIMEDIA_DOCUMENT = 'MULTIMEDIA_DOCUMENT',
  ARTICLE = 'ARTICLE',
  BENEFIT = 'BENEFIT',
  PODCAST = 'PODCAST',
  SVS_HZV_DOCUMENT = 'SVS_HZV_DOCUMENT',
}

export interface AokSearchRequestOptions {
  documentType?: ContentType;
  enableHighlights?: boolean;
}
export interface AokSearchSuggestionsOptions extends AokSearchRequestOptions {
  query: string;
}

export interface AokSearchOptions extends AokPageQueryParams, AokSearchRequestOptions {}

export interface AokSearchResult {
  documentId: number;
  title?: string;
  validFrom?: string;
  validUntil?: string;
  createdDate?: number;
  categories?: string[]; // breadcrumbs
  url?: string; // actual link
  content?: string;
  contentType?: string; // enum
  language?: string;
  userRole?: string;
  conditions?: string[];
  keywords?: string[];
  source?: string; // for icon
  highlights?: AokHighlight[];
}

export interface AokBenefitSearchResult extends AokSearchResult {
  benefitFavorite?: boolean; //FE only
  benefitCategory: string;
  benefitRegion: string;
  benefitIndications: string[];
}

export interface AokHighlight {
  [key: string]: string[];
}

export interface AokBenefitSearchOptions extends AokSearchOptions {
  category?: string;
  region?: string;
}
