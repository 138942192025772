import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { A11yUtilsModule, AokPopoverComponent, AokSvgIconComponent, AokUserSalutationIntlPipe } from '@aok/common';
import { AokHintModule, AokInputFieldModule, AokToTopScrollerModule, RequiredMarkModule } from '../../cdk';
import { AokCheckboxModule } from '../../checkbox/checkbox.module';
import { AokDropdownModule } from '../../dropdown/dropdown.module';
import { FormControlErrorModule } from '../../form-control-error/module';
import { AokEmailErrorMessageModule } from '../../form-error-message/email-error-message/email-error-message.module';
import { AokRequiredErrorMessageModule } from '../../form-error-message/required-error-message/required-error-message.module';
import { AokNotificationBannerModule } from '../../notification-banner/notification-banner.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { AokMfaDialog } from './mfa-dialog.component';
import { AokMfaDialogDirective } from './mfa-dialog.directive';

@NgModule({
  declarations: [AokMfaDialog, AokMfaDialogDirective],
  exports: [AokMfaDialog, AokMfaDialogDirective],
  imports: [
    FormControlErrorModule,
    AokRequiredErrorMessageModule,
    AokEmailErrorMessageModule,
    ReactiveFormsModule,
    CommonModule,
    AokHintModule,
    AokInputFieldModule,
    AokNotificationBannerModule,
    A11yUtilsModule,
    RequiredMarkModule,
    AokSvgIconComponent,
    AokDialogLayoutModule,
    AokPopoverComponent,
    AokDropdownModule,
    AokCheckboxModule,
    AokToTopScrollerModule,
    A11yModule,
  ],
  providers: [AokUserSalutationIntlPipe],
})
export class AokMfaDialogModule {}
