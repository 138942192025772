<aok-svg-icon
  *ngIf="steps.length > nrOfStepsDisplayed && currentSlide !== 0"
  [id]="'arrow-left_circle'"
  class="aok-link-1"
  [size]="26"
  aokFocusable
  (click)="carouselBack()"
  (keyup.enter)="carouselBack()"
></aok-svg-icon>
<div class="steps-carousel-wrapper">
  <ul class="steps-carousel" #stepsCarousel>
    <ng-container *ngFor="let step of steps; let i = index">
      <li
        aokFocusable
        (keyup.enter)="selectStep.emit(step)"
        (click)="selectStep.emit(step)"
        class="carousel-step"
        [class.active]="step.active"
      >
        <a>
          <ng-container *ngIf="step.visited && step.valid && !step.active; else customIcon">
            <aok-svg-icon class="checked" name="0858-checkmark_circle" [size]="64"></aok-svg-icon>
          </ng-container>
          <ng-template #customIcon>
            <aok-svg-icon [id]="step.iconName" [size]="64"></aok-svg-icon>
          </ng-template>
          <span
            [class.no-wrap]="!step.allowLinebreakInTitle"
            [class.checked]="step.visited && step.valid && !step.active"
            >{{ step.title }}</span
          >
        </a>
      </li>
      <li [class.checked]="step.visited" *ngIf="step.index !== steps.length - 1">.....</li>
    </ng-container>
  </ul>
</div>
<aok-svg-icon
  *ngIf="steps.length > nrOfStepsDisplayed && currentSlide < steps.length - nrOfStepsDisplayed"
  [id]="'arrow-right_circle'"
  class="aok-link-1"
  [size]="26"
  aokFocusable
  (click)="carouselNext()"
  (keyup.enter)="carouselNext()"
></aok-svg-icon>
