import { ModuleWithProviders, NgModule } from '@angular/core';
import { AOK_SPECIALIZATIONS_INIT_PROVIDER } from './specializations.listener';

@NgModule()
export class AokSpecializationsModule {
  static forRoot(): ModuleWithProviders<AokSpecializationsModule> {
    return {
      ngModule: AokSpecializationsModule,
      providers: [AOK_SPECIALIZATIONS_INIT_PROVIDER],
    };
  }
}
