import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yUtilsModule, AokSvgIconComponent, DialogOverlayModule } from '@aok/common';
import { AokTypeofPipeModule } from '../../../pipes';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { AokSimpleDialog } from './simple-dialog.component';
import { AokSimpleDialogDirective } from './simple-dialog.directive';

@NgModule({
  declarations: [AokSimpleDialog, AokSimpleDialogDirective],
  exports: [AokSimpleDialog, AokSimpleDialogDirective],
  imports: [
    DialogOverlayModule,
    CommonModule,
    A11yModule,
    A11yUtilsModule,
    AokSvgIconComponent,
    AokDialogLayoutModule,
    AokTypeofPipeModule,
  ],
})
export class AokSimpleDialogModule {}
