import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AokDialogResult,
  AokToastService,
  AokUserRegistrationClient,
  ContextState,
  DialogBase,
  DialogRef,
  sanitizePhoneNumber,
  setFocusOnFirstBannerFocusableElement,
} from '@aok/common';
import { EMPTY, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  createAuthOptionsForm,
  FormErrorsBannerUtil,
  secondFactorAuthFormLabels,
  secondFactorAuthHint,
} from '../../../utils';

@Component({
  selector: 'aok-cockpit-direct-registration-2fa',
  templateUrl: './direct-registration-2fa.component.html',
  styleUrls: ['./direct-registration-2fa.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectRegistration2faDialog
  extends FormErrorsBannerUtil
  implements OnDestroy, DialogBase<AokDialogResult>
{
  @Input() isCardOrderingEnabled: boolean;

  public bannerSingleError = 'Es ist ein Fehler aufgetreten.';

  public form: UntypedFormGroup;
  public formLabels = {
    ...secondFactorAuthFormLabels,
    bsnr: 'An folgende Praxis senden',
  };
  protected readonly secondFactorAuthHint = secondFactorAuthHint;

  private ngDestroyed = new Subject<void>();

  constructor(
    public readonly dialogRef: DialogRef<AokDialogResult>,
    public contextState: ContextState,
    private registrationsClient: AokUserRegistrationClient,
    private toastService: AokToastService
  ) {
    super();

    this.form = createAuthOptionsForm(true);
  }

  public ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  public submit(): void {
    if (this.form.invalid) {
      this.handleErrors({ form: this.form });
      setTimeout(() => setFocusOnFirstBannerFocusableElement(), 500);
      return;
    }

    this.errorIds = [];

    this.registrationsClient
      .select2fa({
        twoFactorAuthOption: this.form.value.authOption,
        bsnr: this.form.value.bsnr,
        countryCode: this.form.value.countryCode,
        secondFactorPhoneNumber: sanitizePhoneNumber(this.form.value.secondFactorPhoneNumber),
      })
      .pipe(
        catchError(() => {
          this.toastService.createErrorToast(
            'Technischer Fehler',
            'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
          );

          return EMPTY;
        })
      )
      .subscribe(() => {
        this.dialogRef.dispose(AokDialogResult.CONFIRM);
      });
  }
}
