import { NgModule } from '@angular/core';
import { AokCardComponent } from './card.component';
import { CommonModule } from '@angular/common';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { RouterModule } from '@angular/router';

const declarations = [AokCardComponent];

@NgModule({
  declarations,
  imports: [CommonModule, A11yUtilsModule, AokSvgIconComponent, RouterModule],
  exports: declarations,
})
export class AokCardModule {}
