import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import {
  AokPage,
  AokRegistrationToOrganisation,
  AokUser,
  AokUserClient,
  CurrentUserState,
  isAssistant,
  isDoctor,
} from '@aok/common';
import { ProfileService } from '@aok/components';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

export const bsnrRegistrationsResolver: ResolveFn<AokPage<AokRegistrationToOrganisation>> = () => {
  const currentUser = inject(CurrentUserState);
  const profileService = inject(ProfileService);
  const client = inject(AokUserClient);

  return currentUser.first$.pipe(
    mergeMap((user) => getBSNRRequests(user, client)),
    tap((registrations) => profileService.setJoinRequestsToBSNR(registrations?._embedded?.items || []))
  );
};

const getBSNRRequests = (
  currentUser: AokUser,
  client: AokUserClient
): Observable<AokPage<AokRegistrationToOrganisation>> => {
  return !isDoctor(currentUser) && !isAssistant(currentUser) ? of(null) : client.listBsnrRegistrations();
};
