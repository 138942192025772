import { Injectable } from '@angular/core';
import { EMPTY, lastValueFrom, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AokOrgClient, AokUserClient } from '../clients';
import { AokOrg, AokUser, KnownRoles } from '../schemas';
import { ContextState } from '../states/context.state';
import { isDoctor } from '../utils';
import { UserService } from './index';

@Injectable({ providedIn: 'root' })
export class ContextOrgLoader {
  constructor(
    protected clientUser: AokUserClient,
    protected client: AokOrgClient,
    private userService: UserService,
    private contextState: ContextState
  ) {}

  // TODO if method only needed for org load on refresh (after we have context already picked) - move to other place
  async init(currentUser?: AokUser): Promise<AokOrg | null> {
    this.checkStoredContextAgainstCurrentUser(currentUser);
    if (this.userService.hasAnyRole([KnownRoles.PendingHealthcareProfessional, KnownRoles.PendingAssistant])) {
      return Promise.resolve(null);
    }
    let user = this.contextState.practitioner;
    // TODO why is this needed?
    if (
      !(user?.practitionerResource?.organizationsIds?.[0] || currentUser?.practitionerResource?.organizationsIds?.[0])
    ) {
      const { email } = this.userService.getParsedToken() || {};
      const pagedUser = await lastValueFrom(this.clientUser.list({ email }));
      if (pagedUser?._embedded?.items?.length === 1) user = pagedUser._embedded.items[0];
    }

    const activeOrgId = this.contextState.orgId;

    if (!activeOrgId) {
      return lastValueFrom(EMPTY);
    }

    return lastValueFrom(
      this.client.find(activeOrgId).pipe(
        tap((org) => this.contextState.setOrg(org)),
        catchError((err) => {
          this.contextState.setOrg(null);
          return throwError(() => err);
        })
      )
    );
  }

  private checkStoredContextAgainstCurrentUser(currentUser?: AokUser): void {
    if (currentUser) {
      const orgId = this.contextState.orgId;
      if (orgId && !currentUser.practitionerResource.organizationsIds.includes(Number(orgId))) {
        this.contextState.clearSelectedContext();
      }
      const practitioner = this.contextState.practitioner;
      if (practitioner && isDoctor(currentUser) && currentUser.id !== practitioner.id) {
        this.contextState.clearSelectedContext();
      }
    }
  }
}
