<dialog-layout
  [fullscreen]="false"
  [headerText]="'Praxismitarbeiter entfernen'"
  cdkTrapFocus
  cdkTrapFocusAutoCapture="true"
>
  <p>
    {{ contentText }}
  </p>

  <p *ngIf="singleLink">
    {{ profileDeletionNote }} <a href="/datenschutz" tabindex="0" class="aok-link-1">Datenschutzerklärung</a>.
  </p>

  <dialog-footer>
    <button class="secondary" (click)="cancel()">Abbrechen</button>
    <button (click)="submit()">{{ confirmButtonText }}</button>
  </dialog-footer>
</dialog-layout>
