import { Inject, Injectable } from '@angular/core';
import { ArrayBehaviorState, ENVIRONMENT, EnvironmentSchema, NavigationEntry, UserService } from '@aok/common';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { generalNavigationEntries, kvnErrorNavigationEntries } from '../providers';
import { KvnState } from './kvn.state';

interface HeaderLayoutStateModel {
  navigation: NavigationEntry[];
  hiddenContext: boolean;
}

@Injectable({ providedIn: 'root' })
export class HeaderLayoutState {
  private initValue: HeaderLayoutStateModel;
  private customNavigation: boolean;

  private navigationState = new ArrayBehaviorState<NavigationEntry>();

  // TODO also include header individual menu links (e.g. contact)
  constructor(
    private kvnState: KvnState,
    private userService: UserService,
    @Inject(ENVIRONMENT) private environment: EnvironmentSchema
  ) {
    this.initValue = {} as HeaderLayoutStateModel;
  }

  public init(): Observable<NavigationEntry[]> {
    return this.setNavigation();
  }

  public setCustomNavigation(navigationEntries: NavigationEntry[]): void {
    this.customNavigation = true;
    this.navigationState.reset(...navigationEntries);
  }

  public resetCustomNavigation(): void {
    this.customNavigation = false;
    this.navigationState.reset(...this.initValue.navigation);
  }

  public getNavigationEntries$(): Observable<NavigationEntry[]> {
    return this.navigationState.asObservable();
  }

  private setNavigation(): Observable<NavigationEntry[]> {
    return this.kvnState.getKvnInfo$().pipe(
      first(),
      map((kvnInfo) => {
        if (kvnInfo.isKvnWithoutAccess) {
          return kvnErrorNavigationEntries;
        } else {
          return generalNavigationEntries(this.kvnState, this.environment);
        }
      }),
      // filter based on visibility
      map((navigationEntries) => {
        // need to check specifically for false value as not having a value is considered as always visible
        return navigationEntries.filter((entry) => entry.visible !== false);
      }),
      // filter entries based on roles
      map((navigationEntries) => {
        return navigationEntries.filter(
          (entry) => !Array.isArray(entry.roles) || this.userService.hasAnyRole(entry.roles)
        );
      }),
      tap((navigationEntries) => {
        this.initValue.navigation = navigationEntries;
        if (!this.customNavigation) {
          this.navigationState.reset(...navigationEntries);
        }
      })
    );
  }
}
