import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken, Optional, Type } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AokLoadingStateDirective, LOADING_INDICATOR_COMPONENT } from '../directives';
import { BehaviorSubject } from 'rxjs';

export type LoadingOverlayStrategy = 'onHttpEvent' | 'onRouterEvent';
export const LOADING_OVERLAY_STRATEGY = new InjectionToken<LoadingOverlayStrategy>('LOADING_OVERLAY_STRATEGY');

@Injectable({ providedIn: 'root' })
export class AokLoadingStateService {
  public isLoading$ = new BehaviorSubject<boolean>(false);

  protected readonly componentPortal: ComponentPortal<unknown>;
  protected bodyElementState: AokLoadingStateDirective;

  private readonly overlayRef: OverlayRef;

  constructor(
    protected overlay: Overlay,
    @Inject(DOCUMENT)
    protected readonly document: /** @dynamic */ Document,
    @Inject(LOADING_INDICATOR_COMPONENT)
    protected readonly indicatorComponentType: /** @dynamic */ Type<any>,
    @Inject(LOADING_OVERLAY_STRATEGY)
    readonly strategy: /** @dynamic */ LoadingOverlayStrategy,
    @Optional() protected router: Router
  ) {
    if (strategy === 'onRouterEvent') {
      if (router == null)
        throw new Error(
          `Unable to use loading overlay strategy "${strategy}" without an availalbe Router provide. Please` +
            `make sure to import the RouterModule or just configure the "onHttpEvent" strategy only`
        );
      else this._setupOnRouterEventStrategy();
    }

    this.bodyElementState = new AokLoadingStateDirective(document.body);
    this.componentPortal = new ComponentPortal(indicatorComponentType);
    this.overlayRef = overlay.create(
      new OverlayConfig({
        panelClass: 'loading-overlay',
        scrollStrategy: overlay.scrollStrategies.block(),
        positionStrategy: overlay.position().global().centerVertically().centerHorizontally(),
      })
    );
  }

  public attach(): void {
    this.bodyElementState.setTrue();
    if (!this.overlayRef.hasAttached()) this.overlayRef.attach(this.componentPortal);
    this.isLoading$.next(true);
  }

  public detach(): void {
    this.bodyElementState.setFalse();
    if (this.overlayRef.hasAttached()) this.overlayRef.detach();
    this.isLoading$.next(false);
  }

  private _setupOnRouterEventStrategy(): void {
    this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe(() => this.attach());
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError))
      .subscribe(() => this.detach());
  }
}
