export type BlobPreviewRevokeFn = () => void;

function createAndAppendBlobAnchor(blob: Blob): HTMLAnchorElement {
  const anchor = window.document.createElement('a');
  anchor.href = window.URL.createObjectURL(blob);
  anchor.style.display = 'none';
  window.document.body.appendChild(anchor);
  return anchor;
}

export function downloadBlob(blob: Blob, fileName: string): void {
  if (window == null) throw new Error('Blob download is not supported outside a browser environment');
  const anchor = createAndAppendBlobAnchor(blob);
  anchor.download = fileName;
  anchor.click();
  window.URL.revokeObjectURL(anchor.href);
  anchor.remove();
}

export function previewBlob(blob: Blob): BlobPreviewRevokeFn {
  if (window == null) throw new Error('Blob preview is not supported outside a browser environment');
  const anchor = createAndAppendBlobAnchor(blob);
  anchor.target = '_blank';
  anchor.click();
  return () => {
    window.URL.revokeObjectURL(anchor.href);
    anchor.remove();
  };
}

export async function fetchBlob(url: string): Promise<Blob> {
  if (window == null) throw new Error('Blob fetching is not supported outside a browser environment');
  return window.fetch(url).then((res) => res.blob());
}
