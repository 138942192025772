import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AokFocusFirstChildDirective,
  AokKeycloakTokenParsed,
  AokOrgClient,
  AokSpecializationState,
  AokToastService,
  AokUserClient,
  AokUserRegistration,
  AokUserRegistrationClient,
  AuthOption,
  createRegistrationRequestForm,
  DialogBase,
  DialogRef,
  extractAokRegistrationUserData,
  FormErrorDetectorRef,
  KnownUserType,
  resetErrors,
  sanitizePhoneNumber,
} from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { secondFactorAuthFormLabels, secondFactorAuthHint } from '../../../../utils';
import { AokInputFieldComponent } from '../../../cdk';
import { SecondFactorAuthOptionsComponent } from '../../../second-factor-auth-options/second-factor-auth-options.component';
import { AokStepperComponent, StepChangeEvent } from '../../../stepper/stepper.component';
import { AokBaseRegistrationDialog } from '../base-registration.component';

@Component({
  selector: 'aok-registration-dialog',
  styleUrls: ['./registration-dialog.component.scss'],
  templateUrl: './registration-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AokRegistrationDialog
  extends AokBaseRegistrationDialog
  implements OnInit, AfterViewInit, DialogBase<Partial<AokUserRegistration>>
{
  @Input() formValue: AokUserRegistration;
  @Input() isCardOrderingEnabled: boolean;

  @ViewChild(AokStepperComponent) stepper: AokStepperComponent;
  @ViewChildren('userData') userDataFields: QueryList<AokInputFieldComponent>;
  @ViewChild(SecondFactorAuthOptionsComponent) secondFactorAuthOptionsComponent: SecondFactorAuthOptionsComponent;
  @ViewChildren('orgData') orgDataFields: QueryList<AokInputFieldComponent>;
  @ViewChildren(AokFocusFirstChildDirective) stepsWithFocusFirstChild: QueryList<AokFocusFirstChildDirective>;

  public readonly formGroup = createRegistrationRequestForm(this.specializationOptions);
  public formLabels = secondFactorAuthFormLabels;

  protected readonly secondFactorAuthHint = secondFactorAuthHint;

  constructor(
    public readonly dialogRef: DialogRef<Partial<AokUserRegistration>>,
    protected orgClient: AokOrgClient,
    protected keycloak: KeycloakService,
    protected userClient: AokUserClient,
    protected userRegistrationClient: AokUserRegistrationClient,
    protected changeDetectorRef: ChangeDetectorRef,
    protected errorDetectorRef: FormErrorDetectorRef,
    protected toastService: AokToastService,
    protected specializationState: AokSpecializationState,
    protected cd: ChangeDetectorRef
  ) {
    super(dialogRef, specializationState, cd, orgClient);

    this.setupLanrSpecializationHandler();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.formValue == null) {
      const preRegistrationUserData = extractAokRegistrationUserData(
        this.keycloak.getKeycloakInstance().tokenParsed as AokKeycloakTokenParsed
      );
      this.formGroup.get('userData')?.patchValue(preRegistrationUserData);
    } else {
      this.formGroup.patchValue(
        createRegistrationRequestForm(this.specializationOptions, this.formValue).getRawValue()
      );
    }

    this.formGroup
      .get(['userData'])
      .valueChanges.pipe(takeUntil(this.destroys))
      .subscribe(() => this.validateUserData(true));

    this.formGroup
      .get(['organizationData'])
      .valueChanges.pipe(takeUntil(this.destroys))
      .subscribe(() => this.validateOrgData(true));
  }

  public ngAfterViewInit(): void {
    this.stepper.stepChange.subscribe((event: StepChangeEvent) => {
      // only validate when stepping forward
      if (event.newStep.index > event.currentStep.index) {
        if (
          (event.currentStep.title === 'Persönliche Daten' && this.validateUserData(false)) ||
          (event.currentStep.title === 'Praxisdaten' && this.validateOrgData(false))
        ) {
          this.changeStep(event.newStep);
        }
      } else {
        this.changeStep(event.newStep);
      }
    });

    this.stepper.stepChanged.subscribe((event: StepChangeEvent) => {
      setTimeout(() => this.stepsWithFocusFirstChild.get(event.newStep.index).setFocus());
    });

    this.stepper.submitted.subscribe(() => this.submit());
  }

  public validateUserData(byValueChanges?: boolean): boolean {
    if (!byValueChanges) {
      this.errorDetectorRef.detectErrors();
    }
    this.bannerLinks = this.getElementLinksFromInvalids(
      [...this.userDataFields, ...(this.secondFactorAuthOptionsComponent?.inputFieldComponents ?? [])],
      this.formGroup.get('userData') as UntypedFormGroup
    );
    const valid = this.validate(this.bannerLinks, byValueChanges);
    if (valid) {
      resetErrors(this.formGroup.get('organizationData'));
    }
    return valid;
  }

  public validateOrgData(byValueChanges?: boolean): boolean {
    if (!byValueChanges) {
      this.errorDetectorRef.detectErrors();
    }
    this.bannerLinks = this.getElementLinksFromInvalids(
      this.orgDataFields,
      this.formGroup.get('organizationData') as UntypedFormGroup
    );
    return this.validate(this.bannerLinks, byValueChanges);
  }

  /**
   * Build registration object from form
   * @param interim - check whether we need final object for submitting or saving intermediary object
   */
  protected buildRegistration(interim = false): AokUserRegistration {
    const registration = super.buildRegistration(interim) as AokUserRegistration;
    if (!interim) {
      registration.userData.userType = KnownUserType.Doctor;
    }
    if (registration.userData.authOption === AuthOption.SMS) {
      registration.userData.secondFactorPhoneNumber = sanitizePhoneNumber(
        registration.userData.secondFactorPhoneNumber
      );
    }

    return registration;
  }

  private async submit(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    this.isErrorBannerEnabled = false;
    const registration = this.buildRegistration();

    try {
      let result;
      if (this.formValue?.id == null) result = await lastValueFrom(this.userRegistrationClient.create(registration));
      else result = await lastValueFrom(this.userRegistrationClient.updateSessionRegistration(registration));
      this.dialogRef.dispose(result);
    } catch (e) {
      this.toastService.createErrorToast(
        'Technischer Fehler',
        'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
      );
    }
  }
}
