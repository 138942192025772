import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FooterLayoutState } from '../../../states/footer-layout.state';

@Component({
  selector: 'aok-cockpit-footer-wrapper',
  templateUrl: './footer-wrapper.component.html',
  styleUrls: ['./footer-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AokFooterWrapperComponent {
  constructor(public footerLayoutState: FooterLayoutState) {}
}
