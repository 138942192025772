<dialog-layout
  [fullscreen]="true"
  [isClosable]="true"
  [headerText]="'Kontakt aufnehmen'"
  aokFocusFirstChild
  cdkTrapFocus
>
  <div class="contact-container grid-10 lg:grid-8" aokFocusFirstChild>
    <p [class.tw-mb-6]="isErrorBannerEnabled" [class.tw-mb-3]="!isErrorBannerEnabled">
      Bitte verwenden Sie dieses Formular, wenn Sie einen Beratungstermin vereinbaren möchten.
    </p>
    <div *ngIf="isErrorBannerEnabled" id="errorMessages" class="notification-error">
      <ng-container [ngSwitch]="errorState">
        <aok-notification-banner
          *ngSwitchCase="ErrorStateEnum.multiError"
          id="error_validation_multi"
          accent="bad"
          bannerHeadline="Es sind mehrere Fehler aufgetreten."
          bannerLabel="Bitte überprüfen Sie Ihre Eingabe."
        >
        </aok-notification-banner>

        <aok-notification-banner
          *ngSwitchCase="ErrorStateEnum.error"
          id="error_validation_one"
          accent="bad"
          bannerHeadline="Es ist ein Fehler aufgetreten"
          bannerLabel="Bitte überprüfen Sie Ihre Eingabe."
        >
        </aok-notification-banner>
      </ng-container>
    </div>

    <p class="required-label">*Pflichtfeld</p>

    <h5>Nachricht erstellen</h5>

    <aok-input-field id="thema" [label]="'Thema'" required>
      <aok-dropdown [control]="formGroup.get('thema')" [options]="categories" [placeholder]="'Bitte wählen'">
      </aok-dropdown>
    </aok-input-field>

    <aok-input-field id="message" label="Ihre Nachricht" required>
      <textarea aokTrim [formControl]="formGroup.get('message')" placeholder="Weitere Informationen"></textarea>
    </aok-input-field>

    <h5 class="tw-mt-3">Ansprechpartner der Praxis wählen</h5>

    <aok-input-field id="partner" [label]="'Ansprechpartner'" required>
      <aok-dropdown [control]="formGroup.get('partner')" [options]="userOptions | async"></aok-dropdown>
    </aok-input-field>

    <h5 class="tw-mt-3">Kontaktkanal festlegen</h5>

    <aok-input-field id="contactOptionPhone" required label="Kontaktkanal">
      <aok-checkbox [formControl]="formGroup.get('contactOptionPhone')">
        <p>Telefon</p>
      </aok-checkbox>
    </aok-input-field>

    <aok-input-field id="phone">
      <input type="text" [formControl]="formGroup.get('phone')" placeholder="Telefonnummer angeben" />
    </aok-input-field>

    <aok-input-field id="contactOptionEmail">
      <aok-checkbox [formControl]="formGroup.get('contactOptionEmail')">
        <p>E-Mail</p>
      </aok-checkbox>
    </aok-input-field>

    <aok-input-field id="email">
      <input type="text" [formControl]="formGroup.get('email')" placeholder="E-Mail-Adresse angeben" />
    </aok-input-field>

    <p class="aok-bold info-title">Datenschutzhinweis</p>

    <p class="info">
      Wir verarbeiten und nutzen Ihre Daten ausschließlich für die Bearbeitung und Beantwortung Ihrer Anfrage. Ihre
      Daten werden anschließend gelöscht. Allgemeine Informationen zur Datenverarbeitung und zu Ihren Rechten finden Sie
      unter
      <a class="aok-link-1" aokFocusable (keyup.enter)="openPrivacyPage()" (click)="openPrivacyPage()">Datenschutz</a>.
    </p>

    <button (click)="submit()">Absenden</button>
  </div>
</dialog-layout>
