import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { AokUserClient } from '../clients/user.client';
import { AokKeycloakTokenParsed, AokUser, KnownUserType } from '../schemas';
import { UserService } from '../services';
import { isDoctor } from '../utils';
import { lanr9Util } from '../utils/lanr.util';
import { BehaviorState } from '../utils/rx/behavior-state';

@Injectable({ providedIn: 'root' })
export class CurrentUserState extends BehaviorState<AokUser> {
  // map representing the bsnr - last doctor relation
  private _lastDoctor: Record<string, boolean> = {};

  /** Gets the LANR identifier without the specialization id applied */
  get lanr(): string | null {
    return this.snapshot?.practitionerResource?.lanr;
  }

  /** Gets the LANR identifier including the specialization id */
  get lanr9(): string | null {
    return lanr9Util(this.lanr, this.snapshot?.practitionerResource?.specialization?.id);
  }

  get first$(): Observable<AokUser> {
    return this.asObservable().pipe(
      filter((user) => user != null),
      take(1)
    );
  }

  constructor(protected keycloak: KeycloakService, protected client: AokUserClient, private userService: UserService) {
    super();
  }

  /**
   * Initializes the active user information based on the current {@link AokKeycloakTokenParsed} data. The result will
   * be the fully quantified information of the active {@link AokUser} instance
   */
  retrieveCurrentUser(): Observable<AokUser | null> {
    const { sub } = (this.keycloak.getKeycloakInstance()?.tokenParsed as AokKeycloakTokenParsed) || {};

    return !sub || this.userService.isPendingUser()
      ? of(this.patch(null))
      : this.client.list({ keycloakId: sub }).pipe(
          map((users) => users._embedded?.items?.[0]),
          tap((user) => this.patch(user))
        );
  }

  public isLastDoctorAtBSNRLocation(bsnr: string): Observable<boolean> {
    if (!isDoctor(this.snapshot)) return of(false);

    if (this._lastDoctor[bsnr] == null) {
      return this.client
        .list({
          userType: [KnownUserType.Doctor, KnownUserType.Kvn_Doctor, KnownUserType.Full_Kvn_Doctor],
          size: 2,
          bsnr,
        })
        .pipe(
          map((users) => users._embedded.items?.length < 2),
          tap((isLast) => (this._lastDoctor[bsnr] = isLast))
        );
    }

    return of(this._lastDoctor[bsnr]);
  }

  public clear(): void {
    this.patch(null);
  }
}
