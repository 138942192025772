import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { ArrayBehaviorState, BehaviorState, groupBy, isAbsoluteURL, normalizeUrl } from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AOK_FOOTER_CONTACT, AOK_FOOTER_LINK_GROUPS, AOK_FOOTER_LINKS } from '../../providers';
import { AokFooterContact, AokFooterLink, AokFooterLinkGroup } from '../../schema/footer.schema';
import { clickFeedbackButton } from '../../utils';

@Component({
  selector: 'aok-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AokFooterComponent {
  @Input() title: string;
  @Input() showJumpTo = true;
  contactState = new BehaviorState<AokFooterContact>();
  linkState = new ArrayBehaviorState<AokFooterLink>();
  linkGroupState = new ArrayBehaviorState<AokFooterLinkGroup>();
  readonly currentYear = new Date().getFullYear();
  readonly contactChange: Observable<AokFooterContact> = this.contactState.asObservable();
  protected readonly clickFeedbackButton = clickFeedbackButton;
  private _absolute = false;
  // this observable will emit on the original link changes as well as on link group changes!
  readonly linksChange = merge(this.linkGroupState.asObservable(), this.linkState.asObservable()).pipe(
    map(() =>
      this.linkState.snapshot.map((link) => {
        const url =
          this._absolute && !isAbsoluteURL(link.url) ? normalizeUrl(this.document.baseURI, link.url) : link.url;
        const target = isAbsoluteURL(link.url) ? '_blank' : null;
        return { ...link, url, target };
      })
    ),
    map((links) => groupBy(links, (link) => link.group)[0])
  );

  get absolute(): boolean {
    return this._absolute;
  }

  @Input()
  set absolute(value: boolean) {
    this._absolute = coerceBooleanProperty(value);
    this.changeDetectorRef.detectChanges();
  }

  get links(): AokFooterLink[] {
    return this.linkState.snapshot;
  }

  @Input()
  set links(value: AokFooterLink[]) {
    this.linkState.reset(...value);
  }

  get linkGroups(): AokFooterLinkGroup[] {
    return this.linkGroupState.snapshot;
  }

  @Input()
  set linkGroups(value: AokFooterLinkGroup[]) {
    this.linkGroupState.reset(...value);
  }

  get contact(): AokFooterContact {
    return this.contactState.snapshot;
  }

  @Input()
  set contact(value: AokFooterContact) {
    this.contactState.resetObjState(value);
  }

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) protected document: /* @dynamic */ Document,
    @Optional() readonly keycloak?: KeycloakService,
    @Optional() @Inject(AOK_FOOTER_LINKS) links?: /* @dynamic */ AokFooterLink[],
    @Optional() @Inject(AOK_FOOTER_CONTACT) contact?: /* @dynamic */ AokFooterContact,
    @Optional() @Inject(AOK_FOOTER_LINK_GROUPS) linkGroups?: /* @dynamic */ AokFooterLinkGroup[]
  ) {
    // injection tokens are used for keycloak usage
    if (linkGroups != null) this.linkGroupState.reset(...linkGroups);
    if (links != null) this.linkState.reset(...links);
    if (contact != null) this.contactState.patch(contact);
  }

  protected checkGroupVisibility(group: any[]): boolean {
    return group.filter((link) => this.checkLinkVisibility(link)).length > 0;
  }

  protected checkLinkVisibility(link: AokFooterLink): boolean {
    let userRoles;
    try {
      userRoles = this.keycloak?.getUserRoles();
    } catch (e) {
      userRoles = [];
    }
    return (
      !link.requiresAuthentication ||
      (this.keycloak?.getKeycloakInstance()?.authenticated &&
        (link.roles == null || link.roles.some((role) => userRoles?.includes(role))))
    );
  }

  protected resolveLinkGroup(key: string): AokFooterLinkGroup | null {
    return this.linkGroupState.snapshot?.find((group) => group.key === key);
  }

  protected getLinkUrl(link: AokFooterLink): string {
    if (
      (link.group === 'a18y' || link.group === 'legal') &&
      !this.keycloak?.getKeycloakInstance()?.authenticated &&
      !this.absolute
    ) {
      return `/publik${link.url}`;
    }
    return link.url;
  }

  /**
   * While the groups and the links are sort, the order destroyed
   * This function sets the group order again
   * @param entries
   */
  protected sortByOrder(entries: Array<{ key: string; value: any }>): Array<{ key: string; value: any }> {
    const linkGroup: string[] = this.linkGroupState.snapshot.map((group) => group.key);
    return linkGroup.map((key) => entries.find((entry) => entry.key === key));
  }

  protected isExternalUrl(link: AokFooterLink): boolean {
    return link?.target === '_blank';
  }
}
