<div class="menu-link list">
  <ul>
    <li *ngFor="let item of items">
      <ng-container *ngIf="!item.uriScheme">
        <ng-container *ngIf="item.internal">
          <a (click)="item.onClick()" (keyup.enter)="item.onClick()" aokFocusable #link>
            <aok-svg-icon [name]="item.icon" [size]="16"></aok-svg-icon>
            <span>{{ item.label }}</span>
          </a>
        </ng-container>

        <ng-container *ngIf="!item.internal">
          <a [routerLinkOrHref]="item.href" aokFocusable (keyup.enter)="window.open(item.href)" #link>
            <aok-svg-icon [name]="item.icon" [size]="16"></aok-svg-icon>
            <span>{{ item.label }}</span>
            <aok-svg-icon class="tw-ml-10" [name]="'arrow-rounded_rightup'" [size]="16"></aok-svg-icon>
          </a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="item.uriScheme">
        <a [href]="item.href" aokFocusable #link>
          <aok-svg-icon [name]="item.icon" [size]="16"></aok-svg-icon>
          <span>{{ item.label }}</span>
        </a>
      </ng-container>
    </li>
  </ul>
</div>
