<section>
  <p class="step-description">{{ currentStep.description }}</p>
  <aok-stepper-carousel *ngIf="hasMultipleSteps" [steps]="steps" (selectStep)="select($event)"></aok-stepper-carousel>
  <div>
    <ng-content></ng-content>
  </div>
  <div *ngIf="!customButtonBar" class="button-bar">
    <button id="btnNext" class="secondary" *ngIf="!isLastStep && !isSecondLastStep" (click)="next()">
      {{ btnTitleNext }}
      <aok-svg-icon [id]="'arrow-rounded_right'"></aok-svg-icon>
    </button>
    <button *ngIf="isSecondLastStep" (click)="next()">
      {{ btnTitleSummary }}
    </button>
    <button id="btnSubmit" *ngIf="isLastStep" (click)="submit()">
      {{ btnTitleSubmit }}
    </button>
    <button id="btnBack" class="secondary" *ngIf="!isFirstStep" (click)="previous()">
      <aok-svg-icon [id]="'arrow-rounded_left'"></aok-svg-icon>
      {{ btnTitleBack }}
    </button>
  </div>
  <div *ngIf="customButtonBar">
    <ng-content select="button-bar"></ng-content>
  </div>
</section>
