import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AokPaginationComponent } from './pagination.component';
import { A11yUtilsModule, AokSvgIconComponent } from '@aok/common';
import { PaginationService } from '../../services/pagination.service';

@NgModule({
  declarations: [AokPaginationComponent],
  exports: [AokPaginationComponent],
  imports: [CommonModule, AokSvgIconComponent, A11yUtilsModule],
  providers: [PaginationService],
})
export class AokPaginationModule {}
