import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { AokPage, calculateSumOfObjectProperties, ContextState, DMPCase, DmpOverviewData } from '@aok/common';
import { EMPTY, map, mergeMap, Observable } from 'rxjs';
import { DmpCaseManagementOverviewService } from '../services/dmp-case-management-overview.service';
import { DmpCaseService } from '../services/dmp-case.service';

export const dmpCasesResolver: ResolveFn<Observable<AokPage<DMPCase>> | AokPage<DMPCase>> = (
  route: ActivatedRouteSnapshot
) => {
  const router = inject(Router);
  const dmpCaseService = inject(DmpCaseService);
  const contextState = inject(ContextState);
  const dmpOverviewService = inject(DmpCaseManagementOverviewService);

  const dmpCategory = route.data.dmpCategory;
  const { page = 0, size, sort = '', query = '', dmp = '', doctorId = '' } = route.queryParams;
  const pageParams = { page, size, sort, query, dmp, doctorId };

  /**
   * Loads DMP cases with conditions based on the user's role and data availability.
   */
  return dmpOverviewService.getDmpPageOverviewDataOnceForContext().pipe(
    map((data: DmpOverviewData) => {
      if (calculateSumOfObjectProperties(data?.counts) === 0) {
        router.navigate(['/services/dmp-falluebersicht']);

        return EMPTY;
      }
    }),
    mergeMap(() => dmpCaseService.loadDmpCases(dmpCategory, pageParams, contextState.practitionerLanr))
  );
};
