import { Injectable } from '@angular/core';
import { AokPage, BehaviorState, DMPCase } from '@aok/common';

@Injectable({
  providedIn: 'root',
})
export class DmpCaseState extends BehaviorState<AokPage<DMPCase>> {
  constructor() {
    super();
  }
}
