<dialog-layout
  fullscreen="false"
  [headerText]="'Zugriff nur über Telematikinfrastruktur möglich'"
  aokFocusFirstChild
  [autoBlur]="true"
  cdkTrapFocus
>
  <div>
    <p>
      Dieser Bereich des AOK-Arztportals kann nur über die Telematikinfrastruktur erreicht werden. Die
      Telematikinfrastruktur ist momentan nicht verfügbar.
    </p>
    <p class="tw-mt-7">Nutzen Sie die erweiterte TI-Prüfung, um Hinweise zur Einrichtung zu erhalten.</p>
  </div>

  <dialog-footer>
    <button class="secondary" (click)="openExtendedTI()" (keyup.enter)="openExtendedTI()">
      Erweiterte TI-Prüfung durchführen
      <aok-svg-icon class="tw-ml-10" name="arrow-rounded_rightup" size="16px"></aok-svg-icon>
    </button>

    <button (click)="close()" (keyup.enter)="close()">Schließen</button>
  </dialog-footer>
</dialog-layout>
