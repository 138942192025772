export function normalizeUrl(...segments: string[]): string {
  const isAbsoluteUrl = segments.some((segment) => segment.includes('://'));
  // replace any multi slash (/) appearance w/ a single slash character
  const url = segments.join('/').replace(/\/+/g, '/');
  // checks whether the url was an absolute url before the normalization
  // operation and if so, replaces the first occurrence of ":/" with "://"
  // to properly preserve any protocol relation
  return isAbsoluteUrl ? url.replace(/:\//, '://') : url;
}

export const getParentDomain = (): string => {
  const rx = /^([a-z\d-]+)\.([a-z\d-]+)\.(.+)$/g;
  const result = rx.exec(document.location.host);

  return result ? `${result[2]}.${result[3]}` : document.location.hostname;
};

export const getDomain = (url: string = window.location.pathname): string => {
  const rx = /^(.*\/\/)(.[^/]*)(\/).*/;
  const result = rx.exec(url);

  return result?.[2];
};

export const getCurrentSafariVersion = (): string => {
  return RegExp(/Version\/(\d+\.\d+)/).exec(navigator.userAgent)[1];
};

export const getSafariVersion = (version = getCurrentSafariVersion()): number => {
  const splittedVersion = version.split('.');

  if (splittedVersion.length === 2) {
    splittedVersion.push('0');
  } else if (splittedVersion.length === 1) {
    splittedVersion.push('00');
  }

  return +splittedVersion.join('');
};
