import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { checkTIConnection, isTIAccessible, setTICookie } from '@aok/common';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AppConfig } from '../config/app.config';

@Injectable({
  providedIn: 'root',
})
export class TiService {
  constructor(private http: HttpClient) {}

  public setupTIConnectionChecker(tiBaseUrl: string): Worker | undefined {
    if (typeof Worker !== 'undefined') {
      return this.setupWorker(tiBaseUrl);
    }

    this.setupTIChecker(tiBaseUrl);
  }

  private setupTIChecker(tiBaseUrl: string): void {
    const tiInterval = interval(AppConfig.tiCheckInterval);
    tiInterval
      .pipe(
        takeWhile(() => {
          return !isTIAccessible();
        })
      )
      .subscribe(() => {
        checkTIConnection(this.http, tiBaseUrl).subscribe();
      });
  }

  private setupWorker(tiBaseUrl: string): Worker {
    // Create a new
    const worker = new Worker(new URL('../workers/ti.worker.ts', import.meta.url));
    worker.onmessage = (response) => {
      setTICookie(response.data);
    };

    worker.postMessage(tiBaseUrl);

    return worker;
  }
}
