<ul *ngIf="pager.pages && pager.pages.length > 1">
  <button class="page-navigation" (click)="setPage(pager.currentPage - 1)" [disabled]="pager.currentPage === 1">
    <aok-svg-icon id="arrow-rounded_left" size="16" title="Zurück"></aok-svg-icon>
  </button>
  <li class="page-item delimiter" [class.pagination-hidden]="pager.startPage === 1">...</li>
  <li
    aokFocusable
    (keyup.enter)="setPage(page)"
    *ngFor="let page of pager.pages"
    [ngClass]="{ active: pager.currentPage === page }"
    class="page-item number-item"
  >
    <a (click)="setPage(page)" class="page-link">{{ page }}</a>
  </li>
  <li class="page-item delimiter" [class.pagination-hidden]="pager.endPage === pager.totalPages">...</li>

  <button
    class="page-navigation"
    (click)="setPage(pager.currentPage + 1)"
    [disabled]="pager.currentPage === pager.totalPages"
  >
    <aok-svg-icon id="arrow-rounded_right" size="16" title="Weiter"></aok-svg-icon>
  </button>
</ul>
